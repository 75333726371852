<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">Report Line Items</h2>            
        </div>
        <div class="card">
            <div class="card-header">
                <strong v-if="status">New Report Line Items</strong>
                <strong v-else>Update Report Line Items</strong>
            </div>
            <div class="card-body">
                <div class="row g-3">
                    <div class="col-md-6">
                        <label class="form-label">Project Group<span class="text-danger"> *</span></label>
                        <select class="form-select" :class="{ 'is-invalid': errors.project_group_id }"
                            v-model="report_templates.project_group_id" ref="project_group_id"
                            @change="getReportTemplate()">
                            <option value="">Select Project Group</option>
                            <option v-for="(project_group, key) in project_groups" :key="key"
                                :value="project_group.project_group_id">{{ project_group.project_group_name }}</option>
                        </select>
                        <span v-if="errors.project_group_id" class="invalid-feedback">{{ errors.project_group_id[0]
                            }}</span>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">Template Name <span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" v-model="report_templates.template_name"
                            :class="{ 'is-invalid': errors.template_name }" placeholder="Template Name" />
                        <span v-if="errors.template_name" class="invalid-feedback">{{ errors.template_name[0] }}</span>
                    </div>
                    <div class="col-md-12">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center" rowspan="2">#</th>
                                    <th class="text-center">Level 1</th>
                                    <th class="text-center">Level 2</th>
                                    <th class="text-center">Level 3</th>
                                    <th class="text-center">Level 4</th>
                                    <th class="text-center">Level 5</th>
                                    <th class="text-center">Level 6</th>
                                    <th class="text-center">Action</th>
                                </tr>
                                <tr>
                                    <th>
                                        <input type="text" class="form-control" placeholder="Level 1"
                                            v-model="report_levels.level1" :class="{ 'is-invalid': errors.level1 }">
                                        <span v-if="errors.level1" class="invalid-feedback">{{ errors.level1[0]
                                            }}</span>
                                    </th>
                                    <th>
                                        <input type="text" class="form-control" placeholder="Level 2"
                                            v-model="report_levels.level2" :class="{ 'is-invalid': errors.level2 }">
                                        <span v-if="errors.level2" class="invalid-feedback">{{ errors.level2[0]
                                            }}</span>
                                    </th>
                                    <th>
                                        <input type="text" class="form-control" placeholder="Level 3"
                                            v-model="report_levels.level3" :class="{ 'is-invalid': errors.level3 }">
                                        <span v-if="errors.level3" class="invalid-feedback">{{ errors.level3[0]
                                            }}</span>
                                    </th>
                                    <th>
                                        <input type="text" class="form-control" placeholder="Level 4"
                                            v-model="report_levels.level4" :class="{ 'is-invalid': errors.level4 }">
                                        <span v-if="errors.level4" class="invalid-feedback">{{ errors.level4[0]
                                            }}</span>
                                    </th>
                                    <th>
                                        <input type="text" class="form-control" placeholder="Level 5"
                                            v-model="report_levels.level5" :class="{ 'is-invalid': errors.level5 }">
                                        <span v-if="errors.level5" class="invalid-feedback">{{ errors.level5[0]
                                            }}</span>
                                    </th>
                                    <th>
                                        <input type="text" class="form-control" placeholder="Level 6"
                                            v-model="report_levels.level6" :class="{ 'is-invalid': errors.level6 }">
                                        <span v-if="errors.level6" class="invalid-feedback">{{ errors.level6[0]
                                            }}</span>
                                    </th>
                                    <th class="text-center">
                                        <button class="btn btn-primary" @click="addReportTemplate()">Add</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="report_template_level, key in report_templates.report_template_levels"
                                    :key="key">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ report_template_level.level1 }}</td>
                                    <td>{{ report_template_level.level2 }}</td>
                                    <td>{{ report_template_level.level3 }}</td>
                                    <td>{{ report_template_level.level4 }}</td>
                                    <td>{{ report_template_level.level5 }}</td>
                                    <td>{{ report_template_level.level6 }}</td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-danger"
                                            @click="deleteReportTemplateLevel(report_template_level.report_template_level_id)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end">
                <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                    @pagechanged="onPageChange" />
                <!-- <button class="btn btn-danger me-2" @click.prevent="discard()">
                    <i class="ri-close-line icon_hgt"></i>
                    Discard
                </button>
                <button class="btn btn-primary" @click="addTemplateMasterCost" v-if="status">
                    <i class="ri-save-line icon_hgt"></i>
                    Submit
                </button>
                <button class="btn btn-primary" @click="updateTemplateMasterCost" v-else>
                    <i class="ri-save-line icon_hgt"></i>
                    Update
                </button> -->
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
let Search = require("@/components/Search.vue").default;
export default {
    components: { Search, Pagination },
    name: "Bats.ReportTemplates.Create",
    data() {
        return {
            meta: {
                search: "",
                order_by: "asc",
                keyword: "report_template_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                report_template_id: 1
            },
            status: true,
            report_levels: {
                level1: '',
                level2: '',
                level3: '',
                level4: '',
                level5: '',
                level6: ''
            },
            report_templates: {
                report_template_id: '',
                project_group_id: '',
                template_name: '',
                report_template_levels: [
                    // {
                    //     level1: 'Land',
                    //     level2: '',
                    //     level3: '',
                    //     level4: '',
                    //     level5: '',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'CIVIL',
                    //     level2: 'Contractor Cost',
                    //     level3: 'Civil - Contractor Cost Main Plant',
                    //     level4: '',
                    //     level5: '',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'CIVIL',
                    //     level2: 'Contractor Cost',
                    //     level3: 'Piling Cost  (Part 1)',
                    //     level4: '',
                    //     level5: '',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'CIVIL',
                    //     level2: 'Contractor Cost',
                    //     level3: 'Piling Cost  (Part 2)',
                    //     level4: '',
                    //     level5: '',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'CIVIL',
                    //     level2: 'Contractor Cost',
                    //     level3: 'Other Civil Cost',
                    //     level4: '',
                    //     level5: '',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'CIVIL',
                    //     level2: 'Civil - Steel & Cement',
                    //     level3: 'Procurement of Steel - TOR',
                    //     level4: '',
                    //     level5: '',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'CIVIL',
                    //     level2: 'Civil - Steel & Cement',
                    //     level3: 'Procurement of Steel - Structure',
                    //     level4: '',
                    //     level5: '',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'CIVIL',
                    //     level2: 'Civil - Steel & Cement',
                    //     level3: 'Procurement of Cement',
                    //     level4: '',
                    //     level5: '',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'CIVIL',
                    //     level2: 'Civil - Steel & Cement',
                    //     level3: 'Grouting & water proofing, Misc etc',
                    //     level4: '',
                    //     level5: '',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'Plant & Machinery',
                    //     level2: 'Mechanical',
                    //     level3: 'Equipment Cost',
                    //     level4: 'P&M - Pre Clinkerisation',
                    //     level5: 'Mech LS Crusher system',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'Plant & Machinery',
                    //     level2: 'Mechanical',
                    //     level3: 'Equipment Cost',
                    //     level4: 'P&M - Pre Clinkerisation',
                    //     level5: 'Mech LS - Stacker',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'Plant & Machinery',
                    //     level2: 'Mechanical',
                    //     level3: 'Equipment Cost',
                    //     level4: 'P&M - Pre Clinkerisation',
                    //     level5: 'Mech LS - Reclaimer',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'Plant & Machinery',
                    //     level2: 'Mechanical',
                    //     level3: 'Equipment Cost',
                    //     level4: 'P&M - Pre Clinkerisation',
                    //     level5: 'Mech Truck Tippler',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'Plant & Machinery',
                    //     level2: 'Mechanical',
                    //     level3: 'Equipment Cost',
                    //     level4: 'P&M - Post Clinkerisation',
                    //     level5: 'Mech Cement Mill',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'Plant & Machinery',
                    //     level2: 'Mechanical',
                    //     level3: 'Equipment Cost',
                    //     level4: 'P&M - Post Clinkerisation',
                    //     level5: 'Grindging Media',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'Plant & Machinery',
                    //     level2: 'Mechanical',
                    //     level3: 'Equipment Cost',
                    //     level4: 'P&M - Post Clinkerisation',
                    //     level5: 'Mech Cement mill BH',
                    //     level6: '',
                    // },
                    // {
                    //     level1: 'Plant & Machinery',
                    //     level2: 'Mechanical',
                    //     level3: 'Equipment Cost',
                    //     level4: 'P&M - Post Clinkerisation',
                    //     level5: 'Mech Cement Silo feeding & Extraction',
                    //     level6: '',
                    // },
                ],
            },
            // project_types: [],
            project_groups: [],
            errors: []
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.getProjectGroups();
            if (to.name == "Bats.ReportTemplates.Create") {
                vm.$refs.project_group_id.focus();
            }
        });
    },
    methods: {
        // getProjectTypes() {
        //     let vm = this;
        //     vm.$store.dispatch("post", { uri: "getProjectTypes" })
        //         .then(function (response) {
        //             vm.project_types = response.data.data;
        //         })
        //         .catch(function (error) {
        //             vm.errors = error.response.data;
        //             vm.$store.dispatch("error", error.response.data.message);
        //         });
        // },

        getProjectGroups() {
            let vm = this;
            vm.$store.dispatch("post", { uri: "getProjectGroups" })
                .then(function (response) {
                    vm.project_groups= response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        addReportLevels() {

        },

        removeReportLevel(report_template_level) {

        },

        addReportTemplate() {
            let vm = this;
            this.errors = [];
            if (this.report_templates.project_group_id == '') {
                this.errors = { project_group_id: ['Project group not selected!'] };
                return;
            }
            if (this.report_templates.template_name == '') {
                this.errors = { template_name: ['Template name field cannot be empty!'] };
                return;
            }
            if (this.report_levels.level1 == '') {
                this.errors = { level1: ['Level 1 field cannot be empty!'] };
                return;
            }
            let obj = Object.assign({}, this.report_levels);
            this.report_templates.report_template_levels.push(obj);
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addReportTemplate', data: this.report_templates })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.report_templates = response.data.data;
                    this.meta.report_template_id = this.report_templates.report_template_id;
                    this.index();
                    this.clearLevelField();
                })
                .catch(error => {
                    loader.hide();
                    vm.report_templates.report_template_levels.pop();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getReportTemplate() {
            this.errors = [];
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getReportTemplate', data: { project_group_id: this.report_templates.project_group_id } })
                .then(response => {
                    loader.hide();
                    this.report_templates = response.data.data;
                    this.meta.report_template_id = this.report_templates.report_template_id;
                    this.index();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        deleteReportTemplateLevel(report_template_level_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'deleteReportTemplateLevel', data: { report_template_level_id: report_template_level_id } })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.getReportTemplate();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        index() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'paginateReportTemplateLevels', data: this.meta })
                .then(response => {
                    this.report_templates.report_template_levels = response.data.data;
                    vm.meta.totalRows = response.data.total;
                    vm.meta.lastPage = response.data.last_page;
                    vm.meta.from = response.data.from;
                    vm.meta.to = response.data.to;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    loader.hide();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },
        onPageChange(page) {
			this.meta.page = page;
			this.index();
		},

		onPerPageChange() {
			this.meta.page = 1;
			this.index();
		},
        
        clearLevelField(){
            if(this.report_levels.level6 != ''){
                this.report_levels.level6 = '';
                return;
            }
            if(this.report_levels.level5 != ''){
                this.report_levels.level5 = '';
                return;
            }
            if(this.report_levels.level4 != ''){
                this.report_levels.level4 = '';
                return;
            }
            if(this.report_levels.level3 != ''){
                this.report_levels.level3 = '';
                return;
            }
            if(this.report_levels.level2 != ''){
                this.level2 = '';
                return;
            }
            if(this.level1 != ''){
                this.report_levels.level1 = '';
                return;
            }
        }
    },
};
</script>

<style scoped>
.dropdown {
    position: relative;
    cursor: pointer;
}

.multiselect {
    position: relative;
}

.multiselect ul {
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    top: -0.1rem;
    width: 100%;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    background: white;
}

.overselect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
