<template>
    <div class="card">
        <div class="card-header">
            <strong>{{ param.title }}</strong>
        </div>
        <div class="card-body">
            <div class="row mb-3">
                <div :class="dynamic_column" v-for="column, key in param.columns" :key="key">
                    <label for="code">{{ column.head}} <span class="text-danger">*</span></label>
                    <input type="text" :placeholder="column.head" id="code" :class="{'is-invalid': column.error_name}" class="form-control" v-model="column.value" v-if="column.input_type=='text'" />
                    <select class="form-control" v-model="column.value" :disabled="column.readonly" :class="{'is-invalid': column.error_name}" v-if="column.input_type=='dropdown'">
                        <option value="">Select Cluster</option>
                        <option v-for="cluster, key in clusters" :key="key" :value="cluster.cluster_id">{{ cluster.cluster_name }}</option>
                    </select>
                    <span v-if="column.error_name" class="invalid-feedback">{{ column.error_name }}</span>  
                </div>
                <div class="col-sm-12 mt-2">
                    <div style="text-align: right">
                        <button class="btn btn-sm btn-danger me-2" @click="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                        <button class="btn btn-primary me-2 btn-sm" v-if="status" @click="add()"><i class="ri-save-line icon_hgt" ></i> SUBMIT</button>
                        <button class="btn btn-primary me-2 btn-sm" v-else @click="update()"><i class="ri-save-line icon_hgt" ></i> UPDATE</button>
                        <button v-if="delete_values!= 0" class="btn btn-sm btn-primary me-2" @click="deleteValues()" ><i class="ri-delete-bin-line icon_hgt"></i></button>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-12">
                    <label for="code">Search</label>
                    <input type="text" placeholder="Type keyword and press enter key" class="form-control" v-model="meta.search" @keypress.enter="Search()" />
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-sm table-bordered text-nowrap">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center">#</th>
                            <th scope="col" v-for="column, key in param.columns" :key="key"  @click="sort(column.name)">
                                {{ column.head }}
                                <span>
                                    <i v-if="meta.keyword==column.name && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                    <i v-else-if="meta.keyword==column.name && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </span>
                            </th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Action</th>
                            <th class="text-center"><input type="checkbox" :checked="isAllSelected" @change="selectAll" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td v-if="!values.length" class="text-center" colspan="10">No records found</td>
                        </tr>
                        <tr class="" v-for="value, key in values" :key="key">
                            <td class="text-center" scope="row">{{ meta.from + key }}</td>
                            <td v-for="column, index in param.columns" :key="index"><span v-if="column.input_type=='dropdown'">{{ getValue(value, column) }}</span>
                            <span v-else>{{ value[column.name] }}</span></td>
                            <td class="text-center">
                                <div class="form-switch">
                                    <input class="form-check-input" type="checkbox" v-model="value.status" role="switch" @click="toggleStatus(value)">
                                </div>
                            </td>
                            <td class="text-center">
                                <nav>
                                    <a href="javascript:void(0)" class="text-success me-2" @click="editRecord(value)"><i class="ri-pencil-line icon_hgt"></i></a>
                                    <a href="javascript:void(0)" class="text-danger" @click="forceDelete(value)"><i class="ri-delete-bin-line icon_hgt"></i></a>
                                </nav>
                            </td>
                            <td class="text-center">
                                <!-- <span>{{}}</span> -->
                                <input type="checkbox" v-model="delete_values" :value="value[param?.edit.name]" @change="select" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between align-items-center">
                <select class="form-select width-75" v-model="meta.per_page" @change="index()">
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                    <option>30</option>
                </select>
                <span>{{'Showing '+ this.meta.from + ' to '+ this.meta.to+ ' of '+this.meta.total_rows +' entries' }}</span>

                <ul class="pagination pagination-space justify-content-end mb-0">
                    <li class="page-item" :class="{ disabled: isInFirstPage }">
                        <button type="button" class="page-link" @click.prevent="onClickFirstPage">
                            First
                        </button>
                    </li>
                    <li class="page-item" :class="{ disabled: isInFirstPage }">
                        <button type="button" class="page-link" @click.prevent="onClickPreviousPage">
                            Previous
                        </button>
                    </li>
                    <li v-for="(page, key) in pages" class="page-item" :key="key" :class="{ active: isPageActive(page.name) }">
                        <button type="button" class="page-link" @click.prevent="onClickPage(page.name)" :disabled="page.isDisabled">
                            {{ page.name }}
                        </button>
                    </li>
                    <li class="page-item" :class="{ disabled: isInLastPage }">
                        <button type="button" class="page-link" @click.prevent="onClickNextPage">
                            Next
                        </button>
                    </li>
                    <li class="page-item" :class="{ disabled: isInLastPage }">
                        <button type="button" class="page-link" @click.prevent="onClickLastPage">
                            Last
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props:{
            initial_page:1,
            non_parameters:''
        },
        data() {
            return {
                isInLastPage:'',
                param:'',
                isAllSelected: false,
                delete_values: [],
                fields: [],
                column:{},
                values:[],
                dynamic_column:'',
                
                meta: {
                    search: '',
                    order_by: "asc",
                    keyword: '',
                    per_page: 10,
                    total_rows: 0,
                    page: 1,
                    last_page: 1,
                    from: 1,
                    to:'',
                    max_page: 1,
                },
                status:'true',
                errors:''
            }
        },
        computed: {            
            startPage() {
                if (this.meta.page === 1) {
                    return 1
                }
                if (this.meta.page === this.meta.last_page) {
                    return this.meta.last_page - this.meta.max_page + 1
                }
                return this.meta.page - 1
            },
            endPage() {
                return Math.min(this.startPage + this.meta.max_page - 1, this.meta.last_page)
            },
            pages() {
                const range = []
                for (let i = this.startPage; i <= this.endPage; i += 1) {
                    range.push({
                        name: i,
                        isDisabled: i === this.meta.page,
                    })
                }
                return range
            },
            isInFirstPage() {
                return this.meta.page === 1
            },
            isInLast_page() {
                return this.meta.page === this.meta.last_page
            },
        },
        
        
        watch:{
            '$store.state.param':function(){
                // alert("1")
                this.param = this.$store.getters.param
                this.meta.keyword = this.param.keyword
                this.meta.search =this.param.search
                // console.log('he this',this.initial_page)
                this.meta.page = this.initial_page
                this.index()
                this.getClusters()
            }
            
        },

        created(){
            // this.$store.watch(
            //     (state)=>{
            //         return this.$store.state.param // could also put a Getter here
            //     },
            //     (newValue, oldValue)=>{
            //         //something changed do something
            //         if(newValue){
            //             this.index()
            //         }
            //     },
            // )
        },
        updated(){
            // alert('2')
        },

        methods: {
            sort(field) {
                // console.log('field:---',field)
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            toggleStatus(value){
                let vm = this;
                let uri = {
                    uri: this.param.delete_url,
                    data: value
                }
                let loader = vm.$loading.show()
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide()
                        vm.$store.dispatch("success", response.data.message)
                        vm.discard()
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.errors
                      
                        vm.$store.dispatch("error", error.response.data.message)
                    })
            },

            forceDelete(value){
                // alert("forceDelete", value)
                // console.log("uriii", this.param)
                confirm("Are You Sure Want to permanently delete?");
                {
                    let vm = this;
                    let uri = { uri: this.param.force_delete_url, data: value}
                    let loader = vm.$loading.show()
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        loader.hide()
                        vm.$store.dispatch("success", response.data.message)
                        vm.discard()
                        vm.getClusters();
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.errors
                        vm.$store.dispatch("error", error.response.data.message)
                    })
                }
            },
            getValue(value, column){
                if(value[column.object]){
                    return value[column.object][column.object_name]
                }else{
                    return ''
                }
            },
            getClusters(){
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getClusters" })
                    .then(function (response) {
                        vm.clusters = response.data.data
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            editRecord(column) {
                let vm = this
                console.log('col')
                console.log(column)
                let entries = Object.entries(column)
                this.param.columns.map(function(element){
                    let data = entries.filter(function(ele){
                        return element.name == ele[0]
                    })
                    element.value = data[0][1]
                })
                let id = entries.filter(function(element){
                    return element[0] == vm.param.edit.name
                })
                // console.log(id[0])
                vm.param.edit.value = id[0][1]
                this.status = false
            },

            Search(){
                this.meta.page = 1
                this.index()
            },
            deleteValues() {
                if (confirm("Are you sure you want to delete")) {
                    let vm = this;
                    let loader = vm.$loading.show()
                    let data= {}
                    data[vm.param.edit.name] = this.delete_values
                    console.log('data:----',data)
                    vm.$store.dispatch("post", { uri: this.param.multiple_delete_url, data: data })
                    .then(function (response) {
                        loader.hide()
                        vm.$store.dispatch("success", response.data.message)
                        vm.discard()
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.errors
                    
                        vm.$store.dispatch("error", error.response.data.message)
                    })
                }
            },
            onClickFirstPage() {
                this.meta.page = 1
                this.index()
            },
            onClickPreviousPage() {
                this.meta.page--
                this.index()
            },
            onClickPage(page) {
                this.meta.page = page
                this.index()
            },
            onClickNextPage() {
                this.meta.page++
                this.index()
            },
            onClickLastPage() {
                this.meta.page = this.meta.last_page
                this.index()
            },
            isPageActive(page) {
                return this.meta.page === page
            },
         
            selectAll() {
                if (this.isAllSelected) {
                    this.delete_values = []
                    this.isAllSelected = false
                } else {
                    this.delete_values = []
                    for (var value in this.values) {
                        this.delete_values.push(this.values[value][this.param?.edit.name])
                        
                    }
                    this.isAllSelected = true
                }
            },
            select() {
                if (this.delete_values.length !== this.values.length) {
                    this.isAllSelected = false
                } else {
                    this.isAllSelected = true
                }
            },

            add(){
                let vm = this
                this.param.columns.map(function(element){
                    vm.column[element.name] = element.value
                })
                let uri = {
                    uri: this.param.add_url,
                    data: this.column
                }
                let loader = vm.$loading.show()
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide()
                        vm.$store.dispatch("success",response.data.message)
                        vm.discard()
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.errors
                        vm.param.columns.filter(function(element){
                            if(vm.errors[element.name]){
                                element.error_name = vm.errors[element.name][0]
                            }

                        })
                        vm.$store.dispatch("error", error.response.data.message)
                    })
                
            },

            update(){
                let vm = this
                this.param.columns.map(function(element){
                    vm.column[element.name] = element.value
                })
                let uri = {
                    uri: this.param.update_url,
                    data: this.column
                }
                vm.column[vm.param.edit.name] = vm.param.edit.value
                let loader = vm.$loading.show()
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide()
                        vm.$store.dispatch("success",response.data.message)
                        vm.discard()
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.errors
                        vm.$store.dispatch("error", error.response.data.message)
                    })
            },

            discard(){
                let vm = this
                this.param.columns.map(function(element){
                    element.value = ''
                    element.error_name = ''
                    vm.column[element.name] = element.value
                    
                })
                this.isAllSelected = false
                this.delete_values = []
                this.status = true
                this.index()
            },

            index(){
                let vm = this
                let num=12/this.param.columns?.length
                this.dynamic_column='col-sm-'+num
                this.meta.keyword
                let loader = vm.$loading.show()
                let uri = { uri: vm.param.index_url, data: vm.meta }
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide()
                        vm.values = response.data.data
                       
                        vm.meta.total_rows = response.data.meta.total
                        vm.meta.last_page = response.data.meta.last_page
                        vm.meta.from = response.data.meta.from
                        vm.meta.to = response.data.meta.to
                        
                        vm.meta.max_page = vm.meta.last_page >= 3 ? 3 : vm.meta.last_page
                        
                    })
                    .catch(function (error) {
                        loader.hide()
                        console.log(error)
                        vm.errors = error.response.data.errors
                        vm.$store.dispatch("error", error.response.data.message)
                    })
                
            }
        },
    }
</script>

<style>
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pagination .pagination-item {
    margin-right: 0.3571rem;
}

.pagination .pagination-item button {
    border: 1px solid #dfe3e7;
}

.pagination .pagination-item .active {
    background-color: #0d6efd;
    color: #fff;
}

    .icon_hgt {
        font-size: 16px;
        vertical-align:middle;
    }
    .width-75 {
        width: 75px;
    }
</style>
