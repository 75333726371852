<template>
    <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-md-flex align-items-center justify-content-between mb-4">
                <h5>Site Dashboard</h5>
                <nav class="nav gap-2 nav-icon nav-icon-sm ms-auto">
                    <div class=" nav-link">
                        <label class="me-2">Project&nbsp;Groups: </label>
                        <select class="form-control form-control-sm" v-model="project_section_group.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class=" nav-link">
                        <label class="me-2">Projects: </label>
                        <select class="form-control form-control-sm" v-model="project_section_group.project_id">
                            <option value="">Select Project</option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name }}</option>
                        </select>                        
                    </div>
                    <div class=" nav-link">
                        <label class="me-2">Date: </label>
                        <input type="date" class="form-control form-control-sm" v-model="project_section_group.job_date_time" />
                    </div>
                    <div class=" nav-link">
                        <button class="btn btn-sm btn-primary btn-sm " @click="getRccBarchart()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                    </div>
              </nav>
               
            </div>
            <div class="card-body">
                <div class="row g-3 mb-3 mt-0">
                
                    <div class="col-xl-3 mb-1 mt-0">
                        <div class="card card-one">
                            <div class="card-body">
                                <BarChart
                                    :type="'bar'"
                                    :container="'barcharta'"
                                    :title="barcharta.title"
                                    :xAxis="barcharta.xAxis"
                                    :series="barcharta.series"
                                    :stacking="barcharta.stacking"
                                    :labels="barcharta.labels"
                                    :xAxisLables="barcharta.xAxisLables"
                                    :height="'50%'"
                                    :legendEnabled="barcharta.legendEnabled"
                                    :yAxisLables="barcharta.yAxisLables"
                                    >
                                </BarChart>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 mb-1 mt-0">
                        <div class="card card-one">
                            <div class="card-body">
                                <BarChart
                                    :type="'bar'"
                                    :container="'barchartb'"
                                    :title="barchartb.title"
                                    :yAxis="barchartb.yAxis"
                                    :xAxis="barchartb.xAxis"
                                    :series="barchartb.series"
                                    :stacking="barchartb.stacking"
                                    :labels="barchartb.labels"
                                    :xAxisLables="barchartb.xAxisLables"
                                    :height="'50%'"
                                    :legendEnabled="barchartb.legendEnabled"
                                    :yAxisLables="barchartb.yAxisLables"
                                    >
                                </BarChart>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 mb-1 mt-0">
                        <div class="card card-one">
                            <div class="card-body">
                                <BarChart
                                    :type="'bar'"
                                    :container="'barchartc'"
                                    :title="barchartc.title"
                                    :yAxis="barchartc.yAxis"
                                    :xAxis="barchartc.xAxis"
                                    :series="barchartc.series"
                                    :stacking="barchartc.stacking"
                                    :labels="barchartc.labels"
                                    :xAxisLables="barchartc.xAxisLables"
                                    :height="'50%'"
                                    :legendEnabled="barchartc.legendEnabled"
                                    :yAxisLables="barchartc.yAxisLables"
                                    >
                                </BarChart>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 mb-1 mt-0">
                        <div class="card card-one">
                            <div class="card-body">
                                <Donut :container="'donutd'" :subtitle="donutd.subtitle" :max="donutd.max" :series="donutd.series" 
                                :plotoption="donutd.plotoption" :margin_top="donutd.margin_top" :height="donutd.height"> </Donut>
                                
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="row g-3 mb-1">
                    <div class="col-sm-3 mb-3 mt-0">
                        <div class="card card-one">
                            <div class="card-body">
                                <SplineBarChart
                                    :type="'column'"
                                    :container="'barcharte'"
                                    :title="barcharte.title"
                                    :yAxis="barcharte.yAxis"
                                    :xAxis="barcharte.xAxis"
                                    :series="barcharte.series"
                                    :stacking="barcharte.stacking"
                                    :labels="barcharte.labels"
                                    :max="barcharte.max"
                                >
                                </SplineBarChart>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 mb-3 mt-0">
                        <div class="card card-one">
                            <div class="card-body">
                                <SplineBarChart
                                    :type="'column'"
                                    :container="'barchartg'"
                                    :title="barchartg.title"
                                    :yAxis="barchartg.yAxis"
                                    :xAxis="barchartg.xAxis"
                                    :series="barchartg.series"
                                    :stacking="barchartg.stacking"
                                    :labels="barchartg.labels"
                                    :max="barchartg.max"
                                >
                                </SplineBarChart>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 mb-3 mt-0">
                        <div class="card card-one">
                            <div class="card-body">
                                <SplineBarChart
                                    :type="'column'"
                                    :container="'barchartf'"
                                    :title="barchartf.title"
                                    :yAxis="barchartf.yAxis"
                                    :xAxis="barchartf.xAxis"
                                    :series="barchartf.series"
                                    :stacking="barchartf.stacking"
                                    :labels="barchartf.labels"
                                    :max="barchartf.max"
                                >
                                </SplineBarChart>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 mb-3 mt-0">
                        <div class="card card-one">
                            <div class="card-body">                                
                                <SplineBarChart
                                    :type="'column'"
                                    :container="'barcharth'"
                                    :title="barcharth.title"
                                    :yAxis="barcharth.yAxis"
                                    :xAxis="barcharth.xAxis"
                                    :series="barcharth.series"
                                    :stacking="barcharth.stacking"
                                    :labels="barcharth.labels"
                                    :max="barcharth.max"
                                >
                                </SplineBarChart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-5 mb-3">
                        <div class="card card-one">
                            <div class="card-body">
                                <LineChart
                                    :title="scurvechart.title"
                                    :container="'scurvechart'"
                                    :subtitle="scurvechart.subtitle"
                                    :backgroundColor="scurvechart.backgroundColor"
                                    :gridLineColor="scurvechart.gridLineColor"
                                    :thousandsSep="scurvechart.thousandsSep"
                                    :decimalPoint="scurvechart.decimalPoint"
                                    :height="scurvechart.height"
                                    :yAxis="scurvechart.yAxis"
                                    :xAxis="scurvechart.xAxis"
                                    :series="scurvechart.series"
                                    :crosshair="scurvechart.crosshair"
                                    :customStyles="scurvechart.customStyles"
                                    :plotOptions="scurvechart.plotOptions"
                                    :label_font_size="scurvechart.label_font_size"
                                    :marginTop="scurvechart.marginTop"
                                >
                                </LineChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    let Search = require("@/components/Search.vue").default;
    import Donut from "@/components/charts/Donut";
    import BarChart from '@/components/charts/BarChart'
    import SplineBarChart from "@/components/charts/SplineBarChart";
    import LineChart from "@/components/charts/LineChart";
    var Highcharts = require("highcharts");
    import moment from "moment";
    require("highcharts/modules/heatmap")(Highcharts);
    
    export default {
        data() {
            return {
                initial:true,
                projects:[],
                projectgroups: [],
                project_section_group: {
                    current_month_year: "",
                    job_date_time:'',
                    is_calculation: "",
                    project_id: "",
                    project_code: "",
                    selected_plan:"overall_plan",
                    project_group_id:'',
                },
                errors:[],
                isLoading: false,
                donut:{
                    job_date_time:'',
                    project_id:'',
                    selected_plan:"overall_plan",
                },
                chart: {
                    title: "Overall S Curve",
                    backgroundColor: "#fff",
                    gridLineColor: "#ccd6eb",
                    thousandsSep: ".",
                    decimalPoint: ",",
                    xAxis: {
                    },
                    yAxis: {
                    },

                    series: [
                        {
                            name: "Overall Plan",
                            marker: {
                                symbol: "circle",
                            },
                            color: "#03bbd4",
                            data: [8, 11, 15, 21, 28, 33, 40, 45, 51, 57, 62, 67, 73, 79, 83, 91, 95, 98, 100],
                        },
                        {
                            name: "Overall Actual",
                            marker: {
                                symbol: "circle",
                            },
                            color: "#f60000",
                            data: [3, 9, 17, 25, 34, 46, 51, 67],
                        },
                    ],

                    crosshair: true,
                    customStyles: {
                        color: "#666",
                        fontFamily: "Nunito Sans",
                    },
                },
                donuta: {
                    title: "94%",
                    max: 0,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            size: '80%',
                            innerSize: '60%',
                            dataLabels: {
                                connectorWidth: 0,
                                distance: 5,
                                enabled: true,
                                formatter: function() {
                                    return '<span style="font-size:10px">' + this.point.y;
                                }
                            },
                            showInLegend: true,
                        },                        
                    },
                    series: [],
                },

                donutb: {
                    title: "8%",
                    max: 0,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            size: '80%',
                            innerSize: '60%',
                            dataLabels: {
                                connectorWidth: 0,
                                distance: 5,
                                enabled: true,
                                formatter: function() {
                                    return '<span style="font-size:10px">' + this.point.y;
                                }
                            },
                            showInLegend: true,
                        },
                    },
                    series: [],
                },
                donutc: {
                    title: "42%",
                    max: 0,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            size: '80%',
                            innerSize: '60%',
                            dataLabels: {
                                connectorWidth: 0,
                                distance: 5,
                                enabled: true,
                                formatter: function() {
                                    return '<span style="font-size:10px">' + this.point.y;
                                }
                            },
                            showInLegend: true,
                        },
                    },
                    series: [],
                },
                donutd: {
                    subtitle: "E&I",
                    height:'45%',
                    max: 0,
                    margin_top:20,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            size: '80%',
                            innerSize: '60%',
                            dataLabels: {
                                connectorWidth: 0,
                                distance: 5,
                                enabled: true,
                                formatter: function() {
                                    return '<span style="font-size:10px">' + this.point.y;
                                }
                            },
                            showInLegend: true,
                        },
                    },
                    series: [],
                },
                donute: {
                    title: "98%",
                    max: 0,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            size: '80%',
                            innerSize: '60%',
                            dataLabels: {
                                connectorWidth: 0,
                                distance: 5,
                                enabled: true,
                                formatter: function() {
                                    return '<span style="font-size:10px">' + this.point.y;
                                }
                            },
                            showInLegend: true,
                        },
                    },
                    series: [],
                },
                donutf: {
                    title: "65%",
                    max: 0,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            size: '80%',
                            innerSize: '60%',
                            dataLabels: {
                                connectorWidth: 0,
                                distance: 5,
                                enabled: true,
                                formatter: function() {
                                    return '<span style="font-size:10px">' + this.point.y;
                                }
                            },
                            showInLegend: true,
                        },
                    },
                    series: [],
                },

                barcharte: {
                    title: "RCC",
                    xAxis:[],
                    xAxisLables: {
                        enabled: true,
                    },
                    yAxis:[],
                    max:'',

                    stacking: "",
                    labels: "",

                    series: [],
                },
                barchartf: {
                    title: "Erection",
                    xAxis:[],
                    xAxisLables: {
                        enabled: true,
                    },
                    yAxis:[],
                    max:'',
                    stacking: "",
                    labels: "",

                    series: [],
                },
                barchartg: {
                    title: "Fabrication",
                    xAxis:[],
                    xAxisLables: {
                        enabled: true,
                    },
                    yAxis: [],
                    max:'',
                    stacking: "",
                    labels: "",

                    series: [],
                },                
                barcharth: {
                    title: "Manpower",
                    xAxis:[],
                    xAxisLables: {
                        enabled: true,
                    },
                    yAxis:[],
                    max:'',
                    stacking: "",
                    labels: "",
                    series: [],
                },
                scurvechart: {
                    title:"",
                    subtitle: "S-Curve for Planned vs Actual",
                    backgroundColor: "#fff",
                    gridLineColor: "#ccd6eb",
                    thousandsSep: ".",
                    decimalPoint: ",",
                    xAxis: {
                        type: "datetime",
                    },
                    yAxis: {},
                    series: [],
                    plotOptions: {
                        series: {},
                    },

                    crosshair: true,
                    customStyles: {
                        color: "#666",
                        fontFamily: "Nunito Sans",
                    },
                    label_font_size: "16px",
                    height: "40%",
                },
                actual_plan: [],
                overall_plan: [],
                overall_plan_data: [],
                actual_plan_data: [],
                barcharta:{
                    title:"Rcc",
                    xAxis: ['Scope', 'Dwg Rel', 'Cumm', 'Balance'], 
                    xAxisLables:{
                        enabled: true
                    },
                    stacking:"",
                    labels:"",
                    legendEnabled:false,
                    yAxisLables:false,
                    series: []
                },
                barchartb:{
                    title:"Fabrication",
                    xAxis: ['Scope', 'Dwg Rel', 'Cumm', 'Balance'], 
                    xAxisLables:{
                        enabled: true
                    },
                    stacking:"",
                    labels:"",
                    legendEnabled:false,
                    yAxisLables:false,
                    series: []
                },
                barchartc:{
                    title:"Erection",
                    xAxis: ['Scope', 'Cumm', 'Balance'], 
                    xAxisLables:{
                        enabled: true
                    },
                    stacking:"",
                    labels:"",
                    legendEnabled:false,
                    yAxisLables:false,
                    series: []
                },
                barchartd:{
                    title:"E&I",
                    xAxis: ['Scope', 'Dwg Rel', 'Cumm', 'Balance'], 
                    xAxisLables:{
                        enabled: true
                    },
                    stacking:"",
                    labels:"",
                    legendEnabled:false,
                    yAxisLables:false,
                    series: []
                },
            };
        },
        components: { Donut, SplineBarChart, LineChart, BarChart,Search },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectGroups();
            }
            
            this.project_section_group.job_date_time = moment().format("YYYY-MM-DD");
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                console.log(from.name)
                if (from.name == "login") {
                    vm.$router.go();
                }
            });
        },
        methods:{
            
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" }
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data
                        if(localStorage.getItem('project_group_id')){
                            vm.project_section_group.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.project_section_group.project_group_id) {
                                vm.project_section_group.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjects() {
                let vm = this
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                let uri = { uri: "getProjectGroupProjects", data:vm.project_section_group};
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projects = []
                        vm.projects = response.data;
                        if(localStorage.getItem('project_id')){
                            vm.project_section_group.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.project_section_group.project_id = vm.projects[0].project_id
                        }
                        if(vm.initial){
                            vm.getRccBarchart()
                            vm.initial = false
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getRccBarchart(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.donut.job_date_time = vm.project_section_group.job_date_time
                vm.donut.project_id = vm.project_section_group.project_id
                let uri = { uri: "rccBarChart", data: vm.donut };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.barcharta.series = [
                            {
                                name: 'RCC',
                                data: [
                                    {
                                        name:'scope',
                                        y:response.data.scope, 
                                        color: '#03bbd4',
                                    },
                                    {
                                        name:'dwg released',
                                        y:response.data.dwg_released, 
                                        color: '#f7a35c',
                                    },
                                    {
                                        name:'cumm',
                                        y:response.data.rcc_complete, 
                                        color: '#f7a35c',
                                    },
                                    {
                                        name:'Balance',
                                        y:response.data.rcc_balance, 
                                        color: '#C0C0C0',
                                    },
                                ]
                            }
                        ]
                        loader.hide();
                        vm.getFabBarchart();
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },

            getFabBarchart(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.donut.project_id = vm.project_section_group.project_id
                let uri = { uri: "fabricationBarChart", data: vm.donut };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.barchartb.series = [
                            {
                                name: 'Fab',
                                data: [
                                    {
                                        name:'scope',
                                        y:response.data.scope, 
                                        color: '#03bbd4',
                                    },
                                    {
                                        name:'dwg released',
                                        y:response.data.dwg_released, 
                                        color: '#f7a35c',
                                    },
                                    {
                                        name:'cumm',
                                        y:response.data.fab_completed, 
                                        color: '#f7a35c',
                                    },
                                    {
                                        name:'Balance',
                                        y:response.data.fab_balance, 
                                        color: '#C0C0C0',
                                    }
                                ]
                            }
                        ]
                        loader.hide();
                        vm.getErectionBarchart();
                        
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },
            getErectionBarchart(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.donut.project_id = vm.project_section_group.project_id
                let uri = { uri: "erectionBarChart", data: vm.donut };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.barchartc.series = [{
                            name: 'Erection',
                            data:[                            
                                {
                                    name:'scope',
                                    y:response.data.scope, 
                                    color: '#03bbd4',
                                },
                                {
                                    name:'cumm',
                                    y:response.data.Er_completed, 
                                    color: '#f7a35c',
                                },
                                {
                                    name:'Balance',
                                    y:response.data.Er_balance, 
                                    color: '#C0C0C0',
                                }
                            ]
                        }]
                        loader.hide();
                        vm.getEIDonutchart()
                        
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },
            getEIDonutchart(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.donut.project_id = vm.project_section_group.project_id
                let uri = { uri: "eiDonut", data: vm.donut };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.donutd.series = [
                            {
                                name: "% Completed",
                                y: response.data.completed,
                                color: "#03bbd4",
                            },
                            {
                                name: "% Balance",
                                y: response.data.balance,
                                color: "#f7a35c",
                            },
                        ]
                        loader.hide();
                        vm.getRccScurve();
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },

            getRccScurve(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.donut.project_id = vm.project_section_group.project_id
                let uri = { uri: "rccSCurve", data: vm.donut };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.barcharte.xAxis = response.data.month_year
                        vm.barcharte.max = response.data.max;
                        vm.barcharte.series = [
                            {
                                name: "Plan",
                                type: "column",
                                yAxis: 0,
                                color: "#03bbd4",
                                data: response.data.rcc_plan_mtd
                            },
                            {
                                name: "Actual",
                                type: "column",
                                yAxis: 0,
                                color: "#FFA500",
                                data: response.data.rcc_actual_mtd,
                            }
                        ]
                        loader.hide();
                        vm.getFabScurve();
                        
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },
            getFabScurve(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.donut.project_id = vm.project_section_group.project_id
                let uri = { uri: "fabricationSCurve", data: vm.donut };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.barchartg.xAxis = response.data.month_year
                        vm.barchartg.max = response.data.max;
                        vm.barchartg.series = [
                            {
                                name: "Plan",
                                type: "column",
                                yAxis: 0,
                                color: "#03bbd4",
                                data: response.data.fab_plan
                            },
                            {
                                name: "Actual",
                                type: "column",
                                yAxis: 0,
                                color: "#FFA500",
                                data: response.data.fab_actual,
                            }
                        ]
                        loader.hide();
                        vm.getErectionScurve()
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },
            getErectionScurve(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.donut.project_id = vm.project_section_group.project_id
                let uri = { uri: "erectionSCurve", data: vm.donut };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.barchartf.xAxis = response.data.month_year
                        vm.barchartf.max = response.data.max;
                        vm.barchartf.series = [
                        {
                                name: "Plan",
                                type: "column",
                                yAxis: 0,
                                color: "#03bbd4",
                                data: response.data.Er_plan
                            },
                            {
                                name: "Actual",
                                type: "column",
                                yAxis: 0,
                                color: "#FFA500",
                                data: response.data.Er_actual,
                            }
                        ]
                        loader.hide();
                        vm.getManpowerScurve();
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },

            getManpowerScurve(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.donut.project_id = vm.project_section_group.project_id
                let uri = { uri: "manpowerSCurve", data: vm.donut };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.barcharth.xAxis = response.data.month_year
                        vm.barcharth.max = response.data.max;
                        vm.barcharth.series = [
                            {
                                name: "Civil",
                                type: "column",
                                yAxis: 0,
                                color: "orange",
                                data: response.data.civil,
                                
                            },
                            {
                                name: "Mechanical",
                                type: "column",
                                yAxis: 0,
                                color: "#7c7c7c",
                                data: response.data.mech,
                                
                            },
                            {
                                name: "E&I",
                                type: "column",
                                yAxis: 0,
                                color: "#f7f700",
                                data: response.data.ei,
                                
                            },
                            {
                                name: "Refractory",
                                type: "column",
                                yAxis: 0,
                                color: "#03bbd4",
                                data: response.data.refractory,
                                
                            },
                            {
                                name: "Total",
                                type: "spline",
                                yAxis: 0,
                                step: 'center',
                                color: "#03bbd4",
                                data: response.data.total,
                                
                            },
                        ]
                        loader.hide();
                        vm.getOverallSCurve();                        
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },

            getOverallSCurve(){
                let vm = this;
                let loader = vm.$loading.show();
                localStorage.setItem('project_group_id', this.project_section_group.project_group_id)
                localStorage.setItem('project_id', this.project_section_group.project_id)
                vm.overall_active = "active";
                vm.project_section_group.current_month_year = moment(vm.project_section_group.job_date_time).format("MMM-YY");
                let uri = { uri: "getOverallSCurve", data: vm.project_section_group };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.scurvechart.series = [];
                        vm.scurvechart.xAxis.categories = response.data.overall_plan.month_year
                        vm.scurvechart.series.push({
                            type: 'line',
                            name: 'Planned',
                            marker: {
                                symbol: "circle",
                            },
                            color: '#03bbd4',
                            data: response.data.overall_plan.parameter_values,
                            tooltip: {
                                valueDecimals: 2,
                                valueSuffix: "%",
                            },
                        });
                        vm.scurvechart.series.push({
                            type: 'line',
                            name: 'Actual"',
                            marker: {
                                symbol: "circle",
                            },
                            color: '#FFA500',
                            data: response.data.actual_plan.parameter_values,
                            tooltip: {
                                valueDecimals: 2,
                                valueSuffix: "%",
                            },
                        });
                        loader.hide();
                    })
                    .catch(function (error) {
                        console.log(error);
                        loader.hide();
                    });
            },
            // calculateplan(plan_data, plan_name, line_color) {
            //     //Linechart
            //     let vm = this
            //     let data = [];
            //     if(plan_data.project_parameters)
            //     {
            //         for (let [key, value] of Object.entries(plan_data.project_parameters)) {
            //             let parameter_scope_value = 0;
            //             let date_time = "01-" + value[0].parameter.parameter_code;
            //             let parameter_value = 0;
            //             let scope_value = 0;
            //             let parameter_scope_multiply = 0;
            //             let over_all_weightage = 0;
            //             for (let i = 0; i < value.length; i++) {
            //                 if (value[i].job_parameter) {
            //                     if (value[i].job_parameter.parameter_value) {
            //                         parameter_value = value[i].job_parameter.parameter_value;
            //                         scope_value = value[i].job_parameter.project_activity.scope;
            //                         parameter_scope_multiply = parameter_value * scope_value;
            //                         parameter_scope_value = parameter_scope_value + parameter_scope_multiply;
            //                         over_all_weightage = over_all_weightage + Number(scope_value);
            //                     }
            //                 }
            //             }
            //             let date_time1 = new Date(date_time);
            //             let d1 = date_time1.getDate();
            //             let d2 = date_time1.getUTCMonth() + 1;
            //             let d3 = date_time1.getUTCFullYear();
            //             data.push([Date.UTC(d3, d2, d1), parameter_scope_value / over_all_weightage]);
            //         }

            //         vm.overall_plan_data = data;
            //         vm.scurvechart.series.push({
            //             name: plan_name,
            //             marker: {
            //                 symbol: "circle",
            //             },
            //             color: line_color,
            //             data: data,
            //             tooltip: {
            //                 valueDecimals: 2,
            //                 valueSuffix: "%",
            //             },
            //         });
            //     }
            // },

            // getRccDonut(){
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.donut.job_date_time = vm.project_section_group.job_date_time
            //     vm.donut.project_id = vm.project_section_group.project_id
            //     let uri = { uri: "rccDonut", data: vm.donut };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             vm.donuta.series = [
            //                 {
            //                     name: "RCC Completed",
            //                     y: response.data.rcc_complete,
            //                     color: "#03bbd4",
            //                 },
            //                 {
            //                     name: "RCC Balance",
            //                     y: response.data.rcc_balance,
            //                     color: "#f7a35c",
            //                 },
            //             ]
            //             loader.hide();
            //             vm.getFabricationDonut();
                        
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             console.log(error);
            //         });
            // },
            // getFabricationDonut(){
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.donut.project_id = vm.project_section_group.project_id
            //     let uri = { uri: "fabricationDonut", data: vm.donut };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             vm.donutb.series = [
            //                 {
            //                     name: "Fab. Completed",
            //                     y: response.data.fab_completed,
            //                     color: "#03bbd4",
            //                 },
            //                 {
            //                     name: "Fab. Balance",
            //                     y: response.data.fab_balance,
            //                     color: "#f7a35c",
            //                 },
            //             ]
            //             loader.hide();
            //             vm.getErectionDonut();
                        
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             console.log(error);
            //         });
            // },

            // getErectionDonut(){
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.donut.project_id = vm.project_section_group.project_id
            //     let uri = { uri: "erectionDonut", data: vm.donut };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             vm.donutc.series = [
            //                 {
            //                     name: "Er. Completed",
            //                     y: response.data.Er_completed,
            //                     color: "#03bbd4",
            //                 },
            //                 {
            //                     name: "Er. Balance",
            //                     y: response.data.Er_balance,
            //                     color: "#f7a35c",
            //                 },
            //             ]
            //             loader.hide();
            //             vm.getRccDwgDonut();
                        
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             console.log(error);
            //         });
            // },

            // getRccDwgDonut(){
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.donut.project_id = vm.project_section_group.project_id
            //     let uri = { uri: "rccDwgDonut", data: vm.donut };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             vm.donute.series = [
            //                 {
            //                     name: "RCC Dwg. Released",
            //                     y: response.data.rcc_dwg_released,
            //                     color: "#03bbd4",
            //                 },
            //                 {
            //                     name: "RCC Dwg. Balance",
            //                     y: response.data.rcc_dwg_balance,
            //                     color: "#f7a35c",
            //                 },
            //             ]
            //             loader.hide();
            //             vm.getFabDwgDonut();
                        
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             console.log(error);
            //         });
            // },

            // getFabDwgDonut(){
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.donut.project_id = vm.project_section_group.project_id
            //     let uri = { uri: "rccFabDwgDonut", data: vm.donut };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             vm.donutf.series = [
            //                 {
            //                     name: "Fab Dwg. Released",
            //                     y: response.data.fab_dwg_released,
            //                     color: "#03bbd4",
            //                 },
            //                 {
            //                     name: "Fab Dwg. Balance",
            //                     y: response.data.fab_dwg_balance,
            //                     color: "#f7a35c",
            //                 },
            //             ]
            //             loader.hide();
            //             vm.getRccScurve();
                        
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             console.log(error);
            //         });
            // },

            // getEIBarchart(){
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.donut.project_id = vm.project_section_group.project_id
            //     let uri = { uri: "eiBarChart", data: vm.donut };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             vm.barchartd.series = [
            //                 {
            //                     name: 'E&I',
            //                     data: [response.data.scope, response.data.dwg_released, response.data.cumm, response.data.balance],
            //                     color: '#03bbd4',
            //                 }, 
            //             ]
            //             loader.hide();
            //             vm.getRccScurve();
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             console.log(error);
            //         });
            // }
        }
    };
</script>

<style scoped>
    /* .chart_hgt {
        height:200px !important;
        width: 200px !important;
    } */
</style>
