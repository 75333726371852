<template>
    <div class="container-fluid">
        <form @submit.prevent="submitForm">
            <div class="card">
                <div class="card-header">
                    <strong v-if="status">New Parameter</strong>
                    <strong v-else>Update Parameter</strong>
                    <a href="javascript:void(0)" type="button" class="" style="float: right;" @click="$emit('indexParameter')"><i class="ri-list-check"></i> Parameters</a>
                </div>
                <div class="card-body">
                    <div class="row g-3">
                        <div class="col-md-4">
                            <label class="form-label">Parameter Code</label><span class="text-danger"> *</span>
                            <input type="text" :disabled="true" placeholder="Parameter Code" class="form-control" :class="{'is-invalid': errors.parameter_code}" v-model="parameter.parameter_code" ref="parameter_code" />
                            <span v-if="errors.parameter_code" class="invalid-feedback">{{ errors.parameter_code[0] }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Parameter Name</label><span class="text-danger"> *</span>
                            <input type="text" placeholder="Parameter Name" class="form-control" :class="{'is-invalid': errors.parameter_name}" v-model="parameter.parameter_name" />
                            <span v-if="errors.parameter_name" class="invalid-feedback">{{ errors.parameter_name[0] }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Field Type</label><span class="text-danger"> *</span>
                            <select class="form-select" aria-label="Default select example" :class="{ 'is-invalid': errors.field_type }" v-model="parameter.field_type">
                                <option value="" selected>Select Field Type</option>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="date">Date</option>
                                <option value="date_time">Date Time</option>
                                <option value="dropdown">Dropdown</option>
                            </select>
                            <span v-if="errors.field_type" class="invalid-feedback">{{ errors.field_type[0] }}</span>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">Field Size</label><span class="text-danger"> *</span>
                            <input type="text" placeholder="Field Size" class="form-control" :class="{'is-invalid': errors.field_size}" v-model="parameter.field_size" />
                            <span v-if="errors.field_size" class="invalid-feedback">{{ errors.field_size[0] }}</span>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">Is Required</label>
                            <select class="form-select" aria-label="Default select example" :class="{ 'is-invalid': errors.is_required }" v-model="parameter.is_required">
                                <!-- <option value="" selected>Select Is Required</option> -->
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <span v-if="errors.is_required" class="invalid-feedback">{{ errors.is_required[0] }}</span>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">Field Values</label>
                            <input type="text" placeholder="Field Values" class="form-control" :class="{'is-invalid': errors.field_values}" v-model="parameter.field_values" />
                            <span v-if="errors.field_values" class="invalid-feedback">{{ errors.field_values[0] }}</span>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">Is Calculated</label>
                            <select class="form-select" aria-label="Default select example" :class="{ 'is-invalid': errors.is_calculated }" v-model="parameter.is_calculated">
                                <!-- <option value="" selected>Select Is Calculated</option> -->
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <span v-if="errors.is_calculated" class="invalid-feedback">{{ errors.is_calculated[0] }}</span>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label">Formula</label>
                            <textarea type="text" placeholder="Formula" class="form-control" :class="{'is-invalid': errors.formula}" v-model="parameter.formula"></textarea>
                            <span v-if="errors.formula" class="invalid-feedback">{{ errors.formula[0] }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button type="reset" class="btn btn-danger me-2" @click.prevent="discard()"><i class="ri-close-line icon_hgt"></i> Discard</button>
                    <button v-if="status" type="submit" class="btn btn-primary"><i class="ri-save-line icon_hgt"></i> Submit</button>
                    <button v-else type="submit" class="btn btn-primary"><i class="ri-save-line icon_hgt"></i> Update</button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
    export default {
        name: "Parameters.Create",
        components: {},
        data() {
            return {
                status: true,
                parameter: {
                    parameter_id: "",
                    parameter_code: '',
                    parameter_name: "",
                    field_type: "",
                    field_size: "",
                    is_required: "1",
                    field_values: "",
                    is_calculated: "0",
                    formula: "",
                },
                parameters: [],
                errors: [],
            };
        },
       
        created() {
            let vm = this;
            if (vm.$store.getters.parameter_id) {
                vm.status = false;
                vm.parameter.parameter_id = vm.$store.getters.parameter_id;
                let uri = { uri: "getParameter", data: vm.parameter };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.parameter = response.data.data;
                        vm.$refs.parameter.focus();
                    })
                    .catch(function (error) {
                        // vm.errors = error.response.data.errors;
                        // vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        // watch:{
        //     'parameter.parameter_name':function(){
        //         if(this.parameter.parameter_name)
        //             this.parameter.parameter_code = this.parameter.parameter_name
        //     }
        // },
        methods: {
            // getValue() {
            //     alert(this.parameter_id);
            // },
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },
            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addParameter", data: vm.parameter };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Parameter is successfully created");
                        vm.$emit("indexParameter");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateParameter", data: vm.parameter };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Parameter is successfully updated");
                        vm.$emit("indexParameter");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
                let vm = this;
                vm.parameter.parameter_code = "";
                vm.parameter.parameter_name = "";
                vm.parameter.field_type = "";
                vm.parameter.field_size = "";
                vm.parameter.is_required = "";
                vm.parameter.field_values = "";
                vm.parameter.is_calculated = "";
                vm.parameter.formula = "";
                vm.$refs.parameter_code.focus();
                vm.errors = [];
                vm.status = true;
            },
        },
    };
</script>
<style scoped>
    .ml-auto {
        margin-left: auto !important;
    }
</style>
