<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">{{ project.project_name }}</h2>
            <!-- <router-link to="/projects" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-list-check"></i> Back</router-link> -->
            <!-- <a href="javascript:void(0)" class="btn btn-sm mb-2 btn-primary" @click="showRequests()"><i
                    class="ri-list-check"></i> Requests List</a> -->
        </div>
        <div class="card">
            <div class="card-header">
                <strong>WITHDRAWALS</strong>
                <button v-can="'budget_withdraw.create'" class="btn btn-sm btn-primary" style="float: right;" @click="newWithdrawal()">New Withdrawal</button>
            </div>
            <div class="card-body">
                <div class="table-responsive table-resposive-sm">
                    <table class="table table-sm text-nowrap table-bordered mb-0">
                        <thead>
                            <tr style="text-align: center;">
                                <th class="text-center">#</th>
                                <th>Reference No</th>
                                <th>Withdrawal Date</th>
                                <th>User</th>
                                <th>Project</th>
                                <th>Amount</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="withdrawal_cost, key in withdrawals" :key="key">
                                <td>{{ key + 1 }}</td>
                                <td>{{ withdrawal_cost.reference_no }}</td>
                                <td>{{ withdrawal_cost.withdrawal_date }}</td>
                                <td>{{ withdrawal_cost.user.name }}</td>
                                <td>{{ withdrawal_cost.project.project_name }}</td>
                                <td>{{ withdrawal_cost.amount }}</td>
                                <td class="text-center">
                                    <a v-can="'budget_withdraw.view'" href="javascript:void(0)" class="text-success me-2"
                                        @click.prevent="viewWithdrawal(withdrawal_cost)"><i class="ri-eye-line icon_hgt"
                                            data-bs-toggle="modal" data-bs-target="#withdrawalModal"></i></a>
                                    <a v-can="'budget_withdraw.delete'" href="javascript:void(0)" class="text-danger"
                                        @click.prevent="deleteWithdrawal(withdrawal_cost)"><i
                                            class="ri-delete-bin-line icon_hgt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <!-- <div class="d-flex justify-content-between align-items-center">
                    <select class="form-select from-select-sm width-75" @change="onPerPageChange" v-model="meta.per_page">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="30">30</option>
                    </select>
                    <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div> -->
            </div>
        </div>
    </div>
    <!-- Withdrawal view modal -->
    <div class="modal fade" id="withdrawalModal" tabindex="-1" aria-labelledby="requestModalLabel" aria-hidden="true"
        ref="request_modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="requestModalLabel">Withdrawal Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-6">
                            Name : <span class="text-primary">{{ withdrawal_costs[0]?.withdrawal?.user?.name }}</span>
                        </div>
                        <div class="col-sm-6">
                            Role : <span class="text-primary">{{ withdrawal_costs[0]?.withdrawal?.user?.role?.role_name }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            Reference No : <span class="text-primary">{{ withdrawal_costs[0]?.withdrawal?.reference_no }}</span>
                        </div>
                        <div class="col-sm-6">
                            Withdrawal Date : <span class="text-primary">{{ withdrawal_costs[0]?.withdrawal?.withdrawal_date }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            Project : <span class="text-primary">{{ withdrawal_costs[0]?.withdrawal?.project?.project_name }}</span>
                        </div>
                        <div class="col-sm-6">
                            WBS Code : <span class="text-primary">{{ withdrawal_costs[0]?.withdrawal?.project?.wbs_code }}</span>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-sm table-bordered border-warning">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th class="text-center">WBS Code</th>
                                            <th class="text-center">Budget</th>
                                            <th class="text-center">Balance</th>
                                            <th class="text-center">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="withdrawal, key in withdrawal_costs" :key="key">
                                            <td class="text-center">{{ key + 1 }}</td>
                                            <td>{{ withdrawal.project_master_cost?.wbs_code }}</td>
                                            <td style="text-align: right;">{{ withdrawal.budget }}</td>
                                            <td style="text-align: right;">{{ withdrawal.balance }}</td>
                                            <td style="text-align: right;">{{ withdrawal.amount }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" class="text-center"><strong>Total</strong></td>
                                            <td style="text-align: right;">{{ withdrawal_costs[0]?.withdrawal?.amount }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
    components: { Pagination },
    data() {
        return {
            meta: {
                search: "",
                // order_by: "asc",
                // keyword: "project_master_cost_id",
                // per_page: 10,
                // totalRows: 0,
                // page: 1,
                // lastPage: 1,
                // from: 1,
                // to: 1,
                // maxPage: 1,
                // trashed: false,
                project_id: ''
            },
            errors: [],
            error: {},
            project: {
                project_id: "",
                project_master_cost: [],
                last_stage: {}
            },
            project_master_cost: {},
            show_modal: 'modal fade',
            withdrawals: [],
            withdrawal_costs: []
        };
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.project.project_id = to.params.project_id;
            vm.meta.project_id = to.params.project_id;
            vm.index();
        });
    },


    methods: {
        index() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getWithdrawalList", data:vm.meta})
                .then(function (response) {
                    vm.withdrawals = response.data.data;
                    // vm.meta.totalRows = response.data.meta.total;
                    // vm.meta.lastPage = response.data.meta.last_page;
                    // vm.meta.from = response.data.meta.from;
                    // vm.meta.to = response.data.meta.to;
                    // vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },

        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },

        newWithdrawal() {
            this.$router.push('/BATS/' + this.project.project_id + '/withdrawal');
        },

        deleteWithdrawal(withdrawal_cost) {
            let vm = this;
            this.$store.dispatch('post', { uri: 'deleteWithdrawal', data: { withdrawal_id: withdrawal_cost.withdrawal_id } })
                .then(response => {
                    vm.$store.dispatch('success', response.data.message);
                    vm.index();
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        viewWithdrawal(withdrawal_cost) {
            let vm = this;
            vm.$store.dispatch('post', { uri: 'getWithdrawalDetails', data: { withdrawal_id: withdrawal_cost.withdrawal_id } })
                .then(response => {
                    vm.withdrawal_costs = response.data.data;
                })
                .catch(error => {
                    vm.errors = error.response.data.message;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },
    },
};
</script>

