<template>
    <div class="">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Daily Progress (DPR)</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <!-- <div class="col-md-3">
                        <label class="form-label">Project</label>
                        <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.project_id }" v-model="project.project_id" @change="getContractors()">
                            <option value="">Select Project</option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name}} </option>
                        </select>
                        <span v-if="errors.project_id"><small class="text-danger">{{ errors.project_id[0] }}</small></span>
                    </div> -->
                    <div class="col-md-3">
                        <label class="form-label">DPR Name</label>
                        <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': errors.dpr_name }" placeholder="DPR Name" v-model="project.dpr_name" />
                        <span v-if="errors.dpr_name"><small class="text-danger">{{ errors.dpr_name[0] }}</small></span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label"></label>
                        <div class="mt-1">
                            <button class="btn btn-success me-2 btn-sm" @click="addDailyProgressReport()"><i class="ri-pencil-line icon_hgt"></i> UPDATE</button>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div class="col-sm-12 mb-4">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-1">
                            <thead>
                                <tr>
                                    Contractor's Manpower
                                </tr>
                                <tr>
                                    <th>
                                        Contractor
                                        <span>
                                            <a href="javascript:void(0)" class="text-success me-1" title="add" @click="addContractorRow()"><i class="ri-add-circle-line icon_hgt"></i></a>
                                        </span>
                                    </th>
                                    <th class="text-center">Manpower Avail.</th>
                                    <th class="text-center">Manpower Reqd.</th>
                                    <th class="text-center">Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="contractor, key in project.contractors" :key="key">
                                    <td>
                                        <select class="form-control form-control-sm" v-model="contractor.contractor_name" @change="addContractor(contractor)">
                                            <option value="">Select Contractor </option>
                                            <option v-for="value, key in contractors" :value="value" :key="key">{{value}}</option>
                                        </select>
                                    </td>
                                    <td>{{ contractor.manpower_req }}</td>
                                    <td>{{ contractor.manpower_avail }}</td>
                                    <td>{{ contractor.remarks }}</td>
                                </tr>
                                <td></td>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-sm-12 mb-3">
                    <div class="row">
                        <div class="col-md-3">
                            <label>Activity Group</label>
                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.activity_group_id }" v-model="activity_group.activity_group_id" @change="getActivity(activity_group.activity_group_id)">
                                <option value="">Select Activity Group </option>
                                <option v-for="ag, key in activity_groups" :value="ag.activity_group_id" :key="key">{{ag.activity_group_name}}</option>
                            </select>
                            <span v-if="errors.activity_group_id"><small class="text-danger">{{ errors.activity_group_id[0] }}</small></span>
                        </div>
                        <div class="col-md-3">
                            <label>Contractor</label>
                            <select class="form-control form-control-sm" v-model="activity_group.contractor_name" @change="getActivity(activity_group.contractor_name)">
                                <option value="">Select Contractor </option>
                                <option v-for="value, key in contractors" :value="value" :key="key">{{value}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Activities</label>
                                <div class="dropdown" @click="showDropdown">
                                    <div class="overselect"></div>
                                    <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.activity_id }">
                                        <option value="">Please Select Activity</option>
                                    </select>
                                    <span v-if="errors.activity_id"><small class="text-danger">{{ errors.activity_id[0] }}</small></span>
                                </div>
                                <div class="multiselect" v-if="show">
                                    <ul>
                                        <li class="" v-for="(activity, index) in activities" :key="index">
                                            <input type="checkbox" :value="activity.activity_id" v-model="activity_group.activities" style="padding: 2px;" />
                                            <label style="margin-left: 5px;">{{ activity.activity_name }}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-success me-2 mt-3 btn-sm" @click="addRow()"><i class="ri-add-circle-line icon_hgt"></i> Add</button>
                        </div>
                    </div>
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-0 mt-3">
                            <thead>
                                <tr>
                                    <th>Particulars</th>
                                    <th class="text-center">Group</th>
                                    <th class="text-center">UOM</th>
                                    <th class="text-center">Scope</th>
                                    <th class="text-center">Cu.Dwg.Avail.</th>
                                    <th class="text-center">CTD (act)</th>
                                    <th class="text-center">FTD</th>
                                    <th class="text-center">MTD</th>
                                    <th class="text-center">Target FTD</th>
                                    <th class="text-center">Target FTM</th>
                                    <th class="text-center">Asking Rate</th>
                                </tr>
                            </thead>
                            <tbody v-for="activity_group, key in project.activity_groups" :key="key">
                                <tr>
                                    <td class="d-flex justify-content-between">
                                        <h5>{{ activity_group.activity_group_name }}</h5>
                                        <a href="javascript:void(0)" class="text-danger me-2" @click="deleteDprActivityGroup(activity_group)"><i class="ri-delete-bin-5-line icon_hgt"></i></a>
                                    </td>
                                </tr>
                                <tr v-for="contractor, contractor_key in activity_group.contractors" :key="contractor_key">
                                    <td>{{ contractor.contractor_name }}</td>
                                    <td>
                                        <table>
                                            <tr v-for="activity, activity_key in contractor.activities" :key="activity_key">
                                                <td>{{ activity.activity_name }}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr v-for="activity, activity_key in activity_group.activities" :key="activity_key">
                                    <td></td>
                                    <td>{{ activity.activity_name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DprTemplate",
        data() {
            return {
                // projects: [],
                show: false,
                project: {
                    project_id: "",
                    dpr_name: "",
                    daily_progress_report_id: "",
                    contractors: [],
                    contractor_name: "",
                    activity_groups: [],
                },
                contractors: [],
                activities: [],
                activity_group: {
                    project_id: "",
                    activity_group_id: "",
                    contractor_name: "",
                    activities: [],
                },
                daily_progress_report: {
                    daily_progress_report_id: "",
                    // dpr_name: "",
                },
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.daily_progress_report.daily_progress_report_id = to.params.daily_progress_report_id;
                // console.log("to", to.params.daily_progress_report_id)
            });
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getContractors(), this.getActivityGroups(), this.getDailyProgressReport();
            }
            
        },
        methods: {
            showDropdown() {
                this.show = !this.show;
            },
            
            getContractors() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getContractors" })
                    .then(function (response) {
                        vm.contractors = response.data.contractor.field_values.split(",");
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addContractorRow() {
                this.project.contractors.push({
                    activity_id: "",
                    manpower_avail: "",
                    manpower_req: "",
                    remarks: "",
                });
            },
            addContractor(contractor) {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getContractorReport", data: contractor })
                    .then(function (response) {
                        contractor.manpower_avail = response.data.manpower_avail;
                        contractor.manpower_req = response.data.manpower_req;
                        contractor.remarks = response.data.remarks;
                        contractor.project_activity_id = response.data.project_activity_ids;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getActivity() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getActivities" })
                    .then(function (response) {
                        vm.activities = response.data.data;

                        let selected_activity_group = vm.project.activity_groups.filter(function (element) {
                            return element.activity_group_id == vm.activity_group.activity_group_id;
                        });
                        if (selected_activity_group.length) {
                            selected_activity_group[0].activities.map(function (element) {
                                vm.activity_group.activities.push(element.activity_id);
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("error");
                        console.log(error);
                        // vm.errors = error.response.data.errors;
                        // vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getActivityGroups() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getActivityGroups"})
                    .then(function (response) {
                        vm.activity_groups = response.data.data;
                        vm.getContractors();
                    })
                    .catch(function (error) {
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addRow() {
                let vm = this;
                if (vm.activity_group.activity_group_id == "") {
                    vm.errors.activity_group_id = ["The activity group id field is required"];
                }
                if (vm.activity_group.activities == "") {
                    vm.errors.activity_id = ["The activity field is required"];
                }
                vm.pushActivityGroup();
                vm.pushContractor();
                vm.pushActivity();

                this.activity_group.activity_group_id = "";
                this.activity_group.contractor_name = "";
                this.activity_group.activities = [];
                this.show = false;
            },
            pushActivityGroup() {
                let vm = this;
                let ag = this.activity_groups.filter(function (element) {
                    return element.activity_group_id == vm.activity_group.activity_group_id;
                });
                if (ag.length) {
                    let ag_exists = this.project.activity_groups.filter(function (element) {
                        return element.activity_group_id == ag[0].activity_group_id;
                    });
                    if (!ag_exists.length) {
                        ag[0].contractors = [];
                        ag[0].activities = [];
                        this.project.activity_groups.push(ag[0]);
                    }
                }
            },

            pushContractor() {
                let vm = this;
                if (vm.activity_group.contractor_name) {
                    let activity_group = this.project.activity_groups.filter(function (element) {
                        return element.activity_group_id == vm.activity_group.activity_group_id;
                    });
                    if (activity_group.length) {
                        let ct = activity_group[0].contractors.filter(function (contractor) {
                            return contractor.contractor_name == vm.activity_group.contractor_name;
                        });
                        if (!ct.length) {
                            activity_group[0].contractors.push({
                                contractor_name: vm.activity_group.contractor_name,
                                activities: [],
                            });
                        }
                    }
                }
            },

            pushActivity() {
                let vm = this;
                if (this.activity_group.activities.length && vm.activity_group.contractor_name) {
                    let activity_group = vm.project.activity_groups.filter(function (element) {
                        return element.activity_group_id == vm.activity_group.activity_group_id;
                    });
                    if (activity_group.length) {
                        this.activity_group.activities.map(function (activity_id) {
                            let ct = activity_group[0].contractors.filter(function (act) {
                                return act.contractor_name == vm.activity_group.contractor_name;
                            });
                            if (ct.length) {
                                let ac = ct[0].activities.filter(function (act) {
                                    return act.activity_id == activity_id;
                                });
                                if (!ac.length) {
                                    let act = vm.activities.filter(function (activity) {
                                        return activity.activity_id == activity_id;
                                    });
                                    if (act.length) {
                                        ct[0].activities.push(act[0]);
                                    }
                                }
                            }
                        });
                    }
                }

                if (this.activity_group.activities.length && !vm.activity_group.contractor_name) {
                    let activity_group = vm.project.activity_groups.filter(function (element) {
                        return element.activity_group_id == vm.activity_group.activity_group_id;
                    });
                    if (activity_group.length) {
                        activity_group[0].activities = []
                        this.activity_group.activities.map(function (activity_id) {
                            let ac = activity_group[0].activities.filter(function (act) {
                                return act.activity_id == activity_id;
                            });
                            if (!ac.length) {
                                let act = vm.activities.filter(function (activity) {
                                    return activity.activity_id == activity_id;
                                });
                                if (act.length) {
                                    activity_group[0].activities.push(act[0]);
                                }
                            }
                        });
                    }
                }
            },
            addDailyProgressReport() {
                let vm = this;
                vm.project.project_id = vm.project.project_id;
                vm.project.daily_progress_report_id = vm.daily_progress_report.daily_progress_report_id
                // vm.daily_progress_report.dpr_name = vm.project.dpr_name
                vm.$store
                    .dispatch("post", { uri: "updateDailyProgressReports", data: vm.project })
                    .then(function (response) {
                        vm.$store.dispatch("success", "Updated successfully!");
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getDailyProgressReport() {
                let vm = this;
                vm.project.project_id = vm.project.project_id;
                vm.$store
                    .dispatch("post", { uri: "getDPREdit", data: vm.daily_progress_report })
                    .then(function (response) {
                        console.log("response", response.data.data);
                        vm.project.project_id = response.data.data.project_id;
                        vm.project.dpr_name = response.data.data.dpr_name;
                        vm.project.contractors = response.data.data.dpr_contractors;
                        vm.project.activity_groups = response.data.data.dpr_activity_groups;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            deleteDprActivityGroup(activity_group) {
                let vm = this;
                let data = {
                    dpr_activity_group_id: activity_group.dpr_activity_group_id,
                };
                let loader = vm.$loading.show();

                vm.$store
                    .dispatch("post", { uri: "deleteDPRActivityGroup", data: data })
                    .then(function (response) {
                        loader.hide();
                        vm.getDailyProgressReport();
                        vm.$store.dispatch("success", "Deleted successfully!");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>

<style scoped>
    .dropdown {
        position: relative;
        cursor: pointer;
    }
    .multiselect {
        position: relative;
    }
    .multiselect ul {
        border: 1px solid #ddd;
        border-top: 0;
        border-radius: 0 0 3px 3px;
        left: 0px;
        padding: 8px 8px;
        top: -0.1rem;
        width: 100%;
        list-style: none;
        max-height: 150px;
        overflow: auto;
        background: white;
    }
    .overselect {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>
