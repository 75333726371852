<template>
    <div class="">
        <!-- <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">PMT Master Template</h2>
            <router-link to="/templates" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-list-check"></i> PMT Master Templates</router-link>
        </div> -->
        <div class="card">
            <div class="card-header ">
                <div class="d-sm-flex align-items-center justify-content-between">
                    <div>
                        <strong>New PMT Master Template</strong>
                    </div>
                    <div>
                        <router-link to="/templates" type="submit" class="btn btn-sm btn-primary"><i class="ri-list-check"></i> PMT Master Templates</router-link>
                    </div>

                </div>
               
            </div>
            <div class="card-body mb-3">
                <form>
                    <div class="row g-3">
                        <div class="col-md-3">
                            <label class="form-label">Project Type <span class="text-danger"> *</span></label>
                             <select class="form-select form-select-sm" :class="{'is-invalid': errors.project_type_id}" v-model="template.project_type_id" ref="project_type_id">
                                <option value="">Select Project Type</option>
                                <option v-for="project_type, key in project_types" :key="key"
                                    :value="project_type.project_type_id">{{ project_type.project_type_name }}</option>
                            </select> 
                            <span v-if="errors.project_type_id" class="invalid-feedback">{{ errors.project_type_id[0]
                            }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Activity Group <span class="text-danger"> *</span></label>
                            <!-- <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.activity_group_id }"
                                v-model="template.activity_group_id">
                                <option value="">Select Activity Group</option>
                                <option v-for="activity_group, key in activity_groups" :key="key"
                                    :value="activity_group.activity_group_id">{{ activity_group.activity_group_name }}
                                </option>
                            </select> -->

                            <search
                                :class="{ 'is-invalid': errors.activity_group_id }"
                                :customClass="{ 'is-invalid': errors.activity_group_id }"
                                :initialize="template.activity_group_id"
                                id="activity_group_id"
                                label="activity_group_name"
                                placeholder="Select Activity Group"
                                :data="activity_groups"
                                @input="activity_group => template.activity_group_id = activity_group"
                            >
                            </search>
                            <span v-if="errors.activity_group_id" class="invalid-feedback">{{ errors.activity_group_id[0]
                            }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Template Code <span class="text-danger"> *</span></label>
                            <input type="text" class="form-control form-control-sm" v-model="template.template_code" :class="{'is-invalid': errors.template_code}" placeholder="Templace Code" />
                            <span v-if="errors.template_code" class="invalid-feedback">{{ errors.template_code[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Template Name <span class="text-danger"> *</span></label>
                            <input type="text" class="form-control form-control-sm" v-model="template.template_name" :class="{'is-invalid': errors.template_name}" placeholder="Templace Name" />
                            <span v-if="errors.template_name" class="invalid-feedback">{{ errors.template_name[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Is Plan <span class="text-danger"> *</span></label>
                            <select class="form-control form-control-sm" v-model="template.is_plan" :class="{'is-invalid': errors.is_plan}">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <span v-if="errors.is_plan" class="invalid-feedback">{{ errors.is_plan[0] }}</span>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger btn-sm me-2" @click.prevent="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                <button class="btn btn-primary btn-sm" @click="addTemplate()"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
            </div>
        </div>
    </div>
</template>
<script>
 let Search = require("@/components/Search.vue").default;
    export default {
        components: { Search },
        data() {
            return {
                template: {
                    template_id: "",
                    project_type_id: "",
                    activity_group_id: "",
                    template_code: "",
                    template_name: "",
                    is_plan:'0'
                },
                parameter_type_id: "",
                templates: [],
                project_types: [],
                activity_groups: [],
                section_group_no: 1,
                no_of_parameters: "",
                section_groups: [],
                sections: [],
                parameter_types: [],
                parameters: [],
                activities: [],
                units: [],
                errors: [],
            };
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectTypes();
            }
            
        },
        watch: {
            "template.project_type_id": function () {
                this.generateProjectCode();
            },
            "template.activity_group_id": function () {
                this.generateProjectCode();
            },
        },
        methods: {
            generateProjectCode() {
                let vm = this;
                if (vm.template.project_type_id) {
                    let project_type = vm.project_types.filter(function (element) {
                        return element.project_type_id == vm.template.project_type_id;
                    });
                    if (project_type.length) vm.template.template_code = project_type[0].project_type_code;
                }
                if (this.template.activity_group_id) {
                    let activity_group = vm.activity_groups.filter(function (element) {
                        return element.activity_group_id == vm.template.activity_group_id;
                    });
                    if (activity_group.length && vm.template.template_code) vm.template.template_code = vm.template.template_code + "_" + activity_group[0].activity_group_code;
                }
            },

            getProjectTypes() {
                let vm = this;
                if (vm.$store.getters.project_types) {
                    vm.project_types = vm.$store.getters.project_types;
                    vm.getActivityGroups();
                } else {
                    let uri = { uri: "getProjectTypes", data: vm.template };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.$store.dispatch("setProjectTypes", response.data.data);
                            vm.project_types = vm.$store.getters.project_types;
                            vm.getActivityGroups();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            },

            getActivityGroups() {
                let vm = this;
                if (vm.$store.getters.activity_groups) {
                    vm.activity_groups = vm.$store.getters.activity_groups;
                } else {
                    let uri = { uri: "getActivityGroups", data: vm.template };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.$store.dispatch("setActivityGroups", response.data.data);
                            vm.activity_groups = vm.$store.getters.activity_groups;
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            },

            addTemplate() {
                let vm = this;
                let uri = { uri: "addTemplate", data: vm.template };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.template = response.data.data;
                        vm.$store.dispatch("success", "Template master created successfully");
                        vm.$router.push("/template/" + vm.template.template_id + "/update");
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
                let vm = this;
                vm.template.template_id = "";
                vm.template.project_type_id = "";
                vm.template.activity_group_id = "";
                vm.template.template_code = "";
                vm.template.template_name = "";
                vm.$refs.project_type_id.focus();
                vm.errors = [];
                vm.status = true;
            },
        },
    };
</script>
<style scoped>
</style>
