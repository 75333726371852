<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">Projects</li>
			</ol>
			<h4 class="main-title mb-0">WBS Codes</h4>
		</div>
		<div class="d-flex align-items-center gap-2 mt-3 mt-md-0" v-can="'project_costs.upload'">
			<button class="btn btn-primary d-flex align-items-center gap-2" @click="addProjectCost()">
				<i class="ri-add-box-line fs-18 lh-1"></i>Add New WBS
			</button>
			<a target="_blank" :href="$store.getters.apiUrl + '/storage/formats/wbs_format.xlsx'"
				class="btn btn-success d-flex align-items-center gap-2">
				<i class="ri-chat-download-line fs-18 lh-1"></i> Download Format
			</a>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-sm-12">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">Upload WBS Codes</h6>
					<nav class="nav ms-auto" v-can="'project_costs.upload'">
						<div class="d-flex">
							<input type="file" class="form-control form-controls-sm mx-2" ref="attachment"
								@change="getAttachment($event)" />
							<button @click="uploadAttachment" class="btn btn-primary btn-sm me-2">
								Upload
							</button>
						</div>
					</nav>
				</div>
				<div class="card-body">
					<div class="row g-2 mb-1">
						<Project :project="project" />
						<div class="col-md-12">
							<div class="form-group">
								<div class="row">
									<div :class="{'col-sm-11':selected.length, 'col-sm-12':selected.length==0}">
										<input class="form-control" type="text"
											placeholder="Type keyword and press enter key" v-model="meta.search"
											@keypress.enter="search()" />
									</div>
									<div class="col-sm-1" v-show="selected.length">
										<button class="btn float-end btn-outline-danger" @click="bulkDelete()">Delete</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="table-responsive table-responsive-sm">
								<table class="table table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th class="text-center">Level</th>
											<th>WBS Description</th>
											<th>WBS Code</th>
											<th class="text-center">UOM</th>
											<th class="text-center">Approved Budget</th>
											<th class="text-center">Is Quantity ?</th>
											<th class="text-center">Quantity</th>
											<th class="text-center">UOM</th>
											<th class="text-center d-flex justify-content-between"
												v-can="'project_costs.delete'">
												Action
												<input type="checkbox" v-model="select_all" @change="selectAll()"
													name="" id="">
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="!project_costs.length">
											<td colspan="10" class="text-center">No records found</td>
										</tr>
										<tr v-for="project_cost, key in project_costs" :key="key">
											<td class="text-center">{{ meta.from + key }}</td>
											<td class="text-center">{{ project_cost.level }}</td>
											<td>{{ project_cost.wbs_description }}</td>
											<td>{{ project_cost.wbs_code }}</td>
											<td class="text-center">{{ project_cost.cost_type?.cost_type_code }}</td>
											<td>
												<input type="text" v-model="project_cost.budget"
													@blur="updateProjectCostBudget(project_cost)"
													placeholder="Approved Budget" class="form-control form-control-sm">
											</td>
											<td class="text-center">
												<div class="form-switch form-switch">
													<input class="form-check-input" type="checkbox" role="switch"
														:id="project_cost.project_cost_id"
														:checked="project_cost.is_quantity"
														v-model="project_cost.is_quantity"
														@change="updateProjectCostIsQuantity(project_cost)">
													<label class="custom-control-label"
														:for="project_cost.project_cost_id"></label>
												</div>
											</td>
											<td v-if="project_cost.is_quantity">
												<input type="text" v-model="project_cost.quantity"
													placeholder="Quantity" class="form-control form-control-sm"
													@blur="updateProjectCostQuantity(project_cost)">
											</td>
											<td v-else></td>
											<td v-if="project_cost.is_quantity">
												<select v-model="project_cost.quantity_cost_type_id"
													class="form-control form-control-sm"
													@change="updateProjectCostType(project_cost)">
													<option value="">Select One</option>
													<option v-for="cost_type, key in cost_types" :key="key"
														:value="cost_type.cost_type_id">
														{{ cost_type.cost_type_code }}
													</option>
												</select>
											</td>
											<td v-else></td>
											<td class="text-center d-flex justify-content-between"
												v-can="'project_costs.delete'">
												<a href="#" class="text-danger"
													@click.prevent="deleteProjectCost(project_cost)">
													<i class="ri-delete-bin-line icon_hgt"></i>
												</a>
												<input type="checkbox" @change="checkSelection()" v-model="selected"
													:value="project_cost.project_cost_id" name="" id="">
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
						@pagechanged="onPageChange" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Project from '@/components/Project'
import Pagination from "@/components/Pagination.vue";
export default {
	components: { Project, Pagination },
	data() {
		return {
			project: {},
			meta: {
				level: '',
				search: '',
				project_id: '',
				order_by: "asc",
				keyword: "project_cost_id",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				maxPage: 1,
				trashed: false,
			},
			cost_types: [],
			project_costs: [],
			attachment: '',
			select_all: false,
			selected: []
		};
	},

	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.meta.project_id = to.params.project_id
			let uri = { uri: "getProject", data: vm.meta };
			vm.$store.dispatch("post", uri)
				.then(function (response) {
					vm.project = response.data.data
					vm.getCostTypes()
					vm.getProjectCosts()
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		});
	},
	methods: {
		getCostTypes() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getCostTypes", data: vm.meta })
				.then(function (response) {
					vm.cost_types = response.data.data
				})
				.catch(function (error) {
					vm.errors = error.response.data.error;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		getProjectCosts() {
			let vm = this
			let loader = vm.$loading.show()
			vm.$store.dispatch("post", { uri: "paginateProjectCosts", data: vm.meta })
				.then(function (response) {
					loader.hide()
					vm.project_costs = response.data.data
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					loader.hide()
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		getAttachment(e) {
			let vm = this;
			vm.attachment = e.target.files[0]
		},

		uploadAttachment() {
			let vm = this
			let loader = vm.$loading.show()
			const formData = new FormData()
			formData.append('user_id', vm.$store.getters.user.user_id)
			formData.append('project_id', vm.project.project_id)
			formData.append('attachment', vm.attachment)
			vm.$store.dispatch("post", { uri: "uploadProjectCosts", data: formData })
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "WBS codes has been uploaded successfully");
					vm.$refs.attachment.value = null;
					vm.getProjectCosts()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		updateProjectCostBudget(project_cost) {
			this.$store.dispatch('post', { uri: 'updateProjectCostBudget', data: project_cost })
		},

		updateProjectCostIsQuantity(project_cost) {
			this.$store.dispatch('post', { uri: 'updateProjectCostIsQuantity', data: project_cost })
		},

		updateProjectCostQuantity(project_cost) {
			this.$store.dispatch('post', { uri: 'updateProjectCostQuantity', data: project_cost })
		},

		updateProjectCostType(project_cost) {
			this.$store.dispatch('post', { uri: 'updateProjectCostType', data: project_cost })
		},

		deleteProjectCost(project_cost) {
			let vm = this;
			if (confirm('Do you want to delete the WBS ' + project_cost.wbs_code)) {
				let loader = vm.$loading.show();
				vm.$store.dispatch('post', { uri: 'deleteProjectCost', data: project_cost })
					.then(response => {
						loader.hide();
						vm.$store.dispatch("success", response.data.message);
						vm.search();
					})
					.catch(error => {
						loader.hide();
						vm.$store.dispatch("error", error.response.data.errors.message);
					})
			}
		},

		addProjectCost() {
			this.$router.push('/BATS/' + this.meta.project_id + '/add_project_cost')
		},

		search() {
			let vm = this;
			vm.meta.page = 1;
			vm.getProjectCosts();
		},

		onPageChange(page) {
			this.meta.page = page;
			this.getProjectCosts();
		},

		sort(field) {
			this.meta.keyword = field;
			this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
			this.getProjectCosts();
		},

		onPerPageChange() {
			this.meta.page = 1;
			this.getProjectCosts();
		},

		selectAll() {
			if (this.select_all) {
				this.selected = [];
				this.project_costs.forEach(ele => this.selected.push(ele.project_cost_id));
			} else {
				this.selected = [];
			}
		},

		checkSelection() {
			if (this.selected.length == this.project_costs.length) {
				this.select_all = true;
			} else {
				this.select_all = false;
			}
		},

		bulkDelete(){
			if(!confirm('Selected project costs will be deleted permanently!')){
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post',{uri:'bulkDelete', data: {project_cost_ids: this.selected}})
			.then(response => {
				loader.hide();
				this.$store.dispatch('success',response.data.message);
				this.getProjectCosts();
				this.selected = [];
				this.errors = [];
			})
			.catch(error => {
				loader.hide();
				vm.errors = error.response.data.errors;
				vm.$store.dispatch('error',error.response.data.message.error);
			})
		}
	}
};
</script>
