<template>
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
        <div class="d-flex align-items-center gap-2 mt-5 mt-md-0">
            <h5 class="my-auto">Dashboard</h5>
            <div class="d-flex gap-2" v-if="$store.getters?.user?.role?.role_group?.role_group_code=='TM'">
                <router-link class="btn btn-sm btn-primary btn-sm " to="/admin_dashboard">Overall</router-link>
                <router-link class="btn btn-sm btn-primary btn-sm " to="/pmtchart">Daily</router-link>
            </div>
        </div>
        <div class="d-flex align-items-center gap-2 mt-5 mt-md-0">
            
            <div class="d-flex align-items-center gap-2">
                <label class="me-2">Cement Capacity : {{cement_capacity}}</label>
            </div>
            <div class="d-flex align-items-center" v-if="$store.getters.user?.role?.role_code=='HQAdmin'">
                <label class="me-2">Select&nbsp;Plan:</label>
                <select class="form-control form-control-sm" v-model="project_section_group.selected_plan">
                    <option value="overall_plan" selected>Overall Plan</option>
                    <option value="cerc_plan">CERC Plan</option>
                </select>
            </div>
            <div class="d-flex align-items-center">
                <label class="me-2">Project&nbsp;Groups: </label>
                <select class="form-control form-control-sm" v-model="project_section_group.project_group_id" @change="getProjects()">
                    <option value="">Select Project Groups</option>
                    <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                </select>
            </div>
            <div class="d-flex align-items-center">
                <label class="me-2">Projects: </label>
                <select class="form-control form-control-sm" v-model="project_section_group.project_id">
                    <option value="">Select Project</option>
                    <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name }}</option>
                </select>
            </div>
            <div class="d-flex align-items-center">
                <label class="me-2">Date: </label>
                <input type="date" class="form-control form-control-sm" v-model="project_section_group.job_date_time" />
            </div>
            <div class="d-flex gap-2">
                <button class="btn btn-sm btn-primary btn-sm " @click="getOverallEngineerig()">GET</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-3 mb-2">
            <div class="card card-one">
                <div class="card-body py-0">
                    <div class="row mb-3 mt-1">
                        <div class="col-sm-12">
                            <GaugeMeter
                                :container="'gaugemetera'"
                                :title="gauge_meter_a.title"
                                :max="gauge_meter_a.max"
                                :series="gauge_meter_a.series"
                                :type="gauge_meter_a.type"
                                :plot_bands="gauge_meter_a.plot_bands"
                                :actual="gauge_meter_a.actual"
                                :plan="gauge_meter_a.plan"
                                :label_font_size="gauge_meter_a.label_font_size"
                                ref="child"
                                :height="gauge_meter_a.height"
                                :width="gauge_meter_a.width"
                                :vartical_align="gauge_meter_a.vartical_align"
                            />
                        </div>
                        <div class="text-center">
                            <label class="f1">Baseline :&nbsp;</label>
                            <span class="f2" v-if="project_section_group.selected_plan=='overall_plan'">{{ changeDateFormat(project?.base_line_start_date) }} :: {{ changeDateFormat(project?.base_line_end_date) }}</span>
                            <span class="f2" v-else>{{ changeDateFormat(project?.cerc_start_date) }} :: {{ changeDateFormat(project?.cerc_end_date) }}</span>
                        </div>
                        <div class="text-center">
                            <label class="f1">Forecast :&nbsp;</label>
                            <span class="f2" v-if="project_section_group.selected_plan=='overall_plan'">{{ changeDateFormat(project?.forecast_start_date) }} :: {{ changeDateFormat(project?.forecast_end_date) }}</span>
                            <span class="f2" v-else>{{ changeDateFormat(project?.cerc_forecast_start_date) }} :: {{ changeDateFormat(project?.cerc_forecast_end_date) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9 mb-2">
            <div class="card card-one">
                <div class="card-body py-0">
                    <div class="row">
                            <div class="mb-1 col-xl-4">
                                <GaugeMeter
                                    :container="'gaugemeterb'"
                                    :title="gauge_meter_b.title"
                                    :max="gauge_meter_b.max"
                                    :series="gauge_meter_b.series"
                                    :type="gauge_meter_b.type"
                                    :plot_bands="gauge_meter_b.plot_bands"
                                    :actual="gauge_meter_b.actual"
                                    :plan="gauge_meter_b.plan"
                                    :label_font_size="gauge_meter_b.label_font_size"
                                    ref="child"
                                    :height="gauge_meter_b.height"
                                    :width="gauge_meter_b.width"
                                    :vartical_align="gauge_meter_b.vartical_align"
                                />
                                <div>
                                    <div class="text-center">
                                        <label class="f1">Baseline :&nbsp;</label>
                                        <span class="f2" v-if="project_section_group.selected_plan=='overall_plan'">{{ changeDateFormat(project?.engineering_start_date) }} :: {{ changeDateFormat(project?.engineering_end_date) }}</span>
                                        <span class="f2" v-else>{{ changeDateFormat(project?.engineering_cerc_start_date) }} :: {{ changeDateFormat(project?.engineering_cerc_end_date) }}</span>
                                    </div>
                                    <div class="text-center">
                                        <label class="f1">Forecast :&nbsp;</label>
                                        <span class="f2" v-if="project_section_group.selected_plan=='overall_plan'">{{ changeDateFormat(project?.engineering_forecast_start_date) }} :: {{ changeDateFormat(project?.engineering_forecast_end_date) }}</span>
                                        <span class="f2" v-else>{{ changeDateFormat(project?.engineering_cerc_forecast_start_date) }} :: {{ changeDateFormat(project?.engineering_cerc_forecast_end_date) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-1 col-xl-4">
                                <GaugeMeter
                                    :container="'gaugemeterc'"
                                    :title="gauge_meter_c.title"
                                    :max="gauge_meter_c.max"
                                    :series="gauge_meter_c.series"
                                    :type="gauge_meter_c.type"
                                    :plot_bands="gauge_meter_c.plot_bands"
                                    :actual="gauge_meter_c.actual"
                                    :plan="gauge_meter_c.plan"
                                    :label_font_size="gauge_meter_c.label_font_size"
                                    ref="child"
                                    :height="gauge_meter_c.height"
                                    :width="gauge_meter_c.width"
                                    :vartical_align="gauge_meter_c.vartical_align"
                                />
                                <div>
                                    <div class="text-center">
                                        <label class="f1">Baseline :&nbsp;</label>
                                        <span class="f2" v-if="project_section_group.selected_plan=='overall_plan'">{{ changeDateFormat(project?.procurement_start_date) }} :: {{ changeDateFormat(project?.procurement_end_date) }}</span>
                                        <span class="f2" v-else>{{ changeDateFormat(project?.procurement_cerc_start_date) }} :: {{ changeDateFormat(project?.procurement_cerc_end_date) }}</span>
                                    </div>
                                    <div class="text-center">
                                        <label class="f1">Forecast :&nbsp;</label>
                                        <span class="f2" v-if="project_section_group.selected_plan=='overall_plan'">{{ changeDateFormat(project?.procurement_forecast_start_date) }} :: {{ changeDateFormat(project?.procurement_forecast_end_date) }}</span>
                                        <span class="f2" v-else>{{ changeDateFormat(project?.procurement_cerc_forecast_start_date) }} :: {{ changeDateFormat(project?.procurement_cerc_forecast_end_date) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-1 col-xl-4">
                                <GaugeMeter
                                    :container="'gaugemeterd'"
                                    :title="gauge_meter_d.title"
                                    :max="gauge_meter_d.max"
                                    :series="gauge_meter_d.series"
                                    :type="gauge_meter_d.type"
                                    :plot_bands="gauge_meter_d.plot_bands"
                                    :actual="gauge_meter_d.actual"
                                    :plan="gauge_meter_d.plan"
                                    :label_font_size="gauge_meter_d.label_font_size"
                                    ref="child"
                                    :height="gauge_meter_d.height"
                                    :width="gauge_meter_d.width"
                                    :vartical_align="gauge_meter_d.vartical_align"
                                />
                                <div>
                                    <div class="text-center">
                                        <label class="f1">Baseline :&nbsp;</label>
                                        <span class="f2" v-if="project_section_group.selected_plan=='overall_plan'">{{ changeDateFormat(project?.construction_start_date) }} :: {{ changeDateFormat(project?.construction_end_date) }}</span>
                                        <span class="f2" v-else>{{ changeDateFormat(project?.construction_cerc_start_date) }} :: {{ changeDateFormat(project?.construction_cerc_end_date) }}</span>
                                    </div>
                                    <div class="text-center">
                                        <label class="f1">Forecast :&nbsp;</label>
                                        <span class="f2" v-if="project_section_group.selected_plan=='overall_plan'">{{ changeDateFormat(project?.construction_forecast_start_date) }} :: {{ changeDateFormat(project?.construction_forecast_end_date) }}</span>
                                        <span class="f2" v-else>{{ changeDateFormat(project?.construction_cerc_forecast_start_date) }} :: {{ changeDateFormat(project?.construction_cerc_forecast_end_date) }}</span>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
            <div class="col-sm-5 mb-2">
                <div class="card card-one">
                        <div class="card-body pb-0 mb-0">
                        <span>
                            <div class="row">
                                <div class="col-sm-9">
                                    <nav class="nav nav-pills">
                                        <a :class="overall_active" style="font-size: 10px;" @click="getOverallSCurve()" href="javascript:void(0)" class="nav-link"><i class="ri-line-chart-line me-1"></i>Overall</a>
                                       
                                        <a @click="getConstructionLineChart()" style="font-size: 10px;" :class="{active:construction_active}" href="javascript:void(0)" class="nav-link" >
                                            <i class="ri-line-chart-line me-1"></i>Construction
                                        </a>
                                        <a @click="getProjectSectionRCCLineChart()" style="font-size: 10px;" :class="{active:rcc_active}" href="javascript:void(0)" class="nav-link" >
                                            <i class="ri-line-chart-line me-1"></i>RCC
                                        </a>
                                        <a @click="getProjectSectionErectionLineChart()" style="font-size: 10px;" :class="{active:erc_active}" href="javascript:void(0)" class="nav-link">
                                            <i class="ri-line-chart-line me-1"></i>Erection
                                        </a>                        
                                    </nav>
                                </div>
                            </div>
                            
                        </span>
                        <LineChart
                            :title="scurvechart.title"
                            :container="'scurvechart'"
                            :subtitle="scurvechart.subtitle"
                            :backgroundColor="scurvechart.backgroundColor"
                            :gridLineColor="scurvechart.gridLineColor"
                            :thousandsSep="scurvechart.thousandsSep"
                            :decimalPoint="scurvechart.decimalPoint"
                            :height="scurvechart.height"
                            :width="scurvechart.width"
                            :yAxis="scurvechart.yAxis"
                            :xAxis="scurvechart.xAxis"
                            :series="scurvechart.series"
                            :crosshair="scurvechart.crosshair"
                            :customStyles="scurvechart.customStyles"
                            :plotOptions="scurvechart.plotOptions"
                            :label_font_size="scurvechart.label_font_size"
                            :marginTop="scurvechart.marginTop"
                            :marginBottom="scurvechart.marginBottom"
                        >
                        </LineChart>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 mb-2">
                <div class="card card-one">
                    <div class="card-body pb-0 mb-0">
                        <RccBarchart
                            :type="'column'"
                            :container="'barchartb'"
                            :title="barchartb.title"
                            :yAxis="barchartb.yAxis"
                            :xAxis="barchartb.xAxis"
                            :series="barchartb.series"
                            :stacking="barchartb.stacking"
                            :labels="barchartb.labels"
                            :xAxisLables="barchartb.xAxisLables"
                            :yAxisLables="barchartb.yAxisLables"
                            :label_font_size="barchartb.label_font_size"
                            :marginTop="barchartb.marginTop"
                            :height="barchartb.height"
                        >
                        </RccBarchart>
                    </div>
                </div>
            </div>

            <div class="col-sm-2 mb-2">
                <div class="card card-one">
                    <div class="card-body pb-0 mb-0">
                        <RccBarchart
                            :type="'column'"
                            :container="'barchartd'"
                            :title="barchartd.title"
                            :yAxis="barchartd.yAxis"
                            :xAxis="barchartd.xAxis"
                            :series="barchartd.series"
                            :stacking="barchartd.stacking"
                            :labels="barchartd.labels"
                            :xAxisLables="barchartd.xAxisLables"
                            :yAxisLables="barchartd.yAxisLables"
                            :label_font_size="barchartd.label_font_size"
                            :marginTop="barchartd.marginTop"
                            :height="barchartd.height"
                        >
                        </RccBarchart>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 mb-2">
                <div class="card card-one">
                    <div class="card-body pb-0 mb-0">
                        <ManpowerBarchart
                            :type="'bar'"
                            :container="'barchartc'"
                            :title="barchartc.title"
                            :subtitle="barchartc.subtitle"
                            :yAxis="barchartc.yAxis"
                            :xAxis="barchartc.xAxis"
                            :series="barchartc.series"
                            :stacking="barchartc.stacking"
                            :labels="barchartc.labels"
                            :xAxisLables="barchartc.xAxisLables"
                            :label_font_size="barchartc.label_font_size"
                            :height="barchartc.height">
                        </ManpowerBarchart>
                    </div>
                </div>
            </div>
    </div>
    <!-- <div class="row">
        <div class="col-sm-12" >
            <div style="float:right" class="d-flex">
                <a class="btn btn-primary btn-icon me-2"  @click.prevent="getPmtProjectUser(project)" href="#" title="PMT"><i class="ri-building-line icon_hgt"></i></a>
                <router-link class="btn btn-success btn-icon me-2" to="/daily_progress" title="DPR"><i class="ri-file-chart-fill icon_hgt"></i></router-link>
                <router-link class="btn btn-warning btn-icon me-2" to="/mechanical_summary" title="Mechanical Summary"><i class="ri-file-line icon_hgt"></i></router-link>
                <router-link class="btn btn-secondary btn-icon me-2" to="/civil_summary" title="Civil Summary"><i class="ri-file-edit-line icon_hgt"></i></router-link>
                <router-link class="btn btn-danger btn-icon me-2" to="/refractory" title="Refractory"><i class="ri-building-3-line icon_hgt"></i></router-link>
                <router-link class="btn btn-info btn-icon me-2" to="/hindrance" title="Hindrance"><i class="ri-article-line icon_hgt"></i></router-link>
                <router-link class="btn btn-warning btn-icon me-2" to="/drive_trial_status" title="Drive Trial Status"><i class="ri-survey-line icon_hgt"></i></router-link>
                <router-link class="btn btn-success btn-icon me-2" to="/electrical" title="Electrical"><i class="ri-store-3-line icon_hgt"></i></router-link>
                <router-link class="btn btn-secondary btn-icon me-2" to="/instrument" title="Instrument"><i class="ri-pages-line icon_hgt"></i></router-link>
                <router-link class="btn btn-info btn-icon me-2" to="/whrs" title="WHRS"><i class="ri-file-chart-line icon_hgt"></i></router-link>
            </div>
        </div>
    </div> -->
</template>
<script>
    import { Chart } from "highcharts-vue";
    import { ColumnChart } from "highcharts-vue";
    import Heatmap from "@/components/charts/Heatmap";
    import Donut from "@/components/charts/Donut";
    import BarChart from "@/components/charts/BarChart";
    import RccBarchart from "@/components/charts/RccBarchart";
    import ManpowerBarchart from "@/components/charts/ManpowerBarchart";
    import GaugeMeter from "@/components/charts/GaugeMeter";
    import LineChart from "@/components/charts/LineChart";
    import moment from "moment";
    import SolidGauge from "@/components/charts/SolidGauge.vue";
    import router from "@/router";
    let Search = require("@/components/Search.vue").default;

    export default {
        name: "Dashboard",
        components: {
            highcharts: Chart,
            ColumnChart,
            Heatmap,
            Donut,
            BarChart,
            GaugeMeter,
            LineChart,
            SolidGauge,
            Search,
            RccBarchart,
            ManpowerBarchart
        },
        data() {
            return {
                initial:true,
                overall_active: "active",
                col_length: "col-sm-4",
                rcc_active: false,
                fab_active:false,
                erc_active:false,
                construction_active:false,
                project: {},
                empty_project:{},
                cement_capacity:"0.0",
                project_section_group: {
                    current_month_year: "",
                    is_calculation: "",
                    project_id: "",
                    project_code: "",
                    selected_plan:"overall_plan",
                    project_group_id:'',
                    job_date_time:'',
                },
                project_section: {
                    overall_plan_project_section_id: "",
                    actual_plan_project_section_id: "",
                    current_month_year: "",
                },
                scurve:{
                    project_id:"",
                    job_date_time:''
                },
                projectgroups: [],
                projects: [],
                project_activities: [],
                project_activity_group_id: "",
                overall_plan_project_sections: [],
                actual_plan_project_sections: [],
                overall_plan_project_activities: [],
                actual_plan_project_activities: [],
                over_all_weightage: "",
                type: "",
                overall_scope: "",
                engineering_scope: "",
                procurement_scope: "",
                construction_scope: "",
                chartOptions: {
                    series: [
                        {
                            data: [1, 2, 3], // sample data
                        },
                    ],
                },
                gauge_meter_empty: {
                    title: "",
                    type: "solidgauge",
                    max: 0,
                    label_font_size: "12px",
                    plot_bands: [
                        {
                            from: 0,
                            to: 100,
                            color: "#C0C0C0",
                            thickness: 15,
                        },
                    ],
                    actual_plan: [0],
                    overall_plan: [0],
                    base_line_start_date: "",
                    base_line_end_date: "",
                    marginTop: "50"
                },

                // chart: {
                //     xAxis: {
                //         categories: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007],
                //     },
                //     series: [
                //         {
                //             name: "Installation",
                //             color: "#03bbd4",
                //             data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
                //         },
                //         {
                //             name: "Manufacturing",
                //             color: "#f7a35c",
                //             data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
                //         },
                //     ],
                // },

                // Project Dashboard TM
                donuta: {
                    title: "3",
                    max: 0,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            dataLabels: {
                                enabled: true,
                                format: "{y}",
                            },
                            showInLegend: true,
                        },
                        series: {
                            borderWidth: 0,
                            colorByPoint: true,
                            type: "pie",
                            size: "100%",
                            innerSize: "80%",
                            dataLabels: {
                                enabled: true,
                                crop: false,
                                distance: "-10%",
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                },
                                connectorWidth: 0,
                            },
                            events: {
                                click: function (e) {
                                    e.preventDefault(); // prevent any action from occuring on "click" event
                                },
                            },
                        },
                    },
                    series: [
                        {
                            name: "Delay",
                            y: 2,
                            color: "#f70000",
                        },
                        {
                            name: "On Track",
                            y: 1,
                            color: "#7bf700",
                        },
                    ],
                },
                gaugemeterb: {
                    title: "Overall",
                    type: "solidgauge",
                    max: 0,
                    series: [71],
                },

                barcharta: {
                    title: "Cost PTD",
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "",
                    labels: "",
                    height: "170%",
                    label_font_size: "12px",
                    marginTop: 50,
                    series: [
                        {
                            name: "Sanction",
                            data: [],
                            color: "#26aaac",
                        },
                        {
                            name: "Committed",
                            data: [],
                            color: "#198de4",
                        },
                        {
                            name: "Budget Spend",
                            data: [],
                            color: "#bbad02",
                        },
                    ],
                },
                barchartb: {
                    title: "RCC",
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "",
                    labels: "",
                    label_font_size: "12px",
                    marginTop: 15,
                    height: "145%",
                    series: [],
                },
                barchartc:{
                    title:"Manpower",
                    subtitle: {
                        text: "",
                    },
                    xAxis: ['Civil', 'Mech', 'E&I'], 
                    xAxisLables:{
                        enabled: true
                    },
                    label_font_size: "12px",
                    stacking:"",
                    labels:"",
                    height: "90%",
                    series: []
                },

                barchartd: {
                    title: "Erection",
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "",
                    labels: "",
                    label_font_size: "12px",
                    marginTop: 15,
                    height: "150%",
                    series: [],
                },

                scurvechart: {
                    title:"",
                    subtitle: "S-Curve for Planned vs Actual",
                    backgroundColor: "#fff",
                    gridLineColor: "#ccd6eb",
                    thousandsSep: ".",
                    decimalPoint: ",",
                    xAxis: {
                        type: "datetime",
                    },
                    yAxis: {},
                    marginBottom:50,
                    series: [
                        {
                            name: "Planned",
                            marker: {
                                symbol: "circle",
                            },
                            color: '#03bbd4',
                            data: []
                        },
                        {
                            name: "Actual",
                            marker: {
                                symbol: "circle",
                            },
                            color: '#FFA500',
                            data: []
                        },
                    ],
                    plotOptions: {
                        series: {},
                    },

                    crosshair: true,
                    customStyles: {
                        color: "#666",
                        fontFamily: "Nunito Sans",
                    },
                    label_font_size: "12px",
                    marginTop: 15,
                    height: "48%"
                },
                actual_plan: [],
                overall_plan: [],
                overall_plan_data: [],
                actual_plan_data: [],
                empty_project_activity: ["Engineering", "Procurement", "Construction"],
                barchart:{
                    job_date_time:'',
                    project_id:'',
                    selected_plan:"overall_plan",
                },
                gauge_actual:[],
                gauge_overall:[],

                gauge_meter_a: {
                    title: "Overall",
                    type: "solidgauge",
                    max: 0,
                    label_font_size: "12px",
                    project_parameter_length: "",
                    plot_bands: [
                        {
                            from: 0,
                            to: 100,
                            color: "#C0C0C0",
                            thickness: 15,
                        },
                    ],
                    actual: [],
                    plan: [],
                    base_line_start_date: "",
                    base_line_end_date: "",
                    completion_days: "",
                    height: "50%",
                    marginTop: 50,
                    vartical_align:'top'
                },
                gauge_meter_c: {
                    title: "Overall Procurement",
                    type: "solidgauge",
                    max: 0,
                    label_font_size: "12px",
                    project_parameter_length: "",
                    plot_bands: [
                        {
                            from: 0,
                            to: 100,
                            color: "#C0C0C0",
                            thickness: 15,
                        },
                    ],
                    actual: [],
                    plan: [],
                    base_line_start_date: "",
                    base_line_end_date: "",
                    completion_days: "",
                    height: "45%",
                    marginTop: 50,
                    vartical_align:'top'
                },
                gauge_meter_b: {
                    title: "Overall Engineering",
                    type: "solidgauge",
                    max: 0,
                    label_font_size: "12px",
                    project_parameter_length: "",
                    plot_bands: [
                        {
                            from: 0,
                            to: 100,
                            color: "#C0C0C0",
                            thickness: 15,
                        },
                    ],
                    actual: [],
                    plan: [],
                    base_line_start_date: "",
                    base_line_end_date: "",
                    completion_days: "",
                    height: "45%",
                    marginTop: 50,
                    vartical_align:'top'
                },
                gauge_meter_d: {
                    title: "Overall Construction",
                    type: "solidgauge",
                    max: 0,
                    label_font_size: "12px",
                    project_parameter_length: "",
                    plot_bands: [
                        {
                            from: 0,
                            to: 100,
                            color: "#C0C0C0",
                            thickness: 15,
                        },
                    ],
                    actual: [],
                    plan: [],
                    base_line_start_date: "",
                    base_line_end_date: "",
                    completion_days: "",
                    height: "45%",
                    marginTop: 50,
                    vartical_align:'top',
                },
            };
        },

        mounted() {
            this.getProjectGroups()
            this.project_section_group.job_date_time = moment().format("YYYY-MM-DD");
            this.barchart.job_date_time = moment().format("YYYY-MM-DD");
            
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (from.name == "login") {
                    vm.$router.go();
                }
            });
        },

        methods: {
            calculateProjectExecution(){
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "calculateProjectExecution", data:vm.project_section_group };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", "Calculations updated successfully");
                        vm.getOverallSCurve();
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error);
                    });
            },
            changeDateFormat(date){
                return moment(date).format('DD-MMM-YYYY')
            },
            getPlotBands(actual, plan) {
                //Plot band calculation
                let plot_bands = [];
                let plot_band_calculation = (actual / plan) * 100;
                let plot_band_to = "";
                let plot_band_color = "";
                if (plan[0] <= actual[0]) {
                    plot_band_to = parseInt(actual[0]);
                    plot_band_color = "green";
                } else if (plot_band_calculation < 75) {
                    plot_band_to = parseInt(plot_band_calculation);
                    // plot_band_color = "red";
                    plot_band_color = "green";
                } else if (plot_band_calculation >= 75 && plot_band_calculation < 89) {
                    plot_band_to = parseInt(plot_band_calculation);
                    // plot_band_color = "yellow";
                    plot_band_color = "green";
                } else if (plot_band_calculation >= 89 && plot_band_calculation <= 100) {
                    plot_band_to = parseInt(plot_band_calculation);
                    plot_band_color = "green";
                }
                
                plot_bands.push(
                    {
                        from: 0,
                        to: actual,
                        color: plot_band_color,
                        thickness: 15,
                    },
                    {
                        from: actual,
                        to: 100,
                        color: "#C0C0C0",
                        thickness: 15,
                    }
                );
                if(actual > plan){
                    
                    plot_bands.push(                        
                        {
                            from: 0,
                            to: actual,
                            color: "green",
                            thickness: 15,
                        }
                    )
                }
                else{
                    plot_bands.push(                        
                        {
                            from: actual,
                            to: plan,
                            color: "black",
                            thickness: 15,
                        }
                    )
                }
                return plot_bands;
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response?.data?.data
                        if(localStorage.getItem('project_group_id')){
                            vm.project_section_group.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.project_section_group.project_group_id) {
                                vm.project_section_group.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjects() {
                let vm = this
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                let uri = { uri: "getProjectGroupProjects", data:vm.project_section_group};
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projects = response.data
                        if(localStorage.getItem('project_id')){
                            vm.project_section_group.project_id = localStorage.getItem('project_id')
                        }else if(vm.projects.length && !localStorage.getItem('project_id')){
                            vm.project_section_group.project_id = vm.projects[0].project_id
                        }
                        if(vm.initial){
                            vm.getOverallEngineerig()
                            vm.initial = false
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            
            getOverallEngineerig(){
                let vm = this
                localStorage.setItem('project_group_id', this.project_section_group.project_group_id)
                localStorage.setItem('project_id', this.project_section_group.project_id)
                vm.project_section_group.current_month_year = moment(vm.project_section_group.job_date_time).format("MMM-YY")
                let uri = { uri: "getOverallGaugeGraph", data: vm.project_section_group  };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    let data = response.data 
                    vm.project = response.data.project;              
                   
                   vm.gauge_meter_a.actual = [];
                    vm.gauge_meter_a.plan = [];
                    vm.gauge_meter_b.actual = [];
                    vm.gauge_meter_b.plan = [];
                    vm.gauge_meter_c.actual = [];
                    vm.gauge_meter_c.plan = [];
                    vm.gauge_meter_d.actual = [];
                    vm.gauge_meter_d.plan = [];

                    let overall_plan_value = (response.data.engineering.overall_plan.total_value * data.engineering.overall_plan.total_scope) + (data.procurement.overall_plan.total_value * data.procurement.overall_plan.total_scope) + (data.delivery.overall_plan.total_value * data.delivery.overall_plan.total_scope) + (data.construction.overall_plan.total_value * data.construction.overall_plan.total_scope) + (data.commissioning.overall_plan.total_value * data.commissioning.overall_plan.total_scope) + (data.pre_project.overall_plan.total_value * data.pre_project.overall_plan.total_scope)
                    let plan_scope = data.engineering.overall_plan.total_scope + data.procurement.overall_plan.total_scope + data.delivery.overall_plan.total_scope + data.construction.overall_plan.total_scope + data.commissioning.overall_plan.total_scope + data.pre_project.overall_plan.total_scope
                    let overall_plan = Math.round(overall_plan_value/plan_scope);

                    let overall_actual_value = (response.data.engineering.actual_plan.total_value * data.engineering.actual_plan.total_scope) + (data.procurement.actual_plan.total_value * data.procurement.actual_plan.total_scope) + (data.delivery.actual_plan.total_value * data.delivery.actual_plan.total_scope) + (data.construction.actual_plan.total_value * data.construction.actual_plan.total_scope) + (data.commissioning.actual_plan.total_value * data.commissioning.actual_plan.total_scope) + (data.pre_project.actual_plan.total_value * data.pre_project.actual_plan.total_scope)
                    let actual_scope = data.engineering.actual_plan.total_scope + data.procurement.actual_plan.total_scope + data.delivery.actual_plan.total_scope + data.construction.actual_plan.total_scope + data.commissioning.actual_plan.total_scope + data.pre_project.actual_plan.total_scope
                    let overall_actual =  Math.round(overall_actual_value/actual_scope);
                    
                    vm.gauge_meter_a.plan.push(overall_plan);
                    vm.gauge_meter_a.actual.push(overall_actual);
                    vm.gauge_meter_a.plot_bands = vm.getPlotBands(overall_actual, overall_plan);

                    vm.gauge_meter_b.plan.push(response.data.engineering.overall_plan.total_value);
                    vm.gauge_meter_b.actual.push(response.data.engineering.actual_plan.total_value);
                    vm.gauge_meter_b.plot_bands = vm.getPlotBands(response.data.engineering.actual_plan.total_value, 
                            response.data.engineering.overall_plan.total_value);

                    vm.gauge_meter_c.plan.push(response.data.procurement.overall_plan.total_value);
                    vm.gauge_meter_c.actual.push(response.data.procurement.actual_plan.total_value);
                    vm.gauge_meter_c.plot_bands = vm.getPlotBands(response.data.procurement.actual_plan.total_value, 
                            response.data.procurement.overall_plan.total_value);

                    vm.gauge_meter_d.plan.push(response.data.construction.overall_plan.total_value);
                    vm.gauge_meter_d.actual.push(response.data.construction.actual_plan.total_value);
                    vm.gauge_meter_d.plot_bands = vm.getPlotBands(response.data.construction.actual_plan.total_value, 
                            response.data.construction.overall_plan.total_value);

                    vm.getOverallSCurve();
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            gauge_graph_calculation(plan, plan_type) {
                let vm = this;
                let overall_weightage = 0;
                let overall_parameter_value= 0;
                let actual_weightage = 0;
                let actual_parameter_value = 0;
                let result = 0;

                if(plan_type=='actual_plan'){                    
                    plan?.project_activity?.map(function (project_activity) {
                        actual_weightage = actual_weightage + Number(project_activity.scope)
                    });
                    plan?.project_parameters_latest?.map(function (pp_latest) {
                        let total_scope = Number(pp_latest.job_parameter?.parameter_value) * Number(pp_latest.job_parameter?.project_activity?.scope)
                        if(isNaN(total_scope)){
                            total_scope = 0
                        }
                        actual_parameter_value = actual_parameter_value + total_scope
                    });
                    result = actual_parameter_value / actual_weightage;

                    if(isNaN(result)){
                        result = 0
                    }
                }
                else{
                    plan?.project_activity?.map(function (project_activity) {
                        overall_weightage = overall_weightage + Number(project_activity.scope)
                    });
                    plan?.project_parameters_latest?.map(function (pp_latest) {
                        let total_scope = Number(pp_latest.job_parameter?.parameter_value) * Number(pp_latest.job_parameter?.project_activity?.scope)
                        if(isNaN(total_scope)){
                            total_scope = 0
                        }
                        overall_parameter_value = overall_parameter_value + total_scope
                    });
                    result = overall_parameter_value / overall_weightage;
                    if(isNaN(result)){
                        result = 0
                    }
                }
                        
                plan?.project_sections?.map(function (project_section) {
                    let oneDay = 24 * 60 * 60 * 1000;
                    if (project_section.section.section_name == "Engineering") {
                        let start_date = new Date();
                        let end_date = new Date(vm.project.engineering_forecast_end_date);
                        let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                        project_section.completion_days = Daysdiff;
                    } 
                    else if (project_section.section.section_name == "Procurement") {
                        let start_date = new Date();
                        let end_date = new Date(vm.project.procurement_forecast_end_date);
                        let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                        project_section.completion_days = Daysdiff;
                    } 
                    else if (project_section.section.section_name == "Construction") {
                        let start_date = new Date();
                        let end_date = new Date(vm.project.construction_forecast_end_date);
                        let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                        project_section.completion_days = Daysdiff;
                    }
                });
                return result;
            },
            
            getOverallSCurve(){
                let vm = this;
                let loader = vm.$loading.show()
                vm.overall_active = "active"
                vm.rcc_active= false;
                vm.erc_active= false;
                vm.fab_active= false;
                vm.construction_active=false
                vm.project_section_group.current_month_year = moment(vm.project_section_group.job_date_time).format("MMM-YY");
                let uri = { uri: "getOverallSCurve", data: vm.project_section_group };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.scurvechart.series = []
                        vm.scurvechart.xAxis.categories = response.data.overall_plan.month_year
                        vm.scurvechart.series.push({
                            type: 'line',
                            name: 'Planned',
                            marker: {
                                symbol: "circle",
                            },
                            color: '#03bbd4',
                            data: response.data.overall_plan.parameter_values,
                            tooltip: {
                                valueSuffix: "%",
                            },
                        });
                        vm.scurvechart.series.push({
                            type: 'line',
                            name: 'Actual',
                            marker: {
                                symbol: "circle",
                            },
                            color: '#FFA500',
                            data: response.data.actual_plan.parameter_values,
                            tooltip: {
                                valueSuffix: "%",
                            },
                        });
                        vm.getRccBarchart();
                        loader.hide();
                    })
                    .catch(function (error) {
                        console.log(error);
                        loader.hide();
                    });
            },

            calculateplan(plan_data, plan_name, line_color) {
                let vm = this;
                let data = [];
                if(plan_data.project_parameters)
                {
                    for (let [key, value] of Object.entries(plan_data.project_parameters)) {
                        let parameter_scope_value = 0;
                        let date_time = "01-" + value[0].parameter.parameter_code;
                        let parameter_value = 0;
                        let scope_value = 0;
                        let parameter_scope_multiply = 0;
                        let over_all_weightage = 0;
                        for (let i = 0; i < value.length; i++) {                            
                            if (value[i].job_parameter) {
                                if (value[i].job_parameter.parameter_value) {
                                    parameter_value = value[i].job_parameter.parameter_value;
                                    scope_value = value[i].job_parameter.project_activity.scope;
                                    parameter_scope_multiply = parameter_value * scope_value;
                                    parameter_scope_value = parameter_scope_value + parameter_scope_multiply;
                                    over_all_weightage = over_all_weightage + Number(scope_value);
                                }
                            }
                        }
                        let date_time1 = new Date(date_time);
                        let d1 = date_time1.getDate();
                        let d2 = date_time1.getUTCMonth() + 1;
                        let d3 = date_time1.getUTCFullYear();
                        data.push([Date.UTC(d3, d2, d1), parameter_scope_value / over_all_weightage]);
                    }

                    vm.overall_plan_data = data;
                    vm.scurvechart.series.push({
                        name: plan_name,
                        marker: {
                            symbol: "circle",
                        },
                        color: line_color,
                        data: data
                    });
                }
            },

            getProjectSection(project_section) {
                let vm = this;
                project_section.is_active = "active";
                vm.overall_active = "";
                vm.actual_plan_project_sections.map(function (element) {
                    if (element.project_section_id != project_section.project_section_id) {
                        element.is_active = "";
                    }
                });
                vm.project_section.current_month_year = moment(vm.project_section_group.job_date_time).format("MMM-YY");
                vm.project_section.actual_plan_project_section_id = project_section.project_section_id;
                vm.overall_plan_project_sections.map(function (overall_plan_project_section) {
                    if (project_section.section_id == overall_plan_project_section.section_id) {
                        vm.project_section.overall_plan_project_section_id = overall_plan_project_section.project_section_id;
                    }
                });
                vm.project_section.selected_plan = vm.project_section_group.selected_plan

                let uri = { uri: "getOverallSectionPlan", data: vm.project_section };
                vm.$store.dispatch("post", uri).then(function (response) {
                    vm.project_activity_group_id = "";
                    vm.gauge_meter_a.title = response.data.overall_plan.section.section_name;
                    vm.project_activities = response.data.actual_plan.project_activity;
                    vm.overall_plan_project_activities = response.data.overall_plan.project_activities;
                    vm.actual_plan_project_activities = response.data.actual_plan.project_activities;
                    vm.gauge_meter_a.actual = [];
                    vm.gauge_meter_a.plan = [];
                    vm.gauge_meter_b.actual_plan = [];
                    vm.gauge_meter_b.overall_plan = [];
                    if (vm.project_activities.length) {
                        let len = 12 / vm.project_activities.length;
                        vm.col_length = "col-sm-" + len;
                    }

                    vm.gauge_meter_a.actual.push(vm.gaugeProjectActivitiesCalculation(response.data.actual_plan, "actual_plan"));
                    vm.gauge_meter_a.plan.push(vm.gaugeProjectActivitiesCalculation(response.data.overall_plan, "overall_plan"));
                    vm.gauge_meter_a.title = response.data.overall_plan.section.section_name;

                    let oneDay = 24 * 60 * 60 * 1000;
                    let start_date = new Date();
                    let end_date = new Date(vm.project.forecast_end_date);

                    let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                    vm.gauge_meter_b.completion_days = Daysdiff;

                    //Plot band calculation
                    vm.gauge_meter_a.plot_bands = vm.getPlotBands(vm.gauge_meter_a.actual, vm.gauge_meter_a.plan);
                    vm.actual_plan = response.data.actual_plan;
                    vm.overall_plan = response.data.overall_plan;
                    vm.scurvechart.series = [];
                    vm.calculateplan(vm.overall_plan, "Planned", "#03bbd4");
                    vm.calculateplan(vm.actual_plan, "Actual", "#f60000");
                });
            },

            getConstructionLineChart(){
                let vm = this;
                vm.rcc_active= false;
                vm.erc_active= false;
                vm.fab_active= false;
                vm.construction_active=true
                vm.overall_active = false
                vm.scurvechart.series = [];
                let loader = vm.$loading.show();
                vm.scurve.project_id = vm.project_section_group.project_id
                vm.scurve.job_date_time = moment(vm.project_section_group.job_date_time).format('YYYY-MM-DD')
                vm.project_section.current_month_year = moment(vm.project_section_group.job_date_time).format("MMM-YY");
                vm.scurve.selected_plan = vm.project_section_group.selected_plan
                
                let uri = { uri: "getOverallSectionPlan", data: vm.scurve};
                vm.$store.dispatch("post", uri).then(function (response) {
                    vm.scurvechart.series = [];

                    vm.scurvechart.xAxis.categories = response.data.overall_plan.month_year
                    vm.scurvechart.series.push({
                        type: 'line',
                        name: 'Planned',
                        marker: {
                            symbol: "circle",
                        },
                        color: '#03bbd4',
                        data: response.data.overall_plan.parameter_values,
                        tooltip: {
                            valueSuffix: "%",
                        },
                    });
                    vm.scurvechart.series.push({
                        type: 'line',
                        name: 'Actual',
                        marker: {
                            symbol: "circle",
                        },
                        color: '#FFA500',
                        data: response.data.actual_plan.parameter_values,
                        tooltip: {
                            valueSuffix: "%",
                        },
                    });
                    loader.hide();
                
                });
            },

            getProjectSectionRCCLineChart(){
                let vm = this;
                vm.overall_active="";
                vm.rcc_active= true;
                vm.erc_active= false;
                vm.fab_active= false;
                vm.construction_active=false;
                let loader = vm.$loading.show();
                vm.scurve.project_id = vm.project_section_group.project_id
                vm.scurve.job_date_time = moment(vm.project_section_group.job_date_time).format('YYYY-MM-DD')
                vm.project_section.current_month_year = moment(vm.project_section_group.job_date_time).format("MMM-YY");
                vm.scurve.selected_plan = vm.project_section_group.selected_plan
                
                let uri = { uri: "rccSCurve", data: vm.scurve};
                vm.$store.dispatch("post", uri).then(function (response) {
                    vm.scurvechart.series = [];

                    vm.scurvechart.xAxis.categories = response.data.rcc_plan.month_year
                    vm.scurvechart.series.push({
                        type: 'line',
                        name: 'Planned',
                        marker: {
                            symbol: "circle",
                        },
                        color: '#03bbd4',
                        data: response.data.rcc_plan.parameter_values
                    });
                    vm.scurvechart.series.push({
                        type: 'line',
                        name: 'Actual',
                        marker: {
                            symbol: "circle",
                        },
                        color: '#FFA500',
                        data: response.data.rcc_actual.parameter_values
                    });
                    loader.hide()
                
                });
            },

            getProjectSectionFabricationLineChart(){
                let vm = this;
                vm.overall_active="";
                vm.rcc_active= false;
                vm.erc_active= false;
                vm.fab_active= true;
                vm.construction_active=false;
                let loader = vm.$loading.show();
                vm.scurve.project_id = vm.project_section_group.project_id
                vm.scurve.job_date_time = moment(vm.project_section_group.job_date_time).format('YYYY-MM-DD')
                vm.project_section.current_month_year = moment(vm.project_section_group.job_date_time).format("MMM-YY");
                vm.scurve.selected_plan = vm.project_section_group.selected_plan
                
                let uri = { uri: "fabricationSCurve", data: vm.scurve};
                vm.$store.dispatch("post", uri).then(function (response) {
                    vm.scurvechart.series = [];

                    vm.scurvechart.xAxis.categories = response.data.month_year
                    vm.scurvechart.series.push({
                        type: 'line',
                        name: 'Planned',
                        marker: {
                            symbol: "circle",
                        },
                        color: '#03bbd4',
                        data: response.data.fab_plan
                    });
                    vm.scurvechart.series.push({
                        type: 'line',
                        name: 'Actual',
                        marker: {
                            symbol: "circle",
                        },
                        color: '#f60000',
                        data: response.data.fab_actual
                    });
                    loader.hide()
                });

            },

            getProjectSectionErectionLineChart(){
                let vm = this;
                vm.overall_active="";
                vm.rcc_active= false;
                vm.erc_active= true;
                vm.fab_active= false;
                vm.construction_active=false;

                let loader = vm.$loading.show();

                let date = new Date();
                vm.scurve.project_id = vm.project_section_group.project_id
                vm.scurve.job_date_time = moment(vm.project_section_group.job_date_time).format('YYYY-MM-DD')
                vm.project_section.current_month_year = moment(vm.project_section_group.job_date_time).format("MMM-YY");
                vm.scurve.selected_plan = vm.project_section_group.selected_plan
                
                let uri = { uri: "erectionSCurve", data: vm.scurve};
                vm.$store.dispatch("post", uri).then(function (response) {
                    vm.scurvechart.series = [];

                    vm.scurvechart.xAxis.categories = response.data.erection_plan.month_year
                    vm.scurvechart.series.push({
                        type: 'line',
                        name: 'Planned',
                        marker: {
                            symbol: "circle",
                        },
                        color: '#03bbd4',
                        data: response.data.erection_plan.parameter_values
                    });
                    vm.scurvechart.series.push({
                        type: 'line',
                        name: 'Actual',
                        marker: {
                            symbol: "circle",
                        },
                        color: '#FFA500',
                        data: response.data.erection_actual.parameter_values
                    });
                    loader.hide()
                });
            },

            gaugeProjectActivitiesCalculation(plan, plan_type) {
                let vm = this;
                vm.over_all_weightage = 0;
                for (let j = 0; j < plan.project_activity.length; j++) {
                    vm.over_all_weightage = vm.over_all_weightage + Number(plan.project_activity[j].scope);
                }

                let project_activity_values = [];
                plan.project_activity.map(function (project_activity) {
                    let job_parameter_values = [];

                    project_activity.project_parameter_latest.map(function (project_parameter) {
                        if (project_parameter.job_parameter) {
                            if (project_parameter.job_parameter?.parameter_value) {
                                let parameter_value = project_parameter.job_parameter.parameter_value * project_activity.scope;
                                project_activity_values.push(parameter_value);
                                job_parameter_values.push(parameter_value / project_activity.scope);
                            }
                        }
                    });

                    let param_sum = job_parameter_values.reduce((total, num) => total + num, 0);
                    if (plan_type == "actual_plan") vm.gauge_meter_b.actual_plan.push(param_sum);
                    else vm.gauge_meter_b.overall_plan.push(param_sum);

                });

                let values = [];

                values.push({ actual: vm.gauge_meter_b.actual_plan, plan: vm.gauge_meter_b.overall_plan });

                for (let j = 0; j < values.length; j++) {
                    let plot_band_calculation = (values[j].actual[j] / values[j].plan[j]) * 100;
                }

                let result = project_activity_values.reduce((total, num) => total + num, 0);
                let result1 = result / vm.over_all_weightage;
                if(isNaN(result1)){
                    result1 = 0
                }
                return result1;
            },
            getRccBarchart(){
                let vm = this;
                vm.barchart.job_date_time = vm.project_section_group.job_date_time
                vm.barchart.project_id = vm.project_section_group.project_id
                vm.barchart.selected_plan = vm.project_section_group.selected_plan
                let uri = { uri: "rccBarChart", data: vm.barchart };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.barchartb.series = [
                        {
                            name: "Scope",
                            data: [response.data.scope],
                            color: "#03bbd4",
                        },
                        {
                            name: "CTD",
                            data: [response.data.rcc_complete],
                            color: "#FFA500",
                        },
                        {
                            name: "Balance",
                            data: [response.data.rcc_balance],
                            color: "#899499",
                        },
                    ]
                    vm.getErectionBarchart();
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            getErectionBarchart(){
                let vm = this;
                vm.barchart.project_id = vm.project_section_group.project_id
                vm.barchart.selected_plan = vm.project_section_group.selected_plan
                let uri = { uri: "erectionBarChart", data: vm.barchart };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.barchartd.series = [
                        {
                            name: "Scope",
                            data: [response.data.scope],
                            color: "#03bbd4",
                        },
                        {
                            name: "CTD",
                            data: [response.data.Er_completed],
                            color: "#FFA500",
                        },
                        {
                            name: "Balance",
                            data: [response.data.Er_balance],
                            color: "#899499",
                        },
                    ]
                    vm.getManpowerBarchart();
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getManpowerBarchart(){
                let vm = this;
                vm.barchart.project_id = vm.project_section_group.project_id
                let uri = { uri: "manpowerBarChart", data: vm.barchart };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.barchartc.series=[
                        {
                            name: 'Available',
                            data: response.data.manpower_available,
                            color: '#f7a35c',
                        }, 
                        {
                            name: 'Required',
                            data: response.data.manpower_required,                            
                            color: '#03bbd4',
                        },
                    ]
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getProject(){
                let vm = this;
                let uri = { uri: "getProject", data: vm.project_section_group };
                
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    if(response.data.data.cement_capacity != null){
                        vm.cement_capacity = response.data.data.cement_capacity?.capacity
                    }else{
                        vm.cement_capacity = "0.0"
                    }
                    
                    vm.empty_project=response.data.data;
                    vm.gauge_meter_empty.base_line_start_date=response.data.data.base_line_start_date;
                    vm.gauge_meter_empty.base_line_end_date=response.data.data.base_line_end_date;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getPmtProjectUser(project){
                let vm=this;
                vm.$router.push('/PMT/' + project.project_id + '/user')
            },
            getPmtProjectDpr(){
                let vm=this;
                vm.$router.push('/daily_progress')
            },
        },
    };
</script>
<style scoped>
    .justify-content-space-around {
        justify-content: space-around;
    }
    .f1 {
        font-size: 9pt;
        font-weight: bold;
    }
    .f2 {
        font-size: 10pt;
    }
    .fontsize {
        font-size: 12px;
        font-weight: bold;
    }
    .card-one {
        border: 1px solid #e2e5ec;
    }

    .table-sm > :not(caption) > * > * {
        padding: 0rem;
    }
</style>
