<template>
    <div class="row g-2 g-xxl-3 mb-5">
        <div :class="col1">
            <div class="card">
                <div class="card-header">
                    <strong>{{ project?.project_name }}</strong>
                    <div style="float: right;" class="d-flex" v-if="!activity_group_div">
                        <button class="btn btn-warning btn-sm" @click="restore()"><i class="ri-fullscreen-exit-line size"></i></button>
                    </div>
                </div>
                <div class="card-body" v-if="activity_group_div">
                    <div>
                        <PMTTree class="item" :item="project" :sections="section" />
                        <span><input type="radio" class="radio_button1" name="upload_image" @click="showImages()">&nbsp;Show Images</span><br/>
                         <span><input type="radio" ref="email_recipients" name="email_recipients" @click="showEmailRecipients()">&nbsp;Email Recipients</span>
                    </div>
                </div>
            </div>
        </div>
        <div :class="col2">
            <div :class="{invisible:!show}">
                <ProjectSectionGroups v-if="projectsection_groups_check" :project="project"
                        :is_get_button="is_get_button" :activity_group_div="activity_group_div" :item="item_data"  />

                <ProjectSections v-if="project_section_groups_check" :project="project"
                        :is_get_button="is_get_button" :activity_group_div="activity_group_div" :item="item_data"/>

                <ProjectActivities v-if="project_activity_check" :project="project" :is_get_button="is_get_button" 
                        :activity_group_div="activity_group_div" :item="item_data"/>   

                <Images v-if="show_image_check" :project="project"/> 

                <EmailRecipients v-if="show_email_recipients_check" :project="project"/>                                        
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import PMTTree from "@/components/tree/PMTTree.vue";
    import ProjectSectionGroups from "@/components/project/ProjectSectionGroups.vue";
    import ProjectActivities from "@/components/project/ProjectActivities.vue";
    import ProjectSections from "@/components/project/ProjectSections.vue";
    import Images from "@/components/project/Images.vue";
    import EmailRecipients from "@/components/project/EmailRecipients.vue";
    

    export default {
        components: { PMTTree, ProjectSectionGroups, ProjectActivities, ProjectSections, Images, EmailRecipients },
        data() {
            return {
                projectsection_groups_check:false,
                project_section_groups_check:false,
                project_activity_check:false,
                show_image_check:false,
                show_email_recipients_check:false,
                project: {
                    job_date_time: "",
                    date_time:'',
                    project_id: "",
                    type: "pmt_user",
                    is_manual: true,
                    project_section_groups: [],
                    project_sections: [],
                    project_activities: [],
                    project_activity_group_id: "",
                    project_section_group:{},
                    image:"",
                },
                treeData: [],
                active: "",
                show: false,
                section: {
                    project_section_id: [],
                    is_manual: true,
                    job_date_time: "",
                    project_activity_group_id: "",
                },
                showSection: false,
                name: "",
                col1: "col-md-3",
                col2: "col-md-9",
                activity_group_div: true,
                project_activity_group: {
                    project_id: "",
                    is_manual: true,
                    project_activity_group_id: "",
                    job_date_time: "",
                    is_request_date_time : false,
                    ps_offset: 0,
                    psg_offset: 0,
                },

                project_section:{
                    project_id: '',
                    is_manual: true,
                    project_activity_group_id: '',
                    job_date_time: '',
                    is_request_date_time : false,
                    project_activities: []
                },

                project_section_groups:{
                    project_id: '',
                    is_manual: true,
                    project_section_group_id: '',
                    job_date_time: '',
                    is_request_date_time : false,
                    project_section_groups: []
                },
                status: false,
                is_get_button: true,
                errors: [],
                item_data:[],
            };
        },
        watch: {
            "$store.getters.item": function () {
                let item = this.$store.getters.item;
                let activity_group = {};
                console.log('item.module:----',item.module)
                if (item.module == "project_section_id") {
                    this.section.project_section_id = [];
                    this.section.project_section_id.push(item.id);
                    this.getProjectActivities(this.$store.getters.item.name);
                    this.showSection = true;
                    this.item_data = item;
                    this.projectsection_groups_check = false;
                    this.project_section_groups_check =false;
                    this.project_activity_check = true;
                    this.show_image_check=false;
                    this.show_email_recipients_check = false
                }
                if (item.module == "project_activity_group_id") {
                    this.showSection = false;
                    this.project_activity_group.project_activity_group_id = item.id;
                    this.name = this.$store.getters.item.name;
                    // this.project_activity_group.project_activity_group_id = item.id;
                    this.getProjectSectionGroups();
                    this.item_data = item;
                    this.projectsection_groups_check = true;
                    this.project_activity_check = false;
                    this.project_section_groups_check =false;
                    this.show_image_check=false;
                    this.show_email_recipients_check = false
                }
                if(item.module == 'project_section_group_id'){
                    this.showSection = false;
                    this.project_section_groups.project_section_group_id = item.id;
                    this.name = this.$store.getters.item.name;
                    this.getProjectSection();
                    this.item_data = item;
                    this.project_section_groups_check =true;
                    this.projectsection_groups_check = false
                    this.project_activity_check = false;
                    this.show_image_check=false;
                    this.show_email_recipients_check = false
                }
            },
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                (vm.project.project_id = to.params.project_id), (vm.project.type = "pmt_user");
                let loader = vm.$loading.show();
                let uri = { uri: "getProjectPMTUser", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.project = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            });
        },
        mounted() {
        },
        methods: {

            showImages(){
                this.project_section_groups_check = false
                this.projectsection_groups_check = false
                this.project_activity_check = false
                this.show_image_check = true
                this.show_email_recipients_check = false
                let radio = document.getElementsByClassName('radio_button')
                for(let i=0;i< radio.length;i++){
                    radio[i].checked = false
                }
                this.$refs.email_recipients.checked = false
            },
            showEmailRecipients(){
                this.project_section_groups_check = false
                this.projectsection_groups_check = false
                this.project_activity_check = false
                this.show_image_check = false
                this.show_email_recipients_check = true
                let radio = document.getElementsByClassName('radio_button')
                for(let i=0;i< radio.length;i++){
                    radio[i].checked = false
                }
            },
            getLatestJobs(){
                vm.project.project_activity_group_id = vm.project_activity_group.project_activity_group_id
                let uri = { uri: "getLatestJobs", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        
                    })
                    .catch(function (error) {
                        
                    });
            },

            downloadPMTForm() {
                let vm = this
                let project_activity_group_id = vm.project_activity_group.project_activity_group_id
                let job_date_time = vm.project_activity_group.job_date_time
                window.open(this.$store.getters.apiUrl + 'downloadPMTForm?project_activity_group_id='+project_activity_group_id+'&job_date_time='+job_date_time);
            },
            display(val) {
                console.log("val", val);
            },
            expand() {
                let vm = this;
                vm.col1 = "col-md-12";
                vm.col2 = "col-md-12";
                vm.activity_group_div = false;
            },
            restore() {
                let vm = this;
                vm.col1 = "col-md-3";
                vm.col2 = "col-md-9";
                vm.activity_group_div = true;
            },
            focusNext(project_activity_key, project_parameter_key) {
                console.log(project_parameter_key);
                console.log(this.$refs.myInputs);
                this.$refs.myInputs[project_parameter_key + 1].focus();            
            },
            
            getProjectActivities(item_name) {
                // console.log("item", item_name);
                let vm = this;
                let loader = vm.$loading.show();

                // vm.section.job_date_time = vm.project.job_date_time;
                if (vm.section.job_date_time) {
                    vm.status = true;
                }
                let uri = { uri: "getProjectSectionActivities", data: vm.section };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        // console.log("project_activities", response.data.data);
                        loader.hide()
                        vm.show_image_check=false;
                        vm.project.project_id = response.data.project_activity_group.project_id
                        vm.project.project_activity_group_id = response.data.project_activity_group.project_activity_group_id
                        vm.project.project_activities = response.data.activities
                        vm.show = true;
                        vm.name = item_name;
                        vm.is_get_button = response.data.is_get_button
                        // vm.status = response.data.status
                        vm.$store.dispatch("setJobStatus", response.data.status)
                        if(response.data.job){
                            vm.project.job_date_time = moment(response.data.job.job_date_time).format('yyyy-MM-DD')
                        }else{
                            vm.project.job_date_time = moment().format('yyyy-MM-DD')
                        }
                        vm.project_section.project_id = response.data.project_activity_group.project_id
                        vm.project_section.project_activity_group_id = response.data.project_activity_group.project_activity_group_id
                    })
                    .catch(function (error) {
                        loader.hide();
                    });
            },

            getPSG() {
                this.project_activity_group.job_date_time = "";
                this.getProjectSectionGroups();
            },

            getProjectSectionGroups() {
                let vm = this;
                console.log("success")
                let loader = vm.$loading.show();
                vm.show_image_check=false
                vm.project_activity_group.project_id = vm.project.project_id
                vm.project_activity_group.job_date_time = vm.project.job_date_time
                let uri = { uri: "getProjectSectionGroups", data: vm.project_activity_group };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        // console.log("ss", response.data);
                        vm.project.project_section_groups = response.data.project_section_groups
                        vm.is_get_button = response.data.is_get_button
                        vm.status = response.data.status
                        vm.$store.dispatch("setJobStatus", response.data.status)
                        if(response.data.job){
                            vm.project.job_date_time = moment(response.data.job.job_date_time).format('yyyy-MM-DD')
                        }else{
                            vm.project.job_date_time = moment().format('yyyy-MM-DD')
                        }
                        vm.show = true
                    })
                    .catch(function (error) {
                        loader.hide();
                    });
            },

            getProjectSectionGroups1() {
                let vm = this;
                console.log("success")
                // let loader = vm.$loading.show();
                vm.project_activity_group.project_id = vm.project.project_id
                vm.project_activity_group.job_date_time = vm.project.job_date_time
                vm.project.project_activity_group_id = vm.project_activity_group.project_activity_group_id

                let uri = { uri: "getProjectSectionGroupActivities", data: vm.project_activity_group };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        // vm.project.project_section_groups = response.data.project_activities
                        // vm.is_get_button = response.data.is_get_button
                        // vm.status = response.data.status
                        // vm.$store.dispatch("setJobStatus", response.data.status)
                        // if(response.data.job){
                        //     vm.project.job_date_time = moment(response.data.job.job_date_time).format('yyyy-MM-DD')
                        // }else{
                        //     vm.project.job_date_time = moment().format('yyyy-MM-DD')
                        // }
                        vm.show = true
                    })
                    .catch(function (error) {
                        // loader.hide();
                    });
            },

            getProjectSection(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.project_section_groups.project_id = vm.project.project_id
                vm.project_section_groups.job_date_time = vm.project.job_date_time
                let uri = { uri: "getProjectSections", data: vm.project_section_groups };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        console.log("getProjectSection:---", response.data.project_sections);
                        vm.project.project_sections = response.data.project_sections
                        vm.project.project_section_group = response.data.section_group
                        vm.is_get_button = response.data.is_get_button
                        vm.status = response.data.status
                        vm.$store.dispatch("setJobStatus", response.data.status)
                        // vm.projectsection_groups = true
                        if(response.data.job){
                            vm.project.job_date_time = moment(response.data.job.job_date_time).format('yyyy-MM-DD')
                        }else{
                            vm.project.job_date_time = moment().format('yyyy-MM-DD')
                        }
                        vm.show = true
                    })
                    .catch(function (error) {
                        loader.hide();
                    });
            },            
        },
    };
</script>
<style scoped>
    .invisible {
        visibility: hidden;
    }
    /* Style the tab */
    .tab {
        overflow: hidden;
        border: 1px solid #ccc;
        background-color: #f1f1f1;
    }

    /* Style the buttons inside the tab */
    .tab button {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        font-size: 16px;
    }

    /* Change background color of buttons on hover */
    .tab button:hover {
        background-color: #ddd;
    }

    /* Create an active/current tablink class */
    .tab button.active {
        background-color: #007bff;
        color: #fff;
    }

    /* Style the tab content */
    .tabcontent {
        padding: 6px 12px;
        border: none;
    }
    .size {
        font-size: 17px;
    }

    /*.td1 {
        position: sticky;
        width: 100px;
        left: 0;
        z-index: 10;
        background: #fff;
    }

    .td2 {
        position: sticky;
        width: 100px;
        left: 0;
        z-index: 10;
        background: #fff;
    }

    .td3 {
        position: sticky;
        width: 100px;
        left: 0;
        z-index: 10;
        background: #fff;
    }*/

    /* table tr th:first-child, table td:first-child{
        position: sticky;
        width: 100px;
        left: 0;
        z-index: 10;    
        background: #fff;
        
    }*/

    .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        z-index: 0;
        background-color: #fff !important;
        background-attachment: fixed;
    }

    .td1 {
        width: 100%;
        min-width: 200px;
        max-width: 200px;
        left: 0px;
        z-index: 1;
        background-color: #fff !important;
        text-wrap: wrap !important;
    }

    .td2 {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        left: 199px;
        z-index: 1;
        background-color: #fff !important ;
        background-attachment: fixed;
    }

    .td3 {
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        left: 268px;
        z-index: 1;
        background-color: #fff !important;
        background-attachment: fixed;
    }
    .td3-1 {
        width: 100px;
        min-width: 80px;
        max-width: 80px;
        left: 268px;
        z-index: 1;
        background-color: #fff !important;
        background-attachment: fixed;
    }

    .td4 {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        left: 0px;
    }
    .justify-content-space-between {
        justify-content: space-between;
    }

    /*table, th, td {
      border-collapse: collapse;
  }*/

    /* .card-body tr th {
        position: sticky;
    }

   
.card-body tr:nth-child(1),
.card-body td:nth-child(1) {
    position: sticky;
    left: 0;
    width: 100px;
    z-index: 10;
    background: #fff;
}


*/
    /* .form-control {
        width: 100%!important;
        display: block !important;
    } */

    /* .form-control1 {
        width: 100% !important;
        display: block !important;
    }

    .form-control {
        width: -webkit-fill-available;
    } */
</style>
