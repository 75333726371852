<template>
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
        <div>
            <ol class="breadcrumb fs-sm mb-1">
                <li class="breadcrumb-item">Dashboard</li>
                <li class="breadcrumb-item active" aria-current="page">Transfer</li>
            </ol>
            <h4 class="main-title mb-0">Transfer</h4>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">New Transfer</h6>
                </div>
                <div class="card-body">
                    <div class="row g-2 mb-1">
                        <Project :project="project" />
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input class="form-control" type="text"
                                            placeholder="Type keyword and press enter key" v-model="meta.search"
                                            @keypress.enter="search()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="table-responsive table-responsive-sm">
                                <table class="table table-sm table-bordered table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th class="text-center">Level</th>
                                            <th>WBS Code</th>
                                            <th>WBS Description</th>
                                            <th>Available Balance</th>
                                            <th class="text-center">Outward / Allocate / Withdrawal</th>
                                            <th class="text-center">Inward / Receipt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="!project?.project_costs?.length">
                                            <td colspan="6" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="project_cost, key in project.project_costs" :key="key">
                                            <td class="text-center">{{ meta.from + key }}</td>
                                            <td class="text-center">{{ project_cost.level }}</td>                                            
                                            <td>{{ project_cost.wbs_code }}</td>
                                            <td>{{ project_cost.wbs_description }}</td>
                                            <td>{{ project_cost.withdrawal_balance }}</td>
                                            <td> <input type="text" name="" id="" class="form-control form-control-sm"
                                                    :class="{ 'is-invalid': project_cost?.outward_errors }"
                                                    v-model="project_cost.outward_amount">
                                                <span v-if="project_cost?.outward_errors"
                                                    class="text-danger">{{ project_cost.outward_errors }}</span>
                                            </td>
                                            <td> <input type="text" name="" id="" class="form-control form-control-sm"
                                                    :class="{ 'is-invalid': project_cost?.inward_errors }"
                                                    v-model="project_cost.inward_amount">
                                                <span v-if="project_cost?.inward_errors"
                                                    class="text-danger">{{ project_cost.inward_errors }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button type="button" class="btn btn-sm btn-outline-primary mx-1 mt-2" style="float: right;"
                                    @click="commitShuffling()">Submit</button>
                                <button type="button" class="btn btn-sm btn-outline-danger mx-1 mt-2" style="float: right;"
                                    @click="discard()">Discard</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Project from '@/components/Project'
import Pagination from "@/components/Pagination.vue";
export default {
    components: { Project, Pagination },
    data() {
        return {
            project: {},
            meta: {
                search: '',
                project_id: '',
                order_by: "asc",
                keyword: "project_cost_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
                trashed: false,
            },
            cost_types: [],
            project_costs: [],
            project: {
                project_id: "",
                project_costs: [],
                last_stage: {}
            },
            attachment: '',
            transfer: {
                allocate: [],
                withdraw: []
            }
        };
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.meta.project_id = to.params.project_id
            let uri = { uri: "getProject", data: vm.meta };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.project = response.data.data
                    vm.getCostTypes()
                    vm.index();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        });
    },
    methods: {

        index() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "paginateTransferCosts", data: vm.meta })
                .then(function (response) {
                    vm.project.project_costs = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.to = response.data.meta.to;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getCostTypes() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getCostTypes", data: vm.meta })
                .then(function (response) {
                    vm.cost_types = response.data.data
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        commitShuffling() {
            let vm = this;
            let transfers = [];
            let inward_total = 0;
            let outward_total = 0;
            this.project.project_costs.filter(ele => {
            console.log(ele);
                if ((typeof (ele.outward_amount) === "string") || (typeof (ele.inward_amount) === "string")) {
                    if (ele.outward_amount || ele.inward_amount) {
                        let a = {
                            // project_cost: ele,
                            wbs_code: ele.wbs_code,
                            budget: ele.budget,
                            balance: ele.withdrawal_balance,
                            project_cost_id: ele.project_cost_id,
                            cost_type_id: ele.cost_type.cost_type_id,
                            is_quantity: ele.is_quantity,
                            quantity_cost_type_id:ele.quantity_cost_type_id,
                            quantity: ele.quantity,
                            outward: ele.outward_amount,
                            inward: ele.inward_amount,
                        }
                        let b = Object.assign({}, a);
                        transfers.push(b);
                        if(ele.outward_amount){
                            outward_total += parseFloat(ele.outward_amount);
                        }
                        if(ele.inward_amount){
                            inward_total += parseFloat(ele.inward_amount);
                        }
                    }
                }
            })

            let data = {
                project_id: this.project.project_id,
                amount: outward_total,
                inward_amount: inward_total,
                outward_amount: outward_total,
                transfers: transfers
            }
            let loader = this.$loading.show()
            this.$store.dispatch('post', { uri: 'commitShuffling', data: data })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.showTransferList(this.project);
                })
                .catch(error => {
                    loader.hide();
                    transfers = [];
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },

        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },

        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },

        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },

        discard() {
            this.project.project_costs.forEach(ele => {
                ele.outward_amount = 0;
                ele.inward_amount = 0;
            })
        },

        showTransferList(project) {
            this.$router.push('/BATS/' + project.project_id + '/transfer_list')
        },
    }

};
</script>
