<template>
    <div class="">
        <form @submit.prevent="create()">
            <div class="card">
                <div class="card-header">
                    <div class="d-sm-flex align-items-center justify-content-between">
                        <div>
                            <strong  v-if="status">Create Project</strong>
                            <strong  v-else>Update Project</strong>
                        </div>
                        <div class="d-flex align-items-center">
                            <router-link to="/projects" type="submit" class="btn btn-sm btn-primary"><i class="ri-list-check icon_hgt"></i> PROJECTS</router-link>
                        </div>
                    </div>
                </div>
                <div class="card-body mb-3">
                    <div class="row g-3 mb-3">
                        <div class="col-md-3">
                            <label class="form-label">Project Code</label><span class="text-danger"> *</span>
                            <input type="text" placeholder="Project Code" class="form-control" :class="{ 'is-invalid': errors.project_code }" v-model="project.project_code" ref="project_code" id="project_code" />
                            <span v-if="errors.project_code" class="invalid-feedback">{{ errors.project_code[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Project Name</label><span class="text-danger"> *</span>
                            <input type="text" placeholder="Project Name" class="form-control" :class="{ 'is-invalid': errors.project_name }" v-model="project.project_name" />
                            <span v-if="errors.project_name" class="invalid-feedback">{{ errors.project_name[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Project Type</label><span class="text-danger"> *</span>
                            <select class="form-control" :class="{ 'is-invalid': errors.project_type_id }" v-model="project.project_type_id">
                                <option value="">Select Project Type </option>
                                <option v-for="(project_type, key) in project_types" :key="key" :value="project_type.project_type_id">{{ project_type.project_type_name }} </option>
                            </select>
                            <span v-if="errors.project_type_id" class="invalid-feedback">{{ errors.project_type_id[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Project Category</label><span class="text-danger"> *</span>
                            <select class="form-control" :class="{ 'is-invalid': errors.project_category_id }" v-model="project.project_category_id">
                                <option value="">Select Project Category </option>
                                <option v-for="(project_category, key) in project_categories" :key="key" :value="project_category.project_category_id">{{ project_category.project_category_name }}</option>
                            </select>
                            <span v-if="errors.project_category_id" class="invalid-feedback">{{ errors.project_category_id[0] }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Project Group</label><span class="text-danger"> *</span>
                            <select class="form-control" :class="{ 'is-invalid': errors.project_group_id }" v-model="project.project_group_id">
                                <option value="">Select Project Group </option>
                                <option v-for="(project_group, key) in project_groups" :key="key" :value="project_group.project_group_id">{{ project_group.project_group_name }} </option>
                            </select>
                            <span v-if="errors.project_group_id" class="invalid-feedback">{{ errors.project_group_id[0] }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Plant</label><span class="text-danger"> *</span>
                            <!-- <select class="form-control" :class="{ 'is-invalid': errors.plant_id }" v-model="project.plant_id">
                                <option value="">Select Plant </option>
                                <option v-for="(plant, key) in plants" :key="key" :value="plant.plant_id">{{ plant.plant_name }} </option>
                            </select> -->

                            <search
                                :class="{ 'is-invalid': errors.plant_id }"
                                :customClass="{ 'is-invalid': errors.plant_id  }"
                                :initialize="project.plant_id"
                                id="plant_id"
                                label="plant_name"
                                placeholder="Select Plant"
                                :data="plants"
                                @input="plant => project.plant_id = plant"
                            >
                            </search>

                            <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">WBS Code</label>
                            <input type="text" placeholder="WBS Code" class="form-control" :class="{ 'is-invalid': errors.wbs_code }" v-model="project.wbs_code" ref="wbs_code" />
                            <span v-if="errors.wbs_code" class="invalid-feedback">{{ errors.wbs_code[0] }}</span>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label">Note</label>
                            <textarea type="text" placeholder="Project Description" class="form-control" :class="{ 'is-invalid': errors.note }" v-model="project.note"></textarea>
                            <span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
                        </div>
                    </div>

                    <div class="col-md-12 mb-3">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap table-striped table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Forecast Start Date</th>
                                        <th>Forecast End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Project Baseline</td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.base_line_start_date }" v-model="project.base_line_start_date" />
                                            <span v-if="errors.base_line_start_date" class="invalid-feedback">{{ errors.base_line_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.base_line_end_date }" v-model="project.base_line_end_date" />
                                            <span v-if="errors.base_line_end_date" class="invalid-feedback">{{ errors.base_line_end_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.forecast_start_date }" v-model="project.forecast_start_date" />
                                            <span v-if="errors.forecast_start_date" class="invalid-feedback">{{ errors.forecast_start_date [0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.forecast_end_date }" v-model="project.forecast_end_date" />
                                            <span v-if="errors.forecast_end_date" class="invalid-feedback">{{ errors.forecast_end_date[0] }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Project CERC</td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.cerc_start_date }" v-model="project.cerc_start_date" />
                                            <span v-if="errors.cerc_start_date" class="invalid-feedback">{{ errors.cerc_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.cerc_end_date }" v-model="project.cerc_end_date" />
                                            <span v-if="errors.cerc_end_date" class="invalid-feedback">{{ errors.cerc_end_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.cerc_forecast_start_date }" v-model="project.cerc_forecast_start_date" />
                                            <span v-if="errors.cerc_forecast_start_date" class="invalid-feedback">{{ errors.cerc_forecast_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.cerc_forecast_end_date }" v-model="project.cerc_forecast_end_date" />
                                            <span v-if="errors.cerc_forecast_end_date" class="invalid-feedback">{{ errors.cerc_forecast_end_date[0] }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Engineering</td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.engineering_start_date }" v-model="project.engineering_start_date" />
                                            <span v-if="errors.engineering_start_date " class="invalid-feedback">{{ errors.engineering_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.engineering_end_date }" v-model="project.engineering_end_date" />
                                            <span v-if="errors.engineering_end_date" class="invalid-feedback">{{ errors.engineering_end_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.engineering_forecast_start_date }" v-model="project.engineering_forecast_start_date" />
                                            <span v-if="errors.engineering_forecast_start_date" class="invalid-feedback">{{ errors.engineering_forecast_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.engineering_forecast_end_date }" v-model="project.engineering_forecast_end_date" />
                                            <span v-if="errors.engineering_forecast_end_date" class="invalid-feedback">{{ errors.engineering_forecast_end_date[0] }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Engineering CERC</td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.engineering_cerc_start_date }" v-model="project.engineering_cerc_start_date" />
                                            <span v-if="errors.engineering_cerc_start_date " class="invalid-feedback">{{ errors.engineering_cerc_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.engineering_cerc_end_date }" v-model="project.engineering_cerc_end_date" />
                                            <span v-if="errors.engineering_cerc_end_date" class="invalid-feedback">{{ errors.engineering_cerc_end_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.engineering_cerc_forecast_start_date }" v-model="project.engineering_cerc_forecast_start_date" />
                                            <span v-if="errors.engineering_cerc_forecast_start_date" class="invalid-feedback">{{ errors.engineering_cerc_forecast_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.engineering_cerc_forecast_end_date }" v-model="project.engineering_cerc_forecast_end_date" />
                                            <span v-if="errors.engineering_cerc_forecast_end_date" class="invalid-feedback">{{ errors.engineering_cerc_forecast_end_date[0] }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Procurement</td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.procurement_start_date }" v-model="project.procurement_start_date" />
                                            <span v-if="errors.procurement_start_date" class="invalid-feedback">{{ errors.procurement_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.procurement_end_date }" v-model="project.procurement_end_date" />
                                            <span v-if="errors.procurement_end_date" class="invalid-feedback">{{ errors.procurement_end_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.procurement_forecast_start_date }" v-model="project.procurement_forecast_start_date" />
                                            <span v-if="errors.procurement_forecast_start_date" class="invalid-feedback">{{ errors.procurement_forecast_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.procurement_forecast_end_date }" v-model="project.procurement_forecast_end_date" />
                                            <span v-if="errors.procurement_forecast_end_date" class="invalid-feedback">{{ errors.procurement_forecast_end_date[0] }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Procurement CERC</td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.procurement_cerc_start_date }" v-model="project.procurement_cerc_start_date" />
                                            <span v-if="errors.procurement_cerc_start_date" class="invalid-feedback">{{ errors.procurement_cerc_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.procurement_cerc_end_date }" v-model="project.procurement_cerc_end_date" />
                                            <span v-if="errors.procurement_cerc_end_date" class="invalid-feedback">{{ errors.procurement_cerc_end_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.procurement_cerc_forecast_start_date }" v-model="project.procurement_cerc_forecast_start_date" />
                                            <span v-if="errors.procurement_cerc_forecast_start_date" class="invalid-feedback">{{ errors.procurement_cerc_forecast_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.procurement_cerc_forecast_end_date }" v-model="project.procurement_cerc_forecast_end_date" />
                                            <span v-if="errors.procurement_cerc_forecast_end_date" class="invalid-feedback">{{ errors.procurement_cerc_forecast_end_date[0] }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Construction</td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.construction_start_date }" v-model="project.construction_start_date" />
                                            <span v-if="errors.construction_start_date" class="invalid-feedback">{{ errors.construction_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.construction_end_date }" v-model="project.construction_end_date" />
                                            <span v-if="errors.construction_end_date" class="invalid-feedback">{{ errors.construction_end_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.construction_forecast_start_date }" v-model="project.construction_forecast_start_date" />
                                            <span v-if="errors.construction_forecast_start_date" class="invalid-feedback">{{ errors.construction_forecast_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.construction_forecast_end_date }" v-model="project.construction_forecast_end_date" />
                                            <span v-if="errors.construction_forecast_end_date" class="invalid-feedback">{{ errors.construction_forecast_end_date[0] }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Construction CERC</td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.construction_cerc_start_date }" v-model="project.construction_cerc_start_date" />
                                            <span v-if="errors.construction_cerc_start_date" class="invalid-feedback">{{ errors.construction_cerc_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.construction_cerc_end_date }" v-model="project.construction_cerc_end_date" />
                                            <span v-if="errors.construction_cerc_end_date" class="invalid-feedback">{{ errors.construction_cerc_end_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.construction_cerc_forecast_start_date }" v-model="project.construction_cerc_forecast_start_date" />
                                            <span v-if="errors.construction_cerc_forecast_start_date" class="invalid-feedback">{{ errors.construction_cerc_forecast_start_date[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" class="form-control" :class="{ 'is-invalid': errors.construction_cerc_forecast_end_date }" v-model="project.construction_cerc_forecast_end_date" />
                                            <span v-if="errors.construction_cerc_forecast_end_date" class="invalid-feedback">{{ errors.construction_cerc_forecast_end_date[0] }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-sm-12">
                            <div class="table-responsive table-responsive-sm">
                                <table class="table table-sm text-nowrap table-bordered mb-0">
                                    <tbody>
                                        <tr>
                                            <!-- <th class="text-center"></th> -->
                                            <th>Capacity</th>
                                            <th>Capacity value</th>
                                            <th>UOM</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <select class="form-control" :class="{ 'is-invalid': errors.capacity_id }" v-model="capacity.capacity_id">
                                                    <option value="">Select Capacity </option>
                                                    <option v-for="(capacity, key) in capacities" :key="key" :value="capacity.capacity_id">{{ capacity.capacity_name }} </option>
                                                </select>
                                                <span v-if="errors.capacity_id"><small class="text-danger">{{ errors.capacity_id }}</small></span>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" :class="{ 'is-invalid': errors.capacity_value }" placeholder="capacity " v-model="capacity.capacity_value" />
                                                <span v-if="errors.capacity_value"><small class="text-danger">{{ errors.capacity_value }}</small></span>
                                            </td>
                                            <td>
                                                <select class="form-control" :class="{ 'is-invalid': errors.uom_id }" v-model="capacity.uom_id">
                                                    <option value="">Select UOM </option>
                                                    <option v-for="(unit, key) in units" :key="key" :value="unit.unit_id"> {{ unit.unit_name }} </option>
                                                </select>
                                                <span v-if="errors.uom_id"><small class="text-danger">{{ errors.uom_id }}</small></span>
                                            </td>

                                            <td class="text-center" colspan="2">
                                                <span v-if="!edit_status">
                                                    <button class="btn btn-sm btn-outline-primary mx-1" @click.prevent="addCapacity()"><i class="ri-add-line"></i></button>
                                                    <button class="btn btn-sm btn-outline-danger mx-1" @click.prevent="discard_capacity()"><i class="ri-close-line"></i></button>
                                                </span>
                                                <span v-else>
                                                    <button class="btn btn-sm btn-outline-success mx-1" @click.prevent="updateCapacity()"><i class="ri-edit-line"></i></button>
                                                    <button class="btn btn-sm btn-outline-danger mx-1" @click.prevent="cancelEdit1()"><i class="ri-close-line"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>

                                    <tbody>
                                        <tr v-for="capacity, index in project.project_capacities" :key="index">
                                            <!-- <td class="text-center">{{ index + 1 }}</td> -->
                                            <td>{{ getCapacityName(capacity.capacity_id) }}</td>
                                            <td>{{ capacity?.capacity }}</td>
                                            <td>{{ getUnitName(capacity.unit_id) }}</td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-sm btn-outline-danger" @click.prevent="deleteNewCapacity(index, capacity.project_capacity_id)"><i class="ri-delete-bin-line"></i></button>
                                                <button type="button" class="btn btn-sm btn-outline-primary mx-2" @click.prevent="editNewCapacity(index)"><i class="ri-pencil-line"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="table-responsive table-responsive-sm">
                                <table class="table table-sm text-nowrap table-bordered mb-0">
                                    <tbody>
                                        <tr>
                                            <th>Equipment</th>
                                            <th>OEM</th>
                                            <th>Capacity</th>
                                            <th>UOM</th>
                                            <th>Quantity</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <select class="form-control" :class="{ 'is-invalid': errors.equipment_id }" v-model="equipment.equipment_id">
                                                    <option value="">Select Equipment </option>
                                                    <option v-for="(equipment, key) in equipments" :key="key" :value="equipment.equipment_id">{{ equipment.equipment_name }} </option>
                                                </select>
                                                <!-- <search
                                                    :class="{ 'is-invalid': errors.equipment_id }"
                                                    :customClass="{ 'is-invalid': errors.equipment_id  }"
                                                    :initialize="equipment.equipment_id"
                                                    id="equipment_id"
                                                    label="equipment_name"
                                                    placeholder="Select Equipment"
                                                    :data="equipments"
                                                    @input="equipment => equipment.equipment_id = equipment"
                                                >
                                                </search> -->
                                                <span v-if="errors.equipment_id"><small class="text-danger">{{ errors.equipment_id }}</small></span>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" :class="{ 'is-invalid': errors.oem }" placeholder="OEM" v-model="equipment.oem" />
                                                <span v-if="errors.oem"><small class="text-danger">{{ errors.oem }}</small></span>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" :class="{ 'is-invalid': errors.capacity }" placeholder="Capacity" v-model.number="equipment.capacity" />
                                                <span v-if="errors.capacity"><small class="text-danger">{{ errors.capacity }}</small></span>
                                            </td>
                                            <td>
                                                <select class="form-control" :class="{ 'is-invalid': errors.unit_id }" v-model="equipment.unit_id">
                                                    <option value="">Select UOM </option>
                                                    <option v-for="(unit, key) in units" :key="key" :value="unit.unit_id"> {{ unit.unit_name }} </option>
                                                </select>
                                                <span v-if="errors.unit_id"><small class="text-danger">{{ errors.unit_id }}</small></span>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" :class="{ 'is-invalid': errors.quantity }" placeholder="Quantity" v-model="equipment.quantity" />
                                                <span v-if="errors.quantity"><small class="text-danger">{{ errors.quantity }}</small></span>
                                            </td>
                                            <td class="text-center" colspan="2">
                                                <span v-if="!edit_status">
                                                    <button class="btn btn-sm btn-outline-primary mx-1" @click.prevent="addEquipment()"><i class="ri-add-line"></i></button>
                                                    <button class="btn btn-sm btn-outline-danger mx-1" @click.prevent="discard_equipment()"><i class="ri-close-line"></i></button>
                                                </span>
                                                <span v-else>
                                                    <button class="btn btn-sm btn-outline-success mx-1" @click.prevent="updateEquipment()"><i class="ri-edit-line"></i></button>
                                                    <button class="btn btn-sm btn-outline-danger mx-1" @click.prevent="cancelEdit()"><i class="ri-close-line"></i></button>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr v-for="equipment, index in project.project_equipment" :key="index">
                                            <!-- <td class="text-center">{{ index + 1 }}</td> -->
                                            <td>{{ getEquipmentName(equipment.equipment_id) }}</td>
                                            <td>{{ equipment.oem }}</td>
                                            <td>{{ equipment.capacity }}</td>
                                            <td>{{ getUnitName(equipment.unit_id) }}</td>
                                            <td>{{ equipment.quantity }}</td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-sm btn-outline-danger" @click.prevent="deleteNewEquipment(index, equipment.project_equipment_id)"><i class="ri-delete-bin-line"></i></button>
                                                <button type="button" class="btn btn-sm btn-outline-primary mx-2" @click.prevent="editNewEquipment(index)"><i class="ri-pencil-line"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button type="reset" class="btn btn-danger me-2" @click.prevent="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                    <button v-if="status" type="submit" class="btn btn-primary" @click.prevent="create()"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
                    <button v-else type="submit" class="btn btn-primary" @click.prevent="update()"><i class="ri-save-line icon_hgt"></i> UPDATE</button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import moment from 'moment';
    let Search = require("@/components/Search.vue").default;
    export default {
        name: "CreateProject",
        components: { Search },
        data() {
            return {
                rawHtml:'<p style="font-size:10pt">testing</p>',
                users: [],
                errors: [],
                project: {
                    project_id: "",
                    project_code: "",
                    project_name: "",
                    project_category_id: "",
                    project_type_id: "",
                    project_group_id: "",
                    plant_id: "",
                    email_recipients:'',
                    wbs_code: "",
                    base_line_start_date: '',
                    base_line_end_date: '',
                    forecast_start_date: '',
                    forecast_end_date: '',

                    cerc_start_date: '',
                    cerc_end_date: '',
                    cerc_forecast_start_date: '',
                    cerc_forecast_end_date: '',

                    engineering_start_date: '',
                    engineering_end_date: '',
                    engineering_forecast_start_date: '',
                    engineering_forecast_end_date: '',

                    engineering_cerc_start_date: '',
                    engineering_cerc_end_date: '',
                    engineering_cerc_forecast_start_date: '',
                    engineering_cerc_forecast_end_date: '',

                    procurement_start_date: '',
                    procurement_end_date: '',
                    procurement_forecast_start_date: '',
                    procurement_forecast_end_date: '',

                    procurement_cerc_start_date: '',
                    procurement_cerc_end_date: '',
                    procurement_cerc_forecast_start_date: '',
                    procurement_cerc_forecast_end_date: '',

                    construction_start_date: '',
                    construction_end_date: '',
                    construction_forecast_start_date: '',
                    construction_forecast_end_date: '',

                    construction_cerc_start_date: '',
                    construction_cerc_end_date: '',
                    construction_cerc_forecast_start_date: '',
                    construction_cerc_forecast_end_date: '',

                    clinker_capacity: "",
                    cement_capacity: "",
                    note: "",
                    templates: [],
                    equipment_id: "",
                    capacity_id: "",
                    unit_id: "",
                    project_equipment: [],
                    project_capacities: []
                },
                deleted_equipment: [],
                deleted_capacities: [],
                deleted_: [],
                project_categories: [],
                project_types: [],
                project_groups: [],
                plants: [],
                equipments: [],
                capacities: [],
                new_equipments: [],
                new_capacities: [],
                temp: {
                    index: "",
                    equipment: {},
                },
                temp1: {
                    index: "",
                    capacity: {},
                },
                equipment: {
                    equipment_id: "",
                    equipment_name: "",
                    oem: "",
                    capacity: "",
                    unit_id: "",
                    uom: "",
                    quantity: "",
                },
                capacity: {
                    capacity_id: "",
                    capacity_name: "",
                    capacity_value: "",
                    unit_id: "",
                    uom: "",
                    uom_id: "",
                    capacity: "",
                },
                units: [],
                project_template: {
                    project: "",
                    template_master_costs: [],
                    templates: [],
                },
                edit_status: false,
                status: true,
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getProjectCategories();
                vm.getCapacities();
                vm.getEquipments();
                if (to.name == "CreateProject") {
                    vm.$refs.project_code.focus();
                } else {
                    vm.status = false;
                    let uri = { uri: "getProject", data: { project_id: to.params.project_id } };
                    vm.$store
                        .dispatch("post", uri)
                        .then((response) => {
                            console.log(response);
                            vm.project = response.data.data;
                            console.log("PROject", vm.project);
                            vm.new_equipments = vm.project.project_equipment;
                            vm.new_capacities = vm.project.project_capacities;
                        })
                        .catch((error) => {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            });
        },
        mounted(){
            this.project.base_line_start_date = moment().format('yyyy-MM-DD'),
            this.project.base_line_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),
            this.project.forecast_start_date = moment().format('yyyy-MM-DD'),
            this.project.forecast_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),
            this.project.cerc_start_date = moment().format('yyyy-MM-DD'),
            this.project.cerc_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),
            this.project.cerc_forecast_start_date = moment().format('yyyy-MM-DD'),
            this.project.cerc_forecast_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),

            this.project.engineering_start_date = moment().format('yyyy-MM-DD'),
            this.project.engineering_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),
            this.project.engineering_forecast_start_date = moment().format('yyyy-MM-DD'),
            this.project.engineering_forecast_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),

            this.project.engineering_cerc_start_date = moment().format('yyyy-MM-DD'),
            this.project.engineering_cerc_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),
            this.project.engineering_cerc_forecast_start_date = moment().format('yyyy-MM-DD'),
            this.project.engineering_cerc_forecast_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),

            this.project.procurement_start_date = moment().format('yyyy-MM-DD'),
            this.project.procurement_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),
            this.project.procurement_forecast_start_date = moment().format('yyyy-MM-DD'),
            this.project.procurement_forecast_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),

            this.project.procurement_cerc_start_date = moment().format('yyyy-MM-DD'),
            this.project.procurement_cerc_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),
            this.project.procurement_cerc_forecast_start_date = moment().format('yyyy-MM-DD'),
            this.project.procurement_cerc_forecast_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),

            this.project.construction_start_date = moment().format('yyyy-MM-DD'),
            this.project.construction_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),
            this.project.construction_forecast_start_date = moment().format('yyyy-MM-DD'),
            this.project.construction_forecast_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),

            this.project.construction_cerc_start_date = moment().format('yyyy-MM-DD'),
            this.project.construction_cerc_end_date = moment().add(18, 'months').format('yyyy-MM-DD'),
            this.project.construction_cerc_forecast_start_date = moment().format('yyyy-MM-DD'),
            this.project.construction_cerc_forecast_end_date = moment().add(18, 'months').format('yyyy-MM-DD')
        },

        methods: {
            isValidEmail(email) {
                const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return regex.test(email);
            },
            create() {
                // console.log(this.project.email_recipients.split(','))
                // let vm = this
                // if(this.project.email_recipients ){
                //     let emails = vm.project.email_recipients.split(',')
                //     emails.map(function(element){
                //         if(!vm.isValidEmail(element)){
                //             '<strong>'+element+'</strong>'
                //         }
                //     })
                //     vm.project.email_recipients = emails
                // }
                
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addProject", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", "Project created successfully!");
                        vm.$router.push("/projects");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.project.deleted_capacities = this.deleted_capacities;
                vm.project.deleted_equipment = this.deleted_equipment;
                let uri = { uri: "updateProject", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.$store.dispatch("success", "Project updated successfully!");
                        loader.hide();
                        vm.$router.push("/projects");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            createProjectTemplates() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addProjectTemplates", data: vm.project_template };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Project added successfully!");
                        vm.$router.push("/projects");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discard() {
                let vm = this;
                vm.errors = [];
                vm.project.project_code = "";
                vm.project.project_name = "";
                vm.project.project_category_id = "";
                vm.project.project_type_id = "";
                vm.project.project_group_id = "";
                vm.project.wbs_code = "";
                vm.project.plant_id = "";
                vm.project.clinker_capacity = "";
                vm.project.cement_capacity = "";
                vm.project.activity_group_id = "";
                vm.project.note = "";

                vm.project.base_line_start_date = "";
                vm.project.base_line_end_date = "";
                vm.project.forecast_start_date = "";
                vm.project.forecast_end_date = "";

                vm.project.cerc_start_date = "";
                vm.project.cerc_end_date = "";
                vm.project.cerc_forecast_start_date = "";
                vm.project.cerc_forecast_end_date = "";

                vm.project.engineering_start_date = "";
                vm.project.engineering_end_date = "";
                vm.project.engineering_forecast_start_date = "";
                vm.project.engineering_forecast_end_date = "";

                vm.project.engineering_cerc_start_date = "";
                vm.project.engineering_cerc_end_date = "";
                vm.project.engineering_cerc_forecast_start_date = "";
                vm.project.engineering_cerc_forecast_end_date = "";

                vm.project.procurement_start_date = "";
                vm.project.procurement_end_date = "";
                vm.project.procurement_forecast_start_date = "";
                vm.project.procurement_forecast_end_date = "";

                vm.project.procurement_cerc_start_date = "";
                vm.project.procurement_cerc_end_date = "";
                vm.project.procurement_cerc_forecast_start_date = "";
                vm.project.procurement_cerc_forecast_end_date = "";

                vm.project.construction_start_date = "";
                vm.project.construction_end_date = "";
                vm.project.construction_forecast_start_date = "";
                vm.project.construction_forecast_end_date = "";

                vm.project.construction_cerc_start_date = "";
                vm.project.construction_cerc_end_date = "";
                vm.project.construction_cerc_forecast_start_date = "";
                vm.project.construction_cerc_forecast_end_date = "";

                vm.project.project_capacities = "";
                vm.project.project_equipment = "";

                vm.$refs.project_code.focus();
            },

            getProjectCategories() {
                let vm = this;
                if (vm.$store.getters.project_categories) {
                    vm.project_categories = vm.$store.getters.project_categories;
                    vm.getProjectTypes();
                } else {
                    vm.$store
                        .dispatch("post", { uri: "getProjectCategories" })
                        .then(function (response) {
                            vm.$store.dispatch("setProjectCategories", response.data.data);
                            vm.project_categories = vm.$store.getters.project_categories;
                            vm.getProjectTypes();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            getProjectTypes() {
                let vm = this;
                if (vm.$store.getters.project_types) {
                    vm.project_types = vm.$store.getters.project_types;
                    vm.getProjectGroups();
                } else {
                    vm.$store
                        .dispatch("post", { uri: "getProjectTypes" })
                        .then(function (response) {
                            vm.$store.dispatch("setProjectTypes", response.data.data);
                            vm.project_types = vm.$store.getters.project_types;
                            vm.getProjectGroups();
                        })
                        .catch((error) => {
                            vm.errors = error.response.data;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            getProjectGroups() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getAllProjectGroups" })
                    .then((response) => {
                        vm.$store.dispatch("setProjectGroups", response.data.data);
                        vm.project_groups = vm.$store.getters.project_groups;
                        vm.getPlants();
                    })
                    .catch((error) => {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getPlants() {
                let vm = this;
                if (vm.$store.getters.plants) {
                    vm.plants = vm.$store.getters.plants;
                } else {
                    vm.$store
                        .dispatch("post", { uri: "getPlants" })
                        .then((response) => {
                            vm.$store.dispatch("setPlants", response.data.data);
                            vm.plants = vm.$store.getters.plants;
                        })
                        .catch((error) => {
                            vm.errors = error.response.data;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            getCapacities() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getCapacities" })
                    .then(function (response) {
                        vm.capacities = response.data.data;
                        vm.getUnits();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getEquipments() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getEquipments" })
                    .then(function (response) {
                        vm.equipments = response.data.data;
                        vm.getUnits();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getUnits() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getUnits" })
                    .then(function (response) {
                        vm.units = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            //
            addEquipment() {
                this.errors = [];
                let error_flag = false;
                let n = parseFloat(this.equipment.capacity);

                if (isNaN(n)) {
                    this.errors.capacity = "The capacity field should be a number!";
                    error_flag = true;
                }
                n = parseFloat(this.equipment.quantity);
                if (isNaN(n)) {
                    this.errors.quantity = "The quantity field should be a number!";
                    error_flag = true;
                }
                if (this.equipment.equipment_id == "") {
                    this.errors.equipment_id = "The equipment field cannot be empty!";
                    error_flag = true;
                }
                if (this.equipment.oem == "") {
                    this.errors.oem = "The OEM field cannot be empty!";
                    error_flag = true;
                }
                if (this.equipment.capacity == "") {
                    this.errors.capacity = "The Capacity field cannot be empty!";
                    error_flag = true;
                }
                if (this.equipment.unit_id == "") {
                    this.errors.unit_id = "The UOM field cannot be empty!";
                    error_flag = true;
                }
                if (this.equipment.quantity == "") {
                    this.errors.quantity = "The quantity field is required!";
                    error_flag = true;
                }
                if (error_flag) return;

                let temp = this.new_equipments.find((o) => o.equipment_id === this.equipment.equipment_id);
                if (temp) {
                    this.errors.equipment_id = "This equipment already exists!";
                    return;
                }
                var obj = Object.assign({}, this.equipment);
                obj.project_id = this.project.project_id;
                obj.project_equipment_id = "";
                this.new_equipments.push(obj);
                this.project.project_equipment = this.new_equipments;
                this.discard_equipment();
            },
            addCapacity() {
                this.errors = [];
                let error_flag = false;
                this.capacity.capacity = this.capacity.capacity_value;
                this.capacity.unit_id = this.capacity.uom_id;
                console.error("n", this.capacity.capacity_value);
                // console.log("capacity_uom",this.capacity.unit_id)
                let n = parseFloat(this.capacity.capacity);

                if (isNaN(n)) {
                    this.errors.capacity_value = "The capacity field should be a number!";
                    error_flag = true;
                }

                if (this.capacity.capacity_id == "") {
                    this.errors.capacity_id = "The capacity field cannot be empty!";
                    error_flag = true;
                }

                if (this.capacity.capacity_value == "") {
                    this.errors.capacity_value = "The Capacity field cannot be empty!";
                    error_flag = true;
                }
                if (this.capacity.uom_id == "") {
                    this.errors.uom_id = "The UOM field cannot be empty!";
                    error_flag = true;
                }

                if (error_flag) return;
                console.log("new_capacities", this.new_capacities);
                let temp1 = this.new_capacities.find((o) => o.capacity_id === this.capacity.capacity_id);
                if (temp1) {
                    this.errors.capacity_id = "This capacity already exists!";
                    return;
                }
                var obj = Object.assign({}, this.capacity);
                console.log("obj", obj);
                obj.project_id = this.project.project_id;
                obj.project_capacity_id = "";
                this.new_capacities.push(obj);
                this.project.project_capacities = this.new_capacities;
                console.log("PROJECTS", this.project.project_capacities);
                this.discard_capacity();
            },

            getCapacityName(id) {
                console.log("getCapacityName", id);
                let obj = this.capacities.find((o) => o.capacity_id === id);
                console.log("CAPACITY", obj);
                if (obj) {
                    return obj.capacity_name;
                }
            },
            getEquipmentName(id) {
                let obj = this.equipments.find((o) => o.equipment_id === id);
                if (obj) {
                    return obj.equipment_name;
                }
            },

            getUnitName(id) {
                let obj = this.units.find((o) => o.unit_id === id);
                if (obj) {
                    return obj.unit_name;
                }
            },
            deleteNewEquipment(index, project_equipment_id) {
                this.new_equipments.splice(index, 1);
                this.deleted_equipment.push(project_equipment_id);
                this.equipment = {
                    equipment_id: "",
                    equipment_name: "",
                    oem: "",
                    capacity: "",
                    unit_id: "",
                    uom: "",
                    quantity: "",
                };
            },

            editNewEquipment(index) {
                this.errors = [];
                this.edit_status = true;
                this.equipment = this.new_equipments[index];
                this.temp.index = index;
                this.temp.equipment = Object.assign({}, this.equipment);
            },
            deleteNewCapacity(index, project_capacity_id) {
                this.new_capacities.splice(index, 1);
                this.deleted_capacities.push(project_capacity_id);
                this.capacity = {
                    capacity_id: "",
                    capacity_name: "",
                    unit_id: "",
                    uom: "",
                    capacity: "",
                };
                this.discard_capacity();
            },
            editNewCapacity(index) {
                this.errors = [];
                this.edit_status = true
                this.capacity = this.new_capacities[index];
                this.capacity.capacity_value = this.new_capacities[index].capacity;
                this.capacity.uom_id = this.new_capacities[index].unit_id
                this.temp1.index = index;
                this.temp1.capacity = Object.assign({}, this.capacity);
            },

            cancelEdit1() {
                this.new_capacities[this.temp1.index] = this.temp1.capacity;
                this.discard_capacity();
            },
            cancelEdit() {
                this.new_equipments[this.temp.index] = this.temp.equipment;
                this.discard_equipment();
            },

            updateEquipment() {
                this.edit_status = false;
                this.discard_equipment();
            },
            updateCapacity() {
                this.capacity.capacity = this.capacity.capacity_value;
                this.capacity.unit_id = this.capacity.uom_id;
                this.edit_status = false;
                this.discard_capacity();
            },

            discard_equipment() {
                this.equipment = {
                    equipment_id: "",
                    equipment_name: "",
                    oem: "",
                    capacity: "",
                    unit_id: "",
                    uom: "",
                    quantity: "",
                };
                this.edit_status = false;
                this.errors = [];
            },
            discard_capacity() {
                this.capacity = {
                    capacity_id: "",
                    capacity_name: "",
                    unit_id: "",
                    uom: "",
                    capacity: "",
                    uom_id: "",
                };
                this.edit_status = false;
                this.errors = [];
            },
        },
    };
</script>
