<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">Project Cost</h2>
            <a href="javascript:void(0)" @click="back()" class="btn btn-sm mb-2 btn-primary"><i class="ri-list-check"></i>
                Back</a>
        </div>
        <div class="card">
            <div class="card-header">
                <strong v-if="status">New Template Master Cost</strong>
                <strong v-else>Update Project Cost</strong>
            </div>
            <div class="card-body mb-3">
                <form>
                    <div class="row mb-3 g-3">
                        <div class="col-md-8">
                            <label class="form-label">Template Code<span class="text-danger"> *</span></label>
                            <input type="text" class="form-control form-control-sm"
                                v-model="project_master_cost.template_code" :class="{ 'is-invalid': errors.template_code }"
                                placeholder="Template Code" disabled="true" />
                            <span v-if="errors.template_code" class="invalid-feedback">{{ errors.template_code[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">WBS Code<span class="text-danger"> *</span></label>
                            <input type="text" class="form-control form-control-sm" v-model="project_master_cost.wbs_code"
                                :class="{ 'is-invalid': errors.wbs_code }" placeholder="WBS Code" disabled="true" />
                            <span v-if="errors.wbs_code" class="invalid-feedback">{{ errors.wbs_code[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Level 1 (Cost Groups) <span class="text-danger"> *</span></label>
                            <search :class="{ 'is-invalid': errors.cost_group_id }"
                                :customClass="{ 'is-invalid': errors.cost_group_id }"
                                :initialize="project_master_cost.cost_group_id" id="cost_group_id" label="cost_group_name"
                                placeholder="Select Cost Group" :data="cost_groups" @change="costGroupSelected($event)"
                                @input="cost_group => project_master_cost.cost_group_id = cost_group">
                            </search>
                            <span v-if="errors.cost_group_id" class="invalid-feedback">{{ errors.cost_group_id[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Level 2 (Cost Sub Groups)<span class="text-danger"> *</span></label>
                            <!-- <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.cost_sub_group_id }"
                                v-model="project_master_cost.cost_sub_group_id" @change="costSubGroupSelected($event)"
                                id="cost_sub_group_code">
                                <option value="">Select Cost Sub Group</option>
                                <option v-for="(cost_sub_group, key) in cost_sub_groups" :key="key"
                                    :value="cost_sub_group.cost_sub_group_id">{{ cost_sub_group.cost_sub_group_name }}
                                </option>
                            </select> -->
                            <search :class="{ 'is-invalid': errors.cost_sub_group_id }"
                                :customClass="{ 'is-invalid': errors.cost_sub_group_id }"
                                :initialize="project_master_cost.cost_sub_group_id" id="cost_sub_group_id"
                                label="cost_sub_group_name" placeholder="Select Cost Sub Group" :data="cost_sub_groups"
                                @change="costSubGroupSelected($event)"
                                @input="cost_sub_group => project_master_cost.cost_sub_group_id = cost_sub_group">
                            </search>
                            <span v-if="errors.cost_sub_group_id" class="invalid-feedback">{{ errors.cost_sub_group_id[0]
                            }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Level 3 (Cost Heads)<span class="text-danger"> *</span></label>
                            <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.cost_head_id }"
                                v-model="project_master_cost.cost_head_id" @change="costHeadSelected($event)">
                                <option value="">Select Cost Head</option>
                                <option v-for="(cost_head, key) in cost_heads" :key="key" :value="cost_head.cost_head_id">{{
                                    cost_head.cost_head_name }}</option>
                            </select>
                            <span v-if="errors.cost_head_id" class="invalid-feedback">{{ errors.cost_head_id[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Level 4 (Cost Sub Heads) <span class="text-danger"> *</span></label>
                            <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.cost_sub_head_id }"
                                v-model="project_master_cost.cost_sub_head_id" @change="costSubHeadSelected($event)">
                                <option value="">Select Cost Sub Head</option>
                                <option v-for="(cost_sub_head, key) in cost_sub_heads" :key="key"
                                    :value="cost_sub_head.cost_sub_head_id">{{ cost_sub_head.cost_sub_head_name }}</option>
                            </select>
                            <span v-if="errors.cost_sub_head_id" class="invalid-feedback">{{ errors.cost_sub_head_id[0]
                            }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Level 5 (Cost Items) <span class="text-danger"> *</span></label>
                            <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.cost_item_id }"
                                v-model="project_master_cost.cost_item_id" @change="costItemSelected($event)">
                                <option value="">Select Cost Item</option>
                                <option v-for="(cost_item, key) in cost_items" :key="key" :value="cost_item.cost_item_id">{{
                                    cost_item.cost_item_name }}</option>
                            </select>
                            <span v-if="errors.cost_item_id" class="invalid-feedback">{{ errors.cost_item_id[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Level 6 (Cost Sub Items) <span class="text-danger"> *</span></label>
                            <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.cost_sub_item_id }"
                                v-model="project_master_cost.cost_sub_item_id" @change="costSubItemSelected($event)">
                                <option value="">Select Cost Sub Item</option>
                                <option v-for="(cost_sub_item, key) in cost_sub_items" :key="key"
                                    :value="cost_sub_item.cost_sub_item_id">{{ cost_sub_item.cost_sub_item_name }}</option>
                            </select>
                            <span v-if="errors.cost_sub_item_id" class="invalid-feedback">{{ errors.cost_sub_item_id[0]
                            }}</span>
                        </div>
                        <!-- <div class="col-md-6">
                            <label class="form-label">Level 7 (Cost Sub Sub Items)</label>
                            <select class="form-select form-select-sm">
                                <option value="">Select Cost Sub Sub Item</option>
                                <option>cost sub sub item 1</option>
                                <option>cost sub sub item 2</option>
                                <option>cost sub sub item 3</option>
                            </select>
                        </div> -->

                        <div class="col-md-3">
                            <label class="form-label">Cost Types <span class="text-danger"> *</span></label>
                            <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.cost_type_id }"
                                v-model="project_master_cost.cost_type_id">
                                <option value="">Select Cost Type</option>
                                <option v-for="(cost_type, key) in cost_types" :key="key" :value="cost_type.cost_type_id">{{
                                    cost_type.cost_type_name }}</option>
                            </select>
                            <span v-if="errors.cost_type_id" class="invalid-feedback">{{ errors.cost_type_id[0] }}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Units <span class="text-danger"> *</span></label>
                            <div class="dropdown" @click="showDropdown">
                                <!-- <div class="overselect"></div> -->
                                <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.unit_id }"
                                    v-model="project_master_cost.unit_id">
                                    <option value="">Select Unit</option>
                                    <option :value="unit_of_measurement.unit_id"
                                        v-for="(unit_of_measurement, key) in unit_of_measurements" :key="key">{{
                                            unit_of_measurement.unit_name }}</option>
                                </select>
                                <span v-if="errors.unit_id" class="invalid-feedback">{{
                                    errors.unit_id[0] }}</span>
                            </div>
                            <!-- <div class="multiselect">
                                <ul>
                                    <li v-for="(unit_of_measurement, key) in unit_of_measurements" :key="key">
                                        <input type="checkbox" :value="unit_of_measurement.unit_id"
                                            v-model="template_master_cost.template_unit_of_measurements" />
                                        <label style="margin-left: 5px;">{{ unit_of_measurement.unit_name }}</label>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Budget <span class="text-danger"> *</span></label>
                            <input type="text" name="" id="" v-model="project_master_cost.budget" placeholder="budget"
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger me-2" @click.prevent="discard()"><i class="ri-close-line icon_hgt"></i>
                    DISCARD</button>
                <button class="btn btn-primary" @click="addTemplateMasterCost" v-if="status"><i
                        class="ri-save-line icon_hgt"></i> SUBMIT</button>
                <button class="btn btn-primary" @click="updateProjectMasterCost" v-else><i
                        class="ri-save-line icon_hgt"></i> Update</button>
            </div>
        </div>
    </div>
</template>
<script>
let Search = require("@/components/Search.vue").default;
export default {
    name: "TemplateMasterCosts.Create",
    components: { Search },
    data() {
        return {
            status: true,
            show: false,
            project_master_cost: {
                project_master_cost_id: '',
                budget: ''
            },
            from_path: '',
            to_path: '',

            template_master_cost: {
                template_master_cost_id: "",
                template_code: "",
                wbs_code: '',
                template_name: "",
                project_type_id: "",
                cost_group_id: "",
                cost_sub_group_id: "",
                cost_head_id: "",
                cost_sub_head_id: "",
                cost_item_id: "",
                cost_sub_item_id: "",
                cost_type_id: "",
                unit_id: "",
            },
            template_master_costs: [],
            project_types: [],
            cost_groups: [],
            cost_sub_groups: [],
            cost_heads: [],
            cost_sub_heads: [],
            cost_items: [],
            cost_sub_items: [],
            cost_types: [],
            unit_of_measurements: [],
            errors: [],
            selected: {
                project_type_code: '',
                cost_group_code: '',
                cost_sub_group_code: '',
                cost_head_code: '',
                cost_sub_head_code: '',
                cost_item_code: '',
                cost_sub_item_code: '',
                //wbs code
                wbs_cost_group_code: '',
                wbs_cost_sub_group_code: '',
                wbs_cost_head_code: '',
                wbs_cost_sub_head_code: '',
                wbs_cost_item_code: '',
                wbs_cost_sub_item_code: '',
            }
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.getProjectTypes();
            vm.from_path = from.path
            if (to.name == "ProjectCost.Edit") {
                vm.project_master_cost.project_master_cost_id = to.params.project_master_cost_id
                vm.status = false;
                let uri = { uri: "getProjectMasterCost", data: vm.project_master_cost };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.project_master_cost = response.data.data
                    })
                    .catch(function (error) {
                        console.log(error)
                        // vm.errors = error.response.data.errors;
                        // vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        });
    },
    methods: {
        showDropdown() {
            // this.show = !this.show;
        },
        back() {
            this.$router.push(this.from_path)
        },
        addTemplateMasterCost() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "addTemplateMasterCost", data: vm.template_master_cost })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Template Master Cost added successfully");
                    vm.$router.push("/template_master_costs");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    console.log(vm.errors);
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        updateProjectMasterCost() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                // .dispatch("post", { uri: "updateProjectMasterCost", data: vm.template_master_cost })
                .dispatch("post", { uri: "updateProjectMasterCost", data: vm.project_master_cost })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Project Cost updated successfully");
                    vm.$router.push("/template_master_costs");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getProjectTypes() {
            let vm = this;
            if (vm.$store.getters.project_types) {
                vm.project_types = vm.$store.getters.project_types;
                vm.getCostGroups();
            } else {
                vm.$store
                    .dispatch("post", { uri: "getProjectTypes" })
                    .then(function (response) {
                        // vm.project_types = response.data.data;
                        vm.$store.dispatch("setProjectTypes", response.data.data);
                        vm.project_types = vm.$store.getters.project_types;
                        vm.getCostGroups();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        getCostGroups() {
            let vm = this;
            if (vm.$store.getters.cost_groups) {
                vm.cost_groups = vm.$store.getters.cost_groups;
                vm.getCostSubGroups();
            } else {
                vm.$store
                    .dispatch("post", { uri: "getCostGroups" })
                    .then(function (response) {
                        vm.$store.dispatch("setCostGroups", response.data.data);
                        vm.cost_groups = vm.$store.getters.cost_groups;
                        vm.getCostSubGroups();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        getCostSubGroups() {
            let vm = this;
            if (vm.$store.getters.cost_sub_groups) {
                vm.cost_sub_groups = vm.$store.getters.cost_sub_groups;
                vm.getCostHeads();
            } else {
                vm.$store
                    .dispatch("post", { uri: "getCostSubGroups" })
                    .then(function (response) {
                        vm.$store.dispatch("setCostSubGroups", response.data.data);
                        vm.cost_sub_groups = vm.$store.getters.cost_sub_groups;
                        vm.getCostHeads();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        getCostHeads() {
            let vm = this;
            if (vm.$store.getters.cost_heads) {
                vm.cost_heads = vm.$store.getters.cost_heads;
                vm.getCostSubHeads();
            } else {
                vm.$store
                    .dispatch("post", { uri: "getCostHeads" })
                    .then(function (response) {
                        vm.$store.dispatch("setCostHeads", response.data.data);
                        vm.cost_heads = vm.$store.getters.cost_heads;
                        vm.getCostSubHeads();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        getCostSubHeads() {
            let vm = this;
            if (vm.$store.getters.cost_sub_heads) {
                vm.cost_sub_heads = vm.$store.getters.cost_sub_heads;
                vm.getCostItems();
            } else {
                vm.$store
                    .dispatch("post", { uri: "getCostSubHeads" })
                    .then(function (response) {
                        vm.$store.dispatch("setCostSubHeads", response.data.data);
                        vm.cost_sub_heads = vm.$store.getters.cost_sub_heads;
                        vm.getCostItems();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        getCostItems() {
            let vm = this;
            if (vm.$store.getters.cost_items) {
                vm.cost_items = vm.$store.getters.cost_items;
                vm.getCostSubItems();
            } else {
                vm.$store
                    .dispatch("post", { uri: "getCostItems" })
                    .then(function (response) {
                        vm.$store.dispatch("setCostItems", response.data.data);
                        vm.cost_items = vm.$store.getters.cost_items;
                        vm.getCostSubItems();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        getCostSubItems() {
            let vm = this;
            if (vm.$store.getters.cost_sub_items) {
                vm.cost_sub_items = vm.$store.getters.cost_sub_items;
                vm.getCostTypes();
            } else {
                vm.$store
                    .dispatch("post", { uri: "getCostSubItems" })
                    .then(function (response) {
                        vm.$store.dispatch("setCostSubItems", response.data.data);
                        vm.cost_sub_items = vm.$store.getters.cost_sub_items;

                        vm.getCostTypes();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        getCostTypes() {
            let vm = this;
            if (vm.$store.getters.cost_types) {
                vm.cost_types = vm.$store.getters.cost_types;
                vm.getUnitOfMeasurements();
            } else {
                vm.$store
                    .dispatch("post", { uri: "getCostTypes" })
                    .then(function (response) {
                        vm.$store.dispatch("setCostTypes", response.data.data);
                        vm.cost_types = vm.$store.getters.cost_types;
                        vm.getUnitOfMeasurements();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        getUnitOfMeasurements() {
            let vm = this;
            if (vm.$store.getters.unit_of_measurements) {
                vm.unit_of_measurements = vm.$store.getters.unit_of_measurements;
            } else {
                vm.$store
                    .dispatch("post", { uri: "getUnits" })
                    .then(function (response) {
                        vm.$store.dispatch("setUnitOfMeasurements", response.data.data);
                        vm.unit_of_measurements = vm.$store.getters.unit_of_measurements;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        discard() {
            let vm = this;
            vm.template_master_cost.project_type_id = "";
            vm.template_master_cost.template_code = "";
            vm.template_master_cost.template_name = "";
            vm.template_master_cost.cost_group_id = "";
            vm.template_master_cost.cost_sub_group_id = "";
            vm.template_master_cost.cost_head_id = "";
            vm.template_master_cost.cost_sub_head_id = "";
            vm.template_master_cost.cost_item_id = "";
            vm.template_master_cost.cost_sub_item_id = "";
            vm.template_master_cost.cost_type_id = "";
            vm.template_master_cost.template_unit_of_measurements = [];
            vm.$refs.project_type_id.focus();
            vm.errors = [];
            vm.status = true;
        },
        projectTypeSelected(event) {
            var result = this.project_types.filter(obj => {
                return obj.project_type_id == event.target.value;
            });
            this.selected.project_type_code = result[0].project_type_code;
            this.generateTemplateCode();
        },

        costGroupSelected(event) {
            var result = this.cost_groups.filter(obj => {
                return obj.cost_group_id == event.target.value;
            });
            if (result.length) {
                this.selected.cost_group_code = result[0].cost_group_code;
                this.selected.wbs_cost_group_code = result[0].wbs_code;
            }
            else {
                this.selected.cost_group_code = '';
                this.selected.wbs_cost_group_code = '';
            }
            this.generateTemplateCode();
        },

        costSubGroupSelected(event) {
            var result = this.cost_sub_groups.filter(obj => {
                return obj.cost_sub_group_id == event.target.value;
            });
            if (result.length) {
                this.selected.cost_sub_group_code = result[0].cost_sub_group_code;
                this.selected.wbs_cost_sub_group_code = result[0].wbs_code;
            }
            else {
                this.selected.cost_sub_group_code = '';
                this.selected.wbs_cost_sub_group_code = '';
            }
            this.generateTemplateCode();
        },

        costHeadSelected(event) {
            var result = this.cost_heads.filter(obj => {
                return obj.cost_head_id == event.target.value;
            });
            this.selected.cost_head_code = result[0].cost_head_code;
            this.selected.wbs_cost_head_code = result[0].wbs_code;
            this.generateTemplateCode();
        },
        costSubHeadSelected(event) {
            var result = this.cost_sub_heads.filter(obj => {
                return obj.cost_sub_head_id == event.target.value;
            });
            this.selected.cost_sub_head_code = result[0].cost_sub_head_code;
            this.selected.wbs_cost_sub_head_code = result[0].wbs_code;
            this.generateTemplateCode();
        },
        costItemSelected(event) {
            var result = this.cost_items.filter(obj => {
                return obj.cost_item_id == event.target.value;
            });
            this.selected.cost_item_code = result[0].cost_item_code;
            this.selected.wbs_cost_item_code = result[0].wbs_code;
            this.generateTemplateCode();
        },
        costSubItemSelected(event) {
            var result = this.cost_sub_items.filter(obj => {
                return obj.cost_sub_item_id == event.target.value;
            });
            this.selected.cost_sub_item_code = result[0].cost_sub_item_code;
            this.selected.wbs_cost_sub_item_code = result[0].wbs_code;
            this.generateTemplateCode();
        },
        generateTemplateCode() {
            let template_code = '';
            let wbs_code = '';
            for (const value in this.selected) {
                if (value.startsWith('wbs')) {
                    if (this.selected[value] != '') {
                        wbs_code = wbs_code + this.selected[value] + '-';
                    }
                }
                else {
                    if (this.selected[value] != '') {
                        template_code = template_code + this.selected[value] + '-';
                    }
                }
            }
            this.template_master_cost.template_code = template_code.substring(0, template_code.length - 1);
            this.template_master_cost.wbs_code = wbs_code.substring(0, wbs_code.length - 1);
        }
    },
};
</script>
<style scoped>
.dropdown {
    position: relative;
    cursor: pointer;
}

.multiselect {
    position: relative;
}

.multiselect ul {
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    top: -0.1rem;
    width: 100%;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    background: white;
}

.overselect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
