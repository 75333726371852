<template>
    <div class="">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Instrument (Detail)</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                        <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="instrument.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Projects</label>
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="instrument.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @input="project => instrument.project_id = project"
                        >
                        </search>
                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">From Date</label>
                        <div class="input-group date" id="from_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.from_date }" v-model="instrument.from_date" />
                        </div>
                        <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">To Date</label>
                        <div class="input-group date" id="to_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.to_date }" v-model="instrument.to_date" />
                        </div>
                        <span v-if="errors.to_date" style="margin-top: 0.25rem;font-size: 0.875em;color: #dc3545;">{{ errors.to_date[0] }}</span>
                    </div>
                    <div class="col-md-12 mt-1">
                        <div style="float:right">
                        <button class="btn btn-sm btn-primary me-2" @click="getInstrumentDetail()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-warning me-2 " @click="downloadInstrumentDetail()"><i class="ri-download-line icon_hgt"></i> Download </button>
                        <button class="btn btn-sm btn-info " @click="sendEmailInstrumentDetail()"><i class="ri-mail-send-line icon_hgt"></i> Send Email </button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive table-responsive-sm" style="max-height: 392px; overflow-y: auto; ">
                            <table class="table table-sm text-nowrap table-bordered mb-0">
                                <thead class="bg-primary bg-opacity-50 ">
                                    <tr class="text-center" style="vertical-align: middle;">
                                        <th>Sl.No</th>
                                        <th>Area Description</th>
                                        <th colspan="6">Cable Tray Erection</th>
                                        <th colspan="6">Panel Erection</th>
                                        <th colspan="6">Instrument Cable Laying</th>
                                        <th colspan="6">Instrument Cable Termination</th>

                                    </tr>
                                </thead>
                                <tbody v-for="project_activity, key in project_activity_group.project_section_groups" :key="key">
                                    <tr class="bg-primary bg-opacity-50 text-center" >
                                        <th ></th>
                                        <th class="text-center" style="font-size: 18px; font-weight: 600;">
                                            {{ project_activity.section_group.section_group_name }}
                                        </th>
                                        <th v-if="key==0">Scope</th>
                                        <th v-if="key==0">UOM</th>
                                        <th v-if="key==0">FTD</th>
                                        <th v-if="key==0">MTD</th>
                                        <th v-if="key==0">CTD</th>
                                        <th v-if="key==0">% completed</th>

                                        <th v-if="key==0">Scope</th>
                                        <th v-if="key==0">UOM</th>
                                        <th v-if="key==0">FTD</th>
                                        <th v-if="key==0">MTD</th>
                                        <th v-if="key==0">CTD</th>
                                        <th v-if="key==0">% completed</th>

                                        <th v-if="key==0">Scope</th>
                                        <th v-if="key==0">UOM</th>
                                        <th v-if="key==0">FTD</th>
                                        <th v-if="key==0">MTD</th>
                                        <th v-if="key==0">CTD</th>
                                        <th v-if="key==0">% completed</th>

                                        <th v-if="key==0">Scope</th>
                                        <th v-if="key==0">UOM</th>
                                        <th v-if="key==0">FTD</th>
                                        <th v-if="key==0">MTD</th>
                                        <th v-if="key==0">CTD</th>
                                        <th v-if="key==0">% completed</th>
						            </tr>
                                    <template v-for="project_section,key in project_activity.project_sections" :key="key">
                                        <tr v-if="eliminateZeros(project_section)">
                                            <td class="text-center">{{ key+1 }}</td>
                                            <td>{{ project_section.section.section_name }}</td>
                                            <td class="text-center">{{ project_section.cable_tray_erection.scope }}</td>
                                            <td class="text-center">{{ project_section.cable_tray_erection.uom }}</td>
                                            <td class="text-center">{{ project_section.cable_tray_erection.ftd }}</td>
                                            <td class="text-center">{{ project_section.cable_tray_erection.mtd }}</td>
                                            <td class="text-center">{{ project_section.cable_tray_erection.ctd_act}}</td>
                                            <td class="text-center">{{ project_section.cable_tray_erection.percentage_complete_act}}</td>

                                            <td class="text-center">{{ project_section.panel_erection.scope }}</td>
                                            <td class="text-center">{{ project_section.panel_erection.uom }}</td>
                                            <td class="text-center">{{ project_section.panel_erection.ftd }}</td>
                                            <td class="text-center">{{ project_section.panel_erection.mtd }}</td>
                                            <td class="text-center">{{ project_section.panel_erection.ctd_act }}</td>
                                            <td class="text-center">{{ project_section.panel_erection.percentage_complete_act }}</td>

                                            <td class="text-center">{{project_section.instrument_cable_laying.scope  }}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_laying.uom }}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_laying.ftd }}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_laying.mtd }}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_laying.ctd_act }}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_laying.percentage_complete_act }}</td>

                                            <td class="text-center">{{project_section.instrument_cable_termination.scope  }}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_termination.uom }}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_termination.ftd}}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_termination.mtd }}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_termination.ctd_act }}</td>
                                            <td class="text-center">{{ project_section.instrument_cable_termination.percentage_complete_act }}</td>

                                        </tr>
                                    </template>
                                    
                                                                                                        
                                </tbody>

                                <tbody v-if="project_activity_group?.project_section_groups?.length > 0">
                                     <tr v-if="eliminateZeros2(project_activity_group)">
                                        <td></td>
                                        <td class="text-center " style="font-size:18px;font-weight:600;">Total</td>
                                        <td class="text-center">{{ project_activity_group.total_scope_cable_tray_erection }}</td>
                                        <td class="text-center"></td>
                                        <td class="text-center">{{ project_activity_group.total_ftd_cable_tray_erection }}</td>
                                        <td class="text-center">{{ project_activity_group.total_mtd_cable_tray_erection }}</td>
                                        <td class="text-center">{{ project_activity_group.total_ctd_cable_tray_erection }}</td>
                                        <td class="text-center"></td>


                                        <td class="text-center">{{project_activity_group.total_scope_panel_erection }}</td>
                                        <td class="text-center"></td>
                                        <td class="text-center">{{ project_activity_group.total_ftd_panel_erection}}</td>
                                        <td class="text-center">{{ project_activity_group.total_mtd_panel_erection}}</td>
                                        <td class="text-center">{{ project_activity_group.total_ctd_panel_erection}}</td>
                                        <td class="text-center"></td>

                                        <td class="text-center">{{project_activity_group.total_scope_instument_cable_laying }}</td>
                                        <td class="text-center"></td>
                                        <td class="text-center">{{ project_activity_group.total_ftd_instument_cable_laying}}</td>
                                        <td class="text-center">{{ project_activity_group.total_mtd_instument_cable_laying}}</td>
                                        <td class="text-center">{{ project_activity_group.total_ctd_instument_cable_laying}}</td>
                                        <td class="text-center"></td>

                                        <td class="text-center">{{project_activity_group.total_scope_instument_cable_termination }}</td>
                                        <td class="text-center"></td>
                                        <td class="text-center">{{ project_activity_group.total_ftd_instument_cable_termination}}</td>
                                        <td class="text-center">{{ project_activity_group.total_mtd_instument_cable_termination}}</td>
                                        <td class="text-center">{{ project_activity_group.total_ctd_instument_cable_termination}}</td>
                                        <td class="text-center"></td>
                                     </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import moment from 'moment';
    let Search = require("@/components/Search.vue").default;
    export default {
        components: {Search },
        data() {
            return {                
                initial:true, 
                instrument:{
                    project_id:'',
                    from_date:'',
                    to_date:'',
                    project_group_id:'',
                    isDownload:false
                },
                project_activity_group:[],
                projects: [],
                projectgroups:[],
                errors: [],
            };
        },
        mounted() {    
            if(this.$store.getters?.user){
                this.getProjectGroups();
            }           
            this.instrument.to_date=moment().format('YYYY-MM-DD')
        },
        methods: {
            eliminateZeros(project_section){
                if(project_section.cable_tray_erection.ctd_act || project_section.panel_erection.ctd_act || project_section.instrument_cable_laying.ctd_act || project_section.instrument_cable_termination.ctd_act){
                    return project_section
                }else{
                    return false
                }
            },
            eliminateZeros2(project_activity_group){
                if(project_activity_group.total_ctd_cable_tray_erection || project_activity_group.total_ctd_panel_erection || project_activity_group.total_ctd_instument_cable_laying || project_activity_group.total_ctd_instument_cable_termination){
                    return project_activity_group
                }else{
                    return false
                }
            },
            sendEmailInstrumentDetail(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "downloadInstrumentDetailReport", data: vm.instrument})
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Instrument summary - Mail sent successfully");
                    })
                    .catch(function (error) {
                        loader.hide()
                        if(error.response.data.length){
                            console.log(error.response.data[1])
                            vm.$store.dispatch("error", error.response.data[1]);
                        }
                    });
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.instrument.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.instrument.project_group_id) {
                                vm.instrument.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getInstrumentDetail(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "getInstrumentDetail", data: vm.instrument })
                    .then(function (response) {
                        loader.hide();
                        vm.project_activity_group = response.data.data     
                        localStorage.setItem('project_group_id', vm.instrument.project_group_id)
                        localStorage.setItem('project_id', vm.instrument.project_id)                   
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            downloadInstrumentDetail(){
                let vm = this;
                vm.errors = [];
                if (vm.instrument.project_id == ""){
                    vm.errors.project_id = ["The project field is required."]
                }
                if (vm.instrument.to_date == ""){
                    vm.errors.to_date = ["The To Date field is required."]
                }
                if (vm.instrument.project_id && vm.instrument.to_date ){
                    window.open(vm.$store.getters.apiUrl+'downloadInstrumentDetailReport?project_id='+vm.instrument.project_id+'&to_date='+vm.instrument.to_date+'&from_date='+vm.instrument.from_date+'&isDownload=true')
                }
            },

            getProjects(){
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects", data: {project_group_id: vm.instrument.project_group_id} })
                    .then(function (response) {
                        vm.projects = response.data;  
                        if (vm.projects.length) {
                            vm.instrument.project_id = vm.projects[0].project_id;
                        }     
                        if(localStorage.getItem('project_id')){
                            vm.instrument.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.instrument.project_id = vm.projects[0].project_id;
                        }
                        if(vm.initial){
                            vm.getInstrumentDetail();
                            vm.initial = false
                        }                     
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>
<style scoped>
  table {
        border-collapse: collapse;
       
    }

    thead {  
        th {
            position: -webkit-sticky;
            position: sticky;
            background-color: white !important;
            outline: 1px solid #e2e5ec;
            outline-offset: -1px ;
            top: 0; left: 0;                       
            &:first-child {
                z-index: 3;
                
            }
            
        }
        th:nth-child(2) {
            position: -webkit-sticky;
            position: sticky;
            background-color: white !important;
            top: 0; 
            left: 35px;                       
            z-index: 3;
            outline: 1px solid #e2e5ec;
            outline-offset: -1px ;
        }
        tr:nth-child(2){
            position: sticky;
            top: 29px;
            outline: 1px solid #e2e5ec;
            outline-offset: -1px ;
        }
        
    }
    th, td {
        &:first-child {
            position: -webkit-sticky;
            position: sticky;
            left: 0px;
            top: 0; 
            z-index: 2;
            background-color: white !important;
            outline: 1px solid #e2e5ec;
                outline-offset: -1px ;
        }
    }
    th:nth-child(2), td:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 35px;
        top: 0; 
        z-index: 2;
        width: 200px;
        background-color: white !important;
        outline: 1px solid #e2e5ec;
                outline-offset: -1px ;
    }
    tbody tr:first-child {
        position: sticky;
        top:25px;
        background: #fff !important;
        outline: 1px solid #e2e5ec;
        outline-offset: -1px ;
    } 




</style>