<template>
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
        <div>
            <ol class="breadcrumb fs-sm mb-1">
                <li class="breadcrumb-item">Dashboard</li>
                <li class="breadcrumb-item active" aria-current="page">Projects</li>
                <li class="breadcrumb-item active" aria-current="page">Add New WBS</li>
            </ol>
            <h4 class="main-title mb-0">WBS Codes</h4>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">Add WBS Codes</h6>
                </div>
                <div class="card-body">
                    <div class="row g-2 mb-1">
                        <Project :project="project" />
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3 mt-2">
                                    <label class="form-label">WBS Code</label>
                                    <input type="text" class="form-control" placeholder="Enter wbs code..."
                                        :class="{ 'is-invalid': errors.wbs_code }" v-model="project_cost.wbs_code">
                                    <span v-if="errors.wbs_code" class="invalid-feedback">{{ errors.wbs_code[0] }}</span>
                                </div>
                                <div class="col-md-3 mt-2">
                                    <label class="form-label">WBS Descripition</label>
                                    <input type="text" class="form-control" v-model="project_cost.wbs_description" placeholder="Enter wbs description..."  :class="{ 'is-invalid': errors.wbs_description }">
                                    <span v-if="errors.wbs_description" class="invalid-feedback">{{ errors.wbs_description[0] }}</span>
                                </div>
                                <div class="col-md-3 mt-2">
                                    <label for="" class="form-label">Level</label>
                                    <select class="form-control" :class="{ 'is-invalid': errors.level }" v-model="project_cost.level">
                                        <option value="">Select Level</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                    <span v-if="errors.level" class="invalid-feedback">{{ errors.level[0] }}</span>
                                </div>
                                <div class="col-md-3 mt-2">
                                    <label class="form-label">Budget</label>
                                    <input type="number" v-model="project_cost.budget" class="form-control" placeholder="Enter project budget..." :class="{ 'is-invalid': errors.budget }">
                                    <span v-if="errors.budget" class="invalid-feedback">{{ errors.budget[0] }}</span>
                                </div>
                                <div class="col-md-3 mt-2">
                                    <label class="form-label">Cost Type</label>
                                    <select class="form-control" v-model="project_cost.cost_type_id" :class="{ 'is-invalid': errors.cost_type_id }">
                                        <option value="">Select Cost Type</option>
                                        <option v-for="cost_type, key in cost_types" :value="cost_type.cost_type_id">{{
                                            cost_type.cost_type_name }}</option>
                                    </select>
                                    <span v-if="errors.cost_type_id" class="invalid-feedback">{{ errors.cost_type_id[0] }}</span>
                                </div>
                                <div class="col-md-3 mt-2">
                                    <label class="form-label">Is Quantity</label>
                                    <select class="form-control" v-model="project_cost.is_quantity" :class="{ 'is-invalid': errors.is_quantity }">
                                        <option value="">Select</option>
                                        <option :value=true>Yes</option>
                                        <option :value=false>No</option>
                                    </select>
                                    <span v-if="errors.is_quantity" class="invalid-feedback">{{ errors.is_quantity[0] }}</span>
                                </div>
                                <div class="col-md-3 mt-2" v-if="project_cost.is_quantity">
                                    <label class="form-label">Quantity</label>
                                    <input type="number" v-model="project_cost.quantity" class="form-control" placeholder="Enter quantity..." :class="{ 'is-invalid': errors.quantity }">
                                    <span v-if="errors.quantity" class="invalid-feedback">{{ errors.quantity[0] }}</span>
                                </div>
                                <div class="col-md-3 mt-2" v-if="project_cost.is_quantity">
                                    <label class="form-label">Quantity Cost Type</label>
                                    <select class="form-control" v-model="project_cost.quantity_cost_type_id" :class="{ 'is-invalid': errors.quantity_cost_type_id }">
                                        <option value="">Select Level</option>
                                        <option v-for="cost_type, key in cost_types" :value="cost_type.cost_type_id">{{
                                            cost_type.cost_type_name }}</option>
                                    </select>
                                    <span v-if="errors.quantity_cost_type_id" class="invalid-feedback">{{ errors.quantity_cost_type_id[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button class="btn btn-primary float-end" @click="addProjectCost()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Project from '@/components/Project'
import Pagination from "@/components/Pagination.vue";
export default {
    components: { Project, Pagination },
    data() {
        return {
            project: {},
            meta: {
                level: '',
                search: '',
                project_id: '',
                order_by: "asc",
                keyword: "project_cost_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
                trashed: false,
            },
            cost_types: [],
            project_costs: [],
            attachment: '',
            project_cost: {
                project_id: '',
                level: '',
                template_code: '',
                wbs_description: '',
                wbs_code: '',
                project_id: '',
                cost_type_id: '',
                budget: '',
                is_quantity: false,
                quantity_cost_type_id: '',
                quantity: '',
                projected_commitment: '',
            },
            errors: []
        };
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.meta.project_id = to.params.project_id
            vm.project_cost.project_id = to.params.project_id;
            let uri = { uri: "getProject", data: vm.meta };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.project = response.data.data
                    vm.getCostTypes()
                    vm.getProjectCosts()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        });
    },
    methods: {

        getCostTypes() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getCostTypes", data: vm.meta })
                .then(function (response) {
                    vm.cost_types = response.data.data
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getProjectCosts() {
            let vm = this
            let loader = vm.$loading.show()
            vm.$store.dispatch("post", { uri: "paginateProjectCosts", data: vm.meta })
                .then(function (response) {
                    loader.hide()
                    vm.project_costs = response.data.data
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    loader.hide()
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        addProjectCost() {
            let vm = this;
            this.project_cost.project_id = this.meta.project_id;
            this.project_cost.template_code = this.project_cost.wbs_description;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addProjectCost', data: this.project_cost })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success',response.data.message);
                    this.$router.push('/BATS/'+this.project_cost.project_id+'/edit');
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },
    }

};
</script>
