<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">Projects</li>
			</ol>
			<h4 class="main-title mb-0">Budget Request</h4>
		</div>
		<div class="d-flex align-items-center gap-2 mt-3 mt-md-0">
			<router-link to="/projects" class="btn btn-secondary d-flex align-items-center gap-2" v-can="'projects.view'">
				<i class="ri-arrow-left-s-line fs-18 lh-1"></i>Projects
			</router-link>
			<router-link to="/project_cost/pending_request" class="btn btn-success d-flex align-items-center gap-2" v-can="'budget_requests.view'">
				<i class="ri-list-check fs-18 lh-1"></i>Budget Request List
			</router-link>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-sm-12">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">Update Budget Request :: <span class="text-primary">{{ project_request?.request_no }}</span></h6>
				</div>
				<div class="card-body">
					<div class="row g-2 mb-1">
						<Project :project="project" />
						<div class="col-md-6">
							<div class="profile-item mb-3">
								<i class="ri-profile-line"></i>
								<div class="profile-item-body">
									<p>WBS Code</p>
									<p class="text-primary h6">{{ project_cost?.wbs_code }}</p>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="profile-item mb-3">
								<i class="ri-archive-drawer-line"></i>
								<div class="profile-item-body">
									<p>WBS Description</p>
									<p class="text-primary h6">{{ project_cost?.wbs_description }}</p>
								</div>
							</div>
						</div>
						<div class="col-sm-12" v-can="'budget_requests.view.change_wbs_code'">
							<label for="project_cost_id" class="form-label">
								WBS Code <span class="text-danger"> *</span>
							</label>
							<SearchProjectCost
                                :class="{ 'is-invalid': errors.project_cost_id }"
                                :customClass="{ 'is-invalid': errors.project_cost_id  }"
                                :initialize="project_request.project_cost_id"
                                id="project_cost_id"
                                label="wbs_code"
								label2="wbs_description"
                                placeholder="Select WBS Code & Discription"
                                :data="project_costs"
                                @selected="project_cost => project_request.project_cost_id = project_cost"
                            >
                            </SearchProjectCost>
							<span v-if="errors.project_cost_id" class="invalid-feedback">{{ errors.project_cost_id[0] }}</span>
						</div>
						<div class="col-sm-12">
							<label for="subject" class="form-label">
								Subject <span class="text-danger"> *</span>
							</label>
							<input type="text" class="form-control" v-model="project_request.subject" :class="{ 'is-invalid': errors.subject }" placeholder="Subject" />
							<span v-if="errors.subject" class="invalid-feedback">{{ errors.subject[0] }}</span>
						</div>
						<div class="col-sm-3">
							<label for="amount" class="form-label hstack gap-3">
								<span>Amount <span class="text-danger">*</span></span>
								<strong class="text-success ms-auto">{{ formatCurrency(project_request.amount) }}</strong>
							</label>
							<input type="number" class="form-control" v-model="project_request.amount" :class="{ 'is-invalid': errors.amount }" placeholder="Amount" @keyup="calculateRate" />
							<span v-if="errors.amount" class="invalid-feedback">{{ errors.amount[0] }}</span>
						</div>
						<div class="col-sm-3">
							<label for="is_gst" class="form-label">Is GST</label>
							<select name="" id="" class="form-control" v-model="project_request.is_gst">
								<option value=1>Yes</option>
								<option value=0>No</option>
							</select>
						</div>
						<div class="col-sm-3">
							<label for="quantity" class="form-label">Quantity</label>
							<input :disabled="!project_cost?.is_quantity" type="number" class="form-control" v-model="project_request.quantity" :class="{ 'is-invalid': errors.quantity }" placeholder="Quantity" @keyup="calculateRate" />
							<span v-if="errors.quantity" class="invalid-feedback">{{ errors.quantity[0] }}</span>
						</div>
						<div class="col-sm-3">
							<label for="rate" class="form-label hstack gap-3">
								<span>Rate</span>
								<strong class="text-success ms-auto">{{ formatCurrency(project_request.rate) }}</strong>
							</label>
							<input :disabled="!project_cost?.is_quantity" type="number" class="form-control" v-model="project_request.rate" :class="{ 'is-invalid': errors.rate }" placeholder="Rate" @keyup="calculateAmount" />
							<span v-if="errors.rate" class="invalid-feedback">{{ errors.rate[0] }}</span>
						</div>
					</div>
					<div class="row g-2 mt-2" v-if="project_request?.project_request_approvals?.length > 0">
						<label class="form-label">Select if already approved from following:</label>
						<div class="col-sm-4" v-for="stage,key in project_request.project_request_approvals">
							<input type="checkbox" :id="'role'+stage.role_id" class="checkbox mb-1" v-model="stage.selected" :disabled="enable_select(stage, key)">
							<label :for="'role'+stage.role_id" class="mx-1">{{ stage?.role?.role_name }}</label>
							<select v-if="stage.selected" class="form-select from-select-sm mt-2" v-model="stage.approved_by">
								<option value="">Select User</option>
								<option v-for="user, key in stage.users" :key="key" :value="user.user_id">
									{{ user.name }}
								</option>
							</select>
							<input v-if="stage.selected" class="form-control mt-2" type="file" @change="approvalAttachment($event, stage)">
						</div>
					</div>
					<div class="row g-2 mt-2">
						<div class="col-sm-12">
							<label for="note" class="form-label">Note</label>
							<textarea class="form-control" v-model="project_request.note" :class="{ 'is-invalid': errors.note }" placeholder="Note"></textarea>
							<span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
						</div>
					</div>
					<div class="row g-2 mt-2">
						<div class="col-sm-3">
							<label for="file_attachment" class="form-label">Attachment (Optional)</label>
							<input class="form-control mt-2" id="file_attachment" type="file" @change="fileAttachment($event)">
						</div>
					</div>
				</div>
				<div class="card-footer d-sm-flex justify-content-end">
					<router-link to="/project_cost/pending_request" class="btn btn-danger me-2">
						<i class="ri-close-line lh-1"></i> Discard
					</router-link>
					<button type="button" class="btn btn-primary" @click.prevent="updateProjectRequest">
						<i class="ri-save-line lh-1"></i> Submit
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Project from '@/components/Project'
let SearchProjectCost = require("@/components/SearchProjectCost.vue").default;
export default {
	components: { Project, SearchProjectCost },
	data() {
		return {
			project_request: {
                project_request_id:'',
				request_no: '',
				project_id: '',
				project_cost_id: '',
				cost_type_id:'',
				subject:'',
				amount:'',
				is_gst: '',
				is_quantity:'',
				quantity_cost_type_id:'',
				quantity:'',
				rate:'',
				note:'',
				file_attachment: '',
				project_request_approvals:[],
			},
			project: {},
			project_cost: {},
			project_costs: [],
			stages:[],
			errors: [],
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.project_request.project_id = to.params.project_id;
			vm.project_request.project_cost_id = to.params.project_cost_id;
            vm.project_request.project_request_id = to.params.project_request_id;
			vm.getProject()
		});
	},

	methods: {

		getProject() {
			let vm = this
			let uri = { uri: "getProject", data: vm.project_request };
			vm.$store.dispatch("post", uri)
			.then(function (response) {
				vm.project = response.data.data;
				vm.getProjectRequest()
			})
			.catch(function (error) {
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},
        getProjectRequest() {
			let vm = this
			let uri = { uri: "getProjectRequest", data: vm.project_request };
			vm.$store.dispatch("post", uri)
			.then(function (response) {
				vm.project_request = response.data.data;
                vm.project_request.project_request_approvals = [];
				vm.getProjectCost()
			})
			.catch(function (error) {
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},
		getProjectCost() {
			let vm = this
			let uri = { uri: "getProjectCost", data: vm.project_request };
			vm.$store.dispatch("post", uri)
			.then(function (response) {
				vm.project_cost = response.data.data
				vm.project_request.cost_type_id = vm.project_cost.cost_type_id
				vm.project_request.is_quantity = vm.project_cost.is_quantity
				vm.project_request.quantity_cost_type_id = vm.project_cost.quantity_cost_type_id
				vm.getStages()
			})
			.catch(function (error) {
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		getStages() {
			let vm = this;
			vm.$store.dispatch('post', { uri: 'getStages', data:{project_id: this.project_request.project_id}})
			.then(response => {
				vm.stages = response.data.data;
				vm.getNextStages()
				vm.getProjectCosts()
			})
			.catch(error => {
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			})
		},

		getProjectCosts() {
			let vm = this;
			vm.$store.dispatch('post', { uri: 'getProjectCosts', data:vm.project_request })
			.then(response => {
				vm.project_costs = response.data.data;
			})
			.catch(error => {
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			})
		},

		getNextStages() {
			let vm = this;
			let stage = vm.stages.find(stage => stage.role_id == vm.$store.getters.user.role_id);
			if(stage) {
				for (let index = stage.stage; index < (vm.stages.length-1); index++) {
					let data = {
						stage_id:vm.stages[index].stage_id,
						stage:vm.stages[index].stage,
						role_group_id:vm.stages[index].role_group_id,
						role_group:vm.stages[index].role_group,
						role_id:vm.stages[index].role_id,
						role:vm.stages[index].role,
						escalation_days:vm.stages[index].escalation_days,
						users:vm.stages[index].users,
						selected:false,
						approved_by: '',
						attachment: '',
						process_status: ''
					}
					vm.project_request.project_request_approvals.push(data);
				}
			}
		},

		approvalAttachment(e,stage) {
			stage.attachment = e.target.files[0]
		},

		fileAttachment(e) {
			this.project_request.file_attachment = e.target.files[0];
		},

		// enable_select(stage,key){
		// 	if(key==0)
		// 		return false;
		// 	else{
		// 		let obj = this.project_request.project_request_approvals.find(ele => ele.stage == stage.stage - 1);
        //         if (obj.approved_by == '' || obj.attachment == '') {
        //             return true;
        //         }
        //         else {
        //             return false;
        //         }
		// 	}
		// },

		enable_select(stage, key) {
			if (stage.users.length == 0) {
				stage.process_status = true;
				return true;
			}
			if (key == 0) {
				if (stage.users.length == 0 || (stage.approved_by != '' && stage.attachment != '')) {
					stage.process_status = true;
				}
				return false;
			}
			if (stage.users.length == 0 || (stage.approved_by != '' && stage.attachment != '')) {
				stage.process_status = true;
			}
			let temp = [];
			for (let i = 0; i < key; i++) {
				temp.push(this.project_request.project_request_approvals[i]);
			}
			if (temp.every(ele => ele.process_status == true)) {
				return false;
			} else {
				return true;
			}
		},

		updateProjectRequest() {
			let vm = this
			let err_flag = false;
			vm.project_request.project_request_approvals.forEach(element => {
				if(element.selected && (element.approved_by=='' || element.attachment=='')) {
					alert('Next stage user or attachment details are missing')
					err_flag = true;
				}
			});
			if(err_flag) return;
			const formData = new FormData()
            formData.append('project_request_id', vm.project_request.project_request_id)
			formData.append('project_id', vm.project_request.project_id)
			formData.append('project_cost_id', vm.project_request.project_cost_id)
			formData.append('cost_type_id', vm.project_request.cost_type_id)
			formData.append('subject', vm.project_request.subject)
			formData.append('amount', vm.project_request.amount)
			formData.append('is_gst', vm.project_request.is_gst);
			formData.append('is_quantity', vm.project_request.is_quantity)
			formData.append('quantity', vm.project_request.quantity)
			formData.append('quantity_cost_type_id', vm.project_request.quantity_cost_type_id)
			formData.append('rate', vm.project_request.rate)
			formData.append('note', vm.project_request.note)
			formData.append('user_id', vm.$store.getters.user.user_id)
			formData.append('file_attachment', this.project_request.file_attachment);
			vm.project_request.project_request_approvals.forEach(element => {
				if(element.selected) {
					formData.append("project_request_stages[]", element.stage_id)
					formData.append("project_request_users[]", element.approved_by)
					formData.append("project_request_roles[]", element.role_id)
					formData.append("project_request_attachments[]", element.attachment)
				}
			})
			let loader = vm.$loading.show();
			vm.$store.dispatch("post", { uri: "updateProjectRequest", data: formData })
			.then(function (response) {
				loader.hide();
				vm.$store.dispatch("success", response.data.message);
				vm.$router.push('/project_cost/pending_request');
			})
			.catch(function (error) {
				loader.hide();
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		showRequests() {
			this.$router.push('/project_cost/' + this.project.project_id + '/request')
		},

		discard() {
			this.$router.push('/BATS/' + this.project.project_id + '/user');
		},

		calculateRate() {
			if(this.project_cost.is_quantity) {
				this.project_request.rate = (parseFloat(this.project_request.amount) / parseFloat(this.project_request.quantity)).toFixed(2);
			}
		},

		calculateAmount() {
			if(this.project_cost.is_quantity) {
				this.project_request.amount = (parseFloat(this.project_request.quantity) * parseFloat(this.project_request.rate)).toFixed(0);
			}
		},

		formatCurrency(amount) {
			if(amount) {
				return amount.toLocaleString("en-IN", { maximumFractionDigits: 0, style: "currency", currency: "INR" });
			}
		},
	},
};
</script>