<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">Transfer</h2>
            <!-- <router-link to="/users/create" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-add-line"></i> ADD USER</router-link> -->
        </div>
        <div class="card">
            <div class="card-header">
                <strong>BATS-Transfer</strong>
            </div>
            <div class="card-body">
                <div class="form-group mb-2 d-flex">
                    <input class="form-control form-control-sm" type="text" placeholder="Type keyword and press enter key"
                        v-model="meta.search" @keypress.enter="search()" />
                </div>
                <div class="table-responsive table-responsive-sm">
                    <table class="table table-sm text-nowrap table-bordered mb-0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th @click="sort('wbs_code')">WBS Code
                                    <span>
                                        <i v-if="meta.keyword == 'wbs_code' && meta.order_by == 'asc'"
                                            class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'wbs_code' && meta.order_by == 'desc'"
                                            class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('budget')">Budget
                                    <span>
                                        <i v-if="meta.keyword == 'budget' && meta.order_by == 'asc'"
                                            class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'budget' && meta.order_by == 'desc'"
                                            class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('balance')">Balance
                                    <span>
                                        <i v-if="meta.keyword == 'balance' && meta.order_by == 'asc'"
                                            class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'balance' && meta.order_by == 'desc'"
                                            class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('outward')">Outward
                                    <span>
                                        <i v-if="meta.keyword == 'outward' && meta.order_by == 'asc'"
                                            class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'outward' && meta.order_by == 'desc'"
                                            class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('inward')">Inward
                                    <span>
                                        <i v-if="meta.keyword == 'inward' && meta.order_by == 'asc'"
                                            class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'inward' && meta.order_by == 'desc'"
                                            class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="transfers.length == 0">
                                <td colspan="8" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(transfer, key) in transfers" :key="key">
                                <td class="text-center">{{ meta.from + key }}</td>
                                <td>{{ transfer }}</td>
                                <td></td>
                                <td></td>
                                <td><input type="text" class="form-control form-control-sm"></td>
                                <td><input type="text" class="form-control form-control-sm"></td>


                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                    <select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </select>
                    <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
    components: { Pagination },
    data() {
        return {
            meta: {
                search: "",
                order_by: "asc",
                keyword: "transfer_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
            },


            transfers: [],
            errors: [],
        };
    },
    mounted() {
        if(this.$store.getters?.user){
            this.index();
        }
        
    },
    methods: {
        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },
        index() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "paginateTransfers", data: vm.meta })
                .then(function (response) {
                    vm.transfers = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },
    },
};
</script>



