<template>
    <div class="">
        <div class="card">
            <div class="card-header">
                <div class="d-sm-flex align-items-center justify-content-between">
                    <div>
                        <strong>Logs</strong>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-3">
                        <label class="form-label mb-0">Users</label>
                        <search
                            :class="{ 'is-invalid': errors.user_id }"
                            :customClass="{ 'is-invalid': errors.user_id  }"
                            :initialize="meta.user_id"
                            id="user_id"
                            label="name"
                            placeholder="Select User"
                            :data="users"
                            @change="search()"
                            @input="selectUser($event)"
                            ref="user"
                        />
                    </div>
                    <div class="col-md-3">
                        <label class="form-label mb-0">Project Groups</label>
                        <div class="input-group mb-3">
                            <select class="form-control form-control-sm"
                            v-model="meta.project_group_id"
                            @change="getProjects()">
                            <option value="">Select Project Group</option>
                            <option v-for="(project_group, project_group_key) in project_groups" :key="project_group_key"
                                :value="project_group.project_group_id">{{
                                    project_group.project_group_name }}</option>
                            </select>
                            <div v-if="!project_groups.length" :class="spinner" class="spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label mb-0">Projects</label>
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="meta.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @change="search()"
                            @input="selectProject($event)"
                            ref="project"
                        />
                    </div>
                     <div class="col-md-3">
                        <label class="form-label mb-0">Project Activity Groups</label>
                        <div class="input-group mb-3">
                            <select class="form-control form-control-sm"
                                v-model="meta.project_activity_group_id" @change="search()">
                                <option value="">Select Project Activity Group</option>
                                <option v-for="(project_activity_group, project_activity_group_key) in project_activity_groups" :key="project_activity_group_key"
                                    :value="project_activity_group.project_activity_group_id">{{
                                        project_activity_group?.activity_group?.activity_group_name }}</option>
                            </select>
                            <div :class="project_group_spinner" class="spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label mb-0 mt-1">From Date</label>
                        <div class="input-group date" id="from_date">
                            <input type="date" class="form-control form-control-sm" :max="today" :class="{ 'is-invalid': errors.from_date }" v-model="meta.from_date" @change="search()" />
                        </div>
                        <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label mb-0 mt-1">To Date</label>
                        <div class="input-group date" id="to_date">
                            <input type="date" class="form-control form-control-sm" :max="today" :class="{ 'is-invalid': errors.to_date }" v-model="meta.to_date" @change="search()" />
                        </div>
                        <span v-if="errors.to_date" style="margin-top: 0.25rem;font-size: 0.875em;color: #dc3545;">{{ errors.to_date[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label mb-0 mt-1">Search</label>
                        <input class="form-control form-control-sm" type="text" placeholder="Type keyword and press enter key" v-model="meta.search" @keypress.enter="search()" />
                    </div>
                    <div class="col-md-3">
                        <button class="btn btn-primary btn-sm mt-4" @click="reset()"><i class="ri-refresh-line"></i>&nbsp;RESET</button>
                    </div>
                </div>
                <div class="form-group mb-2 d-flex">
                    <table class="table table-sm text-nowrap table-bordered mb-0">
                        <thead>
                            <tr>
                                <th class="text-center" width="3%">#</th>
                                <th @click="sort('name')" width="20%">
                                    User
                                    <span>
                                        <i v-if="meta.keyword == 'name' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'name' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('project_name')" width="20%">
                                    Project
                                    <span>
                                        <i v-if="meta.keyword == 'project_name' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'project_name' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('project_activity_group')" width="20%">
                                    Project Activity Group
                                    <span>
                                        <i v-if="meta.keyword == 'project_activity_group' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'project_activity_group' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th class="text-center" @click="sort('operation')" width="10%">
                                    Operation
                                    <span>
                                        <i v-if="meta.keyword == 'operation' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'operation' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('date_time')" width="15%">
                                    Date & Time
                                    <span>
                                        <i v-if="meta.keyword == 'date_time' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'date_time' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th class="text-center" width="10%">Note</th>
                                <th class="text-center" width="10%">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="logs.length == 0">
                                <td colspan="8" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(log, key) in logs" :key="key">
                                <td class="text-center">{{ meta.from + key }}</td>
                                <td>{{ log?.user?.name }}</td>
                                <td>{{ log?.project?.project_name }}</td>
                                <td>{{ log?.project_activity_group?.activity_group?.activity_group_name }}</td>
                                <td class="text-center"><span :style="log.badge">{{ log.operation }}</span></td>
                                <td>{{ convertDateFormat(log.date_time) }}</td>
                                <td>{{ log?.note }}</td>
                                <td class="text-center">
                                    <a href="javascript:void(0)" v-if="log.log_parameters.length" class="me-2 text-success" @click="logParameters(log)"><i class="ri-eye-line icon_hgt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                    <select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </select>
                    <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    let Search = require("@/components/Search.vue").default;
    import moment from 'moment';

    export default {
        components: { Pagination, Search },
        data() {
            return {
                meta: {
                    search: "",
                    user_id:'',
                    project_group_id:'',
                    project_id:'',
                    project_activity_group_id:'',
                    from_date:'',
                    to_date:'',
                    order_by: "desc",
                    keyword: "log_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                spinner:'spinner-border',
                logs: [],
                users:[],
                project_groups:[],
                projects:[],
                project_activity_groups:[],
                errors: [],
                today:'',
                project_spinner:'',
                project_group_spinner:''
            };
        },
        mounted() {
            if(this.$store.getters.user){
                this.getUsers();
            }
            
            this.meta.to_date = moment().format("YYYY-MM-DD")
            this.today = moment().format("YYYY-MM-DD")
        },
        methods: {
            reset(){
                this.meta.search = '',
                this.meta.user_id = '',
                this.meta.project_group_id = '',
                this.meta.project_id = '',
                this.meta.project_activity_group_id = '',
                this.meta.from_date = '',
                this.meta.to_date =  moment().format("YYYY-MM-DD"),
                this.meta.order_by = 'desc',
                this.meta.keyword = 'log_id',
                this.meta.per_page = 10,
                this.meta.totalRows = 0,
                this.meta.page = 1,
                this.meta.lastPage = 1,
                this.meta.from = 1,
                this.meta.to = 1,
                this.meta.maxPage = 1,
                this.meta.trashed = false
                this.index()
            },
            selectUser(e) {
                let vm = this;
                this.meta.page = 1
                vm.meta.user_id = e;
                vm.index()
            },
            selectProject(e) {
                let vm = this;
                this.meta.page = 1
                vm.meta.project_id = e;
                vm.getProjectActivityGroups()
            },
            getUsers() {
                let vm = this;
                vm.$refs.user.show_spinner = true
                vm.$store.dispatch("post", { uri: "getUsers" })
                    .then(function (response) {
                        vm.users = response.data.data
                        vm.$refs.user.show_spinner = false
                        vm.getProjectGroups()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getProjectGroups() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getProjectGroups" })
                    .then(function (response) {
                        vm.project_groups = response.data.data
                        vm.index()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getProjects() {
                let vm = this
                vm.$refs.project.show_spinner = true
                vm.$store.dispatch("post", { uri: "getProjectGroupProjects", data:{project_group_id: vm.meta.project_group_id} })
                    .then(function (response) {
                        vm.projects = response.data
                        vm.$refs.project.show_spinner = false
                    })
                    .catch(function (error) {
                        vm.project_spinner = ''
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getProjectActivityGroups() {
                let vm = this;
                vm.project_group_spinner = 'spinner-border'
                vm.$store.dispatch("post", { uri: "getProjectActivityGroups", data:{project_id: vm.meta.project_id} })
                    .then(function (response) {
                        vm.project_group_spinner = ''
                        vm.project_activity_groups = response.data.data
                        vm.index()
                    })
                    .catch(function (error) {
                        vm.project_group_spinner = ''
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            logParameters(log){
                this.$router.push('/log/'+log.log_id+'/view')
            },
            convertDateFormat(date_time){
                if(date_time){
                    return moment(date_time).format("DD-MMM-yyyy hh:mm a")
                }else{
                    return '-'
                }
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "paginateLogs", data: vm.meta })
                    .then(function (response) {
                        loader.hide()
                        vm.logs = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                // this.index();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
        },
    };
</script>
<style scoped>
.input-group .spinner-border {
    position: absolute;
    right: 10px;
    top: 24%;
    color:#9b9b9b;
}
</style>
