import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
var moment = require('moment');

const app = createApp(App);
app.use(store);
app.use(router);
app.use(LoadingPlugin);

app.config.globalProperties.$formatDate = (date, format) => {
	const options = {};
	if(date ==null || date=='') {
		return null;
	}
	else if(format === 'long') {
		options.weekday = 'long';
		options.year = 'numeric';
		options.month = 'long';
		options.day = 'numeric';
		return new Date(date).toLocaleDateString(undefined, options);
	} 
	else if (format === 'short') {
		options.year = 'numeric';
		options.month = 'short';
		options.day = 'numeric';
		return new Date(date).toLocaleDateString(undefined, options);
	}
	else if(format === 'india') {
		return moment(date).format('DD-MM-YY hh:mm A')
	}
	else if(format === 'time') {
		return moment(date).format('HH:mm:ss')
	}
    else if(format === 'date') {
		return moment(date).format('DD-MM-YYYY')
	}
	else if(format === 'indian_time') {
		return moment(date).format('hh:mm A')
	}
	else {
		return new Date(date).toLocaleDateString(undefined, options);
	}
};

app.directive('can',{
    mounted: function(el, binding){
        let permissions = store.getters.permissions;
        let user = store.getters.user;
        if(permissions && permissions.length != 0){
            let permission = permissions.filter(element => {
                if(user){
                    if(user.user_type=='PMT') {
                        return (element.ability.ability == binding.value) && (element.ability.user_type=='PMT' || element.ability.user_type=='BOTH');
                    }
                    else if(user.user_type=='BATS') {
                        return (element.ability.ability == binding.value) && (element.ability.user_type=='BATS' || element.ability.user_type=='BOTH');
                    }
                    else {
                        return element.ability.ability == binding.value;
                    }
                }
            })          
            if(!permission[0]){
                el.parentNode.removeChild(el);
            }
        }
        else{
            el.parentNode.removeChild(el);
        }
    }
});

app.mount('#app');
