<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">Report Line Items</h2>
            <router-link v-can="'project_costs.create'" to="/bats/report_templates/create" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-add-line"></i> New Template</router-link>
        </div>
        <div class="card">
            <div class="card-header">
                <strong> Report Line Items</strong>
            </div>
            <div class="card-body">
                <div class="form-group mb-2 d-flex">
                    <input class="form-control form-control-sm" type="text" placeholder="Type keyword and press enter key"  v-model="meta.search" @keypress.enter="search()" />
                    <a @click="deleteValues()" v-if="delete_values!= 0" href="javascript:void(0)" class="btn btn-sm btn-primary ms-2 "><i class="ri-delete-bin-line icon_hgt"></i></a>
                </div>
                <div class="table-responsive table-resposive-sm">
                    <table class="table table-sm text-nowrap table-bordered mb-0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th @click="sort('project_type_id')">
                                    Template Code
                                    <span>
                                        <i v-if="meta.keyword=='project_type_id' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='project_type_id' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('project_type_id')">
                                    Project Type
                                    <span>
                                        <i v-if="meta.keyword=='project_type_id' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='project_type_id' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('unit_id')">
                                    UOM
                                    <span>
                                        <i v-if="meta.keyword=='unit_id' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='unit_id' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th class="text-center" v-can="'project_costs.delete'">Status</th>
                                <th class="text-center" v-can="'project_costs.update'">Actions</th>
                                <!-- <th class="text-center"><input type="checkbox" /></th> -->
                                <th v-can="'project_costs.delete'" class="text-center"><input type="checkbox" :checked="isAllSelected" @change="selectAll"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center" colspan="7" v-if="!template_master_costs.length">No records found</td>
                            </tr>
                            <tr v-for="(template_master_cost, key) in template_master_costs" :key="key">
                                <td class="text-center">{{ meta.from+key }}</td>
                                <td>{{ template_master_cost.template_code}}</td>
                                <td>{{ template_master_cost.project_type?.project_type_name }}</td>
                                <td>{{ template_master_cost.unit?.unit_name}}</td>
                                <td class="text-center" v-can="'project_costs.delete'">
                                    <div class="form-switch form-switch">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            :id="'template_master_cost' + template_master_cost.template_master_cost_id"
                                            :checked="template_master_cost.status"
                                            :value="template_master_cost.status"
                                            @change="deleteTemplateMasterCost(template_master_cost)"
                                        />
                                        <label class="custom-control-label" :for="'template_master_cost' + template_master_cost.template_master_cost_id"></label>
                                    </div>
                                </td>
                                <td v-can="'project_costs.update'" class="text-center">
                                    <nav>
                                        <a href="javascript:void(0)" class="text-success me-2" @click.prevent="edit(template_master_cost)" :disabled="!template_master_cost.status"><i class="ri-pencil-line icon_hgt"></i></a>
                                        <!-- <a href="javascript:void(0)" class="text-danger" @click.prevent="trash(template_master_cost)"><i class="ri-delete-bin-line icon_hgt"></i></a> -->
                                    </nav>
                                </td>
                                <td v-can="'project_costs.delete'" class="text-center"><input type="checkbox" v-model="delete_values" :value="template_master_cost.template_master_cost_id" @change="select" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                    <select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </select>
                    <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "template_master_cost_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                isAllSelected: false,
                delete_values: [],
                template_master_costs: [],
                errors: [],
            };
        },
        mounted() {
            if(this.$store.getters?.user){
                this.index();
            }
        },
        methods: {
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateTemplateMasterCosts", data: vm.meta })
                    .then(function (response) {
                        vm.template_master_costs = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            edit(template_master_cost) {
                this.$router.push("/template_master_costs/" + template_master_cost.template_master_cost_id + "/edit");
            },
            deleteTemplateMasterCost(template_master_cost) {
                let vm = this;
                let loader = vm.$loading.show();
                template_master_cost.status = template_master_cost.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deleteTemplateMasterCost", data: template_master_cost })
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", response.data.message);
                        vm.isAllSelected = false
                        vm.delete_values = []
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            confirmDelete(template_master_cost) {
                this.confirm.name = template_master_cost.name;
                this.confirm.template_master_cost_id = template_master_cost.template_master_cost_id;
            },

            trash(template_master_cost) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    vm.$store
                        .dispatch("post", { uri: "forceDeleteTemplateMasterCost", data: template_master_cost })
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Record deleted successfully");
                            vm.index();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            selectAll() {
                if (this.isAllSelected) {
                    this.delete_values = [];
                    this.isAllSelected = false;
                } else {
                    this.delete_values = [];
                    for (var template_master_cost in this.template_master_costs) {
                        this.delete_values.push(this.template_master_costs[template_master_cost].template_master_cost_id);
                    }
                    this.isAllSelected = true;
                }
            },
            select() {
                if (this.delete_values.length !== this.template_master_costs.length) {
                    this.isAllSelected = false;
                } else {
                    this.isAllSelected = true;
                }
            },
            deleteValues() {
                confirm("Are You Sure Want to delete?");
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "multipleDeleteTemplateMasterCost", data: { template_master_cost_id: this.delete_values } })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Template Master Cost is successfully deleted.");
                        vm.isAllSelected = false
                        vm.delete_values = []
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
        },
    };
</script>
<style scoped>
    .item + .item:before {
        content: ", ";
    }
</style>
