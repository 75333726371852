<template>
    <figure class="gaugemeter-figure mb-0">
        <div :id="container"></div>
    </figure>
    
</template>

<script>
    var Highcharts = require("highcharts");
    require("highcharts/modules/heatmap")(Highcharts);
	require("highcharts/modules/data")(Highcharts);
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/export-data")(Highcharts);
    require("highcharts/modules/accessibility")(Highcharts);
    require("highcharts/highcharts-more")(Highcharts);
    export default {
        name: "GaugeMeter",
        props: {
			container: {
                default: null,
            },            
            title: {
                default: null,
            },
            max: {
				default: null,
			},
			series: {
				default: null,
			},
            height: {
				// default: '78%',
                default: '60%',
			},
            marginTop: {
                default: '30',
			},
            width: {
				default: null,
			},
            label_font_size: String, 
            plot_bands: Array, 
            actual: Array,
            plan: Array,
            vartical_align:{
                default:'top'
            },

        },

        watch: {
			actual: {
                handler() {
                    this.loadChart();

                },
                deep: true,
            },
            plan:{
                handler() {
                    this.loadChart();

                },
                deep: true,
            },
            max: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
        },

        mounted() {
            this.loadChart();   
        },

        methods: {
            loadChart() {
                let vm = this;
                Highcharts.chart(vm.container, {
                    credits: {
                        enabled: false,
                    },
                    exporting:{
                        enabled:false,
                    },
                    chart: {
                        type: "gauge",
                        marginTop: 22,
                        marginBottom: 0,
                        plotBackgroundColor: null,
                        plotBackgroundImage: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                        // height: '78%'
                        height:vm.height,
                        width:vm.width,
                    },
                    title: {
                        text: vm.title,
                        verticalAlign: vm.vartical_align,
                        floating: true,
                        // marginBottom: 10,
                        style: {
                            fontSize: vm.label_font_size,
                        }
                    },
                    pane: {
                        startAngle: -90,
                        endAngle: 89.9,
                        background: null,
                        center: ['50%', '75%'],
                        size: '110%'
                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        tickPixelInterval: 50,
                        tickPosition: 'inside',
                        tickColor: '#FFFFFF',
                        tickLength: 20,
                        tickWidth: 2,
                        minorTickInterval: null,
                        labels: {
                            distance: 20,
                            style: {
                                fontSize: '12px'
                            }
                        },
                        lineWidth: 0,
                        plotBands: this.plot_bands
                    },
                    series: [
                        {
                            name:'Actual',
                            data: vm.actual,    
                            tooltip: {
                                valueDecimal:'',
                                valueSuffix: '%'
                            },
                            dataLabels: {
                                format: '{y} %',
                                borderWidth: 0,
                                color: (
                                    Highcharts.defaultOptions.title &&
                                    Highcharts.defaultOptions.title.style &&
                                    Highcharts.defaultOptions.title.style.color
                                ) || '#333333',
                                style: {
                                    fontSize: vm.label_font_size
                                }
                            },
                            dial: {
                                radius: '80%',
                                backgroundColor: 'gray',
                                baseWidth: 12,
                                baseLength: '0%',
                                rearLength: '0%'
                            },
                            pivot: {
                                backgroundColor: 'gray',
                                radius: 6
                            }
                        },
                        {
                            name:'Plan',
                            data: vm.plan,    
                            tooltip: {
                                valueDecimal:'',
                                valueSuffix: '%'
                            },
                            dataLabels: {
                                format: '{y} %',
                                borderWidth: 0,
                                color: (
                                    Highcharts.defaultOptions.title &&
                                    Highcharts.defaultOptions.title.style &&
                                    Highcharts.defaultOptions.title.style.color
                                ) || '#333333',
                                style: {
                                    fontSize: '10px'
                                }
                            },
                            dial: {
                                radius: '80%',
                                backgroundColor: 'gray',
                                baseWidth: 12,
                                baseLength: '0%',
                                rearLength: '0%'
                            },
                            pivot: {
                                backgroundColor: '#F0F0F0',
                                radius: 6
                            }
                        }
                    ]
                });
            }
        }
    };
</script>