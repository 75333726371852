<template>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <!-- <h6 class="card-title">Existing Projects</h6> -->
                    <strong style="font-size:medium;"> Inactive Projects</strong>
                    
                </div>

                <!-- <div class="card-header">
                <strong style="font-size:medium;"> PMT Master Templates</strong>
                <router-link v-can="'templates.create'" to="/templates/create" type="submit" class="btn btn-sm mb-2 btn-primary" style="float:right;"><i class="ri-add-line"></i> Add PMT Master Template</router-link>
            </div> -->
                <div class="card-body">
                    <div class="form-group mb-2">
                        <div class="row">
                            <div class="col-sm-3">
                                <label class="form-label">Project Type</label>
                                <select class="form-control form-control-sm"
                                    :class="{ 'is-invalid': errors.project_type_id }" v-model="meta.project_type_id"
                                    @change="search()">
                                    <option value="">Select Project Type </option>
                                    <option v-for="(project_type, key) in project_types" :key="key"
                                        :value="project_type.project_type_id">{{ project_type.project_type_name }} </option>
                                </select>
                                <span v-if="errors.project_type_id" class="invalid-feedback">{{ errors.project_type_id[0]
                                }}</span>
                            </div>
                            <div class="col-sm-3">
                                <label class="form-label">Project Category</label>
                                <select class="form-control form-control-sm"
                                    :class="{ 'is-invalid': errors.project_category_id }" v-model="meta.project_category_id"
                                    @change="search()">
                                    <option value="">Select Project Category</option>
                                    <option v-for="(project_category, key) in project_categories" :key="key"
                                        :value="project_category.project_category_id">{{
                                            project_category.project_category_name }}</option>
                                </select>
                                <span v-if="errors.project_category_id" class="invalid-feedback">{{
                                    errors.project_category_id[0] }}</span>
                            </div>
                            <div class="col-sm-3 ">
                                <label class="form-label">Project Group</label>
                                <select class="form-control form-control-sm"
                                    :class="{ 'is-invalid': errors.project_group_id }" v-model="meta.project_group_id"
                                    @change="search()">
                                    <option value="">Select Project Group </option>
                                    <option v-for="(project_group, key) in project_groups" :key="key"
                                        :value="project_group.project_group_id">{{ project_group.project_group_name }}
                                    </option>
                                </select>
                                <span v-if="errors.project_group_id" class="invalid-feedback">{{ errors.project_group_id[0]
                                }}</span>
                            </div>
                            <div class="col-sm-3">
                                <label class="form-label">Search</label>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="Type keyword and press enter key" v-model="meta.search"
                                    @keypress.enter="search()" />
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm table-bordered table-striped mb-0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th @click="sort('project_code')">
                                        Project Code
                                        <span>
                                            <i v-if="meta.keyword == 'project_code' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'project_code' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('project_id')">
                                        Project Name
                                        <span>
                                            <i v-if="meta.keyword == 'project_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'project_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('plant_id')">
                                        Plant Name
                                        <span>
                                            <i v-if="meta.keyword == 'plant_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'plant_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('project_type_id')">
                                        Project Type
                                        <span>
                                            <i v-if="meta.keyword == 'project_type_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'project_type_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('project_category_id')">
                                        Project Catogry
                                        <span>
                                            <i v-if="meta.keyword == 'project_category_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'project_category_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('project_group_id')">
                                        Project Group
                                        <span>
                                            <i v-if="meta.keyword == 'project_group_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'project_group_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-if="!projects.length" class="text-center" colspan="10">No records found</td>
                                </tr>
                                <tr v-for="project, key in projects" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>
                                        <a href="#" @click.prevent="viewProject(project)">
                                            {{ project.project_code }}
                                        </a>
                                    </td>
                                    <td>
                                        <a href="#" @click.prevent="viewProject(project)">
                                            {{ project.project_name }}
                                        </a>
                                    </td>
                                    <td>{{ project?.plant?.plant_name }}</td>
                                    <td>{{ project?.project_type?.project_type_name }}</td>
                                    <td>{{ project?.project_category?.project_category_name }}</td>
                                    <td>{{ project?.project_group?.project_group_name }}</td>
                                    <td class="d-flex justify-content-center gap-1">
                                        <a class="btn btn-danger btn-icon" v-can="'pmt_user_forms.view'" href="#"
                                            @click.prevent="updateProjectStatus(project)" title="Active">
                                            <i class="ri-delete-bin-line icon_hgt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <select class="form-select from-select-sm width-75" v-model="meta.per_page"
                            @change="onPerPageChange">
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>25</option>
                            <option>30</option>
                        </select>
                        <!-- <span>Showing {{ meta.from }} of {{ meta.totalRows }} entries</span> -->

                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                            @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
    components: { Pagination },
    data() {
        return {
            meta: {
                search: '',
                order_by: "desc",
                keyword: "project_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
                type: 'pmt_paginate',
                project_type_id: "",
                project_category_id: "",
                project_group_id: "",
            },
            projects: [],
            project_types: [],
            project_categories: [],
            project_groups: [],
            selected_projects: [],
            errors: [],
            permissions: []
        }
    },

    mounted() {
        if(this.$store.getters?.user){
            this.index();
            this.getProjectTypes();
        }
        
        this.permissions = this.$store.getters.permissions;
    },
    methods: {
        index() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "paginateInActiveProjects", data: vm.meta };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide()
                    vm.projects = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },

        edit(project) {
            this.$router.push("/project_update/" + project.project_id + "/edit");
        },

        updateProjectStatus(project){
            console.log("success")
            let vm = this;
            
            let loader = vm.$loading.show();
            vm.$store.dispatch("post", { uri: "updateProjectStatus", data: project})
                .then(function (response) {
                    loader.hide();
                    console.log("response", response)
                    vm.$store.dispatch("success", response.data.message);
                    vm.index();
                    // vm.isAllSelected = false;
                    // vm.selected_users = [];
                    // vm.getExistingUsers();
                    // vm.index();
                })
                .catch(function (error) {
                    loader.hide();
                    // vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        showWithdrawList(project) {
            this.$router.push('/BATS/' + project.project_id + '/withdraw_list')
        },
        viewProject(project) {
            this.$router.push("/project/" + project.project_id + "/view");
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },

        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },
        getProjectTypes() {
            let vm = this;
            vm.$store.dispatch("post", { uri: "getProjectTypes" })
                .then(function (response) {
                    vm.project_types = response.data.data;
                    vm.getProjectCategories();
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getProjectCategories() {
            let vm = this;
            vm.$store.dispatch("post", { uri: "getProjectCategories" })
                .then(function (response) {
                    vm.project_categories = response.data.data;
                    vm.getProjectGroups();
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getProjectGroups() {
            let vm = this;
            vm.$store.dispatch("post", { uri: "getProjectGroups" })
                .then(function (response) {
                    vm.project_groups = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        disableTemplateButton(){
            let disable_template_button = this.permissions.filter(ele => {
                return (ele.ability.ability == 'project_templates.update' || 
                        ele.ability.ability == 'project_costs.update');
            })
            if(disable_template_button.length)
                return true;
            else
                return false;
        }
    },
};
</script>