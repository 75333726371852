<template>
    <div class="">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Hindrance Report</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                         <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="hindrance.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Projects</label>
                        <!-- <select class="form-select form-select-sm" :class="{'is-invalid': errors?.project_id}" v-model="hindrance.project_id">
                            <option value=""> Select Project </option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{project.project_name }} </option>
                        </select> -->
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="hindrance.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @input="project1 => hindrance.project_id = project1"
                        >
                        </search>
                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">From Date</label>
                        <div class="input-group date" id="from_date_range">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.from_date_range }" v-model="hindrance.from_date_range" />
                        </div>
                        <span v-if="errors.from_date_range" class="invalid-feedback">{{ errors.from_date_range[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">To Date</label>
                        <div class="input-group date" id="to_date_range">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.to_date_range }" v-model="hindrance.to_date_range" />
                        </div>
                        <span v-if="errors.to_date_range" style="margin-top: 0.25rem;font-size: 0.875em;color: #dc3545;">{{ errors.to_date_range[0] }}</span>
                    </div>                    
                    <div class="col-md-12 mt-1">
                        <div style="float:right">
                        <button class="btn btn-sm btn-primary me-2" @click="getHindrances()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-warning me-2 " @click="downloadHindranceReport()"><i class="ri-download-line icon_hgt"></i> Download </button>
                        <button class="btn btn-sm btn-info " @click="sendEmailHindranceReport()"><i class="ri-mail-send-line icon_hgt"></i> Send Email </button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-wrap table-bordered mb-0">
                                <thead class="bg-primary bg-opacity-50">
                                    <tr class="text-center" style="vertical-align: middle;">
                                        <th>Date</th>
                                        <th>Work Interruption</th>
                                        <th>Period</th>
                                        <th>Duration</th>
                                    </tr>
                                </thead>
                                <tbody v-if="hindrances?.length == 0">
                                    <tr>
                                        <td colspan="4" class="text-center">No Records to display!...</td>
                                    </tr>
                                </tbody>
                                <tbody v-else v-for="hindrance, key in hindrances" :key="key">
                                    <tr class="bg-primary bg-opacity-25">
                                        <td><strong>{{ hindrance.month_year }}</strong></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr v-if="hindrance.job_parameters?.length == 0">
                                        <td colspan="4" class="text-center">No Records to display!...</td>
                                    </tr>
                                    <tr v-else v-for="parameter, key in hindrance.job_parameters" :key="key">
                                        <td>{{ format_date(parameter.to_date) }}</td>
                                        <td>{{ parameter.parameter_value }}</td>
                                        <td class="text-center">{{ convertDateFormat(parameter) }}</td>
                                        <td class="text-center">{{ calculateHrs(parameter) }}</td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
let Search = require("@/components/Search.vue").default;
import moment from 'moment';
    export default {
        components: {Search },
        data() {
            return {    
                initial:true,            
                hindrances:[],
                projects:[],
                errors: [],
                hindrance: {
                    project_id:'',
                    from_date_range:'',
                    to_date_range:'',
                    project_group_id:'',
                    isDownload:false
                },
                projectgroups:[],
            };
        },
        mounted() {  
            if(this.$store.getters?.user){
                this.getProjectGroups();
            }          
            
            this.hindrance.to_date_range = moment().format('yyyy-MM-DD')
        },
        methods: {
            sendEmailHindranceReport(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "downloadHindranceReport", data: vm.hindrance})
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Hindrance summary - Mail sent successfully");
                    })
                    .catch(function (error) {
                        loader.hide()
                        if(error.response.data.length){
                            console.log(error.response.data[1])
                            vm.$store.dispatch("error", error.response.data[1]);
                        }
                    });
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.hindrance.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.hindrance.project_group_id) {
                                vm.hindrance.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            calculateHrs(parameter){
                if(parameter.from_date){
                    let duration = moment.duration(moment(parameter.to_date.parameter_value).diff(moment(parameter.from_date.parameter_value)));
                    return duration.asHours()+ ' Hrs'
                }else{
                    return '-'
                }
            },
            convertDateFormat(parameter){
                if(parameter.from_date){
                    return moment(parameter.from_date.parameter_value, ["yyyy-MM-DD HH:mm"]).format("DD-MMM-yyyy hh:mm a")+' to '+moment(parameter.to_date.parameter_value, ["yyyy-MM-DD HH:mm"]).format("DD-MMM-yyyy hh:mm a")
                }else{
                    return '-'
                }
            },
            getHindrances() {
                let vm = this;
                localStorage.setItem('project_group_id', vm.hindrance.project_group_id)
                localStorage.setItem('project_id', vm.hindrance.project_id)
                vm.$store
                    .dispatch("post", { uri: "getHindrances", data: vm.hindrance })
                    .then(function (response) {
                        vm.hindrances = response.data;    
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            
            getProjects(){
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects", data: vm.hindrance })
                    .then(function (response) {
                        vm.projects = response.data; 
                        if(localStorage.getItem('project_id')){
                            vm.hindrance.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.hindrance.project_id = vm.projects[0].project_id;
                        }
                        if(vm.initial){
                            vm.getHindrances();
                            vm.initial = false
                        }               
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            format_date(dt){
                return moment(dt).format("DD-MMM-YYYY");
            },
            downloadHindranceReport(){
                let vm = this;
                vm.errors = [];
                if (vm.hindrance.project_id == ""){
                    vm.errors.project_id = ["The project field is required."]
                }
                if (vm.hindrance.to_date_range == ""){
                    vm.errors.to_date_range = ["The Date field is required."]
                }
                if (vm.hindrance.project_id && vm.hindrance.to_date_range ){
                    window.open(vm.$store.getters.apiUrl+'downloadHindranceReport?project_id='+vm.hindrance.project_id+'&to_date_range='+vm.hindrance.to_date_range+'&from_date_range='+vm.hindrance.from_date_range+'&isDownload=true');
                }
            }
        },
    };
</script>