<template>
    <div class="pb-3">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <strong >Ordering Schedule</strong>
                <div style="float: right;" class="d-flex display-content">
                    <div class="me-2 mb-1">
                        <input type="file" class="form-control form-control-sm me-2" ref="file" @change="getParameterValues($event)" style="display: block; width: 100%;" />
                    </div>
                    <div class="">
                        <button class="btn btn-primary btn-sm mb-1 me-2" @click="uploadData()">Upload</button>
                        <button class="btn btn-success me-2 mb-1 btn-sm" @click="downloadOverallScheduleTemplate()">Download Template</button>
                        
                    </div>
                </div>
                <!-- <div class="d-flex align-items-center gap-2 ">
                    <div class="d-flex align-items-center">
                        <label class="me-2">Project&nbsp;Groups: </label>
                        <select class="form-control form-control-sm" v-model="project_section_group.project_group_id">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="d-flex gap-2">
                        <button class="btn btn-sm btn-primary me-2 btn-sm " @click="getOrderingSchedules()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                    </div>
                </div> -->
            </div>
            <div class="card-body">
                <div class="row ">
                    <div class="col-md-12">
                        <div class="table-container table-responsive table-responsive-sm" >
                            <table class="table table-sm text-nowrap1 table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word;" class="text-center">Equipments/Packages</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word;" class="text-center">Category</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word;" class="text-center">Critical/Non Critical</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word;" class="text-center">Ordering Status</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word;" class="text-center">Responsibility (Tech)</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word;" class="text-center">Responsibility (Ordering)</th>
                                        <th colspan="5" class="text-center">Plan</th>
                                        <th colspan="3" class="text-center">Actual</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center">Vendor Name</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center">Vendor Details</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center">Remark</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center">Tech Completion</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center">Order Completion</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center">Total</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center">Package</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center">Package Tech Completed</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center">Package Ordering Done</th>
                                        <th rowspan="2" style="vertical-align : middle;word-wrap: break-word" class="text-center" v-for="(project, key) in projects" :key="key">
                                            {{ project.project.project_name }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="text-center">Boq Submission</th>
                                        <th class="text-center">Te Submission Date</th>
                                        <th class="text-center">Technical submission month</th>
                                        <th class="text-center">Ordering Planning month</th>
                                        <th class="text-center">Plan ordering date</th>
                                        <th class="text-center">Final BOQ submission</th>
                                        <th class="text-center">Final TE Submission</th>
                                        <th class="text-center">Final Ordering Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(ordering_schedule, key) in ordering_schedules" :key="key">
                                        <td class="text-center" style="vertical-align: middle;">{{ ordering_schedule.equipments }}</td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <select class="form-control form-control-sm form-control1" :class="{ 'is-invalid': errors.category }"
                                                v-model="ordering_schedule.category">
                                                <option value="">Select Category</option>
                                                <option value="Technology">Technology</option>
                                                <option value="Process">Process</option>
                                                <option value="Mech">Mech</option>
                                                <option value="Contractor & Consultancy">Contractor & Consultancy</option>
                                                <option value="Railway ">Railway </option>
                                                <option value="Inst ">Inst </option>
                                                <option value="Electrical ">Electrical </option>
                                                <option value="Others ">Others </option>
                                                <option value="Mining Equipment ">Mining Equipment </option>
                                                <option value="WHRS ">WHRS </option>
                                            </select>
                                            <span v-if="errors.category" class="invalid-feedback">{{ errors.category[0]
                                            }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <select class="form-control form-control-sm form-control1" :class="{ 'is-invalid': errors.cnc_status }"
                                                v-model="ordering_schedule.cnc_status">
                                                <option value="">Select C/NC Status</option>
                                                <option value="Critical">Critical</option>
                                                <option value="Non Critical">Non Critical</option>
                                            </select>
                                            <span v-if="errors.cnc_status" class="invalid-feedback">{{ errors.cnc_status[0]
                                            }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Ordering Status" class="form-control form-control-sm "
                                                :class="{ 'is-invalid': errors.ordering_status }"
                                                v-model="ordering_schedule.ordering_status" />
                                            <span v-if="errors.ordering_status" class="invalid-feedback">{{
                                                errors.ordering_status[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Responsibility(Tech)"
                                                class="form-control form-control-sm "
                                                :class="{ 'is-invalid': errors.responsibility_tech }"
                                                v-model="ordering_schedule.responsibility_tech" />
                                            <span v-if="errors.responsibility_tech" class="invalid-feedback">{{
                                                errors.responsibility_tech[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Responsibility(Ordering)"
                                                class="form-control form-control-sm "
                                                :class="{ 'is-invalid': errors.responsibility_ordering }"
                                                v-model="ordering_schedule.responsibility_ordering" />
                                            <span v-if="errors.responsibility_ordering" class="invalid-feedback">{{
                                                errors.responsibility_ordering[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="date" placeholder="BOQ submission" class="form-control form-control-sm"
                                                :class="{ 'is-invalid': errors.boq_submission }"
                                                v-model="ordering_schedule.boq_submission" />
                                            <span v-if="errors.boq_submission" class="invalid-feedback">{{
                                                errors.boq_submission[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="date" placeholder="TE Submission date" class="form-control form-control-sm"
                                                :class="{ 'is-invalid': errors.te_submission_date }"
                                                v-model="ordering_schedule.te_submission_date" />
                                            <span v-if="errors.te_submission_date" class="invalid-feedback">{{
                                                errors.te_submission_date[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Technical submission month" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.te_submission_month }"
                                                v-model="ordering_schedule.te_submission_month" />
                                            <span v-if="errors.te_submission_month" class="invalid-feedback">{{
                                                errors.te_submission_month[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Ordering Planning month" class="form-control form-control-sm form-control2" 
                                                :class="{ 'is-invalid': errors.ordering_planning_month }"
                                                v-model="ordering_schedule.ordering_planning_month" />
                                            <span v-if="errors.ordering_planning_month" class="invalid-feedback">{{
                                                errors.ordering_planning_month[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="date" placeholder="Plan ordering date" class="form-control form-control-sm"
                                                :class="{ 'is-invalid': errors.plan_ordering_date }"
                                                v-model="ordering_schedule.plan_ordering_date" />
                                            <span v-if="errors.plan_ordering_date" class="invalid-feedback">{{
                                                errors.plan_ordering_date[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="date" placeholder="Final BOQ submission" class="form-control form-control-sm"
                                                :class="{ 'is-invalid': errors.final_boq_submission }"
                                                v-model="ordering_schedule.final_boq_submission" />
                                            <span v-if="errors.final_boq_submission" class="invalid-feedback">{{
                                                errors.final_boq_submission[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="date" placeholder="Final TE Submission" class="form-control form-control-sm"
                                                :class="{ 'is-invalid': errors.final_te_submission }"
                                                v-model="ordering_schedule.final_te_submission" />
                                            <span v-if="errors.final_te_submission" class="invalid-feedback">{{
                                                errors.final_te_submission[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="date" placeholder="Final Ordering Date" class="form-control form-control-sm"
                                                :class="{ 'is-invalid': errors.final_ordering_date }"
                                                v-model="ordering_schedule.final_ordering_date" />
                                            <span v-if="errors.final_ordering_date" class="invalid-feedback">{{
                                                errors.final_ordering_date[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Vendor Name" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.vendor_name }"
                                                v-model="ordering_schedule.vendor_name" />
                                            <span v-if="errors.vendor_name" class="invalid-feedback">{{
                                                errors.vendor_name[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Vendor Details" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.vendor_details }"
                                                v-model="ordering_schedule.vendor_details" />
                                            <span v-if="errors.vendor_details" class="invalid-feedback">{{
                                                errors.vendor_details[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Remark" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.remark }"
                                                v-model="ordering_schedule.remark" />
                                            <span v-if="errors.remark" class="invalid-feedback">{{ errors.remark[0]
                                            }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Tech Completion" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.tech_completion }"
                                                v-model="ordering_schedule.tech_completion" />
                                            <span v-if="errors.tech_completion" class="invalid-feedback">{{
                                                errors.tech_completion[0] }}</span>
                                        </td>
                                        <td v-else><input type="text" class="form-control" v-model="ordering_schedule.tech_completion" placeholder="scope"></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Order Completion" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.order_completion }"
                                                v-model="ordering_schedule.order_completion" />
                                            <span v-if="errors.order_completion" class="invalid-feedback">{{
                                                errors.order_completion[0] }}</span>
                                        </td>
                                        <td v-else><input type="text" class="form-control" v-model="ordering_schedule.order_completion" placeholder="scope"></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Total" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.total }" v-model="ordering_schedule.total" />
                                            <span v-if="errors.total" class="invalid-feedback">{{ errors.total[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Package" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.package }"
                                                v-model="ordering_schedule.package" />
                                            <span v-if="errors.package" class="invalid-feedback">{{ errors.package[0]
                                            }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Package Tech completed" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.package_tech_completed }"
                                                v-model="ordering_schedule.package_tech_completed" />
                                            <span v-if="errors.package_tech_completed" class="invalid-feedback">{{
                                                errors.package_tech_completed[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        <td v-if="ordering_schedule.equipments != 'scope'">
                                            <input type="text" placeholder="Package ordering done" class="form-control form-control-sm form-control2"
                                                :class="{ 'is-invalid': errors.package_ordering_done }"
                                                v-model="ordering_schedule.package_ordering_done" />
                                            <span v-if="errors.package_ordering_done" class="invalid-feedback">{{
                                                errors.package_ordering_done[0] }}</span>
                                        </td>
                                        <td v-else></td>
                                        
                                        <td v-if="ordering_schedule.equipments != 'scope'" v-for="(project, index) in ordering_schedule.ordering_projects" :key="index">                                        
                                            <input type="text" placeholder="Scope" class="form-control form-control-sm "
                                                v-model="ordering_schedules[key].ordering_projects[index].scope" @keyup="calculateTotal(ordering_schedules[key])"/>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                                                    v-model="ordering_schedules[key].ordering_projects[index].tech" @click="calculateTech(ordering_schedules[key], 
                                                            ordering_schedules[key].ordering_projects[index].tech, ordering_schedules[key].ordering_projects[index].scope)"/>
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                        Tech
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                        v-model="ordering_schedules[key].ordering_projects[index].order" @click="calculateOrder(ordering_schedules[key], 
                                                            ordering_schedules[key].ordering_projects[index].order, ordering_schedules[key].ordering_projects[index].scope)"/>
                                                    <label class="form-check-label" for="flexCheckChecked">
                                                        Order
                                                    </label>
                                                </div>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-primary btn-sm" @click="updateOrderingSchedule()">
                    <span><i class="ri-save-line icon_hgt"></i> UPDATE</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    name: "OverallSchedule",
    data() {
        return {
            ordering_schedule: {
                ordering_schedule_id: "",
                category: "",
                technical_person_responsible: "",
                boq_submission: "",
                te_completion_month: "",
                te_submission_date: "",
                ordering_status: "",
                tentative_ordering_date: "",
                final_ordering_date: "",
                vendor_name: "",
                vendor_details: "",
                remark: "",
                tech_completion: "",
                order_completion: "",
                total: "",
                package: "",
                package_tech_completed: "",
                package_ordering_done: "",
                projects: [],
                project: {
                    project_id: "",
                    scope: "",
                    tech: "",
                    order: "",
                },

                final_boq_submission:"",
                final_te_submission:"",
                ordering_planning_month:"",
                plan_ordering_date:"",
                responsibility_ordering:"",
                responsibility_tech:"",
                te_submission_month:"",
                
            },
            projectgroups: [],
            project_section_group:{
                project_group_id:'',
            },
            projects: [],
            ordering_schedules: [],
            status: true,
            formStatus: true,
            equipments: [],
            parameter_values:[],
            errors: [],
        };
    },
    mounted() {
        if(this.$store.getters?.user){
            this.getOrderingSchedules();
            this.addOrderingProjects();
        }   
        // this.getProjectGroups();
        
    },

    methods: {
        getParameterValues(e) {
            this.parameter_values = e.target.files[0];
            console.log("this.parameter_values", this.parameter_values)
        },
        uploadData() {
            let vm = this;
            // this.project.project_activity_group_id = this.item.id;
            let loader = vm.$loading.show();
            const formData = new FormData();
            formData.append("file", vm.parameter_values);
            vm.$store
                .dispatch("post", { uri: "uploadOrderingScheduleReport",data: formData})
                .then(function () {
                    loader.hide();
                    vm.$refs.file.value = null
                    vm.$store.dispatch("success", "Ordering schedule uploaded successfully");
                    vm.getOrderingSchedules()
                    
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        downloadOverallScheduleTemplate() {
                let vm = this;
                // window.location.assign(this.$store.getters.apiUrl + "downloadTemplate?project_activity_group_id=" + this.item.id);
                window.open(vm.$store.getters.apiUrl+'downloadOrderSchedulingReport');
            },
        getProjectGroups() {
            let vm = this;
            let uri = { uri: "getProjectGroups" };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.projectgroups = response.data.data;
                    // if (vm.projectgroups.length) {
                    //     if (!vm.project_section_group.project_group_id) {
                    //         vm.project_section_group.project_group_id = vm.projectgroups[0].project_group_id;
                    //     }
                    // }
                    vm.getOrderingSchedules();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
       getOrderingSchedules() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "getOrderingSchedules", data: vm.project_section_group })
                .then(function (response) {
                    vm.ordering_schedules = response.data.data;
                    // console.log('ordering_schedules:----',vm.ordering_schedules)
                    vm.getProject();
                    loader.hide();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getProject() {
            // let vm = this;
            // vm.$store
            //     .dispatch("post", { uri: "getProjects" })
            //     .then(function (response) {
            //         vm.projects = response.data.data;
            //     })
            //     .catch(function (error) {
            //         vm.errors = error.response.data;
            //         vm.$store.dispatch("error", error.response.data.message);
            //     });
            if(this.ordering_schedules.length){
                this.projects = this.ordering_schedules[0].ordering_projects;
            }
        },

        updateOrderingSchedule() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "updateOrderingSchedule", data: { ordering_schedules: vm.ordering_schedules } };
            vm.$store
                .dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Ordering Schedule is successfully updated");
                    vm.errors = [];
                    vm.discard();
                    vm.getOrderingSchedules();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        discard() {
            let vm = this;
            vm.ordering_schedule.equipment_id = "";
            vm.ordering_schedule.category = "";
            vm.ordering_schedule.technical_person_responsible = "";
            vm.ordering_schedule.technical_person_responsible = "";
            vm.ordering_schedule.te_completion_month = "";
            vm.ordering_schedule.te_submission_date = "";
            vm.ordering_schedule.ordering_status = "";
            vm.ordering_schedule.tentative_ordering_date = "";
            vm.ordering_schedule.final_ordering_date = "";
            vm.ordering_schedule.vendor_name = "";
            vm.ordering_schedule.vendor_details = "";
            vm.ordering_schedule.remark = "";
            vm.ordering_schedule.tech_completion = "";
            vm.ordering_schedule.order_completion = "";
            vm.ordering_schedule.total = "";
            vm.ordering_schedule.package = "";
            vm.ordering_schedule.package_tech_completed = "";
            vm.ordering_schedule.package_ordering_done = "";
            vm.errors = [];
            // vm.formStatus = true;
        },

        addOrderingProjects(){
            let vm = this;
            let loader = vm.$loading.show();
            this.$store.dispatch('post',{uri:'addOrderingProjects'})
            .then(response => {
                loader.hide();
            })
        },

        calculateTotal(data){
            let total = 0;           
            let total_tech=0;
            let total_order=0;
            data.ordering_projects.map(function(element){                
                total += Number(element.scope);
                if(element.tech){
                    total_tech += Number(element.scope);
                }
                if(element.order){
                    total_order += Number(element.scope);                
                }
            });
            data.total = total;
            data.tech_completion = total_tech;
            data.order_completion = total_order;
            
        },
        calculateTech(data, operation, value){
            if(operation){
                data.tech_completion -= Number(value);
            }
            else{                
                data.tech_completion += Number(value);
            }
        },
        calculateOrder(data, operation, value){
            if(operation){
                data.order_completion -= Number(value);
            }
            else{                
                data.order_completion += Number(value);
            }
        }
    },
};
</script>

<style scoped>

/* .table-container1{
    height: calc(100vh - 200px);
    overflow-y: scroll;
} */
    .table-container {
        height: 465px;
        /* height: calc(100vh - 80px); */
        overflow-y: scroll;
    } 

   
    /* @media screen and ( min-height: 600px ){
        .table-container {
        height: 410px;
        overflow-y: scroll;
    }
} */
    /* thead th{
        top :0;
        position: sticky;
        z-index: 20;
        background-color: #fff !important;
        background-attachment: fixed;
        position: -webkit-sticky;

    }
    table tr th:first-child,
    table td:first-child {
        position: sticky;
        left: -1px;
        z-index: 10;
        background: #fff;
        width: 15rem!important;
  min-width: 15rem!important;
  max-width: 15rem!important;
    }

    table tr th:first-child {
        z-index: 11;
    }

    table tr th {
        position: sticky;
        top: -1px;
        z-index: 9;
        background: #fff;
    }

    .table-bordered > :not(caption) > * > * {
    border-width: 0 1px;
} */
.form-control1{
    width: -webkit-fill-available;
}
td{
    vertical-align: middle;
}

    /* thead {  
        th {
            position: -webkit-sticky;
            position: sticky;
            background-color: white !important;
            outline: 1px solid #CCC;
                outline-offset: -1px ;
            top: 0; left: 0;                       
            &:first-child {
                z-index: 3;
                
            }            
        }        
    }

    th:nth-child(1), td:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0px;
        top: 0; 
        z-index: 2;
        width: 200px;
        background-color: white !important;
        outline: 1px solid #CCC;
        outline-offset: -1px ;
    }
     */

    thead {  
        th {
            position: -webkit-sticky;
            position: sticky;
            background-color: white !important;
            outline: 1px solid #e2e5ec;
            outline-offset: -1px ;
            top: 0; left: 0;                       
            &:first-child {
                z-index: 3;
            }
        }

        tr:nth-child(2) th{
            &:first-child {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                top: 29px; 
                bottom: 10px;
                z-index: 3;
                background-color: white !important;
                outline: 1px solid #e2e5ec;
                outline-offset: -1px ;
            }
        }
        tr:nth-child(2){
            position: sticky;
            top: 29px;
            outline-offset: -1px ;
        }
    }
    td {
        &:first-child {
            position: -webkit-sticky;
            position: sticky;
            left: 0px;
            top: 0; 
            z-index: 2;
            background-color: white !important;
            outline: 1px solid #e2e5ec;
                outline-offset: -1px ;
        }
    }
    .form-control2{
        width: 5rem;
    }
    thead tr th {
        text-wrap: balance;
    }
    .table > thead {
    vertical-align: middle;
}
</style>
