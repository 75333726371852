<template>
    <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Civil</strong>
            </div>

            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                         <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="project.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Project</label>
                        <!-- <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.project_id }" v-model="project.project_id">
                            <option value="">Select Project</option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name}} </option>
                        </select> -->
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="project.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @input="project1 => project.project_id = project1"
                        >
                        </search>
                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">From Date</label>
                        <div class="input-group date" id="from_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.from_date }" v-model="project.from_date" />
                        </div>
                        <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">To Date</label>
                        <div class="input-group date" id="to_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.to_date }" v-model="project.to_date" />
                        </div>
                        <span v-if="errors.to_date" style="margin-top: 0.25rem;font-size: 0.875em;color: #dc3545;">{{ errors.to_date[0] }}</span>
                    </div>
                    <div class="col-md-12 mt-1">
                        <div style="float:right">
                            <input type="checkbox" class="form-check-input" v-model="project.piling" style="margin-top:8px"><span style="margin:0 20px 0 5px">Piling</span>
                            <input type="checkbox" class="form-check-input" v-model="project.pile_breaking" style="margin-top:8px"><span style="margin: 0 20px 0 5px">Pile Breaking</span>
                            <button class="btn btn-sm btn-primary me-2" @click="getCivilSummary()" type="button"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                            <button class="btn btn-sm btn-warning me-2" @click="downloadCivilReport()"><i class="ri-download-line icon_hgt"></i> Download </button>
                            <button class="btn btn-sm btn-info me-2" @click="sendEmailCivilReport()"><i class="ri-mail-send-line icon_hgt"></i> Send Email </button>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 mb-4">
                    <div class="table-responsive table-responsive-sm" style="max-height: 392px; overflow-y: auto; ">
                        <table class="table table-sm text-nowrap table-bordered mb-0">
                            <thead class="bg-primary" style="background-color: #fff !important;">
                                <tr class="text-center" style="vertical-align: middle;">
                                    <th rowspan="2">Sl No.</th>
                                    <th rowspan="2">Section</th>
                                    <th v-if="project.piling" colspan="7">Piling Work</th>
                                    <th v-if="project.pile_breaking" colspan="7">Pile Breaking Work</th>
                                    <th colspan="7">Excavation Work</th>
                                    <th colspan="7">PCC Work</th>
                                    <th colspan="7">RCC Work</th>
                                </tr>
                                <tr class="text-center" >
                                    <th v-if="project.piling"> Scope (Nos) </th>
                                    <th v-if="project.piling"> Dwg. Released  </th>
                                    <th v-if="project.piling"> CTD </th>
                                    <th v-if="project.piling"> Target FTM </th>
                                    <th v-if="project.piling"> MTD </th>
                                    <th v-if="project.piling"> FTD </th>
                                    <th v-if="project.piling"> Manpower </th>

                                    <th v-if="project.pile_breaking"> Scope (Nos) </th>
                                    <th v-if="project.pile_breaking"> Dwg. Released  </th>
                                    <th v-if="project.pile_breaking"> CTD </th>
                                    <th v-if="project.pile_breaking"> Target FTM </th>
                                    <th v-if="project.pile_breaking"> MTD </th>
                                    <th v-if="project.pile_breaking"> FTD </th>
                                    <th v-if="project.pile_breaking"> Manpower </th>

                                    <th> Scope (CuM) </th>
                                    <th> Dwg. Released  </th>
                                    <th> CTD </th>
                                    <th> Target FTM </th>
                                    <th> MTD </th>
                                    <th> FTD </th>
                                    <th> Manpower </th>

                                    <th> Scope (CuM) </th>
                                    <th> Dwg. Released  </th>
                                    <th> CTD </th>
                                    <th> Target FTM </th>
                                    <th> MTD </th>
                                    <th> FTD </th>
                                    <th> Manpower </th>

                                    <th> Scope (CuM) </th>
                                    <th> Dwg. Released  </th>
                                    <th> CTD </th>
                                    <th> Target FTM </th>
                                    <th> MTD </th>
                                    <th> FTD </th>
                                    <th> Manpower </th>
                                </tr>
                            </thead>
                            <tbody v-for="section_group, key in civil_summary" :key="key">
                                <tr class="bg-primary" style="background-color: #fff !important;">    
                                    <td></td>
                                    <td class="text-left fs-12"><strong>{{ section_group.section_group.section_group_name }}</strong></td>
                                    <td></td><td></td><td></td><td></td>
                                    <td></td><td></td><td></td><td></td>
                                    <td></td><td></td><td></td><td></td>
                                    <td></td><td></td><td></td><td></td>
                                    <td></td><td></td><td></td><td></td>
                                    <td></td><td></td><td></td><td></td>
                                    <td></td><td></td><td></td><td></td>
                                    <td></td><td></td><td></td><td></td>
                                    <td></td><td></td>
                                </tr>
                                <template v-for="section, key in section_group.project_sections" :key="key">
                                    <tr v-if="eliminateZeros(section)">
                                        <td class="text-center">{{ key + 1 }}</td>
                                        <td>{{ section.section.section_name }}</td>
                                        <td v-if="project.piling" class="text-center">{{ section.piling.scope }}</td>
                                        <td v-if="project.piling" class="text-center">{{ section.piling.dwg_released }}</td>
                                        <td v-if="project.piling" class="text-center">{{ section.piling.ctd }}</td>
                                        <td v-if="project.piling" class="text-center">{{ section.piling.target_ftm }}</td>
                                        <td v-if="project.piling" class="text-center">{{ section.piling.mtd }}</td>
                                        <td v-if="project.piling" class="text-center">{{ section.piling.ftd }}</td>
                                        <td v-if="project.piling" class="text-center">{{ section.piling.manpower }}</td>
                                        
                                        <td v-if="project.pile_breaking" class="text-center">{{ section.pile_breaking.scope }}</td>
                                        <td v-if="project.pile_breaking" class="text-center">{{ section.pile_breaking.dwg_released }}</td>
                                        <td v-if="project.pile_breaking" class="text-center">{{ section.pile_breaking.ctd }}</td>
                                        <td v-if="project.pile_breaking" class="text-center">{{ section.pile_breaking.target_ftm }}</td>
                                        <td v-if="project.pile_breaking" class="text-center">{{ section.pile_breaking.mtd }}</td>
                                        <td v-if="project.pile_breaking" class="text-center">{{ section.pile_breaking.ftd }}</td>
                                        <td v-if="project.pile_breaking" class="text-center">{{ section.pile_breaking.manpower }}</td>

                                        <td class="text-center">{{ section.excavation.scope }}</td>
                                        <td class="text-center">{{ section.excavation.dwg_released }}</td>
                                        <td class="text-center">{{ section.excavation.ctd }}</td>
                                        <td class="text-center">{{ section.excavation.target_ftm }}</td>
                                        <td class="text-center">{{ section.excavation.mtd }}</td>
                                        <td class="text-center">{{ section.excavation.ftd }}</td>
                                        <td class="text-center">{{ section.excavation.manpower }}</td>

                                        <td class="text-center">{{ section.pcc.scope }}</td>
                                        <td class="text-center">{{ section.pcc.dwg_released }}</td>
                                        <td class="text-center">{{ section.pcc.ctd }}</td>
                                        <td class="text-center">{{ section.pcc.target_ftm }}</td>
                                        <td class="text-center">{{ section.pcc.mtd }}</td>
                                        <td class="text-center">{{ section.pcc.ftd }}</td>
                                        <td class="text-center">{{ section.pcc.manpower }}</td>

                                        <td class="text-center">{{ section.rcc.scope }}</td>
                                        <td class="text-center">{{ section.rcc.dwg_released }}</td>
                                        <td class="text-center">{{ section.rcc.ctd }}</td>
                                        <td class="text-center">{{ section.rcc.target_ftm }}</td>
                                        <td class="text-center">{{ section.rcc.mtd }}</td>
                                        <td class="text-center">{{ section.rcc.ftd }}</td>
                                        <td class="text-center">{{ section.rcc.manpower }}</td>
                                    </tr>
                                </template>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let Search = require("@/components/Search.vue").default;
import moment from 'moment';
    export default {
        name: "CivilSummary",
        components: {Search },
        data() {
            return {
                initial:true,
                project:{
                    project_id:'',
                    from_date:'',
                    to_date:'',
                    project_group_id:'',
                    isDownload:false,
                    piling: true,
                    pile_breaking: true
                },
                projects:[],
                projectgroups:[],
                errors:[],
                civil_summary:[],
            }
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectGroups();
            } 
            
            this.project.to_date = moment().format('yyyy-MM-DD')
        },
        computed(){

        },
        methods: {
            eliminateZeros(section){
                if(section.piling.dwg_released || section.piling.ctd || section.piling.manpower || section.pile_breaking.dwg_released || section.pile_breaking.ctd || section.pile_breaking.manpower || section.excavation.dwg_released || section.excavation.ctd || section.excavation.manpower || section.pcc.dwg_released || section.pcc.ctd || section.pcc.manpower || section.rcc.dwg_released || section.rcc.ctd || section.rcc.manpower){
                    return section
                }else{
                    return false
                }
            },
            sendEmailCivilReport(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "downloadCivilSummary", data: vm.project})
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Civil summary - Mail sent successfully");
                    })
                    .catch(function (error) {
                        loader.hide()
                        if(error.response.data.length){
                            vm.$store.dispatch("error", error.response.data[1]);
                        }
                    });
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.project.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.project.project_group_id) {
                                vm.project.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjects() {
            let vm = this;
            if(!vm.initial){
                localStorage.removeItem('project_id')
            }
            vm.$store
                .dispatch("post", { uri: "getProjectGroupProjects", data: vm.project })
                .then(function (response) {
                    vm.projects = response.data;
                    if(localStorage.getItem('project_id')){
                        vm.project.project_id = localStorage.getItem('project_id') 
                    }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                        vm.project.project_id = vm.projects[0].project_id;
                    }
                    if(vm.initial){
                        vm.getCivilSummary();
                        vm.initial = false
                    }
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            getCivilSummary(){
                let vm = this;
                localStorage.setItem('project_group_id', vm.project.project_group_id)
                localStorage.setItem('project_id', vm.project.project_id)
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "civilSummary", data: vm.project})
                    .then(function (response) {
                        loader.hide();
                        vm.civil_summary = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            downloadCivilReport(){
                let vm = this;
                vm.errors = [];
                if (vm.project.project_group_id == ""){
                    vm.errors.project_group_id = ["The project group field is required."]
                }
                if (vm.project.to_date == ""){
                    vm.errors.to_date = ["The To Date field is required."]
                }
                if (vm.project.project_group_id && vm.project.to_date ){
                    window.open(vm.$store.getters.apiUrl+'downloadCivilSummary?project_id='+vm.project.project_id+'&to_date='+vm.project.to_date+'&from_date='+vm.project.from_date+'&isDownload=true'+'&piling='+vm.project.piling+'&pile_breaking='+vm.project.pile_breaking);
                }
            }
        }

        
    }
</script>


<style scoped>
    /* table {
        border-collapse: collapse;
    } */

    thead {  
        th {
            position: -webkit-sticky;
            position: sticky;
            background-color: white !important;
            outline: 1px solid #e2e5ec;
            outline-offset: -1px ;
            top: 0; 
            left: 0px;                       
            &:first-child {
                z-index: 5;
                
            }
            
        }
        th:nth-child(2) {
            position: -webkit-sticky;
            position: sticky;
            background-color: white !important;
            top: 0; 
            left: 43px;                       
            z-index: 3;
            outline: 1px solid #e2e5ec;
            outline-offset: -1px ;
        }
        tr:nth-child(2){
            position: sticky;
            top: 29px;
            /* outline: 1px solid #e2e5ec; */
            outline-offset: -1px ;
        }
        
    }
    th, td {
        &:first-child {
            position: -webkit-sticky;
            position: sticky;
            left: -0.5px;
            top: 0; 
            z-index: 2;
            /* width: 200px; */
            background-color: white !important;
            outline: 1px solid #e2e5ec;
            outline-offset: -1px ;
        }
    }
    th:nth-child(2), td:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 43px;
        top: 0; 
        z-index: 2;
        width: 200px;
        background-color: white !important;
        outline: 1px solid #e2e5ec;
                outline-offset: -1px ;
    }
    
</style>