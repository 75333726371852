<template>
	 <div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">User Management</li>
			</ol>
			<h4 class="main-title mb-0">Approval Settings</h4>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-sm-4" v-for="stage, key in stages" :key="key">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">Stage {{ stage.stage }}</h6>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-sm-6">
							<label class="form-label">Role Group :: Role</label>
							<select class="form-control" :class="{ 'is-invalid': errors.role_id }" v-model="stage.role_id" :disabled="!stage.edit">
								<option value="">Select Role</option>
								<optgroup v-for="role_group, i in roles" :key="i" :label="role_group[0]?.role_group?.role_group_name">
									<option v-for="role, j in role_group" :key="j" :value="role.role_id">
										{{ role_group[0]?.role_group?.role_group_name }} :: {{role.role_name }}
									</option>
								</optgroup>
							</select>
							<span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
						</div>
						<div class="col-sm-6">
							<label class="form-label">Escalation Days</label>
							<input type="text" placeholder="Escalation Days" class="form-control" :class="{ 'is-invalid': errors.escalation_days }" v-model="stage.escalation_days" :disabled="!stage.edit">
							<span v-if="errors.escalation_days" class="invalid-feedback">{{ errors.escalation_days[0] }}</span>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex flex-row-reverse">
					<div v-if="stage.edit" class="d-flex align-items-center gap-2">
						<button class="btn btn-sm btn-outline-success" title="Update" @click="updateStage(stage,key)">
							<i class="ri-refresh-line icon_hgt"></i>
						</button>
					</div>
					<div v-else class="d-flex align-items-center gap-2">
						<button v-if="stage.stage == stages.length" class="btn btn-sm btn-outline-danger" title="Edit" @click="deleteStage(stage,key)">
							<i class="ri-delete-bin-line icon_hgt"></i>
						</button>
						<button class="btn btn-sm btn-outline-primary" title="Edit" @click="editStage(stage,key)">
							<i class="ri-pencil-line icon_hgt"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-4">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">Stage {{ stages.length + parseInt(1) }}</h6>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-sm-6">
							<label class="form-label">Role</label>
							<select class="form-control" :class="{ 'is-invalid': create_errors.role_id }" v-model="stage.role_id">
								<option value="">Select Role</option>
								<optgroup v-for="role_group, i in roles" :key="i" :label="role_group[0]?.role_group?.role_group_name">
									<option v-for="role, j in role_group" :key="j" :value="role.role_id">
										{{ role_group[0]?.role_group?.role_group_name }} :: {{role.role_name }}
									</option>
								</optgroup>
							</select>
							<span v-if="create_errors.role_id" class="invalid-feedback">{{ create_errors.role_id[0] }}</span>
						</div>
						<div class="col-sm-6">
							<label class="form-label">Escalation Days</label>
							<input type="text" placeholder="Escalation Days" :class="{ 'is-invalid': create_errors.escalation_days }" class="form-control" v-model="stage.escalation_days" />
							<span v-if="create_errors.escalation_days" class="invalid-feedback">{{ create_errors.escalation_days[0] }}</span>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex flex-row-reverse">
					<div class="gap-2">
						<button class="btn btn-sm btn-outline-success" @click="addStage()" title="Add">
							<i class="ri-add-line icon_hgt"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			stage: {
				stage: '',
				role_id: '',
				// role_group_id: '',
				escalation_days: ''
			},
			roles: [],
			stages: [],
			errors: [],
			create_errors: [],
		};
	},
	mounted() {
		if(this.$store.getters?.user){
			this.getRoles();
        }
	},
	methods: {
		getRoles() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getRoles" })
			.then(function (response) {
				vm.roles = response.data;
				vm.getStages();
			})
			.catch(function (error) {
				vm.errors = error.response.data.data;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		getStages() {
			let vm = this;
			vm.$store.dispatch('post', { uri: 'getStages' })
			.then(response => {
				vm.stages = response.data.data;
			})
			.catch(function (error) {
				vm.errors = error.response.data.data;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		addStage() {
			let vm = this
			vm.stage.stage = vm.stages.length + parseInt(1)
			let loader = vm.$loading.show();
			vm.$store.dispatch('post', { uri: 'addStage', data: vm.stage })
			.then(response => {
				loader.hide();
				vm.stages.push(response.data.data);
				vm.$store.dispatch("success", 'Stage is successfully created');
				vm.discard()
			})
			.catch(function (error) {
				loader.hide();
				vm.create_errors = error.response.data.errors
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		editStage(stage,key) {
			stage.edit = true;
		},

		updateStage(stage,key) {
			let vm = this;
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'updateStage', data: stage })
			.then(response => {
				loader.hide();
				vm.$store.dispatch("success", 'Stage is successfully updated');
				vm.stages.splice(key,1,response.data.data)
				vm.discard()
			})
			.catch(function (error) {
				loader.hide();
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		deleteStage(stage,key) {
		    let vm = this;
		    if(confirm('Do you want to delete the stage ' + stage.stage)) {
				let loader = vm.$loading.show();
				vm.$store.dispatch('post', { uri: 'deleteStage', data: stage })
				.then(response => {
					loader.hide();
					vm.$store.dispatch("success", response.data.message);
					vm.stages.splice(key,1)
					vm.discard()
				})
				.catch(error => {
					loader.hide();
					console.log(error.response)
					vm.$store.dispatch("error", error.response.data.errors.message);
				})
			}
		},

		discard() {
			let vm = this;
			// vm.stage.role_group_id = '';
			vm.stage.role_id = '';
			vm.stage.escalation_days = '';
			vm.errors = [],
			vm.create_errors = []
		}
	},
};
</script>
