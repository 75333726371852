<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <strong>Parameters</strong>
                <a href="javascript:void(0)" style="float: right;" type="button" class="" @click="$emit('addParameter')"><i class="ri-add-line"></i> Add Parameters</a>
            </div>
            <div class="card-body">
                <div class="form-group mb-2 d-flex">
                    <input class="form-control" type="text" placeholder="Search" v-model="meta.search" @keyup="search()" />
                    <a @click="deleteValues()" v-if="delete_values!= 0" href="javascript:void(0)" class="btn btn-sm btn-primary ms-2"><i class="ri-delete-bin-line icon_hgt"></i></a>
                </div>

                <div class="table-responsive table-responsive-sm">
                    <table class="table table-sm table-bordered text-nowrap">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th @click="sort('parameter_code')">
                                    Parameter Code
                                    <span>
                                        <i v-if="meta.keyword=='parameter_code' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='parameter_code' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('parameter_name')">
                                    Parameter Name
                                    <span>
                                        <i v-if="meta.keyword=='parameter_name' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='parameter_name' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('field_type')">
                                    Field Type
                                    <span>
                                        <i v-if="meta.keyword=='field_type' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='field_type' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('field_size')">
                                    Field Size
                                    <span>
                                        <i v-if="meta.keyword=='field_size' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='field_size' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('is_required')">
                                    Is Required
                                    <span>
                                        <i v-if="meta.keyword=='is_required' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='is_required' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('field_values')">
                                    Field Values
                                    <span>
                                        <i v-if="meta.keyword=='field_values' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='field_values' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('is_calculated')">
                                    Is Calculated
                                    <span>
                                        <i v-if="meta.keyword=='is_calculated' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='is_calculated' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Actions</th>
                                <th class="text-center"><input type="checkbox" :checked="isAllSelected" @change="selectAll" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="parameters.length==0">
                                <td colspan="11" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(parameter, key) in parameters" :key="key">
                                <td class="text-center">{{ meta.from+key }}</td>
                                <td>{{ parameter.parameter_code }}</td>
                                <td>{{ parameter.parameter_name }}</td>
                                <td>{{ parameter.field_type }}</td>
                                <td>{{ parameter.field_size }}</td>
                                <td>{{ parameter.is_required }}</td>
                                <td>{{ parameter.field_values }}</td>
                                <td>{{ parameter.is_calculated }}</td>
                                <td class="text-center">
                                    <div class="form-switch form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" :id="'parameter' + parameter.parameter_id" :checked="parameter.status" :value="parameter.status" @change="deleteParameter(parameter)" />
                                        <label class="custom-control-label" :for="'parameter' + parameter.parameter_id"></label>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <nav>
                                        <a href="javascript:void(0)" class="text-success me-2" @click.prevent="edit(parameter)" :disabled="!parameter.status"><i class="ri-pencil-line icon_hgt"></i></a>
                                        <a href="javascript:void(0)" class="text-danger" @click.prevent="trash(parameter)"><i class="ri-delete-bin-line icon_hgt"></i></a>
                                    </nav>
                                </td>
                                <td class="text-center"><input type="checkbox" v-model="delete_values" :value="parameter.parameter_id" @change="select" /></td>
                            </tr>
                            <!-- <tr>
                                <td class="text-center">2</td>
                                <td>Date</td>
                                <td>50</td>
                                <td>No</td>
                                <td>12-08-2023</td>
                                <td>No</td>
                                <td class="text-center">
                                    <div class="form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </td>
                                <td class="text-center">
                                    <nav>
                                        <a href="" class="text-success me-2"><i class="ri-pencil-line icon_hgt"></i></a>
                                        <a href="" class="text-danger"><i class="ri-delete-bin-line icon_hgt"></i></a>
                                    </nav>
                                </td>
                                <td class="text-center"><input type="checkbox" /></td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                    <select class="form-select width-75" v-model="meta.per_page" @change="onPerPageChange">
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </select>
                    <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>

                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
       
        data() {
            return {
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "parameter_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                isAllSelected: false,
                delete_values: [],
                parameters: [],
                errors: [],
                // status: true,
            };
        },
        mounted() {
            this.index();
        },
        methods: {
            onClickFirstPage() {
                this.$emit("pagechanged", 1);
            },
            onClickPreviousPage() {
                this.$emit("pagechanged", this.currentPage - 1);
            },
            onClickPage(page) {
                this.$emit("pagechanged", page);
            },
            onClickNextPage() {
                this.$emit("pagechanged", this.currentPage + 1);
            },
            onClickLastPage() {
                this.$emit("pagechanged", this.totalPages);
            },
            isPageActive(page) {
                return this.currentPage === page;
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateParameters", data: vm.meta })
                    .then(function (response) {
                        vm.parameters = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            edit(parameter) {
                // console.log("parameter",parameter);
                // this.$router.push("/parameters/" + parameter.parameter_id + "/edit");
                this.$emit('showValue',parameter.parameter_id)
            },
            deleteParameter(parameter) {
                let vm = this;
                let loader = vm.$loading.show();
                parameter.status = parameter.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deleteParameter", data: parameter })
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", response.data.message);
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            confirmDelete(parameter) {
                this.confirm.name = parameter.name;
                this.confirm.parameter_id = parameter.parameter_id;
            },

            trash(parameter) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    vm.$store
                        .dispatch("post", { uri: "forceDeleteParameter", data: parameter })
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Record deleted successfully");
                            vm.index();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            selectAll() {
                if (this.isAllSelected) {
                    this.delete_values = [];
                    this.isAllSelected = false;
                } else {
                    this.delete_values = [];
                    for (var parameter in this.parameters) {
                        this.delete_values.push(this.parameters[parameter].parameter_id);
                    }
                    this.isAllSelected = true;
                }
            },
            select() {
                if (this.delete_values.length !== this.parameters.length) {
                    this.isAllSelected = false;
                } else {
                    this.isAllSelected = true;
                }
            },
            deleteValues() {
                confirm("Are You Sure Want to delete?");
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "multipleDeleteParameters", data: { parameter_id: this.delete_values } })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Parameter is successfully deleted.");
                        vm.isAllSelected = false
                        vm.delete_values = []
                        vm.index();
                    })
                    .catch(function (error) {
                        // loader.hide();
                        // vm.errors = error.response.data.errors;
                        // vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
        },
    };
</script>
<style>
    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
    }

    .pagination .pagination-item {
        margin-right: 0.3571rem;
    }

    .pagination .pagination-item button {
        border: 1px solid #dfe3e7;
    }

    .pagination .pagination-item .active {
        background-color: #0d6efd;
        color: #fff;
    }
    .icon_hgt {
        /* line-height: 1; */
        font-size: 16px;
    }
</style>
