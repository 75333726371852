<template>
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
        <div>
            <ol class="breadcrumb fs-sm mb-1">
                <li class="breadcrumb-item">Dashboard</li>
                <li class="breadcrumb-item active" aria-current="page">Projects</li>
            </ol>
            <h4 class="main-title mb-0">Report Line Items</h4>
        </div>
        <div class="d-flex align-items-center gap-2 mt-3 mt-md-0" v-can="'project_costs.upload'">
            <router-link to="/projects" class="btn btn-success d-flex align-items-center gap-2">
                <i class="ri-add-box-line fs-18 lh-1"></i> Projects
            </router-link>
            <input type="file" id="report_items" class="form-control" @change="onChange($event)">
            <button class="btn btn-outline-success" title="Upload Report Line Items" :disabled="is_file_selected"
                @click="uploadReportLineItems()">Upload</button>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">Report Line Items</h6>
                </div>
                <div class="card-body">
                    <div class="row g-2 mb-1">
                        <Project :project="project" />
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input class="form-control" type="text"
                                            placeholder="Type keyword and press enter key" v-model="meta.search"
                                            @keypress.enter="search()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="table-responsive table-responsive-sm">
                                <table class="table table-sm table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th class="text-center">Level 1</th>
                                            <th class="text-center">Level 2</th>
                                            <th class="text-center">Level 3</th>
                                            <th class="text-center">Level 4</th>
                                            <th class="text-center">Level 5</th>
                                            <th class="text-center">Level 6</th>
                                            <th class="text-center">WBS Count</th>
                                            <th class="text-center">Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="report_template_level, key in report_template_levels" :key="key">
                                            <td class="text-center">{{ meta.from + key }}</td>
                                            <td>{{ report_template_level.level1 }}</td>
                                            <td>{{ report_template_level.level2 }}</td>
                                            <td>{{ report_template_level.level3 }}</td>
                                            <td>{{ report_template_level.level4 }}</td>
                                            <td>{{ report_template_level.level5 }}</td>
                                            <td>{{ report_template_level.level6 }}</td>
                                            <td class="text-center">{{ report_template_level.wbs_count }}</td>
                                            <td class="text-center">
                                                <button class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                                    data-bs-target="#link"
                                                    @click="addLink(report_template_level)">Link</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <select class="form-select from-select-sm width-75" v-model="meta.per_page"
                            @change="onPerPageChange">
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>25</option>
                            <option>30</option>
                        </select>
                        <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                            @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="link" tabindex="-1" aria-labelledby="linkLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="linkLabel">WBS Code</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 mb-1" v-if="report_template_level">
                                <label for="project_cost_id" class="form-label">
                                    Report Line Items
                                </label>
                                <table class="table table-sm table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Level 1</th>
                                            <th class="text-center">Level 2</th>
                                            <th class="text-center">Level 3</th>
                                            <th class="text-center">Level 4</th>
                                            <th class="text-center">Level 5</th>
                                            <th class="text-center">Level 6</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ report_template_level?.level1 }}</td>
                                            <td>{{ report_template_level?.level2 }}</td>
                                            <td>{{ report_template_level?.level3 }}</td>
                                            <td>{{ report_template_level?.level4 }}</td>
                                            <td>{{ report_template_level?.level5 }}</td>
                                            <td>{{ report_template_level?.level6 }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-sm-4 mb-3">
                                <label for="project_cost_id" class="form-label">
                                    Total Budget <span class="text-danger"> *</span>
                                </label>
                                <input type="numnber" v-model="report_line_item.total_budget"
                                    class="form-control form-control-sm" placeholder="Total Budget">
                            </div>
                            <div class="col-sm-4 mb-3">
                                <label for="project_cost_id" class="form-label">
                                    Manual Commitment <span class="text-danger"> *</span>
                                </label>
                                <input type="numnber" v-model="report_line_item.manual_commit"
                                    class="form-control form-control-sm" placeholder="Manual Commitment">
                            </div>
                            <div class="col-sm-4 mb-3">
                                <label for="project_cost_id" class="form-label">
                                    Projected Commitment <span class="text-danger"> *</span>
                                </label>
                                <input type="numnber" v-model="report_line_item.projected_commitment"
                                    class="form-control form-control-sm" placeholder="Projected Commitment">
                            </div>
                            <div class="col-sm-8 mb-3">
                                <label for="project_cost_id" class="form-label">
                                    WBS Code <span class="text-danger"> *</span>
                                </label>
                                <SearchProjectCost :class="{ 'is-invalid': errors?.project_cost_id }"
                                    :customClass="{ 'is-invalid': errors?.project_cost_id }"
                                    :initialize="project_cost_id" id="project_cost_id" label="wbs_code"
                                    label2="wbs_description" placeholder="Select WBS Code & Discription"
                                    :data="project_costs" @selected="project_cost => project_cost_id = project_cost">
                                </SearchProjectCost>
                                <span v-if="errors?.project_cost_id" class="invalid-feedback">{{
                                    errors?.project_cost_id[0] }}</span>
                            </div>
                            <div class="col-sm-3">
                                <label class="form-label" for="wbs_amount">Amount</label> <span class="text-danger">
                                    *</span>
                                <input type="number" v-model="project_cost_amount" class="form-control form-control-sm"
                                    id="wbs_amount">
                            </div>
                            <div class="col-sm-1 pt-4">
                                <button @click="addItem" class="btn btn-block btn-sm btn-primary"
                                    style="margin-top: 5px;">Add</button>
                            </div>
                            <div class="col-sm-12 mb-3">
                                <table class="table table-sm table-bordered table-stripped">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Level</th>
                                            <th>WBS Code</th>
                                            <th>WBS Description</th>
                                            <th>Budget</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="project_cost, key in selected_project_costs" :key="key">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ project_cost.level }}</td>
                                            <td>{{ project_cost.wbs_code }}</td>
                                            <td>{{ project_cost.wbs_description }}</td>
                                            <td>{{ project_cost?.budget }}</td>
                                            <td class="text-center">
                                                <button class="btn btn-sm btn-danger"
                                                    @click="deleteSelectedProjectCost(project_cost)">Delete</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            ref="close_link_modal">Close</button>
                        <!-- <button type="button" class="btn btn-primary" :disabled="isBudgetEqual"
                            @click="updateReportLineItem()">Update</button> -->
                        <button type="button" class="btn btn-primary" @click="updateReportLineItem()">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Project from '@/components/Project';
import Pagination from "@/components/Pagination.vue";
// let Search = require("@/components/Search.vue").default;
let SearchProjectCost = require("@/components/SearchProjectCost.vue").default;
export default {
    components: { Project, Pagination, SearchProjectCost },
    data() {
        return {
            project: {},
            meta: {
                level: '',
                search: '',
                project_id: '',
                order_by: "asc",
                keyword: "report_template_level_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
                trashed: false,
            },
            cost_types: [],
            project_costs: [],
            report_template_level: {},
            selected_project_costs: [],
            deleted_report_line_item_levels: [],
            project_cost_id: '',
            project_cost_amount: 0,
            total_budget: 0,
            wbs_total_budget: 0,
            errors: [],
            report_template_levels: [],
            report_line_item: {
                total_budget: 0
            },
            attachment: '',
        };
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.meta.project_id = to.params.project_id;
            vm.index();
            let uri = { uri: "getProject", data: vm.meta };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.project = response.data.data;
                    vm.getProjectCosts();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        });
    },

    computed: {
        isBudgetEqual() {
            let sum = 0;
            this.selected_project_costs.forEach(ele => {
                sum += ele.budget;
            })
            if (this.report_line_item.total_budget == sum && (this.report_line_item.total_budget != 0 || sum != 0)) {
                return false;
            } else {
                return true;
            }
        },

        is_file_selected() {
            if (this.attachment == '') {
                return true;
            } else {
                return false;
            }
        }
    },

    methods: {
        getProjectCosts() {
            let vm = this;
            vm.$store.dispatch('post', { uri: 'getProjectCosts', data: vm.meta })
                .then(response => {
                    vm.project_costs = response.data.data;
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        addLink(report_template_level) {
            console.log(report_template_level);
            this.report_template_level = report_template_level;
            let uri = {
                uri: 'getReportLineItem',
                data: {
                    project_id: this.project.project_id,
                    report_template_id: report_template_level.report_template_id,
                    report_template_level_id: report_template_level.report_template_level_id
                }
            }
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', uri)
                .then(response => {
                    loader.hide();
                    this.report_line_item = response.data.data;
                    this.getReportLineItemLevels();
                })
                .catch(error => {                                        
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);    
                    vm.cloaseModal();
                })
        },

        cloaseModal(){
            let vm = this;
            vm.$refs.close_link_modal.click();
        },

        getReportLineItemLevels() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getReportLineItemLevels', data: { report_line_item_id: this.report_line_item.report_line_item_id } })
                .then(response => {
                    loader.hide();
                    this.selected_project_costs = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        addItem() {
            if (this.project_cost_id != '') {
                let selected_project_costs = this.project_costs.filter(element => {
                    return element.project_cost_id == this.project_cost_id
                });

                if (selected_project_costs.length != 0) {
                    let exists = this.selected_project_costs.find(ele => { return ele.project_cost_id == selected_project_costs[0].project_cost_id});
                    if(exists){
                        alert('This wbs code already exists!...');
                        return;
                    }
                    selected_project_costs[0].budget = this.project_cost_amount;
                    this.selected_project_costs.push(selected_project_costs[0]);
                    this.project_cost_id = "";
                    this.project_cost_amount = "";
                }
            }
        },

        index() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getReportTemplateLevels', data: this.meta })
                .then(response => {
                    loader.hide();
                    this.report_template_levels = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },
        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },
        deleteSelectedProjectCost(project_cost) {
            this.deleted_report_line_item_levels.push(project_cost.report_line_item_level_id);
            let temp = this.selected_project_costs.filter(ele => ele !== project_cost);
            this.selected_project_costs = temp;
        },

        updateReportLineItem() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateReportLineItem', data: this.report_line_item })
                .then(response => {
                    loader.hide();
                    this.$refs.close_link_modal.click();
                    this.report_line_item = response.data.data;
                    this.updateReportLineItemLevels();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateReportLineItemLevels() {
            let report_line_item_levels = [];
            this.selected_project_costs.forEach(ele => {
                let obj = {};
                obj.report_line_item_id = this.report_line_item.report_line_item_id;
                obj.project_cost_id = ele.project_cost_id;
                obj.budget = ele.budget;
                let temp = Object.assign({}, obj);
                report_line_item_levels.push(temp);
            });

            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateReportLineItemLevels', data: { report_line_item_levels: report_line_item_levels, deleted_report_line_item_levels: this.deleted_report_line_item_levels } })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.discard();
                    this.index();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        discard() {
            this.report_line_item.total_budget = 0;
            this.errors = [];
            this.selected_project_costs = [];
        },

        onChange(e) {
            this.attachment = e.target.files[0];
        },

        uploadReportLineItems() {
            let data = new FormData();
            data.append('project_id', this.project.project_id);
            data.append('attachment', this.attachment);

            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'uploadReportLineItems', data: data })
                .then(response => {
                    loader.hide();
                    this.index();
                    this.$store.dispatch('success', response.data.message);
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },
    }
};
</script>
