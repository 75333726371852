<template>
    <figure class="donut-figure">
        <div :id="container"></div>
    </figure>
</template>

<script>
    var Highcharts = require("highcharts");
    require("highcharts/modules/heatmap")(Highcharts);
	require("highcharts/modules/data")(Highcharts);
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/export-data")(Highcharts);
    require("highcharts/modules/accessibility")(Highcharts);
    export default {
        name: "Donut",
        props: {
			container: {
                default: null,
            },
            title: {
                default: null,
            },
            subtitle:{
                default: null,
            },
            max: {
				default: null,
			},
			series: {
				default: null,
			},
            plotoption:{
                default:null
            },
            height: {
				default: '65%',
                // default:null
			},
            width: {
                default:null
			},
            margin_top:{
                default:1
            },
            showInLegend: {
                default : true
            }

        },

        watch: {
			series: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
            max: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
        },

        mounted() {
            this.loadChart();
        },

        methods: {
            loadChart() {
                let vm = this;
                Highcharts.chart(vm.container, {
                    credits: {
                        enabled: false,
                    },
                    exporting:{
                        enabled:false,
                    },
                    chart: {
                        
                        marginTop: vm.margin_top,
                        marginBottom: 2,
                        // plotBorderWidth: 1,
                        height:vm.height,
                        width:vm.width,
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    title: {
                        text: vm.title,
                        verticalAlign: 'middle',
                        floating: true,

                    },
                    subtitle:{
                        text:vm.subtitle,
                        align:'left',
                        x:-10,
                        style:{
                            fontSize:'12px',
                            fontWeight:'bold'
                        }
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.y}</b>'
                    },
                    plotOptions: vm.plotoption,
                    // plotOptions:{
                    //     formatter: function() {
                    //             return '<b>' + this.point.name + '</b><br>' + this.point.y;
                    //         }
                    // },
                    plotOptions: {
                        pie: {
                        size: '80%',
                        innerSize: '60%',
                        dataLabels: {
                            connectorWidth: 5,
                            distance: -8,
                            enabled: true,
                            formatter: function() {
                                if(this.point.y != 0){
                                    return '<span style="color:black;font-size:8pt">'+ this.point.y+'</span>'
                                }
                                // return '<span style="color:gray;font-size:8pt">'+this.point.name + '<br>' + this.point.y+'</span>'
                                
                            }
                        }
                        }
                    },
                    legend: {                        
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: 10,
                        y: -18,
                        floating: true,
                        backgroundColor:Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                        shadow: false,
                        itemStyle: {
                            // color: 'white',
                            // fontWeight: 'bold',
                            fontSize: '9px'
                        }
                    },
                    // {
                    //     pie: {
                    //         allowPointSelect: true,
                    //         cursor: 'pointer',
                    //         dataLabels: {
                    //             enabled: true,
                    //             format: '{point.name}: {y}'
                    //         },
                    //         showInLegend: true
                    //     },
                    //     series: vm.plotoption
                    // },
                    series: [{
                        type: "pie",
                        showInLegend: true,
                        name: vm.series.name,
                        colorByPoint: true,
                        innerSize: '70%',
                        borderWidth: 2,
                        data: vm.series,
                        dataLabels: {
                            enabled: true,
                            color: "#000000",
                        },
                    }]
                });
            }
        }
    };
</script>