<template>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <strong >Activity Jobs List</strong>
                    
                </div>
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col-md-3">
                            <label class="form-label">Project Groups</label>
                            <select class="form-control form-control-sm" v-model="meta.project_group_id" @change="getProjects()">
                                <option value="">Select Project Groups</option>
                                <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Projects </label>
                            <select class="form-control form-control-sm" v-model="meta.project_id">
                                <option value="">Select Project </option>
                                <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name }}</option>
                            </select>
                            <!-- <search
                                :class="{ 'is-invalid': errors.project_id }"
                                :customClass="{ 'is-invalid': errors.project_id  }"
                                :initialize="meta.project_id"
                                id="project_id"
                                label="project_name"
                                placeholder="Select Project"
                                :data="projects"
                                @input="project => meta.project_id = project"
                            >
                            </search> -->
                            <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0]}}</span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Project Activity Group</label>
                            <!-- <select class="form-control form-control-sm" v-model="meta.activity_group_id">
                                <option value=""> Select Project Activity Group </option>
                                <option v-for="ag, key in activity_groups" :key="key" :value="ag.activity_group_id">{{ ag.activity_group_name }}</option>
                            </select> -->

                            <search
                                :class="{ 'is-invalid': errors.activity_group_id }"
                                :customClass="{ 'is-invalid': errors.activity_group_id  }"
                                :initialize="meta.activity_group_id"
                                id="activity_group_id"
                                label="activity_group_name"
                                placeholder="Select Project Activity Group"
                                :data="activity_groups"
                                @input="activity_group => meta.activity_group_id = activity_group"
                            >
                            </search>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Section Group</label>
                            <!-- <select class="form-control form-control-sm" v-model="meta.section_group_id">
                                <option value="">Select Section Group</option>
                                <option v-for="sg, key in section_groups" :key="key" :value="sg.section_group_id">{{ sg.section_group_name }}</option>
                            </select> -->

                            <search
                                :class="{ 'is-invalid': errors.section_group_id }"
                                :customClass="{ 'is-invalid': errors.section_group_id  }"
                                :initialize="meta.section_group_id"
                                id="section_group_id"
                                label="section_group_name"
                                placeholder="Select Section Group"
                                :data="section_groups"
                                @input="section_group => meta.section_group_id = section_group"
                            >
                            </search>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Section</label>
                            <!-- <select class="form-control form-control-sm" v-model="meta.section_id" >
                                <option value="">Select Section </option>
                                <option v-for="section, key in sections" :key="key" :value="section.section_id">{{ section.section_name }}</option>
                            </select> -->

                            <search
                                :class="{ 'is-invalid': errors.section_id }"
                                :customClass="{ 'is-invalid': errors.section_id  }"
                                :initialize="meta.section_id"
                                id="section_id"
                                label="section_name"
                                placeholder="Select Section"
                                :data="sections"
                                @input="section => meta.section_id = section"
                            >
                            </search>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Activity</label>
                            <!-- <select class="form-control form-control-sm" v-model="meta.activity_id" >
                                <option value="">Select Activity </option>
                                <option v-for="activity, key in activities" :key="key" :value="activity.activity_id">{{ activity.activity_name }}</option>
                            </select> -->

                            <search
                                :class="{ 'is-invalid': errors.activity_id }"
                                :customClass="{ 'is-invalid': errors.activity_id  }"
                                :initialize="meta.activity_id"
                                id="activity_id"
                                label="activity_name"
                                placeholder="Select Activity"
                                :data="activities"
                                @input="activity => meta.activity_id = activity"
                            >
                            </search>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Parameter</label>
                            <!-- <select class="form-control form-control-sm" v-model="meta.activity_id" >
                                <option value="">Select Activity </option>
                                <option v-for="activity, key in activities" :key="key" :value="activity.activity_id">{{ activity.activity_name }}</option>
                            </select> -->

                            <search
                                :class="{ 'is-invalid': errors.parameter_id }"
                                :customClass="{ 'is-invalid': errors.parameter_id  }"
                                :initialize="meta.parameter_id"
                                id="parameter_id"
                                label="parameter_name"
                                placeholder="Select Parameter"
                                :data="parameters"
                                @input="parameter => meta.parameter_id = parameter"
                            >
                            </search>
                        </div>
                        <div class="col-md-3 mt-4">
                            <!-- <div style="text-align: right"> -->
                                <button class="btn btn-primary btn-sm me-2" @click="index()"><i class="ri-drag-move-fill icon_hgt"></i> Get </button>
                                <!-- <button class="btn btn-sm btn-danger me-2" @click="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                                <button class="btn btn-primary me-2 btn-sm" v-if="status" @click="add()"><i class="ri-save-line icon_hgt" ></i> SUBMIT</button>
                                <button class="btn btn-primary me-2 btn-sm" v-else @click="update()"><i class="ri-save-line icon_hgt" ></i> UPDATE</button>
                                <button v-if="delete_values!= 0" class="btn btn-sm btn-primary me-2" @click="deleteValues()" ><i class="ri-delete-bin-line icon_hgt"></i></button> -->
                            <!-- </div> -->
                        </div>
                        
                    </div>
                    <!-- <div class="row mb-3"> -->
                        
                        <!-- <div class="col-md-2 ">
                            <label class="form-label">&nbsp;</label><br>
                            <button class="btn btn-primary btn-sm" @click="index()">Get</button>
                        </div> -->
                    <!-- </div> -->

                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm table-bordered table-striped mb-0">
                            <thead>
                                <tr >
                                    <th class="text-center">#</th>
                                    <th>Date Time</th>
                                    <th>User</th>
                                    <th>Section Group</th>
                                    <th>Section</th>
                                    <th>Activity</th>
                                    <th>Parameter</th>
                                    <th>Value</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="job, key in jobs" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{ change_dataformat(job.job.job_date_time) }}</td>
                                    <td>{{ job.user?.name }}</td>
                                    <td>{{ job.section_group?.section_group_name }}</td>
                                    <td>{{ job.section?.section_name }}</td>
                                    <td>{{ job.project_activity?.activity?.activity_name }}</td>
                                    <td>{{ job.parameter?.parameter_name }}</td>
                                    <td>{{ job.parameter_value }}</td>
                                    
                                    <td>
                                        <!-- 
                                            <button type="button" class="btn btn-sm btn-outline-success me-2"  
                                                @click="editJobs(job.job_id)">
                                                <i class="ri-eye-fill icon_hgt"></i>
                                            </button>
                                        -->
                                        <button type="button" class="btn btn-outline-danger btn-sm" 
                                            @click="deleteJobs(job.job_parameter_id)">
                                            <i class="ri-delete-bin-line icon_hgt"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center" style="float: right;">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                            @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>    
<script>
let Search = require("@/components/Search.vue").default;
import Pagination from "@/components/Pagination.vue";
import moment from "moment";
export default{
    name:"ActivityJobsView",
    components: { Pagination, Search },
    data(){
        return{
            initial:true,
            jobs:[],
            projects: [],
            projectgroups:[],
            activity_groups: [],
            section_groups: [],
            sections: [],
            activities: [],
            parameters:[],
            errors:[],
            meta: {
                project_id:'',
                project_group_id:'',
                activity_group_id:'',
                section_group_id:'',
                section_id:'',
                activity_id:'',
                search: '',
                order_by: "desc",
                keyword: "job_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
            },
        }
    },
    mounted(){
        if(this.$store.getters.user){
            this.getProjectGroups();
        }
        
        // this.index();
    },
    methods:{
        change_dataformat(date_time){
            return moment(date_time).format("DD-MMM-yyyy");
        },
        getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.meta.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.meta.project_group_id) {
                                vm.meta.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        // vm.getProjectRequestApproved()
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        getProjects() {
            let vm = this;
            if(!vm.initial){
                localStorage.removeItem('project_id')
            }
            let uri = { uri: "getProjectGroupProjects", data:vm.meta };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.projects = response.data;
                    if(localStorage.getItem('project_id')){
                        vm.meta.project_id = localStorage.getItem('project_id') 
                    }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                        vm.meta.project_id = vm.projects[0].project_id;
                    }
                    if(vm.initial){
                        vm.getActivityGroup()
                        vm.initial = false
                    }
                    

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // getProjects() {
        //     let vm = this;
        //     let uri = { uri: "getProjectGroupProjects", data: {project_group_id: vm.meta.project_group_id} };
        //     vm.$store
        //         .dispatch("post", uri)
        //         .then(function (response) {
        //             vm.projects = response.data;
        //             if (vm.projects.length) {
        //                 vm.meta.project_id = vm.projects[0].project_id;
        //             } 
        //             vm.getActivityGroup()

        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // },
        getActivityGroup() {
            let vm = this;
            let uri = { uri: "getActivityGroups"};
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {                    
                    vm.activity_groups = response.data.data;
                    vm.getSectionGroup();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getSectionGroup() {
            let vm = this;
            let uri = { uri: "getSectionGroups"};
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.section_groups=[]
                    vm.section_groups = response.data.data;
                    vm.getSection();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getSection() {
            let vm = this;
            let uri = { uri: "getSections"};
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.sections=[]
                    vm.sections = response.data.data;
                    vm.getActivity();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getActivity() {
            let vm = this;
            let uri = { uri: "getActivities"};
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.activities=[];
                    vm.activities = response.data.data; 
                    vm.getParameters();                   
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getParameters(){
            let vm = this;
            let uri = { uri: "getNonAutoParameters"};
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.parameters=[];
                    vm.parameters = response.data.data;                   
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        index(){
            let vm = this;
            localStorage.setItem('project_group_id', vm.meta.project_group_id)
            localStorage.setItem('project_id', vm.meta.project_id)
            let loader = vm.$loading.show();
            let uri = { uri: "getProjectJobParameters", data: vm.meta };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide()
                    vm.jobs = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;                    
                })
                .catch(function (error) {
                    loader.hide();
                    console.log('error:-----',error)
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        // editJobs(job_id){
        //     this.$router.push("/job/" + job_id);
        // },
        deleteJobs(job_id){
            if (confirm("Are you sure you want to delete")) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri:'forceDeleteJobParameter', data:{'job_parameter_id': job_id}};
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.index();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        },
        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
    }
}
</script>