<template>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    Job Parameter
                    <!-- <div class="text-end mt-0 pt-0"> -->
                        <button class="btn btn-primary btn-sm" @click="return_job_list()" style="float: right;">Job List</button>
                    <!-- </div> -->
                </div>
                <div class="card-body">
                    <div class="row mb-4" >
                        <div class="col-md-3">
                            <label class="form-label">Project</label>
                            <input type="text" :disabled="true" placeholder="Project Name" class="form-control" v-model="job.project_name" />
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Activity Group</label>
                            <input type="text" :disabled="true" placeholder="Activity Group" class="form-control" v-model="job.activity_group" />
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Job Date</label>
                            <input type="text" :disabled="true" placeholder="Job date" class="form-control" v-model="job.job_date_time" />
                        </div>
                        
                    </div>

                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm table-bordered table-striped mb-0">
                            <thead>
                                <tr class="text-center">
                                    <th>#</th>
                                    <!-- <th>Activity Group</th> -->
                                    <th>Section Group</th>
                                    <th>Section</th>
                                    <th>Activity</th>
                                    <th>Parameter Type</th>
                                    <th>Parameter Name</th>
                                    <th>Parameter Value</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  class="text-center" v-for="parameter, key in job_parameters" :key="key">
                                    <td>{{ meta.from + key }}</td>
                                    <!-- <td>{{ parameter.project_section_group?.project_activity_group?.activity_group?.activity_group_name }}</td> -->
                                    <td>{{ parameter.section_group?.section_group_name }}</td>
                                    <td>{{ parameter.section?.section_name }}</td>
                                    <td>{{ parameter.activity?.activity_name }}</td>
                                    <td>{{ parameter.project_parameter_type?.parameter_type?.parameter_type_name }}</td>
                                    <td>{{ parameter.parameter?.parameter_name }}</td>
                                    <td>{{ parameter.parameter_value }}</td>
                                    <td>
                                        <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteJobs(parameter.job_parameter_id)">
                                            <i class="ri-delete-bin-line icon_hgt"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center" style="float: right;">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                            @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>    
<script>
import Pagination from "@/components/Pagination.vue";
export default{
    name:"JobView",
    components: { Pagination },
    data(){
        return{
            job_parameters:[],
            job:{
                project_name:'',
                activity_group:'',
                job_date_time:''
            },
            meta: {
                job_id:'',
                search: '',
                order_by: "desc",
                keyword: "job_parameter_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
            },
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // if (to.name == "userEdit") {  
                // console.log("to.params.job_id:-----",to.params.job_id);              
                vm.meta.job_id = to.params.job_id
                if(this.$store.getters.user){
                    this.index();
                }
            // } 
        });
    },
    mounted(){
        // this.getJob();
    },
    methods: {
        getJob(){
            let vm = this;
            let uri = { uri:'getJob', data:{'job_id': vm.meta.job_id}};
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.job.project_name = response.data.data.project.project_name
                vm.job.activity_group = response.data.data.project_activity_group.activity_group.activity_group_name
                vm.job.job_date_time = response.data.data.job_date_time
            })
            .catch(function (error) {
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },
        index(){
            let vm = this;
            this.getJob();
            let loader = vm.$loading.show();
            let uri = { uri: "paginateJobParameters", data: vm.meta };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide()
                    vm.job_parameters = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        // editJobs(job_id){
        //     this.$router.push("/jobs/" + job_id);
        // },
        deleteJobs(job_id){
            if (confirm("Are you sure you want to delete")) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri:'forceDeleteJobParameter', data:{'job_parameter_id': job_id}};
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.index();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        },
        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
        return_job_list(){
            this.$router.push("/jobs");
        }
    }
}
</script>