<template>
    <div class="card">
        <div class="card-header">
            <strong>{{ project.project_name }}</strong>
            <button class="btn btn-sm btn-outline-primary" style="float: right;" @click="assignNewUser()" v-can="'projects.assign'">Assign New User</button>
        </div>
        <div class="card-body">
            <div class="row">
                <Project :project="project" />
                <div class="col-12">
                    <div class="form-group mb-2 d-flex">
                        <input class="form-control form-control-sm" type="text"
                            placeholder="Type keyword and press enter key" v-model="meta.search"
                            @keypress.enter="search()" />
                    </div>
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th @click="sort('name')">Name
                                        <span>
                                            <i v-if="meta.keyword == 'name' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'name' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('email')">Email
                                        <span>
                                            <i v-if="meta.keyword == 'email' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'email' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('role_id')">Role Group
                                        <span>
                                            <i v-if="meta.keyword == 'role_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'role_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('role_id')">Role
                                        <span>
                                            <i v-if="meta.keyword == 'role_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'role_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('user_type')">User Type
                                        <span>
                                            <i v-if="meta.keyword == 'user_type' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'user_type' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('mobile_no')">Mobile No.
                                        <span>
                                            <i v-if="meta.keyword == 'mobile_no' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'mobile_no' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="existing_users.length == 0">
                                    <td colspan="7" class="text-center">No records found!</td>
                                </tr>
                                <tr v-for="(user, key) in existing_users" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{ user.user.name }}</td>
                                    <td>{{ user.user.email }}</td>
                                    <td>{{ user.user.role?.role_group?.role_group_name }}</td>
                                    <td>{{ user.user.role?.role_name }}</td>
                                    <td>{{ user.user.user_type }}</td>
                                    <td>{{ user.user.mobile_no }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between align-items-center">
                <select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                    <option>30</option>
                </select>
                <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                    @pagechanged="onPageChange" />
            </div>
        </div>
    </div>
</template>
<script>
import ProjectUpdate from "@/components/tree/ProjectUpdate.vue";
import Project from '@/components/Project'
import Pagination from "@/components/Pagination.vue";
export default {
    components: { ProjectUpdate, Project, Pagination },
    data() {
        return {
            treeData: [],
            project_activity_groups: [],
            project: {
                project_id: '',
                type: '',
                is_manual: false
            },
            meta: {
                search: "",
                order_by: "asc",
                keyword: "user_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
            },
            existing_users: [],
            users: [],
            errors: [],
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            let loader = vm.$loading.show()
            vm.project.project_id = to.params.project_id
            let uri = { uri: "getProjectPMTEdit", data: vm.project };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide()
                    vm.project = response.data.data;
                    vm.getExistingUsers();
                })
                .catch(function (error) {
                    loader.hide()
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        });
    },
    methods: {
        getExistingUsers() {
            let vm = this;
            let loader = vm.$loading.show();
            this.meta.project_id = this.project.project_id;
            this.$store.dispatch('post', { uri: 'getExistingUsers', data: this.meta })
                .then(response => {
                    loader.hide();
                    this.existing_users = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },
        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.getExistingUsers();
        },
        assignNewUser(){
            this.$router.push('/project/'+this.project.project_id+'/assign_user');
        },
        onPageChange(page) {
            this.meta.page = page;
            this.getExistingUsers();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.getExistingUsers();
        },
        onPerPageChange() {
            this.meta.page = 1;
            this.getExistingUsers();
        }        
    }

};
</script>
