<template>
    <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>DPR HO Manpower</strong>
            </div>

            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                        <label class="form-label">Project Group</label>
                        <select class="form-select form-select-sm" :class="{'is-invalid': errors?.project_id}" v-model="dpr_manpower.project_group_id">
                            <option value=""> Select Project Group </option>
                            <option v-for="project_group, key in project_groups" :key="key" :value="project_group.project_group_id">{{project_group.project_group_name }} </option>
                        </select>
                        <span v-if="errors.project_group_id" class="invalid-feedback">{{ errors.project_group_id[0] }}</span>
                    </div>
                    <div class="col-md-2">
                        <label class="form-label">From Date</label>
                        <div class="input-group date" id="date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.from_date }" v-model="dpr_manpower.from_date" />
                        </div>
                        <span v-if="errors.from_date"><small class="text-danger">{{ errors.from_date[0] }}</small></span>
                    </div>
                    <div class="col-md-2">
                        <label class="form-label">To Date</label>
                        <div class="input-group date" id="date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.to_date }" v-model="dpr_manpower.to_date" />
                        </div>
                        <span v-if="errors.to_date"><small class="text-danger">{{ errors.to_date[0] }}</small></span>
                    </div>
                    <div class="col-md-2 mt-auto">
                            <button class="btn btn-primary me-2 btn-sm" @click="getDPRManpowers()"><i class="ri-drag-move-fill icon_hgt"></i> Get </button>
                            <button class="btn btn-sm btn-primary " @click="downloadDPRManpowerReport()"><i class="ri-download-line icon_hgt"></i> Download </button>
                    </div>
                </div>

                <div class="col-sm-12 mb-4">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-1">
                            <thead >
                                <tr>
                                    <td colspan="2" class="fs-14 ft-bold bg-primary bg-opacity-50"> Manpower Report </td>
                                </tr>
                                <tr>
                                    <th class="text-center fs-12 ft-bold bg-primary bg-opacity-50" colspan="2">Particulars</th>
                                    <th class="text-center fs-12 ft-bold bg-primary bg-opacity-50"> Required </th>
                                    <th class="text-center fs-12 ft-bold bg-primary bg-opacity-50" v-for="date, key in dpr_manpower.dates" :key="key"> {{date}} </th>
                                </tr>
                            </thead>
                             <tbody v-for="dpr_manpower, key in project.dpr_manpower_templates" :key="key">
                                <tr>
                                    <td class="text-left fs-12 bg-primary bg-opacity-25"><strong>{{ dpr_manpower.project_name }}</strong></td>
                                    <td class="bg-primary bg-opacity-25"></td>
                                    <td class="bg-primary bg-opacity-25"></td>
                                    <td class="bg-primary bg-opacity-25" v-for="date, key in dpr_manpower.dates" :key="key"></td>

                                </tr>
                                <tr>
                                    <td><h5>Civil</h5></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr v-for="civil, civil_key in dpr_manpower.pc_civil" :key="civil_key">
                                    <td></td>
                                    <td><strong>{{civil.contractor}}</strong></td>
                                    <td class="text-center"><strong>{{civil.manpower_req}}</strong></td>
                                    <td class="text-center" v-for="data, key in civil.manpower_avail" :key="key"><strong>{{data}}</strong></td>
                                </tr>
                                <tr v-for="mech, mech_key in getMechManpowerData(dpr_manpower.pc_mech)" :key="mech_key">
                                    <td class="text-left" v-if="mech.activity_group_name != ''"><h5>{{ mech.activity_group_name }}</h5></td>
                                    <td v-else></td>

                                    <td class="text-left" v-if="mech.contractor_name != ''"><strong>{{mech.contractor_name}}</strong></td>
                                    <td class="text-left" v-else>{{mech.activity_name}}</td>

                                    <td class="text-center" v-if="mech.contractor_name != ''"><strong>{{mech.required}}</strong></td>
                                    <td class="text-center" v-else>{{mech.required}}</td>   

                                    <td class="text-center" v-if="mech.manpower_avail != ''" v-for="mp_req, key in mech.manpower_avail" :key="key">{{mp_req}}</td>
                                    <td class="text-center" v-else-if="mech.result == '' && mech.manpower_avail == ''" v-for="date, key in dpr_manpower.dates" ></td>
                                    <td class="text-center fw-bold" v-else v-for="result in mech.result">{{result}}</td>
                                </tr>

                                <tr>
                                    <td><h5>Electrical</h5></td>
                                    <td></td>
                                    <td></td>
                                    <td v-for="date, key in dpr_manpower.dates" :key="key"></td>
                                </tr>
                                <tr v-for="electrical, electrical_key in project.pc_electrical" :key="electrical_key">
                                    <td></td>
                                    <td><strong>{{electrical.contractor}}</strong></td>
                                    <td class="text-center"><strong>{{electrical.manpower_req}}</strong></td>
                                    <td class="text-center" v-for="data, key in electrical.manpower_avail" :key="key"><strong>{{data}}</strong></td>
                                </tr>

                                <tr>
                                    <td><h5>Refractory</h5></td>
                                    <td></td>
                                    <td></td>
                                    <td v-for="date, key in dpr_manpower.dates" :key="key"></td>
                                </tr>
                                <tr v-for="refractory, refractory_key in project.pc_refractory" :key="refractory_key">
                                    <td></td>
                                    <td><strong>{{refractory.contractor}}</strong></td>
                                    <td class="text-center"><strong>{{refractory.manpower_req}}</strong></td>
                                    <td class="text-center" v-for="data, key in refractory.manpower_avail" :key="key"><strong>{{data}}</strong></td>
                                </tr>

                                <!-- <tr v-for="activity_group, key in getManpowerData(project)" :key="key">
                                    <td class="text-left" v-if="activity_group.activity_group_name != ''"><h5>{{ activity_group.activity_group_name }}-{{ activity_group.section_group_name }}</h5></td>
                                    <td v-else></td>

                                    <td class="text-left" v-if="activity_group.contractor_name != ''"><strong>{{activity_group.contractor_name}}</strong></td>
                                    <td class="text-left" v-else>{{activity_group.activity_name}}</td>

                                    <td class="text-center" v-if="activity_group.contractor_name != ''"><strong>{{activity_group.required}}</strong></td>
                                    <td class="text-center" v-else>{{activity_group.required}}</td>
                                    
                                    <td class="text-center" v-if="activity_group.manpower_avail != ''" v-for="date, key in activity_group.manpower_avail" :key="key">{{date}}</td>
                                    <td class="text-center" v-else-if="activity_group.result == '' && activity_group.manpower_avail == ''" v-for="date, key in dpr_manpower.dates" ></td>
                                    <td class="text-center fw-bold" v-else v-for="result in activity_group.result">{{result}}</td>

                                    
                                </tr> -->
                            </tbody> 
                        </table>
                    </div>
                    <div style="float: right;" class="mt-2">
                        <button class="btn btn-warning btn-sm" @click="getDPRManpowers()"><i class="ri-restart-line icon_hgt"></i>Load More</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import moment from 'moment';
    export default {
        data() {
            return {
                dpr_manpower: {
                    project_group_id: '',
                    project_id:'',
                    manpower_template_id:'',
                    from_date: '',
                    to_date:'',
                    dates:[],
                },
                project:{
                    project_group_id:'',
                    project_id:'',
                    manpower_template_name:'',
                    contractors:[],
                    contractor_name:'',
                    activity_groups:[],
                    section_groups:[],
                    dpr_manpower_templates:[]
                },
                dpr_manpower_templates:[],
                errors: [],
                project_groups: [],
                projects:[],
                project_index:0
            }
        },
        mounted() {      
            if(this.$store.getters?.user){
                this.getProjectGroups();
            }      
            
            this.dpr_manpower.to_date= moment().subtract(1, 'day').format('yyyy-MM-DD')
        },
        methods: {
            getMechManpowerData(mech){
                let vm = this;
                let activity_groups = []

                activity_groups.push({
                    'activity_group_name' : 'Mechanical',
                    'contractor_name': '',
                    'activity_name':'',
                    'required':'',
                    'manpower_avail':'',
                    'result':''
                })
                if(mech.length){
                    mech.map(function(ele){
                        activity_groups.push({
                            'activity_group_name' : '',
                            'contractor_name': ele.contractor,
                            'activity_name':'',
                            'required': ele?.manpower_req_total,
                            'manpower_avail':'',
                            'result': ele.manpower_avail_total
                        })
                        if(ele.manpower_avail_fab.length){
                            activity_groups.push({
                                'activity_group_name' : '',
                                'section_group_name' : '',
                                'contractor_name': '',
                                'activity_name': 'Fabriaction',
                                'required': ele.manpower_req_fab,
                                'manpower_avail':ele.manpower_avail_fab,
                                'result':''
                            })
                        }

                        if(ele.manpower_avail_erection.length){
                            activity_groups.push({
                                'activity_group_name' : '',
                                'section_group_name' : '',
                                'contractor_name': '',
                                'activity_name': 'Erection',
                                'required': ele.manpower_req_erection,
                                'manpower_avail':ele.manpower_avail_erection,
                                'result':''
                            })
                        }


                    })
                }
                return activity_groups
            },
            getManpowerData(project){
                let vm = this;
                project.contractors = []
                let activity_groups = []
                let contractors = []

                if(project.manpower_activity_groups.length){
                    project.manpower_activity_groups.map(function(ele){
                        activity_groups.push({
                            'activity_group_name' : ele.activity_group.activity_group_name,
                            'section_group_name' : ele.section_group.section_group_name,
                            'contractor_name': '',
                            'activity_name':'',
                            'required':'',
                            'manpower_avail':'',
                            'result':''
                        }) 
                        ele.manpower_contractors.map(function(contractor){
                            let total_required = 0;
                            let manpower_avail = [];
                            let result = [];
                            if (contractor.manpower_activities.length){
                                contractor.manpower_activities.map(function(activity){
                                    manpower_avail.push(activity.manpower_avail)
                                    total_required =+ total_required + activity.manpower_reqd
                                    result = []
                                    if(activity.manpower_avail.length){
                                        for (let i = 0; i < activity.manpower_avail.length; i++) {
                                            let manpower_avail_data = []
                                        
                                            for (let j = 0; j < manpower_avail.length; j++) {
                                                manpower_avail_data.push(manpower_avail[j][i])
                                            }
                                            result.push(manpower_avail_data.reduce((total, num) => total + num, 0))
                                        }
                                    }
                                    
                                })
                            }
                            console.log("result", result)
                            activity_groups.push({
                                'activity_group_name' : '',
                                'section_group_name' : '',
                                'contractor_name': contractor.contractor_name,
                                'activity_name':'',
                                'required':total_required,
                                'manpower_avail':'',
                                'result':result
                            })

                            if (contractor.manpower_activities.length){
                                let result = [];
                                contractor.manpower_activities.map(function(activity){
                                    activity_groups.push({
                                        'activity_group_name' : '',
                                        'section_group_name' : '',
                                        'contractor_name': '',
                                        'activity_name': activity.activity.activity_name,
                                        'required':activity.manpower_reqd,
                                        'manpower_avail':activity.manpower_avail,
                                        'result':''
                                    })
                                })
                            }
                        })
                    })
                }
                return activity_groups
            },
            getProjectGroups(){
                let vm = this;
                this.$store.dispatch('post',{uri:'getProjectGroups'})
                .then(response => {
                    vm.project_groups = response.data.data;
                    if(localStorage.getItem('project_group_id')){
                        vm.dpr_manpower.project_group_id = localStorage.getItem('project_group_id') 
                    }else if (vm.project_groups.length) {
                        if (!vm.dpr_manpower.project_group_id) {
                            vm.dpr_manpower.project_group_id = vm.project_groups[0].project_group_id;
                        }
                    }
                    vm.getProjectsGroup()
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
            },
            // getManpowerTemplates(){
            //     let vm = this
            //     this.$store.dispatch('post',{uri:'getManpowerTemplates', data: vm.dpr_manpower})
            //     .then(response => {
            //         vm.dpr_manpower_templates.push(response.data)
            //     })
            //     .catch(error => {
            //         console.log(error)
            //     })
            // },

            getProjectsGroup(){
                let vm = this
                vm.project.dpr_manpower_templates = []
                this.$store.dispatch('post',{uri:'getProjectsGroup', data:vm.dpr_manpower})
                .then(response => {
                    vm.projects = response.data.data
                    // if(vm.projects.length){
                    //     vm.dpr_report.project_id = vm.projects[0].project_id
                    // }
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
            },

            getDPRManpowers() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.dpr_manpower.dates = [];
                
                if (this.dpr_manpower.from_date && this.dpr_manpower.to_date){
                    const start = new Date(this.dpr_manpower.from_date);
                    const end = new Date(this.dpr_manpower.to_date);
                    const days = Math.floor((end - start) / (1000 * 60 * 60 * 24));

                    while (start <= end) {
                        vm.dpr_manpower.dates.push(moment(String(start)).format("YYYY-MM-DD"));
                        start.setDate(start.getDate() + 1);
                    }
                   
                }
                vm.dpr_manpower.project_id = vm.projects[vm.project_index].project_id
                vm.project_index++

                this.$store.dispatch('post',{uri:'getManpowerTemplate', data: vm.dpr_manpower})
                .then(response => {
                    loader.hide();
                    vm.project.dpr_manpower_templates.push(response.data.data[0])
                    vm.errors = []
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
            },

            
            downloadDPRManpowerReport(){
                let vm = this;
                vm.errors = [];
                if (vm.dpr_manpower.project_group_id == ""){
                    vm.errors.project_group_id = ["The project group field is required."]
                }
                if (vm.dpr_manpower.from_date == ""){
                    vm.errors.from_date = ["The from date field is required."]
                }
                if (vm.dpr_manpower.to_date == ""){
                    vm.errors.to_date = ["The to date field is required."]
                }
                if (vm.dpr_manpower.manpower_template_id == ""){
                    vm.errors.manpower_template_id = ["The dpr name field is required."]
                }
                if (vm.dpr_manpower.project_group_id && vm.dpr_manpower.from_date && vm.dpr_manpower.to_date){
                    window.open(vm.$store.getters.apiUrl+'downloadDprManpowerReport?project_group_id='+vm.dpr_manpower.project_group_id+'&from_date='+vm.dpr_manpower.from_date+'&to_date='+vm.dpr_manpower.to_date+'&dates='+vm.dpr_manpower.dates);
                }
            }
        }
    }

</script>

<style scoped>
    .text-right{
        text-align: right;
    }
    .fs-12 {
        font-size: 12pt;
    }
    .fs-14{
        font-size: 14pt;
    }
    .ft-bold{
        font-weight: bold;
    }
    /* .bg-primary bg-opacity-50{
        background-color: #c6b505;
        background-color: #91a3e0;
        color:rgb(34, 33, 33);

    
    } */
    /* .bg-primary bg-opacity-25{
        background-color: #daca24;
        background-color: #dce2f7;
        color:black;
    } */
    .dropdown {
        position: relative;
        cursor: pointer;
    }
    .multiselect {
        position: relative;
    }
    .multiselect ul {
        border: 1px solid #ddd;
        border-top: 0;
        border-radius: 0 0 3px 3px;
        left: 0px;
        padding: 8px 8px;
        top: -0.1rem;
        width: 100%;
        list-style: none;
        max-height: 150px;
        overflow: auto;
        background: white;
    }
    .overselect {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>