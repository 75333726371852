<template>
    <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Overall Schedule</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                        <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="report.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Project</label>
                        <!-- <select class="form-select" :class="{'is-invalid': errors.project_id}" v-model="report.project_id">
                            <option value="">Select Project</option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name}} </option>
                        </select> -->
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="report.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @input="project => report.project_id = project"
                        >
                        </search>
                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Date</label>
                        <input class="form-control" type="date" placeholder="Date" :class="{ 'is-invalid': errors.date_time }" v-model="report.date_time" />
                        <span v-if="errors.date_time" class="invalid-feedback">{{ errors.date_time[0] }}</span>
                    </div>
                    <div class="col-md-3 mt-auto">
                        <button class="btn btn-sm btn-primary me-2" @click="showOverallSchedule()" type="button"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-primary " @click="downloadOverallScheduleReport()"><i class="ri-download-line icon_hgt"></i> Download </button>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center">Sl No.</th>
                                        <th class="text-center">Activity Description</th>
                                        <th class="text-center">Target Completion</th>
                                        <th class="text-center">Excepted Completion</th>
                                        <th class="text-center">Remarks</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input text="number" class="form-control" placeholder="Sl No." :class="{ 'is-invalid': errors.sl_no }" v-model="form_field.sl_no" />
                                            <span v-if="errors.sl_no" class="invalid-feedback">{{ errors.sl_no[0] }}</span>
                                        </td>
                                        <td>
                                            <select class="form-select" :class="{'is-invalid': errors.activity_description_id}" v-model="form_field.activity_description_id">
                                                <option value="">Select Activity Description</option>
                                                <option v-for="activity_description, key in activity_descriptions" :key="key" :value="activity_description.activity_description_id">
                                                    {{ activity_description.activity_description_name}}
                                                </option>
                                            </select>
                                            <span v-if="errors.activity_description_id" class="invalid-feedback">{{ errors.activity_description_id[0] }}</span>
                                        </td>

                                        <td>
                                            <input type="date" placeholder="Target Completion" class="form-control" :class="{ 'is-invalid': errors.target_completion }" v-model="form_field.target_completion" />
                                            <span v-if="errors.target_completion" class="invalid-feedback">{{ errors.target_completion[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="date" placeholder="Excepted Completion" class="form-control" :class="{ 'is-invalid': errors.expected_completion }" v-model="form_field.expected_completion" />
                                            <span v-if="errors.expected_completion" class="invalid-feedback">{{ errors.expected_completion[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="text" placeholder="Remarks" class="form-control" :class="{ 'is-invalid': errors.remarks }" v-model="form_field.remarks" />
                                            <span v-if="errors.remarks" class="invalid-feedback">{{ errors.remarks[0] }}</span>
                                        </td>
                                        <td class="text-center">
                                            <button v-if="formStatus" @click="addRow()" class="btn btn-sm btn-primary"><i class="ri-add-line"></i></button>
                                            <button v-else @click="updateRow()" class="btn btn-sm btn-primary"><i class="ri-save-line"></i></button>
                                        </td>
                                    </tr>
                                    <tr v-for="(form_field, key) in report.overall_project_schedules" :key="key">
                                        <td>{{ form_field.sl_no }}</td>
                                        <td>{{form_field.activity_description_name}}</td>
                                        <td>{{form_field.target_completion}}</td>
                                        <td>{{form_field.expected_completion}}</td>
                                        <td>{{form_field.remarks}}</td>
                                        <td class="text-center">
                                            <button class="btn btn-sm btn-outline-success me-2" data-toggle="tooltip" data-placement="bottom" title="Edit" @click="editRow(form_field,key)">
                                                <i class="ri-pencil-line"></i>
                                            </button>
                                            <button class="btn btn-outline-danger btn-sm" type="button" @click="deleteRow(form_field,key)">
                                                <i class="ri-delete-bin-line"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-primary btn-sm" @click.prevent="submitForm()">
                    <span v-if="status"><i class="ri-save-line icon_hgt"></i> SUBMIT</span>
                    <span v-else><i class="ri-save-line icon_hgt"></i> UPDATE</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
    let Search = require("@/components/Search.vue").default;
    export default {
        name: "OverallSchedule",
        components: {Search },
        data() {
            return {
                initial:true,
                report: {
                    report_id: "",
                    project_id: "",
                    project_group_id:"",
                    date_time: "",
                    deleted_form_fields: [],
                    overall_project_schedules: [],
                },
                form_field: {
                    sl_no: "",
                    activity_description_id: "",
                    activity_description_name: "",
                    target_completion: "",
                    expected_completion: "",
                    remarks: "",
                },
                status: true,
                formStatus: true,
                activity_descriptions: [],

                projects: [],
                projectgroups:[],
                errors: [],
            };
        },
        mounted() {      
            if(this.$store.getters?.user){
                this.getProjectGroups();
            }       
            
            this.report.date_time = moment().subtract(1, 'day').format('yyyy-MM-DD')
        },
        methods: {
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.report.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.report.project_group_id) {
                                vm.report.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.addReports();
                } else {
                    vm.updateReports();
                }
            },
            getProjects() {
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects", data: {project_group_id: vm.report.project_group_id} })
                    .then(function (response) {
                        vm.projects = response.data;
                        if(localStorage.getItem('project_id')){
                            vm.report.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.report.project_id = vm.projects[0].project_id;
                        }
                        if(vm.initial){
                            vm.getActivityDescriptions();
                            vm.showOverallSchedule();
                            vm.initial = false
                        }
                        
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getActivityDescriptions() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getActivityDescriptions" })
                    .then(function (response) {
                        vm.activity_descriptions = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addRow() {
                let vm = this;
                vm.errors = [];
                if (vm.form_field.activity_description_id == "" || vm.form_field.target_completion == "" || vm.form_field.expected_completion == "" || vm.form_field.remarks == "") {
                    if (vm.form_field.activity_description_id == "") {
                        vm.errors.activity_description_id = ["Activity Desription field cannot be empty"];
                    }
                    if (vm.form_field.target_completion == "") {
                        vm.errors.target_completion = ["Target Completion field cannot be empty"];
                    }
                    if (vm.form_field.expected_completion == "") {
                        vm.errors.expected_completion = ["Excepted Completion field cannot be empty"];
                    }
                    if (vm.form_field.remarks == "") {
                        vm.errors.remarks = ["Remarks cannot be empty"];
                    }
                } else {
                    let activity_desctiption = vm.activity_descriptions.filter(function (element) {
                        return element.activity_description_id == vm.form_field.activity_description_id;
                    });
                    if (activity_desctiption.length) {
                        vm.report.overall_project_schedules.push({
                            sl_no: vm.form_field.sl_no,
                            activity_description_id: vm.form_field.activity_description_id,
                            activity_description_name: activity_desctiption[0].activity_description_name,
                            target_completion: vm.form_field.target_completion,
                            expected_completion: vm.form_field.expected_completion,
                            remarks: vm.form_field.remarks,
                        });
                        vm.discardForm();
                    }
                }
            },
            updateRow() {
                let vm = this;
                vm.errors = [];
                if (vm.form_field.activity_description_id == "" || vm.form_field.target_completion == "" || vm.form_field.expected_completion == "" || vm.form_field.remarks == "") {
                    if (vm.form_field.activity_description_id == "") {
                        vm.errors.activity_description_id = ["Activity Desription field cannot be empty"];
                    }
                    if (vm.form_field.target_completion == "") {
                        vm.errors.target_completion = ["Target Completion field cannot be empty"];
                    }
                    if (vm.form_field.expected_completion == "") {
                        vm.errors.expected_completion = ["Excepted Completion field cannot be empty"];
                    }
                    if (vm.form_field.remarks == "") {
                        vm.errors.remarks = ["Remarks cannot be empty"];
                    }
                }
                else {
                    let activity_desctiption = vm.activity_descriptions.filter(function (element) {
                        return element.activity_description_id == vm.form_field.activity_description_id;
                    });
                    console.log("activity_desctiption",activity_desctiption)
                    if (activity_desctiption.length) {
                        vm.report.overall_project_schedules.push({
                            sl_no: vm.form_field.sl_no,
                            activity_description_id: vm.form_field.activity_description_id,
                            activity_description_name: activity_desctiption[0].activity_description_name,
                            target_completion: vm.form_field.target_completion,
                            expected_completion: vm.form_field.expected_completion,
                            remarks: vm.form_field.remarks,
                        });                        
                        vm.report.overall_project_schedules.splice(vm.form_field.key, 1, vm.report.overall_project_schedules);
                        vm.report.overall_project_schedules.splice(vm.form_field.key, 1);
                        vm.discardForm();
                    }
                }
            },
            discardForm() {
                let vm = this;
                vm.form_field.sl_no = "";
                vm.form_field.activity_description_id = "";
                vm.form_field.target_completion = "";
                vm.form_field.expected_completion = "";
                vm.form_field.remarks = "";
                vm.formStatus = true;
                vm.errors = [];
            },
            editRow(forms, key) {
                let vm = this;
                vm.form_field = forms;
                vm.formStatus = false;
                vm.form_field.key = key;
                vm.errors = [];
            },
            deleteRow(forms, key) {
                let vm = this;
                vm.report.deleted_form_fields.push(forms), vm.report.overall_project_schedules.splice(key, 1);
                vm.discardForm();
            },

            addReports() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addOverallProjectSchedule", data: vm.report })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Overall Project Schedule is successfully created");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            updateReports() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "updateOverallProjectSchedule", data: vm.report })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Overall Project Schedule is successfully updated");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            showOverallSchedule() {
                let vm = this;
                localStorage.setItem('project_group_id', vm.report.project_group_id)
                localStorage.setItem('project_id', vm.report.project_id)
                vm.errors = [];
                vm.report.overall_project_schedules = []
                if (vm.report.project_id == "" || vm.report.date_time == "") {
                    if (vm.report.project_id == "") {
                        vm.errors.project_id = ["Project field cannot be empty"];
                    }
                    if (vm.report.date_time == "") {
                        vm.errors.date_time = ["Date field cannot be empty"];
                    }
                } else {
                    let loader = vm.$loading.show();
                    vm.errors = [];
                    vm.$store
                        .dispatch("post", { uri: "getOverallProjectSchedule", data: vm.report })
                        .then(function (response) {
                            loader.hide();
                            if (response.data) {
                                vm.report.overall_project_schedules = response.data.data.ovearall_project_schedule
                                vm.status = false
                            }
                        })
                        .catch(function (error) {
                            loader.hide()
                            console.log(error)
                        });
                }
            },
            downloadOverallScheduleReport(){
                let vm = this;
                vm.errors = [];
                if (vm.report.project_id == ""){
                    vm.errors.project_id = ["The project field is required."]
                }
                if (vm.report.date_time == ""){
                    vm.errors.date_time = ["The Date field is required."]
                }
                if (vm.report.project_id && vm.report.date_time ){
                    if(vm.report.overall_project_schedules.length){
                        window.open(vm.$store.getters.apiUrl+'downloadOverallScheduleReport?project_id='+vm.report.project_id+'&date_time='+vm.report.date_time)
                    }else{
                        vm.$store.dispatch("info", 'No records to download');
                    }
                }
            }
        },
    };
</script>
