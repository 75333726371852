<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">Budget</li>
			</ol>
			<h4 class="main-title mb-0">Snapshot Report</h4>
		</div>
        <!-- <div class="d-flex align-items-center gap-2 mt-3 mt-md-0" v-can="'budget_requests.create'">
			<a href="#" target="_blank" class="btn btn-success d-flex align-items-center gap-2">
				<i class="ri-download-line fs-18 lh-1"></i>Download
			</a>
		</div> -->
	</div>
	<div class="row g-3 mb-4">
		<div class="col-sm-12">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">
                        <select class="form-control form-controls-sm mx-2" v-model="report.project_group_id" @change="getProjects">
                            <option value="">Select Project Group</option>
                            <option v-for="project_group in project_groups" :value="project_group.project_group_id">
                                {{ project_group.project_group_name }}
                            </option>
                        </select>
                    </h6>
					<nav class="nav ms-auto">
						<div class="d-flex">
                            <select class="form-control form-controls-sm mx-2" v-model="currency_unit">
                                <option value="1">in rupees</option>
                                <option value="100000">in lakhs</option>
                                <option value="10000000">in cr</option>
                            </select>
						</div>
					</nav>
				</div>
				<div class="card-body">
                    <div class="row g-2 mb-4">
						<div class="col-md-12" v-if="report.project_group_id==''">
                            <strong>Project Groups</strong>
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
                                            <th>Project Group</th>
											<th class="text-center">Approved <br> Budget</th>
											<th class="text-center">SAP <br> Commitment</th>
											<th class="text-center">Manual <br> Commitment</th>
											<th class="text-center">Total <br> Commitment</th>
											<th class="text-center">Actual <br> Spent</th>
											<th class="text-center">Balance <br>Commitment <br> in Hand</th>
											<th class="text-center">Projected <br> Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
                                        <tr v-for="project_group, key in project_groups" :key="key">
                                            <td class="text-center">{{ key+1 }}</td>
											<td>{{ project_group.project_group_name }}</td>
                                            <td class="text-right">{{ formatCurrency(project_group.budget) }}</td>
                                            <td class="text-right">{{ formatCurrency(project_group.sap_commit) }}</td>
                                            <td class="text-right">{{ formatCurrency(project_group.manual_commit) }}</td>
											<td class="text-right">{{ formatCurrency(project_group.total_commit) }}</td>
											<td class="text-right">{{ formatCurrency(project_group.actual_spent) }}</td>
											<td :class="project_group.balance_in_hand>=0?'green-savings':'red-savings'">
												{{ formatCurrency(project_group.balance_in_hand) }}
												<i v-if="project_group.balance_in_hand>0" class="ri-arrow-up-s-fill"></i>
                                                <i v-if="project_group.balance_in_hand<0" class="ri-arrow-down-s-fill"></i>
											</td>
											<td class="text-right">{{ formatCurrency(project_group.projected_commitment) }}</td>
											<td :class="project_group.savings>=0?'green-savings':'red-savings'">
												{{ formatCurrency(project_group.savings) }}
												<i v-if="project_group.savings>0" class="ri-arrow-up-s-fill"></i>
                                                <i v-if="project_group.savings<0" class="ri-arrow-down-s-fill"></i>
											</td>
                                        </tr>
									</tbody>
                                    <tfoot>
                                        <tr>
                                            <th colspan="2" class="text-center">Total</th>
                                            <th class="text-right">{{ formatCurrency(project_group_budget) }}</th>
                                            <th class="text-right">{{ formatCurrency(project_group_sap_commit) }}</th>
                                            <th class="text-right">{{ formatCurrency(project_group_manual_commit) }}</th>
                                            <th class="text-right">{{ formatCurrency(project_group_total_commit) }}</th>
                                            <th class="text-right">{{ formatCurrency(project_group_actual_spent) }}</th>
											<th :class="project_group_balance_in_hand>=0?'green-savings':'red-savings'">
												{{ formatCurrency(project_group_balance_in_hand) }}
												<i v-if="project_group_balance_in_hand>0" class="ri-arrow-up-s-fill"></i>
                                                <i v-if="project_group_balance_in_hand<0" class="ri-arrow-down-s-fill"></i>
											</th>
                                            <th class="text-right">{{ formatCurrency(project_group_projected_commitment) }}</th>
                                            <th :class="project_group_savings>=0?'green-savings':'red-savings'">
												{{ formatCurrency(project_group_savings) }}
												<i v-if="project_group_savings>0" class="ri-arrow-up-s-fill"></i>
                                                <i v-if="project_group_savings<0" class="ri-arrow-down-s-fill"></i>
											</th>
                                        </tr>
                                    </tfoot>
								</table>
							</div>
						</div>
						<div class="col-md-12" v-else>
                            <strong>Projects</strong>
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
                                            <th>Project Name</th>
											<th class="text-center">Approved <br> Budget</th>
											<th class="text-center">SAP <br> Commitment</th>
											<th class="text-center">Manual <br> Commitment</th>
											<th class="text-center">Total <br> Commitment</th>
											<th class="text-center">Actual <br> Spent</th>
											<th class="text-center">Balance <br>Commitment <br> in Hand</th>
											<th class="text-center">Projected <br> Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
                                        <tr v-for="project, key in projects" :key="key">
                                            <td class="text-center">{{ key+1 }}</td>
											<td>{{ project.project_name }}</td>
                                            <td class="text-right">{{ formatCurrency(project.budget) }}</td>
                                            <td class="text-right">{{ formatCurrency(project.sap_commit) }}</td>
                                            <td class="text-right">{{ formatCurrency(project.manual_commit) }}</td>
											<td class="text-right">{{ formatCurrency(project.total_commit) }}</td>
											<td class="text-right">{{ formatCurrency(project.actual_spent) }}</td>
											<td :class="project.balance_in_hand>=0?'green-savings':'red-savings'">
												{{ formatCurrency(project.balance_in_hand) }}
												<i v-if="project.balance_in_hand>0" class="ri-arrow-up-s-fill"></i>
                                                <i v-if="project.balance_in_hand<0" class="ri-arrow-down-s-fill"></i>
											</td>
											<td class="text-right">{{ formatCurrency(project.projected_commitment) }}</td>
											<td :class="project.savings>=0?'green-savings':'red-savings'">
												{{ formatCurrency(project.savings) }}
												<i v-if="project.savings>0" class="ri-arrow-up-s-fill"></i>
                                                <i v-if="project.savings<0" class="ri-arrow-down-s-fill"></i>
											</td>
                                        </tr>
									</tbody>
                                    <tfoot>
                                        <tr class="text-primary">
                                            <th colspan="2" class="text-center">Total</th>
                                            <th class="text-right">{{ formatCurrency(budget) }}</th>
                                            <th class="text-right">{{ formatCurrency(sap_commit) }}</th>
                                            <th class="text-right">{{ formatCurrency( manual_commit) }}</th>
                                            <th class="text-right">{{ formatCurrency(total_commit) }}</th>
                                            <th class="text-right">{{ formatCurrency(actual_spent) }}</th>
											<th :class="balance_in_hand>=0?'green-savings':'red-savings'">
												{{ formatCurrency(balance_in_hand) }}
												<i v-if="balance_in_hand>0" class="ri-arrow-up-s-fill"></i>
                                                <i v-if="balance_in_hand<0" class="ri-arrow-down-s-fill"></i>
											</th>
                                            <th class="text-right">{{ formatCurrency(projected_commitment) }}</th>
                                            <th :class="savings>=0?'green-savings':'red-savings'">
												{{ formatCurrency(savings) }}
												<i v-if="savings>0" class="ri-arrow-up-s-fill"></i>
                                                <i v-if="savings<0" class="ri-arrow-down-s-fill"></i>
											</th>
                                        </tr>
                                    </tfoot>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Users.Create",
	components: {},
	data() {
		return {
            report:{
                project_group_id:'',
                project_id:'',
            },
            project_groups:[],
			projects:[],
            currency_unit: 10000000
		};
	},
	mounted() {
        this.getProjectGroups();
	},
    computed: {
		project_group_budget: function () {
			let vm = this;
			return vm.project_groups.reduce((prev, cur) => +prev + +cur.budget, 0);
		},
		project_group_sap_commit: function () {
			let vm = this;
			return vm.project_groups.reduce((prev, cur) => +prev + +cur.sap_commit, 0);
		},
		project_group_manual_commit: function () {
			let vm = this;
			return vm.project_groups.reduce((prev, cur) => +prev + +cur.manual_commit, 0);
		},
		project_group_total_commit: function () {
			let vm = this;
			return vm.project_groups.reduce((prev, cur) => +prev + +cur.total_commit, 0);
		},
		project_group_actual_spent: function () {
			let vm = this;
			return vm.project_groups.reduce((prev, cur) => +prev + +cur.actual_spent, 0);
		},
		project_group_balance_in_hand: function () {
			let vm = this;
			return vm.project_groups.reduce((prev, cur) => +prev + +cur.balance_in_hand, 0);
		},
		project_group_projected_commitment: function () {
			let vm = this;
			return vm.project_groups.reduce((prev, cur) => +prev + +cur.projected_commitment, 0);
		},
		project_group_savings: function () {
			let vm = this;
			return vm.project_groups.reduce((prev, cur) => +prev + +cur.savings, 0);
		},
		
		budget: function () {
			let vm = this;
			return vm.projects.reduce((prev, cur) => +prev + +cur.budget, 0);
		},
		sap_commit: function () {
			let vm = this;
			return vm.projects.reduce((prev, cur) => +prev + +cur.sap_commit, 0);
		},
		manual_commit: function () {
			let vm = this;
			return vm.projects.reduce((prev, cur) => +prev + +cur.manual_commit, 0);
		},
		total_commit: function () {
			let vm = this;
			return vm.projects.reduce((prev, cur) => +prev + +cur.total_commit, 0);
		},
		actual_spent: function () {
			let vm = this;
			return vm.projects.reduce((prev, cur) => +prev + +cur.actual_spent, 0);
		},
		balance_in_hand: function () {
			let vm = this;
			return vm.projects.reduce((prev, cur) => +prev + +cur.balance_in_hand, 0);
		},
		projected_commitment: function () {
			let vm = this;
			return vm.projects.reduce((prev, cur) => +prev + +cur.projected_commitment, 0);
		},
		savings: function () {
			let vm = this;
			return vm.projects.reduce((prev, cur) => +prev + +cur.savings, 0);
		}
    },
	methods: {
        getProjectGroups() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "projectGroupSnapshotReport" })
			.then(function (response) {
				vm.project_groups = response.data.data
			})
			.catch(function (error) {
				console.log(error);
			});
		},

        getProjects() {
			let vm = this;
			this.report.project_id ='';
            this.projects = [];
			vm.$store.dispatch("post", { uri: "budgetSnapshotReport", data:vm.report })
			.then(function (response) {
				vm.projects = response.data.data;
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		formatCurrency(amount){
            let value = amount/this.currency_unit;
            return value.toLocaleString('en-IN',{
                maximumFractionDigits: 0,
                style: 'currency',
                currency: 'INR'
            });
        },
	},
};
</script>

<style scoped>
    .red-savings {
        color: red !important;
		text-align: right;
    }

	/* .red-savings i {
		font-size: 20px;
	} */

    .green-savings {
        color: green !important; 
		text-align: right;
    }

	/* .green-savings i {
		font-size: 20px;
	} */

	.text-right {
		text-align: right !important;
	}

	thead th {
		font-weight: bolder !important;
		vertical-align: middle;
	}
</style>