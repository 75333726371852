<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">Project Cost</h2>
            <router-link to="/project_costs" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-list-check"></i> PROJECT COSTS</router-link>
        </div>
        <div class="card">
            <div class="card-header">
                <strong>New Project Cost</strong>
            </div>
            <div class="card-body mb-3">
                <form>
                    <div class="row mb-3 g-3">
                        <div class="col-md-4">
                            <label class="form-label">Project Code</label>
                            <input type="text" class="form-control form-control-sm" placeholder="Project Code">
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Project Name</label>
                            <input type="text" class="form-control form-control-sm" placeholder="Project Name">
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Project Category</label>
                            <select class="form-select form-select-sm" v-model="project_category" @change="getProjects()">
                                <option value="">Select Project Category</option>
                                <option value="iu">IU</option>
                                <option value="gu">GU</option>
                                <option value="rp">RP</option>
                                <option value="bt">BT</option>
                            </select>
                        </div>

                        <div class="col-md-12">
                            <div class="table-responsive table-resposive-sm">
                                <table class="table table-sm text-nowrap table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Cost Group</th>
                                            <th>Cost Sub Group</th>
                                            <th>Cost Head</th>
                                            <th>Cost Sub Head</th>
                                            <th>Cost Item</th>
                                            <th>Cost Sub Item</th>
                                            <th>Cost Sub Sub Item</th>
                                            <th>Unit Of Measurement</th>
                                            <th>Cost Type</th>
                                            <th>Budget</th>
                                            <th>Request Allocation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,key) in selected_project_category" :key="key">
                                            <td class="text-center">{{ meta.from+key }}</td>
                                            <td>{{ item.cost_group }}</td>
                                            <td>{{item.cost_sub_group }}</td>
                                            <td>{{ item.cost_head}}</td>
                                            <td>{{ item.cost_sub_head}}</td>
                                            <td>{{ item.cost_item}}</td>
                                            <td>{{ item.cost_sub_item}}</td>
                                            <td>{{ item.cost_sub_sub_item}}</td>
                                            <td>{{ item.uom }}</td>
                                            <td>{{ item.cost_type }}</td>
                                            <td>{{ item.budget }}</td>
                                            <td><input type="text" class="form-control form-control-sm" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger me-2"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                <button class="btn btn-primary"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        components: {},
        data() {
            return {
                result: "",
                meta: {
                    from: 1,
                },
                project_category: "",
                selected_project_category: [],
                project_categories: [
                    {
                        project_category: "iu",
                        cost_group: " ui Cost Group",
                        cost_sub_group: " ui Cost Sub Group",
                        cost_head: " ui Cost Head",
                        cost_sub_head: " ui Cost Sub Head",
                        cost_item: " ui Cost Item",
                        cost_sub_item: " ui Cost Sub Item",
                        cost_sub_sub_item: " ui Cost Sub Sub Item",
                        unit_of_measurement: [
                            {
                                uom: "No",
                            },
                            { uom: "MT" },
                        ],
                        cost_type: " ui Cost Type",
                        budget: "10,000",
                    },
                    {
                        project_category: "gu",
                        cost_group: " gu Cost Group",
                        cost_sub_group: " gu Cost Sub Group",
                        cost_head: " gu Cost Head",
                        cost_sub_head: " gu Cost Sub Head",
                        cost_item: " gu Cost Item",
                        cost_sub_item: " gu Cost Sub Item",
                        cost_sub_sub_item: " gu Cost Sub Sub Item",
                        unit_of_measurement: [
                            {
                                uom: "No",
                            },
                        ],
                        cost_type: " gu Cost Type",
                        budget: "20,000",
                    },
                    {
                        project_category: "rp",
                        cost_group: " rp Cost Group",
                        cost_sub_group: " rp Cost Sub Group",
                        cost_head: " rp Cost Head",
                        cost_sub_head: " rp Cost Sub Head",
                        cost_item: " rp Cost Item",
                        cost_sub_item: " rp Cost Sub Item",
                        cost_sub_sub_item: " rp Cost Sub Sub Item",
                        unit_of_measurement: [
                            {
                                uom: "CuM",
                            },
                            { uom: "800 Rs" },
                        ],
                        cost_type: " rp Cost Type",
                        budget: "30,000",
                    },
                    {
                        project_category: "bt",
                        cost_group: " bt Cost Group",
                        cost_sub_group: " bt Cost Sub Group",
                        cost_head: " bt Cost Head",
                        cost_sub_head: " bt Cost Sub Head",
                        cost_item: " bt Cost Item",
                        cost_sub_item: " bt Cost Sub Item",
                        cost_sub_sub_item: " bt Cost Sub Sub Item",
                        unit_of_measurement: [
                            {
                                uom: "RM",
                            }
                        ],
                        cost_type: " bt Cost Type",
                        budget: "40,00",
                    },
                ],
            };
        },

        methods: {
            getProjects() {
                let vm = this;
                let pc = vm.project_categories.filter((project_category) => {
                    return project_category.project_category == vm.project_category;
                });
                console.log(pc[0]);
                if (pc.length) {
                    // try
                    pc[0].unit_of_measurement.map(function (element) {
                        vm.selected_project_category.push({
                            cost_group: pc[0].cost_group,
                            cost_head: pc[0].cost_head,
                            cost_item: pc[0].cost_item,
                            cost_sub_group: pc[0].cost_sub_group,
                            cost_sub_head: pc[0].cost_sub_head,
                            cost_sub_item: pc[0].cost_sub_item,
                            cost_sub_sub_item: pc[0].cost_sub_sub_item,
                            cost_type: pc[0].cost_type,
                            uom: element.uom,
                            budget: pc[0].budget,
                        });
                    });
                }
            },
        },
    };
</script>
