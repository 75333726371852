<template>
    <div class="">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>DPR Progress</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                        <label class="form-label">Project Group</label>
                        <select class="form-select form-select-sm" :class="{'is-invalid': errors.project_group_id}" v-model="dpr_report.project_group_id" @change="getProjectsGroup()">
                            <option value=""> Select Project Group </option>
                            <option v-for="project_group, key in project_groups" :key="key" :value="project_group.project_group_id">{{project_group.project_group_name }} </option>
                        </select>
                        <span v-if="errors.project_group_id" class="invalid-feedback">{{ errors.project_group_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Date</label>
                        <input class="form-control form-control-sm" type="date" name="" id="" v-model="dpr_report.job_date_time">
                        <span v-if="errors.job_date_time" class="invalid-feedback">{{ errors.job_date_time[0] }}</span>
                    </div>
                    <div class="col-md-3 mt-auto">
                        <button class="btn btn-sm btn-primary me-2" @click="dprProgressReport()" type="button"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-primary " @click="downloadDprProgressReport()"><i class="ri-download-line icon_hgt"></i> Download </button>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-wrap table-bordered mb-0">
                                <thead class="bg-primary bg-opacity-50">
                                    <tr class="text-center">
                                        <th rowspan="2" style="vertical-align: middle;"> Particulars </th>
                                        <th rowspan="2" style="vertical-align: middle;"> UOM </th>
                                        <th colspan="5"> Total( As on date) </th>
                                        <th colspan="3">Month</th>
                                        <th>Day</th>
                                    </tr>
                                    <tr class="text-center" style="vertical-align: middle;">
                                        <th>Scope</th>
                                        <th>CTD (Act)</th>
                                        <th>% Complete <br>w.r.t CTD (Act.)</th>
                                        <th>CTD (Pl)</th>
                                        <th>% Complete <br>w.r.t CTD (Pl.)</th>
                                        <th>Target FTM</th>
                                        <th>MTD</th>
                                        <th>% Comp.</th>
                                        <th>FTD</th>
                                    </tr>
                                </thead>
                                <tbody v-if="dpr_data?.length == 0">
                                    <tr>
                                        <td colspan="11" class="text-center">No Records to display!...</td>
                                    </tr>
                                </tbody>
                                <tbody v-for="dprs, dpr_data_key in dpr_data" :key="dpr_data_key">
                                    <tr class="bg-primary bg-opacity-25">
                                        <td colspan="11"><strong>{{dprs.project.project_name}}</strong></td>
                                    </tr>
                                    <template v-for="dpr, dpr_key in dprs.data" :key="dpr_key">
                                        <tr v-if="eliminateZeros(dpr)">
                                            <td>{{ dpr.particular }}</td>
                                            <td>{{ dpr.activity.uom }}</td>
                                            <td class="text-center">{{ dpr.activity.scope }}</td> 
                                            <td class="text-center">{{ dpr.activity.ctd_act}}</td> 
                                            <td class="text-center">{{ dpr.activity.percentage_complete_act}}</td> 
                                            <td class="text-center">{{ dpr.activity.ctd_pl }}</td> 
                                            <td class="text-center">{{ dpr.activity.percentage_complete_pl}}</td> 
                                            <td class="text-center">{{ dpr.activity.target_ftm}}</td> 
                                            <td class="text-center">{{ dpr.activity.mtd}}</td> 
                                            <td class="text-center">{{ dpr.activity.percentage_complete }}</td> 
                                            <td class="text-center">{{ dpr.activity.ftd}}</td> 
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div style="float: right;" class="mt-2">
                            <button class="btn btn-warning btn-sm" @click="dprProgressReport()"><i class="ri-restart-line icon_hgt"></i>Load More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
    export default {
        data() {
            return {               
                errors: [],
                project_groups: [],
                dpr_report: {
                    project_group_id: '',
                    job_date_time: '',
                    project_id:'',
                    to_date:''
                },
                projects:[],
                dpr_data:[],
                project_index:0
            };
        },
        mounted() {    
            if(this.$store.getters?.user){
                this.getProjectGroups()
            }
            this.dpr_report.job_date_time = moment().format('yyyy-MM-DD')
        },
        methods: {
            eliminateZeros(dpr){
                if(dpr.activity.ctd_act || dpr.activity.ctd_pl){
                    return dpr
                }else{
                    return false
                }
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();                
            },

            getProjectGroups(){
                let vm = this;
                this.$store.dispatch('post',{uri:'getProjectGroups'})
                .then(response => {
                    vm.project_groups = response.data.data;
                    if(localStorage.getItem('project_group_id')){
                        vm.dpr_report.project_group_id = localStorage.getItem('project_group_id') 
                    }else if (vm.project_groups.length) {
                        if (!vm.dpr_report.project_group_id) {
                            vm.dpr_report.project_group_id = vm.project_groups[vm.project_index].project_group_id;
                        }
                    }
                    vm.getProjectsGroup()
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
            },

            getProjectsGroup(){
                let vm = this;
                vm.dpr_data = []
                this.$store.dispatch('post',{uri:'getProjectsGroup', data:vm.dpr_report})
                .then(response => {
                    vm.projects = response.data.data
                    // if(vm.projects.length){
                    //     vm.dpr_report.project_id = vm.projects[0].project_id
                    // }
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
            },

            dprProgressReport(){
                let vm = this;
                if(this.dpr_report.project_group_id == '' || this.dpr_report.job_date_time == ''){
                    return ;
                }
                let loader = vm.$loading.show()
                vm.dpr_report.project_id = vm.projects[vm.project_index].project_id
                vm.project_index++
                vm.dpr_report.to_date = this.dpr_report.job_date_time
                vm.$store.dispatch('post',{uri:'dprProgressReport',data:vm.dpr_report})
                .then(response => {
                    loader.hide()
                    vm.dpr_data.push(response.data)
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
            },
            downloadDprProgressReport(){
              let vm = this;
                vm.errors = [];
                console.log("test",vm.dpr_report)
                if (vm.dpr_report.project_group_id == ""){
                    vm.errors.project_group_id = ["The project group field is required."]
                }
                if (vm.dpr_report.job_date_time == ""){
                    vm.errors.job_date_time = ["The Date field is required."]
                }
                if (vm.dpr_report.project_group_id && vm.dpr_report.job_date_time ){
                    window.open(vm.$store.getters.apiUrl+'downloadDPRProgressReport?project_group_id='+vm.dpr_report.project_group_id+'&job_date_time='+vm.dpr_report.job_date_time+'&to_date='+vm.dpr_report.job_date_time);
                }
            }
        },
    };
</script>