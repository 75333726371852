<template>
	<div class="">
		<div class="d-flex justify-content-between">
			<h2 class="main-title mb-3">Belt Conveyor</h2>
			<router-link to="/belt_conveyors" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-list-check"></i>
				BELT CONVEYORS</router-link>
		</div>
		<form @submit.prevent="submitForm">
			<div class="card">
				<div class="card-header">
					<strong v-if="status">New Belt Conveyor</strong>
					<strong v-else>Update Belt Conveyor</strong>
				</div>
				<div class="card-body mb-3">
					<div class="row g-3">
						<div class="col-md-4">
							<label class="form-label">Conveyor</label><span class="text-danger"> *</span>
							<input type="text" placeholder="Conveyor" class="form-control" :class="{ 'is-invalid': errors.conveyor }"
								v-model="conveyor.conveyor" ref="conveyor" />
							<span v-if="errors.conveyor" class="invalid-feedback">{{ errors.conveyor[0] }}</span>
						</div>
						<div class="col-md-4">
							<label class="form-label">Civil Drawing</label>
							<input type="text" placeholder="Civil Drawing" class="form-control"
								:class="{ 'is-invalid': errors.civil_drawing}" v-model="conveyor.civil_drawing" />
							<span v-if="errors.civil_drawing" class="invalid-feedback">{{ errors.civil_drawing[0] }}</span>
						</div>
						<div class="col-md-4">
							<label class="form-label">Mech. Drawing</label>
							<input type="text" placeholder="Mech. Drawing" class="form-control"
								:class="{ 'is-invalid': errors.mech_drawing }" v-model="conveyor.mech_drawing" />
							<span v-if="errors.mech_drawing" class="invalid-feedback">{{ errors.mech_drawing[0] }}</span>
						</div>
						
						<div class="col-md-4">
							<label class="form-label">No. of Foundation</label>
							<input type="text" placeholder="No. of Foundation" class="form-control"
								:class="{ 'is-invalid': errors.no_of_foundation }" v-model="conveyor.no_of_foundation" />
							<span v-if="errors.no_of_foundation" class="invalid-feedback">{{ errors.no_of_foundation[0] }}</span>
						</div>
                        <div class="col-md-4">
							<label class="form-label">Foundation Casting Done</label>
							<input type="text" placeholder="Foundation Casting Done" class="form-control"
								:class="{ 'is-invalid': errors.foundation_casting_done }" v-model="conveyor.foundation_casting_done" />
							<span v-if="errors.foundation_casting_done" class="invalid-feedback">{{ errors.foundation_casting_done[0] }}</span>
						</div>
                        <div class="col-md-4">
							<label class="form-label">No. of Trestle</label>
							<input type="text" placeholder="No. of Trestle" class="form-control"
								:class="{ 'is-invalid': errors.no_of_trestle }" v-model="conveyor.no_of_trestle" />
							<span v-if="errors.no_of_trestle" class="invalid-feedback">{{ errors.no_of_trestle[0] }}</span>
						</div>
                        <div class="col-md-4">
							<label class="form-label">Trestle Fab. Done</label>
							<input type="text" placeholder="Trestle Fab. Done" class="form-control"
								:class="{ 'is-invalid': errors.trestle_fab_done }" v-model="conveyor.trestle_fab_done" />
							<span v-if="errors.trestle_fab_done" class="invalid-feedback">{{ errors.trestle_fab_done[0] }}</span>
						</div>
                        <div class="col-md-4">
							<label class="form-label">Trestle Erect. Done</label>
							<input type="text" placeholder="Trestle Erect. Done" class="form-control"
								:class="{ 'is-invalid': errors.trestle_erect_done }" v-model="conveyor.trestle_erect_done" />
							<span v-if="errors.trestle_erect_done" class="invalid-feedback">{{ errors.trestle_erect_done[0] }}</span>
						</div>

                        <div class="col-md-4">
							<label class="form-label">No. of Gallery</label>
							<input type="text" placeholder="No. of Gallery" class="form-control"
								:class="{ 'is-invalid': errors.no_of_gallery }" v-model="conveyor.no_of_gallery" />
							<span v-if="errors.no_of_gallery" class="invalid-feedback">{{ errors.no_of_gallery[0] }}</span>
						</div>
                        <div class="col-md-4">
							<label class="form-label">Gallery Fab. Done</label>
							<input type="text" placeholder="Gallery Fab. Done" class="form-control"
								:class="{ 'is-invalid': errors.gallery_fab_done }" v-model="conveyor.gallery_fab_done" />
							<span v-if="errors.gallery_fab_done" class="invalid-feedback">{{ errors.gallery_fab_done[0] }}</span>
						</div>
                        <div class="col-md-4">
							<label class="form-label">Gallery Erec. Done</label>
							<input type="text" placeholder="Gallery Erec. Done" class="form-control"
								:class="{ 'is-invalid': errors.gallery_erec_done }" v-model="conveyor.gallery_erec_done" />
							<span v-if="errors.gallery_erec_done" class="invalid-feedback">{{ errors.gallery_erec_done[0] }}</span>
						</div>
						

					</div>
				</div>
				<div class="card-footer text-end">
					<button type="reset" class="btn btn-danger me-2" @click.prevent="discard()"><i
							class="ri-close-line icon_hgt"></i> DISCARD</button>
					<button v-if="status" type="submit" class="btn btn-primary"><i class="ri-save-line icon_hgt"></i>
						SUBMIT</button>
					<button v-else type="submit" class="btn btn-primary"><i class="ri-save-line icon_hgt"></i>
						UPDATE</button>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
export default {
	name: "BeltConveyors.Create",
	components: {},
	data() {
		return {
			status: true,
			conveyor: {
				belt_conveyor_id: "",
				conveyor: "",
				civil_drawing: "",
				mech_drawing: "",
				no_of_foundation: "",
				foundation_casting_done: "",
				no_of_trestle: "",
				trestle_fab_done: "",
				trestle_erect_done: "",
				no_of_gallery:"",
                gallery_fab_done: "",
                gallery_erec_done: "",
			},
			conveyors: [],
            errors: [],
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (to.name == "BeltConveyors.Create") {
				vm.$refs.conveyor.focus();
			} else {
				vm.status = false;
				let uri = { uri: "getBeltConveyor", data: { belt_conveyor_id: to.params.belt_conveyor_id } };
				vm.$store
					.dispatch("post", uri)
					.then(function (response) {
						vm.conveyor = response.data.data;
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		});
	},
	// watch: {
	// 	'user.role_id': function () {
	// 		if (this.user.role_id) {
	// 			this.getRole()
	// 		}
	// 	},
	// },
	methods: {
		submitForm() {
			let vm = this;
			if (vm.status) {
				vm.create();
			} else {
				vm.update();
			}
		},
		create() {
			let vm = this;
			let loader = vm.$loading.show();
			let uri = { uri: "addBeltConveyor", data: vm.conveyor };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Conveyor is successfully created");
					vm.$router.push("/belt_conveyors");
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		update() {
			let vm = this;
			let loader = vm.$loading.show();
			let uri = { uri: "updateBeltConveyor", data: vm.conveyor };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Belt Conveyor is successfully updated");
					vm.$router.push("/belt_conveyors");
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		discard() {
			let vm = this;
			vm.conveyor.conveyor = "";
			vm.conveyor.civil_drawing = "";
			vm.conveyor.mech_drawing = "";
			vm.conveyor.no_of_foundation = "";
			vm.conveyor.foundation_casting_done = "";
			vm.conveyor.no_of_trestle = "";
			vm.conveyor.trestle_fab_done = "";
            vm.conveyor.trestle_erect_done = "";
            vm.conveyor.no_of_gallery = "";
            vm.conveyor.gallery_fab_done = "";
            vm.conveyor.gallery_erec_done = "";
			vm.$refs.conveyor.focus();
			vm.errors = [];
			vm.status = true;
		},
	
	},
};
</script>