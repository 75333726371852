<template>
    <div class="">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Electrical (Detail)</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                         <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="engineering.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Projects</label>
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="engineering.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @input="project => engineering.project_id = project"
                        >
                        </search>
                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">From Date</label>
                        <div class="input-group date" id="from_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.from_date }" v-model="engineering.from_date" />
                        </div>
                        <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">To Date</label>
                        <div class="input-group date" id="to_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.to_date }" v-model="engineering.to_date" />
                        </div>
                        <span v-if="errors.to_date" style="margin-top: 0.25rem;font-size: 0.875em;color: #dc3545;">{{ errors.to_date[0] }}</span>
                    </div>
                    <div class="col-md-12 mt-1">
                        <div style="float:right">
                        <button class="btn btn-sm btn-primary me-2" @click="getElectricalDetail()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-warning me-2" @click="downloadEngineeringDetail()"><i class="ri-download-line icon_hgt"></i> Download </button>
                        <button class="btn btn-sm btn-info " @click="sendEmailEngineeringDetail()"><i class="ri-mail-send-line icon_hgt"></i> Send Email </button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive table-responsive-sm" style="max-height: 392px; overflow-y: auto; ">
                         <table class="table table-sm text-wrap table-bordered mb-0">
                                <thead class="bg-primary" style="background-color: #fff !important;">
                                    <tr class="text-center" style="vertical-align: middle;">
                                        <th rowspan="2">Sl.No</th>
                                        <th rowspan="2">Area Description</th>
                                        <th rowspan="2" colspan="7">Cable Tray Erection</th>
                                        <th colspan="21">Cable Laying</th>
                                    </tr>
                                    <tr class="text-center" style="vertical-align: middle;">                                        
                                        <th colspan="7">HT Cable</th>
                                        <th colspan="7">LT Cable</th>
                                        <th colspan="7">Control Cable</th>
                                    </tr>                                    
                                </thead>                               
                                <tbody v-for="project_activity, key in project_activity_group.project_section_groups" :key="key">
                                    <tr class="bg-primary" style="background-color: #fff !important;">
                                        <th style="background-color: white;"></th>
                                        <th class="text-center" style="font-size: 18px; font-weight: 600; background-color: white;">
                                            {{ project_activity.section_group.section_group_name }}
                                        </th>
                                        <th class="text-center" v-if="key==0">Scope</th>
                                        <th class="text-center" v-if="key==0">UOM	</th>
                                        <th class="text-center" v-if="key==0">Target FTM</th>
                                        <th class="text-center" v-if="key==0">FTD	</th>
                                        <th class="text-center" v-if="key==0">MTD	</th>
                                        <th class="text-center" v-if="key==0">CTD	</th>
                                        <th class="text-center" v-if="key==0">% completed</th>
                                        <th class="text-center" v-if="key==0">Scope</th>
                                        <th class="text-center" v-if="key==0">UOM	</th>
                                        <th class="text-center" v-if="key==0">Target FTM</th>
                                        <th class="text-center" v-if="key==0">FTD	</th>
                                        <th class="text-center" v-if="key==0">MTD	</th>
                                        <th class="text-center" v-if="key==0">CTD	</th>
                                        <th class="text-center" v-if="key==0">% completed</th>
                                        <th class="text-center" v-if="key==0">Scope</th>
                                        <th class="text-center" v-if="key==0">UOM	</th>
                                        <th class="text-center" v-if="key==0">Target FTM</th>
                                        <th class="text-center" v-if="key==0">FTD	</th>
                                        <th class="text-center" v-if="key==0">MTD	</th>
                                        <th class="text-center" v-if="key==0">CTD	</th>
                                        <th class="text-center" v-if="key==0">% completed</th>
                                        <th class="text-center" v-if="key==0">Scope</th>
                                        <th class="text-center" v-if="key==0">UOM	</th>
                                        <th class="text-center" v-if="key==0">Month Target</th>
                                        <th class="text-center" v-if="key==0">FTD	</th>
                                        <th class="text-center" v-if="key==0">MTD	</th>
                                        <th class="text-center" v-if="key==0">CTD	</th>
                                        <th class="text-center" v-if="key==0">% completed</th>
						            </tr>
                                    <template v-for="project_sections, key1 in project_activity.project_sections" :key="key1">
                                        <tr v-if="eliminateZeros(project_sections)">                                    
                                            <td class="text-center">{{ key1 + 1 }}</td>
                                            <td style="white-space: nowrap;">{{ project_sections.section.section_name }}</td>
                                            <td class="text-center">{{ project_sections.cable_tray_erection.scope }}</td>
                                            <td class="text-center">{{ project_sections.cable_tray_erection.uom }}</td>
                                            <td class="text-center">{{ project_sections.cable_tray_erection.target_ftm }}</td>
                                            <td class="text-center">{{ project_sections.cable_tray_erection.ftd }}</td>
                                            <td class="text-center">{{ project_sections.cable_tray_erection.mtd }}</td>
                                            <td class="text-center">{{ project_sections.cable_tray_erection.ctd_act }}</td>
                                            <td class="text-center">{{ project_sections.cable_tray_erection.percentage_complete_act }}</td>



                                            <td class="text-center">{{ project_sections.ht_cable.scope }}</td>
                                            <td class="text-center">{{ project_sections.ht_cable.uom }}</td>
                                            <td class="text-center">{{ project_sections.ht_cable.target_ftm }}</td>
                                            <td class="text-center">{{ project_sections.ht_cable.ftd }}</td>
                                            <td class="text-center">{{ project_sections.ht_cable.mtd }}</td>
                                            <td class="text-center">{{ project_sections.ht_cable.ctd_act }}</td>
                                            <td class="text-center">{{ project_sections.ht_cable.percentage_complete_act }}</td>

                                            <td class="text-center">{{ project_sections.lt_cable.scope }}</td>
                                            <td class="text-center">{{ project_sections.lt_cable.uom }}</td>
                                            <td class="text-center">{{ project_sections.lt_cable.target_ftm }}</td>
                                            <td class="text-center">{{ project_sections.lt_cable.ftd }}</td>
                                            <td class="text-center">{{ project_sections.lt_cable.mtd }}</td>
                                            <td class="text-center">{{ project_sections.lt_cable.ctd_act }}</td>
                                            <td class="text-center">{{ project_sections.lt_cable.percentage_complete_act }}</td>

                                            <td class="text-center">{{ project_sections.control_cable.scope }}</td>
                                            <td class="text-center">{{ project_sections.control_cable.uom }}</td>
                                            <td class="text-center">{{ project_sections.control_cable.target_ftm }}</td>
                                            <td class="text-center">{{ project_sections.control_cable.ftd }}</td>
                                            <td class="text-center">{{ project_sections.control_cable.mtd }}</td>
                                            <td class="text-center">{{ project_sections.control_cable.ctd_act }}</td>
                                            <td class="text-center">{{ project_sections.control_cable.percentage_complete_act }}</td>
                                        </tr> 
                                    </template>                                                                       
                                </tbody>
                        </table> 
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    let Search = require("@/components/Search.vue").default;
    export default {
        components: {Search },
        data() {
            return {   
                initial:true,             
                projects: [],
                errors: [],
                engineering:{
                    project_id:'',
                    project_group_id:'',
                    from_date:'',
                    to_date:'',
                    isDownload:false
                },
                projectgroups:[],
                project_activity_group:[]
            };
        },
        mounted() {  
            if(this.$store.getters?.user){
                this.getProjectGroups()
            }          
            this.engineering.to_date = moment().format('yyyy-MM-DD')
        },
        methods: {
            eliminateZeros(project_sections){
                if(project_sections.cable_tray_erection.ctd_act || project_sections.ht_cable.ctd_act || project_sections.lt_cable.ctd_act || project_sections.control_cable.ctd_act){
                    return project_sections
                }else{
                    return false
                }
            },
            sendEmailEngineeringDetail(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "downloadElectricalDetailReport", data: vm.engineering})
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Electrical summary - Mail sent successfully");
                    })
                    .catch(function (error) {
                        loader.hide()
                        if(error.response.data.length){
                            console.log(error.response.data[1])
                            vm.$store.dispatch("error", error.response.data[1]);
                        }
                    });
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.engineering.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.engineering.project_group_id) {
                                vm.engineering.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getElectricalDetail(){
                let vm = this;
                localStorage.setItem('project_group_id', vm.engineering.project_group_id)
                localStorage.setItem('project_id', vm.engineering.project_id)
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "getElectricalDetail", data: vm.engineering })
                    .then(function (response) {
                        loader.hide();
                        vm.project_activity_group = response.data.data                        
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            downloadEngineeringDetail(){
                let vm = this;
                vm.errors = [];
                if (vm.engineering.project_id == ""){
                    vm.engineering.project_id = ["The project field is required."]
                }
                if (vm.engineering.to_date == ""){
                    vm.engineering.to_date = ["The To Date field is required."]
                }
                if (vm.engineering.project_id && vm.engineering.to_date){
                    window.open(vm.$store.getters.apiUrl+'downloadElectricalDetailReport?project_id='+vm.engineering.project_id+'&to_date='+vm.engineering.to_date+'&from_date='+vm.engineering.from_date+'&isDownload=true');
                }
            },

            getProjects(){
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects", data: {project_group_id: vm.engineering.project_group_id} })
                    .then(function (response) {
                        vm.projects = response.data;  
                        if(localStorage.getItem('project_id')){
                            vm.engineering.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.engineering.project_id = vm.projects[0].project_id;
                        }
                        if(vm.initial){
                            vm.getElectricalDetail();
                            vm.initial = false
                        }                      
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>

<style scoped>
    table {
        border-collapse: collapse;
        /* color: lightgrey;         */
    }

    thead {  
        th {
            position: -webkit-sticky;
            position: sticky;
            background-color: white !important;
            outline: 1px solid #e2e5ec;
            outline-offset: -1px ;
            top: 0; left: 0;                       
            &:first-child {
                z-index: 3;
                
            }
            
        }
        th:nth-child(2) {
            position: -webkit-sticky;
            position: sticky;
            background-color: white !important;
            top: 0; 
            left: 35px;                       
            z-index: 3;
            outline: 1px solid #e2e5ec;
            outline-offset: -1px ;
        }
        tr:nth-child(2){
            position: sticky;
            top: 29px;
            /* outline: 1px solid #e2e5ec; */
            outline-offset: -1px ;
        }
        
    }
    th, td {
        &:first-child {
            position: -webkit-sticky;
            position: sticky;
            left: 0px;
            top: 0; 
            z-index: 2;
            /* width: 200px; */
            background-color: white !important;
            outline: 1px solid #e2e5ec;
                outline-offset: -1px ;
        }
    }
    th:nth-child(2), td:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 35px;
        top: 0; 
        z-index: 2;
        width: 200px;
        background-color: white !important;
        outline: 1px solid #e2e5ec;
                outline-offset: -1px ;
    }
    tbody tr:first-child {
        position: sticky;
        top:55px;
        background: #fff !important;
        outline: 1px solid #e2e5ec;
        outline-offset: -1px ;
    }
</style>