import { createStore } from "vuex";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import axios from "axios";
export default createStore({
	state: {
		apiUrl: "https://pmt.akxatech.com/",
		// apiUrl: "http://localhost:8000/",
		authenticated: false,
		user: null,
		token: "",
		parameter_id: '',
		param: '',
		project_types: '',
		activity_groups: '',
		section_groups: '',
		parameter_types: '',
		sections: '',
		activities: '',
		units: '',
		parameters: '',
		item: '',
		cost_groups: '',
		cost_sub_groups: '',
		cost_heads: '',
		cost_sub_heads: '',
		cost_items: '',
		cost_sub_items: '',
		cost_types: '',
		unit_of_measurements: '',
		project_categories: '',
		project_groups: '',
		plants: '',
		template_item: '',
		permissions: [],
		selected_item: {},
		job_status: false,
		project_request_meta: null,
	},
	mutations: {
		setselectedItem(state, selected_item) {
			state.selected_item = selected_item;
		},
		setTemplateItem(state, template_item) {
			state.template_item = template_item;
		},
		setProjectCategories(state, project_categories) {
			state.project_categories = project_categories;
		},
		setProjectGroups(state, project_groups) {
			state.project_groups = project_groups;
		},
		setPlants(state, plants) {
			state.plants = plants;
		},
		setCostGroups(state, cost_groups) {
			state.cost_groups = cost_groups;
		},
		setCostSubGroups(state, cost_sub_groups) {
			state.cost_sub_groups = cost_sub_groups;
		},
		setCostHeads(state, cost_heads) {
			state.cost_heads = cost_heads;
		},
		setCostSubHeads(state, cost_sub_heads) {
			state.cost_sub_heads = cost_sub_heads;
		},
		setCostItems(state, cost_items) {
			state.cost_items = cost_items;
		},
		setCostSubItems(state, cost_sub_items) {
			state.cost_sub_items = cost_sub_items;
		},
		setCostTypes(state, cost_types) {
			state.cost_types = cost_types;
		},
		setUnitOfMeasurements(state, unit_of_measurements) {
			state.unit_of_measurements = unit_of_measurements;
		},
		setItem(state, item) {
			state.item = item;
		},
		setProjectTypes(state, project_types) {
			state.project_types = project_types;
		},
		setActivityGroups(state, activity_groups) {
			state.activity_groups = activity_groups;
		},
		setSectionGroups(state, section_groups) {
			state.section_groups = section_groups;
		},
		setParameterTypes(state, parameter_types) {
			state.parameter_types = parameter_types;
		},
		setSections(state, sections) {
			state.sections = sections;
		},
		setActivities(state, activities) {
			state.activities = activities;
		},
		setUnits(state, units) {
			state.units = units;
		},
		setParameters(state, parameters) {
			state.parameters = parameters;
		},
		setParam(state, param) {
			state.param = param;
		},
		setParameterId(state, parameter_id) {
			state.parameter_id = parameter_id;
		},
		setUser(state, user) {
			state.user = user;
		},
		setToken(state, token) {
			state.token = token;
		},
		setUrl(state, url) {
			state.apiUrl = url;
		},
		setPermissions(state, permissions) {
			state.permissions = permissions;
		},
		setJobStatus(state, job_status) {
			state.job_status = job_status;
		},
		setProjectRequestMeta(state, project_request_meta) {
			state.project_request_meta = project_request_meta;
		},
	},

	getters: {
		selected_item(state) {
			return state.selected_item;
		},
		template_item(state) {
			return state.template_item;
		},
		project_categories(state) {
			return state.project_categories;
		},
		project_groups(state) {
			return state.project_groups;
		},
		plants(state) {
			return state.plants;
		},

		cost_groups(state) {
			return state.cost_groups;
		},
		cost_sub_groups(state) {
			return state.cost_sub_groups;
		},
		cost_heads(state) {
			return state.cost_heads;
		},
		cost_sub_heads(state) {
			return state.cost_sub_heads;
		},
		cost_items(state) {
			return state.cost_items;
		},
		cost_sub_items(state) {
			return state.cost_sub_items;
		},
		cost_types(state) {
			return state.cost_types;
		},
		unit_of_measurements(state) {
			return state.unit_of_measurements;
		},

		item(state) {
			return state.item;
		},
		project_types(state) {
			return state.project_types;
		},
		activity_groups(state) {
			return state.activity_groups;
		},
		section_groups(state) {
			return state.section_groups;
		},
		parameter_types(state) {
			return state.parameter_types;
		},
		sections(state) {
			return state.sections;
		},
		activities(state) {
			return state.activities;
		},
		units(state) {
			return state.units;
		},
		parameters(state) {
			return state.parameters;
		},
		param(state) {
			return state.param;
		},
		parameter_id(state) {
			return state.parameter_id;
		},
		user(state) {
			return state.user;
		},
		token(state) {
			return state.token;
		},
		apiUrl(state) {
			return state.apiUrl;
		},
		assetUrl(state) {
			return state.assetUrl;
		},
		permissions(state) {
			return state.permissions;
		},
		job_status(state) {
			return state.job_status;
		},
		project_request_meta(state) {
			return state.project_request_meta;
		},
	},

	actions: {

		async setselectedItem(context, payload) {
			await context.commit("setselectedItem", payload);
		},
		async setTemplateItem(context, payload) {
			await context.commit("setTemplateItem", payload);
		},
		async setProjectCategories(context, payload) {
			await context.commit("setProjectCategories", payload);
		},
		async setProjectGroups(context, payload) {
			await context.commit("setProjectGroups", payload);
		},
		async setPlants(context, payload) {
			await context.commit("setPlants", payload);
		},
		async setPermissions(context, payload) {
			await context.commit('setPermissions', payload);
		},

		async setCostGroups(context, payload) {
			await context.commit("setCostGroups", payload);
		},
		async setCostSubGroups(context, payload) {
			await context.commit("setCostSubGroups", payload);
		},
		async setCostHeads(context, payload) {
			await context.commit("setCostHeads", payload);
		},
		async setCostSubHeads(context, payload) {
			await context.commit("setCostSubHeads", payload);
		},
		async setCostItems(context, payload) {
			await context.commit("setCostItems", payload);
		},
		async setCostSubItems(context, payload) {
			await context.commit("setCostSubItems", payload);
		},
		async setCostTypes(context, payload) {
			await context.commit("setCostTypes", payload);
		},
		async setUnitOfMeasurements(context, payload) {
			await context.commit("setUnitOfMeasurements", payload);
		},

		async setItem(context, payload) {
			await context.commit("setItem", payload);
		},
		async setProjectTypes(context, payload) {
			await context.commit("setProjectTypes", payload);
		},
		async setActivityGroups(context, payload) {
			await context.commit("setActivityGroups", payload);
		},
		async setSectionGroups(context, payload) {
			await context.commit("setSectionGroups", payload);
		},
		async setParameterTypes(context, payload) {
			await context.commit("setParameterTypes", payload);
		},
		async setSections(context, payload) {
			await context.commit("setSections", payload);
		},
		async setActivities(context, payload) {
			await context.commit("setActivities", payload);
		},
		async setParameters(context, payload) {
			await context.commit("setParameters", payload);
		},
		async setUnits(context, payload) {
			await context.commit("setUnits", payload);
		},
		async setParam(context, payload) {
			await context.commit("setParam", payload);
		},
		async setParameterId(context, payload) {
			await context.commit("setParameterId", payload);
		},
		async setUser(context, payload) {
			await context.commit("setUser", payload);
		},
		async setToken(context, payload) {
			await context.commit("setToken", payload);
		},
		async logout(context) {
			await context.commit("setUser", null);
			await context.commit("setToken", "");
		},
		async setJobStatus(context, payload) {
			await context.commit("setJobStatus", payload);
		},

		async setProjectRequestMeta(context, payload) {
			await context.commit("setProjectRequestMeta", payload);
		},

		auth(context, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(this.state.apiUrl + 'api/' + payload.uri, payload.data)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		get(context, payload) {
			console.log(payload)
			return new Promise((resolve, reject) => {
				axios
					.get(this.state.apiUrl + payload.uri, payload.data)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		post(context, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(this.state.apiUrl + 'api/' + payload.uri, payload.data, {
						headers: {
							Authorization: "Bearer" + " " + context.getters.token,
						},
					})
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
						if (error.response.data.message == "Unauthenticated.") {
							context.commit("setUser", null);
							window.location.href = "/#/";
							window.location.reload();
						}
					});
			});
		},

		async error(context, description) {
			await createToast(
				{
					title: "Error",
					description: description || "The given data was invalid.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "danger",
					position: "top-right",
				}
			);
		},

		async success(context, description) {
			await createToast(
				{
					title: "Success",
					description: description || "Data is successfuly subbmited.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "success",
					position: "top-right",
				}
			);
		},

		async info(context, description) {
			await createToast(
				{
					title: "Info",
					description: description || "Not enoguh input data",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "info",
					position: "top-right",
				}
			);
		},
	},
});
