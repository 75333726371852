<template>
    <div class="card">
        <div class="card-header">
            <strong>{{ project?.project_name }}-Email Recipients</strong>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <label class="form-label">Email Recipients <span class="text-danger"> *</span></label>
                    <input type="text" class="form-control form-control-sm" @keypress.enter="submitForm()" :class="{ 'is-invalid': errors.email_recipients }" v-model="meta.email_recipients" placeholder="Email">
                    <span v-if="errors.email_recipients"><small class="text-danger">{{ errors.email_recipients[0] }}</small></span>
                </div>
                <div class="col-md-3 mt-4">
                    <span v-if="!edit_status">
                        <button class="btn btn-sm btn-outline-primary mx-1" @click.prevent="addProjectEmail()"><i class="ri-add-line"></i></button>
                        <button class="btn btn-sm btn-outline-danger mx-1" @click.prevent="discard()"><i class="ri-close-line"></i></button>
                    </span>
                    <span v-else>
                        <button class="btn btn-sm btn-outline-success mx-1" @click.prevent="updateProjectEmail()"><i class="ri-refresh-line"></i></button>
                        <button class="btn btn-sm btn-outline-danger mx-1" @click.prevent="discard()"><i class="ri-close-line"></i></button>
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-12">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-0">
                            <thead>
                                <th>Email Recipients</th>
                                <th class="text-center">Action</th>
                            </thead>
                            <tbody>
                                <tr v-for="email, key in email_recipients" :key=key>
                                    <td>{{ email.email_recipients }}</td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-outline-warning mx-1" @click.prevent="editEmail(email)"><i class="ri-pencil-line"></i></button>
                                        <button class="btn btn-sm btn-outline-danger mx-1" @click.prevent="deleteProjectEmail(email)"><i class="ri-delete-bin-6-line"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "EmailRecipients",
        props: {
            project: Object,
        },
        data(){
            return{
                email_recipients:'',
                meta:{
                    project_id:'',
                    project_email_id:'',
                    email_recipients:''
                },
                edit_status:false,
                errors:[]
            }
        },
        mounted(){
            this.getProjectEmails()
        },

        methods:{
            submitForm(){
                if(this.edit_status){
                    this.updateProjectEmail()
                }else{
                    this.addProjectEmail()
                }
            },
            addProjectEmail(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.meta.project_id = this.project.project_id
                let uri = { uri: "addProjectEmail", data: vm.meta };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", 'Email added successfully');
                        vm.getProjectEmails()
                        vm.discard()
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            editEmail(email){
                this.meta.email_recipients = email.email_recipients
                this.meta.project_email_id = email.project_email_id
                this.edit_status = true
            },
            updateProjectEmail(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.meta.project_id = this.project.project_id
                let uri = { uri: "updateProjectEmail", data: vm.meta };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", 'Email added successfully');
                        vm.getProjectEmails()
                        vm.discard()
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard(){
                this.meta.email_recipients = ''
                this.edit_status = false
                this.errors = []          
            },
            deleteProjectEmail(email){
                if(confirm('Are you sure you want to delete?')){
                    let vm = this;
                    let loader = vm.$loading.show();
                    vm.meta.project_id = this.project.project_id
                    let uri = { uri: "deleteProjectEmail", data: email };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            loader.hide();
                            vm.$store.dispatch("success", 'Email added successfully');
                            vm.getProjectEmails()
                            vm.meta.email_recipients = ''
                            vm.errors = []
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            // updateEmail(){
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.meta.project_id = this.project.project_id
            //     let uri = { uri: "updateEmailRecipients", data: vm.meta };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             loader.hide();
            //             vm.getEmailRecipients()
            //             vm.$store.dispatch("success", 'Email recipients updated successfully');
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             vm.errors = error.response.data.errors
            //             vm.$store.dispatch("error", error.response.data.message);
            //         });
            // },
            // deleteEmail(){
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.meta.project_id = this.project.project_id
            //     let uri = { uri: "updateEmailRecipients", data: vm.meta };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             loader.hide();
            //             vm.getEmailRecipients()
            //             vm.$store.dispatch("success", 'Email recipients updated successfully');
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             vm.errors = error.response.data.errors
            //             vm.$store.dispatch("error", error.response.data.message);
            //         });
            // },
            // editEmail(email){
            //     this.emial = email
            // },
            // updateEmailRecipients(){
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.meta.project_id = this.project.project_id
            //     let uri = { uri: "updateEmailRecipients", data: vm.meta };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             loader.hide();
            //             vm.getEmailRecipients()
            //             vm.$store.dispatch("success", 'Email recipients updated successfully');
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             vm.errors = error.response.data.errors
            //             vm.$store.dispatch("error", error.response.data.message);
            //         });
            // },
            getProjectEmails(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.meta.project_id = this.project.project_id
                let uri = { uri: "getProjectEmails", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.email_recipients = response.data.data
                    })
                    .catch(function (error) {
                        loader.hide();
                    });
            }
        }
    }
</script>
<style scoped>
.delete_btn{
    font-size:12pt;color:red;border:1px solid #a4a4a4;padding:2px 2px 1px 2px;margin-right:10px;border-radius: 20%
}
.delete_btn:hover{
    font-size:12pt;color:white;background-color: red;border:1px solid #a4a4a4;padding:2px 2px 1px 2px;margin-right:10px;border-radius: 20%
}
.edit_btn{
    font-size:12pt;color:orange;border:1px solid #a4a4a4;padding:2px 2px 1px 2px;margin-right:10px;border-radius: 20%
}
.edit_btn:hover{
    font-size:12pt;color:white;background-color: orange; border:1px solid #a4a4a4;padding:2px 2px 1px 2px;margin-right:10px;border-radius: 20%
}
</style>