<template>
    <div class="">
        <h2 class="main-title mb-3">Functional Roles</h2>
        <div class="row g-3">
            <div class="col-md-4">
                <form @submit.prevent="submitForm()">
                    <div class="card">
                        <div class="card-header">
                            <strong v-if="status">New Functional Role</strong>
                            <strong v-else>Update Functional Role</strong>
                        </div>
                        <div class="card-body mb-3">
                            <div class="row g-3">
                                <div class="col-sm-12">
                                    <label class="form-label">Functional Role Code</label><span class="text-danger"> *</span>
                                    <input type="text" placeholder="Functional Role Code" class="form-control" :class="{'is-invalid': errors?.functional_role_code}" v-model="functional_role.functional_role_code" ref="functional_role_code" />
                                    <span v-if="errors?.functional_role_code" class="invalid-feedback">{{ errors?.functional_role_code[0] }}</span>
                                </div>
                                <div class="col-sm-12">
                                    <label class="form-label">Functional Role</label><span class="text-danger"> *</span>
                                    <input type="text" placeholder="Functional Role" class="form-control" :class="{'is-invalid': errors?.functional_role_name}" v-model="functional_role.functional_role_name" />
                                    <span v-if="errors?.functional_role_name" class="invalid-feedback">{{ errors?.functional_role_name[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-end">
                            <button type="reset" class="btn btn-danger me-2" @click="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                            <button type="submit" class="btn btn-primary" v-if="status"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
                            <button type="submit" class="btn btn-primary" v-else><i class="ri-save-line icon_hgt"></i> UPDATE</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">
                        <strong>Functional Roles</strong> 
                    </div>
                    <div class="card-body">
                        <div class="form-group mb-2 d-flex">
                            <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search()" />
                            <a @click="deleteValues()" v-if="delete_values!= 0" href="javascript:void(0)" class="btn btn-sm btn-outline-danger ms-2 "><i class="ri-delete-bin-line icon_hgt"></i></a>
                        </div>
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th @click="sort('functional_role_code')">
                                            Functional Role Code
                                            <span>
                                                <i v-if="meta.keyword=='functional_role_code' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword=='functional_role_code' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th @click="sort('functional_role_name')">
                                            Functional Role
                                            <span>
                                                <i v-if="meta.keyword=='functional_role_name' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword=='functional_role_name' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <!-- <th class="text-center">Status</th> -->
                                        <th class="text-center">Actions</th>
                                        <th class="text-center"><input type="checkbox" :checked="isAllSelected" @change="selectAll"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="functional_roles.length==0">
                                        <td colspan="6" class="text-center">No records found</td>
                                    </tr>
                                    <tr v-for="(functional_role,key) in functional_roles" :key="key">
                                        <td class="text-center">{{meta.from+key}}</td>
                                        <td>{{functional_role.functional_role_code}}</td>
                                        <td>{{functional_role.functional_role_name}}</td>
                                        <!-- <td class="text-center">
                                            <div class="form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" :id="'functional_role' + functional_role.functional_role_id" :checked="functional_role.status" :value="functional_role.status" @change="deleteFunctionalRole(functional_role)"/>
                                                <label class="custom-control-label" :for="'functional_role' + functional_role.functional_role_id"></label>
                                            </div>
                                        </td> -->
                                        <td class="text-center">
                                            <nav>
                                                <a href="javascript:void(0)" class="text-success me-2" @click.prevent="edit(functional_role)" :disabled="!functional_role.status"><i class="ri-pencil-line icon_hgt"></i></a>
                                                <a href="javascript:void(0)" class="text-danger" @click.prevent="trash(functional_role)"><i class="ri-delete-bin-line icon_hgt"></i></a>
                                            </nav>
                                        </td>
                                        <td class="text-center"><input type="checkbox"  v-model="delete_values" :value="functional_role.functional_role_id" @change="select" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                            </select>
                            <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>

                            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "functional_role_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                isAllSelected: false,
                delete_values: [],
                status: true,
                functional_roles: [],
                errors: [],
                functional_role: {
                    functional_role_id: "",
                    functional_role_code: "",
                    functional_role_name: "",
                },
            };
        },
        mounted() {
            if(this.$store.getters?.user){
                this.index();
            }
            
        },
        methods: {
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateFunctionalRoles", data: vm.meta })
                    .then(function (response) {
                        vm.functional_roles = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.addFunctionalRole();
                } else {
                    vm.updateFunctionalRole();
                }
            },

            addFunctionalRole() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addFunctionalRole", data: vm.functional_role })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Functional Role Added successfully");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            edit(functional_role) {
                let vm = this;
                vm.$refs.functional_role_code.focus();
                vm.errors = [];
                vm.status = false;
                vm.functional_role = functional_role;
            },

            updateFunctionalRole() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateFunctionalRole", data: vm.functional_role };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Functional role is successfully updated");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discard() {
                let vm = this;
                vm.functional_role.functional_role_code = "";
                vm.functional_role.functional_role_name = "";
                vm.$refs.functional_role_code.focus();
                vm.errors = [];
                vm.status = true;
                vm.index();
            },

            deleteFunctionalRole(functional_role) {
                let vm = this;
                let loader = vm.$loading.show();
                functional_role.status = functional_role.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deleteFunctionalRole", data: functional_role })
                    .then(function (response) {
                        loader.hide()
                        vm.$store.dispatch("success", response.data.message);
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            confirmDelete(functional_role) {
                this.confirm.functional_role_name = functional_role.functional_role_name;
                this.confirm.functional_role_id = functional_role.functional_role_id;
            },

            // trash(functional_role) {
            //     let vm = this;
            //     if (confirm("Do you want to delete this entry ?")) {
            //         let loader = vm.$loading.show();
            //         vm.$store
            //             .dispatch("post", { uri: "forceDeleteFunctionalRole", data: functional_role })
            //             .then(function () {
            //                 loader.hide();
            //                 vm.$store.dispatch("success", "Role Group is successfully deleted.");
            //                 vm.discard();   
            //             })
            //             .catch(function (error) {
            //                 loader.hide();
            //                 vm.errors = error.response.data.errors;
            //                 vm.$store.dispatch("error", error.response.data.message);
            //             });
            //     }
            // },
            // selectAll() {
            //     if (this.isAllSelected) {
            //         this.delete_values = [];
            //         this.isAllSelected = false;
            //     } else {
            //         this.delete_values = [];
            //         for (var functional_role in this.functional_roles) {
            //             this.delete_values.push(this.functional_roles[functional_role].functional_role_id);
            //         }
            //         console.log("s",this.delete_values);
            //         this.isAllSelected = true;
            //     }
            // },
            // select() {
            //     if (this.delete_values.length !== this.functional_roles.length) {
            //         this.isAllSelected = false;
            //     } else {
            //         this.isAllSelected = true;
            //     }
            // },
          
            // deleteValues() {
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.$store.dispatch("post", { uri: "multipleDeleteFunctionalRoles", data:{functional_role_id: this.delete_values}})
            //     .then(function () {
            //         loader.hide();
            //         vm.$store.dispatch("success", "Role Group is successfully deleted.");
            //         vm.discard();
            //     })
            //     .catch(function (error) {
            //         loader.hide();
            //         vm.errors = error.response.data.errors;
            //         vm.$store.dispatch("error", error.response.data.message);
            //     });
            // },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
        },
    };
</script>
<!-- ends -->