<template>
    <figure class="gaugemeter-figure">
        <div :id="container"></div>
    </figure>
    
</template>

<script>
    var Highcharts = require("highcharts");
    require("highcharts/modules/heatmap")(Highcharts);
	require("highcharts/modules/data")(Highcharts);
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/export-data")(Highcharts);
    require("highcharts/modules/accessibility")(Highcharts);
    require("highcharts/highcharts-more")(Highcharts);
    export default {
        name: "GaugeMeter",
        props: {
			container: {
                default: null,
            },
            title: {
                default: null,
            },
            max: {
				default: null,
			},
			series: {
				default: null,
			},
            height: {
				// default: '78%',
                default: '80%',
			},
            marginTop: {
                default: '30',
			},
            width: {
				default: null,
			},
            label_font_size: String, 
            plot_bands: Array, 
            actual: Array,
            plan: Array       

        },

        watch: {
			series: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
            max: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
        },

        mounted() {
            this.loadChart();
        },

        methods: {
            loadChart() {
                let vm = this;
                Highcharts.chart(vm.container, {
                    credits: {
                        enabled: false,
                    },
                        chart: {
        type: 'gauge'
    },

    title: null,

    pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
        }
    },

    exporting: {
        enabled: false
    },

    tooltip: {
        enabled: false
    },

    // the value axis
    yAxis: {
        stops: [
            [0.1, '#55BF3B'], // green
            [0.5, '#DDDF0D'], // yellow
            [0.9, '#DF5353'] // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
            y: -70
        },
        labels: {
            y: 16
        }
    },

    plotOptions: {
        solidgauge: {
            dataLabels: {
                y: 5,
                borderWidth: 0,
                useHTML: true
            }
        }
    },
    series: [{
        name: 'Speed',
        data: [80],
        dataLabels: {
            format:
                '<div style="text-align:center">' +
                '<span style="font-size:25px">{y}</span><br/>' +
                '<span style="font-size:12px;opacity:0.4">km/h</span>' +
                '</div>'
        },
        tooltip: {
            valueSuffix: ' km/h'
        }
    }]
                });
            }
        }
    };
</script>