<template>
    <div class="card">
        <div class="card-header">
            <strong>Update PMT Master Template</strong>
        </div>
        <div class="card-body">
            <div class="row g-2 g-xxl-3 mb-5">
                <div class="col-3">
                    <div class="profile-item mb-3">
                        <i class="ri-archive-drawer-line"></i>
                        <div class="profile-item-body">
                            <p>Project Type</p>
                            <span>{{ template?.project_type?.project_type_name }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="profile-item mb-3">
                        <i class="ri-stack-line"></i>
                        <div class="profile-item-body">
                            <p>Activity Group</p>
                            <span>{{ template?.activity_group?.activity_group_name }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="profile-item mb-3">
                        <i class="ri-git-repository-private-line"></i>
                        <div class="profile-item-body">
                            <p>Template Code</p>
                            <span>{{ template?.template_code }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="profile-item mb-3">
                        <i class="ri-profile-line"></i>
                        <div class="profile-item-body">
                            <p>Template Name</p>
                            <span>{{ template?.template_name }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div>
                        <template-update class="item" :item="template" @make-folder="makeFolder" @add-item="addItem"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import TemplateUpdate from "@/components/tree/TemplateUpdate.vue";
    export default {
        components: { TemplateUpdate },
        data() {
            return {
                template: {},
                edit: false,
            };
        },
        watch: {
            "$store.getters.template_item": function () {
                console.log(this.$store.getters.template_item);
                this.template = this.$store.getters.template_item;
            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                let loader = vm.$loading.show();
                let uri = { uri: "getTemplate", data: { template_id: to.params.template_id } };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        console.log('response:-----',response.data.data)
                        vm.template = response.data.data;
                        console.log(vm.template);
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            });
        },
        mounted() {},
        methods: {},
    };
</script>
