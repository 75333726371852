<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">Budget</li>
			</ol>
			<h4 class="main-title mb-0">Detail Report</h4>
		</div>
		<div class="d-flex align-items-center gap-2 mt-3 mt-md-0">
			<button @click="exportTableToExcel" class="btn btn-success d-flex align-items-center gap-2">
				<i class="ri-download-line fs-18 lh-1"></i>Download
			</button>
		</div>
	</div>
	<div class="row g-3 mb-4">
		<div class="col-sm-12">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">Report</h6>
					<nav class="nav ms-auto">
						<div class="d-flex">
							<select class="form-control form-controls-sm mx-2" v-model="currency_unit">
								<option value="1">in rupees</option>
								<option value="100000">in lakhs</option>
								<option value="10000000">in cr</option>
							</select>
						</div>
					</nav>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-sm-6">
							<select class="form-control form-controls-sm" v-model="report.project_group_id"
								@change="getProjects">
								<option value="">Select Project Group</option>
								<option v-for="project_group in project_groups" :value="project_group.project_group_id">
									{{ project_group.project_group_name }}
								</option>
							</select>
						</div>
						<div class="col-sm-6">
							<select class="form-control form-controls-sm" v-model="report.project_id"
								@change="getReportLineItems">
								<option value="">Total</option>
								<option v-for="project in projects" :value="project.project_id">
									{{ project.project_name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row mt-3" v-if="first_levels.length != 0">
						<div class="col-sm-12">
							<div class="table-responsive table-responsive-sm">
								<table id="my-table" class="table text-nowrap table-sm table-bordered mb-0">
									<thead>
										<tr>
											<th class="text-center">L</th>
											<th>Description</th>
											<th class="text-center">Approved <br> Budget</th>
											<th class="text-center">SAP <br> Commitment</th>
											<th class="text-center">Manual <br> Commitment</th>
											<th class="text-center">Total <br> Commitment</th>
											<th class="text-center">Actual <br> Spent</th>
											<th class="text-center">Balance <br>Commitment <br> in Hand</th>
											<th class="text-center">Projected <br> Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
										<template v-for="level1, i in first_levels">
											<tr class="text-primary" @click="openLevel(level1, '2')">
												<td class="text-center">1</td>
												<th :class="getSecondLevel(level1)?.length == 0 ? 'level-1' : ''">
													<div class="d-flex align-items-center gap-2">
														<i v-if="getSecondLevel(level1)?.length != 0 && meta.level1 != level1"
															class="ri-folder-add-line fs-18 lh-1"></i>
														<i v-if="getSecondLevel(level1)?.length != 0 && meta.level1 == level1"
															class="ri-folder-reduce-line fs-18 lh-1"></i>
														{{ level1 }}
													</div>
												</th>
												<td class="text-right">
													{{ formatCurrency(level1ApprovedBudget(level1)) }}
												</td>
												<td class="text-right">
													{{ formatCurrency(level1SapCommit(level1)) }}
												</td>
												<td class="text-right">
													{{ formatCurrency(level1ManualCommit(level1)) }}
												</td>
												<td class="text-right">
													{{ formatCurrency(level1TotalCommit(level1)) }}
												</td>
												<td class="text-right">
													{{ formatCurrency(level1ActualSpent(level1)) }}
												</td>
												<td
													:class="level1BalanceInHand(level1) >= 0 ? 'green-savings' : 'red-savings'">
													{{ formatCurrency(level1BalanceInHand(level1)) }}
													<i v-if="level1BalanceInHand(level1) > 0"
														class="ri-arrow-up-s-fill"></i>
													<i v-if="level1BalanceInHand(level1) < 0"
														class="ri-arrow-down-s-fill"></i>
												</td>
												<td class="text-right">
													{{ formatCurrency(level1PCommit(level1)) }}
												</td>
												<td
													:class="level1Savings(level1) >= 0 ? 'green-savings' : 'red-savings'">
													{{ formatCurrency(level1Savings(level1)) }}
													<i v-if="level1Savings(level1) > 0" class="ri-arrow-up-s-fill"></i>
													<i v-if="level1Savings(level1) < 0"
														class="ri-arrow-down-s-fill"></i>
												</td>
											</tr>
											<template v-for="level2, j in second_levels">
												<tr v-if="meta.level1 == level1" @click="openLevel(level2, '3')">
													<td class="text-center">2</td>
													<td class="level-2">
														<div class="d-flex align-items-center gap-2">
															<i v-if="getThirdLevel(level1, level2)?.length != 0 && meta.level1 == level1 && meta.level2 != level2"
																class="ri-folder-add-line fs-18 lh-1"></i>
															<i v-if="getThirdLevel(level1, level2)?.length != 0 && meta.level1 == level1 && meta.level2 == level2"
																class="ri-folder-reduce-line fs-18 lh-1"></i>
															{{ level2 }}
														</div>
													</td>
													<td class="text-right">
														{{ formatCurrency(level2ApprovedBudget(level1, level2)) }}
													</td>
													<td class="text-right">
														{{ formatCurrency(level2SapCommit(level1, level2)) }}
													</td>
													<td class="text-right">
														{{ formatCurrency(level2ManualCommit(level1, level2)) }}
													</td>
													<td class="text-right">
														{{ formatCurrency(level2TotalCommit(level1, level2)) }}
													</td>
													<td class="text-right">
														{{ formatCurrency(level2SapCommit(level1, level2)) }}
													</td>
													<td
														:class="level2BalanceInHand(level1, level2) >= 0 ? 'green-savings' : 'red-savings'">
														{{ formatCurrency(level2BalanceInHand(level1, level2)) }}
														<i v-if="level2BalanceInHand(level1, level2) > 0"
															class="ri-arrow-up-s-fill"></i>
														<i v-if="level2BalanceInHand(level1, level2) < 0"
															class="ri-arrow-down-s-fill"></i>
													</td>
													<td class="text-right">
														{{ formatCurrency(level2PCommit(level1, level2)) }}
													</td>
													<td
														:class="level2Savings(level1, level2) >= 0 ? 'green-savings' : 'red-savings'">
														{{ formatCurrency(level2Savings(level1, level2)) }}
														<i v-if="level2Savings(level1, level2) > 0"
															class="ri-arrow-up-s-fill"></i>
														<i v-if="level2Savings(level1, level2) < 0"
															class="ri-arrow-down-s-fill"></i>
													</td>
												</tr>
												<template v-for="level3, k in third_levels">
													<tr v-if="meta.level1 == level1 && meta.level2 == level2"
														@click="openLevel(level3, 4)">
														<td class="text-center">3</td>
														<td class="level-3">
															<div class="d-flex align-items-center gap-2">
																<i v-if="getFourthLevel(level1, level2, level3)?.length != 0 && meta.level1 == level1 && meta.level2 == level2 && meta.level3 != level3"
																	class="ri-folder-add-line fs-18 lh-1"></i>
																<i v-if="getFourthLevel(level1, level2, level3)?.length != 0 && meta.level1 == level1 && meta.level2 == level2 && meta.level3 == level3"
																	class="ri-folder-reduce-line fs-18 lh-1"></i>
																{{ level3 }}
															</div>
														</td>
														<td class="text-right">
															{{
																formatCurrency(level3ApprovedBudget(level1, level2, level3))
															}}
														</td>
														<td class="text-right">
															{{ formatCurrency(level3SapCommit(level1, level2, level3))
															}}
														</td>
														<td class="text-right">
															{{ formatCurrency(level3ManualCommit(level1, level2,
																level3))
															}}
														</td>
														<td class="text-right">
															{{ formatCurrency(level3TotalCommit(level1, level2, level3))
															}}
														</td>
														<td class="text-right">
															{{ formatCurrency(level3ActualSpent(level1, level2, level3))
															}}
														</td>
														<td
															:class="level3BalanceInHand(level1, level2, level3) >= 0 ? 'green-savings' : 'red-savings'">
															{{ formatCurrency(level3BalanceInHand(level1, level2,
																level3))
															}}
															<i v-if="level3BalanceInHand(level1, level2, level3) > 0"
																class="ri-arrow-up-s-fill"></i>
															<i v-if="level3BalanceInHand(level1, level2, level3) < 0"
																class="ri-arrow-down-s-fill"></i>
														</td>
														<td class="text-right">
															{{ formatCurrency(level3PCommit(level1, level2, level3)) }}
														</td>
														<td
															:class="level3Savings(level1, level2, level3) >= 0 ? 'green-savings' : 'red-savings'">
															{{ formatCurrency(level3Savings(level1, level2, level3)) }}
															<i v-if="level3Savings(level1, level2, level3) > 0"
																class="ri-arrow-up-s-fill"></i>
															<i v-if="level3Savings(level1, level2, level3) < 0"
																class="ri-arrow-down-s-fill"></i>
														</td>
													</tr>
													<template v-for="level4, l in fourth_levels">
														<tr v-if="meta.level1 == level1 && meta.level2 == level2 && meta.level3 == level3"
															@click="openLevel(level4, 5)">
															<td class="text-center">4</td>
															<td class="level-4">
																<div class="d-flex align-items-center gap-2">
																	<i v-if="getFifthLevel(level1, level2, level3, level4)?.length != 0 && meta.level1 == level1 && meta.level2 == level2 && meta.level3 == level3 && meta.level4 != level4"
																		class="ri-folder-add-line fs-18 lh-1"></i>
																	<i v-if="getFifthLevel(level1, level2, level3, level4)?.length != 0 && meta.level1 == level1 && meta.level2 == level2 && meta.level3 == level3 && meta.level4 == level4"
																		class="ri-folder-reduce-line fs-18 lh-1"></i>
																	{{ level4 }}
																</div>
															</td>
															<td class="text-right">
																{{
																	formatCurrency(level4ApprovedBudget(level1, level2,
																		level3, level4))
																}}
															</td>
															<td class="text-right">
																{{
																	formatCurrency(level4SapCommit(level1, level2,
																		level3, level4))
																}}
															</td>
															<td class="text-right">
																{{
																	formatCurrency(level4ManualCommit(level1, level2,
																		level3, level4))
																}}
															</td>
															<td class="text-right">
																{{
																	formatCurrency(level4TotalCommit(level1, level2,
																		level3, level4))
																}}
															</td>
															<td class="text-right">
																{{
																	formatCurrency(level4ActualSpent(level1, level2,
																		level3, level4))
																}}
															</td>
															<td
																:class="level4BalanceInHand(level1, level2, level3, level4) >= 0 ? 'green-savings' : 'red-savings'">
																{{
																	formatCurrency(level4BalanceInHand(level1, level2,
																		level3, level4))
																}}
																<i v-if="level4BalanceInHand(level1, level2, level3, level4) > 0"
																	class="ri-arrow-up-s-fill"></i>
																<i v-if="level4BalanceInHand(level1, level2, level3, level4) < 0"
																	class="ri-arrow-down-s-fill"></i>
															</td>
															<td class="text-right">
																{{
																	formatCurrency(level4PCommit(level1, level2,
																		level3, level4))
																}}
															</td>
															<td
																:class="level4Savings(level1, level2, level3, level4) >= 0 ? 'green-savings' : 'red-savings'">
																{{
																	formatCurrency(level4Savings(level1, level2,
																		level3, level4))
																}}
																<i v-if="level4Savings(level1, level2, level3, level4) > 0"
																	class="ri-arrow-up-s-fill"></i>
																<i v-if="level4Savings(level1, level2, level3, level4) < 0"
																	class="ri-arrow-down-s-fill"></i>
															</td>
														</tr>
														<template v-for="level5, m in fifth_levels">
															<tr v-if="meta.level1 == level1 && meta.level2 == level2 && meta.level3 == level3 && meta.level4 == level4"
																@click="openLevel(level5, 6)">
																<td class="text-center">5</td>
																<td class="level-5">
																	<div class="d-flex align-items-center gap-2">
																		<i v-if="getSixthLevel(level1, level2, level3, level4, level5)?.length != 0 && meta.level1 == level1 && meta.level2 == level2 && meta.level3 == level3 && meta.level4 == level4 && meta.level5 != level5"
																			class="ri-folder-add-line fs-18 lh-1"></i>
																		<i v-if="getSixthLevel(level1, level2, level3, level4, level5)?.length != 0 && meta.level1 == level1 && meta.level2 == level2 && meta.level3 == level3 && meta.level4 == level4 && meta.level5 == level5"
																			class="ri-folder-reduce-line fs-18 lh-1"></i>
																		{{ level5 }}
																	</div>
																</td>
																<td class="text-right">
																	{{
																		formatCurrency(level5ApprovedBudget(level1, level2,
																			level3, level4, level5))
																	}}
																</td>
																<td class="text-right">
																	{{
																		formatCurrency(level5SapCommit(level1, level2,
																			level3, level4, level5))
																	}}
																</td>
																<td class="text-right">
																	{{
																		formatCurrency(level5ManualCommit(level1, level2,
																			level3, level4, level5))
																	}}
																</td>
																<td class="text-right">
																	{{
																		formatCurrency(level5TotalCommit(level1, level2,
																			level3, level4, level5))
																	}}
																</td>
																<td class="text-right">
																	{{
																		formatCurrency(level5ActualSpent(level1, level2,
																			level3, level4, level5))
																	}}
																</td>
																<td
																	:class="level5BalanceInHand(level1, level2, level3, level4, level5) >= 0 ? 'green-savings' : 'red-savings'">
																	{{
																		formatCurrency(level5BalanceInHand(level1, level2,
																			level3, level4, level5))
																	}}
																	<i v-if="level5BalanceInHand(level1, level2, level3, level4, level5) > 0"
																		class="ri-arrow-up-s-fill"></i>
																	<i v-if="level5BalanceInHand(level1, level2, level3, level4, level5) < 0"
																		class="ri-arrow-down-s-fill"></i>
																</td>
																<td class="text-right">
																	{{
																		formatCurrency(level5PCommit(level1, level2,
																			level3, level4, level5))
																	}}
																</td>
																<td
																	:class="level5Savings(level1, level2, level3, level4, level5) >= 0 ? 'green-savings' : 'red-savings'">
																	{{
																		formatCurrency(level5Savings(level1, level2,
																			level3, level4, level5))
																	}}
																	<i v-if="level5Savings(level1, level2, level3, level4, level5) > 0"
																		class="ri-arrow-up-s-fill"></i>
																	<i v-if="level5Savings(level1, level2, level3, level4, level5) < 0"
																		class="ri-arrow-down-s-fill"></i>
																</td>
															</tr>
															<template v-for="level6 in sixth_levels">
																<tr>
																	<td class="text-center">6</td>
																	<td class="level-6">{{ level6 }}</td>
																	<td class="text-right">0</td>
																	<td class="text-right">0</td>
																	<td class="text-right">0</td>
																	<td class="text-right">0</td>
																	<td class="text-right">0</td>
																	<td class="text-right">0</td>
																	<td class="text-right">0</td>
																	<td class="text-right">0</td>
																</tr>
															</template>
														</template>
													</template>
												</template>
											</template>
										</template>
									</tbody>
									<tfoot>
										<tr>
											<th colspan="2" class="text-right">Total</th>
											<th class="text-right">{{ formatCurrency(totalFirstLevelApprovedBudget) }}
											</th>
											<th class="text-right">{{ formatCurrency(totalFirstLevelSapCommit) }}</th>
											<th class="text-right">{{ formatCurrency(totalFirstLevelManualCommit) }}
											</th>
											<th class="text-right">{{ formatCurrency(totalFirstLevelTotalCommit) }}</th>
											<th class="text-right">{{ formatCurrency(totalFirstLevelActualSpent) }}</th>
											<th
												:class="totalFirstLevelBalanceInHand >= 0 ? 'green-savings' : 'red-savings'">
												{{ formatCurrency(totalFirstLevelBalanceInHand) }}
												<i v-if="totalFirstLevelBalanceInHand > 0"
													class="ri-arrow-up-s-fill"></i>
												<i v-if="totalFirstLevelBalanceInHand < 0"
													class="ri-arrow-down-s-fill"></i>
											</th>
											<th class="text-right">{{ formatCurrency(totalFirstLevelPCommit) }}</th>
											<th :class="totalFirstLevelSavings >= 0 ? 'green-savings' : 'red-savings'">
												{{ formatCurrency(totalFirstLevelSavings) }}
												<i v-if="totalFirstLevelSavings > 0" class="ri-arrow-up-s-fill"></i>
												<i v-if="totalFirstLevelSavings < 0" class="ri-arrow-down-s-fill"></i>
											</th>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import * as XLSX from "xlsx";
export default {
	name: "Users.Create",
	components: {},
	data() {
		return {
			report: {
				project_group_id: '',
				project_id: '',
			},
			project_groups: [],
			projects: [],
			levels: [],
			first_levels: [],
			second_levels: [],
			third_levels: [],
			fourth_levels: [],
			fifth_levels: [],
			sixth_levels: [],
			last_levels: [],
			currency_unit: 10000000,
			meta: {
				level1: "",
				level2: "",
				level3: "",
				level4: "",
				level5: "",
				level6: "",
			}
		};
	},
	mounted() {
		this.getProjectGroups();
	},
	computed: {
		totalFirstLevelApprovedBudget() {
			return this.first_levels.reduce((sum, level1) => sum + this.level1ApprovedBudget(level1), 0);
		},
		totalFirstLevelSapCommit() {
			return this.first_levels.reduce((sum, level1) => sum + this.level1SapCommit(level1), 0);
		},
		totalFirstLevelManualCommit() {
			return this.first_levels.reduce((sum, level1) => sum + this.level1ManualCommit(level1), 0);
		},
		totalFirstLevelTotalCommit() {
			return this.first_levels.reduce((sum, level1) => sum + this.level1TotalCommit(level1), 0);
		},
		totalFirstLevelActualSpent() {
			return this.first_levels.reduce((sum, level1) => sum + this.level1ActualSpent(level1), 0);
		},
		totalFirstLevelBalanceInHand() {
			return this.first_levels.reduce((sum, level1) => sum + this.level1BalanceInHand(level1), 0);
		},
		totalFirstLevelPCommit() {
			return this.first_levels.reduce((sum, level1) => sum + this.level1PCommit(level1), 0);
		},
		totalFirstLevelSavings() {
			return this.first_levels.reduce((sum, level1) => sum + this.level1Savings(level1), 0);
		},
	},
	methods: {
		getProjectGroups() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getProjectGroups" })
				.then(function (response) {
					vm.project_groups = response.data.data
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		getProjects() {
			let vm = this;
			vm.report.project_id = '';
			vm.projects = [];
			vm.$store.dispatch("post", { uri: "getProjectGroupActiveProjects", data: vm.report })
				.then(function (response) {
					vm.projects = response.data;
					vm.getReportLineItems()
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		getReportLineItems() {
			let vm = this;
			let loader = this.$loading.show();
			vm.$store.dispatch("post", { uri: "budgetSummaryReport", data: vm.report })
				.then(function (response) {
					loader.hide();
					vm.levels = response.data.data;
					vm.first_levels = [...new Set(vm.levels.map(person => person.level1))]
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.error;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getSecondLevel(level1) {
			let vm = this;
			let second_levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 != null
			});
			return [...new Set(second_levels.map(person => person.level2))]
		},
		getThirdLevel(level1, level2) {
			let vm = this;
			let third_levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 != null
			});
			return [...new Set(third_levels.map(person => person.level3))]
		},
		getFourthLevel(level1, level2, level3) {
			let vm = this;
			let fourth_levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 != null
			});
			return [...new Set(fourth_levels.map(person => person.level4))]
		},
		getFifthLevel(level1, level2, level3, level4) {
			let vm = this;
			let fifth_levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4 && level.level5 != null
			});
			return [...new Set(fifth_levels.map(person => person.level5))]
		},
		getSixthLevel(level1, level2, level3, level4, level5) {
			let vm = this;
			let sixth_levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4 && level.level5 == level5 && level.level6 != null
			});
			return [...new Set(sixth_levels.map(person => person.level6))]
		},

		formatCurrency(amount) {
			let value = amount / this.currency_unit;
			return value.toLocaleString('en-IN', {
				maximumFractionDigits: 0,
				style: 'currency',
				currency: 'INR'
			});
		},

		level1ApprovedBudget(level1) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.total_budget, 0);
		},

		level1SapCommit(level1) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.sap_commit, 0);
				total = +total + +sum;
			});
			return total;
		},

		// level1ManualCommit(level1) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.manual_commit, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level1ManualCommit(level1) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.manual_commit, 0);
		},

		level1TotalCommit(level1) {
			let vm = this;
			return +vm.level1SapCommit(level1) + +vm.level1ManualCommit(level1);
		},

		level1ActualSpent(level1) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.actual_spent, 0);
				total = +total + +sum;
			});
			return total;
		},

		level1BalanceInHand(level1) {
			let vm = this;
			return +vm.level1ApprovedBudget(level1) - +vm.level1TotalCommit(level1);
		},

		// level1PCommit(level1) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.projected_commitment, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level1PCommit(level1) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.projected_commitment, 0);
		},

		level1Savings(level1) {
			let vm = this;
			return +vm.level1BalanceInHand(level1) - +vm.level1PCommit(level1);
		},

		level2ApprovedBudget(level1, level2) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.total_budget, 0);
		},

		level2SapCommit(level1, level2) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.sap_commit, 0);
				total = +total + +sum;
			});
			return total;
		},

		// level2ManualCommit(level1,level2) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1 && level.level2 == level2
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.manual_commit, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level2ManualCommit(level1, level2) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.manual_commit, 0);
		},

		level2TotalCommit(level1, level2) {
			let vm = this;
			return +vm.level2SapCommit(level1, level2) + +vm.level2ManualCommit(level1, level2);
		},

		level2ActualSpent(level1, level2) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.actual_spent, 0);
				total = +total + +sum;
			});
			return total;
		},

		level2BalanceInHand(level1, level2) {
			let vm = this;
			return +vm.level2ApprovedBudget(level1, level2) - +vm.level2TotalCommit(level1, level2);
		},

		// level2PCommit(level1,level2) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1 && level.level2 == level2
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.projected_commitment, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level2PCommit(level1, level2) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.projected_commitment, 0);
		},


		level2Savings(level1, level2) {
			let vm = this;
			return +vm.level2BalanceInHand(level1, level2) - +vm.level2PCommit(level1, level2);
		},

		level3ApprovedBudget(level1, level2, level3) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.total_budget, 0);
		},

		level3SapCommit(level1, level2, level3) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.sap_commit, 0);
				total = +total + +sum;
			});
			return total;
		},

		// level3ManualCommit(level1,level2,level3) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1 && level.level2 == level2 && level.level3 == level3
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.manual_commit, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level3ManualCommit(level1, level2, level3) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.manual_commit, 0);
		},

		level3TotalCommit(level1, level2, level3) {
			let vm = this;
			return +vm.level3SapCommit(level1, level2, level3) + +vm.level3ManualCommit(level1, level2, level3);
		},

		level3ActualSpent(level1, level2, level3) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.actual_spent, 0);
				total = +total + +sum;
			});
			return total;
		},

		level3BalanceInHand(level1, level2, level3) {
			let vm = this;
			return +vm.level3ApprovedBudget(level1, level2, level3) - +vm.level3TotalCommit(level1, level2, level3);
		},

		// level3PCommit(level1,level2,level3) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1 && level.level2 == level2 && level.level3 == level3
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.projected_commitment, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level3PCommit(level1, level2, level3) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.projected_commitment, 0);
		},

		level3Savings(level1, level2, level3) {
			let vm = this;
			return +vm.level3BalanceInHand(level1, level2, level3) - +vm.level3PCommit(level1, level2, level3);
		},

		level4ApprovedBudget(level1, level2, level3, level4) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.total_budget, 0);
		},

		level4SapCommit(level1, level2, level3, level4) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.sap_commit, 0);
				total = +total + +sum;
			});
			return total;
		},

		// level4ManualCommit(level1,level2,level3,level4) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.manual_commit, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level4ManualCommit(level1, level2, level3, level4) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.manual_commit, 0);
		},

		level4TotalCommit(level1, level2, level3, level4) {
			let vm = this;
			return +vm.level4SapCommit(level1, level2, level3, level4) + +vm.level4ManualCommit(level1, level2, level3, level4);
		},

		level4ActualSpent(level1, level2, level3, level4) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.actual_spent, 0);
				total = +total + +sum;
			});
			return total;
		},

		level4BalanceInHand(level1, level2, level3, level4) {
			let vm = this;
			return +vm.level4ApprovedBudget(level1, level2, level3, level4) - +vm.level4TotalCommit(level1, level2, level3, level4);
		},

		// level4PCommit(level1,level2,level3,level4) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.projected_commitment, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level4PCommit(level1, level2, level3, level4) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.projected_commitment, 0);
		},

		level4Savings(level1, level2, level3, level4) {
			let vm = this;
			return +vm.level4BalanceInHand(level1, level2, level3, level4) - +vm.level4PCommit(level1, level2, level3, level4);
		},

		level5ApprovedBudget(level1, level2, level3, level4, level5) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4 && level.level5 == level5
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.total_budget, 0);
		},

		level5SapCommit(level1, level2, level3, level4, level5) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4 && level.level5 == level5
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.sap_commit, 0);
				total = +total + +sum;
			});
			return total;
		},

		// level5ManualCommit(level1,level2,level3,level4,level5) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4 && level.level5 == level5
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.manual_commit, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level5ManualCommit(level1, level2, level3, level4, level5) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4 && level.level5 == level5
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.manual_commit, 0);
		},

		level5TotalCommit(level1, level2, level3, level4, level5) {
			let vm = this;
			return +vm.level5SapCommit(level1, level2, level3, level4, level5) + +vm.level5ManualCommit(level1, level2, level3, level4, level5);
		},

		level5ActualSpent(level1, level2, level3, level4, level5) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4 && level.level5 == level5
			});
			let total = 0;
			levels.forEach(level => {
				let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.actual_spent, 0);
				total = +total + +sum;
			});
			return total;
		},

		level5BalanceInHand(level1, level2, level3, level4, level5) {
			let vm = this;
			return +vm.level5ApprovedBudget(level1, level2, level3, level4, level5) - +vm.level5TotalCommit(level1, level2, level3, level4, level5);
		},

		// level5PCommit(level1,level2,level3,level4,level5) {
		//     let vm = this;
		//     let levels =  vm.levels.filter(level => {
		//         return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4 && level.level5 == level5
		//     });
		//     let total = 0;
		//     levels.forEach(level => {
		//         let sum = level?.report_line_item?.repor_line_item_levels.reduce((prev, cur) => +prev + +cur?.project_cost?.projected_commitment, 0);
		//         total = +total + +sum;
		//     });
		//     return total;
		// },

		level5PCommit(level1, level2, level3, level4, level5) {
			let vm = this;
			let levels = vm.levels.filter(level => {
				return level.level1 == level1 && level.level2 == level2 && level.level3 == level3 && level.level4 == level4 && level.level5 == level5
			});
			return levels.reduce((prev, cur) => +prev + +cur?.report_line_item?.projected_commitment, 0);
		},

		level5Savings(level1, level2, level3, level4, level5) {
			let vm = this;
			return +vm.level5BalanceInHand(level1, level2, level3, level4, level5) - +vm.level5PCommit(level1, level2, level3, level4, level5);
		},

		openLevel(level, level_no) {
			if (level_no == '2') {
				if (this.meta.level1 == "") {
					this.meta.level1 = level;
					this.second_levels = this.getSecondLevel(this.meta.level1);
				}
				else if (this.meta.level1 != "" && this.meta.level1 == level) {
					this.meta.level1 = "";
				}
				else {
					this.meta.level1 = level;
					this.second_levels = this.getSecondLevel(this.meta.level1);
				}
				this.meta.level2 = "";
				this.meta.level3 = "";
				this.meta.level4 = "";
				this.meta.level5 = "";
				this.meta.level6 = "";
			}
			if (level_no == '3') {
				if (this.meta.level2 == "") {
					this.meta.level2 = level;
					this.third_levels = this.getThirdLevel(this.meta.level1, this.meta.level2);
				}
				else if (this.meta.level2 != "" && this.meta.level2 == level) {
					this.meta.level2 = "";
				}
				else {
					this.meta.level2 = level;
					this.third_levels = this.getThirdLevel(this.meta.level1, this.meta.level2);
				}
				this.meta.level3 = "";
				this.meta.level4 = "";
				this.meta.level5 = "";
				this.meta.level6 = "";
			}
			if (level_no == '4') {
				if (this.meta.level3 == "") {
					this.meta.level3 = level;
					this.fourth_levels = this.getFourthLevel(this.meta.level1, this.meta.level2, this.meta.level3);
				}
				else if (this.meta.level3 != "" && this.meta.level3 == level) {
					this.meta.level3 = "";
				}
				else {
					this.meta.level3 = level;
					this.fourth_levels = this.getFourthLevel(this.meta.level1, this.meta.level2, this.meta.level3);
				}
				this.meta.level4 = "";
				this.meta.level5 = "";
				this.meta.level6 = "";
			}
			if (level_no == '5') {
				if (this.meta.level4 == "") {
					this.meta.level4 = level;
					this.fifth_levels = this.getFifthLevel(this.meta.level1, this.meta.level2, this.meta.level3, this.meta.level4);
				}
				else if (this.meta.level4 != "" && this.meta.level4 == level) {
					this.meta.level4 = "";
				}
				else {
					this.meta.level4 = level;
					this.fifth_levels = this.getFifthLevel(this.meta.level1, this.meta.level2, this.meta.level3, this.meta.level4);
				}
			}
			if (level_no == '6') {
				if (this.meta.level5 == "") {
					this.meta.level5 = level;
					this.sixth_levels = this.getSixthLevel(this.meta.level1, this.meta.level2, this.meta.level3, this.meta.level4, this.meta.level5);
				}
				else if (this.meta.level5 != "" && this.meta.level5 == level) {
					this.meta.level5 = "";
				}
				else {
					this.meta.level5 = level;
					this.sixth_levels = this.getSixthLevel(this.meta.level1, this.meta.level2, this.meta.level3, this.meta.level4, this.meta.level5);
				}
			}
		},
		exportTableToExcel() {
			// Select the table element
			const table = document.getElementById("my-table"); // Replace with your table's ID

			// Convert the table to a worksheet
			const worksheet = XLSX.utils.table_to_sheet(table);

			// Create a new workbook and append the worksheet
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

			// Export the workbook as an Excel file
			XLSX.writeFile(workbook, "detail_export.xlsx");
		}
	},
};
</script>
<style scoped>
.level-1 {
	padding-left: 2em !important;
}

.level-2 {
	padding-left: 2em !important;
}

.level-3 {
	padding-left: 4em !important;
}

.level-4 {
	padding-left: 6em !important;
}

.level-5 {
	padding-left: 8em !important;
}

.level-6 {
	padding-left: 10em !important;
}

.text-right {
	text-align: right;
}

.red-savings {
	color: red !important;
	text-align: right;
}

.green-savings {
	color: green !important;
	text-align: right;
}

thead th {
	font-weight: bolder !important;
	vertical-align: middle;
}
</style>