<template>
    <div class="">
        <div class="card">
            <div class="card-header d-md-flex align-items-center justify-content-between mb-4" style="padding-bottom: 0px !important; margin-bottom: 10px !important;">
                <div class=" nav-link d-flex gap-2">
                    <h5 class="my-auto">Daily Dashboard</h5>
                    <div class=" nav-link d-flex gap-2" v-if="$store.getters.user?.role?.role_group?.role_group_code=='TM'">
                        <router-link class="btn btn-sm btn-primary btn-sm " to="/project_dashboard">Project </router-link>
                        <router-link class="btn btn-sm btn-primary btn-sm " to="/admin_dashboard">Overall </router-link>
                    </div>
                </div>
                
                <!-- <strong >Daily Dashboard</strong> -->
                <nav class="nav gap-2 nav-icon nav-icon-sm ms-auto">
                    
                    <div class=" nav-link">
                        <label class="me-2">Project&nbsp;Groups: </label>
                        <select class="form-control form-control-sm" v-model="barchart.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class=" nav-link">
                        <label class="me-2">Projects: </label>
                        <select class="form-control form-control-sm" v-model="barchart.project_id">
                            <option value="">Select Project</option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name }}</option>
                        </select>
                        <!-- <search
                          
                           :initialize="barchart.project_id"
                           id="project_id"
                           label="project_name"
                           placeholder="Select Project"
                           :data="projects"
                           @input="project =>barchart.project_id= project"
                           @change="setLocalStorage()"
                       >
                       </search> -->
                    </div>
                    <div class="nav-link">
                        <label class="me-2">Date: </label>
                        <div class="input-group date" id="date">
                            <input type="date" class="form-control form-control-sm" v-model="barchart.job_date_time" />
                        </div>

                    </div>
                    <div class="nav-link">
                        <button class="btn btn-sm btn-primary me-2 btn-sm " @click="getOverallEngineerig()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                    </div>
                </nav>
            </div>

            <div class="card-body" style="padding-bottom: 0px !important; padding-top: 0px !important; margin-top: 0px !important;">
                <div class="row">
                    <div class="col-sm-3 mb-1 mt-0" style="border: 1px solid black;">
                        <!-- <Donut :container="'donuta'" :title="donuta.title" :max="donuta.max" :series="donuta.series" :plotoption="donuta.plotoption"> </Donut> -->
                        <GaugeMeter
                            :container="'gaugemetera'"
                            :title="gauge_meter_a.title"
                            :max="gauge_meter_a.max"
                            :series="gauge_meter_a.series"
                            :type="gauge_meter_a.type"
                            :plot_bands="gauge_meter_a.plot_bands"
                            :actual="gauge_meter_a.actual"
                            :plan="gauge_meter_a.plan"
                            :label_font_size="gauge_meter_a.label_font_size"
                            ref="child"
                            :height="gauge_meter_a.height"
                            :width="gauge_meter_a.width"
                            :vartical_align="gauge_meter_a.vartical_align"
                        />
                    </div>
                    <div class="col-sm-3 mb-1 mt-0" style="border: 1px solid black;">
                        <!-- <Donut :container="'donutb'" :title="donutb.title" :max="donutb.max" :series="donutb.series" :plotoption="donutb.plotoption"> </Donut> -->
                        <GaugeMeter
                            :container="'gaugemeterb'"
                            :title="gauge_meter_b.title"
                            :max="gauge_meter_b.max"
                            :series="gauge_meter_b.series"
                            :type="gauge_meter_b.type"
                            :plot_bands="gauge_meter_b.plot_bands"
                            :actual="gauge_meter_b.actual"
                            :plan="gauge_meter_b.plan"
                            :label_font_size="gauge_meter_b.label_font_size"
                            ref="child"
                            :height="gauge_meter_b.height"
                            :width="gauge_meter_b.width"
                            :vartical_align="gauge_meter_b.vartical_align"
                        />
                    </div>
                    <div class="col-sm-3 mb-1 mt-0" style="border: 1px solid black;">
                        <!-- <Donut :container="'donutc'" :title="donutc.title" :max="donutc.max" :series="donutc.series" :plotoption="donutc.plotoption"> </Donut> -->
                        <GaugeMeter
                            :container="'gaugemeterc'"
                            :title="gauge_meter_c.title"
                            :max="gauge_meter_c.max"
                            :series="gauge_meter_c.series"
                            :type="gauge_meter_c.type"
                            :plot_bands="gauge_meter_c.plot_bands"
                            :actual="gauge_meter_c.actual"
                            :plan="gauge_meter_c.plan"
                            :label_font_size="gauge_meter_c.label_font_size"
                            ref="child"
                            :height="gauge_meter_c.height"
                            :width="gauge_meter_c.width"
                            :vartical_align="gauge_meter_c.vartical_align"
                        />
                    </div>
                    <div class="col-sm-3 mb-1 mt-0" style="border: 1px solid black;">
                        <!-- <Donut :container="'donutc'" :title="donutc.title" :max="donutc.max" :series="donutc.series" :plotoption="donutc.plotoption"> </Donut> -->
                        <GaugeMeter
                            :container="'gaugemeterd'"
                            :title="gauge_meter_d.title"
                            :max="gauge_meter_d.max"
                            :series="gauge_meter_d.series"
                            :type="gauge_meter_d.type"
                            :plot_bands="gauge_meter_d.plot_bands"
                            :actual="gauge_meter_d.actual"
                            :plan="gauge_meter_d.plan"
                            :label_font_size="gauge_meter_d.label_font_size"
                            ref="child"
                            :height="gauge_meter_d.height"
                            :width="gauge_meter_d.width"
                            :vartical_align="gauge_meter_d.vartical_align"
                        />
                    </div>
                    <div class="col-sm-3 mb-0 mt-0" style="border: 1px solid black;">
                        <BarChart
                            :type="'bar'"
                            :container="'barchartb'"
                            :title="barchartb.title"
                            :yAxis="barchartb.yAxis"
                            :xAxis="barchartb.xAxis"
                            :series="barchartb.series"
                            :stacking="barchartb.stacking"
                            :labels="barchartb.labels"
                            :xAxisLables="barchartb.xAxisLables"
                            :label_font_size="barchartb.label_font_size"
                            :height="'60%'"
                            :yAxisLables="false">
                        </BarChart>
                    </div>
                    <div class="col-sm-3 mb-0 mt-0" style="border: 1px solid black;">
                        <BarChart
                            :type="'column'"
                            :container="'barchartc'"
                            :title="barchartc.title"
                            :yAxis="barchartc.yAxis"
                            :xAxis="barchartc.xAxis"
                            :series="barchartc.series"
                            :stacking="barchartc.stacking"
                            :labels="barchartc.labels"
                            :xAxisLables="barchartc.xAxisLables"
                            :label_font_size="barchartc.label_font_size"
                            :height="'50%'"
                            :marginBottom="'55'">
                        </BarChart>
                    </div>
                    <div class="col-sm-3 mb-0 mt-0" style="border: 1px solid black;">
                        <BarChart
                            :type="'bar'"
                            :container="'barchartd'"
                            :title="barchartd.title"
                            :yAxis="barchartd.yAxis"
                            :xAxis="barchartd.xAxis"
                            :series="barchartd.series"
                            :stacking="barchartd.stacking"
                            :labels="barchartd.labels"
                            :xAxisLables="barchartd.xAxisLables"
                            :label_font_size="barchartd.label_font_size"
                            :height="'50%'"
                            :yAxisLables="false">
                        </BarChart>
                    </div>
                    <div class="col-sm-3 mb-0 mt-0" style="border: 1px solid black;">
                        <BarChart
                            :type="'column'"
                            :container="'barcharte'"
                            :title="barcharte.title"
                            :yAxis="barcharte.yAxis"
                            :xAxis="barcharte.xAxis"
                            :series="barcharte.series"
                            :stacking="barcharte.stacking"
                            :labels="barcharte.labels"
                            :xAxisLables="barcharte.xAxisLables"
                            :label_font_size="barcharte.label_font_size"
                            :height="'50%'"
                            :marginBottom="'28'">
                        </BarChart>
                    </div>
                                        <div class="col-sm-3 mb-1 mt-0" style="border: 1px solid black;">
                        <Donut :container="'donuta'" :title="donuta.title" :max="donuta.max" :series="donuta.series" 
                        :plotoption="donuta.plotoption" :margin_top="donuta.margin_top" :height="donuta.height"> </Donut>
                    </div>
                    <div class="col-sm-3 mb-1 mt-0" style="border: 1px solid black;">
                        <Donut :container="'donutb'" :title="donutb.title" :max="donutb.max" :series="donutb.series" 
                        :plotoption="donutb.plotoption" :margin_top="donutb.margin_top" :height="donutb.height"> </Donut>
                    </div>
                    <div class="col-sm-3 mb-1 mt-0" style="border: 1px solid black;">
                        <Donut :container="'donutc'" :title="donutc.title" :max="donutc.max" :series="donutc.series" 
                        :plotoption="donutc.plotoption" :margin_top="donutc.margin_top" :height="donutc.height"> </Donut>
                    </div>
                    <div class="col-sm-3 mb-1 mt-0" style="border: 1px solid black;">
                        <BarChart
                            :type="'column'"
                            :container="'barcharta'"
                            :title="barcharta.title"
                            :yAxis="barcharta.yAxis"
                            :xAxis="barcharta.xAxis"
                            :series="barcharta.series"
                            :stacking="barcharta.stacking"
                            :labels="barcharta.labels"
                            :xAxisLables="barcharta.xAxisLables"
                            :label_font_size="barcharta.label_font_size"
                            :height="'50%'"
                            :marginBottom="'65'">
                        </BarChart>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end">
            </div>
        </div>
    </div>
</template>
<script>
let Search = require("@/components/Search.vue").default;
import Donut from '@/components/charts/Donut'
import GaugeMeter from '@/components/charts/GaugeMeter'
import BarChart from '@/components/charts/BarChart'
import SplineBarChart from '@/components/charts/SplineBarChart'
import LineChart from '@/components/charts/LineChart'
var Highcharts = require("highcharts");
require("highcharts/modules/heatmap")(Highcharts);
import moment from "moment";
export default {
    data(){
        return{
            initial:true,
            isLoading: false,
            projects:[],
            projectgroups: [],
            chart: {
                title: 'Overall S Curve',
                backgroundColor: '#fff',
                gridLineColor: '#ccd6eb',
                thousandsSep: '.',
                decimalPoint: ',',
                xAxis: {
                },
                yAxis: {
                },      
                
                series: [
                    {
                        name: 'Overall Plan',
                        marker: {
                            symbol: 'circle'
                        },
                        color:'#03bbd4',
                        data: [8,11,15,21,28,33,40,45,51,57,62,67,73,79,83,91,95,98,100]
                    }, 
                    {
                        name: 'Overall Actual',
                        marker: {
                            symbol: 'circle'
                        },
                        color:'#f60000',
                        data: [3,9,17,25,34,46,51,67]
                    }
                ],



                crosshair: true,
                customStyles: {
                    color: '#666',
                    fontFamily: 'Nunito Sans'
                }
            },
            gauge_meter_a: {
                title: "Overall Engineering",
                type: "solidgauge",
                max: 0,
                label_font_size: "12px",
                project_parameter_length: "",
                plot_bands: [
                    {
                        from: 0,
                        to: 100,
                        color: "#C0C0C0",
                        thickness: 15,
                    },
                ],
                actual: [],
                plan: [],
                base_line_start_date: "",
                base_line_end_date: "",
                completion_days: "",
                height: "45%",
                marginTop: 50,
                vartical_align:'top'
            },
            gauge_meter_b: {
                title: "Overall Procurement",
                type: "solidgauge",
                max: 0,
                label_font_size: "12px",
                project_parameter_length: "",
                plot_bands: [
                    {
                        from: 0,
                        to: 100,
                        color: "#C0C0C0",
                        thickness: 15,
                    },
                ],
                actual: [],
                plan: [],
                base_line_start_date: "",
                base_line_end_date: "",
                completion_days: "",
                height: "45%",
                marginTop: 50,
                vartical_align:'top'
            },
            gauge_meter_c: {
                title: "Overall Delivery",
                type: "solidgauge",
                max: 0,
                label_font_size: "12px",
                project_parameter_length: "",
                plot_bands: [
                    {
                        from: 0,
                        to: 100,
                        color: "#C0C0C0",
                        thickness: 15,
                    },
                ],
                actual: [],
                plan: [],
                base_line_start_date: "",
                base_line_end_date: "",
                completion_days: "",
                height: "45%",
                marginTop: 50,
                vartical_align:'top'
            },
            gauge_meter_d: {
                title: "Overall Construction",
                type: "solidgauge",
                max: 0,
                label_font_size: "12px",
                project_parameter_length: "",
                plot_bands: [
                    {
                        from: 0,
                        to: 100,
                        color: "#C0C0C0",
                        thickness: 15,
                    },
                ],
                actual: [],
                plan: [],
                base_line_start_date: "",
                base_line_end_date: "",
                completion_days: "",
                height: "45%",
                marginTop: 50,
                vartical_align:'top'
            },
            donuta: {
                title: "",
                max: 0,
                margin_top:25,
                height:'45%',
                plotoption: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        size: '80%',
                        innerSize: '80%',
                        dataLabels: {
                            connectorWidth: 0,
                            distance: 5,
                            enabled: true,
                            formatter: function() {
                                return '<span style="font-size:10px">' + this.point.y;
                            }
                        },
                        showInLegend: true,
                    },                        
                },
                series: [],
            },

            donutb: {
                title: "",
                height:'45%',
                max: 0,
                margin_top:20,
                plotoption: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        size: '80%',
                        innerSize: '60%',
                        dataLabels: {
                            connectorWidth: 0,
                            distance: 5,
                            enabled: true,
                            formatter: function() {
                                return '<span style="font-size:10px">' + this.point.y;
                            }
                        },
                        showInLegend: true,
                    },
                },
                series: [],
            },
            donutc: {
                title: "",
                height:'45%',
                max: 0,
                margin_top:20,
                plotoption: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        size: '80%',
                        innerSize: '60%',
                        dataLabels: {
                            connectorWidth: 0,
                            distance: 5,
                            enabled: true,
                            formatter: function() {
                                return '<span style="font-size:10px">' + this.point.y;
                            }
                        },
                        showInLegend: true,
                    },
                },
                series: [],
            },
            barcharta:{
                title:"Safety KPIs",
                xAxis: ['LTI free days', 'Near Miss', 'FAC', 'MTC', 'LTI'],
                xAxisLables:{
                    enabled: true,
                    style:{fontSize:"9px"}
                },
                label_font_size: "12px",
                stacking:"",
                labels:"",
                series: []
            },
            barchartb:{
                title:"Daily Progress",
                xAxis: ['Ere(MT)', 'Fab(MT)', 'RCC(CuM)'],
                xAxisLables:{
                    enabled: true
                },
                label_font_size: "12px",
                stacking:"",
                labels:"",
                series: [
                    // {
                    //     name: 'FTD',
                    //     data: [58, 33, 300],
                    //     color: '#f7a35c',
                    // }, 
                    // {
                    //     name: 'Target FTD',
                    //     data: [88, 50, 350],                            
                    //     color: '#03bbd4',
                    // },
                ]
            },

            barchartc:{
                title:"Productivity",
                // yAxis: [0,1,2,3,4,5,6,7,8,9,10],
                // xAxis: ['Jul. 23', 'Aug. 23', 'Sep. 23', 'Oct. 23'],
                xAxis:[],
                xAxisLables:{
                    enabled: true
                },
                label_font_size: "12px",
                stacking:"",
                labels:"",
                series: [
                    // {
                    //     name: 'RCC',
                    //     data: [6, 5, 3, 3.7],
                    //     color: '#03bbd4',
                        
                    // }, 
                    // {
                    //     name: 'Fabrication',
                    //     data: [3, 3.4, 2.9, 3.6],
                    //     color: '#f7a35c',
                    // },
                    // {
                    //     name: 'Erection',
                    //     data: [2, 3, 3.5, 4],                            
                    //     color: '#7c7c7c',
                    // },
                ]
            },
            barchartd:{
                title:"Progress vs Monthly Target",
                // yAxis: [0,20, 40, 60, 80, 100],
                // xAxis: ['Erection (MT)', 'Fabrication (MT)', 'RCC (CuM)'],
                xAxis: ['Ere(MT)', 'Fab(MT)', 'RCC(CuM)'],
                xAxisLables:{
                    enabled: true
                },
                label_font_size: "12px",
                stacking:"percent",
                series: [
                    // {
                    //     name: 'Target Balance',
                    //     data: [1440, 1700, 8350],
                    //     color: '#f7a35c',                        
                    // }, 
                    // {
                    //     name: 'MTD',
                    //     data: [1560, 1100, 3650],                        
                    //     color: '#03bbd4',
                    // },
                ],
                labels:"%",
            },
            barcharte:{
                title:"Daily Man Power",
                // yAxis: [0,1,2,3,4,5,6,7,8,9,10],
                // xAxis: ['Civil', 'Fabrication', 'Erection', 'E&I'],
                xAxis: ['Civil', 'Fab', 'Erec', 'E&I'],
                xAxisLables:{
                    enabled: true
                },
                label_font_size: "12px",
                stacking:"",
                labels:"",
                series: [
                    // {
                    //     name: 'Required',
                    //     data: [1000, 560, 1100, 200],
                    //     color: '#03bbd4',
                        
                    // }, 
                    // {
                    //     name: 'Available',
                    //     data: [925, 370, 900, 163],
                    //     color: '#f7a35c',
                    // },
                    // {
                    //     name: 'Erection',
                    //     data: [1100, 900],                            
                    //     color: '#7c7c7c',
                    // },
                    // {
                    //     name: 'E&I',
                    //     data: [200, 163],                            
                    //     color: '#7c7c7c',
                    // },
                ]
            },
            barchartf:{
                title:"Erection",
                // yAxis: [0,1,2,3,4,5,6,7,8,9,10],
                xAxis: ['Mar-23', 'Apr-23', 'May-23', 'Jun-23','Jul-23', 'Aug-23', 'Sep-23', 'Oct-23', 'Nov-23', 'Dec-23',
                        'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24'],
                xAxisLables:{
                    enabled: true
                },
                yAxis: [5000,10000,15000,20000,25000,30000,35000],
                    // { // Primary yAxis
                    //     labels: {
                    //         format: '{value}°C',
                    //         style: {
                    //             color: Highcharts.getOptions().colors[2]
                    //         }
                    //     },
                    //     title: {
                    //         text: 'Temperature',
                    //         style: {
                    //             color: Highcharts.getOptions().colors[2]
                    //         }
                    //     },
                    //     opposite: true

                    // }, 
                    // { // Secondary yAxis
                    // gridLineWidth: 0,
                    // title: {
                    //     text: 'Rainfall',
                    //     style: {
                    //         color: Highcharts.getOptions().colors[0]
                    //     }
                    // },
                    // labels: {
                    //     format: '{value} mm',
                    //     style: {
                    //         color: Highcharts.getOptions().colors[0]
                    //     }
                    // }

                    // }, 
                    // { // Tertiary yAxis
                    //     gridLineWidth: 0,
                    //     title: {
                    //         text: 'Sea-Level Pressure',
                    //         style: {
                    //             color: Highcharts.getOptions().colors[1]
                    //         }
                    //     },
                    //     labels: {
                    //         format: '{value} mb',
                    //         style: {
                    //             color: Highcharts.getOptions().colors[1]
                    //         }
                    //     },
                    //     opposite: true
                    // }
                // ],

                            
                stacking:"",
                labels:"",

                series: [{
                    name: 'Plan',
                    type: 'column',
                    yAxis: 1,
                    color:'#7c7c7c',
                    data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4, 10.45, 15.25,12.15, 10],
                    // tooltip: {
                    //     valueSuffix: ' mm'
                    // }

                }, {
                    name: 'Actual',
                    type: 'column',
                    yAxis: 2,
                    color:'#f7f700',
                    data: [49.9, 91.5, 126.4, 109.2, 104.0, 266.0, 105.6, 98.5, 316.4, 104.1, 150.6, 34.4],
                    // marker: {
                    //     enabled: false
                    // },
                    // dashStyle: 'shortdot',
                    // tooltip: {
                    //     valueSuffix: ' mb'
                    // }

                }, {
                    name: 'Cumm Plan',
                    type: 'spline',
                    yAxis: 3,
                    color:'#f7a35c',
                    data: [101, 301, 595, 2302, 3739, 6225, 8651, 12181],
                    // tooltip: {
                    //     valueSuffix: ' °C'
                    // }
                },
                {
                    name: 'Cumm Actual',
                    type: 'spline',
                    color:'#03bbd4',
                    data: [101, 301, 595, 2302, 3739, 6225,8683, 12156, 15630, 19103, 21997, 24602, 26628, 28356, 28944],
                    // tooltip: {
                    //     valueSuffix: ' °C'
                    // }
                }],
            },
            barchart:{
                job_date_time:'',
                project_id:'',
                project_group_id:'',
                current_month_year:'',
            }
        }
    },
    components:{ Donut, GaugeMeter, BarChart, LineChart, SplineBarChart,Search },
    mounted(){
        let vm = this;
        vm.barchart.job_date_time = moment().format("YYYY-MM-DD");
        if(this.$store.getters.user){
            this.getProjectGroups()
        }
        
        // vm.getProjects();
        
    },
    methods:{
        setLocalStorage(){
            localStorage.setItem('project_group_id', this.barchart.project_group_id)
            localStorage.setItem('project_id', this.barchart.project_id)
        },
        getProjectGroups() {
            let vm = this;
            let uri = { uri: "getProjectGroups" };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.projectgroups = response.data.data
                    if(localStorage.getItem('project_group_id')){
                        vm.barchart.project_group_id = localStorage.getItem('project_group_id') 
                    }else if (vm.projectgroups.length) {
                        if (!vm.barchart.project_group_id) {
                            vm.barchart.project_group_id = vm.projectgroups[0].project_group_id;
                        }
                    }
                    vm.getProjects();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getProjects() {
            let vm = this
            if(!vm.initial){
                localStorage.removeItem('project_id')
            }
            let uri = { uri: "getProjectGroupProjects",data: vm.barchart};
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    vm.projects = response.data
                    if(localStorage.getItem('project_id')){
                        vm.barchart.project_id = localStorage.getItem('project_id')
                    }else if(vm.projects.length && !localStorage.getItem('project_id')){
                        vm.barchart.project_id = vm.projects[0].project_id
                    }
                    if(vm.initial){
                        vm.getOverallEngineerig()
                        vm.initial = false
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getOverallEngineerig(){
            let vm = this;
            localStorage.setItem('project_group_id', this.barchart.project_group_id)
            localStorage.setItem('project_id', this.barchart.project_id)
            vm.barchart.current_month_year = moment().format("MMM-YY");
            let loader = vm.$loading.show();
            let uri = { uri: "getOverallGaugeGraph", data: vm.barchart };
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.gauge_meter_a.actual = [];
                vm.gauge_meter_a.plan = [];
                vm.gauge_meter_b.actual = [];
                vm.gauge_meter_b.plan = [];
                vm.gauge_meter_c.actual = [];
                vm.gauge_meter_c.plan = [];
                vm.gauge_meter_d.actual = [];
                vm.gauge_meter_d.plan = [];
                
                vm.gauge_meter_a.plan.push(response.data.engineering.overall_plan.total_value);
                vm.gauge_meter_a.actual.push(response.data.engineering.actual_plan.total_value);
                vm.gauge_meter_a.plot_bands = vm.getPlotBands(response.data.engineering.actual_plan.total_value, 
                        response.data.engineering.overall_plan.total_value);

                vm.gauge_meter_b.plan.push(response.data.procurement.overall_plan.total_value);
                vm.gauge_meter_b.actual.push(response.data.procurement.actual_plan.total_value);
                vm.gauge_meter_b.plot_bands = vm.getPlotBands(response.data.procurement.actual_plan.total_value, 
                        response.data.procurement.overall_plan.total_value);

                vm.gauge_meter_c.plan.push(response.data.delivery.overall_plan.total_value);
                vm.gauge_meter_c.actual.push(response.data.delivery.actual_plan.total_value);
                vm.gauge_meter_c.plot_bands = vm.getPlotBands(response.data.delivery.actual_plan.total_value, 
                        response.data.delivery.overall_plan.total_value);

                vm.gauge_meter_d.plan.push(response.data.construction.overall_plan.total_value);
                vm.gauge_meter_d.actual.push(response.data.construction.actual_plan.total_value);
                vm.gauge_meter_d.plot_bands = vm.getPlotBands(response.data.construction.actual_plan.total_value, 
                        response.data.construction.overall_plan.total_value);

                vm.getDailyProgress()
                loader.hide();
                
            })
            .catch(function (error) {
                console.log(error);
                loader.hide();
            });
        },
        getDailyProgress(){
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "dailyProgressCivilMechBarChart", data: vm.barchart };
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.barchartb.series=[
                    {
                        name: 'FTD',
                        data: response.data.ftd,
                        color: '#f7a35c',
                    }, 
                    {
                        name: 'Target FTD',
                        data: response.data.taget_ftd,                            
                        color: '#03bbd4',
                    },
                ]
                vm.getDailyManPower()
                loader.hide();             
            })
            .catch(function (error) {
                console.log(error);
                loader.hide();
            });
        },
        getDailyManPower(){
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "dailyManPowerBarChart", data: vm.barchart };
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.barcharte.series = [{
                        name: 'Required',
                        data: response.data.man_power_required,
                        color: '#03bbd4',
                        
                    }, 
                    {
                        name: 'Available',
                        data: response.data.man_power_available,
                        color: '#f7a35c',
                    },
                ]
                vm.getProductivity()
                loader.hide();
            })
            .catch(function (error) {
                console.log(error);
                loader.hide();
            });
        },

        getProductivity(){
            let vm = this;
            let loader = vm.$loading.show()
            let uri = { uri: "productivityBarChart", data: vm.barchart };
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.barchartc.xAxis = response.data.month_year;
                vm.barchartc.series = [
                    {
                        name: 'RCC',
                        data: response.data.rcc_productivity,
                        color: '#03bbd4',
                        
                    }, 
                    {
                        name: 'Fabrication',
                        data: response.data.fab_productivity,
                        color: '#f7a35c',
                    },
                    {
                        name: 'Erection',
                        data: response.data.ere_productivity,                            
                        color: '#7c7c7c',
                    },
                ]
                vm.getProgressVsMonthlyTarget();
                loader.hide();
            })
            .catch(function (error) {
                console.log(error);
                loader.hide();
            });
        },
        getProgressVsMonthlyTarget(){
            let vm = this;
            let loader = vm.$loading.show()
            let uri = { uri: "progressVsMonthlyTargetBarChart", data: vm.barchart };
            vm.$store.dispatch("post", uri)
            .then(function (response) {                
                vm.barchartd.series = [
                    {                        
                        name: 'Target Balance',
                        data: response.data.balance,
                        color: '#f7a35c',                        
                    }, 
                    {
                        name: 'MTD',
                        data: response.data.ftd,                        
                        color: '#03bbd4',
                    },
                ]
                vm.getSheetingDonut()
                loader.hide();
            })
            .catch(function (error) {
                console.log(error);
                loader.hide();
            });
        },
        getSheetingDonut(){
            let vm = this
            let loader = vm.$loading.show()
            let uri = { uri: "sheetingDonut", data: vm.barchart };
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.donuta.title = response.data.percentage+'%'               
                vm.donuta.series = [
                    {
                        name: "Sheeting Complete",
                        y: response.data.sheeting_complete,
                        color: "#03bbd4",
                    },
                    {
                        name: "Sheeting Balance",
                        y: response.data.sheeting_balance,
                        color: "#f7a35c",
                    },
                ]
                vm.getRefractoryDonut()
                loader.hide()
            })
            .catch(function (error) {
                console.log(error)
                loader.hide()
            });
        },    
        getRefractoryDonut(){
            let vm = this
            let loader = vm.$loading.show()
            let uri = { uri: "refractoryDonut", data: vm.barchart };
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.donutb.title = response.data.percentage+'%'
                vm.donutb.series = [
                    {
                        name: "Refractory Completed",
                        y: response.data.refractory_complete,
                        color: "#03bbd4",
                    },
                    {
                        name: "Refractory Balance",
                        y: response.data.refractory_balance,
                        color: "#f7a35c",
                    },
                ]
                vm.getInsulationDonut()
                loader.hide()
            })
            .catch(function (error) {
                console.log(error)
                loader.hide()
            });
        },
        getInsulationDonut(){
            let vm = this
            let loader = vm.$loading.show()
            let uri = { uri: "insulationDonut", data: vm.barchart };
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.donutc.title = response.data.percentage+'%'
                vm.donutc.series = [
                    {
                        name: "Insulation Completed",
                        y: response.data.insulation_complete,
                        color: "#03bbd4",
                    },
                    {
                        name: "Insulation Balance",
                        y: response.data.insulation_balance,
                        color: "#f7a35c",
                    },
                ]
                vm.getSafetyKpiBarchart()
                loader.hide()
            })
            .catch(function (error) {
                console.log(error)
                loader.hide()
            });
        }, 
        getSafetyKpiBarchart(){
            let vm = this
            let loader = vm.$loading.show()
            let uri = { uri: "safetyKPIBarChart", data: vm.barchart };
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                loader.hide()
                vm.barcharta.series = [{
                    name:"data",
                    data:[
                    {
                        name:'LTI free days',
                        y: response.data.LTI_Free_days,
                        color: '#008140',
                    },
                    {
                        name:'Near Miss',
                        y: response.data.Near_Miss_Reported,
                        color: '#f7a35c',
                    },
                    {
                        name:'FAC',
                        y: response.data.FAC,
                        color: '#f7a35c',
                    },
                    {
                        name:'MTC',
                        y: response.data.MTC,
                        color: '#f7a35c',
                    },
                    {
                        name:'LTI',
                        y: response.data.LTI,
                        color: '#d30101',
                    }]
                }]
                
            })
            .catch(function (error) {
                console.log(error)
                loader.hide()
            });
        },   
        getPlotBands(actual, plan) {
                //Plot band calculation
                let plot_bands = [];
                let plot_band_calculation = (actual / plan) * 100;
                let plot_band_to = "";
                let plot_band_color = "";
                if (plan[0] <= actual[0]) {
                    plot_band_to = parseFloat(actual[0].toFixed(2));
                    plot_band_color = "green";
                } else if (plot_band_calculation < 75) {
                    plot_band_to = parseFloat(plot_band_calculation.toFixed(2));
                    plot_band_color = "green";
                } else if (plot_band_calculation >= 75 && plot_band_calculation < 89) {
                    plot_band_to = parseFloat(plot_band_calculation.toFixed(2));
                    plot_band_color = "green";
                } else if (plot_band_calculation >= 89 && plot_band_calculation <= 100) {
                    plot_band_to = parseFloat(plot_band_calculation.toFixed(2));
                    plot_band_color = "green";
                }
                
                plot_bands.push(
                    {
                        from: 0,
                        to: actual,
                        color: plot_band_color,
                        thickness: 15,
                    },
                    {
                        from: actual,
                        to: 100,
                        color: "#C0C0C0",
                        thickness: 15,
                    }
                );
                if(actual > plan){
                    plot_bands.push(                        
                        {
                            from: 0,
                            to: actual,
                            color: "green",
                            thickness: 15,
                        }
                    )
                }
                else{
                    plot_bands.push(                        
                        {
                            from: actual,
                            to: plan,
                            color: "black",
                            thickness: 15,
                        }
                    )
                }
                return plot_bands;
            },
    }
}
</script>
<style scoped>

</style>
