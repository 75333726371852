<template>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <strong > Job List</strong>
                </div>
                <div class="card-body">
                    <div class="row" style="margin-bottom: 10px;">
                        <div class="col-md-3">
                            <label class="form-label">Project Groups</label>
                            <select class="form-control form-control-sm" v-model="meta.project_group_id" @change="getProjects()">
                                <option value="">Select Project Groups</option>
                                <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Projects</label>
                            <select class="form-control form-control-sm" v-model="meta.project_id" @change="getProjectActivityGroup()">
                                <option value="">-- Select Project --</option>
                                <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name }}</option>
                            </select>
                            <!-- <search 
                            :initialize="meta.project_id" 
                            id="project_id" 
                            label="project_name" 
                            placeholder="Select Project" 
                            :data="projects" 
                            @change="getProjectActivityGroup()" 
                            @input="project => meta.project_id = project">
                            </search> -->
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Project Activity Group </label>
                            <select class="form-control form-control-sm" v-model="meta.project_activity_group_id">
                                <option value="">Select Project Activity Group</option>
                                <option v-for="pag, key in project_activity_groups" :key="key" :value="pag.project_activity_group_id">{{ pag.activity_group.activity_group_name }}</option>
                            </select>
                        </div>
                        <div class="col-md-2 mt-auto">
                            <label class="form-label"></label>
                            <button class="btn btn-primary btn-sm" @click="index()">Get</button>
                        </div>
                    </div>

                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm table-bordered table-striped mb-0">
                            <thead>
                                <tr >
                                    <th class="text-center">#</th>
                                    <th>Project Name</th>
                                    <th>User</th>
                                    <th>Activity Group</th>
                                    <th class="text-center">Job Number</th>
                                    <th class="text-center">Date Time</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="job, key in jobs" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{ job.project?.project_name }}</td>
                                    <td>{{ job.user?.name }}</td>
                                    <td>{{ job.project_activity_group?.activity_group?.activity_group_name }}</td>
                                    <td class="text-center">{{ job.job_no }}</td>
                                    <td class="text-center">{{ change_dataformat(job.job_date_time) }}</td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-sm btn-outline-success me-2" @click="editJobs(job.job_id)">
                                            <i class="ri-eye-fill icon_hgt"></i>
                                        </button>
                                        <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteJobs(job.job_id)">
                                            <i class="ri-delete-bin-line icon_hgt"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center" style="float: right;">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    import moment from "moment";
    let Search = require("@/components/Search.vue").default;
    export default {
        name: "JobList",
        components: { Pagination, Search },
        watch: {
            project_id: {
                handler() {
                    console.log("test:----");
                    this.getProjectActivityGroup();
                },
                deep: true,
            },
        },
        data() {
            return {
                initial:true,
                jobs: [],
                projects: [],
                project_activity_groups: [],
                meta: {
                    project_id: "",
                    project_group_id:"",
                    project_activity_group_id: "",
                    search: "",
                    order_by: "desc",
                    keyword: "job_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                projectgroups:[]
            };
        },
        mounted() {
            if(this.$store.getters.user){
                this.getProjectGroups();
                this.index();
            }
            
        },
        methods: {
            change_dataformat(date_time) {
                return moment(date_time).format("DD-MMM-yyyy");
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if (vm.projectgroups.length) {
                            if (!vm.meta.project_group_id) {
                                vm.meta.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        if(localStorage.getItem('project_group_id')){
                            vm.meta.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.meta.project_group_id) {
                                vm.meta.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        // vm.getProjectRequestApproved()
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjects() {
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                let uri = { uri: "getProjectGroupProjects", data: {project_group_id: vm.meta.project_group_id} };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projects = response.data;
                        // if (vm.projects.length) {
                        //     vm.meta.project_id = vm.projects[0].project_id;
                        // }  
                        if(localStorage.getItem('project_id')){
                            vm.meta.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.meta.project_id = vm.projects[0].project_id;
                        }
                        if(vm.initial){
                            vm.initial = false
                        }
                        vm.getProjectActivityGroup()
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjectActivityGroup() {
                let vm = this;
                let uri = { uri: "getProjectActivityGroupDropDown", data: { project_id: vm.meta.project_id } };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        console.log("response:-----", response);
                        vm.project_activity_groups = [];
                        vm.project_activity_groups = response.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                    
            },
            index() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "paginateJobs", data: vm.meta };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.jobs = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            editJobs(job_id) {
                // this.$router.push("/job/" + job_id);
                this.$router.push({
                    name: 'JobView', 
                    params: { job_id: job_id },
                    meta:{ project: this.project}
                });
            },
            deleteJobs(job_id) {
                if (confirm("Are you sure you want to delete")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteJobs", data: { job_id: job_id } };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            loader.hide();
                            vm.$store.dispatch("success", response.data.message);
                            vm.index();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
        },
    };
</script>
