<template>
    <div class="card">
        <div class="card-header">
            <strong>{{ project?.project_name }}-{{ name }}</strong>
            <div style="float: right;" class="d-flex display-content">
                <div class="m-auto me-2">Image&nbsp;Upload</div>
                <input type="file" class="form-control form-controls-sm me-2 mb-1" ref="image" />
                <!-- <input type="file" class="form-control form-control-sm me-2" />
                <button class="btn btn-primary btn-sm me-2">Upload</button>
                <button class="btn btn-primary me-2 btn-sm" @click="downloadPMTForm()">Download</button> -->
                <button class="btn btn-warning btn-sm" @click="expand()" v-if="activity_group_div"><i class="ri-fullscreen-fill size"></i></button>
            </div>
        </div>
        <div class="card-body" style="max-height: 485px; overflow-y: auto;">
            <div class="d-md-flex align-items-center justify-content-between">
                <div>
                    <h5 class="mb-0"><strong>{{project.project_section_group?.section_group_name}}</strong></h5>
                </div>
                <div class="d-flex mb-1">
                    <div class="d-flex">
                        <input type="date" class="form-control form-control-sm me-2" :min="min_date" :max="max_date" v-model="project.job_date_time" />
                    </div>

                    <button type="button" class="btn btn-sm btn-primary d-flex align-items-center" @click="getJobParameters()" v-if="activity_group_div && is_get_button">Get</button>
                </div>
            </div>

            <!-- <div style="float: right;" class="d-flex">
                <span class="m-auto me-2">Image&nbsp;Upload</span>
                <input type="file" class="form-control form-controls-sm me-2" ref="image"/>
                <span class="m-auto me-2">Date</span>
                <input type="date" class="form-control form-control-sm me-2" v-model="project.job_date_time" />
                <button class="btn btn-primary btn-sm" @click="getJobParameters()" v-if="activity_group_div && is_get_button">Get</button>
            </div>
            <br> -->
            <!-- <div v-for="project_section_group, psg_key in project.project_section_groups" :key="psg_key"> -->
            <!-- <h5 class="mb-0"><strong>{{project.project_section_group?.section_group_name}}</strong></h5> -->
            <!-- <div v-for="project_section, ps_key in project_section_group.project_sections" :key="ps_key"> -->
            <div v-for="project_section, ps_key in project.project_sections" :key="ps_key">
                <h6 class="mb-1"><strong>{{ project_section.section.section_name }}</strong></h6>
                <div class="table-responsive p-0 mb-4">
                    <table class="table text-nowrap table-responsive-sm table-bordered table-sm mb-0">
                        <tbody>
                            <tr>
                                <td class="sticky-col td4" colspan="3"></td>
                                <td
                                    class="text-center"
                                    v-for="project_parameter_type, project_parameter_type_key in getProjectParameterTypes(project_section)"
                                    :key="project_parameter_type_key"
                                    :colspan="project_parameter_type.project_parameters.length"
                                >
                                    {{ project_parameter_type.parameter_type.parameter_type_name }}
                                </td>
                            </tr>
                            <tr>
                                <td class="sticky-col td1">Section</td>
                                <td class="sticky-col td2">Unit</td>
                                <td class="sticky-col td3 text-center" v-if="project_section?.is_scope">Scope</td>
                                <td class="sticky-col td3-1 text-center" v-else>Weightage</td>

                                <td v-for="project_parameter, project_parameter_key in getProjectParameters(project_section)" :key="project_parameter_key" class="sticky-col text-center">
                                    {{ project_parameter.parameter.parameter_name }}
                                </td>
                            </tr>
                            <tr v-for="project_activity, pa_key in project_section.project_activities" :key="pa_key">
                                <td class="sticky-col td1">{{ project_activity.activity.activity_name }}</td>
                                <td class="sticky-col td2">
                                    {{ project_activity.unit?.unit_name }}
                                </td>

                                <td class="sticky-col td3">
                                    {{ project_activity.scope }}
                                </td>
                                <td v-for="project_parameter, project_parameter_key in project_activity.project_parameters" :key="project_parameter_key">
                                    <div v-if="project_parameter.project_parameter_type?.parameter_type?.parameter_type_code=='Months'">
                                        <div v-if="project_parameter.job_parameter">
                                            <input
                                                v-if="project_parameter.parameter.field_type=='text'"
                                                type="text"
                                                class="form-control form-control1"
                                                v-model="project_parameter.job_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                                @keydown.enter="focusNext(pa_key, project_parameter_key)"
                                                ref="myInputs"
                                                style=""
                                            />
                                            <input
                                                v-if="project_parameter.parameter.field_type=='number'"
                                                type="text"
                                                class="form-control form-control1"
                                                v-model="project_parameter.job_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                                :class="{ 'is-invalid': project_parameter?.errors?.parameter_value }"
                                                @keyup="isNumber(project_parameter)"
                                            />
                                            <span v-if="project_parameter?.errors?.parameter_value"><small class="text-danger">{{ project_parameter?.errors?.parameter_value[0] }}</small></span>
                                            <input
                                                v-if="project_parameter.parameter.field_type=='date'"
                                                type="date"
                                                class="form-control form-control1"
                                                v-model="project_parameter.job_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                            />
                                            <input
                                                v-if="project_parameter.parameter.field_type=='date_time'"
                                                type="datetime-local"
                                                class="form-control form-control1"
                                                v-model="project_parameter.job_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                            />
                                            <select
                                                v-if="project_parameter.parameter.field_type=='dropdown'"
                                                class="form-control form-control1"
                                                v-model="project_parameter.job_parameter.parameter_value"
                                                @change="display(project_parameter)"
                                                style=""
                                            >
                                                <option value="">Select</option>
                                                <option v-for="value, key in project_parameter.parameter.field_values.split(',')" :value="value.replace(/\s/g, '')" :key="key">{{value}}</option>
                                            </select>
                                        </div>
                                        <div v-else>
                                            <input
                                                v-if="project_parameter.parameter.field_type=='text'"
                                                type="text"
                                                class="form-control form-control1"
                                                v-model="project_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                                @keydown.enter="focusNext(project_activity_key, project_parameter_key)"
                                                ref="myInputs"
                                            />
                                            <input
                                                v-if="project_parameter.parameter.field_type=='number'"
                                                type="text"
                                                class="form-control form-control1"
                                                v-model="project_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                                style=""
                                                :class="{ 'is-invalid': project_parameter?.errors?.parameter_value }"
                                                @keyup="isNumber(project_parameter)"
                                            />
                                            <span v-if="project_parameter?.errors?.parameter_value"><small class="text-danger">{{ project_parameter?.errors?.parameter_value[0] }}</small></span>
                                            <input
                                                v-if="project_parameter.parameter.field_type=='date'"
                                                type="date"
                                                class="form-control form-control1"
                                                v-model="project_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                            />
                                            <input
                                                v-if="project_parameter.parameter.field_type=='date_time'"
                                                type="datetime-local"
                                                class="form-control form-control1"
                                                v-model="project_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                            />
                                            <select v-if="project_parameter.parameter.field_type=='dropdown'" class="form-control form-control1 dd" v-model="project_parameter.parameter_value">
                                                <option value="">Select</option>
                                                <option v-for="value, key in project_parameter.parameter.field_values.split(',')" :value="value.replace(/\s/g, '')" :key="key">{{value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="project_parameter.job_parameter">
                                            <input
                                                v-if="project_parameter.parameter.field_type=='text'"
                                                type="text"
                                                size="4"
                                                class="form-control form-control2"
                                                v-model="project_parameter.job_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                                @keydown.enter="focusNext(pa_key, project_parameter_key)"
                                                ref="myInputs"
                                            />
                                            <input
                                                v-if="project_parameter.parameter.field_type=='number'"
                                                type="text"
                                                size="4"
                                                class="form-control form-control2"
                                                v-model="project_parameter.job_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                                :class="{ 'is-invalid': project_parameter?.errors?.parameter_value }"
                                                @keyup="isNumber(project_parameter)"
                                            />
                                            <span v-if="project_parameter?.errors?.parameter_value"><small class="text-danger">{{ project_parameter?.errors?.parameter_value[0] }}</small></span>
                                            <input
                                                v-if="project_parameter.parameter.field_type=='date'"
                                                type="date"
                                                class="form-control form-control2"
                                                v-model="project_parameter.job_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                            />
                                            <input
                                                v-if="project_parameter.parameter.field_type=='date_time'"
                                                type="datetime-local"
                                                class="form-control form-control2"
                                                v-model="project_parameter.job_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                            />
                                            <select v-if="project_parameter.parameter.field_type=='dropdown'" class="form-control form-control2" v-model="project_parameter.job_parameter.parameter_value" @change="display(project_parameter)">
                                                <option value="">Select</option>
                                                <option v-for="value, key in project_parameter.parameter.field_values.split(',')" :value="value.replace(/\s/g, '')" :key="key">{{value}}</option>
                                            </select>
                                        </div>
                                        <div v-else>
                                            <input
                                                v-if="project_parameter.parameter.field_type=='text'"
                                                type="text"
                                                size="4"
                                                class="form-control form-control2"
                                                v-model="project_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                                @keydown.enter="focusNext(project_activity_key, project_parameter_key)"
                                                ref="myInputs"
                                            />
                                            <input
                                                v-if="project_parameter.parameter.field_type=='number'"
                                                type="text"
                                                size="4"
                                                class="form-control form-control2"
                                                v-model="project_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                                :class="{ 'is-invalid': project_parameter?.errors?.parameter_value }"
                                                @keyup="isNumber(project_parameter)"
                                            />
                                            <span v-if="project_parameter?.errors?.parameter_value"><small class="text-danger">{{ project_parameter?.errors?.parameter_value[0] }}</small></span>
                                            <input
                                                v-if="project_parameter.parameter.field_type=='date'"
                                                type="date"
                                                class="form-control form-control2"
                                                v-model="project_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                            />
                                            <input
                                                v-if="project_parameter.parameter.field_type=='date_time'"
                                                type="datetime-local"
                                                class="form-control form-control2"
                                                v-model="project_parameter.parameter_value"
                                                :placeholder="project_parameter.parameter.parameter_name"
                                            />
                                            <select v-if="project_parameter.parameter.field_type=='dropdown'" class="form-control form-control2" v-model="project_parameter.parameter_value">
                                                <option value="">Select</option>
                                                <option v-for="value, key in project_parameter.parameter.field_values.split(',')" :value="value.replace(/\s/g, '')" :key="key">{{value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <div class="card-footer text-end">
            <!-- {{ showSection +' / '+ $store.getters.job_status }} -->
            <button class="btn btn-danger btn-sm me-2" @click.prevent="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
            <button class="btn btn-primary btn-sm" @click="updateJob()" v-if="$store.getters.job_status"><i class="ri-save-line icon_hgt"></i> UPDATE</button>
            <button class="btn btn-primary btn-sm" @click="addJob()" v-if="!$store.getters.job_status"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import moment from 'moment';
    export default {
        name: "ProjectSections",
        props: {
            is_get_button: {
                default: true,
            },
            activity_group_div: {
                default: true,
            },
            project: Object,
            item: Object,
        },
        watch: {
            project: {
                handler() {
                    this.loadData();
                },
                deep: true,
            },
        },
        data() {
            return {
                // treeData: [],
                // active: "",
                // show: false,
                section: {
                    project_section_id: [],
                    is_manual: true,
                    job_date_time: "",
                    project_activity_group_id: "",
                },
                name: "",
                col1: "col-md-3",
                col2: "col-md-9",
                project_activity_group: {
                    is_manual: true,
                    project_id: "",
                    project_activity_group_id: "",
                    job_date_time: "",
                    is_request_date_time: false,
                },

                project_section: {
                    project_id: "",
                    is_manual: true,
                    project_activity_group_id: "",
                    job_date_time: "",
                    is_request_date_time: false,
                    project_activities: [],
                },
                project_section_groups: {
                    project_id: "",
                    is_manual: true,
                    project_section_group_id: "",
                    job_date_time: "",
                    is_request_date_time: false,
                    project_section_groups: [],
                },
                errors: [],
                showSection: false,
                // status: false,
                // is_get_button: true,
                // activity_group_div: true,
            };
        },
        methods: {
            // selectimages(data){
            //     // project.images
            //     // console.log('data:-----',data.srcElement.files)
            //     this.project.image = data.srcElement.files
            // },
            isNumber(project_parameter) {
                console.log("parameter", project_parameter);
                project_parameter.errors = [];
                if (project_parameter.parameter.field_type == "number") {
                    let reg = /^[0-9]\d{0,9}(\.\d{1,5})?%?$/;
                    let parameter_value = "";
                    if (project_parameter.job_parameter) {
                        console.log("job_parameter", project_parameter.job_parameter);
                        parameter_value = project_parameter.job_parameter.parameter_value;
                    } else {
                        parameter_value = project_parameter.parameter_value;
                    }
                    console.log("param value", parameter_value);
                    if (!reg.test(parameter_value)) {
                        if (parameter_value != "") {
                            project_parameter.errors.parameter_value = ["Enter the valid number"];
                            let ag = this.errors.filter(function (element) {
                                return element.project_parameter_id == project_parameter.project_parameter_id;
                            });
                            if (!ag.length) {
                                this.errors.push({ project_parameter_id: project_parameter.project_parameter_id });
                            }
                            console.log(this.errors);
                        } else {
                            let ag = this.errors.filter(function (element) {
                                return element.project_parameter_id == project_parameter.project_parameter_id;
                            });
                            if (ag.length) {
                                this.errors.splice(this.errors.indexOf(ag[0]), 1);
                            }
                        }
                    } else {
                        let ag = this.errors.filter(function (element) {
                            return element.project_parameter_id == project_parameter.project_parameter_id;
                        });
                        if (ag.length) {
                            this.errors.splice(this.errors.indexOf(ag[0]), 1);
                        }
                    }
                }
            },
            loadData() {
                this.project_section_groups.project_section_group_id = this.item.id;
                this.name = this.$store.getters.item.name;
                this.max_date = moment().format('yyyy-MM-DD')
                if(this.$store.getters.user?.role?.role_code!='HQAdmin'){
                    this.min_date = moment().subtract(1, 'days').format('yyyy-MM-DD')
                }
            },
            focusNext(project_activity_key, project_parameter_key) {
                console.log(project_parameter_key);
                console.log(this.$refs.myInputs);
                this.$refs.myInputs[project_parameter_key + 1].focus();
            },
            getProjectParameterTypes(project_section) {
                if (project_section.project_activities?.length) {
                    return project_section.project_activities[0].project_parameter_types;
                }
            },
            getProjectParameters(project_section) {
                let vm = this;
                if (project_section.project_activities?.length) {
                    return project_section.project_activities[0].project_parameters;
                }
            },

            getJobParameters() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.project_section_groups.is_request_date_time = true;
                vm.project_section_groups.project_id = vm.project.project_id;
                vm.project_section_groups.job_date_time = vm.project.job_date_time;
                let uri = { uri: "getProjectSections", data: vm.project_section_groups };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.project.project_sections = response.data.project_sections;
                        vm.$store.dispatch("setJobStatus", response.data.status);
                        // vm.show = true;
                        // vm.name = item_name;
                    })
                    .catch(function (error) {
                        console.log();
                        loader.hide();
                    });
            },
            updateJob() {
                let vm = this;
                vm.project.project_section_group_id = this.project_section_groups.project_section_group_id;
                let loader = vm.$loading.show();
                let uri = { uri: "updateJobSectionGroup", data: vm.project };
                if (vm.errors.length == 0) {
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            loader.hide();
                            vm.$store.dispatch("success", "Updated successfully");
                            if (vm.$refs.image.files[0]) {
                                // console.log('inside if')
                                vm.uploadImage(response.data.job.job_id);
                            }
                        })
                        .catch(function (error) {
                            loader.hide();
                            console.log(error);
                        });
                } else {
                    loader.hide();
                    vm.$store.dispatch("error", "Invalid input data");
                }
            },

            addJob() {
                let vm = this;
                vm.project.project_section_group_id = this.project_section_groups.project_section_group_id;
                let loader = vm.$loading.show();
                let uri = { uri: "addJobSectionGroup", data: vm.project };

                if (vm.errors.length == 0) {
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            loader.hide();
                            vm.$store.state.job_status = true;
                            vm.$store.dispatch("success", "Added successfully");
                            vm.getJobParameters();
                            if (vm.$refs.image.files[0]) {
                                console.log("inside if");
                                vm.uploadImage(response.data.job.job_id);
                            }
                        })
                        .catch(function (error) {
                            loader.hide();
                            console.log("errors:----", error);
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                } else {
                    loader.hide();
                    vm.$store.dispatch("error", "Invalid input data");
                }
            },
            uploadImage(job_id) {
                // console.log('response.data:----',response.data)
                let vm = this;
                const formData = new FormData();
                formData.append("job_id", job_id);
                formData.append("image", vm.$refs.image.files[0]);
                axios
                    .post(vm.$store.state.apiUrl + "api/addJobImage", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: "Bearer" + " " + vm.$store.getters.token,
                        },
                    })
                    .then((response) => {
                        // loader.hide();
                        if (response.data.errors) {
                            vm.errors = response.data.errors;
                        } else {
                            // vm.errors = [];
                            // loader.hide();s
                            vm.$store.dispatch("success", "Image Uploaded successfully");
                        }
                    });
            },
            discard() {
                let vm = this;
                vm.getJobParameters();
                // vm.project.project_activities.map(function (project_parameter) {
                //     project_parameter.project_parameters.map(function (pro_value) {
                //         // console.log('form_class:----',pro_value.value)
                //         pro_value.value = "";
                //     });
                // });
                vm.errors = [];
                // vm.status = true;
            },

            // getProjectSectionGroups() {
            //     let vm = this;
            //     let loader = vm.$loading.show();
            //     vm.project_activity_group.project_id = vm.project.project_id
            //     vm.project_activity_group.job_date_time = vm.project.job_date_time
            //     let uri = { uri: "getProjectSectionGroups", data: vm.project_activity_group };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             loader.hide();
            //             console.log("ss", response.data);
            //             vm.project.project_section_groups = response.data.project_section_groups
            //             // vm.is_get_button = response.data.is_get_button
            //             // vm.status = response.data.status
            //             vm.projectsection_groups = true
            //             // if(response.data.job){
            //             //     vm.project.job_date_time = moment(response.data.job.job_date_time).format('yyyy-MM-DD')
            //             // }else{
            //             //     vm.project.job_date_time = moment().format('yyyy-MM-DD')
            //             // }
            //             // vm.show = true
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //         });
            // },
        },
    };
</script>
<style scoped>
    .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        z-index: 0;
        background-color: #fff !important;
        background-attachment: fixed;
    }

    .td1 {
        width: 100%;
        min-width: 200px;
        max-width: 200px;
        left: -1px;
        z-index: 1;
        background-color: #fff !important;
        text-wrap: wrap !important;
    }

    .td2 {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        left: 198px;
        z-index: 1;
        background-color: #fff !important ;
        background-attachment: fixed;
    }

    .td3 {
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        left: 267px;
        z-index: 1;
        background-color: #fff !important;
        background-attachment: fixed;
    }
    .td3-1 {
        width: 100px;
        min-width: 80px;
        max-width: 80px;
        left: 267px;
        z-index: 1;
        background-color: #fff !important;
        background-attachment: fixed;
    }

    .td4 {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        left: 0px;
    }
    .justify-content-space-between {
        justify-content: space-between;
    }

    .form-control {
        width: -webkit-fill-available;
    }
    .form-control1 {
        /* width: 100% !important; */
        width: 4rem;
        display: block !important;
    }

    .form-control2 {
        box-sizing: border-box;
        width: -webkit-fill-available !important;
        display: block;
    }

    .table-bordered > :not(caption) > * > * {
        border-width: 0 1px !important;
    }

    @media only screen and (max-width: 496px) {
        .display-content {
            display: contents !important;
        }
    }
</style>
