<template>
	<div class="">
		<div class="d-flex justify-content-between">
			<h2 class="main-title mb-3">Belt Conveyor</h2>
			<router-link to="/belt_conveyor/create" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-add-line"></i>
				ADD BELT CONVEYOR</router-link>
		</div>
		<div class="card">
			<div class="card-header">
				<strong>Belt Conveyors</strong>
			</div>
			<div class="card-body">
				<div class="form-group mb-2 d-flex">
					<input class="form-control form-control-sm" type="text" placeholder="Type keyword and press enter key"
						v-model="meta.search" @keypress.enter="search()" />
					<a @click="deleteValues()" v-if="delete_values != 0" href="javascript:void(0)"
						class="btn btn-sm btn-primary ms-2 "><i class="ri-delete-bin-line icon_hgt"></i></a>
				</div>
				<div class="table-responsive table-responsive-sm">
					<table class="table table-sm text-nowrap table-bordered mb-0">
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th @click="sort('conveyor')">Conveyor
									<span>
										<i v-if="meta.keyword == 'conveyor' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'conveyor' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('civil_drawing')">Civil Drawing
									<span>
										<i v-if="meta.keyword == 'civil_drawing' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'civil_drawing' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('mech_drawing')">Mech. Drawing
									<span>
										<i v-if="meta.keyword == 'mech_drawing' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'mech_drawing' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('no_of_foundation')">No of Foundation
									<span>
										<i v-if="meta.keyword == 'no_of_foundation' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'no_of_foundation' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('foundation_casting_done')">Foundation Casting Done
									<span>
										<i v-if="meta.keyword == 'foundation_casting_done' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'foundation_casting_done' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('no_of_trestle')">No of Trestle
									<span>
										<i v-if="meta.keyword == 'no_of_trestle' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'no_of_trestle' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
                                <th @click="sort('trestle_fab_done')">Trestle Fab. Done
									<span>
										<i v-if="meta.keyword == 'trestle_fab_done' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'trestle_fab_done' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
                                <th @click="sort('trestle_erect_done')">Trestle Erect. Done
									<span>
										<i v-if="meta.keyword == 'trestle_erect_done' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'trestle_erect_done' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
                                <th @click="sort('no_of_gallery')">No of Gallery
									<span>
										<i v-if="meta.keyword == 'no_of_gallery' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'no_of_gallery' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
                                <th @click="sort('gallery_fab_done')">Gallery Fab. Done
									<span>
										<i v-if="meta.keyword == 'gallery_fab_done' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'gallery_fab_done' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
                                <th @click="sort('gallery_erect_done')">Gallery Erect. Done
									<span>
										<i v-if="meta.keyword == 'gallery_erect_done' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'gallery_erect_done' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th class="text-center">Status</th>
								<th class="text-center">Actions</th>
								<th class="text-center"><input type="checkbox" :checked="isAllSelected"
										@change="selectAll" /></th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="conveyors.length == 0">
								<td colspan="8" class="text-center">No records found</td>
							</tr>
							<tr v-for="(conveyor, key) in conveyors" :key="key">
								<td class="text-center">{{ meta.from + key }}</td>
								<td>{{ conveyor.conveyor }}</td>
								<td>{{ conveyor.civil_drawing }}</td>
								<td>{{ conveyor.mech_drawing }}</td>
								<td>{{ conveyor.no_of_foundation }}</td>
								<td>{{ conveyor.foundation_casting_done }}</td>
								<td>{{ conveyor.no_of_trestle}}</td>
                                <td>{{ conveyor.trestle_fab_done }}</td>
                                <td>{{ conveyor.trestle_erect_done }}</td>
                                <td>{{ conveyor.no_of_gallery}}</td>
                                <td>{{ conveyor.gallery_fab_done }}</td>
                                <td>{{ conveyor.gallery_erec_done }}</td>
								<td class="text-center">
									<div class="form-switch form-switch">
										<input class="form-check-input" type="checkbox" role="switch"
											:id="'conveyor' + conveyor.belt_conveyor_id" :checked="conveyor.status" :value="conveyor.status"
											@change="deleteConveyor(conveyor)" />
										<label class="custom-control-label" :for="'conveyor' + conveyor.belt_conveyor_id"></label>
									</div>
								</td>
								<td class="text-center">
									<nav>
										<a href="javascript:void(0)" class="text-success me-2" @click.prevent="edit(conveyor)"
											:disabled="!conveyor.status"><i class="ri-pencil-line icon_hgt"></i></a>
									</nav>
								</td>
								<td class="text-center"><input type="checkbox" v-model="delete_values" :value="conveyor.belt_conveyor_id"
										@change="select" /></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex justify-content-between align-items-center">
					<select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
						<option>10</option>
						<option>15</option>
						<option>20</option>
						<option>25</option>
						<option>30</option>
					</select>
					<span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
					<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
						@pagechanged="onPageChange" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
	components: { Pagination },
	data() {
		return {
			meta: {
				search: "",
				order_by: "asc",
				keyword: "belt_conveyor_id",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				to: 1,
				maxPage: 1,
				trashed: false,
			},
			isAllSelected: false,
			delete_values: [],
			conveyors: [],
			errors: [],
		};
	},
	mounted() {
		if(this.$store.getters?.user){
            this.index();
        }
	},
	methods: {
		search() {
			let vm = this;
			vm.meta.page = 1;
			vm.index();
		},
		index() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "paginateBeltConveyors", data: vm.meta })
				.then(function (response) {
					vm.conveyors = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		edit(conveyor) {
			this.$router.push("/belt_conveyors/" + conveyor.belt_conveyor_id + "/edit");
		},

		deleteConveyor(conveyor) {
			let vm = this;
			let loader = vm.$loading.show();
			conveyor.status = conveyor.status == 1 ? 0 : 1;
			console.log('conveyor:---', conveyor)
			vm.$store
				.dispatch("post", { uri: "deleteBeltConveyor", data: conveyor })
				.then(function (response) {
					loader.hide()
					vm.$store.dispatch("success", response.data.message);
					vm.index();
				})
				.catch(function (error) {
					loader.hide()
					vm.errors = error.response.data.error;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		confirmDelete(conveyor) {
			this.confirm.conveyor = conveyor.conveyor;
			this.confirm.belt_conveyor_id = conveyor.belt_conveyor_id;
		},

		trash(conveyor) {
			let vm = this;
			if (confirm("Do you want to delete this entry ?")) {
				let loader = vm.$loading.show();
				vm.$store
					.dispatch("post", { uri: "forceDeleteBeltConveyor", data: conveyor })
					.then(function () {
						loader.hide();
						vm.$store.dispatch("success", "Record deleted successfully");
						vm.index();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},
		selectAll() {
			if (this.isAllSelected) {
				this.delete_values = [];
				this.isAllSelected = false;
			} else {
				this.delete_values = [];
				for (var conveyor in this.conveyors) {
					this.delete_values.push(this.conveyors[conveyor].belt_conveyor_id);
				}
				this.isAllSelected = true;
			}
		},
		select() {
			if (this.delete_values.length !== this.conveyors.length) {
				this.isAllSelected = false;
			} else {
				this.isAllSelected = true;
			}
		},
		deleteValues() {
			confirm("Are You Sure Want to delete?");
			let vm = this;
			let loader = vm.$loading.show();
			vm.$store.dispatch("post", { uri: "multipleDeleteBeltConveyors", data: { belt_conveyor_id: this.delete_values } })
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Belt Conveyor is successfully deleted.");
					vm.isAllSelected = false
					vm.delete_values = []
					vm.index();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		onPageChange(page) {
			this.meta.page = page;
			this.index();
		},
		sort(field) {
			this.meta.keyword = field;
			this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
			this.index();
		},
		onPerPageChange() {
			this.meta.page = 1;
			this.index();
		},
	},
};
</script>



