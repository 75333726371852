<template>
    <div>
        <div :class="{ bold: isFolder }">
            <div class="row">
                <span v-if="isFolder" @click="toggle" class="col-sm-1" style="width: 50px; padding-right:0px; margin-right: 0px;">[{{ isOpen ? '-' : '+' }}]</span>
                <span v-else @click="toggle" class="col-sm-1" style="width: 50px; padding-right:0px; margin-right: 0px;"><i class="ri-focus-line"></i></span>
                
                <span v-if="item?.table_data?.update || item?.table_data?.create" class="col-sm-3" style="margin-bottom: 10px;">
                    <!-- &nbsp; -->
                    <!-- <select v-model="item.table_data.id">
                        <option value="">{{item.table_data.title}} </option>
                        <option v-for="(data, key) in item.table_data.module_data" :key="key" :value="data[item.table_data.field_id]">{{ data[item.table_data.field_name] }}</option>
                    </select> -->
                    <search
                        :initialize="item.table_data.id"
                        :id="item.table_data.field_id"
                        :label="item.table_data.field_name"
                        :placeholder="item.table_data.title"
                        :data="item.table_data.module_data"
                        ref="section_group_id"
                        @input="data => item.table_data.id = data"
                    >
                    </search>
                </span>
                <span @click="toggle" v-else class="col-sm-2">&nbsp;{{ item?.name }}</span>&emsp;
                
                <span class="col-sm-2" v-if="(item?.table_data?.update || item?.table_data?.create) && (item?.table_data.add_url=='addProjectActivity' || item?.table_data.update_url=='updateProjectActivity')">
                    <!-- &nbsp; -->
                    <!-- <select v-model="item.table_data.unit_id">
                        <option value="">Select Unit </option>
                        <option v-for="(unit, key) in item.table_data.units" :key="key" :value="unit.unit_id">{{ unit.unit_name }}</option>
                    </select> -->
                    <search
                        :initialize="item.table_data?.unit_id"
                        :id="'unit_id'"
                        :label="'unit_name'"
                        :placeholder="'Select Unit'"
                        :data="item.table_data.units"
                        ref="unit_id"
                        @input="unit => item.table_data.unit_id = unit"
                    >
                    </search>
                </span>&emsp;

                <span class="col-sm-2" style="margin-bottom: 10px;" v-if="(item?.table_data?.update || item?.table_data?.create) && (item?.table_data.add_url=='addProjectActivity' || item?.table_data.update_url=='updateProjectActivity')"><input class="form-control search-selected" type="text" v-model="item.table_data.scope" placeholder="Scope"></span>
                
                <span class="col-sm-1" style="width: 20px" v-if="(item?.table_data?.update || item?.table_data?.create) && (item?.table_data.add_url=='addProjectActivity' || item?.table_data.update_url=='updateProjectActivity')">&emsp;</span>
                
                <span class="col-sm-1" style="width: 20px " v-if="item.table_data?.duplicate">
                    <a href="javascript:void(0)" class="text-success me-1" title="Duplicate" v-if="item?.table_data?.duplicate" @click="duplicate(item)"><i class="ri-file-copy-line icon_hgt"></i></a>
                </span>
                
                <span class="col-sm-1" style="width: 20px" v-if="item.table_data?.add">
                    <a href="javascript:void(0)" class="text-success me-1" title="add" v-if="!item?.table_data?.update" @click="addItem(item)"><i class="ri-add-circle-line icon_hgt"></i></a>
                </span>
                <span class="col-sm-1" style="width: 20px" v-if="item.table_data?.edit">
                    <a href="javascript:void(0)" class="text-waring me-1" title="edit" v-if="!item?.table_data?.update" @click="editItem(item)"><i class="ri-edit-line icon_hgt"></i></a>
                </span>
                <span class="col-sm-1" style="width: 20px" v-if="item.table_data?.update">
                    <a href="javascript:void(0)" class="text-success me-1" title="update" v-if="item?.table_data?.update" @click="updateItem(item)"><i class="ri-refresh-line icon_hgt"></i></a>
                </span>
                <span class="col-sm-1" style="width: 20px" v-if="item.table_data?.create">
                    <a href="javascript:void(0)" class="text-success me-1" title="add" v-if="item?.table_data?.create" @click="createItem(item)"><i class="ri-refresh-line icon_hgt"></i></a>
                </span>
                <span class="col-sm-1" style="width: 20px" v-if="item.table_data?.create">
                    <a href="javascript:void(0)" class="text-danger" title="discard" @click="discardCreate()"><i class="ri-close-line icon_hgt"></i></a>
                </span>
                <span class="col-sm-1" style="width: 20px" v-if="item.table_data?.delete_url && !item.table_data?.update">
                    <a href="javascript:void(0)" class="text-danger" title="delete" @click="deleteItem(item)"><i class="ri-eraser-line icon_hgt"></i></a>
                </span>
                <span class="col-sm-1" style="width: 20px" v-if="item.table_data?.update">
                    <a href="javascript:void(0)" class="text-danger" title="discard" @click="discard(item)"><i class="ri-close-line icon_hgt"></i></a>
                </span>
            </div>
        </div>
        <ul v-show="isOpen" v-if="isFolder">
            <project-update class="item" v-for="(child, index) in item.children" :key="index" :item="child" @removeChild="removeChild" @updateChild="updateChild" />
        </ul>
    </div>
</template>
<script>
    let Search = require("@/components/Search.vue").default;
    export default {
        components: { Search },
        props: {
            item: Object,
            edit: Boolean 
        },
        data: function () {
            return {
                isOpen: false,
                item_status: false,
                status: true,
                section: {},
                module_data:[],
                activity:{}
            };
        },
        computed: {
            isFolder: function () {
                return this.item.children_length;
            },
        },
        mounted() {
            // console.log(this.item)
        },
        methods: {
            duplicate(item){
                let duplicate_data = {}
                let vm = this
                let loader = vm.$loading.show()
                console.log('item.table_data.duplicate_url:----',item)
                item.table_data.parents.map(function(element){
                    duplicate_data[element.parent_key] = element.parent_value
                })
                duplicate_data[item.table_data.field_id] = item.table_data.id
                duplicate_data[item.table_data.primary_key] = item[item.table_data.primary_key]
                if(item?.template_parameter_type_id){
                    duplicate_data['template_parameter_type_id'] = item.template_parameter_type_id
                }
                
                console.log('item.table_data.duplicate_url:----',item.table_data.duplicate_url)
                let uri = { uri: item.table_data.duplicate_url, data: duplicate_data };
                vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        loader.hide()
                        console.log('response:--duplicate:----',response)
                        vm.$emit("updateChild");
                        // vm.$store.dispatch("setTemplateItem", response.data.data);
                        item.table_data.duplicate = false
                        vm.$store.dispatch("success", response.data.message);
                    })
                    .catch(function (error) {
                        loader.hide()
                        console.log(error)
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            updateChild(children){
                console.log('parent item')
                console.log(this.item)
                this.toggle()
                this.isOpen = true
            },
            updateItem(item) {
                console.log("item1",item);
                let update_data = {};
                let vm = this;
                item.table_data.parents.map(function (element) {
                    console.log("element1",element);
                    update_data[element.parent_key] = element.parent_value;
                });
                // activity name
                update_data[item.table_data.field_id] = item.table_data.id;

                // project activity
                update_data[item.table_data.primary_key] = item[item.table_data.primary_key];

                // project id
                update_data.project_id = item.project_id;

                update_data.template_code = item.table_data.template_code;
                update_data.unit_id = item.table_data.unit_id;
                update_data.scope = item.table_data.scope
                let uri = { uri: item.table_data.update_url, data: update_data };
                console.log("update_data ",update_data )
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        console.log("!!!!!!response",response)
                        
                        vm.$emit("updateChild");
                        console.log("updatedItem",item)
                        item.table_data.update = false;
                        console.log("update activiy after",item[item.table_data.primary_key])
                        vm.$store.dispatch("success", response.data.message);
                    })
                    .catch(function (error) {
                        console.log(error);
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

        addItem(item){
            console.log(item)
            this.isOpen = true
            let vm = this
            item.table_data.add_item[item.table_data.primary_key] = item[item.table_data.primary_key]
            let uri = { uri: item.table_data.add_item.get_url, data: '' };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    console.log(response)
                    item.table_data.add_item.module_data = response.data.data
                })
                .catch(function (error) {
                    console.log(error)
                });
            item.table_data.add_item.edit = false
            if(item.table_data.add_item.get_url == 'getActivities'){
                let uri = { uri: 'getUnits', data: '' };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    console.log(response)
                    item.table_data.add_item.units = response.data.data
                })
                .catch(function (error) {
                    console.log(error)
                });
            }
            item.children.push({
                table_data: item.table_data.add_item
            })
            item.children_length = item.children.length
            console.log(item)
        },

            createItem(item) {
                console.log(item.table_data);
                let create_data = {};
                let vm = this;
                create_data.project_id = item.table_data.project_id;
                create_data[item.table_data.parent_id] = item.table_data[item.table_data.parent_id];
                create_data[item.table_data.field_id] = item.table_data.id;
                create_data.unit_id = item.table_data.unit_id;
                create_data.scope = item.table_data.scope
                item.table_data.parents.map(function (element) {
                    create_data[element.parent_key] = element.parent_value;
                });
                console.log(create_data);
                let uri = { uri: item.table_data.add_url, data: create_data };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.$emit("updateChild");
                        item.table_data.update = false;
                        vm.$store.dispatch("success", response.data.message);
                    })
                    .catch(function (error) {
                        console.log(error);
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getUnits() {
                let vm = this;
                let uri = { uri: "getUnits", data: "" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.units = response.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            editItem(item) {
                console.log("edititem");
                console.log(item);
                console.log("update url",item.table_data.update_url);

                item.table_data.duplicate = false
                if (!item.table_data.create) item.table_data.update = true;
                if (item.table_data.add_url == "addProjectActivity" || item.table_data.update_url == "updateProjectActivity"){
                    item.table_data.units=[]
                    this.getUnits();
                }
                let vm = this;
                let uri = { uri: item.table_data.get_url, data: "" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        console.log(response);
                        vm.item.table_data.module_data = response.data.data;
                        item.table_data.id = item.edit_id
                        item.table_data.unit_id = item.unit_id
                        item.table_data.scope = item.scope
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                if(item.table_data.get_url == 'getActivities'){
                    let uri = { uri: 'getUnits', data: '' };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        console.log(response)
                        item.table_data.units = response.data.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    });
                }
            },
            discard(item) {
                item.table_data.update = false;
                item.table_data.duplicate = true;
            },
            discardCreate() {
                this.$emit("removeChild");
            },
            removeChild() {
                this.item.children.pop();
            },
            deleteItem(item) {
                let delete_data = {};
                let vm = this;
                delete_data[item.table_data.primary_key] = item[item.table_data.primary_key];
                delete_data.project_id = item.project_id;
                let uri = { uri: item.table_data.delete_url, data: delete_data };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.$emit("updateChild");
                        vm.$store.dispatch("success", response.data.message);
                    })
                    .catch(function (error) {
                        console.log(error.response.data);
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            toggle: function () {
                console.log(this.item)
                let child_obj = {}
                let vm = this
                // if(this.item.table_data.child_url && !this.item.children.length){
                    if(this.item.table_data.child_url){
                    child_obj[this.item.table_data.parent_id] = this.item[this.item.table_data.parent_id]
                    console.log('test:--',child_obj)
                    let uri = { uri: this.item.table_data.child_url, data: child_obj };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.item.children = response.data.data
                        })
                        .catch(function (error) {
                            console.log(error.response.data)
                        });
                }
                if(this.item.module == 'project_activity_id'){
                    this.activity.project_activity_id = this.item.project_activity_id
                    this.activity.module = this.item.module
                    console.log(this.activity)
                    // this.getProjectParameterTypes()
                }
                if (this.isFolder) {
                    this.isOpen = !this.isOpen;
                }
            },
            // getProjectParameterTypes(){
            //     let vm = this                
            //     let uri = { uri: 'getProjectParameterTypes', data: vm.activity };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             vm.item.children = response.data.data
            //         })
            //         .catch(function (error) {
            //             console.log(error.response.data)
            //         });
            // },
            makeFolder: function () {
                if (!this.isFolder) {
                    this.$emit("make-folder", this.item);
                    this.isOpen = true;
                }
            },

            //dropdown setup
            getUpdateDropdownList(item){
                let vm = this;
                this.$store.dispatch('post',{ uri: item.getUrl})
                .then(response => {
                    vm.dropdown_list = response.data.data;
                    vm.status = false;
                })
                .catch(error => {
                    console.log(error);
                })
            }
        },
    };
</script>
<style scoped>
.item {
    cursor: pointer;
}

.catalog-code {
    display: inline-flex;
    justify-content: space-between;
    flex-shrink: 0;
    min-width: 65px;
    height: 17px;
    font-size: 12px;
    background-color: #ececec;
    transition-property: color, background-color;
    transition-duration: 0.3s;
    cursor: default;
}

/* govshop css */
.cv-catalog-list-item__fake-checkbox:before {
    top: 1px;
    left: 0;
    width: 13px;
    height: 13px;
    border: 1px solid #cfd5e0;
    border-radius: 1px;
    transition-property: background-color, border-color;
    transition-duration: 0.3s;
}

.catalog-filter-modal__content .catalog-list-wrap {
    flex: 1 1;
}

.catalog-list-select-all {
    display: inline-block;
    position: relative;
    padding-left: 21px;
    margin-bottom: 10px;
}

.catalog.has-scrollbar .catalog-list {
    padding-bottom: 24px;
}

.catalog-filter-modal__content .catalog-list {
    padding-bottom: 20px;
}

.cv-catalog-list {
    padding-bottom: none;
}

.catalog-list-select-all__checkbox {
    display: none;
}

.catalog-list-select-all__button.button {
    position: static;
    cursor: pointer;
}

.button-text {
    border-bottom: 1px dotted var(--accent);
    color: var(--accent);
    padding: 0;
    transition-property: color, border-color;
    transition-duration: 0.3s;
}

.button_weight-regular {
    font-weight: 400;
}

.cv-catalog-list-item.has-checkboxes {
    padding-left: 41px;
}

.cv-catalog-list-item {
    position: relative;
    display: flex;
    padding-left: 20px;
    margin-bottom: 5px;
}

.cv-catalog-list-item__label-checkbox {
    display: inline-block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 13px;
    height: 13px;
    cursor: pointer;
}

.cv-catalog-list-item.has-checkboxes .cv-catalog-list-item__expand-button {
    left: 21px;
}

.cv-catalog-list-item__expand-button {
    position: absolute;
    top: 6px;
    left: 0;
}

.cv-expand-button {
    position: relative;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1px solid #9b9b9b;
    background: none;
    background-color: transparent;
    cursor: pointer;
    line-height: 1;
}

.cv-expand-button svg {
    width: 5px;
    height: 5px;
    line-height: 1;
    fill: #6b6b6b;
}

.icon {
    width: 1em;
    height: 1em;
}

.cv-catalog-list-item__code {
    margin-right: 8px;
}

.hovertext:hover {
    color: #40a4f1 !important;
}

.ex3 {
    background-color: lightblue;

    height: 110px;
    overflow: auto;
}

.cv-catalog-list-item__fake-checkbox:before {
    top: 1px;
    left: 0;
    width: 13px;
    height: 13px;
    border: 1px solid #cfd5e0;
    border-radius: 1px;
    transition-property: background-color, border-color;
    transition-duration: 0.3s;
}

.cv-catalog-list-item__title,
.cv-catalog-list-item__link {
    color: rgb(89, 93, 110);
    font-size: 13px;
}
.search-selected {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /* line-height: 15px; */
}
</style>

