<template>
    <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>{{report_section}}</strong>
            </div>

            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-4">
                        <label class="form-label">Project Group</label>
                        <select class="form-select form-select-sm" :class="{'is-invalid': errors.project_group_id}" v-model="overall_summary.project_group_id" @change="getProjects()">
                            <option value="">Select Project Group</option>
                            <option v-for="project_group, key in project_groups" :key="key" :value="project_group.project_group_id">{{ project_group.project_group_name}} </option>
                        </select>
                        <span v-if="errors.project_group_id" class="invalid-feedback">{{ errors.project_group_id[0] }}</span>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Section</label>
                        <select class="form-select form-select-sm" :class="{'is-invalid': errors.section_id}" v-model="overall_summary.section_id" disabled>
                            <option value="">Select Section</option>
                            <option v-for="section, key in sections" :key="key" :value="section.section_id">{{ section.section_name}} </option>
                        </select>
                        <span v-if="errors.section_id" class="invalid-feedback">{{ errors.section_id[0] }}</span>
                    </div>
                    <div class="col-md-4" style="margin-top: 20px;" >
                        <br />
                        <button class="btn btn-sm btn-primary me-2" @click="getOverallReport()" :disabled="overall_summary.project_group_id == '' || overall_summary.section_id== ''"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <a target="blank" class="btn btn-primary btn-sm" @click="generatePDF()">
                            <i class="ri-save-line icon_hgt"></i> Generate PDF
                        </a>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap table-bordered mb-0">
                                <thead class="bg-primary bg-opacity-50" >
                                    <tr>
                                        <th class="text-center">Package</th>
                                        <th class="text-center" v-for="project, key in projects" :key="key">{{project.project_name}}</th>
                                    </tr>
                                </thead>
                                <tbody v-if="overall_summary.overall_report.length">
                                    <tr v-for="report, key in overall_summary.overall_report" :key="key">
                                        <td>{{ report.package_name }}</td>
                                        <td v-for="ors, key in report.overall_report_status" :key="key">
                                            <table>
                                                <tr>
                                                    <td>
                                                        <select class="form-control form-control-sm me-2" v-model="ors.status">
                                                            <option v-for="option in options" :key="option" :value="option" :selected="option === ors.status">{{ option }}</option>
                                                        </select>
                                                    </td>
                                                    <td v-if="ors.status == 'Not Applicable' || ors.status == 'Yet To Start'">
                                                        <input type="text" class="form-control form-control-sm" :value="ors.percentage = '0.00'" readonly placeholder="Percentage">
                                                    </td>
                                                     <td v-else>
                                                        <input type="text" class="form-control form-control-sm" v-model="ors.percentage" placeholder="Percentage">
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="packagea, key in packages" :key="key">
                                        <td>{{ packagea.package_name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end">
                <!-- <button class="btn btn-danger btn-sm me-2" @click.prevent="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button> -->
                <button class="btn btn-primary btn-sm" @click="submit()"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OverallEngineering",
        data() {
            return {
                overall_summary:{
                    project_group_id: '',
                    section_id: '',
                    overall_report:[]
                },
                report_section:'',
                project_groups: [],
                sections: [],
                projects: [],
                packages: [],
                errors: [],
                overall_report_status: [],
                options: ["Not Applicable", "Yet To Start", "On Track", "In Progress", "Completed"],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.report_section = to.name
                vm.getPackages()
                vm.overall_summary.overall_report = []
            });
        },
        methods: {
            getPackages() {
                let vm = this
                if(localStorage.getItem('project_group_id')){
                    vm.overall_summary.project_group_id = localStorage.getItem('project_group_id')
                } 
                vm.$store
                    .dispatch("post", { uri: "getPackages", data: vm.overall_summary })
                    .then(function (response) {
                        vm.packages = response.data.data
                        vm.getProjectGroups()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getProjectGroups() {
                let vm = this;
                
                vm.$store
                    .dispatch("post", { uri: "getProjectGroups" })
                    .then(function (response) {
                        vm.project_groups = response.data.data;
                        vm.getSections();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getSections() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "getSections" })
                    .then(function (response) {
                        loader.hide();
                        let sections = response.data.data;
                        vm.sections = sections.filter(function (element) {
                            if (vm.report_section == 'Overall Engineering' && element.section_name == "Engineering"){
                                vm.overall_summary.section_id = element.section_id
                            }
                            if (vm.report_section == 'Overall Procurement' && element.section_name == "Procurement"){
                                vm.overall_summary.section_id = element.section_id
                            }
                            if (vm.report_section == 'Overall Delivery' && element.section_name == "Delivery"){
                                vm.overall_summary.section_id = element.section_id
                            }
                            return element.section_name == "Engineering" || element.section_name == "Procurement" || element.section_name == "Delivery";
                        });
                        vm.getProjects()
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getProjects() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.overall_summary.overall_report = []
                vm.errors = [];
                vm.$store
                    .dispatch("post", { uri: "getProjectsByProjectGroup", data: vm.overall_summary })
                    .then(function (response) {
                        loader.hide();
                        vm.projects = response.data
                        if(vm.projects.length && vm.packages.length){
                            vm.getOverallReport()
                        }
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            submit() {
                let vm = this;
                if(vm.projects.length){
                    let loader = vm.$loading.show()
                    vm.$store
                        .dispatch("post", { uri: "addOverallReport", data:vm.overall_summary})
                        .then(function (response) {
                            loader.hide();
                            vm.$store.dispatch("success", "Status updated successfully!")
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors
                            vm.$store.dispatch("error", error.response.data.message)
                        });
                }else{
                    vm.$store.dispatch("info", 'No projects in this group');
                }
            },

            getOverallReport() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "getOverallReport", data: vm.overall_summary })
                    .then(function (response) {
                        loader.hide()
                        vm.overall_summary.overall_report = response.data.data
                    })
                    .catch(function (error) {
                        loader.hide()
                        console.log(error)
                    });
            },
            generatePDF() {
                let vm = this;
                if (vm.overall_summary.project_group_id == '') {
                    vm.errors.project_group_id = ["Please select Project Group"];
                }else{
                    delete vm.errors.project_group_id
                }
                if (vm.overall_summary.section_id == '') {
                    vm.errors.section_id = ["Please select section"];
                }else{
                    delete vm.errors.section_id
                }
                if(vm.projects.length <= 0){
                    vm.$store.dispatch("info", 'No projects in this group');
                }
                if (vm.overall_summary.project_group_id != '' && vm.overall_summary.section_id != '' && vm.projects.length) {
                    window.open(vm.$store.state.apiUrl+'getOverallReportPdf?project_group_id=' + vm.overall_summary.project_group_id + '&section_id=' + vm.overall_summary.section_id)
                }
            },
        },
    };
</script>

<style scoped>
    .justify-content-space-around {
        justify-content: space-around;
    }
    .f1 {
        font-size: 9pt;
        font-weight: bold;
    }
    .f2 {
        font-size: 10pt;
    }
    .fontsize {
        font-size: 16px;
        font-weight: bold;
    }
    .card-one {
        border: 1px solid #e2e5ec;
        left: 10%;
    }
      .form-control {
        width: -webkit-fill-available;
    }
</style>
