<template>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <strong style="font-size:medium;"> Activity Groups Permission Control </strong>
                </div>
                <div class="card-body">
                    <div class="row g-3 mb-4">
                        <div class="col-md-12">
                            <div class="table-responsive table-responsive-sm">
                                <table class="table table-sm text-wrap table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th class="text-center">Activity Groups</th>
                                            <th class="text-center">
                                                Action
                                                <input type="checkbox" :checked="isAllSelected" @change="selectAll" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="activity_group, key in activity_groups" :key="key">
                                            <td class="text-center fs-12">{{ key + 1 }}</td>
                                            <td class="text-right fs-12">{{ activity_group.activity_group_name }}</td>
                                            <td class="text-center fs-12">
                                                <input class="me-1" type="checkbox" v-model="project.activity_group_ids"
                                                    :value="activity_group.activity_group_id" @change="select()" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button class="btn btn-danger btn-sm me-2" @click.prevent="discard()"><i
                            class="ri-close-line icon_hgt"></i> DISCARD</button>
                    <button class="btn btn-primary btn-sm" @click="addActivityGroups()"><i
                            class="ri-save-line icon_hgt"></i> SUBMIT</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            errors: [],
            activity_groups: [],
            project: {
                project_id: '',
                user_id: '',
                activity_group_ids: [],
            },
            isAllSelected: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.project.project_id = to.params.project_id
            vm.project.user_id = to.params.user_id
            if(this.$store.getters?.user){
                vm.getSelectedActivityGroups();
            }
            
        });
    },
    mounted() {
        if(this.$store.getters?.user){
            this.getActivityGroups();
            this.getSelectedActivityGroups();
        }
        
    },
    methods: {
        getActivityGroups() {
            let vm = this;
            this.$store.dispatch('post', { uri: 'getActivityGroups' })
                .then(response => {
                    vm.activity_groups = response.data.data;
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        getSelectedActivityGroups() {
            let vm = this;
            this.$store.dispatch('post', { uri: 'getUserActivityGroups', data: vm.project })
                .then(response => {
                    this.project.activity_group_ids = response.data;
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        addActivityGroups() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "addActivityGroupUsers", data: vm.project };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "Activity Groups added successfully!");
                    vm.$router.push("/project/" + vm.project.project_id + "/assign_user");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        discard() {
            let vm = this
            vm.errors = []
            vm.project.activity_group_ids = []
        },

        selectAll() {
            if (this.isAllSelected) {
                this.project.activity_group_ids = [];
                this.isAllSelected = false;
            }
            else {
                this.project.activity_group_ids = [];
                this.activity_groups.forEach(ele => {
                    this.project.activity_group_ids.push(ele.activity_group_id);
                });
                this.isAllSelected = true;
            }
        },

        select() {
            if (this.activity_groups.length !== this.project.activity_group_ids.length) {
                this.isAllSelected = false;
            } else {
                this.isAllSelected = true;
            }
        },
    }

}
</script>
<style scoped>
    tr:nth-child(even) {background-color: #f2f2f2;}
</style>