<template>
    <div class="">
        <h2 class="main-title mb-3">Hindrance Report</h2>
        <div class="row g-3">
            <div class="col-md-4">
                <form @submit.prevent="submitForm()">
                    <div class="card">
                        <div class="card-header">
                            <strong v-if="status">New Hindrance Report</strong>
                            <strong v-else>Update Hindrance Report</strong>
                        </div>
                        <div class="card-body mb-3">
                            <div class="row g-3">
                                <div class="col-sm-12">
                                    <label class="form-label">Project Types</label><span class="text-danger"> *</span>

                                    <select class="form-control" v-model="plan_parameter.project_type_id">
                                        <option value="">Select Project Type</option>
                                        <option v-for="project_type, key in project_types" :key="key" :value="project_type.project_type_id">{{project_type.project_type_name }}</option>
                                    </select>
                                </div>
                                <div class="col-sm-12">
                                    <label class="form-label">Activity</label><span class="text-danger"> *</span>

                                    <select class="form-control" v-model="plan_parameter.activity_id">
                                        <option value="">Select Activity</option>
                                        <option v-for="activity, key in activities" :key="key" :value="activity.activity_id">{{activity.activity_name }}</option>
                                    </select>
                                </div>
                                <div class="col-sm-12">
                                    <label class="form-label">Month</label><span class="text-danger"> *</span>
                                    <input type="text"  class="form-control"  v-model="plan_parameter.month" />
                                    <!-- <span v-if="errors.date_time" class="invalid-feedback">{{ errors.date_time[0] }}</span> -->
                                </div>
                                <div class="col-sm-12">
                                    <label class="form-label">Month Value</label><span class="text-danger"> *</span>
                                    <input type="text"  class="form-control"  v-model="plan_parameter.month_value" />
                                    <!-- <span v-if="errors.date_time" class="invalid-feedback">{{ errors.date_time[0] }}</span> -->
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-end">
                            <button type="reset" class="btn btn-danger me-2" @click="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                            <button type="submit" class="btn btn-primary" v-if="status"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
                            <button type="submit" class="btn btn-primary" v-else><i class="ri-save-line icon_hgt"></i> UPDATE</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">
                        <strong>Plan Parameter</strong> 
                    </div>
                    <div class="card-body">
                        <div class="form-group mb-2 d-flex">
                            <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search()" />
                            <a @click="deleteValues()" v-if="delete_values!= 0" href="javascript:void(0)" class="btn btn-sm btn-outline-danger ms-2 "><i class="ri-delete-bin-line icon_hgt"></i></a>
                        </div>
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th @click="sort('date')">
                                            Project Type
                                            <span>
                                                <i v-if="meta.keyword=='date' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword=='date' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th @click="sort('hindrance')">
                                            Activity 
                                            <span>
                                                <i v-if="meta.keyword=='hindrance' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword=='hindrance' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th @click="sort('from_time')">
                                            Month
                                            <span>
                                                <i v-if="meta.keyword=='from_time' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword=='from_time' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th @click="sort('to_time')">
                                            Month Value
                                            <span>
                                                <i v-if="meta.keyword=='to_time' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword=='to_time' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Actions</th>
                                        <th class="text-center"><input type="checkbox" :checked="isAllSelected" @change="selectAll"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="plan_parameters.length==0">
                                        <td colspan="8" class="text-center">No records found</td>
                                    </tr>
                                    <tr v-for="plan_parameter, key in plan_parameters" :key="key">
                                        <td class="text-center">{{meta.from+key}}</td>
                                        <td>{{plan_parameter.project_type?.project_type_name}}</td>
                                        <td>{{plan_parameter.activity?.activity_name}}</td>
                                        <td>{{plan_parameter.month}}</td>
                                        <td>{{plan_parameter.month_value}}</td>
                                        <!-- <td class="text-center">
                                            <div class="form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" :id="'hinder' + hinder.id" :checked="hinder.status" :value="hinder.status" @change="deleteRoleGroup(hinder)"/>
                                                <label class="custom-control-label" :for="'hinder' + hinder.id"></label>
                                            </div>
                                        </td> -->
                                        <td class="text-center">
                                            <!-- <nav>
                                                <a href="javascript:void(0)" class="text-success me-2" @click.prevent="edit(hinder)" :disabled="!hinder.status"><i class="ri-pencil-line icon_hgt"></i></a>
                                                <a href="javascript:void(0)" class="text-danger" @click.prevent="trash(hinder)"><i class="ri-delete-bin-line icon_hgt"></i></a>
                                            </nav> -->
                                        </td>
                                        <td class="text-center">
                                            <!-- <input type="checkbox"  v-model="delete_values" :value="hinder.id" @change="select" /> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                            </select>
                            <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>

                            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    import moment from "moment";
    export default {
        components: { Pagination },
        data() {
            return {
                project_types:[],
                activities:[],
                plan_parameter:{
                    project_type_id:'',
                    activity_id:'',
                    month:'',
                    month_value:''
                },
                plan_parameters:[],
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "plan_parameter_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                project_id:"",
                projects:[],
                isAllSelected: false,
                delete_values: [],
                status: true,
                hindrances: [],
                hindrance_report:[],
                errors: [],
                hindrance: {
                    date_time: "",
                    project_id:"",
                    hindrance_id: "",
                    from_time: "",
                    to_time: "",
                },
            };
        },
        mounted() {      
            if(vm.$store.getters?.user){
                this.getProjectTypes();
            }      
            
            // this.getHindrance();
            // this.index();
            // // const date = moment();
            // this.hindrance.date_time = moment().format("YYYY-MM-DD");
            // this.hindrance.from_time = moment().format("HH:MM");
            // this.hindrance.to_time = moment().format("HH:MM");
        },
        methods: {

            getProjectTypes(){
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getProjectTypes", data: '' })
                    .then(function (response) {
                        vm.project_types = response.data.data
                        vm.getActivities()                       
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getActivities(){
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getActivities", data: '' })
                    .then(function (response) {
                        vm.activities = response.data.data;
                        vm.index()                        
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();                
            },
            getProjects(){
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getProjects", data: '' })
                    .then(function (response) {
                        vm.projects = response.data.data;                        
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addPlanParameters(){
                let vm = this;                
                vm.$store
                    .dispatch("post", { uri: "addPlanParameters", data: vm.plan_parameter  })
                    .then(function () {
                        vm.discard()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            updatePlanParameters(){
                let vm = this;                
                vm.$store
                    .dispatch("post", { uri: "updatePlanParameters", data: vm.plan_parameter  })
                    .then(function () {

                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginatePlanParameters", data: vm.meta })
                    .then(function (response) {
                        vm.plan_parameters = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.addPlanParameters();
                } else {
                    vm.updatePlanParameters();
                }
            },
            addRoleGroup() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addRoleGroup", data: vm.role_group })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Role Group Added successfully");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            edit(role_group) {
                let vm = this;
                vm.$refs.role_group_code.focus();
                vm.errors = [];
                vm.status = false;
                vm.role_group = role_group;
            },
            updateRoleGroup() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateRoleGroup", data: vm.role_group };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Role Group is successfully updated");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
                let vm = this;
                // vm.plan_parameter.project_type_id = ''
                // vm.plan_parameter.activity_id = ''
                vm.plan_parameter.month = ''
                vm.plan_parameter.month_value = ''
                vm.errors = [];
                vm.status = true;
                vm.index();
            },
            deleteRoleGroup(role_group) {
                let vm = this;
                let loader = vm.$loading.show();
                role_group.status = role_group.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deleteRoleGroup", data: role_group })
                    .then(function (response) {
                        loader.hide()
                        vm.$store.dispatch("success", response.data.message);
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            confirmDelete(role_group) {
                this.confirm.role_group_name = role_group.role_group_name;
                this.confirm.role_group_id = role_group.role_group_id;
            },

            trash(role_group) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    vm.$store
                        .dispatch("post", { uri: "forceDeleteRoleGroup", data: role_group })
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Role Group is successfully deleted.");
                            vm.discard();   
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            selectAll() {
                if (this.isAllSelected) {
                    this.delete_values = [];
                    this.isAllSelected = false;
                } else {
                    this.delete_values = [];
                    for (var role_group in this.role_groups) {
                        this.delete_values.push(this.role_groups[role_group].role_group_id);
                    }
                    console.log("s",this.delete_values);
                    this.isAllSelected = true;
                }
            },
            select() {
                if (this.delete_values.length !== this.role_groups.length) {
                    this.isAllSelected = false;
                } else {
                    this.isAllSelected = true;
                }
            },
          
            deleteValues() {
                console.log(this.delete_values);
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "multipleDeleteRoleGroups", data:{role_group_id: this.delete_values}})
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Role Group is successfully deleted.");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
        },
    };
</script>
<!-- ends -->