<template>
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
        <div>
            <ol class="breadcrumb fs-sm mb-1">
                <li class="breadcrumb-item">Dashboard</li>
                <li class="breadcrumb-item active" aria-current="page">Budget</li>
            </ol>
            <h4 class="main-title mb-0">Summary Report</h4>
        </div>
    </div>
    <div class="row g-3 mb-4">
        <div class="col-sm-12">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">Report</h6>
                    <nav class="nav ms-auto">
                        <div class="d-flex">
                            <select class="form-control form-controls-sm mx-2" v-model="currency_unit">
                                <option value="1">in rupees</option>
                                <option value="100000">in lakhs</option>
                                <option value="10000000">in cr</option>
                            </select>
                        </div>
                    </nav>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <select class="form-control form-controls-sm" v-model="report.project_group_id" @change="debouncedChangeProjects">
                                <option value="">Select Project Group</option>
                                <option v-for="project_group in project_groups" :value="project_group.project_group_id" :key="project_group.project_group_id">
                                    {{ project_group.project_group_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-6">
                            <select class="form-control form-controls-sm" v-model="report.project_id" @change="debouncedGetReportLineItems">
                                <option value="">Total</option>
                                <option v-for="project in projects" :value="project.project_id" :key="project.project_id">
                                    {{ project.project_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3" v-if="first_levels.length">
                        <div class="col-sm-12">
                            <div class="table-responsive table-responsive-sm">
                                <table class="table text-nowrap table-sm table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Description</th>
                                            <th class="text-center">
                                                Approved <br />
                                                Budget
                                            </th>
                                            <th class="text-center">
                                                SAP <br />
                                                Commitment
                                            </th>
                                            <th class="text-center">
                                                Manual <br />
                                                Commitment
                                            </th>
                                            <th class="text-center">
                                                Total <br />
                                                Commitment
                                            </th>
                                            <th class="text-center">
                                                Actual <br />
                                                Spent
                                            </th>
                                            <th class="text-center">
                                                Balance <br />
                                                Commitment <br />
                                                in Hand
                                            </th>
                                            <th class="text-center">
                                                Projected <br />
                                                Commitment
                                            </th>
                                            <th class="text-center">Savings</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(level1, i) in first_levels" :key="level1">
                                            <tr class="text-primary">
                                                <td class="text-center">{{ i + 1 }}</td>
                                                <th>{{ level1 }}</th>
                                                <td class="text-right">{{ formatCurrency(level1ApprovedBudget(level1)) }}</td>
                                                <td class="text-right">{{ formatCurrency(level1SapCommit(level1)) }}</td>
                                                <td class="text-right">{{ formatCurrency(level1ManualCommit(level1)) }}</td>
                                                <td class="text-right">{{ formatCurrency(level1TotalCommit(level1)) }}</td>
                                                <td class="text-right">{{ formatCurrency(level1ActualSpent(level1)) }}</td>
                                                <td :class="balanceClass(level1BalanceInHand(level1))">
                                                    {{ formatCurrency(level1BalanceInHand(level1)) }}
                                                    <i v-if="level1BalanceInHand(level1) > 0" class="ri-arrow-up-s-fill"></i>
                                                    <i v-if="level1BalanceInHand(level1) < 0" class="ri-arrow-down-s-fill"></i>
                                                </td>
                                                <td class="text-right">{{ formatCurrency(level1PCommit(level1)) }}</td>
                                                <td :class="balanceClass(level1Savings(level1))">
                                                    {{ formatCurrency(level1Savings(level1)) }}
                                                    <i v-if="level1Savings(level1) > 0" class="ri-arrow-up-s-fill"></i>
                                                    <i v-if="level1Savings(level1) < 0" class="ri-arrow-down-s-fill"></i>
                                                </td>
                                            </tr>
                                            <template v-for="(level2, j) in getSecondLevel(level1)" :key="level2">
                                                <tr v-if="i < 3">
                                                    <td class="text-center"></td>
                                                    <td class="text-right">{{ level2 }}</td>
                                                    <td class="text-right">{{ formatCurrency(level2ApprovedBudget(level1, level2)) }}</td>
                                                    <td class="text-right">{{ formatCurrency(level2SapCommit(level1, level2)) }}</td>
                                                    <td class="text-right">{{ formatCurrency(level2ManualCommit(level1, level2)) }}</td>
                                                    <td class="text-right">{{ formatCurrency(level2TotalCommit(level1, level2)) }}</td>
                                                    <td class="text-right">{{ formatCurrency(level2SapCommit(level1, level2)) }}</td>
                                                    <td :class="balanceClass(level2BalanceInHand(level1, level2))">
                                                        {{ formatCurrency(level2BalanceInHand(level1, level2)) }}
                                                        <i v-if="level2BalanceInHand(level1, level2) > 0" class="ri-arrow-up-s-fill"></i>
                                                        <i v-if="level2BalanceInHand(level1, level2) < 0" class="ri-arrow-down-s-fill"></i>
                                                    </td>
                                                    <td class="text-right">{{ formatCurrency(level2PCommit(level1, level2)) }}</td>
                                                    <td :class="balanceClass(level2Savings(level1, level2))">
                                                        {{ formatCurrency(level2Savings(level1, level2)) }}
                                                        <i v-if="level2Savings(level1, level2) > 0" class="ri-arrow-up-s-fill"></i>
                                                        <i v-if="level2Savings(level1, level2) < 0" class="ri-arrow-down-s-fill"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </tbody>
                                    <tfoot>
                                        <tr class="text-success">
                                            <th colspan="2" class="text-right">Total</th>
                                            <th class="text-right">{{ formatCurrency(totalFirstLevelApprovedBudget) }}</th>
                                            <th class="text-right">{{ formatCurrency(totalFirstLevelSapCommit) }}</th>
                                            <th class="text-right">{{ formatCurrency(totalFirstLevelManualCommit) }}</th>
                                            <th class="text-right">{{ formatCurrency(totalFirstLevelTotalCommit) }}</th>
                                            <th class="text-right">{{ formatCurrency(totalFirstLevelActualSpent) }}</th>
                                            <th class="text-right">{{ formatCurrency(totalFirstLevelBalanceInHand) }}</th>
                                            <th class="text-right">{{ formatCurrency(totalFirstLevelPCommit) }}</th>
                                            <th class="text-right">{{ formatCurrency(totalFirstLevelSavings) }}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from "lodash";

    export default {
        name: "Users.Create",
        data() {
            return {
                report: {
                    project_group_id: "",
                    project_id: "",
                },
                project_groups: [],
                projects: [],
                levels: [],
                first_levels: [],
                currency_unit: 10000000,
            };
        },
        computed: {
            totalFirstLevelApprovedBudget() {
                return this.first_levels.reduce((sum, level1) => sum + this.level1ApprovedBudget(level1), 0);
            },
            totalFirstLevelSapCommit() {
                return this.first_levels.reduce((sum, level1) => sum + this.level1SapCommit(level1), 0);
            },
            totalFirstLevelManualCommit() {
                return this.first_levels.reduce((sum, level1) => sum + this.level1ManualCommit(level1), 0);
            },
            totalFirstLevelTotalCommit() {
                return this.first_levels.reduce((sum, level1) => sum + this.level1TotalCommit(level1), 0);
            },
            totalFirstLevelActualSpent() {
                return this.first_levels.reduce((sum, level1) => sum + this.level1ActualSpent(level1), 0);
            },
            totalFirstLevelBalanceInHand() {
                return this.first_levels.reduce((sum, level1) => sum + this.level1BalanceInHand(level1), 0);
            },
            totalFirstLevelPCommit() {
                return this.first_levels.reduce((sum, level1) => sum + this.level1PCommit(level1), 0);
            },
            totalFirstLevelSavings() {
                return this.first_levels.reduce((sum, level1) => sum + this.level1Savings(level1), 0);
            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getProjectGroups();
                vm.report.project_group_id = to.query.project_group_id || "";
                vm.report.project_id = to.query.project_id || "";
                if (vm.report.project_id) {
                    vm.getProjects();
                }
            });
        },
        methods: {
            getProjectGroups() {
                this.$store
                    .dispatch("post", { uri: "getProjectGroups" })
                    .then((response) => {
                        this.project_groups = response.data.data;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            changeProjects() {
                this.report.project_id = "";
                this.projects = [];
                this.getProjects();
            },
            getProjects() {
                this.$store
                    .dispatch("post", { uri: "getProjectGroupActiveProjects", data: this.report })
                    .then((response) => {
                        this.projects = response.data;
                        this.getReportLineItems();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            getReportLineItems() {
                const loader = this.$loading.show();
                this.$store
                    .dispatch("post", { uri: "budgetSummaryReport", data: this.report })
                    .then((response) => {
                        loader.hide();
                        this.levels = response.data.data;
                        this.first_levels = [...new Set(this.levels.map((level) => level.level1))];
                    })
                    .catch((error) => {
                        loader.hide();
                        console.error(error);
                    });
            },
            getSecondLevel(level1) {
                const second_levels = this.levels.filter((level) => level.level1 === level1 && level.level2 !== null);
                return [...new Set(second_levels.map((level) => level.level2))];
            },
            formatCurrency(amount) {
                const value = amount / this.currency_unit;
                return value.toLocaleString("en-IN", {
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: "INR",
                });
            },
            level1ApprovedBudget(level1) {
                const levels = this.levels.filter((level) => level.level1 === level1);
                return levels.reduce((sum, level) => sum + +level?.report_line_item?.total_budget, 0);
            },
            level1SapCommit(level1) {
                const levels = this.levels.filter((level) => level.level1 === level1);
                return levels.reduce((sum, level) => sum + level?.report_line_item?.repor_line_item_levels.reduce((subSum, item) => subSum + +item?.project_cost?.sap_commit, 0), 0);
            },
            // level1ManualCommit(level1) {
            //     const levels = this.levels.filter((level) => level.level1 === level1);
            //     return levels.reduce((sum, level) => sum + level?.report_line_item?.repor_line_item_levels.reduce((subSum, item) => subSum + +item?.project_cost?.manual_commit, 0), 0);
            // },
            level1ManualCommit(level1) {
                const levels = this.levels.filter((level) => level.level1 === level1);
                return levels.reduce((sum, level) => sum + +level?.report_line_item?.manual_commit, 0);
            },
            level1TotalCommit(level1) {
                return this.level1SapCommit(level1) + this.level1ManualCommit(level1);
            },
            level1ActualSpent(level1) {
                const levels = this.levels.filter((level) => level.level1 === level1);
                return levels.reduce((sum, level) => sum + level?.report_line_item?.repor_line_item_levels.reduce((subSum, item) => subSum + +item?.project_cost?.actual_spent, 0), 0);
            },
            level1BalanceInHand(level1) {
                return this.level1ApprovedBudget(level1) - this.level1TotalCommit(level1);
            },
            // level1PCommit(level1) {
            //     const levels = this.levels.filter((level) => level.level1 === level1);
            //     return levels.reduce((sum, level) => sum + level?.report_line_item?.repor_line_item_levels.reduce((subSum, item) => subSum + +item?.project_cost?.projected_commitment, 0), 0);
            // },
            level1PCommit(level1) {
                const levels = this.levels.filter((level) => level.level1 === level1);
                return levels.reduce((sum, level) => sum + +level?.report_line_item?.projected_commitment, 0);
            },
            level1Savings(level1) {
                return this.level1BalanceInHand(level1) - this.level1PCommit(level1);
            },
            level2ApprovedBudget(level1, level2) {
                const levels = this.levels.filter((level) => level.level1 === level1 && level.level2 === level2);
                return levels.reduce((sum, level) => sum + +level?.report_line_item?.total_budget, 0);
            },
            level2SapCommit(level1, level2) {
                const levels = this.levels.filter((level) => level.level1 === level1 && level.level2 === level2);
                return levels.reduce((sum, level) => sum + level?.report_line_item?.repor_line_item_levels.reduce((subSum, item) => subSum + +item?.project_cost?.sap_commit, 0), 0);
            },
            // level2ManualCommit(level1, level2) {
            //     const levels = this.levels.filter((level) => level.level1 === level1 && level.level2 === level2);
            //     return levels.reduce((sum, level) => sum + level?.report_line_item?.repor_line_item_levels.reduce((subSum, item) => subSum + +item?.project_cost?.manual_commit, 0), 0);
            // },
            level2ManualCommit(level1, level2) {
                const levels = this.levels.filter((level) => level.level1 === level1 && level.level2 === level2);
                return levels.reduce((sum, level) => sum + +level?.report_line_item?.manual_commit, 0);
            },
            level2TotalCommit(level1, level2) {
                return this.level2SapCommit(level1, level2) + this.level2ManualCommit(level1, level2);
            },
            level2ActualSpent(level1, level2) {
                const levels = this.levels.filter((level) => level.level1 === level1 && level.level2 === level2);
                return levels.reduce((sum, level) => sum + level?.report_line_item?.repor_line_item_levels.reduce((subSum, item) => subSum + +item?.project_cost?.actual_spent, 0), 0);
            },
            level2BalanceInHand(level1, level2) {
                return this.level2ApprovedBudget(level1, level2) - this.level2TotalCommit(level1, level2);
            },
            // level2PCommit(level1, level2) {
            //     const levels = this.levels.filter((level) => level.level1 === level1 && level.level2 === level2);
            //     return levels.reduce((sum, level) => sum + level?.report_line_item?.repor_line_item_levels.reduce((subSum, item) => subSum + +item?.project_cost?.projected_commitment, 0), 0);
            // },
            level2PCommit(level1, level2) {
                const levels = this.levels.filter((level) => level.level1 === level1 && level.level2 === level2);
                return levels.reduce((sum, level) => sum + +level?.report_line_item?.projected_commitment, 0);
            },
            level2Savings(level1, level2) {
                return this.level2BalanceInHand(level1, level2) - this.level2PCommit(level1, level2);
            },
            balanceClass(value) {
                return value >= 0 ? "green-savings" : "red-savings";
            },
            debouncedChangeProjects: debounce(function () {
                this.changeProjects();
            }, 300),
            debouncedGetReportLineItems: debounce(function () {
                this.getReportLineItems();
            }, 300),
        },
    };
</script>

<style scoped>
    .text-right {
        text-align: right;
    }
    .red-savings {
        color: red !important;
        text-align: right;
    }
    .green-savings {
        color: green !important;
        text-align: right;
    }
    thead th {
        font-weight: bolder !important;
        vertical-align: middle;
    }
</style>
