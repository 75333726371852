<template>
    <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Program Report</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                        <label class="form-label">Date</label>
                        <input class="form-control" type="date" placeholder="Date" :class="{ 'is-invalid': errors.date_time }" v-model="report.date_time" />
                        <span v-if="errors.date_time" class="invalid-feedback">{{ errors.date_time[0] }}</span>
                    </div>
                    <div class="col-md-3 mt-auto">
                        <button class="btn btn-sm btn-primary me-2" @click="showProgramReport()" type="button"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-primary " @click="downloadProgramReport()"><i class="ri-download-line icon_hgt"></i> Download </button>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap table-bordered mb-0">
                                <thead>
                                    <tr class="bg-primary bg-opacity-50">
                                        <th class="text-center">Sl No.</th>
                                        <th class="text-center">Unit</th>
                                        <th class="text-center">Unit Type</th>
                                        <th colspan="2" class="text-center">Plant Capacity</th>
                                        <th colspan="2" class="text-center">Capex (Rs Crs)</th>
                                        <th colspan="2" class="text-center">CWIP Summary (Rs Crs)</th>
                                        <th colspan="2" class="text-center">Timelines (CoD)</th>
                                        <th colspan="2" class="text-center">Overall Progress</th>
                                        <th class="text-center">Highlights and Milestones Completed</th>
                                    </tr>
                                    <tr class="bg-primary bg-opacity-25">
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>Clicker</th>
                                        <th>Cement</th>
                                        <th>Section</th>
                                        <th>LE</th>
                                        <th>Committed</th>
                                        <th>Spent</th>
                                        <th>Original</th>
                                        <th>LE</th>
                                        <th>Planned %</th>
                                        <th>Actual %</th>
                                        <th></th>
                                    </tr>

                                </thead>
                                <tbody v-for="(program_report,key) in program_reports" :key="key">
                                    <tr class="bg-warning bg-opacity-25">
                                        <td class="text-center"></td>
                                        <td>{{ program_report.project_group_name }}</td>
                                        <td></td>
                                        <td>{{ 0.0}}</td>
                                        <td>{{ 0.0}}</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                       
                                    </tr>
                                    <tr v-for="(project,key) in program_report.projects" :key="key">
                                        <td class="text-center">{{ key+1 }}</td>
                                        <td>
                                            {{ project.project_name }}
                                        </td>
                                        <td>{{ project.project_type.project_type_name }}</td>
                                        <td>{{ project.clinker_capacity?.capacity }}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{{ project.overall_plan }}</td>
                                        <td>{{ project.overall_actual }}</td>
                                        <td><textarea class="form-control form-control-sm" ></textarea></td>
                                    </tr>
                                   
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-primary btn-sm" @click.prevent="submitForm()">
                    <span><i class="ri-save-line icon_hgt"></i> SUBMIT</span>
                    <!-- <span><i class="ri-save-line icon_hgt"></i> UPDATE</span> -->
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
    export default {
        name: "ProgramReport",
        data() {
            return {
                report: {
                    report_id: "",
                    date_time: "",
                },
                program_reports:[],
                errors:[]
            };
        },
        mounted() {     
            if(this.$store.getters?.user){
                this.showProgramReport();
            }     
               
            // this.programReport();
            this.report.date_time = moment().subtract(1, 'day').format('yyyy-MM-DD')
        },
        methods: {
            // getProjects() {
            //     let vm = this;
            //     vm.$store
            //         .dispatch("post", { uri: "getProjects" })
            //         .then(function (response) {
            //             vm.projects = response.data.data;
            //             console.log("project",vm.projects);
            //             vm.getActivityDescriptions();
            //         })
            //         .catch(function (error) {
            //             vm.errors = error.response.data;
            //             vm.$store.dispatch("error", error.response.data.message);
            //         });
            // }
            showProgramReport() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "programReport",data: vm.report })
                    .then(function (response) {
                        loader.hide();
                        vm.program_reports = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            downloadProgramReport(){
                let vm = this;
                vm.errors = [];
                if (vm.report.date_time == ""){
                    vm.errors.date_time = ["The Date field is required."]
                }
                if ( vm.report.date_time ){
                    window.open(vm.$store.getters.apiUrl+'downloadProgramReport?date_time='+vm.report.date_time);
                }
               
            },
            submitForm(){

            }
        },
    };
</script>
