<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">{{ project.project_name }}</h2>
            <!-- <router-link to="/projects" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-list-check"></i> Back</router-link> -->
        </div>
        <div class="card">
            <div class="card-header">
                <strong>{{ project.project_name }}</strong>
            </div>
            <div class="card-body">
                <div class="form-search bordered border-secondary">
                    <i class="ri-search-line"></i>
                    <input type="text" class="form-control form-control-sm" v-model="meta.search" @keypress.enter="search()" placeholder="Type and press enter..." />
                </div>

                <hr class="my-3 opacity-0" />
                <div class="table-responsive table-resposive-sm">
                    <table class="table table-sm text-nowrap table-bordered mb-0">
                        <thead>
                            <tr style="text-align: center;">
                                <th class="text-center">#</th>
                                <th>WBS Code</th>
                                <th>Budget</th>
                                <th>Balance</th>
                                <th class="text-center">Withdrawal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center" colspan="5" v-if="!project_costs?.length">No records found</td>
                            </tr>
                            <tr v-for="project_cost, key in project_costs" :key="key">
                                <td>{{ key + 1 }}</td>
                                <!-- <td>
                                    {{ project_master_cost.project_code }}
                                </td> -->
                                <td>{{ project_cost.wbs_code }}</td>
                                <td style="text-align: right;">{{ project_cost?.budget }}</td>
                                <!-- <td style="text-align: right;">{{ project_master_cost.balance }}</td> -->
                                <td style="text-align: right;">{{ project_cost.withdrawal_balance }}</td>
                                <td><input type="text" name="" id="" class="form-control form-control-sm" v-model="project_cost.withdrawal" /></td>
                            </tr>
                            <tr>
                                <td colspan="4" class="text-center"><strong>Total</strong></td>
                                <td>
                                    <input type="text" name="" disabled="true" style="font-weight: bold;" class="form-control form-control-sm" v-model="total_withdrawal_amount" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="button" class="btn btn-sm btn-outline-primary mx-1 mt-2" style="float: right;" @click="withdrawAmount()">Submit</button>
                    <button type="button" class="btn btn-sm btn-outline-danger mx-1 mt-2" style="float: right;" @click="discard()">Discard</button>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                    <select class="form-select from-select-sm width-75" @change="onPerPageChange" v-model="meta.per_page">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="30">30</option>
                    </select>
                    <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "project_cost_id",
                    per_page: 100000,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                    project_id: "",
                },
                errors: [],
                error: {},
                project: {
                    project_id: "",
                    amount: "",
                    last_stage: {},
                    withdrawals: [],
                },
                project_costs: [],
                // project_master_cost: {},
                show_modal: "modal fade",
                stages: [],
                next_stages: [],
                approvals: [],
                submit_disabled: false,
                project_requests: [],
                project_request_stages: [],
                project_request_stage: {},
                spents: [],
                withdrawal_amount: "",
            };
        },
        mounted() {
            // this.index();
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.project.project_id = to.params.project_id;
                vm.meta.project_id = to.params.project_id;
                // vm.getProject();
                vm.index();
            });
        },

        computed: {
            total_withdrawal_amount() {
                // let total = 0;
                // this.project_costs.filter(ele => {
                //     console.log("ele.project", ele.withdrawal);
                //     total += parseInt(ele.withdrawal_amount);
                // })

                let withdrawal_amounts = [];
                this.project_costs.map(function (project) {
                    // console.log("project333", typeof())
                    if (typeof project.withdrawal == "string") {
                        // if(isNAN(project.withdrawal))
                        // {
                        //     console.log("NAN")
                        // }
                        withdrawal_amounts.push(parseInt(project.withdrawal));
                        console.log("test1", withdrawal_amounts)
                    } else {
                        withdrawal_amounts.push(0);
                        console.log("test2", withdrawal_amounts)
                    }
                    // withdrawal_amounts.push(project.withdrawal);
                });

                let total_amount = withdrawal_amounts.reduce((total, num) => total + num, 0);
                console.log("total_amount", total_amount);
                this.withdrawal_amount = total_amount;
                return total_amount;
            },
        },

        methods: {
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateTransferCosts", data: vm.meta })
                    .then(function (response) {
                        vm.project_costs = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.to = response.data.meta.to;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                        vm.prepareProjectData();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },

            // getProject() {
            //     let vm = this
            //     vm.project.type = "bats";
            //     let uri = { uri: "getProject", data: vm.project };
            //     vm.$store
            //         .dispatch("post", uri)
            //         .then(function (response) {
            //             vm.project = response.data.data;
            //             vm.index();
            //         })
            //         .catch(function (error) {
            //         });
            // },

            prepareProjectData() {
                this.project_costs.forEach((ele) => {
                    ele.withdrawal = 0;
                });
            },

            discard() {
                this.project.project_costs.forEach((ele) => {
                    ele.withdrawal_amount = 0;
                });
            },

            // showRequests() {
            //     this.$router.push('/project_cost/' + this.project.project_id + '/request')
            // },

            withdrawAmount() {
                let vm = this;
                let total = 0;
                vm.project.withdrawals = [];
                vm.project_costs.filter(function (element) {
                    // element.withdrawal

                    if (element.withdrawal != 0) {
                        if (typeof element.withdrawal == "string") {
                            total += parseInt(element.withdrawal);
                        }

                        // let a = {
                        //     budget: element.budget,
                        //     balance: element.withdrawal_balance,
                        //     project_cost_id: element.project_cost_id,
                        //     withdrawal: parseInt(element.withdrawal_amount),
                        // }
                        // let b = Object.assign({}, a);
                        vm.project.withdrawals.push(element);
                    }
                });
                this.project.amount = total;
                let loader = this.$loading.show();
                this.$store
                    .dispatch("post", { uri: "addWithdrawal", data: vm.project })
                    .then((response) => {
                        loader.hide();
                        this.$store.dispatch("success", response.data.message);
                        this.discard();
                    })
                    .catch((error) => {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });

                // this.project.project_costs.forEach(ele => {
                // console.log("element", ele)
                //     if(ele.withdrawal_amount != 0){
                //         let a = {
                //             budget: ele.budget,
                //             balance: ele.withdrawal_balance,
                //             project_cost_id: ele.project_cost_id,
                //             withdrawal: parseInt(ele.withdrawal_amount),
                //         }
                //         let b = Object.assign({}, a);
                //         withdrawals.push(b);
                //     }
                // })
                // let data = {
                //     project_id: this.project.project_id,
                //     amount: this.total_withdrawal_amount,
                //     withdrawals: withdrawals
                // }
                // let loader = this.$loading.show();
                // this.$store.dispatch('post', { uri: 'withdrawAmount', data: data })
                //     .then(response => {
                //         loader.hide();
                //         this.$store.dispatch('success', response.data.message);
                //         this.discard();
                //     })
                //     .catch(error => {
                //         loader.hide();
                //         vm.errors = error.response.data.errors;
                //         vm.$store.dispatch("error", error.response.data.message);
                //     })
            },
        },
    };
</script>
<style scoped>
    tr:hover {
        background: #f2f3ff;
        outline: none;
        cursor: pointer;
    }

    .green_dot {
        width: 15px;
        height: 15px;
        background-color: rgb(124, 157, 90);
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        margin: auto;
    }

    .orange_dot {
        width: 15px;
        height: 15px;
        background-color: #ffa500;
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        margin: auto;
    }

    .green_dot {
        width: 15px;
        height: 15px;
        background-color: green;
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        margin: auto;
    }

    .gray_dot {
        width: 15px;
        height: 15px;
        background-color: gray;
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        margin: auto;
    }

    .red_dot {
        width: 15px;
        height: 15px;
        background-color: red;
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        margin: auto;
    }
</style>
