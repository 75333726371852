<template>
    <div class="">
        <div class="card">
            <div class="card-header">
                <div class="d-sm-flex align-items-center justify-content-between">
                    <div>
                        <strong>Log Parameters</strong>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-3">
                        <h6>User : <strong>{{ log?.user?.name }}</strong></h6>
                    </div>
                    <div class="col-md-3">
                        <h6>Project : <strong>{{ log?.project?.project_name }}</strong></h6>
                    </div>
                    <div class="col-md-3">
                        <h6>Project Activity Group : <strong>{{ log?.project_activity_group?.activity_group?.activity_group_name }}</strong></h6>
                    </div>
                    <div class="col-md-3">
                        <div style="float:right">
                            <router-link to="/logs" class="btn btn-primary btn-sm">Logs</router-link>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-2 d-flex">
                    <table class="table table-sm text-nowrap table-bordered mb-0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th @click="sort('project_section_group')">
                                    Project Section Group
                                    <span>
                                        <i v-if="meta.keyword == 'project_section_group' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'project_section_group' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('project_section')">
                                    Project Section
                                    <span>
                                        <i v-if="meta.keyword == 'project_section' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'project_section' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('project_activity')">
                                    Project Activity
                                    <span>
                                        <i v-if="meta.keyword == 'project_activity' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'project_activity' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('project_parameter_type')">
                                    Project Parameter Type
                                    <span>
                                        <i v-if="meta.keyword == 'project_parameter_type' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'project_parameter_type' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('project_parameter')">
                                    Project Parameter
                                    <span>
                                        <i v-if="meta.keyword == 'project_parameter' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'project_parameter' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('operation')">
                                    Operation
                                    <span>
                                        <i v-if="meta.keyword == 'operation' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'operation' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('date_time')">
                                    Date & Time
                                    <span>
                                        <i v-if="meta.keyword == 'date_time' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword == 'date_time' && meta.order_by == 'desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="log_parameters.length == 0">
                                <td colspan="8" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(log_parameter, key) in log_parameters" :key="key">
                                <td class="text-center">{{ meta.from + key }}</td>
                                <td>{{ log_parameter?.project_section_group?.section_group?.section_group_name }}</td>
                                <td>{{ log_parameter?.project_section?.section?.section_name }}</td>
                                <td>{{ log_parameter?.project_activity?.activity?.activity_name }}</td>
                                <td>{{ log_parameter?.project_parameter_type.parameter_type.parameter_type_name }}</td>
                                <td>{{ log_parameter?.project_parameter?.name }}</td>
                                <td>{{ log_parameter?.operation }}</td>
                                <td>{{ convertDateFormat(log_parameter.date_time) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                    <select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </select>
                    <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    import moment from 'moment';

    export default {
        components: { Pagination },
        data() {
            return {
                meta: {
                    log_id:'',
                    search: "",
                    order_by: "desc",
                    keyword: "log_parameter_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                log: {},
                log_parameters:[],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
        next((vm) => {          
                vm.meta.log_id = to.params.log_id
                if(vm.$store.getters?.user){
                    vm.index()
                }
                
                 
            });
        },
        mounted() {
        },
        methods: {
            logParameters(log){

            },
            convertDateFormat(date_time){
                if(date_time){
                    return moment(date_time).format("DD-MMM-yyyy hh:mm a")
                }else{
                    return '-'
                }
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getLog", data: vm.meta })
                    .then(function (response) {
                        vm.log = response.data.data
                        vm.paginateLogParameters()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            paginateLogParameters() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateLogParameters", data: vm.meta })
                    .then(function (response) {
                        vm.log_parameters = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
    };
</script>
