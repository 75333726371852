<template>
	 <div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">User Management</li>
			</ol>
			<h4 class="main-title mb-0">Escalation Settings</h4>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-sm-4" v-for="escalation_stage, key in escalation_stages" :key="key">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">Escalation Stage {{ escalation_stage.escalation_stage }}</h6>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-sm-6">
							<label class="form-label">Role Group :: Role</label>
							<select class="form-control" :class="{ 'is-invalid': errors.escalation_role_id }" v-model="escalation_stage.escalation_role_id" :disabled="!escalation_stage.edit">
								<option value="">Select Role</option>
								<!-- <option v-for="role, key in escalation_roles" :value="role.escalation_role_id">{{ role.name }}</option> -->
								<optgroup v-for="role_group, i in escalation_roles" :key="i" :label="role_group[0]?.role_group?.role_group_name">
									<option v-for="role, j in role_group" :key="j" :value="role.escalation_role_id">
										{{ role_group[0]?.role_group?.role_group_name }} :: {{role.name }}
									</option>
								</optgroup>
							</select>
							<span v-if="errors.escalation_role_id" class="invalid-feedback">{{ errors.escalation_role_id[0] }}</span>
						</div>
						<div class="col-sm-6">
							<label class="form-label">Escalation Days</label>
							<input type="text" placeholder="Escalation Days" class="form-control" :class="{ 'is-invalid': errors.escalation_days }" v-model="escalation_stage.escalation_days" :disabled="!escalation_stage.edit">
							<span v-if="errors.escalation_days" class="invalid-feedback">{{ errors.escalation_days[0] }}</span>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex flex-row-reverse">
					<div v-if="escalation_stage.edit" class="d-flex align-items-center gap-2">
						<button class="btn btn-sm btn-outline-success" title="Update" @click="updateEscalationStage(escalation_stage,key)">
							<i class="ri-refresh-line icon_hgt"></i>
						</button>
					</div>
					<div v-else class="d-flex align-items-center gap-2">
						<button v-if="escalation_stage.escalation_stage == escalation_stages.length" class="btn btn-sm btn-outline-danger" title="Edit" @click="deleteEscalationStage(escalation_stage,key)">
							<i class="ri-delete-bin-line icon_hgt"></i>
						</button>
						<button class="btn btn-sm btn-outline-primary" title="Edit" @click="editStage(escalation_stage,key)">
							<i class="ri-pencil-line icon_hgt"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-4">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">Stage {{ escalation_stages.length + parseInt(1) }}</h6>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-sm-6">
							<label class="form-label">Role</label>
							<select class="form-control" :class="{ 'is-invalid': create_errors.escalation_role_id }" v-model="escalation_stage.escalation_role_id">
								<option value="">Select Role</option>
								<!-- <option v-for="role, key in escalation_roles" :value="role.escalation_role_id">{{ role.name }}</option> -->
								
								<optgroup v-for="role_group, i in escalation_roles" :key="i" :label="role_group[0]?.role_group?.role_group_name">
									<option v-for="role, j in role_group" :key="j" :value="role.escalation_role_id">
										{{ role_group[0]?.role_group?.role_group_name }} :: {{role.name }}
									</option>
								</optgroup>
							</select>
							<span v-if="create_errors.escalation_role_id" class="invalid-feedback">{{ create_errors.escalation_role_id[0] }}</span>
						</div>
						<div class="col-sm-6">
							<label class="form-label">Escalation Days</label>
							<input type="text" placeholder="Escalation Days" :class="{ 'is-invalid': create_errors.escalation_days }" class="form-control" v-model="escalation_stage.escalation_days" />
							<span v-if="create_errors.escalation_days" class="invalid-feedback">{{ create_errors.escalation_days[0] }}</span>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex flex-row-reverse">
					<div class="gap-2">
						<button class="btn btn-sm btn-outline-success" @click="addEscalationStage()" title="Add">
							<i class="ri-add-line icon_hgt"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			escalation_stage: {
				escalation_stage: '',
				escalation_role_id: '',
				// role_group_id: '',
				escalation_days: ''
			},
			escalation_roles: [],
			escalation_stages: [],
			errors: [],
			create_errors: [],
		};
	},
	mounted() {
		if(this.$store.getters?.user){
			this.getEscalationRoles();
        }
	},
	methods: {
		getEscalationRoles() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getEscalationRoles" })
			.then(function (response) {
				vm.escalation_roles = response.data;
				vm.getEscalationStages();
			})
			.catch(function (error) {
				vm.errors = error.response.data.data;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		getEscalationStages() {
			let vm = this;
			vm.$store.dispatch('post', { uri: 'getEscalationStages' })
			.then(response => {
				vm.escalation_stages = response.data.data;
			})
			.catch(function (error) {
				vm.errors = error.response.data.data;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		addEscalationStage() {
			let vm = this
			vm.escalation_stage.escalation_stage = vm.escalation_stages.length + parseInt(1)
			let loader = vm.$loading.show();
			vm.$store.dispatch('post', { uri: 'addEscalationStage', data: vm.escalation_stage })
			.then(response => {
				loader.hide();
				vm.escalation_stages.push(response.data.data);
				vm.$store.dispatch("success", 'Stage is successfully created');
				vm.discard()
			})
			.catch(function (error) {
				loader.hide();
				vm.create_errors = error.response.data.errors
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		editStage(escalation_stage,key) {
			escalation_stage.edit = true;
		},

		updateEscalationStage(escalation_stage,key) {
			let vm = this;
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'updateEscalationStage', data: escalation_stage })
			.then(response => {
				loader.hide();
				vm.$store.dispatch("success", 'Escalation stage is successfully updated');
				vm.escalation_stages.splice(key,1,response.data.data)
				vm.discard()
			})
			.catch(function (error) {
				loader.hide();
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		deleteEscalationStage(escalation_stage,key) {
		    let vm = this;
		    if(confirm('Do you want to delete the escalation_stage ' + escalation_stage.escalation_stage)) {
				let loader = vm.$loading.show();
				vm.$store.dispatch('post', { uri: 'deleteEscalationStage', data: escalation_stage })
				.then(response => {
					loader.hide();
					vm.$store.dispatch("success", response.data.message);
					vm.escalation_stages.splice(key,1)
					vm.discard()
				})
				.catch(error => {
					loader.hide();
					console.log(error.response)
					vm.$store.dispatch("error", error.response.data.errors.message);
				})
			}
		},

		discard() {
			let vm = this;
			// vm.escalation_stage.role_group_id = '';
			vm.escalation_stage.escalation_role_id = '';
			vm.escalation_stage.escalation_days = '';
			vm.errors = [],
			vm.create_errors = []
		}
	},
};
</script>
