<template>
    <figure class="highcharts-figure">
        <div :id="container" :style="style"></div>
    </figure>
</template>

<script>
    var Highcharts = require("highcharts");
    require("highcharts/modules/heatmap")(Highcharts);
	require("highcharts/modules/data")(Highcharts);
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/export-data")(Highcharts);
    require("highcharts/modules/accessibility")(Highcharts);
    export default {
        name: "Heatmap",
        props: {
			container: {
                default: null,
            },
            style: {
                default: 'height: 400px !important'
            },
            title: {
                default: null,
            },
            xAxis: {
                default: null,
            },
            yAxis: {
                default: null,
            },
            min: {
				default: null,
			},
            max: {
				default: null,
			},
            scrollbar: {
				default: true,
			},
			series: {
				default: null,
			}
        },

        watch: {
			series: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
            max: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
        },

        mounted() {
            this.loadChart();
        },

        methods: {
            loadChart() {
                let vm = this;
                Highcharts.chart(vm.container, {
                    chart: {
                        type: "heatmap",
                        // marginTop: 40,
                        // marginBottom: 80,
                       
                        marginTop: 40,
    marginBottom: 80,
    plotBorderWidth: 1
                    },

                    credits: {
                        enabled: false,
                    },

                    title: {
                        text: vm.title
                    },

                    xAxis: {
                        categories: vm.xAxis,
                        // min: vm.min,
                        // max: vm.max,
                        // scrollbar: {
                        //     enabled: true,
                        //     showFull: true,
                        // },
                        // tickLength: 0,
                    },

                    yAxis: {
                        categories: vm.yAxis,
                        title: null,
                        reversed: true,                        
                    },

                    accessibility: {
                        point: {
                        descriptionFormat: '{(add index 1)}. ' +
                            '{series.xAxis.categories.(x)} sales ' +
                            '{series.yAxis.categories.(y)}, {value}.'
                        }
                    },
                    
                    colorAxis: {
                        min: 0,
                        minColor: '#FFFFFF',
                        maxColor: Highcharts.getOptions().colors[0]
                    },
                    legend: {
                        align: 'right',
                        layout: 'vertical',
                        margin: 0,
                        verticalAlign: 'top',
                        y: 25,
                        symbolHeight: 280
                    },
                    
                    plotOptions: {
                        series: {
                            cursor: "pointer",
                            borderWidth: 0,
                            // point: {
                            //     events: {
                            //         click: function () {
							// 			vm.$emit('selectBar',this)
                            //         },
                            //     },
                            // },
                            // dataLabels: {
                            //     align: 'center',
                            //     enabled: true,
                            //     // rotation: 270,
                            //     x: 10,
                            //     y: 15
                            // }
                        },
                        
                    },
                        
                    // tooltip: {
                    //     formatter: function () {
                    //         return "<b>" + vm.$store.state.matric + " Score of " + vm.getPointCategoryName(this.point, "x") + "</b> is <br><b>" + this.point.value + "</b>";
                    //     },
                    // },

                    series: [
                        {
                            name: "Heatmap",
                            borderWidth: 1,
                            data: vm.series,
                            dataLabels: {
                                enabled: true,
                                color: "#000000",
                            },
                        },
                    ],

                    // responsive: {
                    //     rules: [
                    //         {
                    //             condition: {
                    //                 maxWidth: 100,
                    //             },
                    //             chartOptions: {
                    //                 yAxis: {
                    //                     labels: {
                    //                         formatter: function () {
                    //                             return this.value.charAt(0);
                    //                         },
                    //                     },
                    //                 },
                    //             },
                    //         },
                    //     ],
                    // },
                });
            },
            // getPointCategoryName(point, dimension) {
            //     var series = point.series,
            //         isY = dimension === "y",
            //         axis = series[isY ? "yAxis" : "xAxis"];
            //     return axis.categories[point[isY ? "y" : "x"]];
            // },
        },
    };
</script>
<style scoped>
#container{
    max-width: 800px;
    min-width: 320px;
    height: 500px !important;
    width:400px;

}
</style>
