<template>
    <div class="card">
        <div class="card-header">
            <strong>{{ project.project_name }}</strong>
        </div>
        <div class="card-body">
            <div class="row">
                <Project :project="project" />
                <div class="col-12">
                    <div class="form-group mb-2 d-flex">
                        <input class="form-control form-control-sm" type="text"
                            placeholder="Type keyword and press enter key" v-model="meta.search"
                            @keypress.enter="search()" />
                        <!-- <a @click="addValues()" v-if="selected_users != 0" href="javascript:void(0)"
                            class="btn btn-sm btn-primary ms-2 "><i class="ri-delete-bin-line icon_hgt"></i></a> -->
                    </div>
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th @click="sort('name')">Name
                                        <span>
                                            <i v-if="meta.keyword == 'name' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'name' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('email')">Email
                                        <span>
                                            <i v-if="meta.keyword == 'email' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'email' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('role_id')">Role Group
                                        <span>
                                            <i v-if="meta.keyword == 'role_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'role_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('role_id')">Role
                                        <span>
                                            <i v-if="meta.keyword == 'role_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'role_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('user_type')">User Type
                                        <span>
                                            <i v-if="meta.keyword == 'user_type' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'user_type' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('mobile_no')">Mobile No.
                                        <span>
                                            <i v-if="meta.keyword == 'mobile_no' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'mobile_no' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th class="text-center"><input type="checkbox" :checked="isAllSelected"
                                            @change="selectAll" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="users.length == 0">
                                    <td colspan="6" class="text-center">No records found!</td>
                                </tr>
                                <tr v-for="(user, key) in users" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{ user.name }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.role?.role_group?.role_group_name }}</td>
                                    <td>{{ user.role?.role_name }}</td>
                                    <td>{{ user.user_type }}</td>
                                    <td>{{ user.mobile_no }}</td>
                                    <td class="text-center">
                                        <input class="me-1" type="checkbox" v-model="selected_users"
                                            :value="user.user_id" @change="select" />
                                        <!-- <router-link to="/activity_groups" class="text-success me-1" title="add"><i class="ri-add-circle-line"></i></router-link> -->
                                        <a v-if="isProjectUser(user.user_id)" href="javascript:void(0)"
                                            class="text-success me-1" title="add"
                                            @click="addActivityGroups(project.project_id, user.user_id)"><i
                                                class="ri-add-circle-line"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-sm btn-outline-primary m-2" style="float: right;"
                                    @click="addProjectUsers()">Submit</button>
                                <button class="btn btn-sm btn-outline-danger m-2" style="float: right;">Discard</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between align-items-center">
                <select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                    <option>30</option>
                </select>
                <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                    @pagechanged="onPageChange" />
            </div>
        </div>
    </div>
</template>
<script>
import ProjectUpdate from "@/components/tree/ProjectUpdate.vue";
import Project from '@/components/Project'
import Pagination from "@/components/Pagination.vue";
export default {
    components: { ProjectUpdate, Project, Pagination },
    data() {
        return {
            treeData: [],
            project_activity_groups: [],
            project: {
                project_id: '',
                type: '',
                is_manual: false
            },
            meta: {
                search: "",
                order_by: "asc",
                keyword: "user_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
            },
            isAllSelected: false,
            selected_users: [],
            existing_users: [],
            users: [],
            errors: [],
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            let loader = vm.$loading.show()
            vm.project.project_id = to.params.project_id
            let uri = { uri: "getProjectPMTEdit", data: vm.project };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide()
                    vm.project = response.data.data;
                    vm.getAssignedUsers();
                })
                .catch(function (error) {
                    loader.hide()
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        });
    },
    watch: {
    },
    mounted() {
        if(this.$store.getters?.user){
            this.index();
        }
        
    },
    methods: {
        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },
        index() {
            let vm = this;
            vm.$store.dispatch("post", { uri: "paginateProjectUsers", data: vm.meta })
                .then(function (response) {
                    vm.users = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        addActivityGroups(project_id, user_id) {
            console.log("activity_groups")
            this.$router.push('/activity_groups/' + project_id + '/' + user_id)
        },

        selectAll() {
            if (this.isAllSelected) {
                this.selected_users = [];
                this.isAllSelected = false;
            }
            else {
                this.selected_users = [];
                for (var user in this.users) {
                    this.selected_users.push(this.users[user].user_id);
                }
                this.isAllSelected = true;
            }
        },
        select() {
            if (this.selected_users.length !== this.users.length) {
                this.isAllSelected = false;
            } else {
                this.isAllSelected = true;
            }
        },

        addProjectUsers() {
            let vm = this;
            let deleted_users = this.existing_users.filter(x => !this.selected_users.includes(x));
            let loader = vm.$loading.show();
            vm.$store.dispatch("post", { uri: "addProjectUsers", data: { user_id: this.selected_users, project_id: vm.project.project_id, deleted_users: deleted_users } })
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    // vm.isAllSelected = false;
                    // vm.selected_users = [];
                    // vm.getAssignedUsers();
                    // vm.index();
                    vm.$router.push('/project/' + vm.project.project_id + '/view')
                })
                .catch(function (error) {
                    console.log(error)
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },

        getAssignedUsers() {
            let vm = this;
            let loader = vm.$loading.show();
            this.$store.dispatch('post', { uri: 'getAssignedUsers', data: { project_id: this.project.project_id } })
                .then(response => {
                    loader.hide();
                    this.existing_users = response.data.data;
                    this.setSelection();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        setSelection() {
            this.existing_users.forEach(ele => {
                this.selected_users.push(ele.user_id);
            })
            this.existing_users = this.selected_users;
        },

        isProjectUser(id) {
            let temp = this.existing_users.find(ele => {
                return ele == id;
            })
            if (temp) {
                return true;
            }
            else {
                return false;
            }
        }
    }

};
</script>
