<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">Projects</li>
			</ol>
			<h4 class="main-title mb-0">Budget Request</h4>
		</div>
		<div class="d-flex align-items-center gap-2 mt-3 mt-md-0">
			<span v-if="meta.project_id != ''" v-can="'budget_requests.create'">
				<router-link :to="'/BATS/' + meta.project_id + '/user'"
					class="btn btn-success d-flex align-items-center gap-2">
					<i class="ri-add-box-line fs-18 lh-1"></i>New Budget Request
				</router-link>
			</span>
			<span v-can="'budget_requests.view'">
				<a :href="$store.getters.apiUrl + 'downloadRequests?from_date=' + meta.from_date + '&to_date=' + meta.to_date + '&wbs_code=' + meta.wbs_code + '&stage_id=' + meta.stage_id + '&status=' + meta.status + '&project_group_id=' + meta.project_group_id + '&project_id=' + meta.project_id + '&extra=' + meta.extra + '&user_id=' + $store.getters.user.user_id"
					target="_blank" class="btn btn-primary d-flex align-items-center gap-2">
					<i class="ri-download-line fs-18 lh-1"></i>Download Request
				</a>
			</span>
			<span v-can="'budget_requests.view.commit'">
				<a target="_blank"
					:href="$store.getters.apiUrl + 'downloadProjectRequests?user_id=' + $store.getters.user.user_id + '&from_date=' + meta.from_date + '&to_date=' + meta.to_date + '&stage_id=' + meta.stage_id + '&status=' + meta.status + '&project_group_id=' + meta.project_group_id + '&project_id=' + meta.project_id"
					class="btn btn-secondary d-flex align-items-center gap-2">
					<i class="ri-file-download-line fs-18 lh-1"></i>Download
				</a>
			</span>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-sm-12">
			<div class="card card-one">
				<div class="card-header d-flex justify-content-between align-items-center">
					<h6 class="card-title">Budget Request List</h6>
					<select class="form-select" style="float: right; width: 115px;" v-model="currency_value">
						<option value="1">Rupees</option>
						<option value="100000">Lakh</option>
						<option value="10000000">Cr</option>
					</select>
				</div>
				<div class="card-body">
					<div class="row g-2 mb-1">
						<div class="col-md-12">
							<div class="form-group">
								<div class="row">
									<div class="col-sm-2">
										<label for="from_date" class="form-label">From Date</label>
										<input type="date" class="form-control" v-model="meta.from_date"
											@change="search()">
									</div>
									<div class="col-sm-2">
										<label for="to_date" class="form-label">To Date</label>
										<input type="date" class="form-control" v-model="meta.to_date"
											@change="search()">
									</div>
									<div class="col-sm-2">
										<label for="stage" class="form-label">Stage</label>
										<select class="form-control" v-model="meta.stage_id" @change="search()">
											<option value="">Select Stage</option>
											<option v-for="stage, key in stages" :key="key" :value="stage.stage_id">
												{{ "Stage " + stage.stage }}
											</option>
										</select>
									</div>
									<div class="col-sm-2">
										<label for="status" class="form-label">Status</label>
										<select class="form-control" v-model="meta.status" @change="search()">
											<option value="">Select Status </option>
											<option>Approved</option>
											<option>Rejected</option>
											<option>Pending</option>
											<option>Returned</option>
										</select>
									</div>
									<div class="col-sm-2">
										<label for="status" class="form-label">Project Group</label>
										<select class="form-control" v-model="meta.project_group_id"
											@change="getProjects()">
											<option value="">Select Project Groups</option>
											<option v-for="project_group in project_groups"
												:value="project_group.project_group_id">
												{{ project_group.project_group_name }}
											</option>
										</select>
									</div>
									<div class="col-sm-2">
										<label for="status" class="form-label">Project</label>
										<select class="form-control" v-model="meta.project_id" @change="search()">
											<option value="">Select Project</option>
											<option v-for="project in projects" :value="project.project_id">
												{{ project.project_name }}
											</option>
										</select>
									</div>
								</div>
								<div class="row mt-3 mb-3">
									<div
										:class="{ 'col-sm-11': commits.length } || { 'col-sm-12': commits.length == 0 }">
										<input class="form-control" type="text"
											placeholder="Search WBS Code or WBS Description" v-model="meta.search"
											@keypress.enter="search()" />
									</div>
									<div class="col-sm-1" v-if="commits.length">
										<button type="button" class="btn btn-primary" @click="commitProjectRequest()"
											style="float: right;">Commit</button>
									</div>
								</div>
								<div class="table-responsive table-resposive-sm">
									<table class="table table-sm text-nowrap table-striped table-bordered mb-0">
										<thead class="text-center">
											<tr>
												<th class="text-center">#</th>
												<th class="text-center" @click="sort('project_request_id')">
													Request No.
													<span style="float: right;">
														<i v-if="meta.keyword == 'project_request_id' && meta.order_by == 'asc'"
															class="ri-sort-asc ms-2"></i>
														<i v-else-if="meta.keyword == 'project_request_id' && meta.order_by == 'desc'"
															class="ri-sort-desc ms-2"></i>
														<i v-else class="ri-arrow-up-down-line ms-2"></i>
													</span>
												</th>
												<th class="text-center">Level</th>
												<th class="text-center" @click="sort('project_cost_id')">
													WBS Code & Description
													<span style="float: right;">
														<i v-if="meta.keyword == 'project_cost_id' && meta.order_by == 'asc'"
															class="ri-sort-asc ms-2"></i>
														<i v-else-if="meta.keyword == 'project_cost_id' && meta.order_by == 'desc'"
															class="ri-sort-desc ms-2"></i>
														<i v-else class="ri-arrow-up-down-line ms-2"></i>
													</span>
												</th>
												<th class="text-center" @click="sort('amount')">
													Amount <span class="text-primary">[{{ currency_unit }}]</span>
													<span style="float: right;">
														<i v-if="meta.keyword == 'amount' && meta.order_by == 'asc'"
															class="ri-sort-asc ms-2"></i>
														<i v-else-if="meta.keyword == 'amount' && meta.order_by == 'desc'"
															class="ri-sort-desc ms-2"></i>
														<i v-else class="ri-arrow-up-down-line ms-2"></i>
													</span>
												</th>
												<th>Requested By</th>
												<th class="text-center" @click="sort('request_date')">
													Request Date
													<span style="float: right;">
														<i v-if="meta.keyword == 'request_date' && meta.order_by == 'asc'"
															class="ri-sort-asc ms-2"></i>
														<i v-else-if="meta.keyword == 'request_date' && meta.order_by == 'desc'"
															class="ri-sort-desc ms-2"></i>
														<i v-else class="ri-arrow-up-down-line ms-2"></i>
													</span>
												</th>
												<template v-if="meta.status == 'Approved'">
													<th>Last Activity Date</th>
													<th class="text-center"
														@click="sort('project_request_stages.date_time')">
														Approved Date
														<span style="float: right;">
															<i v-if="meta.keyword == 'project_request_stages.date_time' && meta.order_by == 'asc'"
																class="ri-sort-asc ms-2"></i>
															<i v-else-if="meta.keyword == 'project_request_stages.date_time' && meta.order_by == 'desc'"
																class="ri-sort-desc ms-2"></i>
															<i v-else class="ri-arrow-up-down-line ms-2"></i>
														</span>
													</th>
													<th>Action From</th>
													<th>Approver's Name</th>
												</template>
												<template v-else>
													<th class="text-center"
														@click="sort('project_request_stages.date_time')">
														Last Activity Date
														<span style="float: right;">
															<i v-if="meta.keyword == 'project_request_stages.date_time' && meta.order_by == 'asc'"
																class="ri-sort-asc ms-2"></i>
															<i v-else-if="meta.keyword == 'project_request_stages.date_time' && meta.order_by == 'desc'"
																class="ri-sort-desc ms-2"></i>
															<i v-else class="ri-arrow-up-down-line ms-2"></i>
														</span>
													</th>
													<th>Action From</th>
												</template>
												<th>Status</th>
												<th>Delay</th>
												<th class="text-center">Action</th>
												<th v-can="'budget_requests.view.commit'">
													Commit
													<input class="form-check-input" type="checkbox" v-model="select_all"
														@change="selectAll()" v-show="no_of_commitables != 0">
												</th>
												<th class="text-center">View</th>
											</tr>
										</thead>
										<tbody style="vertical-align: middle;">
											<template v-for="project_request, key in project_requests" :key="key">
												<tr>
													<td rowspan="3" class="text-center">
														{{ meta.from + key }}
													</td>
													<td rowspan="3" class="text-center">
														{{ project_request.request_no }} <br>
														<span v-if="project_request.hold == 1" class="badge bg-warning">
															Hold
														</span>
													</td>
													<td class="text-center">
														{{ project_request?.project_cost?.level }}
													</td>
													<td>
														{{ project_request?.project_cost?.wbs_code }} :: {{
															project_request?.project_cost?.wbs_description }}
													</td>
													<td class="text-center">
														{{ formatCurrency(project_request.amount) }}
													</td>
													<td class="text-center">
														{{ project_request.user?.name }}
													</td>
													<td class="text-center">
														{{ $formatDate(project_request.request_date, 'date') }}
													</td>
													<template v-if="meta.status == 'Approved'">
														<td class="text-center">
															{{
																$formatDate(project_request.project_request_stages[project_request.project_request_stages.length
																	- 2]?.date_time, 'date')
															}}
														</td>
														<td class="text-center">
															{{
																$formatDate(project_request.project_request_stage?.date_time,
																	'date')
															}}
														</td>
														<td class="text-center"
															v-if="project_request.project_request_stage">
															{{
																project_request.project_request_stage?.stage?.role?.role_name
															}}
														</td>
														<td class="text-center">
															{{
																project_request.project_request_stage?.user?.name
															}}
														</td>
													</template>
													<template v-else>
														<td class="text-center">
															{{
																$formatDate(project_request.project_request_stage?.date_time,
																	'date')
															}}
														</td>
														<td class="text-center"
															v-if="project_request.project_request_stage">
															{{
																project_request.project_request_stage?.stage?.role?.role_name
															}}
														</td>
													</template>
													<td class="text-center">
														{{ project_request.project_request_stage?.status }}
													</td>
													<td class="text-center">
														{{ project_request.days_delay }}
													</td>
													<td v-if="project_request?.project_request_stage?.status == 'Pending' && project_request?.project_request_stage?.role_id == $store.getters.user.role_id"
														class="text-center" rowspan="3">
														<div class="d-grid gap-2">
															<template
																v-if="project_request?.project_request_stage?.stage?.stage == stages.length">
																<button @click="forwardRequest(project_request)"
																	class="btn btn-sm btn-outline-success">
																	<i class="ri-check-line icon_hgt"></i> Approve
																</button>
																<button type="button"
																	class="btn btn-sm btn-outline-warning"
																	@click="returnRequest(project_request)">
																	<i class="ri-arrow-go-back-line icon_hgt"></i>
																	Return
																</button>
																<button type="button"
																	class="btn btn-sm btn-outline-danger"
																	@click="rejectRequest(project_request)">
																	<i class="ri-close-line icon_hgt"></i> Reject
																</button>
																<button v-if="project_request.hold == 0"
																	@click="holdRequest(project_request)"
																	class="btn btn-sm btn-outline-secondary">
																	<i class="ri-pause-line icon_hgt"></i> Hold
																</button>
															</template>
															<template v-else>
																<button @click="forwardRequest(project_request)"
																	class="btn btn-sm btn-outline-success">
																	<i class="ri-arrow-right-line icon_hgt"></i> Forward
																</button>
															</template>
														</div>
													</td>
													<td rowspan="3" class="text-center" v-else>-</td>
													<td rowspan="3" class="text-center"
														v-can="'budget_requests.view.commit'">
														<input class="form-check-input" type="checkbox"
															:value="project_request.project_request_id"
															v-model="commits" v-show="project_request.commitable"
															@change="setSelectAll()">
													</td>
													<td rowspan="3" class="text-center">
														<div class="d-grid gap-2">
															<button type="button" class="btn btn-sm btn-outline-primary"
																data-bs-toggle="modal"
																data-bs-target="#budgetRequestModal"
																@click.prevent="viewBudgetRequest(project_request)">
																<i class="ri-eye-line icon_hgt"></i> View
															</button>
															<template
																v-if="$store.getters.user?.escalation_role?.code == 'HQAdmin'">
																<button
																	v-if="project_request.project_request_stage?.status == 'Approved'"
																	type="button"
																	class="btn btn-sm  btn-outline-warning"
																	@click.prevent="revertBudgetRequest(project_request)">
																	<i class="ri-arrow-go-back-line icon_hgt"></i>
																	Rollback
																</button>
																<button type="button"
																	class="btn btn-sm  btn-outline-danger"
																	@click.prevent="deleteBudgetRequest(project_request)">
																	<i class="ri-delete-bin-line icon_hgt"></i> Delete
																</button>
															</template>
														</div>
													</td>
												</tr>
												<tr>
													<td colspan="10">
														<b>Subject :</b> {{ project_request.subject }}
													</td>
												</tr>
												<tr>
													<td colspan="10">
														<span class="Center">
															<ul id="progressbar">
																<span
																	v-for="status, key, in project_request.progress_bar_data"
																	:key="key">
																	<li :class="getRequestStatus(status)">
																		{{ stages[key % stages.length]?.role?.role_name
																		}}
																	</li>
																	<li v-if="key > 0 && (key + 1) % stages.length == 0"
																		:class="getStatusDot(status)">
																	</li>
																</span>
															</ul>
														</span>
													</td>
												</tr>
											</template>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
						@pagechanged="onPageChange" />
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="budgetRequestModal" tabindex="-1" aria-labelledby="budgetRequestModalLabel"
		aria-hidden="true" ref="request_modal">
		<div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="budgetRequestModalLabel">Budget Request : <span class="text-primary">{{
						project_request?.request_no }}</span>
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row g-3 mb-4">
						<div class="col-sm-3">
							<div class="profile-item mb-3">
								<i class="ri-profile-line"></i>
								<div class="profile-item-body">
									<p>WBS Code</p>
									<span class="text-primary">{{ project_request?.project_cost?.wbs_code }}</span>
								</div>
							</div>
						</div>

						<div class="col-sm-4">
							<div class="profile-item mb-3">
								<i class="ri-medal-2-line"></i>
								<div class="profile-item-body">
									<p>WBS Description</p>
									<span class="text-primary">{{ project_request?.project_cost?.wbs_description
										}}</span>
								</div>
							</div>
						</div>
						<div class="col-sm-4">
							<div class="profile-item mb-3">
								<i class="ri-profile-line"></i>
								<div class="profile-item-body">
									<p>Requested Amount </p>
									<span class="text-primary">{{ formatCurrency(project_request?.amount) }}&nbsp;{{
										currency_unit }}</span>
								</div>
							</div>
						</div>
						<div class="col-sm-6">
							<div class="profile-item mb-3">
								<i class="ri-profile-line"></i>
								<div class="profile-item-body">
									<p>Subject</p>
									<span class="text-primary">{{ project_request?.subject }}</span>
								</div>
							</div>
						</div>
						<div class="col-sm-6">
							<div class="profile-item mb-3">
								<i class="ri-profile-line"></i>
								<div class="profile-item-body">
									<p>Note</p>
									<span class="text-primary">{{ project_request?.note }}</span>
								</div>
							</div>
						</div>
						<div class="col-sm-12">
							<label for="progress"><strong>Progress</strong></label>
							<span class="Center">
								<ul id="progressbar">
									<span v-for="status, key, in project_request.progress_bar_data" :key="key">
										<li :class="getRequestStatus(status)">
											{{ stages[key % stages.length]?.role?.role_name }}
										</li>
										<li v-if="key > 0 && (key + 1) % stages.length == 0"
											:class="getStatusDot(status)">
										</li>
									</span>
								</ul>
							</span>
						</div>
					</div>

					<div class="row g-3 mb-3">
						<div class="col-12">
							<div class="table-responsive table-responsive-sm">
								<table class="table table-sm text-nowrap table-striped table-bordered mb-0">
									<thead>
										<tr class="text-center">
											<th>#</th>
											<th>Date Time</th>
											<th>Role</th>
											<th>Name</th>
											<th class="text-center">Amount [<span class="text-primary">{{ currency_unit
													}}</span>]</th>
											<th class="text-center">Quantity</th>
											<th class="text-center">Rate</th>
											<th class="text-center">Is GST?</th>
											<th class="text-center">Status</th>
											<th>Note</th>
											<th class="text-center">Attachment</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="project_request_stage, key in project_request_stages" :key="key">
											<td :style="getColor(project_request_stage.status)">
												{{ key + 1 }}
											</td>
											<td :style="getColor(project_request_stage.status)">
												{{ project_request_stage.date_time }}
											</td>
											<td :style="getColor(project_request_stage.status)">
												{{ project_request_stage?.role?.role_name }}
											</td>
											<td :style="getColor(project_request_stage.status)">
												{{ project_request_stage?.user?.name }}
											</td>
											<td :style="getColor(project_request_stage.status)" class="text-center">
												{{ formatCurrency(project_request_stage.amount) }}
											</td>
											<td :style="getColor(project_request_stage.status)" class="text-center">
												{{ project_request_stage.quantity }}
											</td>
											<td :style="getColor(project_request_stage.status)" class="text-center">
												{{ project_request_stage.rate }}
											</td>
											<td :style="getColor(project_request_stage.status)" class="text-center">
												{{ project_request_stage.is_gst ? 'Yes' : 'No' }}
											</td>
											<td :style="getColor(project_request_stage.status)" class="text-center">
												{{ project_request_stage.status }}
											</td>
											<td :style="getColor(project_request_stage.status)">
												{{ project_request_stage.note != 'null' ? project_request_stage.note :
													''
												}}
											</td>
											<td class="text-center" v-if="project_request_stage.file_attachment">
												<a :href="project_request_stage.file_attachment"
													class="btn btn-sm btn-outline-primary" target="_blank">
													<i class="ri-download-fill"></i> Download
												</a>
											</td>
											<td v-else class="text-center">-</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="table-responsive table-responsive-sm mt-4"
								v-if="project_request?.project_request_approvals?.length">
								<table class="table table-sm text-nowrap table-striped table-bordered mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th>Requested By</th>
											<th>Role</th>
											<th>Approved By</th>
											<th>Role</th>
											<th class="text-center">Attachment</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="project_request_approval, key in project_request?.project_request_approvals"
											:key="key">
											<td class="text-center">{{ key + 1 }}</td>
											<td>{{ project_request_approval?.requested_user?.name }}</td>
											<td>{{ project_request_approval?.requested_user?.role?.role_name }}</td>
											<td>{{ project_request_approval?.approved_user?.name }}</td>
											<td>{{ project_request_approval?.approved_user?.role?.role_name }}</td>
											<td class="text-center">
												<a :href="project_request_approval.attachment"
													class="btn btn-sm btn-outline-primary" target="_blank">
													<i class="ri-download-fill"></i> Download
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade" id="returnRequestModal" tabindex="-1" aria-labelledby="exampleModalLabel"
		aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="exampleModalLabel">Return Request</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="mb-3">
						<label for="note" class="form-label">Remarks/Reason (Optional)</label>
						<textarea id="note" class="form-control" v-model="project_request.note"
							:class="{ 'is-invalid': errors.note }" placeholder="Note"></textarea>
						<span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
					</div>
					<div class="mb-3">
						<label for="file_attachment" class="form-label">Attachment (Optional)</label>
						<input class="form-control" id="file_attachment" type="file"
							@change="returnFileAttachment($event)">
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="close_return_modal">Cancel</button>
					<button type="button" class="btn btn-primary" @click="returnProjectRequest()">Return</button>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade" id="rejectRequestModal" tabindex="-1" aria-labelledby="exampleModalLabel"
		aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="exampleModalLabel">Reject Request</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="mb-3">
						<label for="note" class="form-label">Remarks/Reason (Optional)</label>
						<textarea id="note" class="form-control" v-model="project_request.note"
							:class="{ 'is-invalid': errors.note }" placeholder="Note"></textarea>
						<span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
					</div>
					<div class="mb-3">
						<label for="file_attachment" class="form-label">Attachment (Optional)</label>
						<input class="form-control" id="file_attachment" type="file"
							@change="rejectFileAttachment($event)">
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="close_reject_modal">Cancel</button>
					<button type="button" class="btn btn-primary" @click="rejectProjectRequest()">Reject</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
let Search = require("@/components/Search.vue").default;
export default {
	components: { Pagination, Search },
	data() {
		return {
			meta: {
				search: "",
				order_by: "desc",
				keyword: "project_request_stages.date_time",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				to: 1,
				maxPage: 1,
				from_date: '',
				to_date: '',
				wbs_code: '',
				stage_id: '',
				status: '',
				project_group_id: '',
				project_id: '',
				extra: '',
			},
			project_groups: [],
			projects: [],
			from_path: '',
			project_requests: [],
			project_request: {
				file_attachment: '',
				note: ''
			},

			update_request: {
				update_requests: []
			},
			stages: [],
			cost_codes: [],
			project_request_stages: [],
			request_modal: 'modal fade',
			approvals: [],
			project_request_approval: {},
			forward_request: true,
			project_cost: {},
			edit_wbs_code: false,
			project_costs: [],
			select_all: false,
			commits: [],
			no_of_commitables: 0,
			currency_value: 1,
			currency_unit: 'Rs',
			errors: []
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (to.query.from == 'dashboard') {
				vm.meta.status = to.query.type ? to.query.type : '';
				vm.meta.stage_id = to.query.stage_id ? to.query.stage_id : '';
				vm.meta.project_group_id = to.query.project_group_id ? to.query.project_group_id : '';
				vm.meta.project_id = to.query.project_id ? to.query.project_id : '';
				vm.meta.extra = to.query.extra ? to.query.extra : '';
			}
			else if (vm.$store.getters.project_request_meta != null) {
				let meta = vm.$store.getters.project_request_meta;
				vm.meta.from_date = meta.from_date;
				vm.meta.to_date = meta.to_date;
				vm.meta.stage_id = meta.stage_id;
				vm.meta.status = meta.status;
				vm.meta.project_group_id = meta.project_group_id;
				vm.meta.project_id = meta.project_id;
				vm.meta.extra = meta.extra;
			}
			vm.index();
			vm.getProjectGroups()
		});
	},

	methods: {
		index() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "paginateProjectRequest", data: vm.meta })
				.then(function (response) {
					vm.project_requests = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.to = response.data.meta.to;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
					vm.getStages();
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		getStages() {
			let vm = this;
			this.$store.dispatch('post', { uri: 'getStages' })
				.then(response => {
					vm.stages = response.data.data;
					this.getNoOfCommits();
				})
				.catch(error => {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				})
		},

		getNoOfCommits() {
			this.project_requests.forEach(ele => {
				if (ele.commitable) {
					this.no_of_commitables++;
				}
			})
		},

		setSelectAll() {
			if (this.no_of_commitables == this.commits.length) {
				this.select_all = true;
			}
			else {
				this.select_all = false;
			}
		},

		forwardRequest(project_request) {
			let vm = this;
			vm.$store.dispatch("setProjectRequestMeta", vm.meta);
			vm.$router.push('/BATS/' + project_request.project_id + '/' + project_request.project_cost_id + '/request/' + project_request.project_request_id);
		},

		rejectRequest(project_request) {
			if (!confirm('Do you want to reject the request')) return;
			$('#rejectRequestModal').modal('show');
			this.project_request = project_request;
			this.project_request.note = '';
			this.project_request.file_attachment = '';
		},

		rejectFileAttachment(e) {
			this.project_request.file_attachment = e.target.files[0];
		},

		rejectProjectRequest() {
			let vm = this;
			let loader = vm.$loading.show();
			const formData = new FormData()
			formData.append('project_request_id', vm.project_request.project_request_id)
			formData.append('note', vm.project_request.note)
			formData.append('file_attachment', vm.project_request.file_attachment);
			this.$store.dispatch('post', { uri: 'rejectProjectRequest', data: formData })
				.then(response => {
					loader.hide();
					vm.$refs.close_reject_modal.click();
					vm.$store.dispatch('success', 'Budget request has been rejected');
					vm.search();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		holdRequest(project_request) {
			if (!confirm('Do you want to hold the project request ?')) return;
			let vm = this;
			let loader = vm.$loading.show();
			vm.$store.dispatch('post', { uri: 'holdProjectRequest', data: project_request })
				.then(response => {
					loader.hide();
					vm.$store.dispatch('success', 'Budget request has been updated');
					vm.search()
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		returnRequest(project_request) {
			if (!confirm('Budget request will be returned!')) return;
			$('#returnRequestModal').modal('show');
			this.project_request = project_request;
			this.project_request.note = '';
			this.project_request.file_attachment = '';
		},

		returnFileAttachment(e) {
			this.project_request.file_attachment = e.target.files[0];
		},

		returnProjectRequest() {
			let vm = this;
			let loader = vm.$loading.show();
			const formData = new FormData()
			formData.append('project_request_id', vm.project_request.project_request_id)
			formData.append('note', vm.project_request.note)
			formData.append('file_attachment', vm.project_request.file_attachment);
			this.$store.dispatch('post', { uri: 'returnProjectRequest', data: formData })
				.then(response => {
					loader.hide();
					vm.$refs.close_return_modal.click();
					vm.$store.dispatch('success', response.data.message);
					vm.search();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		commitProjectRequest() {
			let vm = this;
			let loader = vm.$loading.show();
			vm.$store.dispatch('post', { uri: 'commitProjectRequest', data: { project_request_ids: this.commits } })
				.then(response => {
					loader.hide();
					vm.$store.dispatch('success', response.data.message);
					vm.select_all = false;
					vm.commits = [];
					vm.no_of_commitables = 0;
					vm.search();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		search() {
			let vm = this
			vm.meta.page = 1
			vm.index()
		},

		sort(field) {
			this.meta.keyword = field;
			this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
			this.search();
		},

		onPageChange(page) {
			this.meta.page = page;
			this.index();
		},

		onPerPageChange() {
			this.meta.page = 1;
			this.index();
		},

		viewBudgetRequest(project_request) {
			this.project_request = project_request;
			this.project_request_stages = project_request.project_request_stages;
			this.setRequestView();
		},

		getRequestStatus(stage_status) {
			if (stage_status == 'Requested') {
				return 'requested'
			}
			if (stage_status == 'Approved') {
				return 'approved'
			}
			if (stage_status == 'Forwarded') {
				return 'forwarded';
			}
			if (stage_status == 'Pending') {
				return 'pending'
			}
			if (stage_status == 'Skipped') {
				return 'default';
			}
			if (stage_status == 'Rejected') {
				return 'rejected';
			}
			if (stage_status == 'Pre Approved') {
				return 'skipped';
			}
			if (stage_status == 'Direct') {
				return 'skipped';
			}
			if (stage_status == 'Returned') {
				return 'returned';
			}
			if (stage_status == 'Default') {
				return 'default';
			}
		},

		getStatusDot(stage_status) {
			if (stage_status == 'Requested') {
				return 'blue-dot';
			}
			if (stage_status == 'Approved') {
				return 'green-dot';
			}
			if (stage_status == 'Forwarded') {
				return 'lime-dot';
			}
			if (stage_status == 'Pending') {
				return 'orange-dot';
			}
			if (stage_status == 'Skipped' || stage_status == 'Pre Approved' || stage_status == 'Direct') {
				return 'black-dot';
			}
			if (stage_status == 'Rejected') {
				return 'red-dot';
			}
			if (stage_status == 'Returned') {
				return 'violet-dot';
			}
			if (stage_status == 'Default') {
				return 'default1';
			}
			return 'default1';
		},

		setRequestView() {
			let flag = false;
			let request_stages = this.project_request_stages.filter(ele => {
				let flag = false;
				if (ele.status == 'Requested' && ele.is_latest == 0) {
					flag = true;
				}
				if (ele.status == 'Forwarded' && ele.is_latest == 0) {
					flag = true;
				}
				if (ele.status == 'Pre Approved' && ele.is_latest == 0) {
					flag = true;
				}
				if (ele.status == 'Pending' && ele.is_latest == 1) {
					flag = true;
				}
				if (ele.status == 'Approved' && ele.is_latest == 1) {
					flag = true;
				}
				if (ele.status == 'Approved' && ele.is_latest == 0) {
					flag = true;
				}
				if (ele.status == 'Rejected' && ele.is_latest == 1) {
					flag = true;
				}
				if (ele.status == 'Direct' && ele.is_latest == 1) {
					flag = true;
				}
				if (ele.status == 'Returned' && ele.is_latest == 0) {
					flag = true;
				}
				if (flag) {
					return ele;
				}
			});
			this.project_request_stages = request_stages;
		},

		selectAll() {
			this.commits = [];
			if (this.select_all) {
				this.project_requests.forEach(ele => {
					if (ele.commitable) {
						this.commits.push(ele.project_request_id);
					}
				})
			}
		},

		getColor(status) {
			if (status == 'Returned') {
				return "color: violet;";
			}
			if (status == 'Rejected') {
				return "color: red;";
			}
			if (status == 'Approved') {
				return "color: green;";
			}
			if (status == 'Pending') {
				return "color: orange;";
			}
			if (status == 'Requested') {
				return "color: blue;";
			}
			if (status == 'Forwarded') {
				return "color: lightseagreen;";
			}
			if (status == 'Pre Approved') {
				return "color: black;";
			}
		},

		formatCurrency(amount) {
			amount = amount / this.currency_value;
			if (this.currency_value == 1) {
				this.currency_unit = 'Rs';
				return amount.toLocaleString("en-IN", { maximumFractionDigits: 0, style: "currency", currency: "INR" });
			}
			else if (this.currency_value == 100000) {
				this.currency_unit = 'Lakh';
				return amount.toLocaleString("en-IN", { maximumFractionDigits: 2, style: "currency", currency: "INR" });
			}
			else {
				this.currency_unit = 'Cr';
				return amount.toLocaleString("en-IN", { maximumFractionDigits: 2, style: "currency", currency: "INR" });
			}
		},

		getProjectGroups() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getProjectGroups" })
				.then(function (response) {
					vm.project_groups = response.data.data
					if (vm.project_groups.length != 0 && vm.meta.project_group_id != '') {
						vm.meta.project_group_id = vm.meta.project_group_id;
						vm.getProjects();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		getProjects() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getProjectGroupActiveProjects", data: vm.meta })
				.then(function (response) {
					vm.projects = response.data
					if (vm.projects.length != 0 && vm.meta.project_id != '') {
						vm.meta.project_id = vm.meta.project_id;
					}
					vm.search();
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		deleteBudgetRequest(project_request) {
			if (confirm('Budget request ' + project_request.request_no + ' will be deleted permanently!')) {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('post', { uri: 'deleteProjectRequest', data: project_request })
					.then(response => {
						loader.hide();
						vm.$store.dispatch('success', 'Budget request has been successfully deleted');
						vm.onPageChange(vm.meta.page)
					})
					.catch(error => {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error', error.response.data.message);
					})
			}
		},

		revertBudgetRequest(project_request) {
			if (confirm('Budget request ' + project_request.request_no + ' will be reverted to the previous stage')) {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('post', { uri: 'revertProjectRequest', data: project_request })
					.then(response => {
						loader.hide();
						vm.$store.dispatch('success', 'project request has been successfully reverted!');
						vm.onPageChange(vm.meta.page)
					})
					.catch(error => {
						loader.hide();
						vm.$store.dispatch('error', error.response.data.message);
					})
			}
		},
	},
};
</script>
<style scoped>
.btn-sm {
	--bs-btn-padding-y: 0.1rem;
}

#progressbar {
	padding: 0px;
}

#progressbar li {
	list-style-type: none;
	font-size: 8px;
	/* width: 25%; */
	width: 80px;
	float: left;
	position: sticky;
	font-weight: 400;
}

#progressbar :before {
	font-family: FontAwesome;
	content: "";
}

#progressbar li:before {
	width: 10px;
	height: 10px;
	display: block;
	color: #ffffff;
	background: lightgray;
	border-radius: 20%;
	padding: 2px;
}

#progressbar li:after {
	content: "";
	width: 100%;
	height: 2px;
	background: lightgray;
	position: absolute;
	left: 0;
	top: 5px;
	z-index: -1;
}

#progressbar li.requested:before,
#progressbar li.requested:after {
	background: blue;
}

#progressbar li.approved:before,
#progressbar li.approved:after {
	background: green;
}

#progressbar li.forwarded:before,
#progressbar li.forwarded:after {
	background: lightseagreen;
}

#progressbar li.returned:before,
#progressbar li.returned:after {
	background: violet;
}

#progressbar li.pending:before,
#progressbar li.pending:after {
	background: orange;
}

#progressbar li.default:before,
#progressbar li.default:after {
	background: lightgray;
	/* position: relative; */
}

#progressbar li.skipped:before,
#progressbar li.skipped:after {
	background: black;
	/* position: relative; */
}

#progressbar li.rejected:before,
#progressbar li.rejected:after {
	background: red;
	/* position: relative; */
}

#progressbar li.default1:before,
#progressbar li.default1:after {
	background: lightgray;
	position: relative;
}

#progressbar li.blue-dot:before,
#progressbar li.blue-dot:after {
	background: blue;
	position: relative;
}

#progressbar li.green-dot:before,
#progressbar li.green-dot:after {
	background: green;
	position: relative;
}

#progressbar li.lime-dot:before,
#progressbar li.lime-dot:after {
	background: lightseagreen;
	position: relative;
}

#progressbar li.violet-dot:before,
#progressbar li.violet-dot:after {
	background: violet;
	position: relative;
}

#progressbar li.red-dot:before,
#progressbar li.red-dot:after {
	background: red;
	position: relative;
}

#progressbar li.orange-dot:before,
#progressbar li.orange-dot:after {
	background: orange;
	position: relative;
}

#progressbar li.black-dot:before,
#progressbar li.black-dot:after {
	background: black;
	position: relative;
}

/* .Center {
	float: left;
} */
</style>
