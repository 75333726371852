<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">Budget</li>
			</ol>
			<h4 class="main-title mb-0">Dashboard</h4>
		</div>
		<div class="d-flex align-items-center gap-2 mt-3 mt-md-0">
			<ol class="breadcrumb fs-sm mb-1">
				<h4 class="breadcrumb-item">{{ project_group_name }}</h4>
				<h4 class="breadcrumb-item">{{ project_name }}</h4>
			</ol>
			<router-link :to="'/projects?project_group_id='+project_group_id+'&project_id='+project_id" class="btn btn-primary d-flex align-items-center gap-2" v-can="'budget_requests.view'">
				<i class="ri-add-line fs-18 lh-1"></i>New Budget Request
			</router-link>
		</div>
	</div>
	<div class="row g-2 mb-3">
		<div class="col-sm-4">
			<div class="list-group">
				<button class="list-group-item d-flex justify-content-between align-items-center active">
					Project Groups
					<span class="label">
						{{ project_groups.length }}
					</span>
				</button>
				<template v-for="project_group in project_groups">
					<button @click="getProjects(project_group)" class="list-group-item d-flex justify-content-between align-items-center" :class="project_group_id==project_group.project_group_id?'list-group-item-success':''">
						{{ project_group.project_group_name }} 
						<span class="badge text-bg-primary rounded-pill">
							&raquo;
						</span>
					</button>
				</template>
			</div>
		</div>
		<div class="col-sm-4">
			<div class="list-group">
				<button class="list-group-item d-flex justify-content-between align-items-center active">
					Projects
					<span class="label">
						{{ projects.length }}
					</span>
				</button>
				<template v-for="project in projects">
					<button @click="openProject(project)" class="list-group-item d-flex justify-content-between align-items-center" :class="project_id==project.project_id?'list-group-item-success':''">
						{{ project.project_code }} : {{ project.project_name }} 
						<span class="badge text-bg-primary rounded-pill">
							&raquo;
						</span>
					</button>
				</template>
			</div>
		</div>
		<div class="col-sm-4">
			<div class="row">
				<template v-if="$store.getters.user.role.role_code=='CFD'">
					<div class="col-sm-12 mb-3" @click="openRequestList('Approved','')" style="opacity: 0.8;">
						<div class="task-category p-1 bg-warning">
							<h2 class="category-percent mb-1">{{ cfd_requests }}</h2>
							<h6 class="category-title mb-1 small">Total Pending Requests</h6>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="col-sm-6 mb-2" @click="openRequestList('Approved','','')">
						<div class="task-category p-1 bg-success">
							<h2 class="category-percent mb-1">{{ approved_requests }}</h2>
							<h6 class="category-title mb-1 small">Total Approved Requests</h6>
						</div>
					</div>
					<div class="col-sm-6 mb-2" @click="openRequestList('Pending','','')" style="opacity: 0.8;">
						<div class="task-category p-1 bg-warning">
							<h2 class="category-percent mb-1">{{ pending_requests }}</h2>
							<h6 class="category-title mb-1 small">Total Pending Requests</h6>
						</div>
					</div>
					<template v-if="$store.getters.user.role.role_group_id==2">
						<div class="col-sm-6 mb-2" @click="openRequestList('Pending','5','')" style="opacity: 0.8;">
							<div class="task-category p-1 bg-warning">
								<h2 class="category-percent mb-1">{{ ho_pending_requests }}</h2>
								<h6 class="category-title mb-1 small">Pending Requests @ HO</h6>
							</div>
						</div>
						<div class="col-sm-6 mb-2" @click="openRequestList('Pending','','plant')" style="opacity: 0.8;">
							<div class="task-category p-1 bg-warning">
								<h2 class="category-percent mb-1">{{ plant_pending_requests }}</h2>
								<h6 class="category-title mb-1 small">Pending Requests @ Plant</h6>
							</div>
						</div>
					</template>
					<div class="col-sm-6 mb-2" @click="openRequestList('Pending', stage_id,'my')">
						<div class="task-category p-1" style="background-color:rgb(204, 85, 0); opacity: 0.8;">
							<h2 class="category-percent mb-1">{{ pending_approvals }}</h2>
							<h6 class="category-title mb-1 small">My Pending Approvals</h6>
						</div>
					</div>
					<div class="col-sm-6 mb-2" @click="openRequestList('Pending', stage_id,'new')">
						<div class="task-category p-1" style="background-color:rgb(204, 85, 0); opacity: 0.8;">
							<h2 class="category-percent mb-1">{{ new_pending_approvals }}</h2>
							<h6 class="category-title mb-1 small">New Approvals</h6>
						</div>
					</div>
					<div class="col-sm-6 mb-2" @click="openRequestList('Pending', stage_id,'delay')">
						<div class="task-category p-1" style="background-color:rgb(204, 85, 0); opacity: 0.8;">
							<h2 class="category-percent mb-1">{{ delayed_pending_approvals }}</h2>
							<h6 class="category-title mb-1 small">Delayed Approvals</h6>
						</div>
					</div>
					<div class="col-sm-6 mb-2" @click="openRequestList('Pending','','hold')">
						<div class="task-category p-1" style="background-color:rgb(204, 85, 0); opacity: 0.8;">
							<h2 class="category-percent mb-1">{{ hold_requests }}</h2>
							<h6 class="category-title mb-1 small">Hold Requests</h6>
						</div>
					</div>
					<template v-if="$store.getters.user.role.role_group_id==2">
						<div class="col-sm-6 mb-2" @click="openRequestList('Pending', stage_id,'pr')">
							<div class="task-category p-1" style="background-color:rgb(204, 85, 0); opacity: 0.8;">
								<h2 class="category-percent mb-1">{{ pr_pending_approvals }}</h2>
								<h6 class="category-title mb-1 small">PR Pending Approvals</h6>
							</div>
						</div>
						<div class="col-sm-6 mb-2" @click="openRequestList('Returned','','returnHoPending')">
							<div class="task-category p-1" style="background-color:Violet;">
								<h2 class="category-percent mb-1">{{ pending_ho_returned_requests }}</h2>
								<h6 class="category-title mb-1 small">Pending Returned Requests @ HO</h6>
							</div>
						</div>
						<div class="col-sm-6 mb-2" @click="openRequestList('Returned','','cfd')">
							<div class="task-category p-1" style="background-color:Violet;">
								<h2 class="category-percent mb-1">{{ cfd_returned_requests }}</h2>
								<h6 class="category-title mb-1 small">CFD Returned Requests</h6>
							</div>
						</div>
					</template>
					<div class="col-sm-6 mb-2" @click="openRequestList('Returned','','')">
						<div class="task-category p-1" style="background-color:Violet;">
							<h2 class="category-percent mb-1">{{ returned_requests }}</h2>
							<h6 class="category-title mb-1 small">Total Returned Requests</h6>
						</div>
					</div>
					<div class="col-sm-6 mb-2" @click="openRequestList('Rejected','','')">
						<div class="task-category p-1" style="background-color: red; opacity: 0.6;">
							<h2 class="category-percent mb-1">{{ rejected_requests }}</h2>
							<h6 class="category-title mb-1 small">Total Rejected Requests</h6>
						</div>
					</div>
					<div class="col-sm-6 mb-2" title="click to open existing projects!">
						<router-link :to="'/projects?project_group_id='+project_group_id+'&project_id='+project_id">
							<div class="task-category p-1 bg-secondary" style="opacity: 0.6;">
								<h2 class="category-percent mb-1">{{ project_costs }}</h2>
								<h6 class="category-title mb-1 small">Total WBS</h6>
							</div>
						</router-link>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Dashboard.BATS",
	components: {},
	data() {
		return {
			project_costs: 0,
			pending_requests: 0,
			ho_pending_requests:0,
			plant_pending_requests:0,
			returned_requests: 0,
			pending_ho_returned_requests:0,
			cfd_returned_requests: 0,
			pending_approvals: 0,
			pr_pending_approvals: 0,
			new_pending_approvals: 0,
			delayed_pending_approvals: 0,
			approved_requests: 0,
			rejected_requests: 0,
			cfd_requests: 0,
			hold_requests:0,
			stage_id: '',

			project_groups:[],
			projects:[],
			project_group_name:'All',
			project_group_id:'',
			project_name:'All',
			project_id:'',
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (from.name == "login") {
				vm.$router.go();
			}
			else {
				vm.getProjectGroups();
				vm.getBatsDashboardCount();
			}
		});
	},
	methods: {
		getBatsDashboardCount() {
			let vm = this;
			let data = {
				project_group_id:vm.project_group_id,
				project_id:vm.project_id
			}
			vm.$store.dispatch("post", { uri: "getBatsDashboardCount",data:data })
			.then(function (response) {
				vm.project_costs = response.data['project_costs'];
				vm.pending_requests = response.data['pending_requests'];
				vm.ho_pending_requests = response.data['ho_pending_requests'];
				vm.plant_pending_requests = response.data['plant_pending_requests'];
				vm.returned_requests = response.data['returned_requests'];
				vm.pending_approvals = response.data['pending_approvals'];
				vm.new_pending_approvals = response.data['new_pending_approvals'];
				vm.delayed_pending_approvals = response.data['delayed_pending_approvals'];
				vm.approved_requests = response.data['approved_requests'];
				vm.rejected_requests = response.data['rejected_requests'];
				vm.cfd_requests = response.data['cfd_requests'];
				vm.pr_pending_approvals = response.data['pr_pending_approvals'];
				vm.cfd_returned_requests = response.data['cfd_returned_requests'];
				vm.pending_ho_returned_requests = response.data['pending_ho_returned_requests'];
				vm.hold_requests = response.data['hold_requests'];
				vm.getStageId();
			})
			.catch(function (error) {
				vm.errors = error.response.data.error;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},

		getProjectGroups() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getProjectGroups" })
			.then(function (response) {
				vm.project_groups = response.data.data
				if(vm.project_groups.length!=0) {
					vm.project_group_id = vm.project_groups[0].project_group_id;
					vm.project_group_name = vm.project_groups[0].project_group_name;
					vm.getProjects(vm.project_groups[0])
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		getProjects(project_group) {
			let vm = this;
			this.project_group_id = project_group.project_group_id;
			this.project_group_name = project_group.project_group_name;
			this.project_id ='';
			this.project_name = 'All';
			vm.$store.dispatch("post", { uri: "getProjectGroupActiveProjects", data:project_group })
			.then(function (response) {
				vm.projects = response.data
				vm.getBatsDashboardCount()
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		openProject(project) {
			this.project_id = project.project_id;
			this.project_name = project.project_name;
			this.getBatsDashboardCount()
		},

		getStageId() {
			this.$store.dispatch('post', { uri: 'getStageId' })
			.then(response => {
				this.stage_id = response.data;
			})
		},

		openRequestList(type, stage_id,extra) {
			this.$router.push('/project_cost/pending_request?type=' + type + '&stage_id=' + stage_id + '&project_group_id=' + this.project_group_id + '&project_id=' + this.project_id+'&from=dashboard&extra='+extra);
		},
	},

};
</script>

<style scoped>
.background {
	border: 2px solid #000 !important;
	box-shadow: 5px 5px 10px #fc894d;
}
</style>