<template>
    <!-- <div class="pb-3"> -->
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <div class="d-flex gap-2">
                    <h5 class="my-auto">Overall Dashboard</h5>
                    <div class="d-flex gap-2" v-if="$store.getters?.user?.role?.role_group?.role_group_code=='TM'">
                        <router-link class="btn btn-sm btn-primary btn-sm " to="/project_dashboard">Project </router-link>
                        <router-link class="btn btn-sm btn-primary btn-sm " to="/pmtchart">Daily </router-link>
                    </div>
                </div>
                
                <div class="d-flex align-items-right gap-2 mt-5 mt-md-0">
                    <div class="d-flex align-items-right">
                        <label class="me-2">Project&nbsp;Groups: </label>
                        <select class="form-control form-control-sm me-2" v-model="project_section_group.project_group_id">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                        <button class="btn btn-primary btn-sm" @click="getProjectCostBarChart()">Get</button>
                    </div>
                </div>
            </div>
            <div class="card-body" style="padding-top: 1px !important;padding-bottom: 1px !important;">
                <div class="row">
                    <div class="col-sm-3 mb-1">
                        <div class="card card-one">
                            <div class="card-header border-bottom-none ">
                                <h6 class="card-title" style="font-size: 11px !important;">Progress Status</h6>
                            </div>
                            <div class="card-body">
                                <Donut :container="'donuta'" :height="'50%'" :title="donuta.title" :max="donuta.max" :series="donuta.series" :plotoption="donuta.plotoption"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3 mb-1">
                        <div class="card card-one">
                            <div class="card-header border-bottom-none  mb-0">
                                <h6 class="card-title" style="font-size: 11px !important;">Cost (INR Cr)</h6>
                            </div>
                            <div class="card-body" style="padding-top: 0px !important;padding-bottom: 0px !important;">
                                <BarChart
                                    :type="'column'"
                                    :container="'barchartb'"
                                    :title="barchartb.title"
                                    :yAxis="barchartb.yAxis"
                                    :xAxis="barchartb.xAxis"
                                    :series="barchartb.series"
                                    :stacking="barchartb.stacking"
                                    :labels="barchartb.labels"
                                    :xAxisLables="barchartb.xAxisLables"
                                    :yAxisLables="barchartb.yAxisLables"
                                    :height="'60%'"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 mb-1">
                        <div class="card card-one">
                            <div class="card-header border-bottom-none ">
                                <h6 class="card-title" style="font-size: 11px !important;">Engineering</h6>
                            </div>
                            <div class="card-body">
                                <Donut :container="'donutb'" :height="'50%'" :title="donutb.title" :max="donutb.max" :series="donutb.series" :plotoption="donutb.plotoption" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 mb-1">
                        <div class="card card-one">
                            <div class="card-header border-bottom-none ">
                                <h6 class="card-title" style="font-size: 11px !important;">Procurement</h6>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <BarChart
                                            :type="'column'"
                                            :container="'barchartc'"
                                            :title="barchartc.title"
                                            :subtitle="barchartc.subtitle"
                                            :yAxis="barchartc.yAxis"
                                            :xAxis="barchartc.xAxis"
                                            :series="barchartc.series"
                                            :stacking="barchartc.stacking"
                                            :labels="barchartc.labels"
                                            :xAxisLables="barchartc.xAxisLables"
                                            :yAxisLables="barchartc.yAxisLables"
                                            :height="'105%'"
                                            :label_font_size="'12px'"
                                        >
                                        </BarChart>
                                        <span style="font-size:9pt;margin-left:25px;">Tech Completed</span>
                                    </div>
                                    <div class="col-sm-6">
                                        <BarChart
                                            :type="'column'"
                                            :container="'barchartd'"
                                            :title="barchartd.title"
                                            :subtitle="barchartd.subtitle"
                                            :yAxis="barchartd.yAxis"
                                            :xAxis="barchartd.xAxis"
                                            :series="barchartd.series"
                                            :stacking="barchartd.stacking"
                                            :labels="barchartd.labels"
                                            :xAxisLables="barchartd.xAxisLables"
                                            :yAxisLables="barchartd.yAxisLables"
                                            :height="'105%'"
                                            :label_font_size="'12px'"
                                        >
                                        </BarChart>
                                        <span style="font-size:9pt;margin-left:25px;">Order Completed</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 mb-3">
                        <div class="card card-one">
                            <div class="card-header">
                                <h6 class="card-title" style="font-size: 13px !important;">Project Details for Plant Capex</h6>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-responsive-sm" style="max-height: 220px; overflow-y: auto;">
                                    <table class="table table-sm text-nowrap table-bordered mb-0" >
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th class="text-center">Project Type</th>
                                                <th class="text-center">Project</th>
                                                <th class="text-center">Status</th>
                                                <th class="text-center">Overall Plan</th>
                                                <th class="text-center">Overall Actual</th>
                                                <th class="text-center">Capacity</th>
                                                <th class="text-center">Baseline Finish</th>
                                                <th class="text-center">Forecast Finish</th>
                                                <th class="text-center">Sanction (in Cr)</th>
                                                <th class="text-center">Pl. Committed <br/> (in Cr)</th>
                                                <th class="text-center">Act. Committed <br/> (in Cr)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="project, key in projects" :key="key">
                                                <td><span class="ff-numerals">{{  key + 1 }}</span></td>
                                                <td><span class="ff-numerals">{{project.project_type.project_type_name}}</span></td>
                                                <td><span class="ff-numerals">{{project.project_name}}</span></td>
                                                <td class="text-center">
                                                    <div class="d-flex align-items-center gap-2" >
                                                        <span :class="project.color"></span>
                                                        <div>
                                                           {{ project.status }}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="progress w-100">
                                                        <div class="progress-bar" role="progressbar" :style="{width: project.overall_plan+ '%'}" style="background-color: #03bbd4;" :aria-valuenow="project.overall_plan" aria-valuemin="0" aria-valuemax="100">{{project.overall_plan + '%'}}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="progress w-100">
                                                        <div class="progress-bar" role="progressbar" :style="{width: project.overall_actual+ '%'}" style="background-color: #ffa500;"  :aria-valuenow="project.overall_actual" aria-valuemin="0" aria-valuemax="100">{{project.overall_actual + '%'}}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-for="cap, key1 in project.project_capacities" :key="key1">
                                                        {{ cap.capacity_value.capacity_name }} : {{ cap.capacity }} <span v-if="cap.capacity" style="font-size:8pt">MTPA</span> 
                                                    </div>
                                                </td>
                                                <td>{{ changeDateFormat(project.base_line_end_date) }}</td>
                                                <td>{{ changeDateFormat(project.forecast_end_date) }}</td>
                                                <td class="text-end">{{ croreValue(project.budget) }}</td>
                                                <td class="text-end">{{ croreValue(project.commit)}}</td>
                                                <td class="text-end">{{ croreValue(project.actual_spent) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>
<script>
    import { Chart } from "highcharts-vue";
    import { ColumnChart } from "highcharts-vue";
    import Heatmap from "@/components/charts/Heatmap";
    import Donut from "@/components/charts/Donut";
    import BarChart from "@/components/charts/BarChart";
    import GaugeMeter from "@/components/charts/GaugeMeter";
    import moment from "moment";

    export default {
        name: "Dashboard",
        components: {
            highcharts: Chart,
            ColumnChart,
            Heatmap,
            Donut,
            BarChart,
            GaugeMeter,
        },
        data() {
            return {
                show_project_category: false,
                show_project_type: false,
                show_project: false,
                show_department: false,
                type: "",
                projectgroups:[],
                chartOptions: {
                    series: [
                        {
                            data: [1, 2, 3], // sample data
                        },
                    ],
                },
                project_category: {
                    title: "Project Categories",
                    xAxis: ["Happy", "Sprint"],
                    yAxis: ["Percentage"],
                    series: [
                        [0, 0, 10],
                        [1, 0, 92],
                    ],
                    // series:[
                    //     {
                    //         x:0,
                    //         y:0,
                    //         value:10,
                    //         color:'#FF0000'
                    //     },
                    //     {
                    //         x:1,
                    //         y:0,
                    //         value:92,
                    //         color:'#008000'
                    //     }
                    // ]
                },

                project_type: {
                    title: "Project Types",
                    xAxis: ["IU", "GU"],
                    yAxis: ["Percentage"],
                    series: [
                        [0, 0, 10],
                        [1, 0, 92],
                    ],
                },

                project: {
                    title: "Projects",
                    xAxis: ["KUCW", "BLCW", "PR1", "PR2"],
                    yAxis: ["Percentage"],
                    series: [
                        [0, 0, 10],
                        [1, 0, 92],
                        [2, 0, 10],
                        [3, 0, 92],
                    ],
                },

                department: {
                    title: "Department",
                    xAxis: ["Civil", "Mechanical", "Electrical", "C&I", "Refractory", "Sheeting", "Insulation", "Crane Planning"],
                    yAxis: ["Percentage"],
                    series: [
                        [0, 0, 10],
                        [1, 0, 92],
                        [2, 0, 10],
                        [3, 0, 92],
                        [4, 0, 10],
                        [5, 0, 92],
                        [6, 0, 10],
                        [7, 0, 92],
                    ],
                },

                chart: {
                    xAxis: {
                        categories: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007],
                    },
                    series: [
                        {
                            name: "Installation",
                            color: "#03bbd4",
                            data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
                        },
                        {
                            name: "Manufacturing",
                            color: "#f7a35c",
                            data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
                        },
                    ],
                },

                // Project Dashboard TM
                donuta: {
                    // title:"3",
                    title: "",
                    max: 0,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            dataLabels: {
                                enabled: true,
                                format: "{y}",
                            },
                            showInLegend: true,
                        },
                        series: {
                            borderWidth: 0,
                            colorByPoint: true,
                            type: "pie",
                            size: "100%",
                            innerSize: "80%",
                            dataLabels: {
                                enabled: true,
                                crop: false,
                                distance: "-10%",
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                },
                                connectorWidth: 0,
                            },
                            events: {
                                click: function (e) {
                                    e.preventDefault(); // prevent any action from occuring on "click" event
                                },
                            },
                        },
                    },
                    // series:[{
                    //     name: 'Delay',
                    //     y: 2,
                    //     color: '#f70000',
                    // }, {
                    //     name: 'On Track',
                    //     y: 1,
                    //     color: '#7bf700',
                    // },],
                    series: [],
                },
                donutb: {
                    // title:"3",
                    title: "",
                    max: 0,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            dataLabels: {
                                enabled: true,
                                format: "{y}",
                            },
                            showInLegend: true,
                        },
                        series: {
                            borderWidth: 0,
                            colorByPoint: true,
                            type: "pie",
                            size: "100%",
                            innerSize: "80%",
                            dataLabels: {
                                enabled: true,
                                crop: false,
                                distance: "-10%",
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                },
                                connectorWidth: 0,
                            },
                            events: {
                                click: function (e) {
                                    e.preventDefault(); // prevent any action from occuring on "click" event
                                },
                            },
                        },
                    },
                    // series:[{
                    //     name: 'Delay',
                    //     y: 2,
                    //     color: '#f70000',
                    // }, {
                    //     name: 'On Track',
                    //     y: 1,
                    //     color: '#7bf700',
                    // },],
                    series: [],
                },

                barchartb: {
                    title: "",
                    // yAxis: [0,1,2,3,4,5,6,7,8,9,10],
                    // xAxis: ['Jul. 23', 'Aug. 23', 'Sep. 23', 'Oct. 23'],
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "",
                    labels: "",
                    series: [
                        // {
                        //     name: "Sanction",
                        //     data: [1510],
                        //     color: "#26aaac",
                        // },
                        // {
                        //     name: "Committed",
                        //     data: [1092],
                        //     color: "#198de4",
                        // },
                        // {
                        //     name: "Actual Spend",
                        //     data: [758],
                        //     color: "#bbad02",
                        // },
                    ],
                },

                barchartc: {
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "",
                    labels: [],
                    series: []
                },
                barchartd: {
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "",
                    labels: "",
                    series: [],
                },
                barcharte: {
                    title: "Engineering",
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "normal",
                    series: [
                        // {
                        //     name: "Pending",
                        //     data: [5],
                        //     color: "#ff3e3e",
                        // },
                        // {
                        //     name: "Delivered",
                        //     data: [40],
                        //     color: "#1b8ae2",
                        // },
                    ],
                    labels: "%",
                },

                gaugemeterb: {
                    title: "Overall Procurement",
                    type: "solidgauge",
                    max: 0,
                    series: [71],
                },
                project_section_group: {
                    current_month_year: "",
                    project_group_id:'',
                },
                projects:[],
                delay:0,
                on_track:0,
                na:0
            };
        },
        mounted() {
            if(this.$store.getters.user){
                this.getProjectGroups();
            }
            // this.getDounetChart(); 
            
            // this.getDonutGraph();
            
            // this.getProjectCostBarChart();        
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                // console.log(from);
                if (from.name == "login") {
                    vm.$router.go();
                }
            });
        },

        methods: {
            croreValue(value){
                return value?(value/10000000).toFixed(2):0
            },
            changeDateFormat(date){
                return moment(date).format('DD-MMM-YYYY')
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        // if (vm.projectgroups.length) {
                        //     if (!vm.project_section_group.project_group_id) {
                        //         vm.project_section_group.project_group_id = vm.projectgroups[0].project_group_id;
                        //     }
                        // }
                        // vm.getProjectRequestApproved()
                        if(localStorage.getItem('project_group_id')){
                            vm.project_section_group.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.project_section_group.project_group_id) {
                                vm.project_section_group.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjectCostBarChart();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getDonutGraph(){
                let vm = this;
                let delay = 0;
                let on_track = 0;
                let na = 0;
                vm.donuta.series=[];
                vm.projects.map(function(project){
                    // if(project.overall_actual == 0 && project.overall_plan == 0){
                    //     na = na + 1;
                    // }else if(project.overall_actual >= project.overall_plan){
                    //     on_track = on_track + 1;
                    // }else if(project.overall_actual < project.overall_plan){
                    //     delay = delay + 1;
                    // }

                    if(project.overall_actual == 0 || project.overall_actual == '' ){
                        na = na + 1;
                    }else if(project.overall_actual == 100){
                        on_track = on_track + 1;
                    }else{
                        delay = delay + 1;
                    }
                })
                
                vm.donuta.series.push(
                    {
                        name: "In Progress",
                        y: delay,
                        color: "#FFA500",
                    },
                    {
                        name: "Completed",
                        y: on_track,
                        color: "#228b22",
                    },
                    {
                        name: "Not Started",
                        y: na,
                        color: "#989898",
                    }
                );
                
            },

            calculateplan(plan_data) {
                //Linechart
                let vm = this;
                let over_all_weightage = 0;
                for (let i = 0; i < plan_data.project_activity.length; i++) {
                    over_all_weightage = over_all_weightage + Number(plan_data.project_activity[i].scope);
                }

                let data = [];
                let parameter_value = 0;
                let parameter_scope_value = 0;
                let scope_value = 0;
                let parameter_scope_multiply = 0;

                for (let j = 0; j < plan_data.project_parameters_latest.length; j++) {
                    parameter_value = Number(plan_data.project_parameters_latest[j].job_parameter.parameter_value);
                    scope_value = Number(plan_data.project_parameters_latest[j].job_parameter.project_parameter_type.project_activity.scope);
                    parameter_scope_multiply = parameter_value * scope_value;
                    parameter_scope_value = parameter_scope_value + parameter_scope_multiply;
                }
                return parameter_scope_value / over_all_weightage;
                
            },

            getProjectCostBarChart() {
                let vm = this;
                localStorage.setItem('project_group_id', vm.project_section_group.project_group_id)
                vm.project_section_group.current_month_year = moment().format("MMM-YY");
                let uri = { uri: "projectCostBarChart", data: vm.project_section_group };
                vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.barchartb.series = [
                            {
                                name: "Sanction",
                                data: [response.data.budget],
                                color: "#26aaac",
                            },
                            {
                                name: "Committed",
                                data: [response.data.commit],
                                color: "#198de4",
                            },
                            {
                                name: "Actual Spent",
                                data: [response.data.actual_spent],
                                color: "#bbad02",
                            }
                        ]
                        vm.getEngineeringDonut();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getEngineeringDonut() {
                let vm = this;
                vm.project_section_group.current_month_year = moment().format("MMM-YY");
                let uri = { uri: "engineeringDonut", data: vm.project_section_group };
                vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.donutb.series = []
                        vm.donutb.series.push(
                            {
                                name: "Plan",
                                y: response.data.plan,
                                color: "#03bbd4",
                            },
                            {
                                name: "Actual",
                                y: response.data.actual,
                                color: "#FFA500",
                            }
                        );
                        vm.getProcurementBarchart()
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProcurementBarchart() {
                let vm = this;
                vm.project_section_group.current_month_year = moment().format("MMM-YY");
                let uri = { uri: "procurementBarchart", data: vm.project_section_group };
                vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.barchartc.series = []
                        vm.barchartd.series = []
                        vm.barchartc.series = [
                            {
                                name: "Scope",
                                data: [parseFloat(response.data.tech_scope)],
                                color: "#03bbd4",
                            },
                            {
                                name: "Completed",
                                data: [parseFloat(response.data.tech_completed)],
                                color: "#FFA500",
                            },
                            {
                                name: "Balance",
                                data: [parseFloat(response.data.tech_balance)],
                                color: "#899499",
                            }
                        ]

                        vm.barchartd.series.push(
                            {
                                name: "Scope",
                                data: [parseFloat(response.data.order_scope)],
                                color: "#03bbd4",
                            },
                            {
                                name: "Completed",
                                data: [parseFloat(response.data.order_completed)],
                                color: "#FFA500",
                            },
                            {
                                name: "Balance",
                                data: [parseFloat(response.data.order_balance)],
                                color: "#899499",
                            }
                        );
                        vm.getOverallDashboardProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getOverallDashboardProjects() {
                let vm = this;
                vm.projects = []
                vm.project_section_group.current_month_year = moment().format("MMM-YY");
                let uri = { uri: "overallDashboardProjects", data: vm.project_section_group };
                vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.projects = response.data.data
                        vm.getDonutGraph()
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            showProjectCategory(type) {
                this.type = type;
                this.show_project_category = true;
                this.show_project_type = false;
                this.show_project = false;
                this.show_department = false;
            },
            showProjectType() {
                this.show_project_type = true;
            },
            showProject() {
                this.show_project = true;
            },
            showDepartment() {
                if (this.type == "pmt") this.show_department = true;
            },
        },
    };
</script>

<style scoped>
    .vertical_line:after {
        content: "";
        background-color: #d9d9d9;
        position: absolute;
        width: 2px;
        height: 200px;
        top: 10px;
        left: 40%;
        display: block;
    }
    /* ul {
  list-style-type: none;
}
li{
    font-weight: 700;
    font-size: 16px;
} */
    .green_dot {
        width: 15px;
        height: 15px;
        background-color: green;
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        /* margin: auto; */
    }
    .orange_dot {
        width: 15px;
        height: 15px;
        background-color: #FFA500;
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        /* margin: auto; */
    }
    .red_dot {
        width: 15px;
        height: 15px;
        background-color: red;
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        /* margin: auto; */
    }
    .progress,
    .progress-bar {
        height: 25px;
    }
    .card-one {
        /* border-color: #e2e5ec; */
        border: 1px solid #e2e5ec;
    }
    .border-bottom-none {
        border-bottom: none;
    }
    thead{
        position: -webkit-sticky;
        position: sticky;
        background-color: white !important;
        outline: 1px solid #e2e5ec;
        outline-offset: -1px ;
        top: 0; left: 0;                       
        &:first-child {
            z-index: 3;
            
        }
    }
</style>
