<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">PMT Master Template</h2>
            <router-link to="/templates" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-list-check"></i> PMT Master Templates</router-link>
        </div>
        <div class="card">
            <div class="card-header">
                <strong>New PMT Master Template</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                        <label class="form-label">Templates</label>
                        <select class="form-select form-select-sm" v-model="template.template_id" @change="getTemplateSectionGroups()">
                            <option value="">Select Template</option>
                            <option v-for="template, key in templates" :key="key" :value="template.template_id">{{ template.template_name +'-'+ template.activity_group.activity_group_name }} </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Template Section Groups</label>
                        <select class="form-select form-select-sm" v-model="template.template_section_group_id" @change="getTemplateSections()">
                            <option value="">Select Template Section Group</option>
                            <option v-for="template_section_group, key in template_section_groups" :key="key" :value="template_section_group.template_section_group_id">{{ template_section_group.section_group.section_group_name}} </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Template Sections</label>
                        <select class="form-select form-select-sm" v-model="template.template_section_id" @change="getTemplateActivitties()">
                            <option value="">Select Template Section</option>
                            <option v-for="template_section, key in template_sections" :key="key" :value="template_section.template_section_id">{{ template_section.section.section_name}} </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Template Activities</label>
                        <select class="form-select form-select-sm" v-model="template.template_activity_id">
                            <option value="">Select Template Activity</option>
                            <option v-for="template_activity, key in template_activities" :key="key" :value="template_activity.template_activity_id">{{ template_activity.activity.activity_name}} </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger btn-sm me-2" @click.prevent="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                <button class="btn btn-primary btn-sm" @click="addParameters()"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            template:{
                template_id:'',
                template_section_group_id:'',
                template_section_id:'',
                template_activity_id:''
            },
            templates:[],
            template_section_groups:[],
            template_sections:[],
            template_activities:[],
            parameter_types:[]
        }
    },
    mounted(){
        if(this.$store.getters?.user){
            this.getTemplates()
            this.getParameterTypes()
        }
        
    },
    methods:{
        getTemplates() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "showTemplates" })
                .then(function (response) {
                    vm.templates = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getTemplateSectionGroups(){
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getTemplateSectionGroups", data:vm.template })
                .then(function (response) {
                    vm.template_section_groups = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getTemplateSections(){
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getTemplateSections", data:vm.template })
                .then(function (response) {
                    vm.template_sections = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getTemplateActivitties(){
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getTemplateActivities", data:vm.template })
                .then(function (response) {
                    vm.template_activities = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getParameterTypes(){
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getParameterTypes" })
                .then(function (response) {
                    vm.parameter_types = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        addParameters(){
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "addMultipleParameters", data:vm.template })
                .then(function (response) {
                    vm.parameter_types = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
    }
}
</script>