<template>
    <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Mechanical </strong>
            </div>

            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                         <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="project.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Project</label>
                        <!-- <select class="form-select form-select-sm" :class="{ 'is-invalid': errors.project_id }" v-model="project.project_id">
                            <option value="">Select Project</option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name}} </option>
                        </select> -->
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="project.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @input="project1 => project.project_id = project1"
                        >
                        </search>
                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">From Date</label>
                        <div class="input-group date" id="date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.from_date }" v-model="project.from_date" />
                        </div>
                        <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">To Date</label>
                        <div class="input-group date" id="date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.to_date }" v-model="project.to_date" />
                        </div>
                        <span v-if="errors.to_date" style="margin-top: 0.25rem;font-size: 0.875em;color: #dc3545;">{{ errors.to_date[0] }}</span>
                    </div>
                    <div class="col-md-12 mt-1">
                        <div style="float:right">
                        <button class="btn btn-sm btn-primary me-2" @click="getMechSummary()" type="button"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-warning me-2" @click="downloadCivilMechReport()"><i class="ri-download-line icon_hgt"></i> Download </button>
                        <button class="btn btn-sm btn-info me-2" @click="sendEmailCivilMechReport()"><i class="ri-mail-send-line icon_hgt"></i> Send Email </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mb-4">
                <div class="table-responsive table-responsive-sm">
                    <table class="table table-sm text-nowrap table-bordered mb-0">
                        <thead>
                            <tr class="bg-primary bg-opacity-50">
                                <th rowspan="2" class="text-center fw-bold" style="vertical-align:middle;">Sl No.</th>
                                <th rowspan="2" class="text-center fw-bold" style="vertical-align:middle;">Section</th>
                                <th colspan="3" class="text-center fw-bold">Fabrication Scope (MT)</th>
                                <th colspan="4" class="text-center fw-bold" style="vertical-align:middle;">Erection Scope (MT)</th>
                                <th colspan="4" class="text-center fw-bold">Fabrication Achieved (MT)</th>
                                <th colspan="5" class="text-center fw-bold">Erection Achieved (MT)</th>
                            </tr>
                            <tr class="bg-primary bg-opacity-50">
                                <th class="text-center fw-bold">Structure</th>
                                <th class="text-center fw-bold">Plate</th>
                                <th class="text-center fw-bold">Total Scope</th>
                                <th class="text-center fw-bold">Structure</th>
                                <th class="text-center fw-bold">Plate</th>
                                <th class="text-center fw-bold">Equipment</th>
                                <th class="text-center fw-bold">Total</th>  
                                <th class="text-center fw-bold">Structure</th>
                                <th class="text-center fw-bold">Plate</th>
                                <th class="text-center fw-bold">Total</th>
                                <th class="text-center fw-bold">% Complete</th>
                                <th class="text-center fw-bold">Structure</th>
                                <th class="text-center fw-bold">Plate</th>
                                <th class="text-center fw-bold">Equipment</th>
                                <th class="text-center fw-bold">Total</th>
                                <th class="text-center fw-bold">% Complete</th>
                            </tr>
                        </thead>
                        <tbody v-for="section_group, key in mech_summary" :key="key">
                            <tr>    
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="text-left fs-12 bg-primary bg-opacity-25"><strong>{{ section_group.section_group.section_group_name }}</strong></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>
                                <td class="bg-primary bg-opacity-25"></td>

                            </tr>
                            <template v-for="section, key in section_group.project_sections" :key="key">
                                <tr v-if="eliminateZeros(section)">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ section.section.section_name }}</td>
                                    <td class="text-center">{{ section.fabrication_str_scope }}</td>
                                    <td class="text-center">{{ section.fabrication_plt_scope }}</td>
                                    <td class="text-center">{{ section.total_fabrication_scope }}</td>
                                    <td class="text-center">{{ section.erection_str_scope }}</td>
                                    <td class="text-center">{{ section.erection_plt_scope }}</td>
                                    <td class="text-center">{{ section.erection_eq_scope }}</td>
                                    <td class="text-center">{{ section.total_erection_scope }}</td>
                                    <td class="text-center">{{ section.fabrication_str_ctd }}</td>
                                    <td class="text-center">{{ section.fabrication_plt_ctd }}</td>
                                    <td class="text-center">{{ section.total_fabrication}}</td>
                                    <td class="text-center">{{ parseFloat(section.fabrication_percentage.toFixed(3)) }}</td>
                                    <td class="text-center">{{ section.erection_str_ctd}}</td>
                                    <td class="text-center">{{ section.erection_pl_ctd}}</td>
                                    <td class="text-center">{{ section.erection_eq_ctd}}</td>
                                    <td class="text-center">{{ section.total_erection}}</td>
                                    <td class="text-center">{{ parseFloat(section.erection_percentage.toFixed(3)) }}</td>
                                </tr>
                            </template>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let Search = require("@/components/Search.vue").default;
import moment from 'moment';
    export default {
        name: "MechanicalSummary",
        components: { Search },
        data() {
            return {
                initial:true,
                project:{
                    project_group_id:'',
                    project_id:'',
                    from_date:'',
                    to_date:'',
                    isDownload:false
                },
                projects:[],
                errors:[],
                mech_summary:[],
                projectgroups: [],
            }
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectGroups();
            }   
            this.project.to_date = moment().format('yyyy-MM-DD')
            
        },

        methods: {
            eliminateZeros(section){
                if(section.total_fabrication || section.total_erection){
                    return section
                }else{
                    return false
                }
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.project.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.project.project_group_id) {
                                vm.project.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjects() {
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects",data:vm.project })
                    .then(function (response) {
                        vm.projects = response.data;
                        if(localStorage.getItem('project_id')){
                            vm.project.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.project.project_id = vm.projects[0].project_id;
                        }
                        if(vm.initial){
                            vm.getMechSummary()
                            vm.initial = false
                        }
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            sendEmailCivilMechReport(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "downloadCivilMechSummary", data: vm.project})
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Mechanical summary - Mail sent successfully");
                    })
                    .catch(function (error) {
                        loader.hide()
                        if(error.response.data.length){
                            console.log(error.response.data[1])
                            vm.$store.dispatch("error", error.response.data[1]);
                        }
                    });
            },
            getMechSummary(){
                let vm = this;
                localStorage.setItem('project_group_id', vm.project.project_group_id)
                localStorage.setItem('project_id', vm.project.project_id)
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "mechanicalSummary", data: vm.project})
                    .then(function (response) {
                        loader.hide();
                        vm.mech_summary = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            downloadCivilMechReport(){
                let vm = this;
                vm.errors = [];
                if (vm.project.project_id == ""){
                    vm.errors.project_id = ["The project field is required."]
                }
                if (vm.project.to_date == ""){
                    vm.errors.to_date = ["The To Date field is required."]
                }
                if (vm.project.project_id && vm.project.to_date ){
                    window.open(vm.$store.getters.apiUrl+'downloadCivilMechSummary?project_id='+vm.project.project_id+'&to_date='+vm.project.to_date+'&from_date='+vm.project.from_date+'&isDownload=true');
                }
            }
        }
    }
</script>
<style scoped>
.invalid-feedback1 {
    display: none !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}
</style>