<template>
    <div>

        <div :class="{bold: isFolder}" @click="toggle">
            <span v-if="isFolder">[{{ isOpen ? '-' : '+' }}] </span>
            <span><input type="radio" class="radio_button" :checked="item?.selected" name="activity_group" @click="setSelectedItem(item)"></span>
            <!-- <span v-if="item.module=='project_activity_group_id'"><input type="radio" class="radio_button" :checked="item?.selected" name="activity_group" @click="setSelectedItem(item)"></span> -->

            {{ item?.name }}
        </div>
        <ul v-show="isOpen" v-if="isFolder">
            <PMTTree class="item" v-for="(child, index) in item.children.filter(ele=>{if(ele.length!=0) return ele})" :key="index" :item="child"/>
        </ul>
        
    </div>
</template>
<script>
    export default {
        props: {
            item: Object,
        },
        data: function () {
            return {
                isOpen: false,
                item_status:false,
                section:{},
                selected:''

            };
        },
        computed: {
            isFolder: function () {
                let item = this.item[0]
                // let item = this.$store.getters.item
                
                let vm = this
                if(this.item.module=='project_id'){
                    // this.item.children[0].selected = true
                        // this.setSelectedItem(this.item.children[0])
                    this.isOpen = true
                }
                if(item){
                    if(item.parents){
                        let selected = item.parents.filter(function(element){
                            return element.module == vm.item.module && element.id == vm.item.id
                        })
                        if(selected.length){
                            this.isOpen = true
                        }
                    }
                    
                    if(this.item.module == item.module && this.item.id == item.id){
                        this.item.selected = true
                    }

                    
                    
                    // this.getParents(item)
                    // let selected = item.parents.filter(fucntion(element){
                    //     return element.module == vm.item.module && element.id == vm.item.id
                    // })
                }else{
                    if(this.item.module=='project_id'){
                        let selected_tree = this.item.children.filter(function(element){
                            return element.name=="Refractory"
                        });
                        if(selected_tree.length>0){
                            selected_tree[0].selected = true
                            this.setSelectedItem(selected_tree[0])
                        }
                        else{
                            this.item.children[0].selected = true
                            this.setSelectedItem(this.item.children[0])
                        }
                    }                    
                }
                let radio = document.getElementsByClassName('radio_button1')
                for(let i=0;i< radio.length;i++){
                    radio[i].checked = false
                }
                return this.item.children_length;
            },
        },

        mounted(){
        },

        methods: {
            setSelectedItem(item) {
                let vm = this
                console.log('itemselect:----',item)
                vm.$store.dispatch("setItem", item)
            },
            toggle: function () {
                let child_obj = {}
                let vm = this
                if(this.item.child_url && !this.item.children.length){
                    child_obj[this.item.module] = this.item[this.item.module]
                    let uri = { uri: this.item.child_url, data: child_obj };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.item.children = response.data.data
                        })
                        .catch(function (error) {
                            console.log(error.response.data)
                        });
                }
                if (this.isFolder) {
                    this.isOpen = !this.isOpen;
                }
            },
            makeFolder: function () {
                if (!this.isFolder) {
                    this.$emit("make-folder", this.item);
                    this.isOpen = true;
                }
            },
          
        },
    };
</script>
<style scoped>
    .item {
        cursor: pointer;
    }
    .catalog-code {
        display: inline-flex;
        justify-content: space-between;
        flex-shrink: 0;
        min-width: 65px;
        height: 17px;
        font-size: 12px;
        background-color: #ececec;
        transition-property: color, background-color;
        transition-duration: 0.3s;
        cursor: default;
    }

    /* govshop css */
    .cv-catalog-list-item__fake-checkbox:before {
        top: 1px;
        left: 0;
        width: 13px;
        height: 13px;
        border: 1px solid #cfd5e0;
        border-radius: 1px;
        transition-property: background-color, border-color;
        transition-duration: 0.3s;
    }

    .catalog-filter-modal__content .catalog-list-wrap {
        flex: 1 1;
    }
    .catalog-list-select-all {
        display: inline-block;
        position: relative;
        padding-left: 21px;
        margin-bottom: 10px;
    }
    .catalog.has-scrollbar .catalog-list {
        padding-bottom: 24px;
    }
    .catalog-filter-modal__content .catalog-list {
        padding-bottom: 20px;
    }

    .cv-catalog-list {
        padding-bottom: none;
    }
    .catalog-list-select-all__checkbox {
        display: none;
    }
    .catalog-list-select-all__button.button {
        position: static;
        cursor: pointer;
    }
    .button-text {
        border-bottom: 1px dotted var(--accent);
        color: var(--accent);
        padding: 0;
        transition-property: color, border-color;
        transition-duration: 0.3s;
    }

    .button_weight-regular {
        font-weight: 400;
    }
    .cv-catalog-list-item.has-checkboxes {
        padding-left: 41px;
    }
    .cv-catalog-list-item {
        position: relative;
        display: flex;
        padding-left: 20px;
        margin-bottom: 5px;
    }
    .cv-catalog-list-item__label-checkbox {
        display: inline-block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 13px;
        height: 13px;
        cursor: pointer;
    }
    .cv-catalog-list-item.has-checkboxes .cv-catalog-list-item__expand-button {
        left: 21px;
    }
    .cv-catalog-list-item__expand-button {
        position: absolute;
        top: 6px;
        left: 0;
    }
    .cv-expand-button {
        position: relative;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        border: 1px solid #9b9b9b;
        background: none;
        background-color: transparent;
        cursor: pointer;
        line-height: 1;
    }
    .cv-expand-button svg {
        width: 5px;
        height: 5px;
        line-height: 1;
        fill: #6b6b6b;
    }

    .icon {
        width: 1em;
        height: 1em;
    }
    .cv-catalog-list-item__code {
        margin-right: 8px;
    }
    .hovertext:hover {
        color: #40a4f1 !important;
    }
    .ex3 {
        background-color: lightblue;

        height: 110px;
        overflow: auto;
    }

    .cv-catalog-list-item__fake-checkbox:before {
        top: 1px;
        left: 0;
        width: 13px;
        height: 13px;
        border: 1px solid #cfd5e0;
        border-radius: 1px;
        transition-property: background-color, border-color;
        transition-duration: 0.3s;
    }

    .cv-catalog-list-item__title, .cv-catalog-list-item__link{
        color: rgb(89, 93, 110);
        font-size: 13px;
    }
</style>
