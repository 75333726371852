<template>
    <div class="">
        <!-- <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">PMT Master Templates</h2>
            <router-link v-can="'templates.create'" to="/templates/create" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-add-line"></i> Add PMT Master Template</router-link>
        </div> -->
        <div class="card">
            <div class="card-header">
                <div class="d-sm-flex align-items-center justify-content-between">
                    <div>
                        <strong > PMT Master Templates</strong>
                    </div>
                    <div>
                        <router-link v-can="'templates.create'" to="/templates/create" type="submit" class="btn btn-sm btn-primary"><i class="ri-add-line"></i> Add PMT Master Template</router-link>
                    </div>
                </div>
                <!-- <strong > PMT Master Templates</strong>
                <router-link v-can="'templates.create'" to="/templates/create" type="submit" class="btn btn-sm mb-2 btn-primary" style="float:right;"><i class="ri-add-line"></i> Add PMT Master Template</router-link> -->
            </div>
            <div class="card-body">
                <div class="form-group mb-2 d-flex">
                    <input class="form-control form-control-sm" type="text" v-model="meta.search" placeholder="Type keyword and press enter key" @keypress.enter="search()" />
                    <a @click="deleteValues()" v-if="delete_values!= 0" href="javascript:void(0)" class="btn btn-sm btn-primary ms-2 "><i class="ri-delete-bin-line icon_hgt"></i></a>
                </div>
                <div class="table-responsive table-responsive-sm">
                    <table class="table table-sm text-nowrap table-bordered mb-0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th @click="sort('template_code')">
                                    Template Code
                                    <span>
                                        <i v-if="meta.keyword=='template_code' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='template_code' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('template_name')">
                                    Template Name
                                    <span>
                                        <i v-if="meta.keyword=='template_name' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='template_name' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('project_type_id')">
                                    Project Type
                                    <span>
                                        <i v-if="meta.keyword=='project_type_id' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='project_type_id' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('activity_group_id')">
                                    Activity Group
                                    <span>
                                        <i v-if="meta.keyword=='activity_group_id' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                        <i v-else-if="meta.keyword=='activity_group_id' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th class="text-center">Status</th>
                                <th v-can="'templates.delete'" class="text-center">Actions</th>
                                <!-- <th class="text-center"><input type="checkbox" /></th> -->
                                <th v-can="'templates.delete'" class="text-center"><input type="checkbox" :checked="isAllSelected" @change="selectAll"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!templates.length"><td colspan="8" class="text-center">No records found</td></tr>
                            <tr v-for="template, key in templates" :key="key">
                                <td class="text-center">{{ meta.from+key }}</td>
                                <td>{{ template.template_code }}</td>
                                <td>{{ template.template_name }}</td>
                                <td>{{ template.project_type?.project_type_name }}</td>
                                <td>{{ template.activity_group?.activity_group_name }}</td>
                                <td v-can="'templates.delete'" class="text-center">
                                    <!-- <div class="form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" v-model="template.status" />
                                    </div> -->

                                    <div class="form-switch form-switch">
                                      <input class="form-check-input" type="checkbox" role="switch" :id="'template' + template.template_id" :checked="template.status" :value="template.status" @change="deleteTemplate(template)" />
                                      <label class="custom-control-label" :for="'template' + template.template_id"></label>
                                  </div>
                                </td>
                                <td class="text-center">
                                    <nav>
                                        <a v-can="'templates.update'" href="javascript:void(0)" class="text-success me-2" @click="showTemplate(template)"><i class="ri-pencil-line icon_hgt"></i></a>
                                        <!-- <a href="javascript:void(0)" class="text-danger"><i class="ri-delete-bin-line icon_hgt"></i></a> -->
                                        <a href="javascript:void(0)" class="text-success me-1" title="add" @click="updateTemplate(template.template_id)"><i class="ri-record-circle-line icon_hgt"></i></a>
                                    </nav>
                                </td>
                                <!-- <td class="text-center"><input type="checkbox" /></td> -->
                                <td v-can="'templates.delete'" class="text-center"><input type="checkbox" v-model="delete_values" :value="template.template_id" @change="select"/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                    <select class="form-select from-select-sm width-75">
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </select>
                    <!-- <span>Showing 1 to 4 of 4 entries</span> -->
                    <!-- <button class="btn btn-primary " @click="getTemplate()"><i class="ri-save-line icon_hgt"></i> Get Template</button>  -->

                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components:{ Pagination },
        data() {
            return {
                template:{
                    template_id:''
                },
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "template_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                templates:[],
                isAllSelected: false,
                delete_values: [],
             
            };
        },
    

        mounted(){
            if(this.$store.getters?.user){
                this.paginateTemplates()
            }
            
        },
      
        methods: {
            search(){
                 this.meta.page = 1
                this.paginateTemplates()
               
            },
            viewCivil() {
                this.$router.push("/viewTemplateCivil");
                // this.$router.push("/hospitals/" + hospital.hospital_id + "/view");
            },
            viewMech() {
                this.$router.push("/viewTemplateMech");
            },
            viewei(){
                this.$router.push("/viewTemplateEI");
            },
            viewRF(){
                this.$router.push("/viewTemplateRefractory");
            },

            showTemplate(template){
                this.$router.push('template/'+template.template_id+'/update');
            },

            paginateTemplates(){
                let vm = this
                let uri = { uri: "paginateTemplates", data: vm.meta };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.templates = response.data.data
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        console.log(error)
                        // loader.hide()
                        
                    });
            },

            updateTemplate(template_id){
                let vm = this
                let data={
                    "template_id":template_id
                }
                let uri = { uri: "updateProjectTemplateData", data: data };
                let loader = vm.$loading.show();
                vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    // console.log("response", response)
                    loader.hide()
                    vm.$store.dispatch("success", "Template updated successfully");
                })
                .catch(function (error) {
                    console.log(error)
                    loader.hide()
                });
            }, 
            onPageChange(page) {
                this.meta.page = page
                this.paginateTemplates();
            },
            onPerPageChange() {                
                this.meta.page = 1
                this.paginateTemplates();
            },

            deleteTemplate(template_data) {
                let vm = this;
                let loader = vm.$loading.show();
                template_data.status = template_data.status == 1 ? 0 : 1;
                console.log('   deleting template ' + template_data)
                vm.$store
                    .dispatch("post", { uri: "deleteTemplate", data: template_data })
                    .then(function (response) {
                        loader.hide()
                        vm.$store.dispatch("success", response.data.message);
                        vm.paginateTemplates();
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            deleteValues() {
                confirm("Are You Sure Want to delete?");
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "multipleDeleteTemplates", data:{template_id: this.delete_values}})
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "User is successfully deleted.");
                    vm.isAllSelected = false
                    vm.delete_values = []
                    vm.paginateTemplates();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            selectAll() {
                if (this.isAllSelected) {
                    this.delete_values = [];
                    this.isAllSelected = false;
                } else {
                    this.delete_values = [];
                    for (var template in this.templates) {
                        this.delete_values.push(this.templates[template].template_id);
                    }
                    this.isAllSelected = true;
                }
            },
            select() {
                if (this.delete_values.length !== this.templates.length) {
                    this.isAllSelected = false;
                } else {
                    this.isAllSelected = true;
                }
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.paginateTemplates();
            },
        },
    };
</script>
<style scoped>
</style>
