<template>
	<div class="">
		<form @submit.prevent="submitForm">
			<div class="card">
				<div class="card-header">
					<strong v-if="status">New User</strong>
					<strong v-else>Update User</strong>
					<router-link to="/users" type="submit" class="btn btn-sm btn-primary" style="float:right;"><i class="ri-list-check"></i>
						USERS</router-link>
				</div>
				<div class="card-body mb-3">
					<div class="row g-3">
						<div class="col-md-4">
							<label class="form-label">Name</label><span class="text-danger"> *</span>
							<input type="text" placeholder="Name" class="form-control" :class="{ 'is-invalid': errors.name }"
								v-model="user.name" ref="name" />
							<span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
						</div>
						<div class="col-md-4">
							<label class="form-label">Email</label><span class="text-danger"> *</span>
							<input type="email" placeholder="Email" class="form-control"
								:class="{ 'is-invalid': errors.email }" v-model="user.email" />
							<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
						</div>
						<div class="col-md-4">
							<label class="form-label">Password</label><span class="text-danger"> *</span>
							<input :disabled="!status" type="password" placeholder="Password" class="form-control"
								:class="{ 'is-invalid': errors.password }" v-model="user.password" />
							<span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
						</div>
						<div class="col-md-4">
							<label class="form-label">Role</label><span class="text-danger"> *</span>
							<select class="form-control" :class="{ 'is-invalid': errors.role_id }" v-model="user.role_id">
								<option value="">Select Role</option>
								<optgroup v-for="role_group, role_group_key in roles" :key="role_group_key"
									:label="role_group[0].role_group.role_group_name">
									<option v-for="role, key in role_group" :key="key" :value="role.role_id">
										{{ role.role_name }}</option>
								</optgroup>
							</select>
							<span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
						</div>
						<div class="col-md-4">
							<label class="form-label">User Type</label><span class="text-danger"> *</span>
							<select class="form-control" :class="{ 'is-invalid': errors.user_type }"
								v-model="user.user_type">
								<option value="">Select User Type</option>
								<option v-for="user_type, key in user_types" :key="key" :value="user_type">{{ user_type }}
								</option>
							</select>
							<span v-if="errors.user_type" class="invalid-feedback">{{ errors.user_type[0] }}</span>
						</div>
						<div class="col-md-4" v-if="user.user_type == 'BATS' || user.user_type == 'BOTH'">
							<label class="form-label">Escalation Role</label>
							<select class="form-control" :class="{ 'is-invalid': errors.role_id }" v-model="user.escalation_role_id">
								<option :value="null">Select Role</option>
								<optgroup v-for="role_group, i in escalation_roles" :key="i" :label="role_group[0]?.role_group?.role_group_name">
									<option v-for="role, j in role_group" :key="j" :value="role.escalation_role_id">
										{{ role_group[0]?.role_group?.role_group_name }} :: {{role.name }}
									</option>
								</optgroup>								
							</select>
							<span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
						</div>
						<div class="col-md-4">
							<label class="form-label">Mobile No.</label><span class="text-danger"> *</span>
							<input type="text" placeholder="Mobile No." class="form-control"
								:class="{ 'is-invalid': errors.mobile_no }" v-model="user.mobile_no" />
							<span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
						</div>
						<div class="col-md-12">
							<label class="form-label">Address</label>
							<textarea type="text" placeholder="Address" class="form-control"
								:class="{ 'is-invalid': errors.address }" v-model="user.address"></textarea>
							<span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
						</div>

					</div>
				</div>
				<div class="card-footer text-end">
					<button type="reset" class="btn btn-danger me-2" @click.prevent="discard()"><i
							class="ri-close-line icon_hgt"></i> DISCARD</button>
					<button v-if="status" type="submit" class="btn btn-primary"><i class="ri-save-line icon_hgt"></i>
						SUBMIT</button>
					<button v-else type="submit" class="btn btn-primary"><i class="ri-save-line icon_hgt"></i>
						UPDATE</button>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
export default {
	name: "Users.Create",
	components: {},
	data() {
		return {
			status: true,
			user: {
				user_id: "",
				name: "",
				email: "",
				password: "",
				mobile_no: "",
				address: "",
				role_group_id: "",
				role_id: "",
				escalation_role_id: null,
				role_group_name: '',
				plants: [],
				user_type: ''
			},
			users: [],
			roles: [],
			plants: [],
			user_types: ['PMT', 'BATS', 'BOTH'],
			escalation_roles: [],
			errors: [],
			functional_roles: [],
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.getRoles();
			vm.getEscalationRoles();
			if (to.name == "Users.Create") {
				vm.$refs.name.focus();
			} else {
				vm.status = false;
				let uri = { uri: "getUser", data: { user_id: to.params.user_id } };
				vm.$store
					.dispatch("post", uri)
					.then(function (response) {
						vm.user = response.data.data;
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		});
	},
	watch: {
		'user.role_id': function () {
			if (this.user.role_id) {
				this.getRole()
			}
		},
	},
	methods: {
		getRole() {
			let vm = this
			// console.log(vm.roles)
			for (const [key, value] of Object.entries(vm.roles)) {
				// console.log(`${key}: ${value}`)
				let role = value.filter(function (element) {
					return element.role_id == vm.user.role_id
				})
				// console.log("role")
				// console.log(role)
				if (role.length) {
					vm.user.role_group_name = role[0].role_group.role_group_name
					if (!vm.plants.length)
						vm.getPlants()
				}
			}
			// let role = this.roles.filter(function(element){
			//     element.role_id == vm.user.role_id
			// })
			// if(role.length){
			//     vm.user.role_name = role[0].role_name
			//     if(!vm.plants.length)
			//         getPlants()
			// }
		},

		getPlants() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getPlants", data: vm.meta })
				.then(function (response) {
					vm.plants = response.data.data
				})
				.catch(function (error) {
					// console.log(error)
					vm.errors = error.response.data.error;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getFunctionalRoles() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getFunctionalRoles", data: '' })
				.then(function (response) {
					// console.log('getFunctionalRoles:----',response.data.data);
					vm.functional_roles = response.data.data
				})
				.catch(function (error) {
					// console.log(error)
					vm.errors = error.response.data.error;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		submitForm() {
			let vm = this;
			if (vm.status) {
				vm.create();
			} else {
				vm.update();
			}
		},
		create() {
			let vm = this;
			let loader = vm.$loading.show();
			let uri = { uri: "addUser", data: vm.user };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "User is successfully created");
					vm.$router.push("/users");
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		update() {
			let vm = this;
			let loader = vm.$loading.show();
			let uri = { uri: "updateUser", data: vm.user };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "User is successfully updated");
					vm.$router.push("/users");
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		discard() {
			let vm = this;
			vm.user.name = "";
			vm.user.email = "";
			vm.user.password = "";
			vm.user.mobile_no = "";
			vm.user.address = "";
			vm.user.role_id = "";
			vm.user.role_group_id = "";
			vm.$refs.name.focus();
			vm.errors = [];
			vm.status = true;
		},
		getRoles() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getRoles" })
				.then(function (response) {
					vm.roles = response.data;
					// console.log("roles",response.data);
				})
				.catch(function (error) {
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getEscalationRoles(){
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getEscalationRoles" })
				.then(function (response) {
					vm.escalation_roles = response.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				});
		}
	},
};
</script>
<style scoped>
.dropdown {
	position: relative;
	cursor: pointer;
}

.multiselect {
	position: relative;
}

.multiselect ul {
	border: 1px solid #ddd;
	border-top: 0;
	border-radius: 0 0 3px 3px;
	left: 0px;
	padding: 8px 8px;
	top: -0.1rem;
	width: 100%;
	list-style: none;
	max-height: 150px;
	overflow: auto;
	background: white;
}

.overselect {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}</style>