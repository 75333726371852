<template>
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
        <div>
            <h6 class="main-title mb-0">Project Dashboard</h6>
        </div>
        <div class="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <div class="d-flex align-items-center">
                <label class="me-2">Projects: </label>
                <select class="form-control form-control-sm" v-model="project_section_group.project_id" @change="getOverallPlan()">
                    <option value="">Select Project</option>
                    <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name }}</option>
                </select>
            </div>
            <div class="d-flex gap-2">
                <router-link v-can="'dashboard.bats'" to="/bats_dashboard" class="btn btn-primary d-flex align-items-center gap-2 btn-sm me-2">BATS Dashboard</router-link>
                <router-link to="/admin_dashboard" class="btn btn-primary d-flex align-items-center gap-1 btn-sm">Overall Dashboard</router-link>
                <router-link to="/project_dashboard1" class="btn btn-primary d-flex align-items-center gap-1 btn-sm">Project Dashboard1</router-link>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xl-3 mb-2">
            <div class="card card-one">
                <div class="card-body py-0">
                    <div class="row">
                        <div v-if="project_activity_group_id" class="col-sm-12">
                            <GaugeMeter
                                :container="'gaugemetera'"
                                :title="gauge_meter_a.title"
                                :max="gauge_meter_a.max"
                                :series="gauge_meter_a.series"
                                :type="gauge_meter_a.type"
                                :plot_bands="gauge_meter_a.plot_bands"
                                :actual="gauge_meter_a.actual"
                                :plan="gauge_meter_a.plan"
                                :label_font_size="gauge_meter_a.label_font_size"
                                ref="child"
                                :height="gauge_meter_a.height"
                                :width="gauge_meter_a.width"
                            />
                            <div class="text-center"><label class="f1">Completion in :&nbsp; </label><span class="f2"> {{ gauge_meter_a.completion_days}} Days</span></div>
                        </div>
                        <div v-else class="col-sm-12">
                            <GaugeMeter
                                :container="'gaugemetera'"
                                :title="gauge_meter_a.title"
                                :max="gauge_meter_a.max"
                                :series="gauge_meter_a.series"
                                :type="gauge_meter_a.type"
                                :plot_bands="gauge_meter_a.plot_bands"
                                :actual="gauge_meter_a.actual"
                                :plan="gauge_meter_a.plan"
                                :label_font_size="gauge_meter_a.label_font_size"
                                ref="child"
                                :height="gauge_meter_a.height"
                                :width="gauge_meter_a.width"
                                :marginTop="gauge_meter_a.marginTop"
                            />
                            <div class="text-center"><label class="f1">Completion in :&nbsp; </label><span class="f2"> {{ gauge_meter_a.completion_days}} Days</span></div>
                        </div>
                        <div class="text-center">
                            <label class="f1">Baseline :&nbsp;</label>
                            <span class="f2">{{ changeDateFormat(project?.base_line_start_date) }} :: {{ changeDateFormat(project?.base_line_end_date) }}</span>
                        </div>
                        <div class="text-center">
                            <label class="f1">Forecast :&nbsp;</label>
                            <span class="f2">{{ changeDateFormat(project?.forecast_start_date) }}</span> :: {{ changeDateFormat(project?.forecast_end_date) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9 mb-2">
            <div class="card card-one">
                <div class="card-body py-0">
                    <div v-if="actual_plan_project_sections">
                        <div class="row" v-if="project_activity_group_id">
                            <div class="mb-1 col-xl-4" v-for="project_section, index in actual_plan_project_sections.slice(1, 4)" :key="project_section.section_id">
                                <!-- {{ gauge_meter_b.actual_plan[index +1] }} -->
                                <GaugeMeter
                                    :container="project_section.section.section_name"
                                    :title="project_section.section.section_name"
                                    :max="gauge_meter_b.max"
                                    :series="gauge_meter_b.series"
                                    :type="gauge_meter_b.type"
                                    :plot_bands="getPlotBands([gauge_meter_b.actual_plan[index+1]], [gauge_meter_b.overall_plan[index + 1]])"
                                    :actual="[gauge_meter_b.actual_plan[index +1]]"
                                    :plan="[gauge_meter_b.overall_plan[index +1]]"
                                    :label_font_size="gauge_meter_b.label_font_size"
                                    :marginTop="gauge_meter_b.marginTop"
                                    :height="gauge_meter_b.height"
                                    :width="gauge_meter_b.width"
                                    ref="child"
                                />
                                <div class="text-center"><label class="f1">Completion in :&nbsp; </label><span class="f2"> {{ project_section.completion_days}} Days</span></div>
                                <!-- <span>{{gauge_meter_b}}</span> -->
                                <div v-if="project_section.section.section_name == 'Engineering'">
                                    
                                    <div class="text-center">
                                        <label class="f1">Baseline :&nbsp;</label>
                                        <span class="f2">{{ changeDateFormat(project?.engineering_start_date) }} :: {{ changeDateFormat(project?.engineering_end_date) }}</span>
                                    </div>
                                    <div class="text-center">
                                        <label class="f1">Forecast :&nbsp;</label>
                                        <span class="f2">{{ changeDateFormat(project?.engineering_forecast_start_date) }} :: {{ changeDateFormat(project?.engineering_forecast_end_date) }}</span>
                                    </div>
                                </div>

                                <div v-if="project_section.section.section_name == 'Procurement'">
                                    <div class="text-center">
                                        <label class="f1">Baseline :&nbsp;</label>
                                        <span class="f2">{{ changeDateFormat(project?.procurement_start_date) }} :: {{ changeDateFormat(project?.procurement_end_date) }}</span>
                                    </div>
                                    <div class="text-center">
                                        <label class="f1">Forecast :&nbsp;</label>
                                        <span class="f2">{{ changeDateFormat(project?.procurement_forecast_start_date) }} :: {{ changeDateFormat(project?.procurement_forecast_end_date) }}</span>
                                    </div>
                                </div>
                                <div v-if="project_section.section.section_name == 'Construction'">
                                    <div class="text-center">
                                        <label class="f1">Baseline :&nbsp;</label>
                                        <span class="f2">{{ changeDateFormat(project?.construction_start_date) }} :: {{ changeDateFormat(project?.construction_end_date) }}</span>
                                    </div>
                                    <div class="text-center">
                                        <label class="f1">Forecast :&nbsp;</label>
                                        <span class="f2">{{ changeDateFormat(project?.construction_forecast_start_date) }} :: {{ changeDateFormat(project?.construction_forecast_end_date) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div :class="col_length" v-for="project_activity, index in project_activities" :key="project_activity.project_activity_id">
                                <GaugeMeter
                                    :container="project_activity.activity.activity_name"
                                    :title="project_activity.activity.activity_name"
                                    :max="gauge_meter_b.max"
                                    :series="gauge_meter_b.series"
                                    :type="gauge_meter_b.type"
                                    :plot_bands="getPlotBands([gauge_meter_b.actual_plan[index ]], [gauge_meter_b.overall_plan[index]])"
                                    :actual="[gauge_meter_b.actual_plan[index]]"
                                    :plan="[gauge_meter_b.overall_plan[index]]"
                                    :label_font_size="gauge_meter_b.label_font_size"
                                    ref="child"
                                />

                                <div class="text-center">
                                    <label class="f1">Baseline :&nbsp;</label>
                                    <span class="f2">{{ changeDateFormat(project?.base_line_start_date) }} :: {{ changeDateFormat(project?.base_line_end_date) }}</span>
                                </div>
                                <div class="text-center">
                                    <label class="f1">Forecast :&nbsp;</label>
                                    <span class="f2">{{ changeDateFormat(project?.base_line_start_date) }} :: {{ changeDateFormat(project?.base_line_end_date) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row">
                            <div class="col-sm-4" v-for="i in 3" :key="i">
                                <GaugeMeter
                                    :container="empty_project_activity[i-1]"
                                    :title="empty_project_activity[i-1]"
                                    :max="gauge_meter_empty.max"
                                    :series="gauge_meter_empty.series"
                                    :type="gauge_meter_empty.type"
                                    :plot_bands="gauge_meter_empty.plot_bands"
                                    :actual="gauge_meter_empty.actual_plan"
                                    :plan="gauge_meter_empty.overall_plan"
                                    :label_font_size="gauge_meter_empty.label_font_size"
                                    ref="child"
                                />
                                <div class="text-center">
                                    <label class="f1">Baseline :&nbsp;</label>
                                    <span class="f2">{{ changeDateFormat(gauge_meter_empty.base_line_start_date) }} :: {{ changeDateFormat(gauge_meter_empty.base_line_end_date) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8 mb-2">
                <div class="card card-one">
                    <!-- <div class="card-body pb-0 mb-0" v-if="overall_plan_data.length>0"> -->
                    <div class="card-body pb-0 mb-0">
                        <span>
                            <div class="row">
                                <div class="col-sm-9">
                                    <nav class="nav nav-pills">
                                        <a :class="overall_active" @click="getOverallPlan()" href="javascript:void(0)" class="nav-link"><i class="ri-line-chart-line me-1"></i>Overall</a>
                                        <a
                                            v-for="project_section in actual_plan_project_sections?.slice(1, 4)"
                                            :key="project_section.section_id"
                                            :class="project_section?.is_active"
                                            @click="getProjectSectionLineChart(project_section)"
                                            href="javascript:void(0)"
                                            class="nav-link"
                                        >
                                            <i class="ri-line-chart-line me-1"></i>{{project_section.section.section_name}}
                                        </a>                        
                                    </nav>
                                </div>
                                <div class="col-sm-3">
                                    <div class="d-flex align-items-center" style="float: left;">
                                        <label class="me-3">Select&nbsp;Plan:</label>
                                        <select class="form-control form-control-sm" v-model="project_section_group.selected_plan" @change="getOverallPlan">
                                            <option value="overall_plan" selected>Overall Plan</option>
                                            <option value="cerc_plan">CERC Plan</option>
                                            
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                        </span>
                        <LineChart
                            :title="scurvechart.title"
                            :container="'scurvechart'"
                            :subtitle="scurvechart.subtitle"
                            :backgroundColor="scurvechart.backgroundColor"
                            :gridLineColor="scurvechart.gridLineColor"
                            :thousandsSep="scurvechart.thousandsSep"
                            :decimalPoint="scurvechart.decimalPoint"
                            :height="scurvechart.height"
                            :width="scurvechart.width"
                            :yAxis="scurvechart.yAxis"
                            :xAxis="scurvechart.xAxis"
                            :series="scurvechart.series"
                            :crosshair="scurvechart.crosshair"
                            :customStyles="scurvechart.customStyles"
                            :plotOptions="scurvechart.plotOptions"
                            :label_font_size="scurvechart.label_font_size"
                            :marginTop="scurvechart.marginTop"
                        >
                        </LineChart>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 mb-2">
                <div class="card card-one">
                    <div class="card-body pb-0 mb-0">
                        <BarChart
                            :type="'column'"
                            :container="'barchartb'"
                            :title="barchartb.title"
                            :yAxis="barchartb.yAxis"
                            :xAxis="barchartb.xAxis"
                            :series="barchartb.series"
                            :stacking="barchartb.stacking"
                            :labels="barchartb.labels"
                            :xAxisLables="barchartb.xAxisLables"
                            :yAxisLables="barchartb.yAxisLables"
                            :label_font_size="barchartb.label_font_size"
                            :marginTop="barchartb.marginTop"
                            :height="barchartb.height"
                        >
                        </BarChart>
                    </div>
                </div>
            </div>

            <div class="col-sm-2 mb-2">
                <div class="card card-one">
                    <div class="card-body pb-0 mb-0">
                        <BarChart
                            :type="'column'"
                            :container="'barchartc'"
                            :title="barchartc.title"
                            :subtitle="barchartc.subtitle"
                            :yAxis="barchartc.yAxis"
                            :xAxis="barchartc.xAxis"
                            :series="barchartc.series"
                            :stacking="barchartc.stacking"
                            :labels="barchartc.labels"
                            :xAxisLables="barchartc.xAxisLables"
                            :yAxisLables="barchartc.yAxisLables"
                            :label_font_size="barchartc.label_font_size"
                            :marginTop="barchartc.marginTop"
                            :height="barchartc.height"
                        >
                        </BarChart>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <h4 class="card-title">Project Dashboard</h4>
                <ul class="nav nav-pills justify-content-end">
                            <li class="nav-item me-2" style="margin-top: 6px;"><label>Projects:</label></li>
                            <li class="nav-item me-2">
                                <select class="form-control" v-model="project_section_group.project_id" @change="getOverallPlan()">
                                    <option value="">Select Project</option>
                                    <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name }}</option>
                                </select>
                            </li>
                        
                        </ul>
                      <div class="d-flex"> 
                        <router-link v-can="'dashboard.bats'" to="/bats_dashboard" class="btn btn-primary d-flex align-items-center gap-2 btn-sm me-2">BATS Dashboard</router-link>
                        <router-link to="/admin_dashboard" class="btn btn-primary d-flex align-items-center gap-2 btn-sm">Overall Dashboard</router-link>
                      </div> 
            </div>
            <div class="card-body">
                <div class="row">
                     <div class="col-xl-3 mb-3">
                        <div class="card card-one">
                            <div class="card-body">
                                <div class="row">
                                    <div v-if="project_activity_group_id" class="col-sm-12">
                                        <GaugeMeter
                                            :container="'gaugemetera'"
                                            :title="gauge_meter_a.title"
                                            :max="gauge_meter_a.max"
                                            :series="gauge_meter_a.series"
                                            :type="gauge_meter_a.type"
                                            :plot_bands="gauge_meter_a.plot_bands"
                                            :actual="gauge_meter_a.actual"
                                            :plan="gauge_meter_a.plan"
                                            :label_font_size="gauge_meter_a.label_font_size"
                                            ref="child"
                                            :height="gauge_meter_a.height"
                                            :width="gauge_meter_a.width"
                                        />
                                        <div style="text-align: center;">
                                            <label class="f1">Completion in:&nbsp; </label><span class="f2"> {{ gauge_meter_b.completion_days}} Days</span>
                                        </div>
                                        
                                    </div>
                                    <div v-else class="col-sm-12">
                                        <GaugeMeter
                                            :container="'gaugemetera'"
                                            :title="gauge_meter_a.title"
                                            :max="gauge_meter_a.max"
                                            :series="gauge_meter_a.series"
                                            :type="gauge_meter_a.type"
                                            :plot_bands="gauge_meter_a.plot_bands"
                                            :actual="gauge_meter_a.actual"
                                            :plan="gauge_meter_a.plan"
                                            :label_font_size="gauge_meter_a.label_font_size"
                                            ref="child"
                                            :height="gauge_meter_a.height"
                                            :width="gauge_meter_a.width"
                                            :marginTop="gauge_meter_a.marginTop"
                                        />
                                        <div style="text-align: center;">
                                            <label class="f1">Completion in:&nbsp; </label><span class="f2"> {{ gauge_meter_b.completion_days}} Days</span>
                                        </div>
                                        
                                    </div>
                                    <ul class="nav justify-content-space-around">
                                        <li class="nav-item me-2">
                                            <label class="f1">Baseline Start:</label><br />
                                            <span class="f2">{{project?.base_line_start_date}}</span>
                                        </li>
                                        <li class="nav-item">
                                            <label class="f1">Baseline End:</label><br />
                                            <span class="f2">{{project?.base_line_end_date}}</span>
                                        </li>
                                    </ul>
                                    <ul class="nav justify-content-space-around">
                                        <li class="nav-item me-2">
                                            <label class="f1">Forecast Start:</label><br />
                                            <span class="f2">{{project?.forecast_start_date}}</span>
                                        </li>
                                        <li class="nav-item">
                                            <label class="f1">Forecast End:</label><br />
                                            <span class="f2">{{project?.forecast_end_date}}</span>
                                        </li>
                                        
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7 mb-3">
                        <div class="card card-one ">
                            <div class="card-body pb-0">
                                <div v-if="actual_plan_project_sections">
                                    <div class="row" v-if="project_activity_group_id">
                                        <div class="mb-1" :class="col_length" v-for="project_section, index in actual_plan_project_sections.slice(1, 4)" :key="project_section.section_id">
                                            <GaugeMeter 
                                                :container="project_section.section.section_name"
                                                :title="project_section.section.section_name"
                                                :max="gauge_meter_b.max"
                                                :series="gauge_meter_b.series"
                                                :type="gauge_meter_b.type"
                                                :plot_bands="getPlotBands([gauge_meter_b.actual_plan[index+1]], [gauge_meter_b.overall_plan[index + 1]])"
                                                :actual="[gauge_meter_b.actual_plan[index +1]]"
                                                :plan="[gauge_meter_b.overall_plan[index +1]]"
                                                :label_font_size="gauge_meter_b.label_font_size"
                                                :marginTop="gauge_meter_b.marginTop"
                                                :height="gauge_meter_b.height"
                                                :width="gauge_meter_b.width"
                                            />
                                            <div style="text-align: center;">
                                                <label class="f1">Completion in:&nbsp; </label><span class="f2"> {{ gauge_meter_b.completion_days}} Days</span>
                                            </div>

                                            <div v-if="project_section.section.section_name == 'Engineering'">
                                                <ul class="nav justify-content-space-around">
                                                <li class="nav-item me-2">
                                                    <label class="f1">Start:</label><br />
                                                    <span class="f2">{{project?.engineering_start_date}}</span>
                                                </li>
                                                <li class="nav-item">
                                                    <label class="f1">End:</label><br />
                                                    <span class="f2">{{project?.engineering_end_date}}</span>
                                                </li>
                                                </ul>
                                                <ul class="nav justify-content-space-around">
                                                    <li class="nav-item me-2">
                                                        <label class="f1">Forecast Start:</label><br />
                                                        <span class="f2">{{project?.engineering_forecast_start_date}}</span>
                                                    </li>
                                                    <li class="nav-item">
                                                        <label class="f1">Forecast End:</label><br />
                                                        <span class="f2">{{project?.engineering_forecast_end_date}}</span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div v-if="project_section.section.section_name == 'Procurement'">
                                                <ul class="nav justify-content-space-around">
                                                <li class="nav-item me-2">
                                                    <label class="f1">Start:</label><br />
                                                    <span class="f2">{{project?.procurement_start_date}}</span>
                                                </li>
                                                <li class="nav-item">
                                                    <label class="f1">End:</label><br />
                                                    <span class="f2">{{project?.procurement_end_date}}</span>
                                                </li>
                                                </ul>
                                                <ul class="nav justify-content-space-around">
                                                    <li class="nav-item me-2">
                                                        <label class="f1">Forecast Start:</label><br />
                                                        <span class="f2">{{project?.procurement_forecast_start_date}}</span>
                                                    </li>
                                                    <li class="nav-item">
                                                        <label class="f1">Forecast End:</label><br />
                                                        <span class="f2">{{project?.procurement_forecast_end_date}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="project_section.section.section_name == 'Construction'">
                                                <ul class="nav justify-content-space-around">
                                                <li class="nav-item me-2">
                                                    <label class="f1">Start:</label><br />
                                                    <span class="f2">{{project?.construction_start_date}}</span>
                                                </li>
                                                <li class="nav-item">
                                                    <label class="f1">End:</label><br />
                                                    <span class="f2">{{project?.construction_end_date}}</span>
                                                </li>
                                                </ul>
                                                <ul class="nav justify-content-space-around">
                                                    <li class="nav-item me-2">
                                                        <label class="f1">Forecast Start:</label><br />
                                                        <span class="f2">{{project?.construction_forecast_start_date}}</span>
                                                    </li>
                                                    <li class="nav-item">
                                                        <label class="f1">Forecast End:</label><br />
                                                        <span class="f2">{{project?.construction_forecast_end_date}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div :class="col_length" v-for="project_activity, index in project_activities" :key="project_activity.project_activity_id">
                                            
                                            <GaugeMeter
                                                :container="project_activity.activity.activity_name"
                                                :title="project_activity.activity.activity_name" 
                                                :max="gauge_meter_b.max"
                                                :series="gauge_meter_b.series"
                                                :type="gauge_meter_b.type"
                                                :plot_bands="getPlotBands([gauge_meter_b.actual_plan[index ]], [gauge_meter_b.overall_plan[index]])"
                                                :actual="[gauge_meter_b.actual_plan[index]]"
                                                :plan="[gauge_meter_b.overall_plan[index]]"
                                                :label_font_size="gauge_meter_b.label_font_size"
                                            />

                                            <ul class="nav justify-content-space-around">
                                                <li class="nav-item me-2">
                                                    <label class="f1">Start:</label><br />
                                                    <span class="f2">{{project?.base_line_start_date}}</span>
                                                </li>
                                                <li class="nav-item">
                                                    <label class="f1">End:</label><br />
                                                    <span class="f2">{{project?.base_line_end_date}}</span>
                                                </li>
                                                
                                            </ul>
                                            <ul class="nav justify-content-space-around">
                                                <li class="nav-item me-2">
                                                    <label class="f1">Forecast Start:</label><br />
                                                    <span class="f2">{{project?.base_line_start_date}}</span>
                                                </li>
                                                <li class="nav-item">
                                                    <label class="f1">Forecast End:</label><br />
                                                    <span class="f2">{{project?.base_line_end_date}}</span>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="row">
                                        <div class="col-sm-4" v-for="i in 3" :key="i">
                                            <GaugeMeter
                                                :container="empty_project_activity[i-1]"
                                                :title="empty_project_activity[i-1]" 
                                                :max="gauge_meter_empty.max"
                                                :series="gauge_meter_empty.series"
                                                :type="gauge_meter_empty.type"
                                                :plot_bands="gauge_meter_empty.plot_bands"
                                                :actual="gauge_meter_empty.actual_plan"
                                                :plan="gauge_meter_empty.overall_plan"
                                                :label_font_size="gauge_meter_empty.label_font_size"
                                            />                                            
                                            <ul class="nav justify-content-space-around">
                                                <li class="nav-item me-2">
                                                    <label class="f1">Baseline Start:</label><br />
                                                    <span class="f2">{{gauge_meter_empty.base_line_start_date}}</span>
                                                </li>
                                                <li class="nav-item">
                                                    <label class="f1">Baseline End:</label><br />
                                                    <span class="f2">{{gauge_meter_empty.base_line_end_date}}</span>
                                                </li>
                                                
                                            </ul>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 mb-3">
                        <div class="card card-one">
                            <div class="card-body">
                                <BarChart
                                    :type="'column'"
                                    :container="'barcharta'"
                                    :title="barcharta.title"
                                    :yAxis="barcharta.yAxis"
                                    :xAxis="barcharta.xAxis"
                                    :series="barcharta.series"
                                    :stacking="barcharta.stacking"
                                    :labels="barcharta.labels"
                                    :xAxisLables="barcharta.xAxisLables"
                                    :yAxisLables="barcharta.yAxisLables"
                                    :label_font_size="barcharta.label_font_size"
                                    :marginTop="barcharta.marginTop"
                                    :height="barcharta.height"
                                >
                                </BarChart>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8 mb-3">
                        <div class="card card-one">
                            
                                <div class="card-body pb-0 mb-0" v-if="overall_plan_data.length>0">
                                    <span >
                                        <nav class="nav nav-pills">
                                            <a :class="overall_active" @click="getOverallPlan()" href="javascript:void(0)" class="nav-link"><i class="ri-line-chart-line me-1"></i>Overall</a>
                                            <a  v-for="project_section in actual_plan_project_sections?.slice(1, 4)" :key="project_section.section_id" :class="project_section?.is_active" @click="getProjectSectionLineChart(project_section)" href="javascript:void(0)" class="nav-link"><i class="ri-line-chart-line me-1"></i>{{project_section.section.section_name}}</a>
                                        </nav>
                                    </span>
                                    <LineChart
                                        :title="scurvechart.title"
                                        :container="'scurvechart'"
                                        :subtitle="scurvechart.subtitle"
                                        :backgroundColor="scurvechart.backgroundColor"
                                        :gridLineColor="scurvechart.gridLineColor"
                                        :thousandsSep="scurvechart.thousandsSep"
                                        :decimalPoint="scurvechart.decimalPoint"
                                        :height="scurvechart.height"
                                        :width="scurvechart.width"
                                        :yAxis="scurvechart.yAxis"
                                        :xAxis="scurvechart.xAxis"
                                        :series="scurvechart.series"
                                        :crosshair="scurvechart.crosshair"
                                        :customStyles="scurvechart.customStyles"
                                        :plotOptions="scurvechart.plotOptions"
                                        :label_font_size="scurvechart.label_font_size"
                                        :marginTop="scurvechart.marginTop"
                                    >
                                    </LineChart>
                                </div>
                             </div>
                    </div>
                    <div class="col-sm-2 mb-3">
                        <div class="card card-one">
                            <div class="card-body pb-0 mb-0">
                                <BarChart
                                    :type="'column'"
                                    :container="'barchartb'"
                                    :title="barchartb.title"
                                    :yAxis="barchartb.yAxis"
                                    :xAxis="barchartb.xAxis"
                                    :series="barchartb.series"
                                    :stacking="barchartb.stacking"
                                    :labels="barchartb.labels"
                                    :xAxisLables="barchartb.xAxisLables"
                                    :yAxisLables="barchartb.yAxisLables"
                                    :label_font_size="barchartb.label_font_size"
                                    :marginTop="barchartb.marginTop"
                                    :height="barchartb.height"
                                >
                                </BarChart>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-2 mb-3">
                        <div class="card card-one">
                            <div class="card-body pb-0 mb-0">
                                <BarChart
                                    :type="'column'"
                                    :container="'barchartc'"
                                    :title="barchartc.title"
                                    :subtitle="barchartc.subtitle"
                                    :yAxis="barchartc.yAxis"
                                    :xAxis="barchartc.xAxis"
                                    :series="barchartc.series"
                                    :stacking="barchartc.stacking"
                                    :labels="barchartc.labels"
                                    :xAxisLables="barchartc.xAxisLables"
                                    :yAxisLables="barchartc.yAxisLables"
                                    :label_font_size="barchartc.label_font_size"
                                    :marginTop="barchartc.marginTop"
                                    :height="barchartc.height"
                                >
                                </BarChart>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
        </div>
    </div>  -->
</template>
<script>
    import { Chart } from "highcharts-vue";
    import { ColumnChart } from "highcharts-vue";
    import Heatmap from "@/components/charts/Heatmap";
    import Donut from "@/components/charts/Donut";
    import BarChart from "@/components/charts/BarChart";
    import GaugeMeter from "@/components/charts/GaugeMeter";
    import LineChart from "@/components/charts/LineChart";
    import moment from "moment";
    import SolidGauge from "@/components/charts/SolidGauge.vue";

    export default {
        name: "Dashboard",
        components: {
            highcharts: Chart,
            ColumnChart,
            Heatmap,
            Donut,
            BarChart,
            GaugeMeter,
            LineChart,
            SolidGauge,
        },
        // watch: {
		// 	gauge_meter_b.series: {
        //         handler() {
        //             // chart.series[0].update({
        //             //     data: [1, 2, 3, 4, 3, 4]
        //             // })
        //             cosnole.log('chart_date:---',this.series)
        //             this.loadChart();

        //         },
        //         deep: true,
        //     },            
        // },
        data() {
            return {
                overall_active: "active",
                col_length: "col-sm-4",
                project: {},
                project_section_group: {
                    current_month_year: "",
                    is_calculation: "",
                    project_id: "",
                    project_code: "",
                    selected_plan:"overall_plan",
                },
                project_section: {
                    overall_plan_project_section_id: "",
                    actual_plan_project_section_id: "",
                    current_month_year: "",
                },
                projects: [],
                project_activities: [],
                project_activity_group_id: "",
                overall_plan_project_sections: [],
                actual_plan_project_sections: [],
                overall_plan_project_activities: [],
                actual_plan_project_activities: [],
                show_project_category: false,
                show_project_type: false,
                show_project: false,
                show_department: false,
                over_all_weightage: "",
                type: "",
                overall_scope: "",
                engineering_scope: "",
                procurement_scope: "",
                construction_scope: "",
                chartOptions: {
                    series: [
                        {
                            data: [1, 2, 3], // sample data
                        },
                    ],
                },
                gauge_meter_a: {
                    title: "Overall",
                    type: "solidgauge",
                    max: 0,
                    label_font_size: "12px",
                    // label_font_size: "20px",
                    project_parameter_length: "",
                    plot_bands: [
                        {
                            from: 0,
                            to: 100,
                            color: "#C0C0C0",
                            thickness: 15,
                        },
                    ],
                    actual: [],
                    plan: [],
                    base_line_start_date: "",
                    base_line_end_date: "",
                    completion_days: "",
                    height: "50%",
                    // width: 250,

                    marginTop: 50,
                },
                gauge_meter_b: {
                    title: "Engineering",
                    type: "solidgauge",
                    max: 0,
                    label_font_size: "12px",
                    plot_bands: [
                        {
                            from: 0,
                            to: 81,
                            color: "#DDDF0D", //  '#DDDF0D' - yellow, '#DF5353' - red, color: '#55BF3B' - green
                            thickness: 15,
                        },
                        {
                            from: 81,
                            to: 100,
                            color: "#C0C0C0", //red
                            thickness: 15,
                        },
                    ],
                    actual_plan: [],
                    overall_plan: [],
                    base_line_start_date: "",
                    base_line_end_date: "",
                    marginTop: "50",
                    completion_days: "",
                    height: "50%",
                    // width: 350,
                },
                gauge_meter_empty: {
                    title: "",
                    type: "solidgauge",
                    max: 0,
                    label_font_size: "12px",
                    plot_bands: [
                        {
                            from: 0,
                            to: 100,
                            color: "#C0C0C0",
                            thickness: 15,
                        },
                    ],
                    actual_plan: [],
                    overall_plan: [],
                    base_line_start_date: "",
                    base_line_end_date: "",
                    marginTop: "50",
                    // height: "65%",
                    // width: 250,
                },
                project_category: {
                    title: "Project Categories",
                    xAxis: ["Happy", "Sprint"],
                    yAxis: ["Percentage"],
                    series: [
                        [0, 0, 10],
                        [1, 0, 92],
                    ],
                },

                project_type: {
                    title: "Project Types",
                    xAxis: ["IU", "GU"],
                    yAxis: ["Percentage"],
                    series: [
                        [0, 0, 10],
                        [1, 0, 92],
                    ],
                },

                project: {
                    title: "Projects",
                    xAxis: ["KUCW", "BLCW", "PR1", "PR2"],
                    yAxis: ["Percentage"],
                    series: [
                        [0, 0, 10],
                        [1, 0, 92],
                        [2, 0, 10],
                        [3, 0, 92],
                    ],
                },

                department: {
                    title: "Department",
                    xAxis: ["Civil", "Mechanical", "Electrical", "C&I", "Refractory", "Sheeting", "Insulation", "Crane Planning"],
                    yAxis: ["Percentage"],
                    series: [
                        [0, 0, 10],
                        [1, 0, 92],
                        [2, 0, 10],
                        [3, 0, 92],
                        [4, 0, 10],
                        [5, 0, 92],
                        [6, 0, 10],
                        [7, 0, 92],
                    ],
                },

                chart: {
                    xAxis: {
                        categories: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007],
                    },
                    series: [
                        {
                            name: "Installation",
                            color: "#03bbd4",
                            data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
                        },
                        {
                            name: "Manufacturing",
                            color: "#f7a35c",
                            data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
                        },
                    ],
                },

                // Project Dashboard TM
                donuta: {
                    title: "3",
                    max: 0,
                    plotoption: {
                        pie: {
                            allowPointSelect: true,
                            cursor: "pointer",
                            dataLabels: {
                                enabled: true,
                                format: "{y}",
                            },
                            showInLegend: true,
                        },
                        series: {
                            borderWidth: 0,
                            colorByPoint: true,
                            type: "pie",
                            size: "100%",
                            innerSize: "80%",
                            dataLabels: {
                                enabled: true,
                                crop: false,
                                distance: "-10%",
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                },
                                connectorWidth: 0,
                            },
                            events: {
                                click: function (e) {
                                    e.preventDefault(); // prevent any action from occuring on "click" event
                                },
                            },
                        },
                    },
                    series: [
                        {
                            name: "Delay",
                            y: 2,
                            color: "#f70000",
                        },
                        {
                            name: "On Track",
                            y: 1,
                            color: "#7bf700",
                        },
                    ],
                },
                gaugemeterb: {
                    title: "Overall",
                    type: "solidgauge",
                    max: 0,
                    series: [71],
                },

                barcharta: {
                    title: "Cost PTD",
                    // yAxis: [0,1,2,3,4,5,6,7,8,9,10],
                    // xAxis: ['Jul. 23', 'Aug. 23', 'Sep. 23', 'Oct. 23'],
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "",
                    labels: "",
                    // height: "200%",
                    height: "170%",
                    // label_font_size: "16px",
                    label_font_size: "12px",
                    marginTop: 50,
                    series: [
                        {
                            name: "Sanction",
                            data: [],
                            color: "#26aaac",
                        },
                        {
                            name: "Committed",
                            data: [],
                            color: "#198de4",
                        },
                        {
                            name: "Budget Spend",
                            data: [],
                            color: "#bbad02",
                        },
                    ],
                },
                barchartb: {
                    title: "Qty Concrete (cum)",
                    // xAxis: ['Erection (MT)', 'Fabrication (MT)', 'RCC (CuM)'],
                    // yAxis: [0, 50, 100, 150, 200, 250, 300, 350, 400],
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "",
                    labels: "",
                    label_font_size: "12px",
                    marginTop: 15,
                    height: "150%",
                    series: [
                        {
                            name: "Total",
                            data: [3253],
                            color: "#26aaac",
                        },
                        {
                            name: "Planned",
                            data: [2966],
                            color: "#198de4",
                        },
                        {
                            name: "Actual",
                            data: [1833],
                            color: "#bbad02",
                        },
                    ],
                },

                barchartc: {
                    title: "Procurement",
                    subtitle: {
                        text: "TS",
                        // verticalAlign: 'center',
                        // y: -10
                    },
                    xAxisLables: {
                        enabled: false,
                    },
                    yAxisLables: false,
                    stacking: "",
                    labels: "",
                    label_font_size: "12px",
                    // marginTop: 15,
                    height: "150%",
                    series: [
                        {
                            name: "Planned",
                            data: [12],
                            color: "#27aaa8",
                        },
                        {
                            name: "Raised",
                            data: [8],
                            color: "#1b8ae2",
                        },
                        {
                            name: "Delayed",
                            data: [4],
                            color: "#ff3e3e",
                        },
                    ],
                },

                // let date_time1 = new Date(date_time);
                // let d1 = date_time1.getDate();
                // let d2 = date_time1.getUTCMonth() + 1;
                // let d3 = date_time1.getUTCFullYear();
                // data.push([Date.UTC(d3, d2, d1)
                // moment().format("MMM-YY")
                scurvechart: {
                    title: "S-Curve for planned Vs Actual",
                    backgroundColor: "#fff",
                    gridLineColor: "#ccd6eb",
                    thousandsSep: ".",
                    decimalPoint: ",",
                    xAxis: {
                        // type: "datetime",
                        categories:'',
                    },
                    yAxis: {
                        // labels: {
                        //     formatter: function() {
                        //         return vm.labels[this.pos - 1]
                        //     }
                        // }
                    },

                    series: [
                        {
                            name: "Planned",
                            marker: {
                                symbol: "circle",
                            },
                            color: "",
                            data: [],
                            // tooltip: {
                            //     valueDecimals: 2,
                            //     valueSuffix: "%",
                            // },
                        },
                        {
                            name: "Actual",
                            marker: {
                                symbol: "circle",
                            },
                            color: "",
                            data: [{}],
                            // tooltip: {
                            //     valueDecimals: 2,
                            //     valueSuffix: "%",
                            // },
                        },
                    ],
                    plotOptions: {
                        series: {
                            // pointStart: Date.UTC(2022, 0, 1),
                            // pointInterval: 24 * 3600 * 1000 * 30 * 4 // one day
                        },
                    },

                    crosshair: true,
                    customStyles: {
                        color: "#666",
                        fontFamily: "Nunito Sans",
                    },
                    // label_font_size: "16px",d
                    label_font_size: "12px",
                    marginTop: 15,
                    height: "27%",
                    // width:40,
                },
                actual_plan: [],
                overall_plan: [],
                overall_plan_data: [],
                actual_plan_data: [],
                empty_project_activity: ["Engineering", "Procurement", "Construction"],
            };
        },

        mounted() {
            this.getProjects();
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (from.name == "login") {
                    vm.$router.go();
                }
            });
        },

        methods: {
            changeDateFormat(date){
                return moment(date).format('DD-MMM-YYYY')
            },
            getPlotBands(actual, plan) {
                //Plot band calculation
                let plot_bands = [];
                let plot_band_calculation = (actual / plan) * 100;
                let plot_band_to = "";
                let plot_band_color = "";
                // console.log("actual", actual, plan);
                // console.log("plot_band_calculation", plot_band_calculation);
                if (plan[0] <= actual[0]) {
                    plot_band_to = parseFloat(actual[0].toFixed(2));
                    plot_band_color = "green";
                } else if (plot_band_calculation < 75) {
                    // console.log("plot band 2");
                    plot_band_to = parseFloat(plot_band_calculation.toFixed(2));
                    plot_band_color = "red";
                } else if (plot_band_calculation >= 75 && plot_band_calculation < 89) {
                    // console.log(plot_band_calculation)
                    plot_band_to = parseFloat(plot_band_calculation.toFixed(2));
                    plot_band_color = "yellow";
                } else if (plot_band_calculation >= 89 && plot_band_calculation <= 100) {
                    plot_band_to = parseFloat(plot_band_calculation.toFixed(2));
                    plot_band_color = "green";
                }
                
                plot_bands.push(
                    {
                        from: 0,
                        to: actual,
                        color: plot_band_color,
                        thickness: 15,
                    },
                    // {
                    //     from: actual,
                    //     to: plan,
                    //     color: "black",
                    //     thickness: 15,
                    // }
                    {
                        from: actual,
                        to: 100,
                        color: "#C0C0C0",
                        thickness: 15,
                    }
                );
                if(actual[0] > plan[0]){
                    plot_bands.push(                        
                        {
                            from: actual,
                            to: plan,
                            color: "green",
                            thickness: 15,
                        }
                    )
                }
                else{
                    plot_bands.push(                        
                        {
                            from: actual,
                            to: plan,
                            color: "black",
                            thickness: 15,
                        }
                    )
                }
                // if (actual < 100) {
                //     plot_bands.push({
                //         from: actual,
                //         to: 100,
                //         color: "#C0C0C0",
                //         thickness: 15,
                //     });
                // } else {
                //     plot_bands.push({
                //         from: actual,
                //         to: 100,
                //         color: "green",
                //         thickness: 15,
                //     });
                // }
                // console.log("plot_band_calculation", plot_bands);
                return plot_bands;
            },
            getProjects() {
                let vm = this;
                let uri = { uri: "getProjectsDropDown" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projects = response.data;
                        if (vm.projects.length) {
                            if (!vm.project_section_group.project_id) {
                                vm.project_section_group.project_id = vm.projects[0].project_id;
                            }
                        }
                        // vm.getProjectRequestApproved()
                        vm.getOverallPlan();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            gauge_graph_calculation(plan, plan_type) {
                let vm = this;
                let over_all_weightage = 0;

                let param_sum = 0;
                if (plan_type == "actual_plan") {
                    vm.gauge_meter_b.actual_plan = [];
                } else {
                    vm.gauge_meter_b.overall_plan = [];
                }
                // console.log("gauge", vm.gauge_meter_b)
                plan.project_sections.map(function (project_section) {
                    let param_sum_section = 0;
                    let section_weight = 0;
                    let over_all_section_weight = 0;
                    project_section.project_parameters_latest.map(function (project_parameter) {
                        if (project_parameter.job_parameter) {
                            let parameter_value = project_parameter.job_parameter.parameter_value * project_parameter.job_parameter.project_activity.scope;
                            param_sum = +param_sum + +parameter_value;
                            // console.log("param_sum", project_parameter.job_parameter.parameter_value, project_parameter.job_parameter.project_activity.scope);
                            param_sum_section = +param_sum_section + +parameter_value;
                            over_all_weightage = +over_all_weightage + +project_parameter.job_parameter.project_activity.scope;
                            over_all_section_weight = +over_all_section_weight + +project_parameter.job_parameter.project_activity.scope;
                            if (project_section.section.section_name == "Pre Project Activity") {
                                vm.overall_scope = over_all_weightage;
                            } else if (project_section.section.section_name == "Engineering") {
                                vm.engineering_scope = over_all_weightage;
                            } else if (project_section.section.section_name == "Procurement") {
                                vm.procurement_scope = over_all_weightage;
                            } else if (project_section.section.section_name == "Construction") {
                                vm.construction_scope = over_all_weightage;
                            }
                            section_weight = +section_weight + +project_parameter.job_parameter.project_activity.scope;
                        } else {
                            if (project_section.section.section_name == "Pre Project Activity") {
                                over_all_weightage = vm.overall_scope;
                            } else if (project_section.section.section_name == "Engineering") {
                                over_all_weightage = vm.engineering_scope;
                                section_weight = vm.engineering_scope;
                            } else if (project_section.section.section_name == "Procurement") {
                                over_all_weightage = vm.procurement_scope;
                                section_weight = vm.procurement_scope;
                            } else if (project_section.section.section_name == "Construction") {
                                over_all_weightage = vm.construction_scope;
                                section_weight = vm.construction_scope;
                            }
                        }
                        // console.log('project_parameter.job_parameter.project_activity.scope:-----',project_parameter.job_parameter.project_activity.scope)
                    });
                    
                    // console.log("param sum", param_sum)
                    if (plan_type == "actual_plan") {
                        let total_a = param_sum_section / over_all_section_weight;
                        if(isNaN(total_a)){
                            total_a = 0
                        }
                        vm.gauge_meter_b.actual_plan.push(parseFloat(total_a.toFixed(2)));
                    } else {
                        let total_b = param_sum_section / over_all_section_weight;
                        if(isNaN(total_b)){
                            total_b = 0
                        }
                        vm.gauge_meter_b.overall_plan.push(parseFloat(total_b.toFixed(2)));
                    }
                    let oneDay = 24 * 60 * 60 * 1000;
                    if (project_section.section.section_name == "Engineering") {
                        let start_date = new Date();
                        // console.log("eng ", vm.gauge_meter_b)
                        let end_date = new Date(vm.project.engineering_forecast_end_date);
                        let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                        // console.log("Daysdiff", Daysdiff)
                        project_section.completion_days = Daysdiff;
                        // console.log("Daysdiff", vm.gauge_meter_b)
                    } 
                    else if (project_section.section.section_name == "Procurement") {
                        // console.log("proc ", vm.gauge_meter_b)
                        let start_date = new Date();
                        let end_date = new Date(vm.project.procurement_forecast_end_date);
                        let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                        project_section.completion_days = Daysdiff;
                        // console.log("proc", Daysdiff)
                    } 
                    else if (project_section.section.section_name == "Construction") {
                        // console.log("const ", vm.gauge_meter_b)
                        let start_date = new Date();
                        let end_date = new Date(vm.project.construction_forecast_end_date);
                        let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                        project_section.completion_days = Daysdiff;
                        // console.log("const", Daysdiff)
                    }
                });
                let result = param_sum / over_all_weightage;
                if(isNaN(result)){
                    result = 0
                }
                return parseFloat(result.toFixed(2));
            },
            getOverallPlan() {
                // console.log("getOverallPlan")
                let vm = this;
                let loader = vm.$loading.show();
                vm.overall_active = "active";
                vm.project_section_group.current_month_year = moment().format("MMM-YY");
                let uri = { uri: "getOverallPlan", data: vm.project_section_group };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.project_activity_group_id = response.data.overall_plan.project_activity_group_id;
                        vm.project = response.data.actual_plan.project;
                        vm.overall_plan_project_sections = response.data.overall_plan.project_sections;
                        vm.actual_plan_project_sections = response.data.actual_plan.project_sections;
                        vm.gauge_meter_a.actual = [];
                        vm.gauge_meter_a.plan = [];
                        vm.gauge_meter_b.actual = [];
                        vm.gauge_meter_b.plan = [];
                        vm.gauge_meter_a.title = "Overall";
                        vm.gauge_meter_a.plan.push(vm.gauge_graph_calculation(response.data.overall_plan, "overall_plan"));
                        vm.gauge_meter_a.actual.push(vm.gauge_graph_calculation(response.data.actual_plan, "actual_plan"));
                        if (vm.actual_plan_project_sections.length) {
                            let len = 12 / (vm.actual_plan_project_sections.length - 1);
                            vm.col_length = "col-sm-" + len;
                        }
                        // console.log("project start------", vm.project.base_line_start_date)
                        // console.log("project end------", vm.project.base_line_end_date)


                        let oneDay = 24 * 60 * 60 * 1000;
                        let start_date = new Date();
                        let end_date = new Date(vm.project.forecast_end_date);
                        let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                        // console.log("Daysdiff", Daysdiff)
                        // console.log("gauge meter", vm.gauge_meter_a.completion_days)

                        vm.gauge_meter_a.completion_days = Daysdiff;
                        // vm.gauge_meter_b.completion_days = Daysdiff

                        //Plog band calculation
                        vm.gauge_meter_a.plot_bands = vm.getPlotBands(vm.gauge_meter_a.actual, vm.gauge_meter_a.plan);

                        // vm.$refs.child.loadChart();

                        // vm.actual_plan = response.data.actual_plan;
                        // vm.overall_plan = response.data.overall_plan;
                        // vm.scurvechart.series = [];
                        // vm.calculateplan(vm.overall_plan, "Planned %", "#03bbd4");
                        // vm.calculateplan(vm.actual_plan, "Actual %", "#f60000");
                        vm.getOverallSCurve();
                        loader.hide();
                    })
                    .catch(function (error) {
                        console.log(error);
                        loader.hide();
                    });
            },
            getOverallSCurve(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.overall_active = "active";
                vm.project_section_group.current_month_year = moment().format("MMM-YY");
                let uri = { uri: "getOverallSCurve", data: vm.project_section_group };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        console.log('ResponseScurve:-----', response)
                        // vm.project_activity_group_id = response.data.overall_plan.project_activity_group_id;
                        // vm.project = response.data.actual_plan.project;
                        // vm.overall_plan_project_sections = response.data.overall_plan.project_sections;
                        // vm.actual_plan_project_sections = response.data.actual_plan.project_sections;
                        // vm.gauge_meter_a.actual = [];
                        // vm.gauge_meter_a.plan = [];
                        // vm.gauge_meter_b.actual = [];
                        // vm.gauge_meter_b.plan = [];
                        // vm.gauge_meter_a.title = "Overall";
                        // vm.gauge_meter_a.plan.push(vm.gauge_graph_calculation(response.data.overall_plan, "overall_plan"));
                        // vm.gauge_meter_a.actual.push(vm.gauge_graph_calculation(response.data.actual_plan, "actual_plan"));
                        // if (vm.actual_plan_project_sections.length) {
                        //     let len = 12 / (vm.actual_plan_project_sections.length - 1);
                        //     vm.col_length = "col-sm-" + len;
                        // }
                        // // console.log("project start------", vm.project.base_line_start_date)
                        // // console.log("project end------", vm.project.base_line_end_date)


                        // let oneDay = 24 * 60 * 60 * 1000;
                        // let start_date = new Date();
                        // let end_date = new Date(vm.project.forecast_end_date);
                        // let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                        // // console.log("Daysdiff", Daysdiff)
                        // // console.log("gauge meter", vm.gauge_meter_a.completion_days)

                        // vm.gauge_meter_a.completion_days = Daysdiff;
                        // // vm.gauge_meter_b.completion_days = Daysdiff

                        // //Plog band calculation
                        // vm.gauge_meter_a.plot_bands = vm.getPlotBands(vm.gauge_meter_a.actual, vm.gauge_meter_a.plan);

                        // // vm.$refs.child.loadChart();

                        // vm.actual_plan = response.data.actual_plan;
                        // vm.overall_plan = response.data.overall_plan;
                        vm.scurvechart.series = [];
                        // vm.calculateplan(vm.overall_plan, "Planned %", "#03bbd4");
                        // vm.calculateplan(vm.actual_plan, "Actual %", "#f60000");


                        vm.scurvechart.xAxis.categories = response.data.overall_plan.month_year
                        vm.scurvechart.series.push({
                            type: 'line',
                            name: 'Planned %',
                            marker: {
                                symbol: "circle",
                            },
                            color: '#03bbd4',
                            data: response.data.overall_plan.parameter_values,
                            tooltip: {
                                valueDecimals: 2,
                                valueSuffix: "%",
                            },
                        });
                        vm.scurvechart.series.push({
                            type: 'line',
                            name: 'Actual %"',
                            marker: {
                                symbol: "circle",
                            },
                            color: '#f60000',
                            data: response.data.actual_plan.parameter_values,
                            tooltip: {
                                valueDecimals: 2,
                                valueSuffix: "%",
                            },
                        });
                        // console.log('vm.scurvechart:----',vm.scurvechart)
                        loader.hide();
                    })
                    .catch(function (error) {
                        console.log(error);
                        loader.hide();
                    });
            },
            getProjectRequestApproved() {
                let vm = this;
                let uri = { uri: "getProjectRequestApproved", data: vm.project_section_group };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.getOverallPlan();

                        for (let j = 0; j < vm.barcharta.series.length; j++) {
                            if (vm.barcharta.series[j].name == "Sanction") {
                                vm.barcharta.series[j].data.push(response.data.sanction);
                            } else if (vm.barcharta.series[j].name == "Committed") {
                                vm.barcharta.series[j].data.push(response.data.committed);
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            calculateplan(plan_data, plan_name, line_color) {
                // console.log('plan_data:------',plan_data)
                //Linechart
                let vm = this;
                // let over_all_weightage = 0;
                // for (let j = 0; j < plan_data.project_activity.length; j++) {
                //     over_all_weightage = over_all_weightage + Number(plan_data.project_activity[j].scope);
                // }

                let data = [];
                if(plan_data.project_parameters)
                {
                    // console.log('plan_data.project_parameters:----',plan_data.project_parameters)
                    for (let [key, value] of Object.entries(plan_data.project_parameters)) {
                        let parameter_scope_value = 0;
                        let date_time = "01-" + value[0].parameter.parameter_code;
                        let parameter_value = 0;
                        let scope_value = 0;
                        let parameter_scope_multiply = 0;
                        let over_all_weightage = 0;
                        for (let i = 0; i < value.length; i++) {
                            // if(plan_name == 'Actual %'){
                            //     console.log('value[i]:------',value[i])
                            // }
                            
                            if (value[i].job_parameter) {
                                if (value[i].job_parameter.parameter_value) {
                                    parameter_value = value[i].job_parameter.parameter_value;
                                    scope_value = value[i].job_parameter.project_activity.scope;
                                    parameter_scope_multiply = parameter_value * scope_value;
                                    parameter_scope_value = parameter_scope_value + parameter_scope_multiply;
                                    over_all_weightage = over_all_weightage + Number(scope_value);
                                }
                            }
                        }
                        let date_time1 = new Date(date_time);
                        let d1 = date_time1.getDate();
                        let d2 = date_time1.getUTCMonth() + 1;
                        let d3 = date_time1.getUTCFullYear();
                        // console.log('over_all_weightage:---',over_all_weightage)
                        data.push([Date.UTC(d3, d2, d1), parameter_scope_value / over_all_weightage]);
                    }
                    // console.log('data:-----',data)
                    vm.overall_plan_data = data;
                    vm.scurvechart.series.push({
                        name: plan_name,
                        marker: {
                            symbol: "circle",
                        },
                        color: line_color,
                        data: data,
                        tooltip: {
                            valueDecimals: 2,
                            valueSuffix: "%",
                        },
                    });
                }
            },

            getProjectSection(project_section) {
                let vm = this;
                project_section.is_active = "active";
                vm.overall_active = "";
                vm.actual_plan_project_sections.map(function (element) {
                    if (element.project_section_id != project_section.project_section_id) {
                        element.is_active = "";
                    }
                });
                vm.project_section.current_month_year = moment().format("MMM-YY");
                vm.project_section.actual_plan_project_section_id = project_section.project_section_id;
                vm.overall_plan_project_sections.map(function (overall_plan_project_section) {
                    if (project_section.section_id == overall_plan_project_section.section_id) {
                        vm.project_section.overall_plan_project_section_id = overall_plan_project_section.project_section_id;
                    }
                });

                let uri = { uri: "getOverallSectionPlan", data: vm.project_section };
                vm.$store.dispatch("post", uri).then(function (response) {
                    vm.project_activity_group_id = "";
                    vm.gauge_meter_a.title = response.data.overall_plan.section.section_name;
                    vm.project_activities = response.data.actual_plan.project_activity;
                    vm.overall_plan_project_activities = response.data.overall_plan.project_activities;
                    vm.actual_plan_project_activities = response.data.actual_plan.project_activities;
                    vm.gauge_meter_a.actual = [];
                    vm.gauge_meter_a.plan = [];
                    vm.gauge_meter_b.actual_plan = [];
                    vm.gauge_meter_b.overall_plan = [];
                    if (vm.project_activities.length) {
                        let len = 12 / vm.project_activities.length;
                        vm.col_length = "col-sm-" + len;
                    }

                    vm.gauge_meter_a.actual.push(vm.gaugeProjectActivitiesCalculation(response.data.actual_plan, "actual_plan"));
                    vm.gauge_meter_a.plan.push(vm.gaugeProjectActivitiesCalculation(response.data.overall_plan, "overall_plan"));
                    vm.gauge_meter_a.title = response.data.overall_plan.section.section_name;

                    let oneDay = 24 * 60 * 60 * 1000;
                    let start_date = new Date();
                    let end_date = new Date(vm.project.forecast_end_date);

                    let Daysdiff = Math.round(Math.abs((start_date - end_date) / oneDay));
                    vm.gauge_meter_b.completion_days = Daysdiff;

                    //Plog band calculation
                    vm.gauge_meter_a.plot_bands = vm.getPlotBands(vm.gauge_meter_a.actual, vm.gauge_meter_a.plan);
                    // vm.$refs.child.loadChart();

                    vm.actual_plan = response.data.actual_plan;
                    vm.overall_plan = response.data.overall_plan;
                    vm.scurvechart.series = [];
                    // vm.calculateplan(vm.overall_plan, "Planned %", "#03bbd4");
                    // vm.calculateplan(vm.actual_plan, "Actual %", "#f60000");
                });
            },
            getProjectSectionLineChart(project_section) {
                // console.log('inside plot_band_calculation ')
                let vm = this;
                project_section.is_active = "active";
                vm.overall_active = "";
                vm.actual_plan_project_sections.map(function (element) {
                    if (element.project_section_id != project_section.project_section_id) {
                        element.is_active = "";
                    }
                });
                vm.project_section.current_month_year = moment().format("MMM-YY");
                vm.project_section.actual_plan_project_section_id = project_section.project_section_id;
                vm.overall_plan_project_sections.map(function (overall_plan_project_section) {
                    if (project_section.section_id == overall_plan_project_section.section_id) {
                        vm.project_section.overall_plan_project_section_id = overall_plan_project_section.project_section_id;
                    }
                });

                let uri = { uri: "getOverallSectionPlan", data: vm.project_section };
                vm.$store.dispatch("post", uri).then(function (response) {                    
                    vm.actual_plan = response.data.actual_plan;
                    vm.overall_plan = response.data.overall_plan;
                    vm.scurvechart.series = [];
                    // vm.calculateplan(vm.overall_plan, "Planned %", "#03bbd4");
                    // vm.calculateplan(vm.actual_plan, "Actual %", "#f60000");
                });
            },

            gaugeProjectActivitiesCalculation(plan, plan_type) {
                let vm = this;
                vm.over_all_weightage = 0;
                for (let j = 0; j < plan.project_activity.length; j++) {
                    vm.over_all_weightage = vm.over_all_weightage + Number(plan.project_activity[j].scope);
                }

                let project_activity_values = [];
                plan.project_activity.map(function (project_activity) {
                    let job_parameter_values = [];

                    project_activity.project_parameter_latest.map(function (project_parameter) {
                        if (project_parameter.job_parameter) {
                            if (project_parameter.job_parameter.parameter_value) {
                                let parameter_value = project_parameter.job_parameter.parameter_value * project_activity.scope;
                                project_activity_values.push(parameter_value);
                                job_parameter_values.push(parameter_value / project_activity.scope);
                            }
                        }
                    });

                    let param_sum = job_parameter_values.reduce((total, num) => total + num, 0);
                    if (plan_type == "actual_plan") vm.gauge_meter_b.actual_plan.push(parseFloat(param_sum.toFixed(2)));
                    else vm.gauge_meter_b.overall_plan.push(parseFloat(param_sum.toFixed(2)));

                    // project_activity_values.push(param_sum);
                });

                let values = [];

                values.push({ actual: vm.gauge_meter_b.actual_plan, plan: vm.gauge_meter_b.overall_plan });

                for (let j = 0; j < values.length; j++) {
                    let plot_band_calculation = (values[j].actual[j] / values[j].plan[j]) * 100;
                }

                let result = project_activity_values.reduce((total, num) => total + num, 0);
                let result1 = result / vm.over_all_weightage;
                if(isNaN(result1)){
                    result1 = 0
                }
                return parseFloat(result1.toFixed(2));
            },

            showProjectCategory(type) {
                this.type = type;
                this.show_project_category = true;
                this.show_project_type = false;
                this.show_project = false;
                this.show_department = false;
            },
            showProjectType() {
                this.show_project_type = true;
            },
            showProject() {
                this.show_project = true;
            },
            showDepartment() {
                if (this.type == "pmt") this.show_department = true;
            },
        },
    };
</script>
<style scoped>
    .justify-content-space-around {
        justify-content: space-around;
    }
    .f1 {
        font-size: 9pt;
        font-weight: bold;
    }
    .f2 {
        font-size: 10pt;
    }
    .fontsize {
        font-size: 12px;
        font-weight: bold;
    }
    .card-one {
        /* border-color: #e2e5ec; */
        border: 1px solid #e2e5ec;
    }

    .table-sm > :not(caption) > * > * {
        padding: 0rem;
    }
</style>
