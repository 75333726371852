<template>
    <nav class="header-main px-3 px-lg-4 sidebar-prime navbar navbar-expand-lg">
        <a class="navbar-brand" href="javascript:void(0)" style="margin-right: 0;">
            <img src="../../src/assets/ultratech-cement-logo.png" class="logo" width="50" />
            <a href="javascript:void(0)" class="sidebar-logo ml-20"><span class="title">PROODOS </span> <small class="sub_title">Project & Budget Mgmt System</small></a>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul class="nav" v-if="$store.getters?.user?.role?.role_group?.role_group_code!='TM'">
                <li class="nav-item dropdown" v-can="'pmt.plant.report'">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-expanded="false">GoTo</a>
                    <ul class="dropdown-menu">
                        <li v-for="project_group, pg_key in project_groups" :key="pg_key" class="font_size">
                            <a class="dropdown-item" href="javascript:void(0)" @mouseover="updateProjectGroup(project_group)" >
                                <i class="ri-focus-2-fill" style="font-size:8pt;"></i>
                                {{ project_group.project_group_name }} &raquo;
                            </a>
                            <ul class="dropdown-menu dropdown-submenu">
                                <li v-for="project_type, pt_key in project_types" :key="pt_key" class="font_size">
                                    <a class="dropdown-item" href="javascript:void(0)" @mouseover="updateProjectType(project_type)">
                                        <i class="ri-focus-2-fill" style="font-size:8pt;"></i>
                                        {{ project_type.project_type_name }} &raquo;
                                    </a>
                                    <ul class="dropdown-menu dropdown-submenu">
                                        <li v-for="project_category, pt_key in project_categories" :key="pt_key" class="font_size">
                                            <a class="dropdown-item" href="javascript:void(0)" @mouseover="updateProjectCategory(project_category)">
                                                <i class="ri-focus-2-fill" style="font-size:8pt;"></i>
                                                {{ project_category.project_category_name }} &raquo;
                                            </a>
                                            <ul class="dropdown-menu dropdown-submenu">
                                                <li style="font-size:10pt;margin-right:8em">
                                                    <a class="dropdown-item" href="javascript:void(0)" @mouseover="updateProjects()">
                                                        <i class="ri-focus-line"></i>
                                                        Projects &raquo;
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-submenu">
                                                        <li v-for="project, project_key in project_group_projects" :key="project_key" class="font_size">
                                                            <a class="dropdown-item" href="javascript:void(0)" @mouseover="updateProject(project)">
                                                                <i class="ri-focus-line"></i>
                                                                {{ project.project_name }} &raquo;
                                                            </a>
                                                            <ul class="dropdown-menu dropdown-submenu" >
                                                                <li class="font_size" v-can="'dashboard.project'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/project_dashboard')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Project Dashboard</a>
                                                                </li>
                                                                <li class="font_size" v-can="'dashboard.pmt'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/dashboard_report')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Site Dashboard</a>
                                                                </li>
                                                                <li class="font_size" v-can="'dashboard.pmt'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/pmtchart')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Daily Dashboard</a>
                                                                </li>
                                                                <li class="font_size" v-can="'pmt.ho.report'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/overall_schedule')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Overall Schedule</a>
                                                                </li>
                                                                <li class="font_size" v-can="'pmt.plant.report'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/daily_progress')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Daily Progress (DPR)</a>
                                                                </li>
                                                                <li class="font_size" v-can="'pmt.plant.report'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/civil_summary')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Civil</a>
                                                                </li>
                                                                <li class="font_size" v-can="'pmt.plant.report'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/mechanical_summary')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Mechanical</a>
                                                                </li>
                                                                <li class="font_size" v-can="'pmt.plant.report'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/refractory')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Refractory</a>
                                                                </li>
                                                                <li class="font_size" v-can="'pmt.plant.report'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/hindrance')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Hindrance</a>
                                                                </li>
                                                                <li class="font_size" v-can="'pmt.plant.report'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/drive_trial_status')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Drive Trial</a>
                                                                </li>
                                                                <li class="font_size" v-can="'pmt.plant.report'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/electrical')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Electrical</a>
                                                                </li>
                                                                <li class="font_size" v-can="'pmt.plant.report'">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/instrument')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Instrumentation</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="font_size" v-can="'dashboard.pmt_cerc'">
                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/admin_dashboard')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Overall Dashboard</a>
                                                </li>
                                                <li class="font_size" v-can="'pmt.ho.report'">
                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/program_report')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Program Report</a>
                                                </li>
                                                <li class="font_size" v-can="'pmt.chmo.report'">
                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/overall_engineering')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Overall Engineering</a>
                                                </li>
                                                <li class="font_size" v-can="'pmt.chmo.report'">
                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/overall_procurement')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Overall Procurement</a>
                                                </li>
                                                <li class="font_size" v-can="'pmt.chmo.report'">
                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/overall_delivery')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Overall Delivery</a>
                                                </li>
                                                <li class="font_size" v-can="'pmt.ho.report'">
                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/dpr_progress')"><i class="ri-arrow-right-s-fill icon_hgt"></i>DPR Progress</a>
                                                </li>
                                                <li class="font_size" v-can="'pmt.ho.report'">
                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/dpr_ho_manpower')"><i class="ri-arrow-right-s-fill icon_hgt"></i>DPR Manpower</a>
                                                </li>
                                                <li class="font_size" v-can="'pmt.ho.report'">
                                                    <a class="dropdown-item" href="javascript:void(0)" @click="redirectPage('/ordering_schedule')"><i class="ri-arrow-right-s-fill icon_hgt"></i>Ordering Schedule</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown" v-if="$store.getters.user.user_type=='PMT' || $store.getters.user.user_type=='BOTH'">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-expanded="false">PMT Dashboard</a>
                    <ul class="dropdown-menu">
                        <li v-can="'dashboard.pmt_cerc'">
                            <router-link to="/admin_dashboard" class="dropdown-item"><i class="ri-donut-chart-fill"></i> Overall Dashboard</router-link>
                        </li>
                        <li v-can="'dashboard.project'">
                            <router-link to="/project_dashboard" class="dropdown-item"><i class="ri-dashboard-3-line"></i> Project Dashboard</router-link>
                        </li>
                        <li v-can="'dashboard.pmt'">
                            <router-link to="/dashboard_report" class="dropdown-item"><i class="ri-donut-chart-fill"></i> Site Dashboard</router-link>
                        </li>
                        <li v-can="'dashboard.pmt'">
                            <router-link to="/pmtchart" class="dropdown-item"><i class="ri-pie-chart-2-line"></i> Daily Dashboard</router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" v-if="($store.getters.user.user_type=='BATS' || $store.getters.user.user_type=='BOTH') && $store.getters.user.role.role_code!='CFD' && $store.getters.user.role.role_code!='HQPR'">
                    <router-link to="/dashboard/bats" class="nav-link">Budget Dashboard</router-link>
                </li>
                <li class="nav-item" v-if="$store.getters.user.role.role_code=='CFD'">
                    <router-link to="/dashboard/cfd" class="nav-link">CFD Dashboard</router-link>
                </li>
                <li class="nav-item" v-if="$store.getters.user.role.role_code=='HQPR'">
                    <router-link to="/dashboard/pr" class="nav-link">PR Dashboard</router-link>
                </li>
                <li class="nav-item dropdown" v-show="disableReports()">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-expanded="false">PMT Reports</a>
                    <ul class="dropdown-menu">
                        <li v-can="'pmt.plant.report'">
                            <a class="dropdown-item" href="javascript:void(0)">
                                <i class="ri-bar-chart-2-fill"></i>
                                Plant &raquo;
                            </a>
                            <ul class="dropdown-menu dropdown-submenu">
                                <li>
                                    <router-link class="dropdown-item" to="/daily_progress"><i class="ri-arrow-right-s-fill icon_hgt"></i>Daily Progress (DPR)</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/mechanical_summary"><i class="ri-arrow-right-s-fill icon_hgt"></i>Mechanical</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/civil_summary"><i class="ri-arrow-right-s-fill icon_hgt"></i>Civil</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/refractory"><i class="ri-arrow-right-s-fill icon_hgt"></i>Refractory</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/hindrance"><i class="ri-arrow-right-s-fill icon_hgt"></i>Hindrance</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/drive_trial_status"><i class="ri-arrow-right-s-fill icon_hgt"></i>Drive Trial status</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/electrical"><i class="ri-arrow-right-s-fill icon_hgt"></i>Electrical</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/instrument"><i class="ri-arrow-right-s-fill icon_hgt"></i>Instrumentation</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/whrs"><i class="ri-arrow-right-s-fill icon_hgt"></i>WHRS</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/slippage"><i class="ri-arrow-right-s-fill icon_hgt"></i>Slippage</router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-can="'pmt.ho.report'">
                            <a class="dropdown-item" href="javascript:void(0)">
                                <i class="ri-group-line"></i>
                                HO &raquo;
                            </a>
                            <ul class="dropdown-menu dropdown-submenu">
                                <li>
                                    <router-link class="dropdown-item" to="/program_report"><i class="ri-arrow-right-s-fill icon_hgt"></i>Program Report</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/dpr_progress"><i class="ri-arrow-right-s-fill icon_hgt"></i>DPR Progress</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/dpr_ho_manpower"><i class="ri-arrow-right-s-fill icon_hgt"></i>DPR manpower</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/overall_schedule"><i class="ri-arrow-right-s-fill icon_hgt"></i>Overall Schedule</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/ordering_schedule"><i class="ri-arrow-right-s-fill icon_hgt"></i>Ordering Schedule</router-link>
                                </li>
                            </ul>
                        </li>
                        <li v-can="'pmt.chmo.report'">
                            <a class="dropdown-item" href="javascript:void(0)">
                                <i class="ri-user-line"></i>
                                CHMO &raquo;
                            </a>
                            <ul class="dropdown-menu dropdown-submenu">
                                <li>
                                    <router-link class="dropdown-item" to="/overall_engineering"><i class="ri-arrow-right-s-fill icon_hgt"></i>Overall Engineering</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/overall_procurement"><i class="ri-arrow-right-s-fill icon_hgt"></i>Overall Procurement</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" to="/overall_delivery"><i class="ri-arrow-right-s-fill icon_hgt"></i>Overall Delivery</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown" v-can="'bats.report'">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-expanded="false">Reports</a>
                    <ul class="dropdown-menu">
                        <!-- <li>
                            <router-link to="/report/bats" class="dropdown-item">
                                <i class="ri-folder-add-line"></i><span>WBS Report</span>
                            </router-link>
                        </li> -->
                        <li>
                            <router-link to="/report/bats/line" class="dropdown-item"> <i class="ri-folder-add-line"></i><span>Snapshot Report</span> </router-link>
                        </li>
                        <!-- <li>
                            <router-link to="/report/bats/summary" class="dropdown-item"> <i class="ri-folder-add-line"></i><span>Summary Report</span> </router-link>
                        </li> -->
                        <li>
                            <router-link to="/report/bats/detail" class="dropdown-item"> <i class="ri-folder-add-line"></i><span>Detailed Report</span> </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown" v-show="disableProject()">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-expanded="false">Projects</a>
                    <ul class="dropdown-menu">
                        <li>
                            <router-link v-can="'projects.create'" to="/project/create" class="dropdown-item"><i class="ri-shape-line"></i><span>NewProject</span></router-link>
                        </li>
                        <li>
                            <router-link v-can="'projects.view'" to="/projects" class="dropdown-item"><i class="ri-folder-add-line"></i><span>Existing Project</span></router-link>
                        </li>
                        <li>
                            <router-link v-can="'budget_requests.view'" to="/project_cost/pending_request" class="dropdown-item"><i class="ri-folder-add-line"></i><span>Budget Requests</span></router-link>
                        </li>
                        <li v-can="'pmt_user_forms.view'">
                            <router-link to="/jobs" class="dropdown-item"><i class="ri-folder-add-line"></i><span>Jobs</span></router-link>
                        </li>
                        <li v-can="'pmt_user_forms.view'">
                            <router-link to="/activityjobs" class="dropdown-item"><i class="ri-folder-add-line"></i><span>Activity Jobs</span></router-link>
                        </li>
                        <li v-can="'pmt_user_forms.view'" v-if="$store.getters.user?.role?.role_code=='HQAdmin'">
                            <router-link to="/sort_project_activity_groups" class="dropdown-item"><i class="ri-grid-fill"></i><span>Project Activity Groups</span></router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a v-show="disableAdmin()" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-expanded="false">Admin</a>
                    <ul class="dropdown-menu">
                        <li>
                            <a v-show="disableUserManagement()" class="dropdown-item" href="javascript:void(0)">
                                <i class="ri-bar-chart-2-fill"></i>
                                User Management &raquo;
                            </a>
                            <ul class="dropdown-menu dropdown-submenu">
                                <!-- <li v-can="'role_groups.view'">
                                    <router-link to="/role_groups" class="dropdown-item">
                                        <i class="ri-group-line"></i> 
                                        <span>Role Groups</span>
                                    </router-link>
                                </li> -->
                                <li v-can="'roles.view'">
                                    <router-link to="/roles" class="dropdown-item">
                                        <i class="ri-user-star-line"></i>
                                        <span>Roles</span>
                                    </router-link>
                                </li>
                                <li v-can="'escalation_roles.view'">
                                    <router-link to="/escalation_roles" class="dropdown-item">
                                        <i class="ri-user-star-line"></i>
                                        <span>Escalation Roles</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link v-can="'users.view'" to="/users" class="dropdown-item"><i class="ri-user-line"></i> <span>Users</span></router-link>
                                </li>
                                <!-- <li>
                                    <router-link v-can="'permissions.view'" to="/permissions" class="dropdown-item"><i class="ri-lock-line"></i>
                                        <span>Permissions</span></router-link>
                                </li> -->
                                <li>
                                    <router-link v-can="'approval_settings.view'" to="/approval_settings" class="dropdown-item"><i class="ri-settings-4-line"></i> <span>Approval Settings</span></router-link>
                                </li>
                                <li>
                                    <router-link v-can="'escalation_settings.view'" to="/escalation_settings" class="dropdown-item"><i class="ri-settings-4-line"></i>
                                        <span>Escalation Settings</span></router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item" v-can="'templates.view'">
                            <router-link to="/templates" class="dropdown-item"><i class="ri-time-line"></i><span>PMT Master Templates</span></router-link>
                        </li>
                        <li class="nav-item" v-show="disableConfigurations()">
                            <router-link to="/configuration" class="dropdown-item"><i class="ri-settings-4-line"></i>Configurations</router-link>
                        </li>
                        <li class="nav-item" v-can="'logs.view'">
                            <router-link to="/logs" class="dropdown-item"><i class="ri-list-check"></i><span>System Logs</span></router-link>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="nav" v-if="$store.getters?.user?.role?.role_group?.role_group_code=='TM'">
                <li>
                    <router-link to="/topmanagement_dashboard" class="dropdown-item" style="color: white;">Dashboard</router-link>
                </li>
            </ul>
            <div class="dropdown dropdown-profile ms-3 ms-xl-4 mt-4">
                <a href="#" class="dropdown-link d-flex" data-bs-toggle="dropdown" data-bs-auto-close="outside">
                    <div class="text-end me-2">
                        <h6 class="mb-0 text-white fw-semibold">{{ $store.getters.user.name }}</h6>
                        <p class="fs-sm text-white">
                            {{ $store.getters.user?.role?.role_group?.role_group_name }} :: {{ $store.getters.user.role?.role_name }}
                        </p>
                    </div>
                    <div class="avatar online"><img src="/assets/img/user.png" alt="" /></div>
                </a>
                <div class="dropdown-menu dropdown-menu-end mt-10-f">
                    <div class="dropdown-menu-body">
                        <nav class="nav">
                            <router-link to="/profile"><i class="ri-edit-2-line"></i> Edit Profile</router-link>
                        </nav>
                        <hr />
                        <nav class="nav">
                            <a href="javascript:void(0)" @click.prevent="logout()"><i class="ri-logout-box-r-line"></i> Log Out</a>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
    export default {
        name: "Header",
        data() {
            return {
                project_label_status: true,
                project_label: "Project Management",
                permissions: [],
                project_groups: [],
                project_types: [],
                project_categories: [],
                projects: [],
                project_group_projects: [],
                meta: {
                    project_group_id: "",
                    project_type_id: "",
                    project_category_id: "",
                    project_id: "",
                },
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                console.log(to);
            });
        },
        mounted() {
            this.permissions = this.$store.getters?.permissions;
            this.getProjectGroups();
        },

        methods: {
            redirectPage(path) {
                if (this.$route.path == path) {
                    location.reload();
                } else {
                    this.$router.push(path);
                }
            },
            updateProject(project) {
                this.meta.project_id = project.project_id;
                localStorage.setItem("project_group_id", this.meta.project_group_id);
                localStorage.setItem("project_id", this.meta.project_id);
            },
            toggleLable() {
                if (this.project_label_status) this.project_label = "Project Management System";
                else this.project_label = "Budget Allocation & Tracking";
            },

            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.project_groups = response.data.data;
                        vm.getProjectTypes();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjectTypes() {
                let vm = this;
                let uri = { uri: "getProjectTypes" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.project_types = response.data.data;
                        vm.getProjectCategories();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjectCategories() {
                let vm = this;
                let uri = { uri: "getProjectCategories" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.project_categories = response.data.data;
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            updateProjectGroup(project_group) {
                let vm = this;
                vm.meta.project_group_id = project_group.project_group_id;
                // localStorage.setItem('project_group_id', this.meta.project_group_id)
                // vm.project_group_projects = vm.projects.filter(function(element){
                //     return element.project_group_id == project_group.project_group_id
                // })
            },
            updateProjectType(project_type) {
                let vm = this;
                vm.meta.project_type_id = project_type.project_type_id;
                // localStorage.setItem('project_group_id', this.meta.project_group_id)
                // vm.project_group_projects = vm.projects.filter(function(element){
                //     return element.project_group_id == project_group.project_group_id
                // })
            },
            updateProjectCategory(project_category) {
                let vm = this;
                vm.meta.project_category_id = project_category.project_category_id;
                // localStorage.setItem('project_group_id', this.meta.project_group_id)
                // vm.project_group_projects = vm.projects.filter(function(element){
                //     return element.project_group_id == project_group.project_group_id
                // })
            },
            updateProjects() {
                let vm = this;
                vm.project_group_projects = vm.projects.filter(function (element) {
                    return element.project_group_id == vm.meta.project_group_id && element.project_type_id == vm.meta.project_type_id && element.project_category_id == vm.meta.project_category_id;
                });
            },
            getProjects() {
                let vm = this;
                let uri = { uri: "getProjects" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projects = response.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            logout() {
                let vm = this;
                localStorage.setItem("current_page", this.$route.path);
                localStorage.setItem("user_id", vm.$store.getters?.user?.user_id);
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "logout", data: vm.$store.getters.user })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("logout");
                        // localStorage.clear()
                        vm.$router.push("/");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            disableUserManagement() {
                let disable_user_management = this.permissions.filter((ele) => {
                    return (
                        ele.ability.ability == "permissions.view" || ele.ability.ability == "role_groups.view" || ele.ability.ability == "roles.view" || ele.ability.ability == "users.view" || ele.ability.ability == "approval_settings.view"
                    );
                });
                if (disable_user_management.length) return true;
                else return false;
            },

            disableConfigurations() {
                let disable_configuration = this.permissions.filter((ele) => {
                    return ele.ability.ability == "configurations.bats" || ele.ability.ability == "configurations.global" || ele.ability.ability == "configurations.pmt";
                });
                if (disable_configuration.length) return true;
                else return false;
            },

            disableAdmin() {
                let flag = this.disableUserManagement() || this.disableConfigurations();
                let template_view = this.permissions.filter((ele) => {
                    return ele.ability.ability == "templates.view";
                });
                return flag || template_view.length > 0;
            },

            disableMasterTemplate() {
                let disable_master_template = this.permissions.filter((ele) => {
                    return ele.ability.ability == "templates.view" || ele.ability.ability == "template_master_costs.view" || ele.ability.ability == "report_line_item.view";
                });
                if (disable_master_template.length) return true;
                else return false;
            },

            disableProject() {
                let disable_project = this.permissions.filter((ele) => {
                    return ele.ability.ability == "projects.create" || ele.ability.ability == "projects.view" || ele.ability.ability == "budget_requests.view" || ele.ability.ability == "pmt_user_forms.view";
                });
                if (disable_project.length) return true;
                else return false;
            },

            disableReports() {
                let user = this.$store.getters.user;
                let disable_reports = this.permissions.filter((ele) => {
                    return ele.ability.ability == "pmt.plant.report" || ele.ability.ability == "pmt.ho.report" || ele.ability.ability == "pmt.plant.report";
                });
                if (disable_reports.length && user.user_type != "BATS") return true;
                else return false;
            },
        },
    };
</script>
<style scoped>
    .sidebar-prime1 {
        background-color: #506fd9;
        background-image: linear-gradient(to bottom, #324daa, #506fd9);
    }

    .header-main .nav-link {
        color: lavender;
    }

    .header-main .nav-link.active {
        font-weight: 600;
        color: white;
        letter-spacing: -0.1px;
    }

    .collpase {
        background-color: #506fd9;
        background-image: linear-gradient(to bottom, #324daa, #506fd9);
    }

    .show {
        background-color: #506fd9;
    }

    .dropdown-menu {
        background-color: white !important;
    }

    @media (min-width: 992px) {
        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
            justify-content: space-between;
        }
    }

    /* drop down */
    .dropdown-menu li {
        position: relative;
    }

    .dropdown-menu .dropdown-submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
    }

    .dropdown-menu .dropdown-submenu-left {
        right: 100%;
        left: auto;
    }

    .dropdown-menu > li:hover > .dropdown-submenu {
        display: block;
    }
    .sub_title {
        font-size: 12px;
        padding-left: 10px;
        font-weight: 200;
        letter-spacing: 0.7px;
    }
    .title {
        font-weight: bold;
        font-size: 25px;
        padding-left: 10px;
        padding-bottom: 0px;
        letter-spacing: 9px;
        display: block !important;
        margin-bottom: -10px;
    }
    .font_size{
        font-size:10pt;
        margin-right:4em;
    }
</style>
