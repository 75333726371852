<template>
    <div class="">
        <div class="row g-2 g-xxl-3 mb-5">
            <div class="col-sm-12 col-md-3">
                <div class="card">
                    <div class="card-header">
                        <strong>Configurations</strong>
                    </div>
                    <div class="card-body hgttt sidebar-body">
                        <div>
                            <div v-can="'configurations.global'" class="nav-group" @click.prevent="toggleCommon()" :class="isCOMMONActive">
                                <a href="javascript:void(0)" class="nav-label fs-16">Global</a>
                                <ul class="nav nav-sidebar">
                                    <li class="nav-item" :class="{active:cluster_active}">
                                        <a href="javascript:void(0)" @click="showCluster()" class="nav-link" :class="{cluster_active:cluster_text}"><i class="ri-shape-line"></i> <span>Cluster</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:plant_active}">
                                        <a href="javascript:void(0)" @click="showPlant()" class="nav-link" :class="{plant_active:plant_text}"><i class="ri-bar-chart-2-fill"></i> <span>Plant</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:project_category_active}">
                                        <a href="javascript:void(0)" @click="showProjectCategory()" class="nav-link" :class="{project_category_active:project_category_text}"><i class="ri-stack-line"></i> <span>Project Category</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:project_group_active}">
                                        <a href="javascript:void(0)" @click="showProjectGroup()" class="nav-link" :class="{project_group_active:project_group_text}"><i class="ri-folder-add-line"></i> <span>Project Group</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:project_type_active}">
                                        <a href="javascript:void(0)" @click="showProjectType()" class="nav-link" :class="{project_type_active:project_type_text}"><i class="ri-function-line"></i> <span>Project Type</span></a>
                                    </li>
                                    <!-- <li class="nav-item" :class="{active:general_active}">
                                        <a href="javascript:void(0)" @click="showGeneral()" class="nav-link" :class="{general_active:general_text}"><i class="ri-shape-line"></i> <span>General</span></a>
                                    </li> -->
                                </ul>
                            </div>
                            <div v-can="'configurations.pmt'" v-if="$store.getters.user.user_type == 'PMT'|| $store.getters.user.user_type == 'BOTH'" class="nav-group" @click.prevent="togglePMT()" :class="isPMTActive">
                                <a href="javascript:void(0)" class="nav-label fs-16">PMT</a>
                                <ul class="nav nav-sidebar">
                                    <li class="nav-item" :class="{active:activity_group_active}">
                                        <a href="javascript:void(0)" @click="showActivityGroup()" class="nav-link" :class="{activity_group_active:activity_group_text}"><i class="ri-contacts-book-line"></i> <span>Activity Group</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:section_group_active}">
                                        <a href="javascript:void(0)" @click="showSectionGroup()" class="nav-link" :class="{section_group_active:section_group_text}"><i class="ri-ruler-2-fill"></i> <span>Section Group</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:section_active}">
                                        <a href="javascript:void(0)" @click="showSection()" class="nav-link" :class="{section_active:section_text}"><i class="ri-pie-chart-fill"></i> <span>Section</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:activity_active}">
                                        <a href="javascript:void(0)" @click="showActivity()" class="nav-link" :class="{activity_active:activity_text}"><i class="ri-grid-fill"></i> <span>Activities</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:unit_active}">
                                        <a href="javascript:void(0)" @click="showUnit()" class="nav-link" :class="{unit_active:unit_text}"><i class="ri-timer-2-line"></i><span>Units</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:parameter_type_active}">
                                        <a href="javascript:void(0)" @click="showParameterType()" class="nav-link" :class="{parameter_type_active:parameter_type_text}">
                                            <i class="ri-bar-chart-horizontal-line"></i> <span>Parameter Type</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" :class="{active:parameter_active}">
                                        <a href="javascript:void(0)" @click="showParameter()" class="nav-link" :class="{parameter_active:parameter_text}"><i class="ri-pages-line"></i><span>Parameters</span></a>
                                    </li>

                                    <li class="nav-item" :class="{active:frequency_active}">
                                        <a href="javascript:void(0)" @click="showFrequency()" class="nav-link" :class="{frequency_active:frequency_text}"><i class="ri-rhythm-line"></i><span>Frequency</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:equipment_active}">
                                        <a href="javascript:void(0)" @click="showEquipment()" class="nav-link" :class="{equipment_active:equipment_text}"><i class="ri-equalizer-line"></i> <span>Equipments</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:capacity_active}">
                                        <a href="javascript:void(0)" @click="showCapacity()" class="nav-link" :class="{capacity_active:capacity_text}"><i class="ri-scales-2-line"></i> <span>Capacity</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:packagea}">
                                        <a href="javascript:void(0)" @click="showPackage()" class="nav-link" :class="{package:package_text}"><i class="ri-red-packet-line"></i> <span>Package</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:activity_description_active}">
                                        <a href="javascript:void(0)" @click="showActivityDescription()" class="nav-link" :class="{activity_description_active:activity_description_text}"><i class="ri-profile-fill"></i> <span>Activity Description</span></a>
                                    </li>
                                </ul>
                            </div>
                            <div v-can="'configurations.bats'" v-if="$store.getters.user.user_type == 'BATS'|| $store.getters.user.user_type == 'BOTH'" class="nav-group" @click.prevent="toggleBats()" :class="isBATSActive">
                                <a href="javascript:void(0)" class="nav-label fs-16">BATS</a>
                                <ul class="nav nav-sidebar">
                                    <!-- <li class="nav-item" :class="{active:cost_group_active}">
                                        <a href="javascript:void(0)" @click="showCostGroups()" class="nav-link" :class="{cost_group_active:cost_group_text}"><i class="ri-apps-line"></i> <span>Level 1 (Cost Groups)</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:cost_sub_group_active}">
                                        <a href="javascript:void(0)" @click="showCostSubGroups()" class="nav-link" :class="{cost__sub_group_active:cost_sub_group_text}">
                                            <i class="ri-currency-line"></i> <span>Level 2 (Cost Sub Groups)</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" :class="{active:cost_head_active}">
                                        <a href="javascript:void(0)" @click="showCostHeads()" class="nav-link" :class="{cost_head_active:cost_head_text}"><i class="ri-server-line"></i> <span>Level 3 (Cost Heads)</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:cost_sub_head_active}">
                                        <a href="javascript:void(0)" @click="showCostSubHeads()" class="nav-link" :class="{cost_sub_head_active:cost_sub_head_text}"><i class="ri-bank-card-line"></i><span>Level 4 (Cost Sub Heads)</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:cost_item_active}">
                                        <a href="javascript:void(0)" @click="showCostItems()" class="nav-link" :class="{cost_item_active:cost_item_text}"><i class="ri-coins-line"></i> <span>Level 5 (Cost Items)</span></a>
                                    </li>
                                    <li class="nav-item" :class="{active:cost_sub_item_active}">
                                        <a href="javascript:void(0)" @click="showCostSubItems()" class="nav-link" :class="{cost_sub_item_active:cost_sub_item_text}"><i class="ri-coin-line"></i> <span>Level 6 (Cost Sub Items)</span></a>
                                    </li> -->
                                    <!-- <li class="nav-item" :class="{active:cost_sub_sub_item_active}">
                                        <a href="javascript:void(0)" @click="showCostSubSubItems()" class="nav-link" :class="{cost_sub_sub_item_active:cost_sub_sub_item_text}"><i class="ri-archive-drawer-line"></i> <span>Cost Sub Sub Items</span></a>
                                    </li> -->
                                    <li class="nav-item" :class="{active:cost_type_active}">
                                        <a href="javascript:void(0)" @click="showCostType()" class="nav-link" :class="{cost_type_active:cost_type_text}"><i class="ri-exchange-line"></i> <span>Cost Types</span></a>
                                    </li>
                                    <li v-can="'report_line_item.view'" class="nav-item" :class="{active:cost_type_active}">
                                        <router-link to="/bats/report_templates/create" class="nav-link"><i
                                    class="ri-money-dollar-circle-line"></i><span>Report Line Items</span></router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-9">
                <Master v-if="show_master" :initial_page="1"/>
                <ParameterIndex v-if="show_parameter_index" @addParameter="addParameter" @showValue="showValue" />
                <ParameterCreate v-if="show_parameter_create" @indexParameter="indexParameter" ref="create_parameter" />
            </div>
        </div>
    </div>
</template>
<script>
    import Master from "@/components/Master.vue";
    import ParameterIndex from "@/components/parameters/Index.vue";
    import ParameterCreate from "@/components/parameters/Create.vue";
    export default {
        components: { Master, ParameterIndex, ParameterCreate },
        data() {
            return {
                packagea:'',
                package_text:'',
                add_url: "addCluster",
                update_url: "updateCluster",
                delete_url: "deleteCluster",
                index_url: "paginateClusters",
                update_url: "updateCluster",
                multiple_delete_url: "multipleDeleteClusters",
                cluster_active: true,
                cluster_text: "white",
                plant_active: false,
                plant_text: "",
                project_category_active: false,
                project_category_text: "",
                project_group_active: false,
                project_group_text: "",
                project_type_active: false,
                project_type_text: "",
                section_group_active: false,
                section_group_text: "",
                section_active: false,
                section_text: "",
                equipment_active: false,
                equipment_text: "",
                activity_group_active: false,
                activity_group_text: "",
                activity_active: false,
                activity_text: "",
                parameter_type_active: false,
                parameter_type_text: "",
                parameter_active: false,
                parameter_text: "",
                unit_active: false,
                unit_text: "",
                frequency_active: false,
                frequency_text: "",
                cost_group_active: false,
                cost_sub_group_active: false,
                cost_group_text: "",
                cost_sub_group_text: "",
                cost_head_active: false,
                cost_head_text: "",
                cost_sub_head_active: false,
                cost_sub_head_text: "",
                cost_item_active: false,
                cost_item_text: "",
                cost_sub_item_active: false,
                cost_sub_item_text: "",
                cost_sub_sub_item_active: false,
                cost_sub_sub_item_text: "",
                cost_type_active: false,
                cost_type_text: "",
                capacity_text: "",
                capacity_active: false,
                activity_description_text: "",
                activity_description_active: false,
                show_master: true,
                show_parameter_index: false,
                show_parameter_create: false,
                isPMTActive: "",
                isBATSActive: "",
                isCOMMONActive: "show",
                modules: [
                    "cluster",
                    "plant",
                    "project_category",
                    "project_group",
                    "project_type",
                    "area",
                    "section",
                    "equipment",
                    "activity_group",
                    "section_group",
                    "activity",
                    "parameter_type",
                    "parameter",
                    "unit",
                    "frequency",
                    "cost_group",
                    "cost_sub_group",
                    "cost_head",
                    "cost_sub_head",
                    "cost_item",
                    "cost_sub_item",
                    "cost_type",
                    "capacity",
                    "activiy_description"
                ],
                cluster: {
                    is_active: true,
                    text_color: "",
                },
                parameter_id: "",
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "cluster_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                param: {
                    title: "Cluster",
                    add_url: "addCluster",
                    index_url: "paginateClusters",
                    update_url: "updateCluster",
                    delete_url: "deleteCluster",
                    force_delete_url: "forceDeleteCluster",
                    multiple_delete_url: "multipleDeleteClusters",
                    keyword: "cluster_id",
                    edit: {
                        name: "cluster_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cluster Code",
                            name: "cluster_code",
                            value: "",
                            readonly: false,
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                            is_code:true
                        },
                        {
                            head: "Cluster Name",
                            name: "cluster_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                            is_code:false
                        },
                    ],
                },
            };
        },
        updated(){

            // this.$store.watch(
            //     (state)=>{
            //         return this.$store.state.param // could also put a Getter here
            //     },
            //     (newValue, oldValue)=>{
            //         console.log(newValue)
            //         //something changed do something
            //         // if(newValue){
            //         //     this.index()
            //         // }
            //     },
            // )
        },

        mounted() {
            this.$store.dispatch("setParam", this.param);
        },
        methods: {
            showGeneral() {},
            showValue(parameter_id) {
                this.show_master = false;
                this.show_parameter_index = false;
                this.show_parameter_create = true;
                this.$store.dispatch("setParameterId", parameter_id);
            },
            addParameter() {
                this.show_master = false;
                this.show_parameter_index = false;
                this.show_parameter_create = true;
                this.$store.dispatch("setParameterId", null);
            },
            indexParameter() {
                this.show_master = false;
                this.show_parameter_index = true;
                this.show_parameter_create = false;
            },
            togglePMT() {
                if (this.isPMTActive == "show") this.isPMTActive = "";
                else this.isPMTActive = "show";
                this.isBATSActive = "";
                this.isCOMMONActive = "";
            },

            showPMT() {
                this.show_parameter_index = false;
                this.show_parameter_create = false;
                this.isPMTActive = "";
                this.show_master = true;
            },
            toggleCommon() {
                if (this.isCOMMONActive == "show") this.isCOMMONActive = "";
                else this.isCOMMONActive = "show";
                this.isPMTActive = "";
                this.isBATSActive = "";
            },

            showCOMMON() {
                this.show_parameter_index = false;
                this.show_parameter_create = false;
                this.isCOMMONActive = "";
                this.show_master = true;
            },
            showBATS() {
                this.show_parameter_index = false;
                this.show_parameter_create = false;
                this.isBATSActive = "";
                this.show_master = true;
            },

            setActive(module) {
                let vm = this;
                this.modules.map(function (element) {
                    let active = element + "_active";
                    let text = element + "_text";
                    if (module == element) {
                        vm[active] = true;
                        vm[text] = "white";
                    } else {
                        vm[active] = false;
                        vm[text] = "";
                    }
                });
            },

            showCluster() {
                let param = {
                    title: "Cluster",
                    add_url: "addCluster",
                    index_url: "paginateClusters",
                    update_url: "updateCluster",
                    delete_url: "deleteCluster",                    
                    multiple_delete_url: "multipleDeleteClusters",
                    force_delete_url: "forceDeleteCluster",
                    keyword: "cluster_id",
                    edit: {
                        name: "cluster_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cluster Code",
                            name: "cluster_code",
                            value: "",
                            readonly: false,
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                            is_code:true
                        },
                        {
                            head: "Cluster Name",
                            name: "cluster_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                            is_code:false
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showCOMMON();
                this.setActive("cluster");
            },

            showPlant() {
                let param = {
                    title: "Plant",
                    add_url: "addPlant",
                    index_url: "paginatePlants",
                    update_url: "updatePlant",
                    keyword: "plant_id",
                    delete_url: "deletePlant",
                    multiple_delete_url: "multipleDeletePlants",
                    force_delete_url: "forceDeletePlant",
                    edit: {
                        name: "plant_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cluster",
                            name: "cluster_id",
                            value: "",
                            input_type: "dropdown",
                            object: "cluster",
                            object_name: "cluster_name",
                            error_name: "",
                        },
                        {
                            head: "Plant Code",
                            name: "plant_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Plant Name",
                            name: "plant_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showCOMMON();
                this.setActive("plant");
            },

            showProjectCategory() {
                let param = {
                    title: "Project Category",
                    add_url: "addProjectCategory",
                    index_url: "paginateProjectCategories",
                    update_url: "updateProjectCategory",
                    keyword: "project_category_id",
                    delete_url: "deleteProjectCategory",
                    multiple_delete_url: "multipleDeleteProjectCategory",
                    force_delete_url: "forceDeleteProjectCategory",
                    edit: {
                        name: "project_category_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Project Category Code",
                            name: "project_category_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Project Category Name",
                            name: "project_category_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showCOMMON();
                this.setActive("project_category");
            },

            showProjectGroup() {
                let param = {
                    title: "Project Group",
                    add_url: "addProjectGroup",
                    index_url: "paginateProjectGroups",
                    update_url: "updateProjectGroup",
                    keyword: "project_group_id",
                    delete_url: "deleteProjectGroup",
                    multiple_delete_url: "multipleDeleteProjectGroups",
                    force_delete_url: "forceDeleteProjectGroup",
                    edit: {
                        name: "project_group_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Project Group Code",
                            name: "project_group_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Project Group Name",
                            name: "project_group_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showCOMMON();
                this.setActive("project_group");
            },

            showProjectType() {
                let param = {
                    title: "Project Type",
                    add_url: "addProjectType",
                    index_url: "paginateProjectType",
                    update_url: "updateProjectType",
                    keyword: "project_type_id",
                    delete_url: "deleteProjectType",
                    multiple_delete_url: "multipleDeleteProjectTypes",
                    force_delete_url: "forceDeleteProjectType",
                    edit: {
                        name: "project_type_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Project Type Code",
                            name: "project_type_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Project Type Name",
                            name: "project_type_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Months",
                            name: "months",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showCOMMON();
                this.setActive("project_type");
            },

            showActivityGroup() {
                let param = {
                    title: "activity_group",
                    add_url: "addActivityGroup",
                    index_url: "paginateActivityGroup",
                    update_url: "updateActivityGroup",
                    keyword: "activity_group_id",
                    delete_url: "deleteActivityGroup",
                    multiple_delete_url: "multipleDeleteActivityGroup",
                    force_delete_url: "forceDeleteActivityGroup",
                    edit: {
                        name: "activity_group_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Activity Group Code",
                            name: "activity_group_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Activity Group Name",
                            name: "activity_group_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("activity_group");
            },

            showSectionGroup() {
                let param = {
                    title: "Section Group",
                    add_url: "addSectionGroup",
                    index_url: "paginateSectionGroups",
                    update_url: "updateSectionGroup",
                    keyword: "section_group_id",
                    delete_url: "deleteSectionGroup",
                    multiple_delete_url: "multipleDeleteSectionGroup",
                    force_delete_url: "forceDeleteSectionGroup",
                    edit: {
                        name: "section_group_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Section Group Code",
                            name: "section_group_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Section Group Name",
                            name: "section_group_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("section_group");
            },

            showSection() {
                let param = {
                    title: "Section",
                    add_url: "addSection",
                    index_url: "paginateSections",
                    update_url: "updateSection",
                    keyword: "section_id",
                    delete_url: "deleteSection",
                    multiple_delete_url: "multipleDeleteSections",
                    force_delete_url: "forceDeleteSection",
                    edit: {
                        name: "section_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Section Code",
                            name: "section_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Section Name",
                            name: "section_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("section");
            },

            showActivity() {
                let param = {
                    title: "Activity",
                    add_url: "addActivity",
                    index_url: "paginateActivity",
                    update_url: "updateActivity",
                    keyword: "activity_id",
                    delete_url: "deleteActivity",
                    multiple_delete_url: "multipleDeleteActivity",
                    force_delete_url: "forceDeleteActivity",
                    edit: {
                        name: "activity_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Activity Code",
                            name: "activity_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Activity Name",
                            name: "activity_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("activity");
            },

            showUnit() {
                let param = {
                    title: "Unit",
                    add_url: "addUnit",
                    index_url: "paginateUnit",
                    update_url: "updateUnit",
                    keyword: "unit_id",
                    delete_url: "deleteUnit",
                    multiple_delete_url: "multipleDeleteUnits",
                    force_delete_url: "forceDeleteUnit",
                    edit: {
                        name: "unit_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Unit Code",
                            name: "unit_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Unit Name",
                            name: "unit_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("unit");
            },

            showParameterType() {
                let param = {
                    title: "Parameter Type",
                    add_url: "addParameterType",
                    index_url: "paginateParameterTypes",
                    update_url: "updateParameterType",
                    keyword: "parameter_type_id",
                    delete_url: "deleteParameterType",
                    multiple_delete_url: "multipleDeleteParameterType",
                    force_delete_url: "forceDeleteParameterType",
                    edit: {
                        name: "parameter_type_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Parameter Type Code",
                            name: "parameter_type_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Parameter Type Name",
                            name: "parameter_type_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("parameter_type");
            },

            showParameter() {
                this.isPMTActive = "";
                this.show_master = false;
                this.show_parameter_index = true;
                this.cluster_active = false;
                this.cluster_text = "";
                this.plant_text = "";
                this.plant_active = false;
                this.project_category_text = "";
                this.project_category_active = false;
                this.project_group_text = "";
                this.project_group_active = false;
                this.project_type_text = "";
                this.project_type_active = false;
                this.section_group_text = "";
                this.section_group_active = false;
                this.section_text = "";
                this.section_active = false;
                this.equipment_text = "";
                this.equipment_active = false;
                this.activity_group_text = "";
                this.activity_group_active = false;
                this.activity_text = "";
                this.activity_active = false;
                this.parameter_type_text = "";
                this.parameter_type_active = false;
                this.parameter_text = "white";
                this.parameter_active = true;
                this.unit_text = "";
                this.unit_active = false;
                this.frequency_text = "";
                this.frequency_active = false;
                this.cost_group_text = "";
                this.cost_group_active = false;
                this.cost__sub_group_text = "";
                this.cost_sub_group_active = false;
                this.cost_head_text = "";
                this.cost_head_active = false;
                this.cost_sub_head_text = "";
                this.cost_sub_head_active = false;
                this.cost_item_text = "";
                this.cost_item_active = false;
                this.cost_sub_item_text = "";
                this.cost_sub_item_active = false;
                this.cost_sub_sub_item_text = "";
                this.cost_sub_sub_item_active = false;
                this.cost_type_text = "";
                this.cost_type_active = false;
                this.capacity_text = "";
                this.capacity_active = false;
                this.activity_description_text = "";
                this.activity_description_active = false;
                this.indexParameter();
            },   

            showFrequency() {
                let param = {
                    title: "Frequency",
                    add_url: "addFrequency",
                    index_url: "paginateFrequencies",
                    update_url: "updateFrequency",
                    keyword: "frequency_id",
                    delete_url: "deleteFrequency",
                    multiple_delete_url: "multipleDeleteFrequency",
                    force_delete_url: "forceDeleteFrequency",
                    edit: {
                        name: "frequency_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Frequency Code",
                            name: "frequency_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Frequency Name",
                            name: "frequency_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "No. of Days",
                            name: "no_of_days",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("frequency");
            },

            showEquipment() {
                let param = {
                    title: "Equipment",
                    add_url: "addEquipment",
                    index_url: "paginateEquipment",
                    update_url: "updateEquipment",
                    keyword: "equipment_id",
                    delete_url: "deleteEquipment",
                    multiple_delete_url: "multipleDeleteEquipment",
                    force_delete_url: "forceDeleteEquipment",
                    edit: {
                        name: "equipment_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Equipment Code",
                            name: "equipment_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Equipment Name",
                            name: "equipment_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("equipment");
            },

            showCapacity() {
                let param = {
                    title: "Capacity",
                    add_url: "addCapacity",
                    index_url: "paginateCapacity",
                    update_url: "updateCapacity",
                    keyword: "capacity_id",
                    delete_url: "deleteCapacity",
                    multiple_delete_url: "multipleDeleteCapacities",
                    force_delete_url: "forceDeleteCapacity",
                    edit: {
                        name: "capacity_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Capacity Code",
                            name: "capacity_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Capacity Name",
                            name: "capacity_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("capacity");
            },

            showPackage() {
                let param = {
                    title: "package",
                    add_url: "addPackage",
                    index_url: "paginatePackages",
                    update_url: "updatePackage",
                    keyword: "package_id",
                    delete_url: "deletePackage",
                    multiple_delete_url: "multipleDeletePackages",
                    force_delete_url: "forceDeletePackage",
                    edit: {
                        name: "package_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Package Name",
                            name: "package_name",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("package");
            },

            showActivityDescription() {
                let param = {
                    title: "Activity Description",
                    add_url: "addActivityDescription",
                    index_url: "paginateActivityDescriptions",
                    update_url: "updateActivityDescription",
                    keyword: "activity_description_id",
                    delete_url: "deleteActivityDescription",
                    multiple_delete_url: "multipleDeleteActivityDescriptions",
                    force_delete_url: "forceDeleteActivityDescription",
                    edit: {
                        name: "activity_description_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Activity Description Code",
                            name: "activity_description_code",
                            value: "",
                            readonly: "true",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Activity Description Name",
                            name: "activity_description_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showPMT();
                this.setActive("activity_description");
            },

            //BATS methods
            toggleBats() {
                if (this.isBATSActive == "show") this.isBATSActive = "";
                else this.isBATSActive = "show";
                this.isPMTActive = "";
            },

            setBATSActive() {
                this.show_master = true;
                this.isBATSActive = "";
                this.cost_group_text = "";
                this.cost_group_active = false;
                this.cost_sub_group_text = "";
                this.cost_sub_group_active = false;
            },

            showCostGroups() {
                let param = {
                    title: "Level 1 (Cost Group)",
                    add_url: "addCostGroup",
                    index_url: "paginateCostGroups",
                    update_url: "updateCostGroup",
                    keyword: "cost_group_id",
                    delete_url: "deleteCostGroup",
                    multiple_delete_url: "multipleDeleteCostGroup",
                    force_delete_url: "forceDeleteCostGroup",
                    edit: {
                        name: "cost_group_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cost Group Code",
                            name: "cost_group_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Cost Group Name",
                            name: "cost_group_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "WBS Code",
                            name: "wbs_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showBATS();
                this.setActive("cost_group");
            },
            showCostSubGroups() {
                let param = {
                    title: "Level 2 (Cost Sub Group)",
                    add_url: "addCostSubGroup",
                    index_url: "paginateCostSubGroups",
                    update_url: "updateCostSubGroup",
                    keyword: "cost_sub_group_id",
                    delete_url: "deleteCostSubGroup",
                    multiple_delete_url: "multipleDeleteCostSubGroups",
                    force_delete_url: "forceDeleteCostSubGroup",
                    edit: {
                        name: "cost_sub_group_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cost Sub Group Code",
                            name: "cost_sub_group_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Cost Sub Group Name",
                            name: "cost_sub_group_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "WBS Code",
                            name: "wbs_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showBATS();
                this.setActive("cost_sub_group");
            },

            showCostHeads() {
                let param = {
                    title: "Level 3 (Cost Head)",
                    add_url: "addCostHead",
                    index_url: "paginateCostHeads",
                    update_url: "updateCostHead",
                    keyword: "cost_head_id",
                    delete_url: "deleteCostHead",
                    multiple_delete_url: "multipleDeleteCostHead",
                    force_delete_url: "forceDeleteCostHead",
                    edit: {
                        name: "cost_head_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cost Head Code",
                            name: "cost_head_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Cost Head Name",
                            name: "cost_head_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "WBS Code",
                            name: "wbs_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showBATS();
                this.setActive("cost_head");
            },

            showCostSubHeads() {
                let param = {
                    title: "Level 4 (Cost Sub Head)",
                    add_url: "addCostSubHead",
                    index_url: "paginateCostSubHeads",
                    update_url: "updateCostSubHead",
                    keyword: "cost_sub_head_id",
                    delete_url: "deleteCostSubHead",
                    multiple_delete_url: "multipleDeleteCostSubHead",
                    force_delete_url: "forceDeleteCostSubHead",
                    edit: {
                        name: "cost_sub_head_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cost Sub Head Code",
                            name: "cost_sub_head_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Cost Sub Head Name",
                            name: "cost_sub_head_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "WBS Code",
                            name: "wbs_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showBATS();
                this.setActive("cost_sub_head");
            },

            showCostItems() {
                let param = {
                    title: "Level 5 (Cost Item)",
                    add_url: "addCostItem",
                    index_url: "paginateCostItems",
                    update_url: "updateCostItem",
                    keyword: "cost_item_id",
                    delete_url: "deleteCostItem",
                    multiple_delete_url: "multipleDeleteCostItem",
                    force_delete_url: "forceDeleteCostItem",
                    edit: {
                        name: "cost_item_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cost Item Code",
                            name: "cost_item_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Cost Item Name",
                            name: "cost_item_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "WBS Code",
                            name: "wbs_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showBATS();
                this.setActive("cost_item");
            },

            showCostSubItems() {
                let param = {
                    title: "Level 6 (Cost Sub Item)",
                    add_url: "addCostSubItem",
                    index_url: "paginateCostSubItems",
                    update_url: "updateCostSubItem",
                    delete_url: "deleteCostSubItem",
                    multiple_delete_url: "multipleDeleteCostSubItem",
                    force_delete_url: "forceDeleteCostSubItem",
                    keyword: "cost_sub_item_id",
                    edit: {
                        name: "cost_sub_item_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cost Sub Item Code",
                            name: "cost_sub_item_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Cost sub Item Name",
                            name: "cost_sub_item_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "WBS Code",
                            name: "wbs_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showBATS();
                this.setActive("cost_sub_item");
            },
            showCostSubSubItems() {
                let param = {
                    title: "Level 7 (Cost Sub Sub Item)",
                    add_url: "addCostSubSubItem",
                    index_url: "paginateCostSubSubItems",
                    update_url: "updateCostSubSubItem",
                    delete_url: "deleteCostSubSubItem",
                    keyword: "cost_sub_sub_item_id",
                    multiple_delete_url: "multipleDeleteCostSubSubItems",
                    force_delete_url: "forceDeleteCostSubSubItems",
                    edit: {
                        name: "cost_sub_sub_item_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cost Sub Sub Item Code",
                            name: "cost_sub_sub_item_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "Cost Sub Sub Item Name",
                            name: "cost_sub_sub_item_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                        {
                            head: "WBS Code",
                            name: "wbs_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                            error_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showBATS();
                this.setActive("cost_sub_sub_item");
            },

            showCostType() {
                let param = {
                    title: "Cost Type",
                    add_url: "addCostType",
                    index_url: "paginateCostTypes",
                    update_url: "updateCostType",
                    keyword: "cost_type_id",
                    delete_url: "deleteCostType",
                    multiple_delete_url: "multipleDeleteCostType",
                    force_delete_url: "forceDeleteCostType",
                    edit: {
                        name: "cost_type_id",
                        value: "",
                    },
                    columns: [
                        {
                            head: "Cost Type Code",
                            name: "cost_type_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                        },
                        {
                            head: "Cost Type Name",
                            name: "cost_type_name",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                        },
                        {
                            head: "WBS Code",
                            name: "wbs_code",
                            value: "",
                            input_type: "text",
                            object: "",
                            object_name: "",
                        },
                    ],
                };
                this.$store.dispatch("setParam", param);
                this.showBATS();
                this.setActive("cost_type");
            },
        },
    };
</script>

<style scoped>
    .active {
        background-color: rgb(13, 110, 253);
    }
    .cluster_active {
        color: white;
    }
    .cluster_active:hover {
        color: white;
    }
    .plant_active {
        color: white;
    }
    .plant_active:hover {
        color: white;
    }
    .project_category_active {
        color: white;
    }
    .project_category_active:hover {
        color: white;
    }
    .project_group_active {
        color: white;
    }
    .project_group_active:hover {
        color: white;
    }
    .project_type_active {
        color: white;
    }
    .project_type_active:hover {
        color: white;
    }
    .section_group_active {
        color: white;
    }
    .section_group_active:hover {
        color: white;
    }
    .section_active {
        color: white;
    }
    .section_active:hover {
        color: white;
    }
    .equipment_active {
        color: white;
    }
    .equipment_active:hover {
        color: white;
    }
    .activity_group_active {
        color: white;
    }
    .activity_group_active:hover {
        color: white;
    }
    .activity_active {
        color: white;
    }
    .activity_active:hover {
        color: white;
    }
    .parameter_type_active {
        color: white;
    }
    .parameter_type_active:hover {
        color: white;
    }
    .parameter_active {
        color: white;
    }
    .parameter_active:hover {
        color: white;
    }
    .unit_active {
        color: white;
    }
    .unit_active:hover {
        color: white;
    }
    .frequency_active {
        color: white;
    }
    .frequency_active:hover {
        color: white;
    }
    .cost_group_active {
        color: white;
    }
    .cost_group_active:hover {
        color: white;
    }
    .cost_sub_group_active {
        color: white;
    }
    .cost_sub_group_active:hover {
        color: white;
    }
    .cost_head_active {
        color: white;
    }
    .cost_head_active:hover {
        color: white;
    }
    .cost_sub_head_active {
        color: white;
    }
    .cost_sub_head_active:hover {
        color: white;
    }
    .cost_item_active {
        color: white;
    }
    .cost_item_active:hover {
        color: white;
    }
    .cost_sub_item_active {
        color: white;
    }
    .cost_sub_item_active:hover {
        color: white;
    }
    .cost_sub_sub_item_active {
        color: white;
    }
    .cost_sub_sub_item_active:hover {
        color: white;
    }
    .cost_type_active {
        color: white;
    }
    .cost_type_active:hover {
        color: white;
    }
    ::-webkit-scrollbar {
        height: 10px;
        width: 3px;
        /* background-color: #9ea7b1; */
    }
    .chat-sidebar .sidebar-body {
        padding: 20px 10px 10px;
        height: calc(100% - 60px);
        position: relative;
        overflow: hidden;
        overflow-y: auto;
    }

    .hgttt {
        height: calc(100vh - 155px);
        overflow-y: auto;
    }
    .fs-16 {
        font-size: 12px;
        font-weight: 600;
    }

    .capacity_active {
        color: white;
    }
    .capacity_active:hover {
        color: white;
    }
    .activity_description_active {
        color: white;
    }
    .activity_description_active:hover {
        color: white;
    }
</style>
