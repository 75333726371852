<template>
    <div class="card">
        <div class="card-header">
            <strong>{{ project?.project_name }}-Images</strong>
            <div style="float: right;" class="d-flex display-content">
                <input type="file" ref="file" multiple class="form-control form-controls-sm me-2 mb-1" @change="getImages($event)" style="display: block; width: 100%;" />
                <!-- <button class="btn btn-primary btn-sm me-2" @click="uploadImages($event)">Upload</button> -->
                <!-- <button class="btn btn-primary me-2 btn-sm" @click="downloadPMTForm()">Download</button> -->
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <label class="form-label">Upload Date <span class="text-danger"> *</span></label>
                    <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.upload_date }" v-model="meta.upload_date">
                    <span v-if="errors.upload_date"><small class="text-danger">{{ errors[0].upload_date }}</small></span>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Activity Groups <span class="text-danger"> *</span></label>
                    <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.project_activity_group_id }" v-model="meta.project_activity_group_id" @change="getProjectSectionGroups()">
                        <option value="">Select Activity Group</option>
                        <option v-for="project_activity_group, key in project_activity_groups" :key="key" :value="project_activity_group.project_activity_group_id">{{ project_activity_group.activity_group.activity_group_name }}</option>
                    </select>
                    <span v-if="errors.project_activity_group_id"><small class="text-danger">{{ errors.project_activity_group_id[0] }}</small></span>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Section Groups <span class="text-danger"> *</span></label>
                    <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.project_section_group_id }" v-model="meta.project_section_group_id" @change="getProjectSections()">
                        <option value="">Select Section Group</option>
                        <option v-for="project_section_group, key in project_section_groups" :key="key" :value="project_section_group.project_section_group_id">{{ project_section_group.section_group.section_group_name }}</option>
                    </select>
                    <span v-if="errors.project_section_group_id"><small class="text-danger">{{ errors.project_section_group_id[0] }}</small></span>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Sections <span class="text-danger"> *</span></label>
                    <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.project_section_id }" v-model="meta.project_section_id">
                        <option value="">Select Section</option>
                        <option v-for="project_section, key in project_sections" :key="key" :value="project_section.project_section_id">{{ project_section.section.section_name }}</option>
                    </select>
                    <span v-if="errors.project_section_id"><small class="text-danger">{{ errors.project_section_id[0] }}</small></span>
                </div>
            </div>
            <div class="row mt-3">
                <!-- Images to be uploaded  -->
                <div class="col-md-3" v-for="pr_image, key in pr_images" :key="key">
                    <input type="checkbox" title="Add" :value="pr_image.name" v-model="meta.checked_images" @change="updateProjectImage(pr_image)">
                    <a href="javascript:void(0)" class="text-danger" title="Remove" @click.prevent="removeImage(pr_image)"><i class="ri-close-circle-line" style="float:right"></i></a>
                    <img :src="pr_image.image" alt="image" width="250" height="150" style="padding-bottom:10px">
                    <label style="font-size:10pt">Note</label>
                    <input type="textarea" v-model="pr_image.note" class="form-control" placeholder="Note">
                </div>
            </div>
        </div>
        <!-- <div class="card-footer">
            <div class="row">
                <div class="col-md-12">
                    <div style="float:right">
                        <button class="btn btn-primary" @click.prevent="updateProjectImages()"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div class="card">
        <div class="card-header">

        </div>
        <div class="card-body">
            <div class="row">
                <!-- Uploaded Images -->
                <div class="col-md-3" v-for="project_image, key in project_images" :key="key">
                    <a href="javascript:void(0)" class="text-danger" title="Remove" @click.prevent="deleteImage(project_image)"><i class="ri-close-circle-line" style="float:right"></i></a>
                    <img :src="project_image.file_name" alt="image" width="250" height="150">
                    <span style="font-size:8pt">Date : {{ project_image.upload_date }}</span><br/>
                    <span style="font-size:8pt">{{project_image.activity_group.activity_group_name+' / '+project_image.section_group.section_group_name+' / '+project_image.section.section_name}}</span><br/>
                    <span style="font-size:8pt;padding-bottom:10px">Note: {{project_image.note }}</span>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between align-items-center">
                <select class="form-select from-select-sm width-75" v-model="meta.per_page"
                    @change="onPerPageChange">
                    <option>12</option>
                    <option>20</option>
                    <option>28</option>
                    <option>32</option>
                    <option>40</option>
                </select>
                <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                    @pagechanged="onPageChange" />
            </div>
        </div>
    </div>
</template>
<script>
import Search from '@/components/Search.vue';
import Pagination from "@/components/Pagination.vue";
import moment from 'moment';
    export default {
        components: { Search, Pagination },
        name: "Images",
        props: {
            project: Object,
        },
        data(){
            return{
                images:[],
                project_images :[],
                checkedNames:[],
                checked_image:'',
                meta:{
                    project_id:'',
                    project_activity_group_id:'',
                    project_section_group_id:'',
                    project_section_id:'',
                    checked_images:[],
                    upload_date:'',
                    note:'',
                    delete_images:[],
                    order_by:'desc',
                    keyword:'project_image_id',
                    per_page:12,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                },
                project_activity_groups:[],
                project_section_groups:[],
                project_sections:[],
                errors:[],
                pr_images:[]
            }
        },

        mounted(){
            this.getProjectImages()
            this.getProjectActivityGroups()
            this.meta.upload_date = moment().format('YYYY-MM-DD')
            this.meta.project_id = this.project.project_id
        },

        methods:{
            getTitle(project_image){
                let text = 'Date : '+project_image.upload_date+'&#10;Activity Group : '+project_image.activity_group.activity_group_name
                return text
            },
            deleteImage(project_image){
                let vm = this
                if(confirm("Are you sure you want to remove image?")){
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteProjectImage", data: project_image };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            loader.hide();
                            vm.getProjectImages()
                        })
                        .catch(function (error) {
                            loader.hide();
                        });
                }
            },
            removeImage(image){
                let vm = this
                if(confirm("Are you sure you want to remove image?")){
                    let image_exists = vm.pr_images.filter(function(pr_image){
                        return pr_image.name != image.name
                    })
                    let checked_images = vm.meta.checked_images.filter(function(checked_image){
                        return image.name != checked_image.name 
                    })
                    vm.pr_images = image_exists
                    vm.meta.checked_images = checked_images
                    vm.errors = []
                }
            },
            getImages(e) {
                let vm = this
                vm.images = e.target.files
                for(let i = 0; i<vm.images.length; i++){
                    console.log(vm.images[i].name)
                    let reader = new FileReader();
                    reader.readAsDataURL(vm.images[i]);
                    reader.onload = (e) => {
                        let image_exists = vm.pr_images.filter(function(pr_image){
                            return pr_image.name == vm.images[i].name
                        })
                        if(!image_exists.length){
                            vm.pr_images.push({
                                image:e.target.result,
                                name:vm.images[i].name,
                                note:'',
                                file : vm.images[i]
                            })
                        }
                    };
                }
            },

            getProjectActivityGroups() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.meta.project_id = this.project.project_id
                let uri = { uri: "getProjectActivityGroups", data: vm.meta };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.project_activity_groups = response.data.data
                    })
                    .catch(function (error) {
                        loader.hide();
                    });
            },

            getProjectSectionGroups() {
                let vm = this;
                let loader = vm.$loading.show()
                let uri = { uri: "getProjectSectionGroupsPag", data: vm.meta };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.project_section_groups = response.data.data
                    })
                    .catch(function (error) {
                        loader.hide();
                    });
            },

            getProjectSections() {
                let vm = this;
                let loader = vm.$loading.show()
                let uri = { uri: "getProjectSectionsPMTEdit", data: vm.meta };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.project_sections = response.data.data
                    })
                    .catch(function (error) {
                        loader.hide();
                    });
            },

            getProjectImages(){
                let vm = this
                let loader = vm.$loading.show()
                vm.meta.project_id = vm.project.project_id
                vm.$store
                    .dispatch("post", { uri: "paginateProjectImages", data: vm.meta })
                    .then(function (response) {
                        loader.hide()
                        vm.project_images = response.data.data
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        console.log(error)
                        loader.hide();
                        // vm.errors = error.response.data.errors;
                        // vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard(){
                this.meta.project_activity_group_id = ''
                this.meta.project_section_group_id = ''
                this.meta.project_section_id = ''
                this.$refs.file.value = ''
                this.meta.checked_images = []
                this.meta.delete_images = []
                this.errors = []
            },
            updatePrImages(pr_image){
                let vm = this
                let temp_images = vm.pr_images.filter(function(element){
                    return element.name != pr_image.name
                })
                vm.pr_images = temp_images
                // vm.meta.checked_images.map(function(checked_image){
                //     console.log(checked_image.name)
                //     let temp_images = vm.pr_images.filter(function(element){
                //         console.log(element)
                //         return element.name != checked_image.name
                //     })
                //     vm.pr_images = temp_images
                // })
            },
            updateProjectImage(pr_image){
                let vm = this
                console.log(vm.meta.checked_images)
                if(vm.meta.upload_date && vm.meta.project_activity_group_id && vm.meta.project_section_group_id && vm.meta.project_section_id){
                    let loader = vm.$loading.show()
                    const formData = new FormData()
                    formData.append('image', pr_image.file)
                    formData.append('note', pr_image.note)
                    formData.append('project_id', vm.project.project_id)
                    formData.append('upload_date', vm.meta.upload_date)
                    formData.append('project_activity_group_id', vm.meta.project_activity_group_id)
                    formData.append('project_section_group_id', vm.meta.project_section_group_id)
                    formData.append('project_section_id', vm.meta.project_section_id)
                    vm.$store
                        .dispatch("post", { uri: "updateProjectImage", data: formData })
                        .then(function () {
                            loader.hide()
                            vm.updatePrImages(pr_image)
                            vm.meta.checked_images = []
                            vm.errors = []
                            vm.getProjectImages()
                            vm.$store.dispatch("success", 'Images uploaded successfully');
                        })
                        .catch(function (error) {
                            loader.hide()
                            vm.errors = error.response.data.errors
                            vm.$store.dispatch("error", error.response.data.message);
                            
                        });
                }else{
                    vm.meta.checked_images = []
                    if(!vm.meta.upload_date){
                        vm.errors.upload_date = ['Upload date field is required']
                    }else{
                        delete vm.errors.upload_date
                    }
                    if(!vm.meta.project_activity_group_id){
                        vm.errors.project_activity_group_id = ['Project activity group id filed is required']
                    }else{
                        delete vm.errors.project_activity_group_id
                    }
                    if(!vm.meta.project_section_group_id){
                        vm.errors.project_section_group_id = ['Project section group id filed is required']
                    }else{
                        delete vm.errors.project_section_group_id
                    }
                    if(!vm.meta.project_section_id){
                        vm.errors.project_section_id = ['Project section id filed is required']
                    }else{
                        delete vm.errors.project_section_id
                    }
                }   
            },
            updateProjectImages(){
                let vm = this
                let loader = vm.$loading.show()
                const formData = new FormData()
                for (var i = 0; i < vm.meta.checked_images.length; i++ ){
                    formData.append('images[' + i + ']', vm.meta.checked_images[i].file)
                    formData.append('notes[' + i + ']', vm.meta.checked_images[i].note)
                }
                formData.append('project_id', this.project.project_id)
                formData.append('upload_date', vm.meta.upload_date)
                formData.append('project_activity_group_id', vm.meta.project_activity_group_id)
                formData.append('project_section_group_id', vm.meta.project_section_group_id)
                formData.append('project_section_id', vm.meta.project_section_id)
                formData.append('delete_images', vm.meta.delete_images)
                vm.$store
                    .dispatch("post", { uri: "updateProjectImages", data: formData })
                    .then(function () {
                        loader.hide()
                        vm.updatePrImages()
                        vm.discard()
                        vm.getProjectImages()
                        vm.$store.dispatch("success", 'Images uploaded successfully');
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.errors
                        vm.$store.dispatch("error", error.response.data.message);
                        
                    });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.getProjectImages();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.getProjectImages();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.getProjectImages();
            },
        }
    }
</script>