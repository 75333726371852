<template>
	<div class="row g-3">
		<div class="col-sm-12">
			<div class="card">
				<div class="card-header">
					<div class="d-sm-flex align-items-center justify-content-between">
						<div>
							<!-- <h6 class="main-title mb-0">{{ title }} hhhh</h6> -->
							<strong> {{ title }}</strong>
						</div>
						<div class="d-flex align-items-center" v-if="$store.getters.user?.role?.role_code == 'HQAdmin'">
							<router-link to="/project/create" type="submit" class="btn btn-sm btn-primary me-2">
								<i class="ri-add-box-line fs-20 lh-1 icon_hgt"></i> New Project
							</router-link>
							<button v-if="inactive" type="button" class="btn btn-sm btn-primary"
								@click="inactiveprojects()">Inactive Projects</button>
							<button v-else type="button" class="btn btn-sm btn-primary" @click="index()">Active
								Projects</button>
						</div>
						<!-- <strong style="font-size:medium;">Users</strong>				
                        <router-link to="/users/create" type="submit" class="btn btn-sm mb-2 btn-primary" style="float:right;"><i class="ri-add-line"></i>
                        ADD USER</router-link>
                        <button type="button" class="btn btn-sm mb-2 btn-primary mr-10"  style="float:right;">Inactive User</button> -->
					</div>
					<!-- <h6 class="card-title">Existing Projects</h6> -->
					<!-- <strong style="font-size:medium;"> Existing Projects</strong>
                    <router-link to="/project/create" type="submit" class="btn btn-sm mb-2 btn-primary"
                        style="float:right;">
                        <i class="ri-add-box-line fs-20 lh-1 icon_hgt"></i> New Project
                    </router-link> -->
				</div>

				<!-- <div class="card-header">
                <strong style="font-size:medium;"> PMT Master Templates</strong>
                <router-link v-can="'templates.create'" to="/templates/create" type="submit" class="btn btn-sm mb-2 btn-primary" style="float:right;"><i class="ri-add-line"></i> Add PMT Master Template</router-link>
            </div> -->
				<div class="card-body">
					<div class="form-group mb-2">
						<div class="row">
							<div class="col-sm-3">
								<label class="form-label">Project Type</label>
								<select class="form-control form-control-sm"
									:class="{ 'is-invalid': errors.project_type_id }" v-model="meta.project_type_id"
									@change="search()">
									<option value="">Select Project Type </option>
									<option v-for="(project_type, key) in project_types" :key="key"
										:value="project_type.project_type_id">{{ project_type.project_type_name }}
									</option>
								</select>
								<span v-if="errors.project_type_id" class="invalid-feedback">{{
									errors.project_type_id[0]
								}}</span>
							</div>
							<div class="col-sm-3">
								<label class="form-label">Project Category</label>
								<select class="form-control form-control-sm"
									:class="{ 'is-invalid': errors.project_category_id }"
									v-model="meta.project_category_id" @change="search()">
									<option value="">Select Project Category</option>
									<option v-for="(project_category, key) in project_categories" :key="key"
										:value="project_category.project_category_id">{{
											project_category.project_category_name }}</option>
								</select>
								<span v-if="errors.project_category_id" class="invalid-feedback">{{
									errors.project_category_id[0] }}</span>
							</div>
							<div class="col-sm-3 ">
								<label class="form-label">Project Group</label>
								<select class="form-control form-control-sm"
									:class="{ 'is-invalid': errors.project_group_id }" v-model="meta.project_group_id"
									@change="search()">
									<option value="">Select Project Group </option>
									<option v-for="(project_group, key) in project_groups" :key="key"
										:value="project_group.project_group_id">{{ project_group.project_group_name }}
									</option>
								</select>
								<span v-if="errors.project_group_id" class="invalid-feedback">{{
									errors.project_group_id[0]
								}}</span>
							</div>
							<div class="col-sm-3">
								<label class="form-label">Search</label>
								<input class="form-control form-control-sm" type="text"
									placeholder="Type keyword and press enter key" v-model="meta.search"
									@keypress.enter="search()" />
							</div>
						</div>
					</div>
					<div class="table-responsive table-responsive-sm" v-if="inactive">
						<table class="table table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th class="text-center">#</th>
									<th @click="sort('project_code')">
										Project Code
										<span>
											<i v-if="meta.keyword == 'project_code' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_code' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('project_id')">
										Project Name
										<span>
											<i v-if="meta.keyword == 'project_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('plant_id')">
										Plant Name
										<span>
											<i v-if="meta.keyword == 'plant_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'plant_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('project_type_id')">
										Project Type
										<span>
											<i v-if="meta.keyword == 'project_type_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_type_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('project_category_id')">
										Project Catogry
										<span>
											<i v-if="meta.keyword == 'project_category_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_category_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('project_group_id')">
										Project Group
										<span>
											<i v-if="meta.keyword == 'project_group_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_group_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th class="text-center" v-can="'project_templates.scope'" width="5%">
										Weightage
									</th>
									<th class="text-center" v-can="'projects.update'">Edit</th>
									<th class="text-center">Template</th>
									<th class="text-center">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td v-if="!projects.length" class="text-center" colspan="10">No records found</td>
								</tr>
								<tr v-for="project, key in projects" :key="key">
									<td class="text-center">{{ meta.from + key }}</td>
									<td>
										{{ project.project_code }}
									</td>
									<td>
										{{ project.project_name }}
									</td>
									<td>{{ project?.plant?.plant_name }}</td>
									<td>{{ project?.project_type?.project_type_name }}</td>
									<td>{{ project?.project_category?.project_category_name }}</td>
									<td>{{ project?.project_group?.project_group_name }}</td>
									<td v-can="'project_templates.scope'">
										<input type="text" class="form-control" v-model="project.project_weightage"
											@blur="updateProjectWeightage(project)">
									</td>
									<td v-can="'projects.update'">
										<div class="d-flex justify-content-center gap-1">
											<a class="btn btn-secondary btn-icon dropdown-link"
												@click="editProject(project)">
												<i class="ri-pencil-line icon_hgt"></i>
											</a>
										</div>
									</td>
									<td>
										<div v-if="disableTemplateButton()"
											class="dropdown-file d-flex justify-content-center gap-1">
											<a href="#" class="btn btn-warning btn-icon dropdown-link"
												data-bs-toggle="dropdown" aria-expanded="false">
												<i class="ri-more-2-fill icon_hgt"></i>
											</a>
											<div class="dropdown-menu dropdown-menu-end">
												<a href="#" v-can="'project_templates.update'"
													class="dropdown-item edit" @click.prevent="editPMT(project)">
													Update PMT Master Templates
												</a>
												<a href="#" v-can="'project_costs.update'" class="dropdown-item delete"
													@click.prevent="editBATS(project)">
													Upload BATS Master Templates
												</a>
												<a href="#" v-can="'report_line_item.view'" class="dropdown-item delete"
													@click.prevent="updateBATS(project)">
													Update Report Line Items
												</a>
											</div>
										</div>
									</td>
									<td class="d-flex justify-content-center gap-1">
										<a class="btn btn-primary btn-icon" v-can="'pmt_user_forms.view'" href="#"
											@click.prevent="showPMTProject(project)" title="PMT">
											<i class="ri-building-line icon_hgt"></i>
										</a>
										<a class="btn btn-info btn-icon" v-can="'project_templates.scope'" href="#"
											@click.prevent="showProjectScope(project)" title="Scope">
											<i class="ri-file-text-line icon_hgt"></i>
										</a>
										<a class="btn btn-dark btn-icon" v-can="'budget_requests.create'" href="#"
											@click.prevent="newBudgetRequest(project)" title="BATS">
											<i class="ri-refund-2-line icon_hgt"></i>
										</a>
										<a class="btn btn-warning btn-icon" v-can="'dashboard.report'" href="#"
											@click.prevent="uploadSapDump(project)" title="Upload SAP Dump">
											<i class="ri-todo-line icon_hgt"></i>
										</a>
										<!-- <a class="btn btn-success btn-icon" v-can="'budget_transfers.view'" href="#"
                                            @click.prevent="showTransferList(project)" title="Transfer">
                                            <i class="ri-swap-box-line icon_hgt"></i>
                                        </a> -->
										<a class="btn btn-success btn-icon" v-can="'pmt_user_forms.view'" href="#"
											@click.prevent="updateProjectStatus(project)" title="Active"
											v-if="$store.getters.user?.role?.role_code == 'HQAdmin'">
											<i class="ri-delete-bin-line icon_hgt"></i>
										</a>
										<a class="btn btn-primary btn-icon" v-can="'projects.assign'" href="#"
											@click.prevent="viewProject(project)" title="Assign Project">
											<i class="ri-user-line icon_hgt"></i>
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-else class="table-responsive table-responsive-sm">
						<table class="table table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th class="text-center">#</th>
									<th @click="sort('project_code')">
										Project Code
										<span>
											<i v-if="meta.keyword == 'project_code' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_code' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('project_id')">
										Project Name
										<span>
											<i v-if="meta.keyword == 'project_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('plant_id')">
										Plant Name
										<span>
											<i v-if="meta.keyword == 'plant_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'plant_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('project_type_id')">
										Project Type
										<span>
											<i v-if="meta.keyword == 'project_type_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_type_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('project_category_id')">
										Project Catogry
										<span>
											<i v-if="meta.keyword == 'project_category_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_category_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th @click="sort('project_group_id')">
										Project Group
										<span>
											<i v-if="meta.keyword == 'project_group_id' && meta.order_by == 'asc'"
												class="ri-arrow-up-line"></i>
											<i v-else-if="meta.keyword == 'project_group_id' && meta.order_by == 'desc'"
												class="ri-arrow-down-line"></i>
											<i v-else class="fas fa-sort"></i>
										</span>
									</th>
									<th>
										Weightage
									</th>
									<th class="text-center">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td v-if="!projects.length" class="text-center" colspan="10">No records found</td>
								</tr>
								<tr v-for="project, key in projects" :key="key">
									<td class="text-center">{{ meta.from + key }}</td>
									<td>{{ project.project_code }}</td>
									<td>{{ project.project_name }}</td>
									<td>{{ project?.plant?.plant_name }}</td>
									<td>{{ project?.project_type?.project_type_name }}</td>
									<td>{{ project?.project_category?.project_category_name }}</td>
									<td>{{ project?.project_group?.project_group_name }}</td>
									<td>
										<input type="text" class="form-control" :value="project.project_weightage"
											@blur="updateProjectWeightage(project)">
									</td>
									<td class="d-flex justify-content-center gap-1">
										<a class="btn btn-danger btn-icon" v-can="'pmt_user_forms.view'" href="#"
											@click.prevent="updateProjectStatus(project)" title="Active">
											<i class="ri-delete-bin-line icon_hgt"></i>
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card-footer">
					<div class="d-flex justify-content-between align-items-center">
						<select class="form-select from-select-sm width-75" v-model="meta.per_page"
							@change="onPerPageChange">
							<option>10</option>
							<option>15</option>
							<option>20</option>
							<option>25</option>
							<option>30</option>
						</select>
						<!-- <span>Showing {{ meta.from }} of {{ meta.totalRows }} entries</span> -->

						<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
							@pagechanged="onPageChange" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
	components: { Pagination },
	data() {
		return {
			meta: {
				search: '',
				order_by: "asc",
				keyword: "project_code",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				to: 1,
				maxPage: 1,
				trashed: false,
				type: 'pmt_paginate',
				project_type_id: "",
				project_category_id: "",
				project_group_id: "",
				project_id: "",
			},
			projects: [],
			project_types: [],
			project_categories: [],
			project_groups: [],
			selected_projects: [],
			errors: [],
			permissions: [],
			inactive: true,
			title: "Existing Projects"
		}
	},

	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.meta.project_group_id = to.query.project_group_id ? to.query.project_group_id : '';
			vm.meta.project_id = to.query.project_id ? to.query.project_id : '';
			vm.index();
			vm.getProjectTypes();
			vm.permissions = vm.$store.getters.permissions;
		});
	},
	methods: {
		index() {
			let vm = this;
			let loader = vm.$loading.show();
			let uri = { uri: "paginateProjects", data: vm.meta };
			vm.$store
				.dispatch("post", uri)
				.then(function (response) {
					loader.hide()
					vm.projects = response.data.data;
					vm.inactive = true;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		updateProjectWeightage(project) {
			let vm = this
			if (project.project_weightage) {
				let uri = { uri: "updateProjectWeightage", data: project };
				vm.$store
					.dispatch("post", uri)
					.then(function () {
					})
					.catch(function (error) {
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},
		inactiveprojects() {
			let vm = this;
			let loader = vm.$loading.show();
			let uri = { uri: "paginateInActiveProjects", data: vm.meta };
			vm.$store
				.dispatch("post", uri)
				.then(function (response) {
					loader.hide()
					vm.projects = response.data.data;
					vm.inactive = false;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		onPageChange(page) {
			this.meta.page = page;
			this.index();
		},
		sort(field) {
			this.meta.keyword = field;
			this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
			this.index();
		},
		onPerPageChange() {
			this.meta.page = 1;
			this.index();
		},

		edit(project) {
			this.$router.push("/project_update/" + project.project_id + "/edit");
		},

		editPMT(project) {
			this.$router.push('/PMT/' + project.project_id + '/edit')
		},

		updateProjectStatus(project) {
			console.log("success")
			let vm = this;

			let loader = vm.$loading.show();
			vm.$store.dispatch("post", { uri: "updateProjectStatus", data: project })
				.then(function (response) {
					loader.hide();
					console.log("response", response)
					vm.$store.dispatch("success", response.data.message);
					vm.index();
					// vm.isAllSelected = false;
					// vm.selected_users = [];
					// vm.getExistingUsers();
					// vm.index();
				})
				.catch(function (error) {
					loader.hide();
					// vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		updateProjectTemplates(project) {
			let vm = this;
			let loader = vm.$loading.show();
			let uri = { uri: "updateProjectParameterProjectId", data: project };
			vm.$store
				.dispatch("post", uri)
				.then(function (response) {
					loader.hide()
					console.log(response)
				})
				.catch(function (error) {
					loader.hide();
					console.log(error)
				});
		},

		showPMTProject(project) {
			this.$router.push('/PMT/' + project.project_id + '/user')
		},

		newBudgetRequest(project) {
			this.$router.push('/BATS/' + project.project_id + '/user')
		},

		// newBudgetRequest(project) {
		// 	this.$router.push('/BATS/' + project.project_id + '/request/create')
		// },

		editBATS(project) {
			this.$router.push('/BATS/' + project.project_id + '/edit');
		},

		updateBATS(project) {
			this.$router.push('/BATS/' + project.project_id + '/report_templates');
		},

		showProjectScope(project) {
			this.$router.push('/PMT/' + project.project_id + '/scope')
		},

		showTransferList(project) {
			this.$router.push('/BATS/' + project.project_id + '/transfer_list')
		},

		uploadSapDump(project) {
			this.$router.push('/BATS/' + project.project_id + '/sap_dump')
		},

		showWithdrawList(project) {
			this.$router.push('/BATS/' + project.project_id + '/withdraw_list')
		},
		editProject(project) {
			this.$router.push("/project/" + project.project_id + "/edit");
		},
		viewProject(project) {
			this.$router.push("/project/" + project.project_id + "/view");
		},
		sort(field) {
			this.meta.keyword = field;
			this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
			this.index();
		},

		search() {
			let vm = this;
			vm.meta.page = 1;
			vm.index();
		},
		getProjectTypes() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getProjectTypes" })
				.then(function (response) {
					vm.project_types = response.data.data;
					vm.getProjectCategories();
				})
				.catch(function (error) {
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getProjectCategories() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getProjectCategories" })
				.then(function (response) {
					vm.project_categories = response.data.data;
					vm.getProjectGroups();
				})
				.catch(function (error) {
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getProjectGroups() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getProjectGroups" })
				.then(function (response) {
					vm.project_groups = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		disableTemplateButton() {
			let disable_template_button = this.permissions.filter(ele => {
				return (ele.ability.ability == 'project_templates.update' ||
					ele.ability.ability == 'project_costs.update' ||
					ele.ability.ability == 'report_line_item.view');
			})
			if (disable_template_button.length)
				return true;
			else
				return false;
		}
	},
};
</script>