<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">Projects</li>
			</ol>
			<h4 class="main-title mb-0">Project Budget</h4>
		</div>
		<div class="d-flex align-items-center gap-2 mt-3 mt-md-0" v-can="'budget_requests.view'">
			<router-link to="/projects" class="btn btn-secondary d-flex align-items-center gap-2">
				<i class="ri-arrow-left-s-line fs-18 lh-1"></i>Back
			</router-link>
			<a target="_blank" :href="$store.getters.apiUrl+'/storage/formats/sap_dump.xlsx'" class="btn btn-success d-flex align-items-center gap-2">
				<i class="ri-add-box-line fs-18 lh-1"></i> Download Format
			</a>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-sm-12">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">Upload SAP Dump</h6>
					<nav class="nav ms-auto" v-can="'project_costs.upload'">
						<div class="d-flex">
							<input type="file" class="form-control form-controls-sm mx-2" ref="attachment" @change="getAttachment($event)" />
							<button @click="uploadAttachment" class="btn btn-primary btn-sm me-2">
								Upload
							</button>
						</div>
					</nav>
				</div>
				<div class="card-body">
					<div class="row g-2 mb-1">
						<Project :project="project"/>
						<div class="col-md-12">
							<div class="form-group">
								<div class="row">
									<div class="col-sm-12">
										<input class="form-control" type="text" placeholder="Type keyword and press enter key" v-model="meta.search" @keypress.enter="search()"/>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th class="text-center">Level</th>
											<th>WBS Code</th>
											<th>WBS Description</th>
											<th class="text-center">UOM</th>
											<!-- <th class="text-center">
												Approved Budget
											</th>											
											<th class="text-center">
												Commitment in SAP
											</th> -->
											<th class="text-center">
												SAP Commit
											</th>
											<!-- <th class="text-center">
												Manual Commit
											</th> -->
											<th class="text-center">
												Actual Spent
											</th>
											<!-- <th class="text-center">
												Balance in Hand
											</th> -->
											<!-- <th class="text-center">
												Projected Commitment
											</th> -->
											<!-- <th class="text-center">
												Savings
											</th> -->
										</tr>
									</thead>
									<tbody>
										<tr v-for="project_cost, key in project.project_cost" :key="key">
											<td class="text-center">{{ meta.from + key }}</td>
											<td class="text-center">{{ project_cost.level }}</td>
											<td>{{ project_cost.wbs_code }}</td>
											<td>{{ project_cost.wbs_description }}</td>
											<td class="text-center">
												{{ project_cost.cost_type?.cost_type_code }}
											</td>
											<!-- <td class="text-center">
												{{ project_cost.budget }}
											</td>
											<td class="text-center">
												{{ project_cost.allocated_in_sap }}
											</td> -->
											<td>
												<input type="number" class="form-control form-control-sm" @blur="addSapCommit(project_cost)" placeholder="SAP Commit" v-model="project_cost.sap_commit">
											</td>											
											<!-- <td>
												<input type="number" class="form-control form-control-sm" @blur="addManualCommit(project_cost)" placeholder="Manual Commit" v-model="project_cost.manual_commit">
											</td> -->
											<td>
												<input type="number" class="form-control form-control-sm" @blur="addSpent(project_cost)" placeholder="Spent" v-model="project_cost.actual_spent">
											</td>
											<!-- <td class="text-center">
												{{ project_cost.balance_in_hand }}
											</td> -->
											<!-- <td>
												<input type="number" class="form-control form-control-sm" @blur="addProjectedCommitment(project_cost)" placeholder="Projected Commitment" v-model="project_cost.projected_commitment">
											</td> -->
											<!-- <td class="text-center">
												{{ project_cost.savings }}
											</td> -->
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Project from '@/components/Project'
import Pagination from "@/components/Pagination.vue";
export default {
	components: { Project, Pagination },
	data() {
		return {
			meta: {
				search: "",
				order_by: "asc",
				keyword: "project_cost_id",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				to: 1,
				maxPage: 1,
				trashed: false,
				project_id: ''
			},
			errors: [],
			error: {},
			project: {
				project_id: "",
				project_cost: [],
				last_stage: {}
			},
			project_cost: {},
			show_modal: 'modal fade',
			stages: [],
			next_stages: [],
			approvals: [],
			submit_disabled: false,
			project_requests: [],
			project_request_stages: [],
			project_request_stage: {},
			spents: [],
			commits:[],
			attachment:'',
		};
	},
	mounted() {
		// this.index();
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.project.project_id = to.params.project_id;
			vm.meta.project_id = to.params.project_id;
			vm.getProject();
			// vm.index();
			// vm.getStages();
		});
	},

	computed: {},

	methods: {
		index() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "paginateProjectCosts", data: vm.meta })
				.then(function (response) {
					console.log("paginateProjectCosts", response)
					vm.project.project_cost = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.to = response.data.meta.to;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
					// vm.prepareProjectData()
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		search() {
			let vm = this;
			vm.meta.page = 1;
			vm.index();
		},

		onPageChange(page) {
			this.meta.page = page;
			this.index();
		},

		onPerPageChange() {
			this.meta.page = 1;
			this.index();
		},

		addRequestApproval() {
			let vm = this;
			let data = {
				'project_id': this.project.project_id,
				'project_cost_id': this.project_cost.project_cost_id,
				'amount': this.project_cost.amount,
				'note': this.project_cost.note,
				'approvals': this.approvals
			}
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'addRequestApproval', data: data })
				.then(response => {
					loader.hide();
					vm.getProject();
					vm.getStages();
					vm.$store.dispatch('success', response.data.message);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				})
		},

		onFileSelect(e, stage, key) {
			let vm = this;
			var reader = new FileReader();
			if (!e.target.files[0]) {
				this.submit_disabled = true;
				stage.attachment = '';
				return;
			}
			reader.readAsDataURL(e.target.files[0]);
			reader.onload = function () {
				stage.attachment = reader.result;
				vm.setApprovals(stage, key);
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};
		},

		selected(stage) {
			if (!stage.selected) {
				this.submit_disabled = true;
			}
			else {
				this.submit_disabled = false;
			}
		},

		onChange(stage, key) {
			this.setApprovals(stage, key);
		},

		setApprovals(stage, key) {
			if (stage.approved_by == '' || stage.attachment == '') {
				this.submit_disabled = true;
				return;
			}
			else {
				this.submit_disabled = false;
				let a = {
					approved_by: stage.approved_by,
					attachment: stage.attachment
				};
				const obj = Object.assign({}, a);
				this.approvals[key] = obj;
			}
		},

		getStages() {
			let vm = this;
			this.$store.dispatch('post', { uri: 'getStages' })
				.then(response => {
					// this.stages = response.data.data;
					// this.getNextStages();

				})
				.catch(error => {
					// vm.errors = error.response.data.errors;
					// vm.$store.dispatch("error", error.response.data.message);
				})
		},

		getNextStages() {
			const role_id = this.$store.getters.user.role_id;
			let obj = this.stages.find(o => o.role.role_id == role_id);
			let stage = '';
			if (obj) {
				stage = obj.stage;
			}
			let next_stages = [];
			while (true) {
				stage++;
				const obj = this.stages.find(o => o.stage == stage);
				if (obj) {
					obj.approved_by = '';
					obj.attachment = '';
					next_stages.push(obj);
				}
				else {
					break;
				}
			}
			this.next_stages = next_stages;
		},

		request(project_cost) {
			this.project_cost = project_cost;
			this.$router.push('/BATS/' + this.project.project_id + '/' + project_cost.project_cost_id + '/request');
		},

		getProject() {
			let vm = this
			vm.project.type = "bats";
			let uri = { uri: "getProject", data: vm.project };
			vm.$store
				.dispatch("post", uri)
				.then(function (response) {
					vm.project = response.data.data;
					vm.index();
				})
				.catch(function (error) {
				});
		},
		// prepareProjectData() {
		//     this.project.project_cost.forEach(ele => {
		//         // ele.balance = 0;
		//         // if (ele.budget != null) {
		//         //     ele.balance = ele.budget - (ele.manual_commit_amount + ele.sap_commit_amount + ele.direct);
		//         // }
		//         // if (ele.total_spent) {
		//         //     ele.forcast = ele.balance - ele.total_spent;
		//         // }
		//         ele.forcast = ele.balance;
		//         ele.savings = ele.balance - ele.forcast;
		//         ele.project_code_partial = '';
		//         let project_code_arr = ele.project_code.split('-');
		//         for (let i = 7; i < project_code_arr.length; i++) {
		//             if (ele.project_code_partial == '') {
		//                 ele.project_code_partial = project_code_arr[i];
		//             }
		//             else {
		//                 ele.project_code_partial = ele.project_code_partial + '-' + project_code_arr[i];
		//             }
		//         }
		//     })
		// },
		createRequest() {
			let vm = this;
			let uri = { uri: "addProjectRequest", data: vm.project_cost };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					vm.getProject()
					vm.$store.dispatch("success", "Request submitted successfully");
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		showRequests() {
			this.$router.push('/project_cost/' + this.project.project_id + '/request')
		},

		addSpent(project_cost) {
			let vm = this;
			vm.$store.dispatch('post', { uri: 'addSpent', data:project_cost })
		},

		addManualCommit(project_cost) {
			let vm = this;
			vm.$store.dispatch('post', { uri: 'addManualCommit', data:project_cost })
			.then(response => {
				vm.index();
			})
		},

		addSapCommit(project_cost) {
			let vm = this;
			vm.$store.dispatch('post', { uri: 'addSapCommit', data:project_cost })
			.then(response => {
				vm.index();
			})
		},

		addProjectedCommitment(project_cost) {
			let vm = this;
			vm.$store.dispatch('post', { uri: 'addProjectedCommitment', data:project_cost })
		},

		getSpents(project_cost) {
			let vm = this;
			this.$store.dispatch('post', { uri: 'getSpents', data: { project_cost_id: project_cost.project_cost_id } })
				.then(response => {
					this.spents = response.data.data;
					document.getElementById('spent_total').innerHTML = project_cost.total_spent;
				})
				.catch(error => {
					vm.error = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		showStatus(project_cost) {
			// console.log(project_cost);
			if (project_cost.new_request_amount != 0) {
				return 'red_dot';
			}
			if (project_cost.manual_commit_amount != 0) {
				return 'orange_dot';
			}
			if (project_cost.sap_commit_amount != 0) {
				return 'green_dot';
			}
			return 'gray_dot';
		},

		setManualCommit() {
			if (!confirm('Do you realy want to proceed with commit?')) {
				project_cost.manual_commit = false;
				return;
			}
			let project_request_ids = [];
			let manual_commits = this.project.project_cost.filter(ele => {
				return ele.manual_commit == true;
			});

			manual_commits.forEach(ele => {
				ele.project_request.forEach(pr => {
					if (pr.status == 'Approved' || pr.status == 'Direct') {
						project_request_ids.push(pr.project_request_id);
					}
				})
			})

			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'commitRequest', data: { project_request_ids: project_request_ids } })
				.then(response => {
					loader.hide();
					this.$store.dispatch('success', response.data.message);
					vm.getProject();
				})
				.catch(error => {
					loader.hide();
					vm.error = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getAttachment(e){
			let vm = this;
			vm.attachment= e.target.files[0]
		},

		uploadAttachment() {
			let vm = this
			let loader = vm.$loading.show()
			const formData = new FormData()
			formData.append('user_id', vm.$store.getters.user.user_id)
			formData.append('project_id', vm.project.project_id)
			formData.append('attachment', vm.attachment)
			vm.$store.dispatch("post", { uri: "uploadSapDump", data: formData })
			.then(function () {
				loader.hide();
				vm.$store.dispatch("success", "SAP Dump has been uploaded successfully");
				vm.$refs.attachment.value = null;
				vm.index();
			})
			.catch(function (error) {
				loader.hide();
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},
	},
};
</script>