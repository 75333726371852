<template>
    <div class="card">
        <div class="card-header">
            <strong>{{ project?.project_name }}-{{ name }}</strong>
            <div style="float: right;" class="d-flex display-content">
                <input type="file" class="form-control form-controls-sm me-2 mb-1" style="display: block; width: 100%;" />
                <!-- <button class="btn btn-primary btn-sm me-2">Upload</button>
                <button class="btn btn-primary me-2 btn-sm" @click="downloadPMTForm()">Download</button> -->
                <button class="btn btn-warning btn-sm" @click="expand()" v-if="activity_group_div"><i class="ri-fullscreen-fill size"></i></button>
            </div>
        </div>
        <div class="card-body">
            <div class="d-md-flex align-items-center justify-content-between">
                <div>
                    <h5 class="mb-1"><strong>{{ name }}</strong></h5>
                </div>
                <div class="d-flex mb-1">
                    <div class="d-flex">
                        <input type="date" class="form-control form-control-sm me-2" :min="min_date" :max="max_date" v-model="project.job_date_time" :class="{'is-invalid': errors.job_date_time}" />
                        <span v-if="errors.job_date_time" class="invalid-feedback">{{ errors.job_date_time[0] }}</span>
                    </div>

                    <button type="button" class="btn btn-sm btn-primary d-flex align-items-center" @click="getProjectActivities_component($store.getters.item.name)" v-if="activity_group_div && is_get_button">Get</button>
                </div>
            </div>
            <div class="table-responsive p-0 mb-3">
                <table class="table text-nowrap table-responsive-sm table-bordered table-sm">
                    <tbody>
                        <tr>
                            <td class="sticky-col td4" colspan="3"></td>
                            <td
                                class="text-center"
                                v-for="project_parameter_type, project_parameter_type_key in getSectionProjectParameterTypes()"
                                :key="project_parameter_type_key"
                                :colspan="project_parameter_type.project_parameters.length"
                            >
                                {{ project_parameter_type.parameter_type.parameter_type_name }}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center sticky-col td1">Section</td>
                            <td class="text-center sticky-col td2">Unit</td>
                            <td class="text-center sticky-col td3">Scope</td>
                            <td v-for="project_parameter, project_parameter_key in getSectionProjectParameters()" :key="project_parameter_key" class="text-center">
                                {{ project_parameter.parameter.parameter_name }}
                            </td>
                        </tr>
                        <tr v-for="project_activity, project_activity_key in project.project_activities" :key="project_activity_key">
                            <td class="sticky-col td1">{{ project_activity.activity.activity_name }}</td>
                            <td class="sticky-col td2">
                                {{ project_activity.unit?.unit_name }}
                                <!-- <input type="text" class="form-control" placeholder="Unit" /> -->
                            </td>

                            <td class="sticky-col td3">
                                {{ project_activity.scope }}
                                <!-- <input type="text" class="form-control" v-model="project_activity.scope" placeholder="Scope" /> -->
                            </td>

                            <td v-for="project_parameter, project_parameter_key in project_activity.project_parameters" :key="project_parameter_key">
                                <div v-if="project_parameter.project_parameter_type?.parameter_type?.parameter_type_code=='Months'">
                                    <div v-if="project_parameter.job_parameter">
                                        <input
                                            v-if="project_parameter.parameter.field_type=='text'"
                                            type="text"
                                            class="form-control form-control1"
                                            v-model="project_parameter.job_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                            @keydown.enter="focusNext(pa_key, project_parameter_key)"
                                            ref="myInputs"
                                        />
                                        <input
                                            v-if="project_parameter.parameter.field_type=='number'"
                                            type="text"
                                            class="form-control form-control1"
                                            v-model="project_parameter.job_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                            :class="{ 'is-invalid': project_parameter?.errors?.parameter_value }"
                                            @keyup="isNumber(project_parameter)"
                                        />
                                        <span v-if="project_parameter?.errors?.parameter_value"><small class="text-danger">{{ project_parameter?.errors?.parameter_value[0] }}</small></span>
                                        <input
                                            v-if="project_parameter.parameter.field_type=='date'"
                                            type="date"
                                            class="form-control form-control1"
                                            v-model="project_parameter.job_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                        />
                                        <input
                                            v-if="project_parameter.parameter.field_type=='date_time'"
                                            type="datetime-local"
                                            class="form-control form-control1"
                                            v-model="project_parameter.job_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                        />
                                        <select v-if="project_parameter.parameter.field_type=='dropdown'" class="form-control form-control1 ee" v-model="project_parameter.job_parameter.parameter_value">
                                            <option value="">Select</option>
                                            <option v-for="value, key in project_parameter.parameter.field_values.split(',')" :value="value.replace(/\s/g, '')" :key="key">{{value}}</option>
                                        </select>
                                        <!-- <span v-if="project_parameter.parameter.field_type=='dropdown'">{{ project_parameter.job_parameter.parameter_value }}</span> -->
                                    </div>
                                    <div v-else>
                                        <input
                                            v-if="project_parameter.parameter.field_type=='text'"
                                            type="text"
                                            class="form-control form-control1"
                                            v-model="project_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                            @keydown.enter="focusNext(project_activity_key, project_parameter_key)"
                                            ref="myInputs"
                                        />
                                        <input
                                            v-if="project_parameter.parameter.field_type=='number'"
                                            type="text"
                                            class="form-control form-control1"
                                            v-model="project_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                            :class="{ 'is-invalid': project_parameter?.errors?.parameter_value }"
                                            @keyup="isNumber(project_parameter)"
                                        />
                                        <span v-if="project_parameter?.errors?.parameter_value"><small class="text-danger">{{ project_parameter?.errors?.parameter_value[0] }}</small></span>
                                        <input
                                            v-if="project_parameter.parameter.field_type=='date'"
                                            type="date"
                                            class="form-control form-control1"
                                            v-model="project_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                        />
                                        <input
                                            v-if="project_parameter.parameter.field_type=='date_time'"
                                            type="datetime-local"
                                            class="form-control form-control1"
                                            v-model="project_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                        />
                                        <select v-if="project_parameter.parameter.field_type=='dropdown'" class="form-control form-control1 ff" v-model="project_parameter.parameter_value">
                                            <option value="">Select</option>
                                            <option v-for="value, key in project_parameter.parameter.field_values.split(',')" :value="value.replace(/\s/g, '')" :key="key">{{value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="project_parameter.job_parameter">
                                        <input
                                            v-if="project_parameter.parameter.field_type=='text'"
                                            type="text"
                                            size="4"
                                            class="form-control form-control2"
                                            v-model="project_parameter.job_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                            @keydown.enter="focusNext(pa_key, project_parameter_key)"
                                            ref="myInputs"
                                        />
                                        <input
                                            v-if="project_parameter.parameter.field_type=='number'"
                                            type="text"
                                            size="4"
                                            class="form-control form-control2"
                                            v-model="project_parameter.job_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                            :class="{ 'is-invalid': project_parameter?.errors?.parameter_value }"
                                            @keyup="isNumber(project_parameter)"
                                        />
                                        <span v-if="project_parameter?.errors?.parameter_value"><small class="text-danger">{{ project_parameter?.errors?.parameter_value[0] }}</small></span>
                                        <input
                                            v-if="project_parameter.parameter.field_type=='date'"
                                            type="date"
                                            class="form-control"
                                            v-model="project_parameter.job_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                        />
                                        <input
                                            v-if="project_parameter.parameter.field_type=='date_time'"
                                            type="datetime-local"
                                            class="form-control"
                                            v-model="project_parameter.job_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                        />
                                        <select v-if="project_parameter.parameter.field_type=='dropdown'" class="form-control form-control2" v-model="project_parameter.job_parameter.parameter_value">
                                            <option value="">Select</option>
                                            <option v-for="value, key in project_parameter.parameter.field_values.split(',')" :value="value.replace(/\s/g, '')" :key="key">{{value}}</option>
                                        </select>
                                        <!-- <span v-if="project_parameter.parameter.field_type=='dropdown'">{{ project_parameter.job_parameter.parameter_value }}</span> -->
                                    </div>
                                    <div v-else>
                                        <input
                                            v-if="project_parameter.parameter.field_type=='text'"
                                            type="text"
                                            size="4"
                                            class="form-control form-control2"
                                            v-model="project_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                            @keydown.enter="focusNext(project_activity_key, project_parameter_key)"
                                            ref="myInputs"
                                        />
                                        <input
                                            v-if="project_parameter.parameter.field_type=='number'"
                                            type="text"
                                            size="4"
                                            class="form-control form-control2"
                                            v-model="project_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                            :class="{ 'is-invalid': project_parameter?.errors?.parameter_value }"
                                            @keyup="isNumber(project_parameter)"
                                        />
                                        <span v-if="project_parameter?.errors?.parameter_value"><small class="text-danger">{{ project_parameter?.errors?.parameter_value[0] }}</small></span>
                                        <input v-if="project_parameter.parameter.field_type=='date'" type="date" class="form-control" v-model="project_parameter.parameter_value" :placeholder="project_parameter.parameter.parameter_name" />
                                        <input
                                            v-if="project_parameter.parameter.field_type=='date_time'"
                                            type="datetime-local"
                                            class="form-control form-control2"
                                            v-model="project_parameter.parameter_value"
                                            :placeholder="project_parameter.parameter.parameter_name"
                                        />
                                        <select v-if="project_parameter.parameter.field_type=='dropdown'" class="form-control form-control2" v-model="project_parameter.parameter_value">
                                            <option value="">Select</option>
                                            <option v-for="value, key in project_parameter.parameter.field_values.split(',')" :value="value.replace(/\s/g, '')" :key="key">{{value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer text-end">
            <!-- {{ showSection +' / '+ $store.getters.job_status }} -->
            <button class="btn btn-danger btn-sm me-2" @click.prevent="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
            <button class="btn btn-primary btn-sm" @click="updateJobSection()" v-if="$store.getters.job_status"><i class="ri-save-line icon_hgt"></i> UPDATE</button>
            <button class="btn btn-primary btn-sm" @click="addJobSection()" v-if="!$store.getters.job_status"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
    export default {
        name: "ProjectActivities",
        props: {
            is_get_button: {
                default: true,
            },
            activity_group_div: {
                default: true,
            },
            project: Object,
            item: Object,
        },
        watch: {
            project: {
                handler() {
                    this.loadData();
                },
                deep: true,
            },
        },
        data() {
            return {
                section: {
                    project_section_id: [],
                    is_manual: true,
                    job_date_time: "",
                    project_activity_group_id: "",
                },
                name: "",
                col1: "col-md-3",
                col2: "col-md-9",
                project_activity_group: {
                    project_id: "",
                    is_manual: true,
                    project_activity_group_id: "",
                    job_date_time: "",
                    is_request_date_time: false,
                },
                activity_group: {},

                project_section: {
                    project_id: "",
                    is_manual: true,
                    project_activity_group_id: "",
                    job_date_time: "",
                    is_request_date_time: false,
                    project_activities: [],
                },
                errors: [],
                showSection: false,
            };
        },
        methods: {
            isNumber(project_parameter) {
                project_parameter.errors = [];
                if (project_parameter.parameter.field_type == "number") {
                    let reg = /^[0-9]\d{0,9}(\.\d{1,5})?%?$/;
                    let parameter_value = "";
                    if (project_parameter.job_parameter) {
                        parameter_value = project_parameter.job_parameter.parameter_value;
                    } else {
                        parameter_value = project_parameter.parameter_value;
                    }
                    if (!reg.test(parameter_value)) {
                        if (parameter_value != "") {
                            project_parameter.errors.parameter_value = ["Enter the valid number"];
                            let ag = this.errors.filter(function (element) {
                                return element.project_parameter_id == project_parameter.project_parameter_id;
                            });
                            if (!ag.length) {
                                this.errors.push({ project_parameter_id: project_parameter.project_parameter_id });
                            }
                        } else {
                            let ag = this.errors.filter(function (element) {
                                return element.project_parameter_id == project_parameter.project_parameter_id;
                            });
                            if (ag.length) {
                                this.errors.splice(this.errors.indexOf(ag[0]), 1);
                            }
                        }
                    } else {
                        let ag = this.errors.filter(function (element) {
                            return element.project_parameter_id == project_parameter.project_parameter_id;
                        });
                        if (ag.length) {
                            this.errors.splice(this.errors.indexOf(ag[0]), 1);
                        }
                    }
                }
            },
            loadData() {
                this.section.project_section_id = [];
                this.section.project_section_id.push(this.item.id);
                this.name = this.$store.getters.item.name
                this.max_date = moment().format('yyyy-MM-DD')
                if(this.$store.getters.user?.role?.role_code!='HQAdmin'){
                    this.min_date = moment().subtract(1, 'days').format('yyyy-MM-DD')
                }
            },
            focusNext(project_activity_key, project_parameter_key) {
                console.log(project_parameter_key);
                console.log(this.$refs.myInputs);
                this.$refs.myInputs[project_parameter_key + 1].focus();
                // if(this.job.job_parameters.length == key+1){
                //     this.$refs.add_job.focus();
                // }else{
                //     this.$refs.myInputs[key+1].focus();
                // }
            },
            getSectionProjectParameterTypes() {
                if (this.project.project_activities?.length) {
                    return this.project.project_activities[0].project_parameter_types;
                }
            },
            getSectionProjectParameters() {
                if (this.project.project_activities?.length) {
                    return this.project.project_activities[0].project_parameters;
                }
            },
            getProjectActivities_component(item_name) {
                console.log("item", item_name);
                let vm = this;
                let loader = vm.$loading.show();

                vm.section.job_date_time = vm.project.job_date_time;
                vm.section.is_request_date_time = true;
                if (vm.section.job_date_time) {
                    vm.status = true;
                }
                let uri = { uri: "getProjectSectionActivities", data: vm.section };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        console.log("getProjectActivities_component", response);
                        loader.hide();
                        vm.activity_group = response.data;
                        vm.project.project_activities = response.data.activities;
                        // vm.show = true;
                        vm.name = item_name;
                        // vm.is_get_button = response.data.is_get_button
                        // vm.status = response.data.status

                        vm.project_section.project_id = response.data.project_activity_group.project_id;
                        vm.project_section.project_activity_group_id = response.data.project_activity_group.project_activity_group_id;
                        vm.$store.dispatch("setJobStatus", response.data.status);
                        // if(response.data.job){
                        //     vm.project.job_date_time = moment(response.data.job.job_date_time).format('yyyy-MM-DD')
                        // }else{
                        //     vm.project.job_date_time = moment().format('yyyy-MM-DD')
                        // }
                    })
                    .catch(function (error) {
                        loader.hide();
                    });
            },

            updateJobSection() {
                let vm = this;
                console.log("update project:----", this.project);
                vm.project_section.project_activities = this.project.project_activities;
                vm.project_section.job_date_time = this.project.job_date_time;
                vm.project_section.project_id = this.project.project_id;
                vm.project_section.project_activity_group_id = this.project.project_activity_group_id;
                let loader = vm.$loading.show();
                let uri = { uri: "updateJobSection", data: vm.project_section };
                if (vm.errors.length == 0) {
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Updated successfully");
                        })
                        .catch(function (error) {
                            loader.hide();
                            console.log(error);
                        });
                } else {
                    loader.hide();
                    vm.$store.dispatch("error", "Invalid input data");
                }
            },
            addJobSection() {
                let vm = this;
                let loader = vm.$loading.show();
                this.project_section.project_id = this.project.project_id;
                this.project_section.project_activity_group_id = this.project.project_activity_group_id;
                this.project_section.project_activities = this.project.project_activities;
                this.project_section.job_date_time = this.project.job_date_time;
                let uri = { uri: "addJobSection", data: vm.project_section };
                if (vm.errors.length == 0) {
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            loader.hide();
                            vm.status = true;
                            vm.$store.dispatch("setJobStatus", vm.status);
                            vm.$store.dispatch("success", "Added successfully");
                            vm.getProjectActivities_component(vm.$store.getters.item.name);
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                } else {
                    loader.hide();
                    vm.$store.dispatch("error", "Invalid input data");
                }
            },
            discard() {
                let vm = this;
                vm.getProjectActivities_component();
                vm.errors = [];
            },
        },
    };
</script>
<style scoped>
    .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        z-index: 0;
        background-color: #fff !important;
        background-attachment: fixed;
    }

    .td1 {
        width: 100%;
        min-width: 200px;
        max-width: 200px;
        left: -1px;
        z-index: 1;
        background-color: #fff !important;
        text-wrap: wrap !important;
    }

    .td2 {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        left: 198px;
        z-index: 1;
        background-color: #fff !important ;
        background-attachment: fixed;
    }

    .td3 {
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        left: 267px;
        z-index: 1;
        background-color: #fff !important;
        background-attachment: fixed;
    }
    .td3-1 {
        width: 100px;
        min-width: 80px;
        max-width: 80px;
        left: 267px;
        z-index: 1;
        background-color: #fff !important;
        background-attachment: fixed;
    }

    .td4 {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        left: 0px;
    }
    .justify-content-space-between {
        justify-content: space-between;
    }

    /* .form-control {
        width: -webkit-fill-available;
    } */
    .form-control1 {
        /* width: 100% !important; */
        width: 4rem;
        display: block !important;
    }
    .form-control2 {
        box-sizing: border-box;
        width: -webkit-fill-available;
        display: block;
    }

    .table-bordered > :not(caption) > * > * {
        border-width: 0 1px !important;
    }

    @media only screen and (max-width: 496px) {
        .display-content {
            display: contents !important;
        }
    }
</style>
