import { createRouter, createWebHashHistory } from "vue-router";
import store from '@/store/index.js';
import Login from "@/views/auth/Login.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
// import Dashboard from "@/views/Dashboard.vue";
import Profile from "@/views/auth/Profile.vue";
import TMDashboard from "@/views/TMDashboard.vue";

//dashboard
import ProjectDashboard from "@/views/dashboards/Dashboard1.vue";
import ProjectDashboard1 from "@/views/dashboards/Dashboard3.vue";
import AdminDashboard from "@/views/dashboards/Dashboard2.vue";
// import BatsDashboard from "@/views/dashboards/BatsDashboard.vue";
import DashboardReport from "@/views/dashboards/DashboardReport.vue";
import TopManagement from "@/views/dashboards/TopManagement.vue";
// import Averages from "@/views/dashboards/Averages.vue";
import BATS from "@/views/dashboards/BATS.vue";
import CFD from "@/views/dashboards/CFD.vue";
import PR from "@/views/dashboards/PR.vue";
import BATSReport from "@/views/dashboards/BATSReport.vue";
import BATSLineReport from "@/views/dashboards/BATSLineReport.vue";
import BATSSummaryReport from "@/views/dashboards/BATSSummaryReport.vue";
import BATSDetailReport from "@/views/dashboards/BATSDetailReport.vue";


// template
import CreateTemplate from "@/views/template/Create.vue";
import Templates from "@/views/template/Index.vue";
import TemplateUpdate from "@/views/template/Update.vue";
import CreateTemplateMultiple from "@/views/template/CreateMultiple.vue";

// template master cost
import CreateTemplateMasterCost from "@/views/template_master_cost/Create.vue";
import TemplateMasterCosts from "@/views/template_master_cost/Index.vue";

// BatsReportTemplates
import BatsReportTemplates from "@/views/report_templates/Index.vue";
import CreateBatsReportTemplates from "@/views/report_templates/Create.vue";
import LinkBatsReportTemplates from "@/views/report_templates/View.vue";

// users management
// role group
import RoleGroups from "@/views/userManagement/RoleGroup.vue";
// role
import Roles from "@/views/userManagement/Role.vue";
//Functional Roles
import FunctionalRole from "@/views/userManagement/FunctionalRole.vue";
// users
import Users from "@/views/userManagement/user/Index.vue";
import CreateUser from "@/views/userManagement/user/Create.vue";
import UserProjects from "@/views/userManagement/user/UserProjects.vue";
//Permissions
import Permission from "@/views/userManagement/Permission.vue";
// new configuration
import Configurations from "@/views/Configuration.vue";
// Escalation Roles
import EscalationRole from "@/views/userManagement/EscalationRole.vue";

// projectCost
import ProjectCost from "@/views/project_cost/Index.vue";
import CreateProjectCost from "@/views/project_cost/Create.vue";
import TransferVue from "@/views/project_cost/Transfer.vue";
import TansferListVue from "@/views/project_cost/TransferList.vue";
import WithdrawListVue from "@/views/project_cost/WithdrawList.vue";
import WithdrawalVue from "@/views/project_cost/Withdrawal.vue";
import AddProjectCost from "@/views/project_cost/AddProjectCost.vue";

//project
import CreateProject from "@/views/project/Create";
import EditPMT from "@/views/project/EditPMT";
import EditBATS from "@/views/project/EditBATS";
import Scope from "@/views/project/Scope";
import ProjectUser from "@/views/project/ProjectUser.vue";
import InactiveProjects from "@/views/project/InactiveProjects.vue";
import sortProjectActivityGroups from "@/views/project/SortProjectActivityGroups.vue";

// import Projects from "@/views/project/Projects.vue";
import Projects from "@/views/project/Index.vue";
import PMTProject from "@/views/project/PMTProject.vue";
import PMTUser from "@/views/project/PMTProject.vue";
import ProjectBudgetRequest from "@/views/project_cost/ProjectBudgetRequest.vue"
import newProjectBudgetRequest from "@/views/project_cost/newProjectBudgetRequest.vue"
import UpdateBudgetRequest from "@/views/project_cost/UpdateBudgetRequest.vue"
import Transfer from "@/views/project/Transfer";

//Project Management
import ProjectCreate from "@/views/project/Create.vue";
import ProjectUpdate from "@/views/project/Update.vue";
import EditProjectCost from "@/views/project_cost/EditProjectCost.vue";
import ProjectCostUser from "@/views/project_cost/ProjectCostUser"
import uploadSapDump from "@/views/project_cost/uploadSapDump"
import ProjectRequests from "@/views/project_cost/ProjectRequests"
import PendingRequests from "@/views/project_cost/PendingRequests.vue";
import ActivityGroups from "@/views/project/ActivityGroupsModel.vue";

import ApprovalSettings from "@/views/userManagement/ApprovalSetting.vue";
import EscalationSetting from "@/views/userManagement/EscalationSetting.vue";

//charts
import PMTCharts from "@/views/charts/Pmtchart";

//reports
import OverallEngineering from "@/views/reports/OverallEngineering";
import DailyProgress from "@/views/reports/DailyProgress";
import PlanParameters from "@/views/PlanParameters";
import Hindrance from "@/views/reports/Hindrance.vue";
import DprTemplate from "@/views/reports/DprTemplate";
import DPRProgress from "@/views/reports/DPRProgress.vue";
import EditDprTemplate from "@/views/reports/EditDprTemplate";
import DPRHoManpower from "@/views/reports/dpr_manpower/DPRHoManpower";
import DPRManpowerTemplate from "@/views/reports/dpr_manpower/DPRManpowerTemplate";
import ProgramReport from "@/views/reports/ProgramReport";
import MechanicalSummary from "@/views/reports/MechanicalSummary";
import CivilSummary from "@/views/reports/CivilSummary";
import WHRS from "@/views/reports/WHRS";
import Refractory from "@/views/reports/Refractory";
import Slippage from "@/views/reports/Slippage";

// import CreateOrderingScheduleTemplate from "@/views/reports/OrderingScheduleTemplate/Create";
// import OrderingScheduleTemplates from "@/views/reports/OrderingScheduleTemplate/Index";

import OverallSchedule from "@/views/reports/OverallSchedule.vue";
import OrderingScheduleTemplate from "@/views/reports/OrderingScheduleTemplate.vue";
import ElectricalCivil from "@/views/reports/ElectricalCivil.vue";
import DriveTrialStatus from "@/views/reports/DriveTrialStatus.vue";
import Electrical from "@/views/reports/Electrical.vue";
import Instrument from "@/views/reports/Instrument.vue";

//Jobs
import JobList from "@/views/jobs/JobList.vue";
import JobView from "@/views/jobs/JobView.vue";
import ActivityJobsView from "@/views/jobs/ActivityJobsView.vue";

//BeltConveyor
import BeltConveyors from "@/views/reports/belt_conveyor/Index.vue";
import CreateBeltConveyor from "@/views/reports/belt_conveyor/Create.vue";
import AssignUser from "@/views/project/AssignUser.vue";
import Logs from "@/views/logs/Index.vue";
import LogParameters from "@/views/logs/Show.vue";

const routes = [

    //dashboards

    {
        path: '/plan_parameters',
        name: 'PlanParameters',
        component: PlanParameters
    },

    // {
    //     path: '/project_dashboard',
    //     name: 'ProjectDashboard',
    //     component: ProjectDashboard
    // },
    {
        path: '/project_dashboard',
        name: 'ProjectDashboard',
        component: ProjectDashboard1
    },
    {
        path: '/admin_dashboard',
        name: 'AdminDashboard',
        component: AdminDashboard
    },
    {
        path: '/topmanagement_dashboard',
        name: 'TopManagement',
        component: TopManagement
    },
    // {
    //     path:'/bats_dashboard',
    //     name:'BatsDashboard',
    //     component:BatsDashboard
    // },
    {
        path:'/dashboard_report',
        name:'DashboardReport',
        component:DashboardReport
    },
    // {
    //     path:'/averages',
    //     name:'Averages',
    //     component:Averages
    // },
    {
        path:'/dashboard/bats',
        name:'BATS',
        component:BATS
    },
    {
        path:'/dashboard/cfd',
        name:'CFD',
        component:CFD
    },
    {
        path:'/dashboard/pr',
        name:'PR',
        component:PR
    },
    {
        path:'/report/bats',
        name:'BATSReport',
        component:BATSReport
    },
    {
        path:'/report/bats/line',
        name:'BATSLineReport',
        component:BATSLineReport
    },
    {
        path:'/report/bats/summary',
        name:'BATSSummaryReport',
        component:BATSSummaryReport
    },
    {
        path:'/report/bats/detail',
        name:'BATSDetailReport',
        component:BATSDetailReport
    },
    //charts
    {
        path: '/pmtchart',
        name: 'PMTCharts',
        component: PMTCharts
    },
    {
        path: "/",
        name: "login",
        component: Login,
    },
    {
        path: "/forgot_password",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
    },
    // {
    //     path: "/dashboard",
    //     name: "Dashboard",
    //     component: Dashboard,
    // },

    // template
    {
        path: "/templates",
        name: "Templates.Index",
        component: Templates,
    },
    {
        path: "/templates/create",
        name: "Templates.Create",
        component: CreateTemplate,
    },
    {
        path: "/templates/create_multiples",
        name: "TemplatesMultiple.Create",
        component: CreateTemplateMultiple,
    },
    {
        path: "/template/:template_id/update",
        name: "Template.Update",
        component: TemplateUpdate
    },

    // template master cost
    {
        path: "/template_master_costs",
        name: "TemplateMasterCosts.Index",
        component: TemplateMasterCosts,
    },
    {
        path: "/template_master_costs/create",
        name: "TemplateMasterCosts.Create",
        component: CreateTemplateMasterCost,
    },
    {
        path: "/template_master_costs/:template_master_cost_id/edit",
        name: "TemplateMasterCosts.Edit",
        component: CreateTemplateMasterCost,
    },

    // BatsReportTemplates
    {
        path: "/bats/report_templates",
        name: "Bats.ReportTemplates.Index",
        component: BatsReportTemplates,
    },
    {
        path: "/bats/report_templates/create",
        name: "Bats.ReportTemplates.Create",
        component: CreateBatsReportTemplates,
    },

    {
        path: "/bats/:project_id/report_templates/",
        name: "Bats.ReportTemplates.Link",
        component: LinkBatsReportTemplates,
    },

    // Role Group
    {
        path: "/role_groups",
        name: "RoleGroups",
        component: RoleGroups,
    },
    // Role
    {
        path: "/roles",
        name: "Roles",
        component: Roles,
    },
    //Escalation Role
    {
        path: "/escalation_roles",
        name: "EscalationRoles",
        component: EscalationRole,
    },
    //Functional Roles
    {
        path: "/functional_roles",
        name: "FunctionalRoles",
        component: FunctionalRole
    },
    //Users
    {
        path: "/users",
        name: "Users.Index",
        component: Users,
    },
    {
        path: "/users/create",
        name: "Users.Create",
        component: CreateUser,
    },
    {
        path: "/users/user_projects/:user_id",
        name: "UserProjects",
        component: UserProjects,
    },
    {
        path: "/users/:user_id/edit",
        name: "User.Edit",
        component: CreateUser,
    },
    // approval setting
    {
        path: "/approval_settings",
        name: "ApprovalSettings",
        component: ApprovalSettings,
    },

    {
        path: "/escalation_settings",
        name: "EscalationSetting",
        component: EscalationSetting,
    },

    // Permissions
    {
        path: "/permissions",
        name: "Permissions",
        component: Permission,
    },
    
    // new configuration
    {
        path: "/configuration",
        name: "Configurations",
        component: Configurations,
    },
    {
        path: "/projects",
        name: "Projects",
        component: Projects,
    },
    {
        path: "/inactive_projects",
        name: "InactiveProjects",
        component: InactiveProjects,
    },
    {
        path: "/project/create",
        name: "CreateProject",
        component: CreateProject,
    },
    {
        path: "/project/:project_id/edit",
        name: "EditProject",
        component: CreateProject,
    },
    {
        path: "/PMT/:project_id/edit",
        name: "PMT.Edit",
        component: EditPMT,
    },
    {
        path: "/PMT/:project_id/scope",
        name: "PMT.scope",
        component: Scope,
    },
    {
        path: "/project_cost/:project_master_cost_id/edit",
        name: "ProjectCost.Edit",
        component: EditProjectCost,
    },
    {
        path: "/project_cost/:project_id/request",
        name: "ProjectCost.Request",
        component: ProjectRequests,
    },
    {
        path: "/project_cost/pending_request",
        name: "ProjectCost.PendingRequest",
        component: PendingRequests,
    },
    {
        path: "/BATS/:project_id/user",
        name: "ProjectCost.User",
        component: ProjectCostUser,
    },
    {
        path: "/BATS/:project_id/sap_dump",
        name: "ProjectCost.UploadSapDump",
        component: uploadSapDump,
    },
    {
        path: "/BATS/:project_id/transfer",
        name: "ProjectCost.Transfer",
        component: TransferVue,
    },
    {
        path: "/BATS/:project_id/transfer_list",
        name: "ProjectCost.TransferList",
        component: TansferListVue,
    },
    {
        path: "/BATS/:project_id/withdrawal",
        name: "ProjectCost.Withdraw",
        component: WithdrawalVue,
    },
    {
        path: "/BATS/:project_id/add_project_cost",
        name: "ProjectCost.Add",
        component: AddProjectCost,
    },
    {
        path: "/BATS/:project_id/withdraw_list",
        name: "ProjectCost.WithdrawList",
        component: WithdrawListVue,
    },
    {
        path: "/BATS/:project_id/:project_cost_id/request",
        name: "ProjectBudgetRequest.User",
        component: ProjectBudgetRequest,
    },
    {
        path: "/BATS/:project_id/request/create",
        name: "ProjectBudgetRequest.Create",
        component: newProjectBudgetRequest,
    },
    {
        path: "/BATS/:project_id/:project_cost_id/request/:project_request_id",
        name: "ProjectBudgetRequest.User.Update",
        component: UpdateBudgetRequest,
    },
    {
        path: "/PMT/:project_id/user",
        name: "PMT.User",
        component: PMTUser,
    },
    {
        path: "/BATS/:project_id/edit",
        name: "BATS.Edit",
        component: EditBATS,
    },
    {
        path: "/pmt_project",
        name: "PMTProject",
        component: PMTProject,
    },

    {
        path: "/transfer",
        name: "Transfer",
        component: Transfer,
    },
    //Project_cost
    {
        path: "/project_cost",
        name: "ProjectCost.Index",
        component: ProjectCost,
    },

    {
        path: "/project_cost/create",
        name: "ProjectCost.Create",
        component: CreateProjectCost,
    },
    {
        path: "/tm_dashboard",
        name: "TMDashboard",
        component: TMDashboard
    },

    //reports
    {
        path:'/civil_summary',
        name:'CivilSummary',
        component:CivilSummary
    },
    {
        path:'/mechanical_summary',
        name:'lMechanicalSummary',
        component:MechanicalSummary
    },
    {
        path:'/program_report',
        name:'ProgramReport',
        component:ProgramReport
    },
    {
        path:'/overall_engineering',
        name:'Overall Engineering',
        component:OverallEngineering
    },
    {
        path:'/overall_procurement',
        name:'Overall Procurement',
        component:OverallEngineering
    },

    {
        path:'/overall_delivery',
        name:'Overall Delivery',
        component:OverallEngineering
    },
    
    {
        path:'/daily_progress',
        name:'DailyProgress',
        component:DailyProgress
    },
    {
        path:'/dpr_template',
        name:'DprTemplate',
        component:DprTemplate
    },
    {
        path:'/dpr_progress',
        name:'DPRProgress',
        component:DPRProgress
    },
    {
        path: "/dpr_template/:daily_progress_report_id/edit",
        name: "EditDprTemplate",
        component:EditDprTemplate,
    },
    {
        path:'/dpr_ho_manpower',
        name:'DPRHoManpower',
        component:DPRHoManpower
    },
    {
        path:'/dpr_manpower_template',
        name:'DPRManpowerTemplate',
        component:DPRManpowerTemplate
    },
    {
        path:'/overall_schedule',
        name:'Overall Schedule',
        component:OverallSchedule
    },

    {
        path:'/ordering_schedule',
        name:'Ordering Schedule Template',
        component:OrderingScheduleTemplate
    },

    {
        path:'/ei_template',
        name:'Electrical Civil',
        component:ElectricalCivil
    },

    {
        path:'/drive_trial_status',
        name:'Drive Trial Status',
        component:DriveTrialStatus
    },
    {
        path:'/electrical',
        name:'Electrical',
        component:Electrical
    },
    {
        path:'/instrument',
        name:'Instrument',
        component:Instrument
    },
    {
        path:'/whrs',
        name:'WHRS',
        component:WHRS
    },
    {
        path:'/refractory',
        name:'Refractory',
        component:Refractory
    },
    {
        path:'/slippage',
        name:'Slippage',
        component:Slippage
    },

    // 
    // {
    //     path:'/ordering_schedule_template/create',
    //     name:'Ordering Schedule Template.Create',
    //     component:CreateOrderingScheduleTemplate
    // },
    // {
    //     path:'/ordering_schedule_templates',
    //     name:'Ordering Schedule Templates.Index',
    //     component:OrderingScheduleTemplates
    // },
    // { 
	// 	path: '/ordering_schedule_templates/:ordering_schedule_id/edit', 
    //     name:'Ordering Schedule Template.Edit', 
	// 	component:CreateOrderingScheduleTemplate,
	// },

    // Hindrance
    {
        path: "/hindrance",
        name: "Hindrance",
        component: Hindrance,
    },

    //ProjectUser
    {
        path: "/project/:project_id/view",
        name: "ProjectUser",
        component: ProjectUser,
    },
    {
        path: "/project/:project_id/assign_user",
        name: "AssignUser",
        component: AssignUser,
    },
    {
        path: "/activity_groups/:project_id/:user_id",
        name: "ActivityGroups",
        component: ActivityGroups,
    },

    //belt conveyor
    {
        path: "/belt_conveyor/create",
        name: "BeltConveyors.Create",
        component: CreateBeltConveyor,
    },
    {
        path: "/belt_conveyors",
        name: "BeltConveyors.Index",
        component: BeltConveyors,
    },
    {
        path: "/belt_conveyors/:belt_conveyor_id/edit",
        name: "BeltConveyor.Edit",
        component: CreateBeltConveyor,
    },

    {
        path: '/jobs',
        name: 'JobList',
        component: JobList
    },
    {
        path: '/job/:job_id',
        name: 'JobView',
        component: JobView
    },
    {
        path: '/activityjobs',
        name: 'ActivityJobsView',
        component: ActivityJobsView
    },
    {
        path: '/sort_project_activity_groups',
        name: 'sortProjectActivityGroups',
        component: sortProjectActivityGroups
    },
    {
        path: '/logs',
        name: 'Logs',
        component: Logs
    },
    {
        path: '/log/:log_id/view',
        name: 'LogParameters',
        component: LogParameters
    },
    
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
