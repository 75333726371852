<template>
    <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Drive Trial Status</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                         <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="report.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Project</label>
                        <!-- <select class="form-select" :class="{'is-invalid': errors.project_id}" v-model="report.project_id">
                            <option value="">Select Project</option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name}} </option>
                        </select> -->
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="report.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @input="project => report.project_id = project"
                        >
                        </search>
                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Date</label>
                        <input class="form-control form-control-sm" type="date" placeholder="Date" :class="{ 'is-invalid': errors.date_time }" v-model="report.date_time" />
                        <span v-if="errors.date_time" class="invalid-feedback">{{ errors.date_time[0] }}</span>
                    </div>
                    <div class="col-md-3 mt-auto">
                        <button class="btn btn-sm btn-primary me-2" @click="showDriveTrialStatus()" type="button"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-warning me-2" @click="downloadOverallScheduleReport()"><i class="ri-download-line icon_hgt"></i> Download</button>
                        <button class="btn btn-sm btn-info" @click="sendEmailOverallScheduleReport()"><i class="ri-mail-send-line icon_hgt"></i> Send Email</button>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap1 table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center">Section</th>
                                        <th class="text-center">Total No. Of Groups</th>
                                        <th class="text-center">Total No Of Drives</th>
                                        <th class="text-center">Total No Of Drives Req for commissioning</th>
                                        <th class="text-center">Handed over to E&I</th>
                                        <th class="text-center">Termination Completed</th>
                                        <th class="text-center">No load trial of motor</th>
                                        <th class="text-center">No load trial of equipment</th>
                                        <th class="text-center">No. of Drive Trials Completed</th>
                                        <th class="text-center">Total No of Drive Trials Pending for commissioning</th>
                                        <th class="text-center">No. of Group Trials Completed</th>
                                        <th class="text-center">% Trials Completed</th>

                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input text="text" class="form-control" placeholder="Section" :class="{ 'is-invalid': errors.section }" v-model="form_field.section" />
                                            <span v-if="errors.section" class="invalid-feedback">{{ errors.section[0] }}</span>
                                        </td>
                                        <td>
                                            <input text="text" class="form-control" placeholder="Total No. Of Groups" :class="{ 'is-invalid': errors.total_groups }" v-model="form_field.total_groups" />
                                            <span v-if="errors.total_groups" class="invalid-feedback">{{ errors.total_groups[0] }}</span>
                                        </td>

                                        <td>
                                            <input type="text" placeholder="Total No Of Drives" class="form-control" :class="{ 'is-invalid': errors.total_drives }" v-model="form_field.total_drives" />
                                            <span v-if="errors.total_drives" class="invalid-feedback">{{ errors.total_drives[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="text" placeholder="Total No Of Drives Req for commissioning" class="form-control" :class="{ 'is-invalid': errors.total_drive_required }" v-model="form_field.total_drive_required" />
                                            <span v-if="errors.total_drive_required" class="invalid-feedback">{{ errors.total_drive_required[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="text" placeholder="Handed over to E&I" class="form-control" :class="{ 'is-invalid': errors.handed_over }" v-model="form_field.handed_over" />
                                            <span v-if="errors.handed_over" class="invalid-feedback">{{ errors.handed_over[0] }}</span>
                                        </td>

                                        <td>
                                            <input type="text" placeholder="Termination Completed" class="form-control" :class="{ 'is-invalid': errors.termination_completed }" v-model="form_field.termination_completed" />
                                            <span v-if="errors.termination_completed" class="invalid-feedback">{{ errors.termination_completed[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="text" placeholder="No load trial of motor" class="form-control" :class="{ 'is-invalid': errors.no_load_trial_of_motor }" v-model="form_field.no_load_trial_of_motor" />
                                            <span v-if="errors.no_load_trial_of_motor" class="invalid-feedback">{{ errors.no_load_trial_of_motor[0] }}</span>
                                        </td>

                                        <td>
                                            <input type="text" placeholder="No load trial of equipment" class="form-control" :class="{ 'is-invalid': errors.no_load_trial_of_equipment }" v-model="form_field.no_load_trial_of_equipment" />
                                            <span v-if="errors.no_load_trial_of_equipment" class="invalid-feedback">{{ errors.no_load_trial_of_equipment[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="text" placeholder="No. of Drive Trials Completed" class="form-control" :class="{ 'is-invalid': errors.drive_completed }" v-model="form_field.drive_completed" />
                                            <span v-if="errors.drive_completed" class="invalid-feedback">{{ errors.drive_completed[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="text" placeholder="Total No of Drive Trials Pending for commissioning" class="form-control" :class="{ 'is-invalid': errors.drive_pending }" v-model="form_field.drive_pending" />
                                            <span v-if="errors.drive_pending" class="invalid-feedback">{{ errors.drive_pending[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="text" placeholder="No. of Group Trials Completed" class="form-control" :class="{ 'is-invalid': errors.total_groups_completed }" v-model="form_field.total_groups_completed" />
                                            <span v-if="errors.total_groups_completed" class="invalid-feedback">{{ errors.total_groups_completed[0] }}</span>
                                        </td>
                                        <td>
                                            <input type="text" placeholder="% Trials Completed" class="form-control" :class="{ 'is-invalid': errors.trials_completed }" v-model="form_field.trials_completed" />
                                            <span v-if="errors.trials_completed" class="invalid-feedback">{{ errors.trials_completed[0] }}</span>
                                        </td>

                                        <td class="text-center">
                                            <button v-if="formStatus" @click="addRow()" class="btn btn-sm btn-primary"><i class="ri-add-line"></i></button>
                                            <button v-else @click="updateRow()" class="btn btn-sm btn-primary"><i class="ri-save-line"></i></button>
                                        </td>
                                    </tr>
                                    <tr v-if="!report.drive_statuses.length">
                                        <td colspan="12" class="text-center">No records found</td>
                                    </tr>
                                    <tr v-for="(drive_status, key) in report.drive_statuses" :key="key">
                                        <td>{{ drive_status.section }}</td>
                                        <td>{{ drive_status.total_groups }}</td>
                                        <td>{{ drive_status.total_drives }}</td>
                                        <td>{{ drive_status.total_drive_required }}</td>
                                        <td>{{ drive_status.handed_over }}</td>
                                        <td>{{ drive_status.termination_completed }}</td>
                                        <td>{{ drive_status.no_load_trial_of_motor }}</td>
                                        <td>{{ drive_status.no_load_trial_of_equipment }}</td>
                                        <td>{{ drive_status.drive_completed }}</td>
                                        <td>{{ drive_status.drive_pending }}</td>
                                        <td>{{ drive_status.total_groups_completed }}</td>
                                        <td>{{ drive_status.trials_completed }}</td>

                                        <td class="text-center d-flex">
                                            <button class="btn btn-sm btn-outline-success me-2" data-toggle="tooltip" data-placement="bottom" title="Edit" @click="editRow(drive_status,key)">
                                                <i class="ri-pencil-line"></i>
                                            </button>
                                            <button class="btn btn-outline-danger btn-sm" type="button" @click="deleteRow(drive_status,key)">
                                                <i class="ri-delete-bin-line"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-primary btn-sm" @click="addDriveStatus()">
                    <span><i class="ri-save-line icon_hgt"></i> SUBMIT</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    let Search = require("@/components/Search.vue").default;
    import moment from "moment";
    export default {
        name: "DriveTrialStatus",
        components: {Search },
        data() {
            return {
                initial:true,
                report: {
                    report_id: "",
                    project_id: "",
                    project_group_id:"",
                    date_time: "",
                    deleted_drive_statuses: [],
                    drive_statuses: [],
                    isDownload:false
                },
                form_field: {
                    form_field_id: "",
                    section: "",
                    total_groups: "",
                    total_drives: "",
                    total_drive_required: "",
                    handed_over: "",
                    termination_completed: "",
                    no_load_trial_of_motor: "",
                    no_load_trial_of_equipment: "",
                    drive_completed: "",
                    drive_pending: "",
                    total_groups_completed: "",
                    trials_completed: "",
                },
                projects: [],
                projectgroups:[],
                status: true,
                formStatus: true,
                errors: [],
            };
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectGroups()
            }
            this.report.date_time = moment().subtract(1, 'day').format('yyyy-MM-DD')
        },
        methods: {
            sendEmailOverallScheduleReport(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "downloadDriveStatus", data: vm.report})
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Drive Trial Status summary - Mail sent successfully");
                    })
                    .catch(function (error) {
                        loader.hide()
                        if(error.response.data.length){
                            console.log(error.response.data[1])
                            vm.$store.dispatch("error", error.response.data[1]);
                        }
                    });
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.report.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.report.project_group_id) {
                                vm.report.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjects() {
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects", data: {project_group_id: vm.report.project_group_id} })
                    .then(function (response) {
                        vm.projects = response.data;
                        if(localStorage.getItem('project_id')){
                            vm.report.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.report.project_id = vm.projects[0].project_id;
                        }
                        if(vm.initial){
                            vm.showDriveTrialStatus()
                            vm.initial = false
                        }
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addRow() {
                let vm = this;
                vm.errors = [];
                if (
                    vm.form_field.section == "" ||
                    vm.form_field.total_groups == "" ||
                    vm.form_field.total_drives == "" ||
                    vm.form_field.total_drive_required == "" ||
                    vm.form_field.handed_over == "" ||
                    vm.form_field.termination_completed == "" ||
                    vm.form_field.no_load_trial_of_motor == "" ||
                    vm.form_field.no_load_trial_of_equipment == "" ||
                    vm.form_field.drive_completed == "" ||
                    vm.form_field.drive_pending == "" ||
                    vm.form_field.total_groups_completed == "" ||
                    vm.form_field.trials_completed == ""
                ) {
                    if (vm.form_field.section == "") {
                        vm.errors.section = ["Section field cannot be empty"];
                    }
                    if (vm.form_field.total_groups == "") {
                        vm.errors.total_groups = ["Total no. of groups field cannot be empty"];
                    }
                    if (vm.form_field.total_drives == "") {
                        vm.errors.total_drives = ["Total no. of drives field cannot be empty"];
                    }
                    if (vm.form_field.total_drive_required == "") {
                        vm.errors.total_drive_required = ["Total no. of drives req for commissioning field cannot be empty"];
                    }
                    if (vm.form_field.handed_over == "") {
                        vm.errors.handed_over = ["Handed over to E&I field cannot be empty"];
                    }
                    if (vm.form_field.termination_completed == "") {
                        vm.errors.termination_completed = ["Termination completed field cannot be empty"];
                    }
                    if (vm.form_field.no_load_trial_of_motor == "") {
                        vm.errors.no_load_trial_of_motor = ["No load trial of motor field cannot be empty"];
                    }
                    if (vm.form_field.no_load_trial_of_equipment == "") {
                        vm.errors.no_load_trial_of_equipment = ["No load trial of equipment field cannot be empty"];
                    }
                    if (vm.form_field.drive_completed == "") {
                        vm.errors.drive_completed = ["No. of drive trials completed field cannot be empty"];
                    }
                    if (vm.form_field.total_groups_completed == "") {
                        vm.errors.total_groups_completed = ["No. of group trials completed field cannot be empty"];
                    }
                } else {
                    vm.report.drive_statuses.push({
                        form_field_id: "",
                        section: vm.form_field.section,
                        total_groups: vm.form_field.total_groups,
                        total_drives: vm.form_field.total_drives,
                        total_drive_required: vm.form_field.total_drive_required,
                        handed_over: vm.form_field.handed_over,
                        termination_completed: vm.form_field.termination_completed,
                        no_load_trial_of_motor: vm.form_field.no_load_trial_of_motor,
                        no_load_trial_of_equipment: vm.form_field.no_load_trial_of_equipment,
                        drive_completed: vm.form_field.drive_completed,
                        drive_pending: vm.form_field.drive_pending,
                        total_groups_completed: vm.form_field.total_groups_completed,
                        trials_completed: vm.form_field.trials_completed,
                    });
                    vm.discardForm();
                }
            },

            updateRow() {
                let vm = this;
                vm.errors = [];
                if (
                    vm.form_field.section == "" ||
                    vm.form_field.total_groups == "" ||
                    vm.form_field.total_drives == "" ||
                    vm.form_field.total_drive_required == "" ||
                    vm.form_field.handed_over == "" ||
                    vm.form_field.termination_completed == "" ||
                    vm.form_field.no_load_trial_of_motor == "" ||
                    vm.form_field.no_load_trial_of_equipment == "" ||
                    vm.form_field.drive_completed == "" ||
                    vm.form_field.drive_pending == "" ||
                    vm.form_field.total_groups_completed == "" ||
                    vm.form_field.trials_completed == ""
                ) {
                    if (vm.form_field.section == "") {
                        vm.errors.section = ["Section field cannot be empty"];
                    }
                    if (vm.form_field.total_groups == "") {
                        vm.errors.total_groups = ["Total no. of groups field cannot be empty"];
                    }
                    if (vm.form_field.total_drives == "") {
                        vm.errors.total_drives = ["Total no. of drives field cannot be empty"];
                    }
                    if (vm.form_field.total_drive_required == "") {
                        vm.errors.total_drive_required = ["Total no. of drives req for commissioning field cannot be empty"];
                    }
                    if (vm.form_field.handed_over == "") {
                        vm.errors.handed_over = ["Handed over to E&I field cannot be empty"];
                    }
                    if (vm.form_field.termination_completed == "") {
                        vm.errors.termination_completed = ["Termination completed field cannot be empty"];
                    }
                    if (vm.form_field.no_load_trial_of_motor == "") {
                        vm.errors.no_load_trial_of_motor = ["No load trial of motor field cannot be empty"];
                    }
                    if (vm.form_field.no_load_trial_of_equipment == "") {
                        vm.errors.no_load_trial_of_equipment = ["No load trial of equipment field cannot be empty"];
                    }
                    if (vm.form_field.drive_completed == "") {
                        vm.errors.drive_completed = ["No. of drive trials completed field cannot be empty"];
                    }
                    if (vm.form_field.total_groups_completed == "") {
                        vm.errors.total_groups_completed = ["No. of group trials completed field cannot be empty"];
                    }
                } else {
                    vm.report.drive_statuses.push({
                        form_field_id: vm.form_field.form_field_id,
                        section: vm.form_field.section,
                        total_groups: vm.form_field.total_groups,
                        total_drives: vm.form_field.total_drives,
                        total_drive_required: vm.form_field.total_drive_required,
                        handed_over: vm.form_field.handed_over,
                        termination_completed: vm.form_field.termination_completed,
                        no_load_trial_of_motor: vm.form_field.no_load_trial_of_motor,
                        no_load_trial_of_equipment: vm.form_field.no_load_trial_of_equipment,
                        drive_completed: vm.form_field.drive_completed,
                        drive_pending: vm.form_field.drive_pending,
                        total_groups_completed: vm.form_field.total_groups_completed,
                        trials_completed: vm.form_field.trials_completed,
                    });
                    vm.report.drive_statuses.splice(vm.form_field.key, 1);
                    vm.discardForm();
                }
            },

            discardForm() {
                let vm = this;
                vm.form_field.section = "";
                vm.form_field.total_groups = "";
                vm.form_field.total_drives = "";
                vm.form_field.total_drive_required = "";
                vm.form_field.handed_over = "";
                vm.form_field.termination_completed = "";
                vm.form_field.no_load_trial_of_motor = "";
                vm.form_field.no_load_trial_of_equipment = "";
                vm.form_field.drive_completed = "";
                vm.form_field.drive_pending = "";
                vm.form_field.total_groups_completed = "";
                vm.form_field.trials_completed = "";
                vm.formStatus = true;
                vm.errors = [];
            },

            editRow(forms, key) {
                let vm = this;
                vm.form_field = forms;
                vm.formStatus = false;
                vm.form_field.key = key;
                vm.errors = [];
            },
            deleteRow(drive_status, key) {
                if (confirm("Are you sure you want to delete")) {
                    let vm = this;
                    vm.report.deleted_drive_statuses.push(drive_status.drive_status_id);
                    vm.report.drive_statuses.splice(key, 1);
                    vm.discardForm();
                }
            },

            addReports() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addDriveStatus", data: vm.report })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Drive Trial Status is successfully created");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addDriveStatus() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addDriveStatus", data: vm.report })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Drive Trail Status is successfully updated");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            showDriveTrialStatus() {
                let vm = this;
                localStorage.setItem('project_group_id', vm.report.project_group_id)
                localStorage.setItem('project_id', vm.report.project_id)
                vm.errors = [];
                if (vm.report.project_id == "") {
                    if (vm.report.project_id == "") {
                        vm.errors.project_id = ["Project field cannot be empty"];
                    }
                } else {
                    let loader = vm.$loading.show();
                    vm.errors = [];
                    vm.$store
                        .dispatch("post", { uri: "getDriveStatus", data: vm.report })
                        .then(function (response) {
                            vm.report.drive_statuses = response.data.data;
                            vm.status = false;
                            loader.hide();
                        })
                        .catch(function (error) {
                            loader.hide();
                            console.log(error);
                        });
                }
            },

            downloadOverallScheduleReport(){
                let vm = this;
                vm.errors = [];
                if (vm.report.project_id == ""){
                    vm.errors.project_id = ["The project field is required."]
                }
                if (vm.report.date_time == ""){
                    vm.errors.date_time = ["The Date field is required."]
                }
                if (vm.report.project_id && vm.report.date_time ){
                    window.open(vm.$store.getters.apiUrl+'downloadDriveStatus?project_id='+vm.report.project_id+'&date_time='+vm.report.date_time+'&isDownload=true');
                }
            }
        },
    };
</script>


<style scoped>
th{
    text-wrap: balance;
}
</style>