<template>
    <div class="">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>WHRS</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                        <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="project.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Projects</label>
                        <!-- <select class="form-select form-select-sm" :class="{'is-invalid': errors?.project_id}" v-model="project.project_id">
                            <option value=""> Select Project </option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{project.project_name }} </option>
                        </select> -->
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="project.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @input="project1 => project.project_id = project1"
                        >
                        </search>
                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">From Date</label>
                        <div class="input-group date" id="from_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.from_date }" v-model="project.from_date" />
                        </div>
                        <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">To Date</label>
                        <div class="input-group date" id="to_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.to_date }" v-model="project.to_date" />
                        </div>
                        <span v-if="errors.to_date" style="margin-top: 0.25rem;font-size: 0.875em;color: #dc3545;">{{ errors.to_date[0] }}</span>
                    </div>
                    <div class="col-md-12 mt-1">
                        <div style="float:right">
                        <button class="btn btn-sm btn-primary me-2" @click="getWHRSErectionFab()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-warning me-2 " @click="downloadWHRSDetail()"><i class="ri-download-line icon_hgt"></i> Download </button>
                        <button class="btn btn-sm btn-info " @click="sendEmailWHRSDetail()"><i class="ri-mail-send-line icon_hgt"></i> Send Email </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-4">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-wrap table-bordered mb-0">
                            <thead class="bg-primary bg-opacity-50">
                                <tr class="text-center" style="vertical-align: middle;">
                                    <th colspan="14"><h4 v-if="whrs_data.length != 0"><strong>{{whrs_data?.project?.project_name + ' -  WHRS'}}</strong></h4></th>
                                    <!-- <th><h4><strong>{{ format_date(project.job_date_time) }}</strong></h4></th> -->
                                </tr>
                                <tr class="text-center" style="vertical-align: middle;">
                                    <th class="fw-bold">SI NO.</th>
                                    <th class="fw-bold">PARTICULAR</th>
                                    <th class="fw-bold">UOM</th>
                                    <th class="fw-bold">Scope</th>
                                    <th class="fw-bold">Dwg Released</th>
                                    <th class="fw-bold">Target FTD</th>
                                    <th class="fw-bold">FTD</th>
                                    <th class="fw-bold">Target FTM</th>
                                    <th class="fw-bold">MTD</th>
                                    <th class="fw-bold">CTD (PL)</th>
                                    <th class="fw-bold">CTD</th>
                                    <th class="fw-bold">BALANCE</th>
                                    <th class="fw-bold">% of completion</th>
                                    <th class="fw-bold">Remarks</th>
                                </tr>
                            </thead>
                            <tbody v-for="project_section, ps_key in project_sections" :key="ps_key">
                                <tr>
                                    <td class="bg-primary bg-opacity-25" colspan="15"><h5>{{ project_section.section.section_name }}</h5></td>
                                </tr>
                                <template v-for="project_activity, pa_key in project_section.project_activities" :key="pa_key">
                                    <tr v-if="eliminateZeros(project_activity)">
                                        <td class="text-center">{{ pa_key+1 }}</td>
                                        <td>{{ project_activity.activity.activity_name }}</td>
                                        <td class="text-center">{{ project_activity.unit.unit_name }}</td>
                                        <td class="text-center">{{ project_activity.scope }}</td>
                                        <td class="text-center">{{ project_activity.dwg_released }}</td>
                                        <td class="text-center">{{ project_activity.target_ftd }}</td>
                                        <td class="text-center">{{ project_activity.ftd }}</td>
                                        <td class="text-center">{{ project_activity.target_ftm }}</td>
                                        <td class="text-center">{{ project_activity.mtd }}</td>
                                        <td class="text-center">{{ project_activity.ctd_pl }}</td>
                                        <td class="text-center">{{ project_activity.ctd }}</td>
                                        <td class="text-center">{{ project_activity.balance }}</td>
                                        <td class="text-center">{{ project_activity.percentage_complete }}</td>
                                        <td class="text-center"></td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

</template>

<script>
import moment from 'moment';
    let Search = require("@/components/Search.vue").default;
    export default {
        name: "DailyProgress",
        components: {Search },
        data() {
            return {
                initial:true,
                project:{
                    project_id:'',
                    from_date:'',
                    to_date:'',
                    project_group_id:'',
                    isDownload:false
                },
                project_sections:[],
                whrs_data:[],
                projectgroups:[],
                date_time:'',
                errors:[],
                projects:[],
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
            });
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectGroups();
            }
            this.project.to_date = moment().format('yyyy-MM-DD')
        },

        methods: {
            eliminateZeros(project_activity){
                if(project_activity.dwg_released || project_activity.ctd_pl || project_activity.ctd){
                    return project_activity
                }else{
                    return false
                }
            },
            sendEmailWHRSDetail(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "downloadWHRSReport", data: vm.project})
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "WHRS summary - Mail sent successfully");
                    })
                    .catch(function (error) {
                        loader.hide()
                        if(error.response.data.length){
                            console.log(error.response.data[1])
                            vm.$store.dispatch("error", error.response.data[1]);
                        }
                    });
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.project.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.project.project_group_id) {
                                vm.project.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getTotalCalculations(project_activities){
                let vm = this; 
                let activities = []
                let total_uom = ''
                let total_scope = 0
                let total_dwg_released = 0
                let total_target_ftd = 0
                let total_ftd = 0
                let total_target_ftm = 0
                let total_mtd = 0
                let total_ctd_pl = 0
                let total_ctd = 0
                let total_balance = 0;
                let total_complete = 0;
                let total_barchart = 0;

                if(project_activities?.length != 0){
                    project_activities?.map(function(ele){
                        if(total_uom == ''){
                            total_uom = ele.unit.unit_name
                        }else if (ele.unit.unit_name != total_uom){
                            total_uom = total_uom + ' + ' + ele.unit.unit_name
                        }
                        if(ele.scope && !isNaN(ele.scope)){
                            total_scope = total_scope + parseFloat(ele.scope)
                        }
                        if(ele.ftd){
                            total_ftd = total_ftd + parseFloat(ele.ftd)
                        }
                        if(ele.dwg_released){
                            total_dwg_released = total_dwg_released + ele.dwg_released
                        }
                        if(ele.target_ftd){
                            total_target_ftd = total_target_ftd + parseFloat(ele.target_ftd)
                        }
                        if(ele.target_ftm){
                            total_target_ftm = total_target_ftm + parseFloat(ele.target_ftm)
                        }
                        if(ele.mtd){
                            total_mtd = total_mtd + parseFloat(ele.mtd)
                        }
                        if(ele.ctd_pl){
                            total_ctd_pl = total_ctd_pl + parseFloat(ele.ctd_pl)
                        }
                        if(ele.ctd){
                            total_ctd = total_ctd + parseFloat(ele.ctd)
                        }
                        if(ele.balance){
                            total_balance = total_balance + parseFloat(ele.balance)
                        }
                        if(ele.percentage_complete){
                            total_complete = total_complete + parseFloat(ele.percentage_complete)
                        }
                        if(ele.bar_chart_value){
                            total_barchart = total_barchart + parseFloat(ele.bar_chart_value)
                        }
                    })

                    activities.push({
                        "uom": total_uom,
                        "scope": total_scope,
                        "dwg_released": total_dwg_released,
                        "target_ftd": total_target_ftd,
                        "ftd": total_ftd,
                        "target_ftm": total_target_ftm,
                        "mtd": total_mtd,
                        "ctd_pl": total_ctd_pl,
                        "ctd": total_ctd,
                        "balance": total_balance,
                        "percentage_complete": total_complete,
                        "bar_chart_value": total_barchart
                    })
                }
                return activities
            },
            getErectionData(project_activities){
                let vm = this; 
                let activities = []
                let total_uom = ''
                let total_scope = 0
                let total_dwg_released = 0
                let total_target_ftd = 0
                let total_ftd = 0
                let total_target_ftm = 0
                let total_mtd = 0
                let total_ctd_pl = 0
                let total_ctd = 0
                let total_balance = 0;
                let total_complete = 0;
                let total_barchart = 0;

                if(project_activities?.length != 0){
                    project_activities?.map(function(ele){
                        if(ele.activity.activity_name == 'AQC' || ele.activity.activity_name == 'PH'){
                            if(total_uom == ''){
                                total_uom = ele.unit.unit_name
                            }else if (ele.unit.unit_name != total_uom){
                                total_uom = total_uom + ' + ' + ele.unit.unit_name
                            }
                            if(ele.scope){
                                total_scope =+ total_scope + parseFloat(ele.scope)
                            }

                            if(ele.ftd){
                                total_ftd =+ total_ftd + parseFloat(ele.ftd)
                            }
                            if(ele.dwg_released){
                                total_dwg_released = total_dwg_released + parseFloat(ele.dwg_released)
                            }
                            if(ele.target_ftd){
                                total_target_ftd = total_target_ftd + parseFloat(ele.target_ftd)
                            }
                            if(ele.target_ftm){
                                total_target_ftm = total_target_ftm + parseFloat(ele.target_ftm)
                            }
                            if(ele.mtd){
                                total_mtd = total_mtd + parseFloat(ele.mtd)
                            }
                            if(ele.ctd_pl){
                                total_ctd_pl = total_ctd_pl + parseFloat(ele.ctd_pl)
                            }
                            if(ele.ctd){
                                total_ctd = total_ctd + parseFloat(ele.ctd)
                            }
                            if(ele.balance){
                                total_balance = total_balance + parseFloat(ele.balance)
                            }
                            if(ele.percentage_complete){
                                total_complete = total_complete + parseFloat(ele.percentage_complete)
                            }
                            if(ele.bar_chart_value){
                                total_barchart = total_barchart + parseFloat(ele.bar_chart_value)
                            }
                        }
                    })

                    activities.push({
                        "uom": total_uom,
                        "scope": total_scope,
                        "dwg_released": total_dwg_released,
                        "target_ftd": total_target_ftd,
                        "ftd": total_ftd,
                        "target_ftm": total_target_ftm,
                        "mtd": total_mtd,
                        "ctd_pl": total_ctd_pl,
                        "ctd": total_ctd,
                        "balance": total_balance,
                        "percentage_complete": total_complete,
                        "bar_chart_value": total_barchart
                    })
                }
                return activities
            },
            getProjects() {
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects", data: vm.project })
                    .then(function (response) {
                        vm.projects = response.data;
                        if(localStorage.getItem('project_id')){
                            vm.project.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.project.project_id = vm.projects[0].project_id;
                        }
                        if(vm.initial){
                            vm.getWHRSErectionFab();
                            vm.initial = false
                        }  
                        
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getWHRSErectionFab() {
                let vm = this;
                localStorage.setItem('project_group_id', vm.project.project_group_id)
                localStorage.setItem('project_id', vm.project.project_id)
                let loader = vm.$loading.show();
                vm.$store
                .dispatch("post", { uri: "getWHRSErectionFab", data:vm.project})
                .then(function (response) {
                    loader.hide();
                    vm.whrs_data = response.data;
                    vm.project_sections = response.data.project_sections
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            format_date(dt){
                return moment(dt).format("DD-MM-YYYY");
            },

            downloadWHRSDetail(){
                let vm = this;
                vm.errors = [];
                if (vm.project.project_group_id == ""){
                    vm.errors.project_group_id = ["The project group field is required."]
                }
                if (vm.project.to_date == ""){
                    vm.errors.to_date = ["The Date field is required."]
                }
                if (vm.project.project_group_id && vm.project.to_date ){
                    window.open(vm.$store.getters.apiUrl+'downloadWHRSReport?project_id='+vm.project.project_id+'&to_date='+vm.project.to_date+'&from_date='+vm.project.from_date+'&isDownload=true')
                }
            }
        }
    }
</script>