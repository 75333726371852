<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
			</ol>
			<h4 class="main-title mb-0">Dashboard</h4>
		</div>
		<div class="d-flex align-items-center gap-2 mt-3 mt-md-0">
			<ol class="breadcrumb fs-sm mb-1">
				<h4 class="breadcrumb-item">{{ project_section_group.project_group_name }}</h4>
				<h4 class="breadcrumb-item">{{ project_section_group.project_name }}</h4>
			</ol>
		</div>
	</div>
	<div class="row g-2 mb-3">
		<div class="col-sm-6">
			<div class="row g-2 mb-3">
				<div class="col-sm-4">
					<div class="list-group">
						<button class="list-group-item d-flex justify-content-between align-items-center active">
							Project Groups
							<span class="label">
								{{ project_groups.length }}
							</span>
						</button>
						<template v-for="project_group, pg_key in project_groups" :key="pg_key">
							<button @click="discardLocalStorage(project_group)" class="list-group-item d-flex justify-content-between align-items-center" :class="project_section_group.project_group_id==project_group.project_group_id?'list-group-item-success':''">
								{{ project_group.project_group_name }} 
								<span class="badge text-bg-primary rounded-pill">
									&raquo;
								</span>
							</button>
						</template>
					</div>
				</div>
				<div class="col-sm-8">
					<div class="list-group">
						<button class="list-group-item d-flex justify-content-between align-items-center active">
							Projects
							<span class="label">
								{{ projects.length }}
							</span>
						</button>
						<template v-for="project, project_key in projects" :key="project_key">
							<button @click="getOverallGaugeGraph(project)" class="list-group-item d-flex justify-content-between align-items-center" :class="project_section_group.project_id==project.project_id?'list-group-item-success':''">
								{{ project.project_code }} : {{ project.project_name }} 
								<span class="badge text-bg-primary rounded-pill">
									&raquo;
								</span>
							</button>
						</template>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-6">
			<div class="row g-2 mb-3">
				<div class="col-sm-6">
					<div class="card card-one">
						<div class="card-body py-0">
							<GaugeMeter
								:container="'gaugemetera'"
								:title="gauge_meter_a.title"
								:max="gauge_meter_a.max"
								:series="gauge_meter_a.series"
								:type="gauge_meter_a.type"
								:plot_bands="gauge_meter_a.plot_bands"
								:actual="gauge_meter_a.actual"
								:plan="gauge_meter_a.plan"
								:label_font_size="gauge_meter_a.label_font_size"
								ref="child"
								:height="gauge_meter_a.height"
								:width="gauge_meter_a.width"
								:vartical_align="gauge_meter_a.vartical_align"
							/>
						</div>
					</div>
					<div class="d-grid gap-2">
						<button class="btn btn-primary" type="button" @click="pageRedirect('PMT')">PMT</button>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="card card-one">
						<div class="card-body py-0">
							<GaugeMeter
								:container="'gaugemeterb'"
								:title="gauge_meter_b.title"
								:max="gauge_meter_b.max"
								:series="gauge_meter_b.series"
								:type="gauge_meter_b.type"
								:plot_bands="gauge_meter_b.plot_bands"
								:actual="gauge_meter_b.actual"
								:plan="gauge_meter_b.plan"
								:label_font_size="gauge_meter_b.label_font_size"
								ref="child"
								:height="gauge_meter_b.height"
								:width="gauge_meter_b.width"
								:vartical_align="gauge_meter_b.vartical_align"
							/>
						</div>
					</div>
					<div class="d-grid gap-2">
						<button class="btn btn-primary" type="button" @click="pageRedirect('BATS')">BATS</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
import moment from "moment";
import GaugeMeter from "@/components/charts/GaugeMeter";
export default {
	name: "Dashboard.BATS",
	components: { GaugeMeter },
	data() {
		return {
			
			gauge_meter_a: {
				title: "Overall",
				type: "solidgauge",
				max: 0,
				label_font_size: "12px",
				project_parameter_length: "",
				plot_bands: [
					{
						from: 0,
						to: 100,
						color: "#C0C0C0",
						thickness: 15,
					},
				],
				actual: [],
				plan: [],
				base_line_start_date: "",
				base_line_end_date: "",
				completion_days: "",
				height: "45%",
				marginTop: 50,
				vartical_align:'top'
			},
			gauge_meter_b: {
				title: "Budget Utilization",
				type: "solidgauge",
				max: 0,
				label_font_size: "12px",
				project_parameter_length: "",
				plot_bands: [
					{
						from: 0,
						to: 100,
						color: "#C0C0C0",
						thickness: 15,
					},
				],
				actual: [0],
				plan: [0],
				base_line_start_date: "",
				base_line_end_date: "",
				completion_days: "",
				height: "45%",
				marginTop: 50,
				vartical_align:'top'
			},
			project_section_group: {
				current_month_year: "",
				is_calculation: "",
				project_id: "",
				project_code: "",
				selected_plan:"overall_plan",
				project_group_id:'',
				job_date_time:'',
				project_group_name:'All',
				project_name:'All',
			},
			project_costs: 0,
			pending_requests: 0,
			ho_pending_requests:0,
			plant_pending_requests:0,
			returned_requests: 0,
			pending_approvals: 0,
			new_pending_approvals: 0,
			delayed_pending_approvals: 0,
			approved_requests: 0,
			rejected_requests: 0,
			cfd_requests: 0,
			stage_id: '',

			project_groups:[],
			projects:[],
			project:{}
		};
	},

	mounted(){
		if(this.$store.getters.user){
			this.getProjectGroups();
		}
	},

	methods: {
		pageRedirect(param){
			localStorage.setItem('project_group_id', this.project_section_group.project_group_id)
			localStorage.setItem('project_id', this.project_section_group.project_id)
			if(param == 'PMT'){
				this.$router.push('/project_dashboard')
			}
			if(param == 'BATS'){
				this.$router.push('/report/bats/summary?project_group_id='+this.project_section_group.project_group_id+'&project_id='+this.project_section_group.project_id)
			}
		},

		getProjectGroups() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getAllProjectGroups" })
			.then(function (response) {
				vm.project_groups = response.data.data
				if(vm.project_groups.length) {
					vm.project_section_group.project_group_id = vm.project_groups[0].project_group_id;
					vm.project_section_group.project_group_name = vm.project_groups[0].project_group_name;
					if(localStorage.getItem('project_group_id')){
						let pg_id = localStorage.getItem('project_group_id')
						let pg = vm.project_groups.filter(function(ele){
							return ele.project_group_id == pg_id
						})
						if(pg.length){
							vm.getProjects(pg[0])
						}
					}else{
						vm.getProjects(vm.project_groups[0])
					}
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		getProjects(project_group) {
			let vm = this;
			this.project_section_group.project_group_id = project_group.project_group_id
			this.project_section_group.project_group_name = project_group.project_group_name
			this.project_name = 'All';
			vm.$store.dispatch("post", { uri: "getProjectsByProjectGroup", data:project_group })
			.then(function (response) {
				vm.projects = response.data
				if(localStorage.getItem('project_id')){
					let pr_id = localStorage.getItem('project_id')
					let pr = vm.projects.filter(function(ele){
						return ele.project_id == pr_id
					})
					if(pr.length){
						vm.getOverallGaugeGraph(pr[0])						
					}
				}else{
					vm.getOverallGaugeGraph(vm.projects[0])
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		getOverallGaugeGraph(project){
			let vm = this
			vm.project_section_group.project_id = project.project_id
			vm.project_section_group.project_name = project.project_name
			vm.project_section_group.job_date_time  =  moment().format("YYYY-MM-DD")
			let uri = { uri: "getOverallGaugeGraph", data: vm.project_section_group  };
			vm.$store.dispatch("post", uri)
			.then(function (response) {
				let data = response.data  
				vm.gauge_meter_a.actual = [];
				vm.gauge_meter_a.plan = [];

				let overall_plan_value = (response.data.engineering.overall_plan.total_value * data.engineering.overall_plan.total_scope) + (data.procurement.overall_plan.total_value * data.procurement.overall_plan.total_scope) + (data.delivery.overall_plan.total_value * data.delivery.overall_plan.total_scope) + (data.construction.overall_plan.total_value * data.construction.overall_plan.total_scope) + (data.commissioning.overall_plan.total_value * data.commissioning.overall_plan.total_scope) + (data.pre_project.overall_plan.total_value * data.pre_project.overall_plan.total_scope)
				let plan_scope = data.engineering.overall_plan.total_scope + data.procurement.overall_plan.total_scope + data.delivery.overall_plan.total_scope + data.construction.overall_plan.total_scope + data.commissioning.overall_plan.total_scope + data.pre_project.overall_plan.total_scope
				let overall_plan = Math.round(overall_plan_value/plan_scope);

				let overall_actual_value = (response.data.engineering.actual_plan.total_value * data.engineering.actual_plan.total_scope) + (data.procurement.actual_plan.total_value * data.procurement.actual_plan.total_scope) + (data.delivery.actual_plan.total_value * data.delivery.actual_plan.total_scope) + (data.construction.actual_plan.total_value * data.construction.actual_plan.total_scope) + (data.commissioning.actual_plan.total_value * data.commissioning.actual_plan.total_scope) + (data.pre_project.actual_plan.total_value * data.pre_project.actual_plan.total_scope)
				let actual_scope = data.engineering.actual_plan.total_scope + data.procurement.actual_plan.total_scope + data.delivery.actual_plan.total_scope + data.construction.actual_plan.total_scope + data.commissioning.actual_plan.total_scope + data.pre_project.actual_plan.total_scope
				let overall_actual =  Math.round(overall_actual_value/actual_scope);
				
				vm.gauge_meter_a.plan.push(overall_plan);
				vm.gauge_meter_a.actual.push(overall_actual);
				vm.gauge_meter_a.plot_bands = vm.getPlotBands(overall_actual, overall_plan);
				
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		getPlotBands(actual, plan) {
			let plot_bands = [];
			let plot_band_calculation = (actual / plan) * 100;
			let plot_band_to = "";
			let plot_band_color = "";
			if (plan[0] <= actual[0]) {
				plot_band_to = parseInt(actual[0]);
				plot_band_color = "green";
			} else if (plot_band_calculation < 75) {
				plot_band_to = parseInt(plot_band_calculation);
				plot_band_color = "green";
			} else if (plot_band_calculation >= 75 && plot_band_calculation < 89) {
				plot_band_to = parseInt(plot_band_calculation);
				plot_band_color = "green";
			} else if (plot_band_calculation >= 89 && plot_band_calculation <= 100) {
				plot_band_to = parseInt(plot_band_calculation);
				plot_band_color = "green";
			}
			
			plot_bands.push(
				{
					from: 0,
					to: actual,
					color: plot_band_color,
					thickness: 15,
				},
				{
					from: actual,
					to: 100,
					color: "#C0C0C0",
					thickness: 15,
				}
			);
			if(actual > plan){
				
				plot_bands.push(                        
					{
						from: 0,
						to: actual,
						color: "green",
						thickness: 15,
					}
				)
			}
			else{
				plot_bands.push(                        
					{
						from: actual,
						to: plan,
						color: "black",
						thickness: 15,
					}
				)
			}
			return plot_bands;
		},
		discardLocalStorage(project_group){
			localStorage.clear()
			this.getProjects(project_group)
		}
	},

};
</script>

<style scoped>
.background {
	border: 2px solid #000 !important;
	box-shadow: 5px 5px 10px #fc894d;
}
</style>