<template>
    <div class="card">
        <div class="card-header">
            <strong>{{ project.project_name }}</strong>
        </div>
        <div class="card-body">
            <div class="row">
                <Project :project="project"/>
                <div class="col-12">
                    <div>
                        <project-update class="item" :item="project" @make-folder="makeFolder" @add-item="addItem" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ProjectUpdate from "@/components/tree/ProjectUpdate.vue";
    import Project from '@/components/Project'
    export default {
        components: { ProjectUpdate, Project },
        data() {
            return {
                treeData: [],
                project_activity_groups:[],
                project:{
                    project_id:'',
                    type:'',
                    is_manual: false
                }
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                let loader = vm.$loading.show()
                vm.project.project_id = to.params.project_id
                let uri = { uri: "getProjectPMTEdit", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide()
                        vm.project = response.data.data;
                        console.log("project", vm.project)
                        // vm.getTemplate()
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            });
        },
        watch: {
            "$store.getters.template_item": function () {
                console.log(this.$store.getters.template_item)
                this.project = this.$store.getters.template_item                
            },
        },
        mounted() {
        },
        methods:{
            getTemplate(){
                let vm = this
                let uri = { uri: "getTemplate", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        // console.log('get-template',response.data.data);
                        vm.treeData = response.data.data
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        }

    };
</script>
