<template>
    <div class="">
        <h2 class="main-title mb-3">Roles</h2>
        <div class="row g-3">
            <div class="col-md-4" v-can="'roles.create'">
                <form @submit.prevent="submitForm()">
                    <div class="card">
                        <div class="card-header">
                            <strong v-if="status">New Role</strong>
                            <strong v-else>Update Role</strong>
                        </div>
                        <div class="card-body mb-3">
                            <div class="row g-3">
                                <div class="col-sm-12">
                                    <label class="form-label">Role Code</label><span class="text-danger"> *</span>
                                    <input type="text" placeholder="Role Code" class="form-control" :class="{'is-invalid': errors.role_code}" v-model="role.role_code" ref="role_code" />
                                    <span v-if="errors.role_code" class="invalid-feedback">{{ errors.role_code[0] }}</span>
                                </div>
                                <div class="col-sm-12">
                                    <label class="form-label">Role Group</label><span class="text-danger"> *</span>
                                    <select class="form-control" :class="{ 'is-invalid': errors.role_group_id }" v-model="role.role_group_id">
                                        <option value="">Select Role Group</option>
                                        <option v-for="(role_group,key) in role_groups" :key="key" :value="role_group.role_group_id">{{ role_group.role_group_name }}</option>
                                    </select>
                                    <span v-if="errors.role_group_id" class="invalid-feedback">{{ errors.role_group_id[0] }}</span>
                                </div>
                                <div class="col-sm-12">
                                    <label class="form-label">Role</label><span class="text-danger"> *</span>
                                    <input type="text" placeholder="Role" class="form-control" :class="{'is-invalid': errors.role_name}" v-model="role.role_name" />
                                    <span v-if="errors.role_name" class="invalid-feedback">{{ errors.role_name[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-end">
                            <button type="reset" class="btn btn-danger me-2" @click="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                            <button type="submit" class="btn btn-primary" v-if="status"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
                            <button type="submit" class="btn btn-primary" v-else><i class="ri-save-line icon_hgt"></i> UPDATE</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-8" v-can="'roles.view'">
                <div class="card">
                    <div class="card-header ">
                        <strong>Roles</strong>
                        
                    </div>
                    <div class="card-body">
                        <div class="form-group mb-2 d-flex">
                            <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search()" />
                            <a @click="deleteValues()" v-if="delete_values!= 0" href="javascript:void(0)" class="btn btn-sm btn-primary ms-2 "><i class="ri-delete-bin-line icon_hgt"></i></a>
                        </div>
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th @click="sort('role_code')">
                                            Role Code
                                            <span>
                                                <i v-if="meta.keyword=='role_code' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword=='role_code' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th @click="sort('role_group_id')">
                                            Role Group
                                            <span>
                                                <i v-if="meta.keyword=='role_group_id' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword=='role_group_id' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th @click="sort('role_name')">
                                            Role
                                            <span>
                                                <i v-if="meta.keyword=='role_name' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                                <i v-else-if="meta.keyword=='role_name' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                                <i v-else class="fas fa-sort"></i>
                                            </span>
                                        </th>
                                        <th class="text-center" v-can="'roles.delete'">Status</th>
                                        <th class="text-center" v-can="'roles.update'">Actions</th>
                                        <th class="text-center"><input type="checkbox" :checked="isAllSelected" @change="selectAll"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="roles.length==0">
                                        <td colspan="7" class="text-center">No records found</td>
                                    </tr>
                                    <tr v-for="(role,key) in roles" :key="key">
                                        <td class="text-center">{{meta.from+key}}</td>
                                        <td>{{role.role_code}}</td>
                                        <td>{{role.role_group?.role_group_name}}</td>
                                        <td>{{role.role_name}}</td>
                                        <td class="text-center" v-can="'roles.delete'">
                                            <div class="form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" :id="'role_group' + role.role__id" :checked="role.status" :value="role.status" @change="deleteRole(role)" />
                                                <label class="custom-control-label" :for="'role' + role.role_id"></label>
                                            </div>
                                        </td>
                                        <!-- <td class="text-center">
                                            <div class="form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" />
                                            </div>
                                        </td> -->
                                        <td class="text-center" v-can="'roles.update'">
                                            <nav>
                                                <a href="javascript:void(0)" class="text-success me-2" @click.prevent="edit(role)" :disabled="!role.status"><i class="ri-pencil-line icon_hgt"></i></a>
                                                <!-- <a href="javascript:void(0)" class="text-danger" @click.prevent="trash(role)"><i class="ri-delete-bin-line icon_hgt"></i></a> -->
                                            </nav>
                                        </td>
                                        <td class="text-center"><input type="checkbox" v-model="delete_values" :value="role.role_id" @change="select"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                            </select>
                            <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>

                            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "role_group_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                status: true,
                roles: [],
                errors: [],
                role_groups: [],
                isAllSelected: false,
                delete_values: [],
                role: {
                    role_id: "",
                    role_code: "",
                    role_group_id: "",
                    role_name: "",
                },
            };
        },
        mounted() {
            if(this.$store.getters?.user){
                this.index();
            }
            
        },
        methods: {
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateRoles", data: vm.meta })
                    .then(function (response) {
                        vm.roles = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                        vm.getRoleGroups();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.addRole();
                } else {
                    vm.updateRole();
                }
            },
            addRole() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addRole", data: vm.role })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Role Added successfully");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            edit(role) {
                let vm = this;
                vm.$refs.role_code.focus();
                vm.errors = [];
                vm.status = false;
                vm.role = role;
            },
            updateRole() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateRole", data: vm.role };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Role is successfully updated");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
                let vm = this;
                vm.role.role_code = "";
                vm.role.role_group_id = "";
                vm.role.role_name = "";
                vm.$refs.role_code.focus();
                vm.errors = [];
                vm.status = true;
                this.isAllSelected = false
                this.delete_values = []
                vm.index();
            },
            getRoleGroups() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getRoleGroups" })
                    .then(function (response) {
                        vm.role_groups = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            deleteRole(role) {
                let vm = this;
                let loader = vm.$loading.show();
                role.status = role.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deleteRole", data: role })
                    .then(function (response) {
                        loader.hide()
                        vm.$store.dispatch("success", response.data.message);
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide()
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            confirmDelete(role) {
                this.confirm.role_name = role.role_name;
                this.confirm.role_id = role.role_id;
            },

            trash(role) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    vm.$store
                        .dispatch("post", { uri: "forceDeleteRole", data: role })
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Role is deleted successfully");
                            vm.discard();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            selectAll() {
                if (this.isAllSelected) {
                    this.delete_values = [];
                    this.isAllSelected = false;
                } else {
                    this.delete_values = [];
                    for (var role in this.roles) {
                        this.delete_values.push(this.roles[role].role_id);
                    }
                    this.isAllSelected = true;
                }
            },
            select() {
                if (this.delete_values.length !== this.roles.length) {
                    this.isAllSelected = false;
                } else {
                    this.isAllSelected = true;
                }
            },
            deleteValues() {
                confirm("Are You Sure Want to delete?");
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "multipleDeleteRoles", data:{role_id: this.delete_values}})
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Role is successfully deleted.");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
        },
    };
</script>
