<template>
    <div class="pb-3">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Daily Progress (DPR)</strong>
            </div>

            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                         <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="dpr.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Project</label>
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                           :initialize="dpr.project_id"
                           id="project_id"
                           label="project_name"
                           placeholder="Select Project"
                           :data="projects"
                           @input="project => dpr.project_id = project"
                       >
                       </search>
                        <span v-if="errors.project_id"><small class="text-danger">{{ errors.project_id[0] }}</small></span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Date</label>
                        <div class="input-group date" id="date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.job_date_time }" v-model="dpr.job_date_time" />
                        </div>
                        <span v-if="errors.job_date_time"><small class="text-danger">{{ errors.job_date_time[0] }}</small></span>
                    </div>
                    <div class="col-md-3 ">
                            <label class="form-label"></label>
                            <div class=" mt-1">
                                <div style="float:right">
                                    <button class="btn btn-sm btn-primary me-2 btn-sm " @click="getDailyProgressReport()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                                    <button class="btn btn-sm btn-warning me-2 btn-sm" @click="downloadDailyProgressReport()"><i class="ri-download-line icon_hgt"></i> Download </button>
                                    <button class="btn btn-sm btn-info me-2 btn-sm" @click="sendMailDPR()"><i class="ri-mail-send-line icon_hgt"></i> Send Email </button>
                                </div>
                            <!-- <button class="btn btn-sm btn-success me-2 btn-sm" @click="editDprTemplate()"><i class="ri-pencil-line icon_hgt"></i> Edit </button> -->
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 mb-4 d-flex">
                    <div class="col-sm-6 mb-4">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap table-bordered mb-1">
                                <thead>
                                    <tr>
                                        <td colspan="4" class="fs-10 ft-bold bg-primary bg-opacity-50"> Contractor's Manpower </td></tr>
                                    <tr class="text-center bg-primary bg-opacity-25">
                                        <th class=""> Contractor</th>
                                        <th class=""> Manpower Avail. </th>
                                        <th class=""> Manpower Reqd. </th>
                                        <th class=""> Remarks </th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="project_contractor, key in project_contractors.slice(0, Math.ceil(project_contractors.length/2))" :key="key">
                                        <td class="text-center">{{ project_contractor.contractor }}</td>
                                        <td class="text-right">{{ project_contractor.manpower_available }}</td>
                                        <td class="text-right ">{{ project_contractor.manpower_required }}</td>
                                        <td class=" ">{{ project_contractor.remarks }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm text-nowrap table-bordered mb-1">
                                <thead>
                                    <tr><td colspan="8" class="fs-10 ft-bold bg-primary bg-opacity-50">&nbsp;</td></tr>
                                    <tr class="text-center bg-primary bg-opacity-25">
                                        <th class=""> Contractor</th>
                                        <th class=""> Manpower Avail. </th>
                                        <th class=""> Manpower Reqd. </th>
                                        <th class=""> Remarks </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="project_contractor, key in project_contractors.slice(Math.ceil(project_contractors.length/2), project_contractors.length)" :key="key">
                                        <td class="text-center">{{ project_contractor.contractor }}</td>
                                        <td class="text-right">{{ project_contractor.manpower_available }}</td>
                                        <td class="text-right ">{{ project_contractor.manpower_required }}</td>
                                        <td class=" ">{{ project_contractor.remarks }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 mb-4">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-1">
                            <thead>
                                <tr class="text-center bg-primary bg-opacity-50">
                                    <th >Particulars</th>
                                    <th> Group </th>
                                    <th> UOM </th>
                                    <th> Scope </th>
                                    <th> Cu. Dwg. Avail. </th>
                                    <th> CTD (act) </th>
                                    <th> Target FTM </th>
                                    <th> MTD </th>
                                    <th> Target FTD </th>
                                    <th> FTD </th>
                                    <th> Asking Rate </th>
                                </tr>
                            </thead>
                            <tbody v-for="civil_activity, civil_activity_key in civil_activities.civil_activities" :key="civil_activity_key">
                                <tr v-if="civil_activity_key==0" class="fs-10 ft-bold bg-primary bg-opacity-25" >
                                    <td class="text-center">Civil</td>
                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>

                                </tr>
                                <tr v-if="eliminateZeros(civil_activity)">
                                     <td class="text-center ">{{ civil_activity.activity_name}}</td>
                                     <td class="text-center"></td>
                                     <td class="text-center">{{ civil_activity.parameter.unit_name }}</td>
                                     <td class="text-right">{{ civil_activity.parameter.total_scope }}</td>
                                     <td class="text-right">{{ civil_activity.parameter.cu_dwg_avail }}</td>
                                     <td class="text-right  ">{{ civil_activity.parameter.ctd }}</td>
                                     <td class="text-right  ">{{ civil_activity.parameter.target_ftm }}</td>
                                     <td class="text-right  ">{{ civil_activity.parameter.mtd }}</td>
                                     <td class="text-right  ">{{ civil_activity.parameter.target_ftd }}</td>
                                     <td class="text-right  ">{{ civil_activity.parameter.ftd }}</td>
                                     <td class="text-right  ">{{ parseFloat(civil_activity.parameter.asking_rate.toFixed(2)) }}</td>
                                </tr>
                                
                            </tbody>
                            <tbody v-for="fabrication, fabrication_key in fabrications.fabrications" :key="fabrication_key">
                                <tr v-if="fabrication_key==0" class="fs-10 ft-bold bg-primary bg-opacity-25" >
                                    <td class="text-center">Mech. Fabrication </td>
                                    <td class="text-center">Total</td>
                                    <td class="text-center">MT</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr v-if="eliminateZeros(fabrication)">
                                    <td class="text-center">{{ fabrication.contractor }}</td>
                                    <td class="text-center">{{ fabrication.fabrication }}</td>
                                    <td class="text-center">{{ fabrication.parameter.unit_name }}</td>
                                    <td class="text-right">{{ fabrication.parameter.total_scope }}</td>
                                    <td class="text-right">{{ fabrication.parameter.cu_dwg_avail }}</td>
                                    <td class="text-right">{{ fabrication.parameter.ctd }}</td>
                                    <td class="text-right">{{ fabrication.parameter.target_ftm }}</td>
                                    <td class="text-right">{{ fabrication.parameter.mtd }}</td>
                                    <td class="text-right">{{ fabrication.parameter.target_ftd }}</td>
                                    <td class="text-right">{{ fabrication.parameter.ftd }}</td>
                                    <td class="text-right">{{ parseFloat(fabrication.parameter.asking_rate.toFixed(2)) }}</td>
                                </tr>
                            </tbody>
                            <tbody v-for="erection, erection_key in erections.erections" :key="erection_key">
                                <tr v-if="erection_key==0" class="fs-10 ft-bold bg-primary bg-opacity-25" >
                                    <td class="text-center">Mech Erection </td>
                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr v-if="eliminateZeros(erection)">
                                    <td class="text-center">{{erection.contractor  }}</td>
                                    <td class="text-center">{{ erection.fabrication }}</td>
                                    <td class="text-center">{{ erection.parameter.unit_name }}</td>
                                    <td class="text-right">{{ erection.parameter.total_scope }}</td>
                                    <td class="text-right">{{ erection.parameter.cu_dwg_avail }}</td>
                                    <td class="text-right">{{ erection.parameter.ctd }}</td>
                                    <td class="text-right">{{ erection.parameter.target_ftm }}</td>
                                    <td class="text-right">{{ erection.parameter.mtd }}</td>
                                    <td class="text-right">{{ erection.parameter.target_ftd }}</td>
                                    <td class="text-right">{{ erection.parameter.ftd }}</td>
                                    <td class="text-right">{{ parseFloat(erection.parameter.asking_rate.toFixed(2)) }}</td>
                                </tr>
                            </tbody>
                            <tbody v-for="electrical, electrical_key in electricals.electricals" :key="electrical_key">
                                <tr v-if="electrical_key==0" class="fs-10 ft-bold bg-primary bg-opacity-25" >
                                    <td class="text-center">Electrical</td>
                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                </tr>
                                <tr v-if="eliminateZeros(electrical)">
                                    <td class="text-center">{{ electrical.activity_name  }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">{{ electrical.parameter.unit_name  }}</td>
                                    <td class="text-right">{{ electrical.parameter.total_scope  }}</td>
                                    <td class="text-right">{{ electrical.parameter.cu_dwg_avail  }}</td>
                                    <td class="text-right">{{ electrical.parameter.ctd  }}</td>
                                    <td class="text-right">{{ electrical.parameter.target_ftm  }}</td>
                                    <td class="text-right">{{ electrical.parameter.mtd  }}</td>
                                    <td class="text-right">{{ electrical.parameter.target_ftd  }}</td>
                                    <td class="text-right">{{electrical.parameter.ftd }}</td>
                                    <td class="text-right">{{ parseFloat(electrical.parameter.asking_rate.toFixed(2))  }}</td>
                                </tr>
                            </tbody>
                            <tbody v-for="ci, ci_key in cis.cis" :key="ci_key">
                                <tr v-if="ci_key==0" class="fs-10 ft-bold bg-primary bg-opacity-25" >
                                    <td class="text-center">C&I</td>
                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                </tr>
                                <tr v-if="eliminateZeros(ci)">
                                    <td class="text-center">{{ ci.activity_name }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">{{ ci.parameter.unit_name }}</td>
                                    <td class="text-right">{{ parseFloat(ci.parameter.total_scope.toFixed(2)) }}</td>
                                    <td class="text-right">{{ ci.parameter.cu_dwg_avail }}</td>
                                    <td class="text-right">{{ ci.parameter.ctd }}</td>
                                    <td class="text-right">{{ ci.parameter.target_ftm }}</td>
                                    <td class="text-right">{{ ci.parameter.mtd }}</td>
                                    <td class="text-right">{{ ci.parameter.target_ftd }}</td>
                                    <td class="text-right">{{ci.parameter.ftd}}</td>
                                    <td class="text-right">{{ parseFloat(ci.parameter.asking_rate.toFixed(2)) }}</td>
                                </tr>
                            </tbody>
                            <tbody v-for="sheeting, sheeting_key in sheetings.sheetings" :key="sheeting_key">
                                <tr v-if="sheeting_key==0" class="fs-10 ft-bold bg-primary bg-opacity-25" >
                                    <td class="text-center">Sheeting</td>
                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                </tr>
                                <tr v-if="eliminateZeros(sheeting)">
                                    <td class="text-center">{{ sheeting.activity_name}}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">{{ sheeting.parameter.unit_name}}</td>
                                    <td class="text-right">{{ sheeting.parameter.total_scope}}</td>
                                    <td class="text-right">{{ sheeting.parameter.cu_dwg_avail}}</td>
                                    <td class="text-right">{{ sheeting.parameter.ctd}}</td>
                                    <td class="text-right">{{ sheeting.parameter.target_ftm}}</td>
                                    <td class="text-right">{{ sheeting.parameter.mtd}}</td>
                                    <td class="text-right">{{ sheeting.parameter.target_ftd}}</td>
                                    <td class="text-right">{{sheeting.parameter.ftd}}</td>
                                    <td class="text-right">{{ parseFloat(sheeting.parameter.asking_rate.toFixed(2)) }}</td>
                                </tr>
                            </tbody>
                            <tbody v-for="insulation, insulation_key in insulations.insulations" :key="insulation_key">
                                <tr v-if="insulation_key==0" class="fs-10 ft-bold bg-primary bg-opacity-25" >
                                    <td class="text-center">Insulation</td>
                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                    <td class="text-right"></td>
                                </tr>
                                <tr v-if="eliminateZeros(insulation)">
                                    <td class="text-center">{{insulation.activity_name }}</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">{{insulation.parameter.unit_name }}</td>
                                    <td class="text-right">{{insulation.parameter.total_scope }}</td>
                                    <td class="text-right">{{insulation.parameter.cu_dwg_avail }}</td>
                                    <td class="text-right">{{insulation.parameter.ctd }}</td>
                                    <td class="text-right">{{insulation.parameter.target_ftm }}</td>
                                    <td class="text-right">{{insulation.parameter.mtd }}</td>
                                    <td class="text-right">{{insulation.parameter.target_ftd }}</td>
                                    <td class="text-right">{{insulation.parameter.ftd}}</td>
                                    <td class="text-right">{{parseFloat(insulation.parameter.asking_rate.toFixed(2)) }}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr class="fs-10 ft-bold bg-primary bg-opacity-25" v-if="eliminateZeros(refractories.total_refractory)">
                                    <td class="text-center">Refractory</td>
                                    <td class="text-center"></td>
                                    <td class="text-center">{{refractories.total_refractory?.unit_name}}</td>
                                    <td class="text-right">{{refractories.total_refractory?.total_scope}}</td>
                                    <td class="text-right">{{refractories.total_refractory?.cu_dwg_avail}}</td>
                                    <td class="text-right">{{refractories.total_refractory?.ctd}}</td>
                                    <td class="text-right">{{refractories.total_refractory?.target_ftm}}</td>
                                    <td class="text-right">{{refractories.total_refractory?.mtd}}</td>
                                    <td class="text-right">{{refractories.total_refractory?.target_ftd}}</td>
                                    <td class="text-right">{{refractories.total_refractory?.ftd}}</td>
                                    <td class="text-right">{{refractories.total_refractory?.asking_rate}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>                
                <div class="col-sm-12 mb-4">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-1">
                            <thead>
                                <tr class="fs-10 ft-bold bg-primary bg-opacity-50">
                                    <td class="" colspan="11">Belt Conveyor</td>
                                </tr>
                                <tr class="text-center bg-primary bg-opacity-25">
                                    <th class=""> Conveyor </th>
                                    <th class=""> Civil Drawing </th>
                                    <th class=""> Mech. Drawing </th>
                                    <th class=""> No. of Foundation </th>
                                    <th class=""> Foundation Casting Done </th>
                                    <th class=""> No. of Trestle </th>
                                    <th class=""> Trestle Fab. Done </th>
                                    <th class=""> Trestle Erect. Done </th>
                                    <th class=""> No. of Gallery </th>
                                    <th class=""> Gallery Fab. Done </th>
                                    <th class=""> Gallery Erec. Done </th>
                                </tr>
                            </thead>    
                            <tbody >
                                <tr v-for="belt_conveyor, key in belt_conveyors" :key="key">
                                    <td class="text-center">{{belt_conveyor.activity}}</td>
                                    <td v-for="parameter, parameter_key in belt_conveyor.job_parameters" :key="parameter_key" class="text-right">{{parameter.parameter_value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-sm-12 mb-4">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-1">
                            <tbody>
                                <tr>
                                    <th width="10%">Concern</th>
                                    <td width="90%">
                                        <input type="text" class="form-control" placeholder="Concern" :class="{'is-invalid':errors.concern}" v-model="daily_progress_report.concern">
                                    <span v-if="errors.concern" invalid-feedback>{{ errors.concern[0] }}</span></td>
                                </tr>
                                <tr>
                                    <th>Highlight</th>
                                    <td><input type="text" class="form-control" placeholder="Highlight" :class="{'is-invalid':errors.highlight}" v-model="daily_progress_report.highlight">
                                    <span v-if="errors.highlight" invalid-feedback>{{ errors.highlight[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Remarks</th>
                                    <td><textarea class="form-control" rows="2" placeholder="Remarks" :class="{'is-invalid':errors.remarks}" v-model="daily_progress_report.remarks"></textarea>
                                    <span v-if="errors.remarks" invalid-feedback>{{ errors.remarks[0] }}</span>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="text-end">
                                    <td colspan="2">
                                        <button class="btn btn-danger me-2">Discard</button>
                                        <button class="btn btn-primary" @click="update()">Submit</button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let Search = require("@/components/Search.vue").default;
import Table from '@/components/Table.vue';
import moment from 'moment';
    export default {
  components: { Table, Search},
        name: "DailyProgress",
        data() {
            return {
                initial:true,
                daily_progress_report:{
                    daily_progress_report_id:'',
                    project_id:'',
                    job_date_time:'',
                    concern:'',
                    highlight:'',
                    remarks:'',
                    isDownload:false
                },
                dpr:{
                    project_group_id:'',
                    project_id:'',
                    job_date_time:''
                },
                project_contractors:[],
                civil_activities:{
                    civil_activities:[],
                    total_civil:[]
                },
                fabrications:{
                    fabrications:[],
                    total_fabrication:[]
                },
                erections:{
                    erections:[],
                    total_erection:[]
                },
                electricals:{
                    electricals:[],
                    total_electrical:[]
                },
                cis:{
                    cis:[],
                    total_ci:[]
                },
                refractories:{
                    refractories:[],
                    total_refractory:[]
                },
                sheetings:{
                    sheetings:[],
                    total_sheeting:[]
                },
                insulations:{
                    insulations:[],
                    total_insulation:[]
                },
                belt_conveyors:[],
                
                contractor:{
                    contractor_name:'',
                    project_activity_id:''
                },
                activity_group:{
                    activity_group_id:'',
                    contractor_name:'',
                    activities:[]
                },
                projectgroups:[],
                projects:[],
                // date:'',
                errors:[]
            }
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectGroups()
            }
            this.dpr.job_date_time = moment().format('yyyy-MM-DD')
        },

        methods: {
            eliminateZeros(activity){
                if(activity.parameter?.cu_dwg_avail || activity.parameter?.ctd){
                    return activity
                }else{
                    return false
                }
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.dpr.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.dpr.project_group_id) {
                                vm.dpr.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjects() {
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects", data: vm.dpr })
                    .then(function (response) {
                        vm.projects = response.data;
                        if(localStorage.getItem('project_id')){
                            vm.dpr.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.dpr.project_id = vm.projects[0].project_id;
                        }
                        if(vm.dpr.project_id){
                            vm.getDailyProgressReport()
                            vm.initial = false
                        }
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            update() {
			let vm = this;
			let loader = vm.$loading.show();
			let uri = { uri: "updateDailyProgressReport", data: vm.daily_progress_report };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Daily Progress Report is successfully updated");
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		    },

            getBeltConveyor(){
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getBeltConveyors" })
                    .then(function (response) {
                        vm.belt_conveyors = response.data.data
                    })
                    .catch(function (error) {
                        console.log(error)
                        vm.errors = error.response.data.errors;
					    vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            
            getDailyProgressReport(){
                let vm = this
                let loader = vm.$loading.show();
                vm.errors=[];
                vm.$store
                    .dispatch("post", { uri: "getDailyProgressReport", data: vm.dpr })
                    .then(function (response) {
                        loader.hide();
                        vm.project_contractors=response.data.data.project_contractors;
                        vm.civil_activities.civil_activities=response.data.data.civil_activities;
                        vm.fabrications.fabrications=response.data.data.fabrications;
                        vm.erections.erections=response.data.data.erection;
                        vm.electricals.electricals=response.data.data.electrical;
                        vm.cis.cis=response.data.data.ci;

                        vm.sheetings.sheetings=response.data.data.sheeting;
                        vm.insulations.insulations=response.data.data.insulation;
                        vm.belt_conveyors = response.data.data.belt_conveyor;

                        vm.daily_progress_report.concern = response.data.data.concern;
                        vm.daily_progress_report.highlight = response.data.data.highlight;
                        vm.daily_progress_report.remarks = response.data.data.remarks;
                        vm.dpr.dpr_contractors = response.data.data.dpr_contractors
                        vm.dpr.dpr_activity_groups = response.data.data.dpr_activity_groups

                        vm.civil_activities.total_civil=response.data.data.total_civil;
                        vm.electricals.total_electrical=response.data.data.total_electrical;
                        vm.cis.total_ci=response.data.data.total_ci;
                        vm.refractories.total_refractory=response.data.data.total_refractory;
                        vm.sheetings.total_sheeting=response.data.data.total_sheeting;
                        vm.insulations.total_insulation=response.data.data.total_insulation;
                        vm.daily_progress_report = response.data.data
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log("error", error.response)
                        vm.errors = error.response?.data
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            sendMailDPR(){
                let vm = this
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "downloadDPR", data: vm.daily_progress_report })
                    .then(function () {
                        loader.hide()
                        vm.$store.dispatch("success", "DPR Report - Mail sent successfully");
                    })
                    .catch(function (error) {
                        loader.hide()
                        if(error.response.data.length){
                            console.log(error.response.data[1])
                            vm.$store.dispatch("error", error.response.data[1]);
                        }
                    }
                );
            },

            downloadDailyProgressReport(){
                let vm = this;
                vm.errors = [];
                if (vm.dpr.job_date_time == ""){
                    vm.errors.job_date_time = ["The job data time field is required."]
                }
                if (vm.dpr.project_id == ""){
                    vm.errors.project_id = ["The project id field is required."]
                }
                if (vm.dpr.job_date_time && vm.dpr.project_id){
                    console.log("success")
                    window.open(vm.$store.getters.apiUrl+'downloadDPR?project_id='+vm.dpr.project_id+'&job_date_time='+vm.dpr.job_date_time+'&isDownload=true');
                }
            },
            editDprTemplate() {
                this.$router.push('/dpr_template/' + this.daily_progress_report.daily_progress_report_id + '/edit')
            },
        }
    }
</script>

<style scoped>
    .text-right{
        text-align: right;
    }
    .fs-12 {
        font-size: 12pt;
    }
    .fs-14{
        font-size: 14pt;
    }
    .ft-bold{
        font-weight: bold;
    }
    /* .bg-primary bg-opacity-50{
        background-color: #c6b505;
        background-color: #91a3e0;
        color:rgb(34, 33, 33);

    
    } */
    /* .bg-primary bg-opacity-25{
        background-color: #daca24;
        background-color: #dce2f7;
        color:black;
    } */
    .dropdown {
        position: relative;
        cursor: pointer;
    }
    .multiselect {
        position: relative;
    }
    .multiselect ul {
        border: 1px solid #ddd;
        border-top: 0;
        border-radius: 0 0 3px 3px;
        left: 0px;
        padding: 8px 8px;
        top: -0.1rem;
        width: 100%;
        list-style: none;
        max-height: 150px;
        overflow: auto;
        background: white;
    }
    .overselect {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>