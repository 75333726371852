<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">
					<router-link to="/dashboard">Dashboard</router-link>
				</li>
				<li class="breadcrumb-item active" aria-current="page">Projects</li>
			</ol>
			<h4 class="main-title mb-0">Budget Request</h4>
		</div>
		<div class="d-flex align-items-center gap-2 mt-3 mt-md-0">
			<router-link to="/projects" class="btn btn-secondary d-flex align-items-center gap-2" v-can="'projects.view'">
				<i class="ri-arrow-left-s-line fs-18 lh-1"></i>Projects
			</router-link>
			<router-link to="/project_cost/pending_request" class="btn btn-success d-flex align-items-center gap-2" v-can="'budget_requests.view'">
				<i class="ri-list-check fs-18 lh-1"></i>Budget Request List
			</router-link>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-sm-12">
			<div class="card card-one">
				<div class="card-header d-flex justify-content-between align-items-center">
					<h6 class="card-title">New Budget Request</h6>
					<select class="form-select" style="float: right; width: 115px;" v-model="currency_unit">
						<option value="1">Rupees</option>
						<option value="100000">Lakh</option>
						<option value="10000000">Cr</option>
					</select>			
				</div>
				<div class="card-body">
					<div class="row g-2 mb-1">
						<Project :project="project"/>
						<div class="col-md-12">
							<div class="form-group">
								<div class="row">
									<div class="col-sm-12">
										<input class="form-control" type="text" placeholder="Type keyword and press enter key" v-model="meta.search" @keypress.enter="search()"/>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th>Level</th>
											<th>WBS Code</th>
											<th>WBS Description</th>
											<!-- <th class="text-center">UOM</th> -->
											<!-- <th class="text-center" v-can="'budget_requests.view.approved_budget'">
												Approved Budget
											</th>
											<th v-can="'budget_requests.view.site_request'">
												Site Request
											</th>											
											<th v-can="'budget_requests.view.direct_hq_request'">
												Direct HQ Request
											</th>
											<th v-can="'budget_requests.view.hq_approved'">
												HQ Approved
											</th>
											<th v-can="'budget_requests.view.allocated_in_sap'">
												Allocated in SAP
											</th>
											<th v-can="'budget_requests.view.request_balance'">
												Request Balance
											</th> -->
											<!-- <th v-can="'budget_requests.view.commit'">
												Commit
											</th> -->
											<!-- <th v-can="'budget_requests.view.actual_spent'">
												Actual Spent
											</th>
											<th v-can="'budget_requests.view.projected_commitment'">
												Projected Commitment
											</th>
											<th v-can="'budget_requests.view.savings'">
												Savings
											</th>
											<th v-can="'budget_requests.view.manual_commit'">
												Manual Commit
											</th>
											<th v-can="'budget_requests.view.sap_committed'">
												SAP Committed
											</th>
											<th v-can="'budget_requests.view.withdrawal_balance'">
												Withdrawal Balance
											</th> -->
											<!-- <th class="text-center" v-can="'budget_requests.view.status'">
												Status
											</th> -->
											<th class="text-center" v-can="'budget_requests.create'">
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="project_cost, key in project.project_cost" :key="key">
											<td class="text-center">{{ meta.from + key }}</td>
											<td class="text-center">{{ project_cost.level }}</td>
											<td>{{ project_cost.wbs_code }}</td>
											<td>{{ project_cost.wbs_description }}</td>
											<!-- <td class="text-center">
												{{ project_cost.cost_type?.cost_type_code }}
											</td> -->
											<!-- <td class="text-center" v-can="'budget_requests.view.approved_budget'">
												{{ formatCurrency(project_cost.budget) }}
											</td>
											<td class="text-center" v-can="'budget_requests.view.site_request'">
												{{ formatCurrency(project_cost.site_request) }}
											</td>											
											<td class="text-center" v-can="'budget_requests.view.direct_hq_request'">
												{{ formatCurrency(project_cost.direct_hq_request) }}
											</td>
											<td class="text-center" v-can="'budget_requests.view.hq_approved'">
												{{ formatCurrency(project_cost.hq_approved) }}
											</td>
											<td class="text-center" v-can="'budget_requests.view.allocated_in_sap'">
												{{ formatCurrency(project_cost.allocated_in_sap) }}
											</td>
											<td class="text-center" v-can="'budget_requests.view.request_balance'">
												{{ formatCurrency(project_cost.request_balance) }}
											</td> -->
											<!-- <td class="text-center" v-can="'budget_requests.view.commit'">
												<input class="form-check-input" type="checkbox" :value="project_cost.project_cost_id" v-model="commits" v-show="project_cost.hq_approved">
											</td> -->
											<!-- <td v-can="'budget_requests.view.actual_spent'">
												<input type="number" class="form-control form-control-sm" @blur="addSpent(project_cost)" placeholder="Spent" v-model="project_cost.actual_spent">
											</td>
											<td v-can="'budget_requests.view.projected_commitment'">
												<input type="number" class="form-control form-control-sm" placeholder="Projected Commitment" v-model="project_cost.projected_commitment">
											</td>
											<td class="text-center" v-can="'budget_requests.view.savings'">
												{{ project_cost.savings }}
											</td>
											<td v-can="'budget_requests.view.manual_commit'">
												<input type="number" class="form-control form-control-sm" placeholder="Manual Commit" v-model="project_cost.manual_commit">
											</td>
											<td v-can="'budget_requests.view.sap_committed'">
												<input type="number" class="form-control form-control-sm" placeholder="SAP Committed" v-model="project_cost.sap_committed">
											</td>
											<td class="text-center" v-can="'budget_requests.view.withdrawal_balance'">
												{{ project_cost.withdrawal_balance }}
											</td> -->
											<!-- <td class="text-center" v-can="'budget_requests.view.status'">
												<span :class="showStatus(project_cost)"></span>
											</td> -->
											<td class="text-center" v-can="'budget_requests.create'">
												<button class="btn btn-success btn-sm" @click.prevent="request(project_cost)">
													<i class="ri-mark-pen-line"> </i>Request
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Project from '@/components/Project'
import Pagination from "@/components/Pagination.vue";
export default {
	components: { Project, Pagination },
	data() {
		return {
			meta: {
				level: '6',
				search: "",
				order_by: "asc",
				keyword: "project_cost_id",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				to: 1,
				maxPage: 1,
				trashed: false,
				project_id: ''
			},
			errors: [],
			error: {},
			project: {
				project_id: "",
				project_cost: [],
				last_stage: {}
			},
			project_cost: {},
			show_modal: 'modal fade',
			stages: [],
			next_stages: [],
			approvals: [],
			submit_disabled: false,
			project_requests: [],
			project_request_stages: [],
			project_request_stage: {},
			spents: [],
			commits:[],
			currency_unit:1
		};
	},
	mounted() {
		// this.index();
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.project.project_id = to.params.project_id;
			vm.meta.project_id = to.params.project_id;
			vm.getProject();
			// vm.index();
			// vm.getStages();
		});
	},

	computed: {},

	methods: {
		index() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "paginateProjectCosts", data: vm.meta })
				.then(function (response) {
					vm.project.project_cost = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.to = response.data.meta.to;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
					// vm.prepareProjectData()
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		search() {
			let vm = this;
			vm.meta.page = 1;
			vm.index();
		},

		onPageChange(page) {
			this.meta.page = page;
			this.index();
		},

		onPerPageChange() {
			this.meta.page = 1;
			this.index();
		},

		addRequestApproval() {
			let vm = this;
			let data = {
				'project_id': this.project.project_id,
				'project_cost_id': this.project_cost.project_cost_id,
				'amount': this.project_cost.amount,
				'note': this.project_cost.note,
				'approvals': this.approvals
			}
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'addRequestApproval', data: data })
				.then(response => {
					loader.hide();
					vm.getProject();
					vm.getStages();
					vm.$store.dispatch('success', response.data.message);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				})
		},

		onFileSelect(e, stage, key) {
			let vm = this;
			var reader = new FileReader();
			if (!e.target.files[0]) {
				this.submit_disabled = true;
				stage.attachment = '';
				return;
			}
			reader.readAsDataURL(e.target.files[0]);
			reader.onload = function () {
				stage.attachment = reader.result;
				vm.setApprovals(stage, key);
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};
		},

		selected(stage) {
			if (!stage.selected) {
				this.submit_disabled = true;
			}
			else {
				this.submit_disabled = false;
			}
		},

		onChange(stage, key) {
			this.setApprovals(stage, key);
		},

		setApprovals(stage, key) {
			if (stage.approved_by == '' || stage.attachment == '') {
				this.submit_disabled = true;
				return;
			}
			else {
				this.submit_disabled = false;
				let a = {
					approved_by: stage.approved_by,
					attachment: stage.attachment
				};
				const obj = Object.assign({}, a);
				this.approvals[key] = obj;
			}
		},

		getStages() {
			let vm = this;
			this.$store.dispatch('post', { uri: 'getStages' })
				.then(response => {
					// this.stages = response.data.data;
					// this.getNextStages();

				})
				.catch(error => {
					// vm.errors = error.response.data.errors;
					// vm.$store.dispatch("error", error.response.data.message);
				})
		},

		getNextStages() {
			const role_id = this.$store.getters.user.role_id;
			let obj = this.stages.find(o => o.role.role_id == role_id);
			let stage = '';
			if (obj) {
				stage = obj.stage;
			}
			let next_stages = [];
			while (true) {
				stage++;
				const obj = this.stages.find(o => o.stage == stage);
				if (obj) {
					obj.approved_by = '';
					obj.attachment = '';
					next_stages.push(obj);
				}
				else {
					break;
				}
			}
			this.next_stages = next_stages;
		},

		request(project_cost) {
			this.project_cost = project_cost;
			this.$router.push('/BATS/' + this.project.project_id + '/' + project_cost.project_cost_id + '/request');
		},

		getProject() {
			let vm = this
			vm.project.type = "bats";
			let uri = { uri: "getProject", data: vm.project };
			vm.$store
				.dispatch("post", uri)
				.then(function (response) {
					vm.project = response.data.data;
					vm.index();
				})
				.catch(function (error) {
				});
		},
		// prepareProjectData() {
		//     this.project.project_cost.forEach(ele => {
		//         // ele.balance = 0;
		//         // if (ele.budget != null) {
		//         //     ele.balance = ele.budget - (ele.manual_commit_amount + ele.sap_commit_amount + ele.direct);
		//         // }
		//         // if (ele.total_spent) {
		//         //     ele.forcast = ele.balance - ele.total_spent;
		//         // }
		//         ele.forcast = ele.balance;
		//         ele.savings = ele.balance - ele.forcast;
		//         ele.project_code_partial = '';
		//         let project_code_arr = ele.project_code.split('-');
		//         for (let i = 7; i < project_code_arr.length; i++) {
		//             if (ele.project_code_partial == '') {
		//                 ele.project_code_partial = project_code_arr[i];
		//             }
		//             else {
		//                 ele.project_code_partial = ele.project_code_partial + '-' + project_code_arr[i];
		//             }
		//         }
		//     })
		// },
		createRequest() {
			let vm = this;
			let uri = { uri: "addProjectRequest", data: vm.project_cost };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					vm.getProject()
					vm.$store.dispatch("success", "Request submitted successfully");
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		showRequests() {
			this.$router.push('/project_cost/' + this.project.project_id + '/request')
		},

		addSpent(project_cost) {
			let vm = this;
			let total = project_cost.actual_spent + parseFloat(project_cost.actual_spent);
			// console.log(project_cost);
			// if (total > project_cost.sap_commit_amount) {
			//     this.$store.dispatch('error', 'Total spent is more than commited!');
			//     return;
			// }
			let uri = {
				uri: 'addSpent',
				data: {
					project_cost_id: project_cost.project_cost_id,
					actual_spent: total
				}
			};
			this.$store.dispatch('post', uri)
				.then(response => {
					this.getProject();
				})
				.catch(error => {
					vm.error = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				})
		},

		getSpents(project_cost) {
			let vm = this;
			this.$store.dispatch('post', { uri: 'getSpents', data: { project_cost_id: project_cost.project_cost_id } })
				.then(response => {
					this.spents = response.data.data;
					document.getElementById('spent_total').innerHTML = project_cost.total_spent;
				})
				.catch(error => {
					vm.error = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		showStatus(project_cost) {
			// console.log(project_cost);
			if (project_cost.new_request_amount != 0) {
				return 'red_dot';
			}
			if (project_cost.manual_commit_amount != 0) {
				return 'orange_dot';
			}
			if (project_cost.sap_commit_amount != 0) {
				return 'green_dot';
			}
			return 'gray_dot';
		},

		setManualCommit() {
			if (!confirm('Do you realy want to proceed with commit?')) {
				project_cost.manual_commit = false;
				return;
			}
			let project_request_ids = [];
			let manual_commits = this.project.project_cost.filter(ele => {
				return ele.manual_commit == true;
			});

			manual_commits.forEach(ele => {
				ele.project_request.forEach(pr => {
					if (pr.status == 'Approved' || pr.status == 'Direct') {
						project_request_ids.push(pr.project_request_id);
					}
				})
			})

			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'commitRequest', data: { project_request_ids: project_request_ids } })
				.then(response => {
					loader.hide();
					this.$store.dispatch('success', response.data.message);
					vm.getProject();
				})
				.catch(error => {
					loader.hide();
					vm.error = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		formatCurrency(amount) {
			amount = amount / this.currency_unit;
			if (this.currency_unit == 1) {
				return amount.toLocaleString("en-IN", { maximumFractionDigits: 0, style: "currency", currency: "INR" });
			}
			else if (this.currency_unit == 100000) {
				return amount.toLocaleString("en-IN", { maximumFractionDigits: 2, style: "currency", currency: "INR" });
			}
			else {
				return amount.toLocaleString("en-IN", { maximumFractionDigits: 2, style: "currency", currency: "INR" });
			}
		},
	},
};
</script>