<template>
    <div class="chat-body-header">
        <div class="chat-item">
            <div class="chat-item-body">
                <h6 class="mb-1">{{ title }}</h6>
            </div>
        </div>
    </div>
    <div id="chatBodyContent" class="chat-body-content">
        <div class="row mb-3">
            <div class="col-sm-5">
                <label for="code">Code</label>
                <input type="text" placeholder="Code" id="code" class="form-control form-control-sm" />
            </div>
            <div class="col-sm-5">
                <label for="name">Name</label>
                <input type="text" placeholder="Name" id="name" class="form-control form-control-sm" />
            </div>
            <div class="col-sm-2 mt-auto">
                <div style="text-align:right">
                    <button type="button" class="btn btn-sm btn-outline-danger me-2" id="discard"><i
                            class="ri-delete-bin-2-line"></i></button>
                    <button type="button" class="btn btn-sm btn-outline-primary" id=""><i
                            class="ri-add-box-line"></i></button>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-12">
                <label for="code">Search</label>
                <input type="text" placeholder="Search" id="code" class="form-control form-control-sm" />
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center">#</th>
                        <th scope="col" v-for="column, key in columns" :key="key">{{ column }}</th>
                        <th class="text-center" colspan="3">Action</th>
                        <th>
                            <div class="form-check">
                                <input v-model="select_all" class="form-check-input mt-2 ms-1" type="checkbox"
                                    @change="selectAll" value="" id="select_all">
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="" v-for="field in fields" :key="field.id">
                        <td class="text-center" scope="row">{{ field.id }}</td>
                        <td>{{ field.code }}</td>
                        <td>{{ field.name }}</td>
                        <td class="text-center">
                            <div class="form-switch">
                                <input class="form-check-input mt-2" type="checkbox" role="switch" id="ck1" checked="">
                            </div>
                        </td>
                        <td class="text-center">
                            <button type="button" style="border-radius: 50%;" class="btn btn-sm btn-outline-primary">
                                <i class="ri-pencil-line"></i>
                            </button>
                        </td>
                        <td class="text-center">
                            <button type="button" style="border-radius: 50%;" class="btn btn-sm btn-outline-danger">
                                <i class="ri-delete-bin-line"></i>
                            </button>
                        </td>
                        <td class="text-center">
                            <div class="form-check">
                                <input class="form-check-input mt-2 ms-1" type="checkbox" :id="'cb_'+field.id" @change="deSelect">{{ 'cb_'+field.id }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="chat-body-footer">
        <div class="row">
            <div class="col-sm-4 offset-8">
                <ul class="pagination mb-0">
                    <li class="pagination-item">
                        <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage">
                            First
                        </button>
                    </li>

                    <li class="pagination-item">
                        <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage">
                            Previous
                        </button>
                    </li>

                    <li v-for="(page, key) in pages" class="pagination-item" :key="key">
                        <button type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled"
                            :class="{ active: isPageActive(page.name) }">
                            {{ page.name }}
                        </button>
                    </li>

                    <li class="pagination-item">
                        <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
                            Next
                        </button>
                    </li>

                    <li class="pagination-item">
                        <button type="button" @click="onClickLastPage" :disabled="isInLastPage">
                            Last
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            select_all: false,
        }
    },

    props: {
        //table
        title: {
            type: Text,
            required: true,
        },
        columns: [],
        fields: [],
        //pagination.
        maxPage: {
            type: Number,
            required: true,
        },
        totalPages: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
    },
    computed: {
        startPage() {
            if (this.currentPage === 1) {
                return 1;
            }
            if (this.currentPage === this.totalPages) {
                return this.totalPages - this.maxPage + 1;
            }
            return this.currentPage - 1;
        },
        endPage() {
            return Math.min(this.startPage + this.maxPage - 1, this.totalPages);
        },
        pages() {
            const range = [];
            for (let i = this.startPage; i <= this.endPage; i += 1) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage,
                });
            }
            console.log(range);
            return range;
        },
        isInFirstPage() {
            return this.currentPage === 1;
        },
        isInLastPage() {
            return this.currentPage === this.totalPages;
        },
    },

    methods: {
        onClickFirstPage() {
            this.$emit("pagechanged", 1);
        },
        onClickPreviousPage() {
            this.$emit("pagechanged", this.currentPage - 1);
        },
        onClickPage(page) {
            this.$emit("pagechanged", page);
        },
        onClickNextPage() {
            this.$emit("pagechanged", this.currentPage + 1);
        },
        onClickLastPage() {
            this.$emit("pagechanged", this.totalPages);
        },
        isPageActive(page) {
            return this.currentPage === page;
        },
        selectAll() {
            if (this.select_all) {
                this.fields.forEach(field => {
                    document.getElementById('cb_'+field.id).checked = true;
                });
            }
            else {
                this.fields.forEach(field => {
                    document.getElementById('cb_'+field.id).checked = false;
                });
            }
        },
        deSelect(e){
            let flag = true;
            this.fields.forEach(field =>{
                let x = document.getElementById('cb_'+field.id).checked;
                if(!x){
                    document.getElementById('select_all').checked = false;
                    flag = false;
                    return;
                }
            });
            document.getElementById('select_all').checked = flag;
        },
    },
};
</script>
<style>
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pagination .pagination-item {
    margin-right: 0.3571rem;
}

.pagination .pagination-item button {
    border: 1px solid #dfe3e7;
}

.pagination .pagination-item .active {
    background-color: #0d6efd;
    color: #fff;
}
</style>

