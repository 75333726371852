<template>
    <div class="">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>DPR Manpower</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                        <label class="form-label">Project Group</label>
                        <select class="form-select form-select-sm" :class="{'is-invalid': errors?.project_group_id}" v-model="project.project_group_id" @click="eraseErrorProjectGroup(project.project_group_id,'project_group_id')" @change="getManpowerTemplates(project.project_group_id)">
                            <option value=""> Select Project Group </option>
                            <option v-for="project_group, key in project_groups" :key="key" :value="project_group.project_group_id">{{project_group.project_group_name }} </option>
                        </select>
                        <span v-if="errors.project_group_id" class="invalid-feedback">{{ errors.project_group_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">DPR Name</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            :class="{ 'is-invalid': errors.manpower_template_name }"
                            placeholder="DPR Name"
                            v-model="project.manpower_template_name"
                            @keyup="eraseErrorDprName(project.manpower_template_name,'manpower_template_name')"
                        />
                        <span v-if="errors.manpower_template_name"><small class="text-danger">{{ errors.manpower_template_name[0] }}</small></span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Manpower Templates</label>
                        <select class="form-select form-select-sm" :class="{'is-invalid': errors?.manpower_template_id}" v-model="manpower_template.manpower_template_id">
                            <option value=""> Select Manpower Template </option>
                            <option v-for="manpower_template, key in manpower_templates" :key="key" :value="manpower_template.manpower_template_id">{{manpower_template.manpower_template_name }} </option>
                        </select>
                        <span v-if="errors.manpower_template_id" class="invalid-feedback">{{ errors.manpower_template_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label"></label>
                        <div class="mt-1">
                            <button class="btn btn-sm btn-primary me-2 btn-sm" @click="getDPRManpowerTemplate()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                            <button class="btn btn-success me-2 btn-sm" @click="addDPRManpowerTemplate()"><i class="ri-save-line icon_hgt"></i> SAVE</button>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div class="col-sm-12 mb-3">
                    <div class="row">
                        <div class="col-md-3">
                            <label>Activity Group</label>
                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.activity_group_id }" v-model="activity_group.activity_group_id" @change="getSectionGroups(activity_group.activity_group_id)">
                                <option value="">Select Activity Group </option>
                                <option v-for="ag, key in activity_groups" :value="ag.activity_group_id" :key="key">{{ag.activity_group_name}}</option>
                            </select>
                            <span v-if="errors.activity_group_id"><small class="text-danger">{{ errors.activity_group_id[0] }}</small></span>
                        </div>
                        <div class="col-md-3">
                            <label>Section Group</label>
                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.section_group_id }" v-model="activity_group.section_group_id">
                                <option value="">Select Section Group </option>
                                <option v-for="setion_group, key in section_groups" :value="setion_group.section_group_id" :key="key">{{setion_group.section_group_name}}</option>
                            </select>
                            <span v-if="errors.section_group_id"><small class="text-danger">{{ errors.section_group_id[0] }}</small></span>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Contractor</label>
                                <div class="dropdown" @click="showContractors">
                                    <div class="overselect"></div>
                                    <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.contractor_id }">
                                        <option value="">Please Select Contractor</option>
                                    </select>
                                    <span v-if="errors.contractor_id"><small class="text-danger">{{ errors.contractor_id[0] }}</small></span>
                                </div>
                                <div class="multiselect" v-if="show_contractors">
                                    <ul>
                                        <li class="" v-for="(value, index) in contractors" :key="index">
                                            <input type="checkbox" :value="value" v-model="activity_group.contractors" style="padding: 2px;" @change="getActivities()" />
                                            <label style="margin-left: 5px;">{{ value }}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- <label>Contractor</label>
                            <select class="form-control form-control-sm" v-model="activity_group.contractors" @change="getActivity(activity_group.contractor_name)" >
                                <option value="">Select Contractor </option>
                                <option v-for="value, key in contractors" :value="value" :key="key">{{value}}</option>
                            </select> -->
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Activities</label>
                                <div class="dropdown" @click="showDropdown">
                                    <div class="overselect"></div>
                                    <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.activity_id }">
                                        <option value="">Please Select Activity</option>
                                    </select>
                                    <span v-if="errors.activity_id"><small class="text-danger">{{ errors.activity_id[0] }}</small></span>
                                </div>
                                <div class="multiselect" v-if="show">
                                    <ul>
                                        <li class="" v-for="(activity, index) in activities" :key="index">
                                            <input type="checkbox" :value="activity.activity_id" v-model="activity_group.activities" style="padding: 2px;" />
                                            <label style="margin-left: 5px;">{{ activity.activity_name }}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1">
                            <button class="btn btn-success me-2 mt-3 btn-sm" @click="addRow()"><i class="ri-add-circle-line icon_hgt"></i> Add</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8 mb-3">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-0 mt-3">
                            <thead>
                                <tr>
                                    <th class="text-center" colspan="2">Particulars</th>
                                    <th class="text-center">Required</th>
                                    <th class="text-center">FTD</th>
                                </tr>
                            </thead>
                            <tbody v-for="ag, key in project.activity_groups" :key="key">
                                <tr>
                                    <td><h5>{{ ag.activity_group_name }}-{{ag.section_group_name}}</h5></td>
                                </tr>
                                <tr v-for="contractor, contractor_key in ag.contractors" :key="contractor_key">
                                    <td></td>
                                    <table>
                                        <tr>
                                            <strong>{{contractor.contractor_name}}</strong>
                                        </tr>
                                        <tr v-for="activity, activity_key in contractor.activities" :key="activity_key">
                                            <td>{{ activity.activity_name }}</td>
                                        </tr>
                                    </table>
                                    <td></td>
                                </tr>
                                <!-- <tr v-for="contractor, contractor_key in ag.contractors" :key="contractor_key">
                                    <td></td>
                                    <td>
                                        <table>
                                            <tr v-for="activity, activity_key in contractor.activities" :key="activity_key">
                                                <td>{{ activity.activity_name }}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr> -->
                                <tr v-for="activity, activity_key in ag.activities" :key="activity_key">
                                    <td></td>
                                    <td>{{ activity.activity_name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DprTemplate",
        data() {
            return {
                projects: [],
                show: false,
                show_contractors: false,
                project_groups: [],
                project: {
                    project_group_id: "",
                    project_id: "",
                    manpower_template_name: "",
                    contractors: [],
                    contractor_name: "",
                    activity_groups: [],
                    section_groups: [],
                },
                activity_groups: [],
                activities: [],
                contractors: [],
                section_groups: [],
                activity_group: {
                    project_group_id: "",
                    project_id: "",
                    activity_group_id: "",
                    section_group_id: "",
                    contractors: [],
                    activities: [],
                },
                section_group_name: "",
                errors: [],
                manpower_templates:[],
                manpower_template:{
                    manpower_template_id:'',
                    manpower_template_name:'',
                    project_group_id:''
                }
            };
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectGroups(), 
                this.getProjects();
            }  
            
        },
        methods: {
            getDPRManpowerTemplate(){
                let vm=this;
                vm.$store
                    .dispatch("post", { uri: "getManpowerTemplate", data: vm.manpower_template })
                    .then(function (response) {
                        console.log("reponse", response.data)
                        // vm.manpower_templates = response.data
                    })
                    .catch(function (error) {
                        vm.errors = error.response?.data?.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getManpowerTemplates(project_group_id){
                let vm=this;
                vm.$store
                    .dispatch("post", { uri: "getManpowerTemplates", data: {'project_group_id': project_group_id} })
                    .then(function (response) {
                        console.log("reponse", response.data)
                        vm.manpower_templates = response.data
                    })
                    .catch(function (error) {
                        vm.errors = error.response?.data?.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            showDropdown() {
                this.show = !this.show;
            },
            showContractors() {
                this.show_contractors = !this.show_contractors;
            },
            getProjectGroups() {
                let vm = this;
                this.$store
                    .dispatch("post", { uri: "getProjectGroups" })
                    .then((response) => {
                        this.project_groups = response.data.data;
                        vm.getActivityGroups();
                        // this.getProjects()
                    })
                    .catch((error) => {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getProjects() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getProjects" })
                    .then(function (response) {
                        vm.projects = response.data.data;
                        vm.getContractors();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getContractors() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getContractors", data: vm.project })
                    .then(function (response) {
                        vm.contractors = response.data.contractor.field_values.split(",");
                        vm.getActivityGroups();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            addContractorRow() {
                this.project.contractors.push({
                    activity_id: "",
                    manpower_avail: "",
                    manpower_req: "",
                    remarks: "",
                });
            },
            addContractor(contractor) {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getContractorReport", data: contractor })
                    .then(function (response) {
                        contractor.manpower_avail = response.data.manpower_avail;
                        contractor.manpower_req = response.data.manpower_req;
                        contractor.remarks = response.data.remarks;
                        contractor.project_activity_id = response.data.project_activity_ids;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getActivities() {
                let vm = this;
                console.log("section group name", vm.activity_group.section_group_name);
                this.activity_group.project_id = this.project.project_id;
                vm.$store
                    .dispatch("post", { uri: "getSectionGroupActivityGroup", data: vm.activity_group })
                    .then(function (response) {
                        vm.activities = response.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getSectionGroups() {
                let vm = this;
                console.log("section group name", vm.activity_group.section_group_name);
                this.activity_group.project_group_id = this.project.project_group_id;
                vm.$store
                    .dispatch("post", { uri: "getSectionGroups", data: vm.activity_group })
                    .then(function (response) {
                        vm.section_groups = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getActivityGroups() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getActivityGroups", data: vm.project })
                    .then(function (response) {
                        vm.activity_groups = response.data.data;
                        vm.getSectionGroups();
                    })
                    .catch(function (error) {
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addRow() {
                let vm = this;
                if (vm.activity_group.activity_group_id == "") {
                    vm.errors.activity_group_id = ["The activity group id field is required"];
                } else {
                    delete vm.errors.activity_group_id;
                }
                if (vm.activity_group.section_group_id == "") {
                    vm.errors.section_group_id = ["The section group id field is required"];
                } else {
                    delete vm.errors.section_group_id;
                }
                if (vm.activity_group.contractors.length == 0) {
                    console.log("error");
                    vm.errors.contractor_id = ["The contractor field is required"];
                } else {
                    delete vm.errors.contractor_id;
                }
                vm.pushActivityGroup();
                vm.pushContractor();
                vm.pushActivity();

                this.activity_group.activity_group_id = "";
                this.activity_group.contractor_name = "";
                this.activity_group.section_group_id = "";
                (this.activity_group.activities = []), (this.activity_group.contractors = []), (this.show = false);
                this.show_contractors = false;

                console.log("activity_groups", vm.project.activity_groups);
            },
            pushActivityGroup() {
                let vm = this;
                let ag = this.activity_groups.filter(function (element) {
                    return element.activity_group_id == vm.activity_group.activity_group_id;
                });
                if (ag.length) {
                    let ag_exists = this.project.activity_groups.filter(function (element) {
                        return element.activity_group_id == vm.activity_group.activity_group_id && element.section_group_id == vm.activity_group.section_group_id;
                    });
                    if (!ag_exists.length) {
                        let sg = vm.section_groups.filter(function (element) {
                            return element.section_group_id == vm.activity_group.section_group_id;
                        });
                        let section_group_name = "";
                        let section_group_id = "";
                        if (sg.length) {
                            section_group_name = sg[0].section_group_name;
                            section_group_id = sg[0].section_group_id;
                        }
                        vm.project.activity_groups.push({
                            activity_group_id: ag[0].activity_group_id,
                            activity_group_name: ag[0].activity_group_name,
                            section_group_id: section_group_id,
                            section_group_name: section_group_name,
                            contractors: [],
                        });
                    }
                }
            },

            pushContractor() {
                let vm = this;

                if (vm.activity_group.contractors) {
                    let activity_group = this.project.activity_groups.filter(function (element) {
                        return element.activity_group_id == vm.activity_group.activity_group_id && element.section_group_id == vm.activity_group.section_group_id;
                    });
                    if (activity_group.length) {
                        if (activity_group[0].contractors.length) {
                            let contractor = vm.activity_group.contractors.filter(function (ele) {
                                let ct = activity_group[0].contractors.filter(function (element) {
                                    return element.contractor_name == ele;
                                });
                                if (!ct.length) {
                                    activity_group[0].contractors.push({
                                        contractor_name: ele,
                                        activities: [],
                                    });
                                }
                            });
                        } else {
                            let ct = vm.contractors.filter(function (element) {
                                vm.activity_group.contractors.map(function (ele) {
                                    if (element == ele) {
                                        activity_group[0].contractors.push({
                                            contractor_name: element,
                                            activities: [],
                                        });
                                    }
                                });
                            });
                        }
                    }
                }
            },

            pushActivity() {
                let vm = this;
                if (this.activity_group.activities.length && vm.activity_group.contractors.length) {
                    let activity_group = vm.project.activity_groups.filter(function (element) {
                        return element.activity_group_id == vm.activity_group.activity_group_id && element.section_group_id == vm.activity_group.section_group_id;
                    });
                    if (activity_group.length) {
                        this.activity_group.activities.map(function (activity_id) {
                            let contractor = vm.activity_group.contractors.filter(function (ele) {
                                let ct = activity_group[0].contractors.filter(function (act) {
                                    return act.contractor_name == ele;
                                });
                                if (ct.length) {
                                    let ac = ct[0].activities.filter(function (act) {
                                        return act.activity_id == activity_id;
                                    });
                                    if (!ac.length) {
                                        let act = vm.activities.filter(function (activity) {
                                            return activity.activity_id == activity_id;
                                        });
                                        if (act.length) {
                                            ct[0].activities.push(act[0]);
                                        }
                                    }
                                }
                            });
                        });
                    }
                }
            },
            addDPRManpowerTemplate() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.activity_group.project_group_id = vm.project.project_group_id;
                console.log(vm.project);
                vm.$store
                    .dispatch("post", { uri: "addManpowerTemplate", data: vm.project })
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", "Added successfully!");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            eraseErrorProjectGroup(project, field_name) {
                let vm = this;
                if (field_name == "project_group_id") {
                    console.log("field name")
                    vm.errors.project_group_id = "";
                }
                
            },
            eraseErrorDprName(manpower_name, field_name) {
                let vm = this;
                if (field_name == "manpower_template_name") {
                    vm.errors.manpower_template_name = "";
                }
            },
        },
    };
</script>

<style scoped>
    .dropdown {
        position: relative;
        cursor: pointer;
    }
    .multiselect {
        position: relative;
    }
    .multiselect ul {
        border: 1px solid #ddd;
        border-top: 0;
        border-radius: 0 0 3px 3px;
        left: 0px;
        padding: 8px 8px;
        top: -0.1rem;
        width: 100%;
        list-style: none;
        max-height: 150px;
        overflow: auto;
        background: white;
    }
    .overselect {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>
