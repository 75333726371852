<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <div>
            <ol class="breadcrumb fs-sm mb-1">
                <li class="breadcrumb-item">Dashboard</li>
                <li class="breadcrumb-item active" aria-current="page">Transfer List</li>
            </ol>
            <h4 class="main-title mb-0">Transfer</h4>
        </div>
        <div class="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <button v-can="'budget_transfers.create'" class="btn btn-sm btn-primary" style="float: right;"
                    @click="newTransfer()">New Transfer</button>
        </div>
            <!-- <h2 class="main-title mb-3">{{ project.project_name }}</h2> -->
            <!-- <router-link to="/projects" type="submit" class="btn btn-sm mb-2 btn-primary"><i class="ri-list-check"></i> Back</router-link> -->
            <!-- <a href="javascript:void(0)" class="btn btn-sm mb-2 btn-primary" @click="showRequests()"><i
                    class="ri-list-check"></i> Requests List</a> -->
        </div>
        <div class="card card-one mt-1">
            <div class="card-header">
                <h6 class="card-title">Transfer List</h6>
            </div>
            <div class="card-body">
                <div class="form-group mb-2">
                    <div class="row">
                        <div class="col-sm-12">
                            <input class="form-control" type="text" placeholder="Type keyword and press enter key"
                                v-model="meta.search" @keypress.enter="search()" />
                        </div>
                    </div>
                </div>
                <div class="table-responsive table-resposive-sm">
                    <table class="table table-sm text-nowrap table-bordered mb-0">
                        <thead>
                            <tr style="text-align: center;">
                                <th class="text-center">#</th>
                                <th>Reference No</th>
                                <th>Reference Date</th>
                                <th>User</th>
                                <th>Project Code</th>
                                <th>Project Name</th>
                                <th>Plant Name</th>
                                <th>Project Type</th>
                                <th>Project Category</th>
                                <th>Project Group</th>
                                <!-- <th>Amount</th> -->
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="transfer_cost, key in transfers" :key="key">
                                <td>{{ key + 1 }}</td>
                                <td>{{ transfer_cost.reference_no }}</td>
                                <td>{{ transfer_cost.reference_date }}</td>
                                <td>{{ transfer_cost.user.name }}</td>
                                <td>{{ transfer_cost.project?.project_code }}</td>
                                <td>{{ transfer_cost.project?.project_name }}</td>
                                <td>{{ transfer_cost.project?.plant.plant_name }}</td>
                                <td>{{ transfer_cost.project?.project_type?.project_type_name }}</td>
                                <td>{{ transfer_cost.project?.project_category?.project_category_name }}</td>
                                <td>{{ transfer_cost.project?.project_group?.project_group_name }}</td>
                                <!-- <td>{{ transfer_cost.amount }}</td> -->
                                <td class="d-flex justify-content-center gap-1">
                                    <a v-can="'budget_transfers.view'" class="btn btn-sm btn-success btn-icon" href="#"
                                        @click.prevent="viewTransfer(transfer_cost)" title="Transfer List" data-bs-toggle="modal" data-bs-target="#transferModal">
                                        <i class="ri-eye-line icon_hgt"></i>
                                    </a>
                                    <a v-can="'budget_transfers.delete'" href="javascript:void(0)" class="btn btn-sm btn-danger"
                                        @click.prevent="deleteTransfer(transfer_cost)"><i
                                            class="ri-delete-bin-line icon_hgt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                    <select class="form-select from-select-sm width-75" @change="onPerPageChange" v-model="meta.per_page">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="30">30</option>
                    </select>
                    <span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
    <!-- Transfer view modal -->
    <div class="modal fade" id="transferModal" tabindex="-1" aria-labelledby="requestModalLabel" aria-hidden="true"
        ref="request_modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="requestModalLabel">Transfer Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-6">
                            Name : <span class="text-primary">{{ transfer_costs[0]?.transfer?.user?.name }}</span>
                        </div>
                        <div class="col-sm-6">
                            Role : <span class="text-primary">{{ transfer_costs[0]?.transfer?.user?.role?.role_name
                            }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            Reference No : <span class="text-primary">{{ transfer_costs[0]?.transfer?.reference_no }}</span>
                        </div>
                        <div class="col-sm-6">
                            Reference Date : <span class="text-primary">{{ transfer_costs[0]?.transfer?.reference_date
                            }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            Project : <span class="text-primary">{{ transfer_costs[0]?.transfer?.project?.project_name
                            }}</span>
                        </div>
                        <div class="col-sm-6">
                            WBS Code : <span class="text-primary">{{ transfer_costs[0]?.transfer?.project?.wbs_code
                            }}</span>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-sm table-bordered border-warning">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th class="text-center">WBS Code</th>
                                            <th class="text-center">Budget</th>
                                            <th class="text-center">Balance</th>
                                            <th class="text-center">Outward</th>
                                            <th class="text-center">Inward</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="transfer, key in transfer_costs" :key="key">
                                            <td class="text-center">{{ key + 1 }}</td>
                                            <td>{{ transfer.project_cost?.wbs_code }}</td>
                                            <td style="text-align: right;">{{ transfer.budget }}</td>
                                            <td style="text-align: right;">{{ transfer.balance }}</td>
                                            <td style="text-align: right;">{{ transfer.outward }}</td>
                                            <td style="text-align: right;">{{ transfer.inward }}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td colspan="4" class="text-center"><strong>Total</strong></td>
                                            <td style="text-align: right;">{{ transfer_costs[0]?.transfer?.amount }}</td>
                                            <td style="text-align: right;">{{ transfer_costs[0]?.transfer?.amount }}</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
    components: { Pagination },
    data() {
        return {
            meta: {
                search: "",
                order_by: "asc",
                keyword: "transfer_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
                project_id: ''
            },
            errors: [],
            error: {},
            project: {
                project_id: "",
                project_master_cost: [],
                last_stage: {}
            },
            project_master_cost: {},
            show_modal: 'modal fade',
            transfers: [],
            transfer_costs: []
        };
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.project.project_id = to.params.project_id;
            vm.meta.project_id = to.params.project_id;
            vm.index();
        });
    },


    methods: {
        index() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getTransferList", data: vm.meta })
                .then(function (response) {
                    vm.transfers = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.to = response.data.meta.to;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },

        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },

        newTransfer() {
            this.$router.push('/BATS/' + this.project.project_id + '/transfer')
        },

        deleteTransfer(transfer_cost) {
            let vm = this;
            this.$store.dispatch('post', { uri: 'deleteTransfer', data: { transfer_id: transfer_cost.transfer_id } })
                .then(response => {
                    vm.$store.dispatch('success', response.data.message);
                    vm.index();
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        viewTransfer(transfer_cost) {
            let vm = this;
            vm.$store.dispatch('post', { uri: 'getTransferDetails', data: { transfer_id: transfer_cost.transfer_id } })
                .then(response => {
                    console.log(response)
                    vm.transfer_costs = response.data.data;
                })
                .catch(error => {
                    vm.errors = error.response.data.message;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },
    },
};
</script>

