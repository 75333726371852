<template>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <strong> {{ user.name }}</strong>
                    <button class="btn btn-sm btn-outline-primary float-end" @click="viewUsers()">Users</button>
                </div>
                <div class="card-body">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm table-bordered table-striped mb-0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th>Project Code</th>
                                    <th @click="sort('project_id')">Project Name</th>
                                    <th @click="sort('plant_id')">Plant Name</th>
                                    <th @click="sort('project_type_id')">Project Type</th>
                                    <th @click="sort('project_category_id')">Project Catogry</th>
                                    <th @click="sort('project_group_id')">Project Group</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-if="!projects.length" class="text-center" colspan="10">No records found</td>
                                </tr>
                                <tr v-for="project, key in projects" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>
                                        {{ project.project_code }}
                                    </td>
                                    <td>
                                        {{ project.project_name }}
                                    </td>
                                    <td>{{ project?.plant?.plant_name }}</td>
                                    <td>{{ project?.project_type?.project_type_name }}</td>
                                    <td>{{ project?.project_category?.project_category_name }}</td>
                                    <td>{{ project?.project_group?.project_group_name }}</td>
                                    <td class="d-flex justify-content-center gap-1">
                                        <a class="btn btn-danger btn-icon" href="javascript:void(0)" title="Delete" @click="removeUser(project.project_id)">
                                            <i class="ri-delete-bin-line icon_hgt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
    components: { Pagination },
    data() {
        return {
            meta: {
                search: '',
                order_by: "desc",
                keyword: "project_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
                type: 'pmt_paginate',
                project_type_id: "",
                project_category_id: "",
                project_group_id: "",
                user_id: ''
            },
            projects: [],
            project_types: [],
            project_categories: [],
            project_groups: [],
            selected_projects: [],
            errors: [],
            permissions: [],
            inactive: true,
            user:{}
        }
    },

    mounted() {
        if(this.$store.getters?.user){
			this.index();
            this.getProjectTypes();
        }
        
        this.permissions = this.$store.getters.permissions;
    },

    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.meta.user_id = to.params.user_id;
            vm.getUser();
        });
    },

    methods: {
        index() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getUserProjects", data: vm.meta };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide()
                    vm.projects = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getUser(){
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post',{uri:'getUser',data:{user_id: this.meta.user_id}})
            .then(response => {
                loader.hide();
                this.user = response.data.data;
            })
            .catch(error => {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            })
        },

        viewUsers(){
            this.$router.push('/users');
        },

        removeUser(project_id){
            let vm = this;
            if(confirm('Do you want to continue ?')) {
                let loader = this.$loading.show();
                this.$store.dispatch('post', {uri:'removeUser', data: {user_id: this.user.user_id, project_id: project_id}})
                .then(response => {
                    loader.hide();
                    vm.$store.dispatch('success', response.data.message);
                    vm.projects = response.data.projects;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error',error.response.data.message);
                })
            }
        },

        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },

        edit(project) {
            this.$router.push("/project_update/" + project.project_id + "/edit");
        },

        editPMT(project) {
            this.$router.push('/PMT/' + project.project_id + '/edit')
        },

        showPMTProject(project) {
            this.$router.push('/PMT/' + project.project_id + '/user')
        },

        editBATS(project) {
            this.$router.push('/BATS/' + project.project_id + '/edit');
        },

        updateBATS(project) {
            this.$router.push('/BATS/' + project.project_id + '/report_templates');
        },

        showWithdrawList(project) {
            this.$router.push('/BATS/' + project.project_id + '/withdraw_list')
        },
        editProject(project) {
            this.$router.push("/project/" + project.project_id + "/edit");
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },

        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },
        getProjectTypes() {
            let vm = this;
            vm.$store.dispatch("post", { uri: "getProjectTypes" })
                .then(function (response) {
                    vm.project_types = response.data.data;
                    vm.getProjectCategories();
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getProjectCategories() {
            let vm = this;
            vm.$store.dispatch("post", { uri: "getProjectCategories" })
                .then(function (response) {
                    vm.project_categories = response.data.data;
                    vm.getProjectGroups();
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getProjectGroups() {
            let vm = this;
            vm.$store.dispatch("post", { uri: "getProjectGroups" })
                .then(function (response) {
                    vm.project_groups = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        }
    },
};
</script>