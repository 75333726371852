<template>
    <div class="col-3">
        <div class="profile-item mb-3">
            <i class="ri-medal-2-line"></i>
            <div class="profile-item-body">
                <span>Project Code</span>
                <p>{{ project?.project_code }}</p>
            </div>
        </div>
    </div>
    <div class="col-3">
        <div class="profile-item mb-3">
            <i class="ri-profile-line"></i>
            <div class="profile-item-body">
                <span>Project Name</span>
                <p>{{ project?.project_name }}</p>
            </div>
        </div>
    </div>
    <div class="col-3">
        <div class="profile-item mb-3">
            <i class="ri-archive-drawer-line"></i>
            <div class="profile-item-body">
                <span>Project Type</span>
                <p>{{ project?.project_type?.project_type_name }}</p>
            </div>
        </div>
    </div>
    <div class="col-3">
        <div class="profile-item mb-3">
            <i class="ri-stack-line"></i>
            <div class="profile-item-body">
                <span>Project Category</span>
                <p>{{ project?.project_category?.project_category_name }}</p>
            </div>
        </div>
    </div>
    <div class="col-3">
        <div class="profile-item mb-3">
            <i class="ri-stack-line"></i>
            <div class="profile-item-body">
                <sapn>Project Group</sapn>
                <p>{{ project?.project_group?.project_group_name }}</p>
            </div>
        </div>
    </div>
    <div class="col-3">
        <div class="profile-item mb-3">
            <i class="ri-archive-drawer-line"></i>
            <div class="profile-item-body">
                <sapn>Plant</sapn>
                <p>{{ project?.plant?.plant_name }}</p>
            </div>
        </div>
    </div>
    <div class="col-3">
        <div class="profile-item mb-3">
            <i class="ri-profile-line"></i>
            <div class="profile-item-body">
                <sapn>WBS Code</sapn>
                <p>{{ project?.wbs_code }}</p>
            </div>
        </div>
    </div>
    <div class="col-3">
        <div class="profile-item mb-3">
            <i class="ri-medal-2-line"></i>
            <div class="profile-item-body">
                <sapn>Note</sapn>
                <p>{{ project?.note }}</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            project: Object,
        }, 
        mounted() {
            console.log(this.project)
        },
        data() {
            return {
                treeData: [],
                project_activity_groups:[],
            };
        },
    }

</script>