<template>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <strong> Project Activity Groups</strong>
                </div>
                <div class="card-body">
                    <div class="row g-3 mb-4">
                        <div class="col-md-3">
                            <label class="form-label">Project Groups</label>
                            <select class="form-control form-control-sm" v-model="project.project_group_id" @change="getProjects()">
                                <option value="">Select Project Groups</option>
                                <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label">Project</label>
                            <search
                                :class="{ 'is-invalid': errors.project_id }"
                                :customClass="{ 'is-invalid': errors.project_id  }"
                                :initialize="project.project_id"
                                id="project_id"
                                label="project_name"
                                placeholder="Select Project"
                                :data="projects"
                                @input="project1 => project.project_id = project1"
                            >
                            </search>
                            <!-- <select class="form-control form-control-sm" v-model="project.project_id">
                                <option value="">Select Project</option>
                                <option v-for="project, key in projects" :key="key" :value="project.project_id">{{ project.project_name }}</option>
                            </select> -->
                            <span v-if="errors.project_id"><small class="text-danger">{{ errors.project_id[0] }}</small></span>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label"></label>
                            <div class="mt-1">
                                <button class="btn btn-sm btn-primary me-2 btn-sm" @click="getProjectActivityGroups()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 mb-4">
                        <div class="table-responsive table-responsive-sm">
                            <table class="table table-sm table-bordered table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>Activity Group</th>
                                        <th>Project Activity Group Code</th>
                                        <th class="text-center">Sort</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="activity_group, key in project_activity_groups" :key="key">
                                        <td class="text-center">{{ key + 1 }}</td>
                                        <td>{{ activity_group?.activity_group?.activity_group_name }}</td>
                                        <td>{{ activity_group?.project_activity_group_code }}</td>
                                        <td class="text-center"><input class="form-control form-control-sm" type="text" v-model="activity_group.sort" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button class="btn btn-primary btn-sm" @click="update()"><i class="ri-file-edit-line icon_hgt"></i> UPDATE</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    let Search = require("@/components/Search.vue").default;
    import Table from "@/components/Table.vue";
    import moment from "moment";
    export default {
        components: { Table, Search },
        name: "SortProjectActivityGroups",
        data() {
            return {
                initial: true,
                project: {
                    project_id: "",
                    project_group_id: "",
                },
                projectgroups: [],
                project_activity_groups: [],
                projects: [],
                errors: [],
            };
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectGroups();
            }
            
        },
        methods: {
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if (localStorage.getItem("project_group_id")) {
                            vm.project.project_group_id = localStorage.getItem("project_group_id");
                        } else if (vm.projectgroups.length) {
                            if (!vm.project.project_group_id) {
                                vm.project.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjects() {
                let vm = this;
                if (!vm.initial) {
                    localStorage.removeItem("project_id");
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects", data: vm.project })
                    .then(function (response) {
                        vm.projects = response.data;
                        if (localStorage.getItem("project_id")) {
                            vm.project.project_id = localStorage.getItem("project_id");
                        } else if (vm.projects.length && !localStorage.getItem("project_id")) {
                            vm.project.project_id = vm.projects[0].project_id;
                        }
                        if (vm.initial) {
                            vm.getProjectActivityGroups();
                            vm.initial = false;
                        }
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getProjectActivityGroups() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "getProjectActivityGroup", data: vm.project })
                    .then(function (response) {
                        loader.hide();
                        vm.project_activity_groups = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            update() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "updateProjectActivityGroupSort", data: { project_activity_groups: vm.project_activity_groups } })
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", "Project activity group updated successfully!");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>
