<template>
    <div class="page-sign d-block py-0">
        <div class="row g-0">
            <div class="col-md-7 col-lg-5 col-xl-4 col-wrapper">
                <div class="card card-sign">
                    <div class="card-header">
                        <div class="text-center">
                            <img src="../../assets/ultratech-cement-logo.png" class="img" width="100" />
                        </div>
                        <div class="text-center">
                            <a href="#" class="text-center header-logo mb-3 mt-2"><span class="title">PROODOS </span>
                            <small class="sub_title">Project & Budget Mgmt System</small></a>
                        </div>
                        <h3 class="card-title">Sign In</h3>
                        <p class="card-text">Welcome back! Please signin to continue.</p>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="login">
                            <div class="mb-4">
                                <label class="form-label">Email address</label>
                                <input type="text" tabindex=1 class="form-control" placeholder="Enter your email address"
                                    :class="{ 'is-invalid': errors.email }" v-model="user.email" ref="email" />
                                <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                            </div>
                            <div class="mb-4">
                                <label class="form-label d-flex justify-content-between">Password <router-link
                                        to="/forgot_password">Forgot password?</router-link></label>
                                <input type="password" tabindex=1 class="form-control" placeholder="Enter your password"
                                    :class="{ 'is-invalid': errors.password }" v-model="user.password" />
                                <span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
                            </div>
                            <button method="submit" tabindex=1 class="btn btn-primary btn-sign">Sign In</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col d-none d-lg-block"><img src="assets/img/bg1.jpg" class="auth-img" alt="" /></div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    name: "Login",
    data() {
        return {
            user: {
                email: "",
                password: ""
            },
            errors: [],
        };
    },
    mounted() {
        this.$refs.email.focus();
        
    },
    created(){
        if(this.$store.getters.user){
            this.getPermissions()
        }
    },

    beforeRouteEnter(t0, from, next){
        next((vm)=>{
            
        })
    },
    methods: {
        login() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
            .dispatch("auth", { uri: "login", data: vm.user })
            .then(function (response) {
                loader.hide();
                vm.$store.dispatch("setUser", response.data.user);
                vm.$store.dispatch("setToken", response.data.token);
                vm.getPermissions();
            })
            .catch(function (error) {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },

        getPermissions() {
            let vm = this;
            let uri = { uri: 'getPermissions', data: {
                role_id: this.$store.getters.user.role_id
            }};
            let current_page = ""
            let loader = vm.$loading.show();
            this.$store.dispatch('post', uri)
            .then(response => {
                loader.hide();
                current_page = '/project_dashboard'
                if(localStorage.getItem('current_page')){
                    if(localStorage.getItem('user_id') == vm.$store.getters.user?.user_id){
                        current_page = localStorage.getItem('current_page')
                    }
                }
                vm.$store.dispatch('setPermissions', response.data);
                if(!localStorage.getItem('page_close_at')){
                    localStorage.setItem('page_close_at', moment().format('YYYY-MM-DD HH:mm:ss'))
                }
                if (vm.$store.getters.user?.role?.role_group?.role_group_code=='TM'){
                    vm.$router.push("/topmanagement_dashboard");
                }
                else if(vm.$store.getters.user?.role?.role_code=='CFD') {
                    vm.$router.push("/dashboard/cfd");
                }
                else if(vm.$store.getters.user?.role?.role_code=='HQPR') {
                    vm.$router.push("/dashboard/pr");
                }
                else if(vm.$store.getters.user.user_type=='BATS') {
                    vm.$router.push("/dashboard/bats");
                }
                else{
                    vm.$router.push(current_page);
                }
            })
            .catch(error => {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            })
        },
    },
};
</script>
<style scoped>
.sub_title {
    font-size:12px;
    font-weight: 200;
    letter-spacing: 0.6px;
}
.title {
    font-weight:bold;
    font-size:25px;
    padding-left:10px;
    padding-bottom:0px;
    letter-spacing: 8px;
    display: block !important;
    margin-bottom: -10px;
}
</style>