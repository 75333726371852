<template>
    <div class="">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <strong>Refractory Report</strong>
            </div>
            <div class="card-body">
                <div class="row g-3 mb-4">
                    <div class="col-md-3">
                         <label class="form-label">Project Groups</label>
                        <select class="form-control form-control-sm" v-model="refractory.project_group_id" @change="getProjects()">
                            <option value="">Select Project Groups</option>
                            <option v-for="pg, key in projectgroups" :key="key" :value="pg.project_group_id">{{ pg.project_group_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Projects</label>
                        <!-- <select class="form-select form-select-sm" :class="{'is-invalid': errors?.project_id}" v-model="refractory.project_id">
                            <option value=""> Select Project </option>
                            <option v-for="project, key in projects" :key="key" :value="project.project_id">{{project.project_name }} </option>
                        </select> -->
                        <search
                            :class="{ 'is-invalid': errors.project_id }"
                            :customClass="{ 'is-invalid': errors.project_id  }"
                            :initialize="refractory.project_id"
                            id="project_id"
                            label="project_name"
                            placeholder="Select Project"
                            :data="projects"
                            @input="project1 => refractory.project_id = project1"
                        >
                        </search>
                        <span v-if="errors.project_id" class="invalid-feedback">{{ errors.project_id[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">From Date</label>
                        <div class="input-group date" id="from_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.from_date }" v-model="refractory.from_date" />
                        </div>
                        <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">To Date</label>
                        <div class="input-group date" id="to_date">
                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.to_date }" v-model="refractory.to_date" />
                        </div>
                        <span v-if="errors.to_date" style="margin-top: 0.25rem;font-size: 0.875em;color: #dc3545;">{{ errors.to_date[0] }}</span>
                    </div>
                    <div class="col-md-12 mt-1">
                        <div style="float:right">
                        <button class="btn btn-sm btn-primary me-2" @click="getSlippageReport()"><i class="ri-drag-move-fill icon_hgt"></i> Get</button>
                        <button class="btn btn-sm btn-warning me-2" @click="downloadRefractory()"><i class="ri-download-line icon_hgt"></i> Download</button>
                        <button class="btn btn-sm btn-info" @click="sendEmailRefractory()"><i class="ri-mail-send-line icon_hgt"></i> Send Email</button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div >
                            <table class="table table-sm text-wrap table-bordered mb-0">
                                <thead class="bg-primary bg-opacity-50">
                                    <tr class="text-center" style="vertical-align: middle;">
                                        <th>Section</th>
                                        <th>BL Finish Date</th>
                                        <th>Exp/Act Finish Date</th>
                                        <th>Slippage</th>
                                    </tr>
                                </thead>
                                <tbody v-if="!project_section_groups.length">
                                    <tr><td colspan="4" class="text-center">No records found</td></tr>
                                </tbody>
                                <tbody v-for="project_section_group,key in project_section_groups" :key="key">
                                    <tr>
                                        <td class="bg-primary bg-opacity-25" colspan=4><strong>{{ project_section_group?.section_group?.section_group_name }}</strong></td>
                                    </tr>
                                    <tr v-for="project_section, ps_key in project_section_group.project_sections" :key="ps_key">
                                        <td>{{ project_section?.section?.section_name }}</td>
                                        <td class="text-center">{{ convertDateFormat(project_section?.project_section_parameter?.bl_finish_date) }}</td>
                                        <td class="text-center">{{ convertDateFormat(project_section?.project_section_parameter?.exp_finish_date) }}</td>
                                        <td class="text-center">{{ project_section?.project_section_parameter?.slippage }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    let Search = require("@/components/Search.vue").default;
    export default {
        components: { Search },
        data() {
            return {
                initial:true,
                refractory: {
                    project_id: '',
                    from_date: '',
                    to_date:'',
                    project_group_id:'',
                    isDownload:false
                },
                projectgroups: [],
                project_section_groups:[],
                projects: [],
                errors: [],
            };
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectGroups();
            }
            
            this.refractory.to_date = moment().format("yyyy-MM-DD");
        },
        methods: {
            sendEmailRefractory(){
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "downloadRefractory", data: vm.refractory})
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Refractory summary - Mail sent successfully");
                    })
                    .catch(function (error) {
                        loader.hide()
                        if(error.response.data.length){
                            console.log(error.response.data[1])
                            vm.$store.dispatch("error", error.response.data[1]);
                        }
                    });
            },
            getProjectGroups() {
                let vm = this;
                let uri = { uri: "getProjectGroups" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.projectgroups = response.data.data;
                        if(localStorage.getItem('project_group_id')){
                            vm.refractory.project_group_id = localStorage.getItem('project_group_id') 
                        }else if (vm.projectgroups.length) {
                            if (!vm.refractory.project_group_id) {
                                vm.refractory.project_group_id = vm.projectgroups[0].project_group_id;
                            }
                        }
                        vm.getProjects();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjects() {
                let vm = this;
                if(!vm.initial){
                    localStorage.removeItem('project_id')
                }
                vm.$store
                    .dispatch("post", { uri: "getProjectGroupProjects", data: vm.refractory })
                    .then(function (response) {
                        vm.projects = response.data;
                        if(localStorage.getItem('project_id')){
                            vm.refractory.project_id = localStorage.getItem('project_id') 
                        }else if (vm.projects.length && !localStorage.getItem('project_id')) {
                            vm.refractory.project_id = vm.projects[0].project_id;
                        }
                        if(vm.initial){
                            vm.getSlippageReport()
                            vm.initial = false
                        }
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            convertDateFormat(date_time){
                if(date_time){
                    return moment(date_time).format("DD-MM-yyyy")
                }else{
                    return '-'
                }
            },
            getSlippageReport() {
                let vm = this;
                localStorage.setItem('project_group_id', vm.refractory.project_group_id)
                localStorage.setItem('project_id', vm.refractory.project_id)
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "slippageReport", data: vm.refractory })
                    .then(function (response) {
                        loader.hide();
                        vm.project_section_groups = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.project_section_groups = []
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            downloadRefractory(){
                let vm = this;
                vm.errors = [];
                if (vm.refractory.project_id == ""){
                    vm.errors.project_id = ["The project field is required."]
                }
                if (vm.refractory.to_date == ""){
                    vm.errors.to_date = ["The To Date field is required."]
                }
                if (vm.refractory.project_id && vm.refractory.to_date ){
                    window.open(vm.$store.getters.apiUrl+'downloadRefractory?project_id='+vm.refractory.project_id+'&to_date='+vm.refractory.to_date+'&from_date='+vm.refractory.from_date+'&isDownload=true');
                }
            }
        },
    };
</script>
<style scoped>
    tr:nth-child(even) {
        background-color: transparent;
    }
</style>
