<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <h2 class="main-title mb-3">Scope</h2>
        </div>

        <div class="card">
            <div class="card-header">
                <strong> Scope</strong>
            </div>
            <div class="card-body">
                <div class="form-group mb-2">
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <label>Poject Activity Group</label>
                            <select class="form-control form-control-sm" v-model="project_activity_group" @change="getJobParametersInitial()">
                                <option value="">Select Poject Activity Group</option>
                                <option v-for="(activity_group, index) in project_activity_groups" :key="index" :value="activity_group">{{ activity_group.activity_group.activity_group_name }} </option>
                            </select>
                        </div>
                    </div>

                    <div class="row mb-3" style="height: 330px;overflow-y: scroll;">
                        <div class="col-sm-12" v-for="project_section_group, project_section_group_key in project_section_groups" :key="project_section_group_key">
                            <span><h5>{{ project_section_group.section_group.section_group_name }}</h5></span>
                            <div class="mb-3" v-for="project_section, project_section_key in project_section_group.project_sections" :key="project_section_key">
                                <span><h6 class="mb-0">{{ project_section.section.section_name }}</h6></span>
                                <div class="table-responsive table-resposive-sm" style="width: 100%;">
                                    <table class="table table-sm text-nowrap table-bordered mb-0">
                                        <thead>
                                            <tr style="text-align: center;">
                                                <th class="text-center">#</th>
                                                <th>Project Activity Code</th>
                                                <th>Activity</th>
                                                <th>UOM</th>
                                                <th>Scope</th>
                                                <th>Scope C</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="project_activity, key in project_section.project_activities" :key="key">
                                                <td class="text-center">{{ key + 1 }}</td>

                                                <td>{{ project_activity.activity.activity_code }}</td>
                                                <td>{{ project_activity.activity.activity_name }}</td>
                                                <td>{{ project_activity.unit.unit_code }}</td>
                                                <td><input class="form-control form-control-sm" type="text" v-model="project_activity.scope" /></td>
                                                <td><input class="form-control form-control-sm" type="text" v-model="project_activity.scope_c" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div style="float: right;">
                        <button v-if="show_load_more" class="btn btn-warning btn-sm" @click="getJobParametersLoadMore()"><i class="ri-restart-line icon_hgt"></i>Load More</button>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger btn-sm me-2" @click.prevent="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                <button class="btn btn-primary btn-sm" @click="submit()"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                project_activity_group: "",
                project_activity_groups: [],
                project: {
                    project_id: "",
                },
                project_activities: [],
                project_activity: [],
                project_activity_ids: [],

                project_sections: [],
                project_section_groups: [],
                show_load_more:false,
                ps_offset: 0,
                psg_offset: 0,
                ps_count: 0,
                psg_count: 0,
                psg_change:false,
            };
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getProjectActivityGroups();
            }
            
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.from_path = from.path;
                vm.project.project_id = to.params.project_id;
                if(vm.$store.getters?.user){
                    vm.getProject();
                }
                
            });
        },
        methods: {
            getProject() {
                let vm = this;
                let uri = { uri: "getProject", data: vm.project };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.project = response.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getProjectActivityGroups() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "getProjectActivityGroupDropDown", data: vm.project })
                    .then(function (response) {
                        loader.hide();
                        vm.project_activity_groups = response.data.data;
                        // console.log("project activities", vm.project_activity_groups);
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getJobParametersInitial(){
                let vm = this;
                vm.ps_offset = 0;
                vm.psg_offset = 0;
                vm.getProjectActivities('initial');
            },
            getJobParametersLoadMore(){
                let vm = this;
                vm.ps_offset = vm.ps_offset + 2;                
                if(vm.ps_count!=0 && vm.ps_count<=vm.ps_offset){
                    vm.psg_offset = vm.psg_offset + 1;
                    vm.ps_offset = 0;
                    vm.psg_change=true;
                }

                if(vm.psg_count!=0 && vm.psg_offset == vm.psg_count){
                    vm.show_load_more = false;                    
                    vm.ps_offset = 0;
                    vm.psg_offset = 0;
                }
                else{
                    vm.getProjectActivities();
                }                
            },
            getProjectActivities(req) {
                // console.log("activity group", this.project_activity_group);
                let vm = this;
                vm.show_load_more = true;
                let loader = vm.$loading.show();
                vm.project_activity_group['ps_offset'] = vm.ps_offset;
                vm.project_activity_group['psg_offset'] = vm.psg_offset;
                // console.log("activity group", vm.project_activity_group);
                vm.$store
                    .dispatch("post", { uri: "getProjectSectionGroupScope", data: vm.project_activity_group })
                    .then(function (response) {
                        loader.hide();
                        // vm.project_section_groups = response.data.data;                        

                        if(req=='initial'){
                            vm.project_section_groups=[];
                            vm.project_section_groups = response.data.data;
                        }
                        else{               
                            if(vm.psg_change){
                                vm.project_section_groups.push(response.data.data[response.data.data?.length-1]);
                                vm.psg_change=false;                                
                            }
                            else{
                                for(let i=0; i<response.data.data[response.data.data?.length-1]?.project_sections.length; i++){                            
                                    vm.project_section_groups[vm.project_section_groups?.length-1].project_sections.push(
                                        response.data.data[response.data.data?.length-1].project_sections[i]);
                                }
                            }
                        }
                        vm.ps_count = vm.project_section_groups[vm.project_section_groups?.length-1].ps_count;
                        vm.psg_count = response.data.psg_count;
                        console.log("Response:---", vm.project_section_groups, vm.psg_change, vm.psg_offset, vm.ps_offset);
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log('error:----',error)
                        // vm.errors = error.response.data.errors;
                        // vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            submit() {
                let vm = this;
                
                let project = {
                    project_section_groups : vm.project_section_groups
                }
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "updateMultipleProjectActivity", data: project })
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", "Activity updated successfully!");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
                let vm = this
                vm.errors = []
            },
        },
    };
</script>
