<template>
    <figure class="barchart-figure">
        <div :id="container"></div>
    </figure>
</template>

<script>
    var Highcharts = require("highcharts");
    require("highcharts/modules/heatmap")(Highcharts);
	require("highcharts/modules/data")(Highcharts);
    require("highcharts/modules/exporting")(Highcharts);
    require("highcharts/modules/export-data")(Highcharts);
    require("highcharts/modules/accessibility")(Highcharts);
    require("highcharts/highcharts-more")(Highcharts);
    export default {
        name: "SplineBarchart",
        props: {
			container: {
                default: null,
            },
            title: {
                default: null,
            },
            max: {
				default: null,
			},
			series: {
				default: null,
			},
            yAxis: {
				default: null,
			},
            xAxis: {
				default: null,
			},
            type:{
                default:null,
            },
            stacking:{
                default:null,
            },
            labels:{
                default:null,
            },
            height: {
				default: '75%',
                // default:null
			},

        },

        watch: {
			series: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
            max: {
                handler() {
                    this.loadChart();
                },
                deep: true,
            },
        },

        mounted() {
            this.loadChart();
        },

        methods: {
            loadChart() {
                let vm = this;
                Highcharts.chart(vm.container, {
                    exporting:{
                        enabled:false,
                    },
                    chart: {
                        zoomType: 'xy',
                        // plotBorderWidth: 1,
                        // scrollablePlotArea: {
                        //     // minWidth: vm.minWidth,
                        //     scrollPositionX: 1
                        // },
                        height:vm.height,
                    },
                    title: {
                        text: '',                        
                    },
                    subtitle: {
                        text: vm.title,
                        align: 'left',
                        x: -10,
                        style: {
                            fontSize:'12px',
                            fontWeight: 'bold',
                        }
                    },
                    xAxis: {
                        categories: vm.xAxis,
                        // min: vm.min,
                        // max: vm.max,
                        // scrollbar: {
                        //     enabled: true,
                        //     showFull: true,
                        // },
                        // tickLength: 0,
                        // gridLineWidth: 1,
                        // lineWidth: 0,
                        // crosshair: true,
                    },

                    yAxis: [
                        // {
                        //     categories: vm.yAxis,
                        //     // scrollbar: {
                        //     //     enabled: true,
                        //     // },
                        //     min: 0,
                        //     // gridLineWidth: 1,
                        //     // lineWidth: 0,
                        //     tickPositioner: function() {
                        //         return vm.yAxis;
                        //     },
                        //     labels: {
                        //         formatter: function(){
                        //             if(vm.labels){
                        //                 return this.value / 100 + '%';
                        //             }
                        //             else{
                        //                 return this.value
                        //             }
                                    
                        //         },
                        //     }

                        // },
                        { // Primary yAxis
                            min: 0,
                            // max: vm.max,
                            title: false,
                            // tickPositioner: function() {
                            //     return vm.yAxis;
                            // },
                            // labels: {
                            //     formatter: function(){
                            //         if(vm.labels){
                            //             return this.value / 100 + '%';
                            //         }
                            //         else{
                            //             return this.value
                            //         }
                                    
                            //     },
                            // },
                            // opposite: true

                        }, 
                        { // Secondary yAxis
                            title: false,
                            min: 0,
                            // gridLineWidth: 0,
                            // tickPositioner: function() {
                            //     return vm.yAxis;
                            // },
                            labels: {
                            //     formatter: function(){
                            //         if(vm.labels){
                            //             return this.value / 100 + '%';
                            //         }
                            //         else{
                            //             return this.value
                            //         }
                                    
                            //     },
                            // enabled: false,
                               style: {
                                    color: '#03BBD4'
                                }
                            },
                            opposite: true,

                        }, 
                        { // Tertiary yAxis
                            title: false,
                            min: 0,
                            // gridLineWidth: 0,
                            // tickPositioner: function() {
                            //     return vm.yAxis;
                            // },
                            labels: {
                            //     formatter: function(){
                            //         if(vm.labels){
                            //             return this.value / 100 + '%';
                            //         }
                            //         else{
                            //             return this.value
                            //         }
                                    
                            //     },
                            // enabled: false,
                            },
                            opposite: true
                        },
                        { // Tertiary yAxis
                            title: false,
                            min: 0,
                            // gridLineWidth: 0,
                            // tickPositioner: function() {
                            //     return vm.yAxis;
                            // },
                            labels: {
                            //     formatter: function(){
                            //         if(vm.labels){
                            //             return this.value / 100 + '%';
                            //         }
                            //         else{
                            //             return this.value
                            //         }
                                    
                            //     },
                            // enabled: false,
                            },
                            opposite: true
                        }
                    ],

                    legend: {
                        layout: 'horizontal',
                        align: 'right',
                        verticalAlign: 'top',
                        x: 10,
                        y: -11,
                        floating: true,
                        // borderWidth: 1,
                        backgroundColor:Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                        // shadow: true,
                        itemStyle: {
                            // color: 'white',
                            // fontWeight: 'bold',
                            fontSize: '9px'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        shared: true
                    },

                    // plotOptions: {
                    //     bar: {
                    //         // borderRadius: '50%',
                    //         dataLabels: {
                    //             enabled: true
                    //         },
                    //         groupPadding: 0.3
                    //     },
                    //     series: {
                    //         stacking: vm.stacking,
                    //         // dataLabels: {
                    //         //     enabled: true
                    //         // }
                    //     }
                    // },                    
                    series: vm.series,
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                // legend: {
                                //     floating: false,
                                //     layout: 'horizontal',
                                //     align: 'center',
                                //     verticalAlign: 'bottom',
                                //     x: 0,
                                //     y: 0
                                // },
                                yAxis: [{
                                    labels: {
                                        align: 'right',
                                        x: 0,
                                        y: -6
                                    },
                                    showLastLabel: false
                                }, {
                                    labels: {
                                        align: 'left',
                                        x: 0,
                                        y: -6
                                    },
                                    showLastLabel: false
                                }, {
                                    visible: false
                                }]
                            }
                        }]
                    }
                });
               
            }
        }
    };
</script>

<style scoped>
    /* .highcharts-figure,
    .highcharts-data-table table {
        min-width: 310px;
        max-width: 800px;
        margin: 1em auto;
    }

    #container {
        height: 400px;
    }

    .highcharts-data-table table {
        font-family: Verdana, sans-serif;
        border-collapse: collapse;
        border: 1px solid #ebebeb;
        margin: 10px auto;
        text-align: center;
        width: 100%;
        max-width: 500px;
    }

    .highcharts-data-table caption {
        padding: 1em 0;
        font-size: 1.2em;
        color: #555;
    }

    .highcharts-data-table th {
        font-weight: 600;
        padding: 0.5em;
    }

    .highcharts-data-table td,
    .highcharts-data-table th,
    .highcharts-data-table caption {
        padding: 0.5em;
    }

    .highcharts-data-table thead tr,
    .highcharts-data-table tr:nth-child(even) {
        background: #f8f8f8;
    }

    .highcharts-data-table tr:hover {
        background: #f1f7ff;
    } */

</style>