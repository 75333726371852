<template>
	<div class="">
		<div class="card">
			<div class="card-header">
				<div class="d-sm-flex align-items-center justify-content-between ">
					<div>
						<strong>{{ title }}</strong>
					</div>
					<div class="d-flex align-items-center">
						<router-link to="/users/create" type="submit" class="btn btn-sm btn-primary me-2" 
							><i class="ri-add-line"></i>
							ADD USER
						</router-link>
						<button v-if="inactive" type="button" class="btn btn-sm btn-primary" @click="inactiveuser()">Inactive User</button>
						<button v-else type="button" class="btn btn-sm btn-primary" @click="index()">Active User</button>
					</div>
					<!-- <strong style="font-size:medium;">Users</strong>				
					<router-link to="/users/create" type="submit" class="btn btn-sm mb-2 btn-primary" style="float:right;"><i class="ri-add-line"></i>
					ADD USER</router-link>
					<button type="button" class="btn btn-sm mb-2 btn-primary mr-10"  style="float:right;">Inactive User</button> -->
				</div>
			</div>
			<div class="card-body">
				<div class="form-group mb-2 d-flex">
					<input class="form-control form-control-sm" type="text" placeholder="Type keyword and press enter key"
						v-model="meta.search" @keypress.enter="search()" />
					<a @click="deleteValues()" v-if="delete_values != 0" href="javascript:void(0)"
						class="btn btn-sm btn-primary ms-2 "><i class="ri-delete-bin-line icon_hgt"></i></a>
				</div>
				<div class="table-responsive table-responsive-sm">
					<table class="table table-sm text-nowrap table-bordered mb-0">
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th @click="sort('name')">Name
									<span>
										<i v-if="meta.keyword == 'name' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'name' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('email')">Email
									<span>
										<i v-if="meta.keyword == 'email' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'email' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('role_id')">Role Group
									<span>
										<i v-if="meta.keyword == 'role_id' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'role_id' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('role_id')">Role
									<span>
										<i v-if="meta.keyword == 'role_id' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'role_id' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('escalation_role_id')">Escalation Role
									<span>
										<i v-if="meta.keyword == 'role_id' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'role_id' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th @click="sort('user_type')">User Type
									<span>
										<i v-if="meta.keyword == 'user_type' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'user_type' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th>Projects</th>
								<th @click="sort('mobile_no')">Mobile No.
									<span>
										<i v-if="meta.keyword == 'mobile_no' && meta.order_by == 'asc'"
											class="ri-arrow-up-line"></i>
										<i v-else-if="meta.keyword == 'mobile_no' && meta.order_by == 'desc'"
											class="ri-arrow-down-line"></i>
										<i v-else class="fas fa-sort"></i>
									</span>
								</th>
								<th class="text-center">Status</th>
								<th class="text-center">Actions</th>
								<th class="text-center"><input type="checkbox" :checked="isAllSelected"
										@change="selectAll" /></th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="users.length == 0">
								<td colspan="8" class="text-center">No records found</td>
							</tr>
							<tr v-for="(user, key) in users" :key="key">
								<td class="text-center">{{ meta.from + key }}</td>
								<td>{{ user.name }}</td>
								<td>{{ user.email }}</td>
								<td>{{ user.role?.role_group?.role_group_name }}</td>
								<td>{{ user.role?.role_name }}</td>
								<td>{{ user.escalation_role?.name }}</td>
								<td>{{ user.user_type }}</td>
								<td class="text-center"><span>{{ user.project_count }}</span> <a href="javascript:void(0)" class="float-end me-2 text-success" @click="viewUserProjects(user.user_id)"><i class="ri-eye-line icon_hgt"></i></a></td>
								<td>{{ user.mobile_no }}</td>
								<td class="text-center">
									<div class="form-switch form-switch">
										<input class="form-check-input" type="checkbox" role="switch"
											:id="'user' + user.user_id" :checked="user.status" :value="user.status"
											@change="deleteUser(user)" />
										<label class="custom-control-label" :for="'user' + user.user_id"></label>
									</div>
								</td>
								<td class="text-center">
									<nav>
										<a href="javascript:void(0)" class="text-success me-2" @click.prevent="edit(user)"
											:disabled="!user.status"><i class="ri-pencil-line icon_hgt"></i></a>
										<!-- <a href="javascript:void(0)" class="text-danger" @click.prevent="trash(user)"><i class="ri-delete-bin-line icon_hgt"></i></a> -->
									</nav>
								</td>
								<td class="text-center"><input type="checkbox" v-model="delete_values" :value="user.user_id"
										@change="select" /></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex justify-content-between align-items-center">
					<select class="form-select from-select-sm width-75" v-model="meta.per_page" @change="onPerPageChange">
						<option>10</option>
						<option>15</option>
						<option>20</option>
						<option>25</option>
						<option>30</option>
					</select>
					<span>Showing {{ meta.from }} to {{ meta.to }} of {{ meta.totalRows }} entries</span>
					<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
						@pagechanged="onPageChange" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
	components: { Pagination },
	data() {
		return {
			meta: {
				search: "",
				order_by: "asc",
				keyword: "user_id",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				to: 1,
				maxPage: 1,
				trashed: false,
			},
			title:'Users',
			inactive:true,
			isAllSelected: false,
			delete_values: [],
			users: [],
			errors: [],
		};
	},
	mounted() {
		if(this.$store.getters?.user){
			this.index();
        }
		
	},
	methods: {
		search() {
			let vm = this;
			vm.meta.page = 1;
			vm.index();
		},
		index() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "paginateUsers", data: vm.meta })
				.then(function (response) {
					vm.users = response.data.data;
					vm.inactive= true;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					console.log("error")
					vm.errors = error.response?.data?.errors;
					vm.$store.dispatch("error", error.response?.data?.message);
				});
		},
		inactiveuser(){
			let vm = this;
			vm.$store.dispatch("post", { uri: "paginateInActiveUsers", data: vm.meta })
			.then(function (response) {
				console.log('response:------',response.data)
				vm.title = 'Inactive Users'
				vm.inactive= false;
				vm.users = response.data.data;
				vm.meta.totalRows = response.data.meta.total;
				vm.meta.lastPage = response.data.meta.last_page;
				vm.meta.from = response.data.meta.from;
				vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
			})
			.catch(function (error) {
				vm.errors = error.response.data.errors;
				vm.$store.dispatch("error", error.response.data.message);
			});
		},
		edit(user) {
			this.$router.push("/users/" + user.user_id + "/edit");
		},

		deleteUser(user) {
			let vm = this;
			let loader = vm.$loading.show();
			user.status = user.status == 1 ? 0 : 1;
			console.log('user:---', user)
			vm.$store
				.dispatch("post", { uri: "deleteUser", data: user })
				.then(function (response) {
					loader.hide()
					vm.$store.dispatch("success", response.data.message);
					vm.index();
				})
				.catch(function (error) {
					loader.hide()
					vm.errors = error.response.data.error;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		confirmDelete(user) {
			this.confirm.name = user.name;
			this.confirm.user_id = user.user_id;
		},

		trash(user) {
			let vm = this;
			if (confirm("Do you want to delete this entry ?")) {
				let loader = vm.$loading.show();
				vm.$store
					.dispatch("post", { uri: "forceDeleteUser", data: user })
					.then(function () {
						loader.hide();
						vm.$store.dispatch("success", "Record deleted successfully");
						vm.index();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},
		selectAll() {
			if (this.isAllSelected) {
				this.delete_values = [];
				this.isAllSelected = false;
			} else {
				this.delete_values = [];
				for (var user in this.users) {
					this.delete_values.push(this.users[user].user_id);
				}
				this.isAllSelected = true;
			}
		},
		select() {
			if (this.delete_values.length !== this.users.length) {
				this.isAllSelected = false;
			} else {
				this.isAllSelected = true;
			}
		},
		deleteValues() {
			confirm("Are You Sure Want to delete?");
			let vm = this;
			let loader = vm.$loading.show();
			vm.$store.dispatch("post", { uri: "multipleDeleteUsers", data: { user_id: this.delete_values } })
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "User is successfully deleted.");
					vm.isAllSelected = false
					vm.delete_values = []
					vm.index();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		viewUserProjects(user_id){
			this.$router.push('/users/user_projects/'+user_id);
		},

		onPageChange(page) {
			this.meta.page = page;
			this.index();
		},
		sort(field) {
			this.meta.keyword = field;
			this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
			this.index();
		},
		onPerPageChange() {
			this.meta.page = 1;
			this.index();
		},
	},
};
</script>



