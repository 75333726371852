<template>
    <div class="">
        <div class="card g-3 mb-3">
            <div class="card-header d-flex justify-content-between">
                <h5 class="card-title">E&I Template</h5>
            </div>
            <div class="card-body">
                <div class="row" >
                    <!-- <div class="col-md-2">
                        <label>Date:</label>
                    </div>
                    <div class="col-md-6" >                        
                        <input class="form-control form-control-sm" type="date" placeholder="Date" :class="{ 'is-invalid': errors.date_time }" v-model="date_time" />
                        <span v-if="errors.date_time" class="invalid-feedback">{{ errors.date_time[0] }}</span>
                    </div>
                    <div class="col-md-3">
                        <button class="btn btn-primary btn-sm" @click="getData()"> Get </button>                        
                    </div> -->
                    <div class="col-md-3" v-if="ei_template_show">
                        <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.ei_template_name }" v-model="ei_template_name" @change="removeError()">
                            <option value="">-- Select E&I Template --</option>
                            <option v-for="ag, key in ei_template" :value="ag.ei_template_id" :key="key">{{ag.ei_template_name}}</option>
                        </select>
                        <span v-if="errors.ei_template_name"><small class="text-danger">{{ errors.ei_template_name[0] }}</small></span>
                    </div>
                    <div class="col-md-3" v-else>
                        <!-- <label>E&I Template Name</label> -->
                        <input type="text" placeholder="E&I Template Name" class="form-control form-control-sm" :class="{ 'is-invalid': errors.ei_template_name }" v-model="ei_template_name"/>
                        <span v-if="errors.ei_template_name"><small class="text-danger">{{ errors.ei_template_name[0] }}</small></span>
                    </div>
                    <div class="col-md-3">                        
                        <button class="btn btn-primary btn-sm" @click="getEITemplateData()"> Get </button>
                        <button class="btn btn-primary btn-sm" style="margin-left: 3px;" @click="newEITemplate()">  New </button>
                        <button class="btn btn-danger btn-sm" style="margin-left: 3px;" @click="alltemplate()">  Discard </button>
                    </div>
                    <div class="col-md-1">                        
                        
                    </div>   
                </div>
                <br>
                <div class="row mb-4">
                    <div class="col-md-3">
                        <label>Activity Group</label>
                        <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.activity_group_id }" v-model="project.activity_group_id" @change="">
                            <option value="">Select Activity Group </option>
                            <option v-for="ag, key in activity_groups" :value="ag.activity_group_id" :key="key">{{ag.activity_group_name}}</option>
                        </select>
                        <span v-if="errors.activity_group_id"><small class="text-danger">{{ errors.activity_group_id[0] }}</small></span>
                    </div>
                    <div class="col-md-3">
                        <label>Activities</label>
                        <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.activity_id }" v-model="project.activity_id">
                            <option value="">Select Activity </option>
                            <option v-for="activity, key in activities" :value="activity.activity_id" :key="key">{{activity.activity_name}}</option>
                        </select>
                        <span v-if="errors.activity_id"><small class="text-danger">{{ errors.activity_id[0] }}</small></span>
                    </div>
                    <div class="col-md-3">
                        <label>Parameters</label>
                        <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.parameter_id }" v-model="project.parameter_id">
                            <option value="">Select Parameters </option>
                            <option v-for="parameter, key in parameters" :value="parameter.parameter_id" :key="key">{{parameter.parameter_name}}</option>
                        </select>
                        <span v-if="errors.parameter_id"><small class="text-danger">{{ errors.parameter_id[0] }}</small></span>
                    </div>
                    <div class="col-md-2">
                        <label>Weightage</label>
                        <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': errors.weightage }" v-model="project.weightage"/>
                        <span v-if="errors.weightage"><small class="text-danger">{{ errors.weightage[0] }}</small></span>
                    </div>                    
                    <div class="col-md-1">
                            <!-- <label class="form-label"></label>
                            <div class=" mt-1">
                            <button class="btn btn-success me-2 btn-sm" @click="addDPRManpowerTemplate()"><i class="ri-save-line icon_hgt"></i> SAVE </button> -->
                            <button v-if="update" class="btn btn-primary me-2 mt-3 btn-sm" @click="updateRow(project.key)"><i class="ri-add-circle-line icon_hgt"></i> Update </button>
                        <button v-else class="btn btn-success me-2 mt-3 btn-sm" @click="addRow()"><i class="ri-add-circle-line icon_hgt"></i> Add </button>
                    </div>                    
                </div>                
                <div class="col-sm-12 mb-3">
                    
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm text-nowrap table-bordered mb-0 mt-3">
                            <thead>
                                <tr>
                                    <th class="text-center">Sl.No</th>
                                    <th class="text-center">Activity Group </th>
                                    <th class="text-center">Activities </th>
                                    <th class="text-center">Parameters</th>
                                    <th class="text-center">Weightage (%)</th>                                    
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="check_update">                                
                                <tr v-for="ag, key in ei" :key="key">
                                    <td class="text-center">{{key+1}}</td>
                                    <td class="text-center">{{ag?.activity_group[0]?.activity_group_name}}</td>
                                    <td class="text-center">{{ag?.activity[0]?.activity_name}}</td>                                    
                                    <td class="text-center">{{ag?.parameter[0]?.parameter_name}}</td>
                                    <td class="text-center">{{ag?.weightage}}</td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-outline-success me-2" data-toggle="tooltip" 
                                            data-placement="bottom" title="Edit" @click="editRow(key)">
                                            <i class="ri-pencil-line"></i>
                                        </button>
                                        <button class="btn btn-outline-danger btn-sm" type="button" @click="deleteRow(key)">
                                            <i class="ri-delete-bin-line"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>                                
                                <tr v-for="ag, key in ei" :key="key">
                                    <td class="text-center">{{key+1}}</td>
                                    <td class="text-center">{{ag?.activity_group[0]?.activity_group_name}}</td>
                                    <td class="text-center">{{ag?.activity[0]?.activity_name}}</td>
                                    <td class="text-center">{{ag?.parameter[0]?.parameter_name}}</td>
                                    <td class="text-center">{{ag?.weightage}}</td>                                    
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-outline-success me-2" data-toggle="tooltip" 
                                            data-placement="bottom" title="Edit" @click="editRow(key)">
                                            <i class="ri-pencil-line"></i>
                                        </button>
                                        <button class="btn btn-outline-danger btn-sm" type="button" @click="deleteRow(key)">
                                            <i class="ri-delete-bin-line"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>                                 
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer text-end" v-if="ei.length>0">
                <button class="btn btn-primary btn-sm" v-if="check_update" @click="UpdateData()">
                    <span><i class="ri-save-line icon_hgt"></i> Update</span>
                </button>
                <button class="btn btn-primary btn-sm" v-else @click="SubmitData()">
                    <span><i class="ri-save-line icon_hgt"></i> SUBMIT</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        name: "DprTemplate",
        data() {
            return {
                // projects:[],
                ei_template_show:true,
                update:false,
                check_update:false,
                date_time:'',
                ei_template_name:'',
                project:{
                    activity_group_id:'',
                    activity_id:'',
                    parameter_id:'',
                    weightage:'',
                    key:'',
                },
                activity_groups:[],
                activities:[],
                parameters:[],
                errors:[],
                ei:[],
                ei_template:[],
                deleted_ei_template_parameters:[],
            }
        },
        mounted() {
            if(this.$store.getters?.user){
                this.getEITemplates();
                this.getActivityGroups();
            }
            this.date_time = moment().format('yyyy-MM-DD')
        },
        methods: {
            removeError(){
                delete this.errors.ei_template_name
            },
            alltemplate(){
                this.ei_template_show=true;
            },
            newEITemplate(){
                this.ei_template_show=false;
                this.ei_template_name=''
            },
            getEITemplates(){
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getEITemplates", data: vm.project })
                    .then(function (response) {
                        vm.ei_template = response.data.data
                        // vm.getActivities()
                    })
                    .catch(function (error) {
                        vm.$store.dispatch("error", error.response.data.message);
                    }
                );
            },
            getActivityGroups() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getActivityGroups", data: vm.project })
                    .then(function (response) {
                        vm.activity_groups = response.data.data
                        vm.getActivities()
                    })
                    .catch(function (error) {
                        vm.$store.dispatch("error", error.response.data.message);
                    }
                );
            },
            getActivities() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getActivities" })
                    .then(function (response) {
                        vm.activities = response.data.data;
                        vm.getParameters();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    }
                );
            },
            getParameters() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getParameters"})
                    .then(function (response) {
                        vm.parameters = response.data.data;                        
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    }
                );
            },        

            addRow(){
                let vm = this
                let error = 0;                
                if (vm.project.activity_group_id == ''){
                    vm.errors.activity_group_id = ["Please Select Activity Group"]
                    error += 1;
                }
                else{
                    delete vm.errors.activity_group_id
                }
                if (vm.project.activity_id == ''){
                    vm.errors.activity_id = ["Please Select Activity"]
                    error += 1;
                }
                else{
                    delete vm.errors.activity_id
                }
                if (vm.project.parameter_id == ''){
                    vm.errors.parameter_id = ["Please Select Parameter"]
                    error += 1;
                }
                else{
                    delete vm.errors.parameter_id
                }
                if (vm.project.weightage == ''){
                    vm.errors.weightage = ["Please Eneter a valid weightage"]
                    error += 1;
                }
                else{
                    delete vm.errors.weightage
                }
                // console.log('vm.errors.activity:-----',error);
                if(error == 0){
                    let activity_group = vm.activity_groups.filter(function(element){
                        return element.activity_group_id == vm.project.activity_group_id
                    })
                    let activity = vm.activities.filter(function(element){
                        return element.activity_id == vm.project.activity_id
                    })
                    let parameter = vm.parameters.filter(function(element){
                        return element.parameter_id == vm.project.parameter_id
                    })

                    let ei = vm.ei.filter(function(element){
                        if(element?.activity_group[0]?.activity_group_id == activity_group[0]?.activity_group_id
                                 && element?.activity[0]?.activity_id == activity[0]?.activity_id && 
                                element?.parameter[0]?.parameter_id == parameter[0]?.parameter_id){
                            return element                        
                        }                    
                    });

                    if(ei.length==0){
                        let count = vm.ei.length;
                        vm.ei.push({'key':count,'activity_group':activity_group,'activity':activity, 
                        'weightage':vm.project.weightage, 'parameter':parameter})
                        vm.Discard();                        
                    }
                    else{
                        vm.errors.activity_group_id = ["Activity Group already added"]
                        vm.errors.activity_id = ["Activity already added"]
                        vm.errors.parameter_id = ["Parameter already added"]
                        vm.$store.dispatch("error", 'Record Already Exist');
                    }
                }              
            },

            editRow(row){
                let vm = this;
                vm.update = true;
                vm.project.activity_group_id = vm.ei[row].activity_group[0].activity_group_id
                vm.project.activity_id = vm.ei[row]?.activity[0]?.activity_id
                vm.project.parameter_id = vm.ei[row]?.parameter[0]?.parameter_id
                vm.project.weightage = vm.ei[row].weightage
                vm.project.key = row
            },
            updateRow(row){
                let vm = this;
                console.log('project_row:---',row);
                let update_element = vm.ei[row]
                let activity_group = vm.activity_groups.filter(function(element){
                    return element.activity_group_id == vm.project.activity_group_id
                })
                let activity = vm.activities.filter(function(element){
                    return element.activity_id == vm.project.activity_id
                })
                let parameter = vm.parameters.filter(function(element){
                    return element.parameter_id == vm.project.parameter_id
                })
                let err = 0;
                vm.ei.map(function(element){
                    
                    if(element.key != row){
                        console.log('counter:---',element.key);
                        if(element?.activity_group[0]?.activity_group_id == vm.project.activity_group_id
                        && element?.activity[0]?.activity_id == vm.project.activity_id && 
                        element?.parameter[0]?.parameter_id == vm.project.parameter_id)
                        {
                            vm.errors.activity_group_id = ["Activity Group already added"]
                            vm.errors.activity_id = ["Activity already added"]
                            vm.errors.parameter_id = ["Parameter already added"]
                            vm.$store.dispatch("error", 'Record Already Exist');
                            err = err + 1;
                           
                        }else{
                            // update_element.activity_group = activity_group;
                            // update_element.activity = activity;
                            // update_element.parameter = parameter;
                            // update_element.weightage = vm.project.weightage;
                            // vm.update = false;
                            // vm.Discard()
                        }
                    }
                });
                if(err==0){
                    update_element.activity_group = activity_group;
                    update_element.activity = activity;
                    update_element.parameter = parameter;
                    update_element.weightage = vm.project.weightage;
                    vm.update = false;
                    vm.Discard()
                }
            },
            getEITemplateData(){
                let vm = this;  
                if(vm.ei_template_name=='')
                {
                    vm.errors.ei_template_name = ["Please Select Template"]
                }
                else
                {
                                  
                    let data = {
                        'ei_template_id':vm.ei_template_name
                    }
                    vm.$store.dispatch("post", { uri: "getEITemplate", data: data })
                    .then(response => {
                        console.log('ResponseData:----',response.data.data)
                        let result = response.data.data;
                        vm.check_update = true;
                        for(let i=0;i<result.length;i++){
                            vm.ei.push({'key':i,'ei_template_parameter_id':result[i].ei_template_parameter_id,
                            'activity_group':[result[i].activity_group],'activity':[result[i].activity], 
                            'weightage':result[i].weightage, 'parameter':[result[i].parameter]})                            
                        }
                        console.log('vm.ei:---',vm.ei)
                        // ei = response.data.data;
                        // this.project_groups = response.data.data;
                        // this.getProjects()
                    })
                    .catch(error => {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    })
                }
            },
            SubmitData(){
                let vm = this;
                let data = []
                vm.ei.map(function(element){
                    data.push({
                        'activity_group_id':element.activity_group[0].activity_group_id,
                        'activity_id':element.activity[0].activity_id,
                        'parameter_id':element.parameter[0].parameter_id,
                        'weightage':element.weightage
                    })
                });
                if(vm.ei_template_name==''){
                    vm.ei_template_show=false;
                    vm.errors.ei_template_name = ["Please Eneter a Template Name"]
                }
                else{
                    delete vm.errors.ei_template_name
                    vm.$store.dispatch("post", { uri: "addEITemplate", data: {'ei_template_name':vm.ei_template_name,
                        'ei_template_parameters':data} })
                    .then(response => {
                        console.log('ResponseData:----',response)
                        vm.ei_template_show=true;
                        vm.ei_template_name='';
                        vm.ei=[];
                        vm.getEITemplates();
                    })
                    .catch(error => {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    })
                }
                // console.log('vm.errors:----',vm.errors);                
            },
            UpdateData(){
                let vm = this;
                let data = []
                vm.ei.map(function(element){                    
                    data.push({
                        'ei_template_parameter_id':element.ei_template_parameter_id ? element.ei_template_parameter_id : '',
                        'activity_group_id':element.activity_group[0].activity_group_id,
                        'activity_id':element.activity[0].activity_id,
                        'parameter_id':element.parameter[0].parameter_id,
                        'weightage':element.weightage
                    })
                });
                vm.$store.dispatch("post", { uri: "updateEITemplate", data: {'ei_template_id':vm.ei_template_name,
                    'ei_template_parameters':data, 'deleted_ei_template_parameters':vm.deleted_ei_template_parameters} })
                .then(response => {
                    console.log('ResponseData:----',response)
                    vm.ei_template_show=true;
                    vm.ei=[];
                    vm.deleted_ei_template_parameters=[];
                    vm.ei_template_name='';

                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
            },
            deleteRow(row){
                // console.log('deleted_array:---',this.ei[row].ei_template_parameter_id)
                
                if (confirm("Are you sure you want to delete")) {
                    let vm = this;
                    vm.deleted_ei_template_parameters.push(vm.ei[row].ei_template_parameter_id)
                    vm.ei.splice(row, 1);
                }
            },
            Discard(){
                let vm = this;
                vm.project.activity_group_id = ''
                vm.project.activity_id = ''
                vm.project.parameter_id = ''
                vm.project.weightage = ''
                vm.project.key = ''
                delete vm.errors.activity_group_id
                delete vm.errors.activity_id
                delete vm.errors.parameter_id
            },
            
        }
    }

</script>