<template>
	<div class="d-sm-flex align-items-center justify-content-between mb-2">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<li class="breadcrumb-item">Dashboard</li>
				<li class="breadcrumb-item active" aria-current="page">BATS</li>
			</ol>
			<h4 class="main-title mb-0">Report</h4>
		</div>
	</div>
	<div class="row g-3">
		<div class="col-sm-12">
			<div class="card card-one">
				<div class="card-header">
					<h6 class="card-title">Report</h6>
					<nav class="nav ms-auto">
						<div class="d-flex">
                            <select class="form-control form-controls-sm mx-2" v-model="currency_unit">
                                <option value="1">in rupees</option>
                                <option value="100000">in lakhs</option>
                                <option value="10000000">in cr</option>
                            </select>
							<select class="form-control form-controls-sm mx-2" v-model="report.project_id" @change="changeProject()">
                                <option value="">Select Project</option>
                                <option v-for="project, key in projects" :key="key" :value="project.project_id">
                                    {{ project.project_name }}
                                </option>
                            </select>
						</div>
					</nav>
				</div>
				<div class="card-body">
					<div class="row g-2 mb-4" v-if="project_costs.length!=0">
						<div class="col-md-12">
                            <strong>{{ heading }}</strong>
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th class="text-center">Level</th>
											<th class="text-center">WBS Code</th>
											<th class="text-center">WBS Description</th>
											<th class="text-center">Approved Budget</th>
											<th class="text-center">SAP Commit</th>
											<th class="text-center">Manual Commit</th>
											<th class="text-center">Total Commit</th>
											<th class="text-center">Actual Spent</th>
											<th class="text-center">Balance in Hand</th>
											<th class="text-center">Projected Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
                                        <tr v-for="project_cost, key in project_costs" :key="key">
											<td class="text-center" @click="openLevel(project_cost)">
                                                <i class="ri-add-box-line icon_hgt"></i>
                                            </td>
                                            <td class="text-center">{{ project_cost.level }}</td>
											<td class="text-center">{{ project_cost.wbs_code }}</td>
											<td class="text-center">{{ project_cost.wbs_description }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.budget) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.sap_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.manual_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.total_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.actual_spent) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.balance_in_hand) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.projected_commitment) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.savings) }}</td>
                                        </tr>
									</tbody>
                                    <tfoot>
                                        <tr class="text-primary">
                                            <th colspan="4" class="text-center">Total</th>
                                            <th class="text-center">{{ formatCurrency(budget) }}</th>
                                            <th class="text-center">{{ formatCurrency(sap_commit) }}</th>
                                            <th class="text-center">{{ formatCurrency( manual_commit) }}</th>
                                            <th class="text-center">{{ formatCurrency(total_commit) }}</th>
                                            <th class="text-center">{{ formatCurrency(actual_spent) }}</th>
                                            <th class="text-center">{{ formatCurrency(balance_in_hand) }}</th>
                                            <th class="text-center">{{ formatCurrency(projected_commitment) }}</th>
                                            <th class="text-center">{{ formatCurrency(savings) }}</th>
                                        </tr>
                                    </tfoot>
								</table>
							</div>
						</div>
					</div>
                    <div class="row g-2 mb-4" v-if="project_costs1.length!=0">
						<div class="col-md-12">
                            <strong>{{ heading1 }}</strong>
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th class="text-center">Level</th>
											<th class="text-center">WBS Code</th>
											<th class="text-center">WBS Description</th>
											<th class="text-center">Approved Budget</th>
											<th class="text-center">SAP Commit</th>
											<th class="text-center">Manual Commit</th>
											<th class="text-center">Total Commit</th>
											<th class="text-center">Actual Spent</th>
											<th class="text-center">Balance in Hand</th>
											<th class="text-center">Projected Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
                                        <tr v-for="project_cost, key in project_costs1" :key="key">
											<td class="text-center" @click="openLevel(project_cost)">
                                                <i class="ri-add-box-line icon_hgt"></i>
                                            </td>
                                            <td class="text-center">{{ project_cost.level }}</td>
											<td class="text-center">{{ project_cost.wbs_code }}</td>
											<td class="text-center">{{ project_cost.wbs_description }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.budget) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.sap_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.manual_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.total_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.actual_spent) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.balance_in_hand) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.projected_commitment) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.savings) }}</td>
                                        </tr>
									</tbody>
                                    <tfoot>
                                        <tr class="text-primary">
                                            <th colspan="4" class="text-center">Total</th>
                                            <th class="text-center">{{ formatCurrency(budget1) }}</th>
                                            <th class="text-center">{{ formatCurrency(sap_commit1) }}</th>
                                            <th class="text-center">{{ formatCurrency(manual_commit1) }}</th>
                                            <th class="text-center">{{ formatCurrency(total_commit1) }}</th>
                                            <th class="text-center">{{ formatCurrency(actual_spent1) }}</th>
                                            <th class="text-center">{{ formatCurrency(balance_in_hand1) }}</th>
                                            <th class="text-center">{{ formatCurrency(projected_commitment1) }}</th>
                                            <th class="text-center">{{ formatCurrency(savings1) }}</th>
                                        </tr>
                                    </tfoot>
								</table>
							</div>
						</div>
					</div>
                    <div class="row g-2 mb-4" v-if="project_costs2.length!=0">
						<div class="col-md-12">
                            <strong>{{ heading2 }}</strong>
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th class="text-center">Level</th>
											<th class="text-center">WBS Code</th>
											<th class="text-center">WBS Description</th>
											<th class="text-center">Approved Budget</th>
											<th class="text-center">SAP Commit</th>
											<th class="text-center">Manual Commit</th>
											<th class="text-center">Total Commit</th>
											<th class="text-center">Actual Spent</th>
											<th class="text-center">Balance in Hand</th>
											<th class="text-center">Projected Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
                                        <tr v-for="project_cost, key in project_costs2" :key="key">
											<td class="text-center" @click="openLevel(project_cost)">
                                                <i class="ri-add-box-line icon_hgt"></i>
                                            </td>
                                            <td class="text-center">{{ project_cost.level }}</td>
											<td class="text-center">{{ project_cost.wbs_code }}</td>
											<td class="text-center">{{ project_cost.wbs_description }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.budget) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.sap_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.manual_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.total_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.actual_spent) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.balance_in_hand) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.projected_commitment) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.savings) }}</td>
                                        </tr>
									</tbody>
                                    <tfoot>
                                        <tr class="text-primary">
                                            <th colspan="4" class="text-center">Total</th>
                                            <th class="text-center">{{ formatCurrency(budget2) }}</th>
                                            <th class="text-center">{{ formatCurrency(sap_commit2) }}</th>
                                            <th class="text-center">{{ formatCurrency(manual_commit2) }}</th>
                                            <th class="text-center">{{ formatCurrency(total_commit2) }}</th>
                                            <th class="text-center">{{ formatCurrency(actual_spent2) }}</th>
                                            <th class="text-center">{{ formatCurrency(balance_in_hand2) }}</th>
                                            <th class="text-center">{{ formatCurrency(projected_commitment2) }}</th>
                                            <th class="text-center">{{ formatCurrency(savings2) }}</th>
                                        </tr>
                                    </tfoot>
								</table>
							</div>
						</div>
					</div>
                    <div class="row g-2 mb-4" v-if="project_costs3.length!=0">
						<div class="col-md-12">
                            <strong>{{ heading3 }}</strong>
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th class="text-center">Level</th>
											<th class="text-center">WBS Code</th>
											<th class="text-center">WBS Description</th>
											<th class="text-center">Approved Budget</th>
											<th class="text-center">SAP Commit</th>
											<th class="text-center">Manual Commit</th>
											<th class="text-center">Total Commit</th>
											<th class="text-center">Actual Spent</th>
											<th class="text-center">Balance in Hand</th>
											<th class="text-center">Projected Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
                                        <tr v-for="project_cost, key in project_costs3" :key="key">
											<td class="text-center" @click="openLevel(project_cost)">
                                                <i class="ri-add-box-line icon_hgt"></i>
                                            </td>
                                            <td class="text-center">{{ project_cost.level }}</td>
											<td class="text-center">{{ project_cost.wbs_code }}</td>
											<td class="text-center">{{ project_cost.wbs_description }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.budget) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.sap_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.manual_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.total_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.actual_spent) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.balance_in_hand) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.projected_commitment) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.savings) }}</td>
                                        </tr>
									</tbody>
                                    <tfoot>
                                        <tr class="text-primary">
                                            <th colspan="4" class="text-center">Total</th>
                                            <th class="text-center">{{ formatCurrency(budget3) }}</th>
                                            <th class="text-center">{{ formatCurrency(sap_commit3) }}</th>
                                            <th class="text-center">{{ formatCurrency(manual_commit3) }}</th>
                                            <th class="text-center">{{ formatCurrency(total_commit3) }}</th>
                                            <th class="text-center">{{ formatCurrency(actual_spent3) }}</th>
                                            <th class="text-center">{{ formatCurrency(balance_in_hand3) }}</th>
                                            <th class="text-center">{{ formatCurrency(projected_commitment3) }}</th>
                                            <th class="text-center">{{ formatCurrency(savings3) }}</th>
                                        </tr>
                                    </tfoot>
								</table>
							</div>
						</div>
					</div>
                    <div class="row g-2 mb-4" v-if="project_costs4.length!=0">
						<div class="col-md-12">
                            <strong>{{ heading4 }}</strong>
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th class="text-center">Level</th>
											<th class="text-center">WBS Code</th>
											<th class="text-center">WBS Description</th>
											<th class="text-center">Approved Budget</th>
											<th class="text-center">SAP Commit</th>
											<th class="text-center">Manual Commit</th>
											<th class="text-center">Total Commit</th>
											<th class="text-center">Actual Spent</th>
											<th class="text-center">Balance in Hand</th>
											<th class="text-center">Projected Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
                                        <tr v-for="project_cost, key in project_costs4" :key="key">
											<td class="text-center" @click="openLevel(project_cost)">
                                                <i class="ri-add-box-line icon_hgt"></i>
                                            </td>
                                            <td class="text-center">{{ project_cost.level }}</td>
											<td class="text-center">{{ project_cost.wbs_code }}</td>
											<td class="text-center">{{ project_cost.wbs_description }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.budget) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.sap_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.manual_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.total_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.actual_spent) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.balance_in_hand) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.projected_commitment) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.savings) }}</td>
                                        </tr>
									</tbody>
                                    <tfoot>
                                        <tr class="text-primary">
                                            <th colspan="4" class="text-center">Total</th>
                                            <th class="text-center">{{ formatCurrency(budget4) }}</th>
                                            <th class="text-center">{{ formatCurrency(sap_commit4) }}</th>
                                            <th class="text-center">{{ formatCurrency(manual_commit4) }}</th>
                                            <th class="text-center">{{ formatCurrency(total_commit4) }}</th>
                                            <th class="text-center">{{ formatCurrency(actual_spent4) }}</th>
                                            <th class="text-center">{{ formatCurrency(balance_in_hand4) }}</th>
                                            <th class="text-center">{{ formatCurrency(projected_commitment4) }}</th>
                                            <th class="text-center">{{ formatCurrency(savings4) }}</th>
                                        </tr>
                                    </tfoot>
								</table>
							</div>
						</div>
					</div>
                    <div class="row g-2 mb-4" v-if="project_costs5.length!=0">
						<div class="col-md-12">
                            <strong>{{ heading5 }}</strong>
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th class="text-center">Level</th>
											<th class="text-center">WBS Code</th>
											<th class="text-center">WBS Description</th>
											<th class="text-center">Approved Budget</th>
											<th class="text-center">SAP Commit</th>
											<th class="text-center">Manual Commit</th>
											<th class="text-center">Total Commit</th>
											<th class="text-center">Actual Spent</th>
											<th class="text-center">Balance in Hand</th>
											<th class="text-center">Projected Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
                                        <tr v-for="project_cost, key in project_costs5" :key="key">
											<td class="text-center" @click="openLevel(project_cost)">
                                                <i class="ri-add-box-line icon_hgt"></i>
                                            </td>
                                            <td class="text-center">{{ project_cost.level }}</td>
											<td class="text-center">{{ project_cost.wbs_code }}</td>
											<td class="text-center">{{ project_cost.wbs_description }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.budget) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.sap_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.manual_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.total_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.actual_spent) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.balance_in_hand) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.projected_commitment) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.savings) }}</td>
                                        </tr>
									</tbody>
                                    <tfoot>
                                        <tr class="text-primary">
                                            <th colspan="4" class="text-center">Total</th>
                                            <th class="text-center">{{ formatCurrency(budget5) }}</th>
                                            <th class="text-center">{{ formatCurrency(sap_commit5) }}</th>
                                            <th class="text-center">{{ formatCurrency(manual_commit5) }}</th>
                                            <th class="text-center">{{ formatCurrency(total_commit5) }}</th>
                                            <th class="text-center">{{ formatCurrency(actual_spent5) }}</th>
                                            <th class="text-center">{{ formatCurrency(balance_in_hand5) }}</th>
                                            <th class="text-center">{{ formatCurrency(projected_commitment5) }}</th>
                                            <th class="text-center">{{ formatCurrency(savings5) }}</th>
                                        </tr>
                                    </tfoot>
								</table>
							</div>
						</div>
					</div>
                    <div class="row g-2 mb-4" v-if="project_costs6.length!=0">
						<div class="col-md-12">
                            <strong>{{ heading6 }}</strong>
							<div class="table-responsive table-responsive-sm">
								<table class="table text-nowrap table-sm table-bordered table-striped mb-0">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th class="text-center">Level</th>
											<th class="text-center">WBS Code</th>
											<th class="text-center">WBS Description</th>
											<th class="text-center">Approved Budget</th>
											<th class="text-center">SAP Commit</th>
											<th class="text-center">Manual Commit</th>
											<th class="text-center">Total Commit</th>
											<th class="text-center">Actual Spent</th>
											<th class="text-center">Balance in Hand</th>
											<th class="text-center">Projected Commitment</th>
											<th class="text-center">Savings</th>
										</tr>
									</thead>
									<tbody>
                                        <tr v-for="project_cost, key in project_costs6" :key="key">
											<td class="text-center" @click="openLevel(project_cost)">
                                                <i class="ri-add-box-line icon_hgt"></i>
                                            </td>
                                            <td class="text-center">{{ project_cost.level }}</td>
											<td class="text-center">{{ project_cost.wbs_code }}</td>
											<td class="text-center">{{ project_cost.wbs_description }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.budget) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.sap_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.manual_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.total_commit) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.actual_spent) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.balance_in_hand) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.projected_commitment) }}</td>
                                            <td class="text-center">{{ formatCurrency(project_cost.savings) }}</td>
                                        </tr>
									</tbody>
                                    <tfoot>
                                        <tr class="text-primary">
                                            <th colspan="4" class="text-center">Total</th>
                                            <th class="text-center">{{ formatCurrency(budget6) }}</th>
                                            <th class="text-center">{{ formatCurrency(sap_commit6) }}</th>
                                            <th class="text-center">{{ formatCurrency(manual_commit6) }}</th>
                                            <th class="text-center">{{ formatCurrency(total_commit6) }}</th>
                                            <th class="text-center">{{ formatCurrency(actual_spent6) }}</th>
                                            <th class="text-center">{{ formatCurrency(balance_in_hand6) }}</th>
                                            <th class="text-center">{{ formatCurrency(projected_commitment6) }}</th>
                                            <th class="text-center">{{ formatCurrency(savings6) }}</th>
                                        </tr>
                                    </tfoot>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Users.Create",
	components: {},
	data() {
		return {
            report:{
                level:0,
                wbs_code:'',
                project_id:'',
            },
            heading:"",
            heading1:"",
            heading2:"",
            heading3:"",
            heading4:"",
            heading5:"",
            heading6:"",
            projects:[],
            project_costs:[],
			project_costs1:[],
            project_costs2:[],
            project_costs3:[],
            project_costs4:[],
            project_costs5:[],
            project_costs6:[],
            currency_unit: 1
		};
	},
	mounted() {
		this.getProjects()	
	},
    computed: {
            budget: function () {
                let vm = this;
                return vm.project_costs.reduce((prev, cur) => +prev + +cur.budget, 0);
            },
            sap_commit: function () {
                let vm = this;
                return vm.project_costs.reduce((prev, cur) => +prev + +cur.sap_commit, 0);
            },
            manual_commit: function () {
                let vm = this;
                return vm.project_costs.reduce((prev, cur) => +prev + +cur.manual_commit, 0);
            },
            total_commit: function () {
                let vm = this;
                return vm.project_costs.reduce((prev, cur) => +prev + +cur.total_commit, 0);
            },
            actual_spent: function () {
                let vm = this;
                return vm.project_costs.reduce((prev, cur) => +prev + +cur.actual_spent, 0);
            },
            balance_in_hand: function () {
                let vm = this;
                return vm.project_costs.reduce((prev, cur) => +prev + +cur.balance_in_hand, 0);
            },
            projected_commitment: function () {
                let vm = this;
                return vm.project_costs.reduce((prev, cur) => +prev + +cur.projected_commitment, 0);
            },
            savings: function () {
                let vm = this;
                return vm.project_costs.reduce((prev, cur) => +prev + +cur.savings, 0);
            },
            
            // Level1
            budget1: function () {
                let vm = this;
                return vm.project_costs1.reduce((prev, cur) => +prev + +cur.budget, 0);
            },
            sap_commit1: function () {
                let vm = this;
                return vm.project_costs1.reduce((prev, cur) => +prev + +cur.sap_commit, 0);
            },
            manual_commit1: function () {
                let vm = this;
                return vm.project_costs1.reduce((prev, cur) => +prev + +cur.manual_commit, 0);
            },
            total_commit1: function () {
                let vm = this;
                return vm.project_costs1.reduce((prev, cur) => +prev + +cur.total_commit, 0);
            },
            actual_spent1: function () {
                let vm = this;
                return vm.project_costs1.reduce((prev, cur) => +prev + +cur.actual_spent, 0);
            },
            balance_in_hand1: function () {
                let vm = this;
                return vm.project_costs1.reduce((prev, cur) => +prev + +cur.balance_in_hand, 0);
            },
            projected_commitment1: function () {
                let vm = this;
                return vm.project_costs1.reduce((prev, cur) => +prev + +cur.projected_commitment, 0);
            },
            savings1: function () {
                let vm = this;
                return vm.project_costs1.reduce((prev, cur) => +prev + +cur.savings, 0);
            },

            // Level2
            budget2: function () {
                let vm = this;
                return vm.project_costs2.reduce((prev, cur) => +prev + +cur.budget, 0);
            },
            sap_commit2: function () {
                let vm = this;
                return vm.project_costs2.reduce((prev, cur) => +prev + +cur.sap_commit, 0);
            },
            manual_commit2: function () {
                let vm = this;
                return vm.project_costs2.reduce((prev, cur) => +prev + +cur.manual_commit, 0);
            },
            total_commit2: function () {
                let vm = this;
                return vm.project_costs2.reduce((prev, cur) => +prev + +cur.total_commit, 0);
            },
            actual_spent2: function () {
                let vm = this;
                return vm.project_costs2.reduce((prev, cur) => +prev + +cur.actual_spent, 0);
            },
            balance_in_hand2: function () {
                let vm = this;
                return vm.project_costs2.reduce((prev, cur) => +prev + +cur.balance_in_hand, 0);
            },
            projected_commitment2: function () {
                let vm = this;
                return vm.project_costs2.reduce((prev, cur) => +prev + +cur.projected_commitment, 0);
            },
            savings2: function () {
                let vm = this;
                return vm.project_costs2.reduce((prev, cur) => +prev + +cur.savings, 0);
            },

            // Level3
            budget3: function () {
                let vm = this;
                return vm.project_costs3.reduce((prev, cur) => +prev + +cur.budget, 0);
            },
            sap_commit3: function () {
                let vm = this;
                return vm.project_costs3.reduce((prev, cur) => +prev + +cur.sap_commit, 0);
            },
            manual_commit3: function () {
                let vm = this;
                return vm.project_costs3.reduce((prev, cur) => +prev + +cur.manual_commit, 0);
            },
            total_commit3: function () {
                let vm = this;
                return vm.project_costs3.reduce((prev, cur) => +prev + +cur.total_commit, 0);
            },
            actual_spent3: function () {
                let vm = this;
                return vm.project_costs3.reduce((prev, cur) => +prev + +cur.actual_spent, 0);
            },
            balance_in_hand3: function () {
                let vm = this;
                return vm.project_costs3.reduce((prev, cur) => +prev + +cur.balance_in_hand, 0);
            },
            projected_commitment3: function () {
                let vm = this;
                return vm.project_costs3.reduce((prev, cur) => +prev + +cur.projected_commitment, 0);
            },
            savings3: function () {
                let vm = this;
                return vm.project_costs3.reduce((prev, cur) => +prev + +cur.savings, 0);
            },

            // Level4
            budget4: function () {
                let vm = this;
                return vm.project_costs4.reduce((prev, cur) => +prev + +cur.budget, 0);
            },
            sap_commit4: function () {
                let vm = this;
                return vm.project_costs4.reduce((prev, cur) => +prev + +cur.sap_commit, 0);
            },
            manual_commit4: function () {
                let vm = this;
                return vm.project_costs4.reduce((prev, cur) => +prev + +cur.manual_commit, 0);
            },
            total_commit4: function () {
                let vm = this;
                return vm.project_costs4.reduce((prev, cur) => +prev + +cur.total_commit, 0);
            },
            actual_spent4: function () {
                let vm = this;
                return vm.project_costs4.reduce((prev, cur) => +prev + +cur.actual_spent, 0);
            },
            balance_in_hand4: function () {
                let vm = this;
                return vm.project_costs4.reduce((prev, cur) => +prev + +cur.balance_in_hand, 0);
            },
            projected_commitment4: function () {
                let vm = this;
                return vm.project_costs4.reduce((prev, cur) => +prev + +cur.projected_commitment, 0);
            },
            savings4: function () {
                let vm = this;
                return vm.project_costs4.reduce((prev, cur) => +prev + +cur.savings, 0);
            },

            // Level5
            budget5: function () {
                let vm = this;
                return vm.project_costs5.reduce((prev, cur) => +prev + +cur.budget, 0);
            },
            sap_commit5: function () {
                let vm = this;
                return vm.project_costs5.reduce((prev, cur) => +prev + +cur.sap_commit, 0);
            },
            manual_commit5: function () {
                let vm = this;
                return vm.project_costs5.reduce((prev, cur) => +prev + +cur.manual_commit, 0);
            },
            total_commit5: function () {
                let vm = this;
                return vm.project_costs5.reduce((prev, cur) => +prev + +cur.total_commit, 0);
            },
            actual_spent5: function () {
                let vm = this;
                return vm.project_costs5.reduce((prev, cur) => +prev + +cur.actual_spent, 0);
            },
            balance_in_hand5: function () {
                let vm = this;
                return vm.project_costs5.reduce((prev, cur) => +prev + +cur.balance_in_hand, 0);
            },
            projected_commitment5: function () {
                let vm = this;
                return vm.project_costs5.reduce((prev, cur) => +prev + +cur.projected_commitment, 0);
            },
            savings5: function () {
                let vm = this;
                return vm.project_costs5.reduce((prev, cur) => +prev + +cur.savings, 0);
            },

            // Level6
            budget6: function () {
                let vm = this;
                return vm.project_costs6.reduce((prev, cur) => +prev + +cur.budget, 0);
            },
            sap_commit6: function () {
                let vm = this;
                return vm.project_costs6.reduce((prev, cur) => +prev + +cur.sap_commit, 0);
            },
            manual_commit6: function () {
                let vm = this;
                return vm.project_costs6.reduce((prev, cur) => +prev + +cur.manual_commit, 0);
            },
            total_commit6: function () {
                let vm = this;
                return vm.project_costs6.reduce((prev, cur) => +prev + +cur.total_commit, 0);
            },
            actual_spent6: function () {
                let vm = this;
                return vm.project_costs6.reduce((prev, cur) => +prev + +cur.actual_spent, 0);
            },
            balance_in_hand6: function () {
                let vm = this;
                return vm.project_costs6.reduce((prev, cur) => +prev + +cur.balance_in_hand, 0);
            },
            projected_commitment6: function () {
                let vm = this;
                return vm.project_costs6.reduce((prev, cur) => +prev + +cur.projected_commitment, 0);
            },
            savings6: function () {
                let vm = this;
                return vm.project_costs6.reduce((prev, cur) => +prev + +cur.savings, 0);
            },
        },
	methods: {

        getProjects() {
            let vm = this;
            let uri = { uri: "getProjectsDropDown" };
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.projects = response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
        },  

        formatCurrency(amount){
            let value = amount/this.currency_unit;
            return value.toLocaleString('en-IN',{
                maximumFractionDigits: 0,
                style: 'currency',
                currency: 'INR'
            });
        },

        changeProject() {
            let vm = this;
            let project = vm.projects.filter( project => {
                return project.project_id == vm.report.project_id
            });
            if(project.length!=0) {
                vm.report.wbs_code = project[0].wbs_code;
                vm.heading = project[0].wbs_code + '::' + project[0].project_name;
                vm.getProjectLevelCosts()
            }
        },

        getProjectLevelCosts() {
            let vm = this;
            let loader = this.$loading.show();
			vm.$store.dispatch("post", { uri: "getProjectLevelCosts",data:vm.report })
			.then(function (response) {
				loader.hide();
                if(vm.report.level==0) {
                    vm.project_costs = response.data;
                }
                if(vm.report.level==1) {
                    vm.project_costs1 = response.data;
                }
                if(vm.report.level==2) {
                    vm.project_costs2 = response.data;
                }
                if(vm.report.level==3) {
                    vm.project_costs3 = response.data;
                }
                if(vm.report.level==4) {
                    vm.project_costs4 = response.data;
                }
                if(vm.report.level==5) {
                    vm.project_costs5 = response.data;
                }
                if(vm.report.level==6) {
                    vm.project_costs6 = response.data;
                }
			})
			.catch(function (error) {
				loader.hide();
				vm.errors = error.response.data.error;
				vm.$store.dispatch("error", error.response.data.message);
			});
        },

        openLevel(project_cost) {
            let vm = this;
            vm.report.wbs_code = project_cost.wbs_code;
            vm.report.level = parseInt(project_cost.level) + parseInt(1);
            if(vm.report.level==1) {
                vm.heading1 = project_cost.wbs_code + '::' + project_cost.wbs_description;
                vm.project_costs1 = [];
                vm.project_costs2 = [];
                vm.project_costs3 = [];
                vm.project_costs4 = [];
                vm.project_costs5 = [];
                vm.project_costs6 = [];
            }
            if(vm.report.level==2) {
                vm.heading2 = project_cost.wbs_code + '::' + project_cost.wbs_description;
                vm.project_costs2 = [];
                vm.project_costs3 = [];
                vm.project_costs4 = [];
                vm.project_costs5 = [];
                vm.project_costs6 = [];
            }
            if(vm.report.level==3) {
                vm.heading3 = project_cost.wbs_code + '::' + project_cost.wbs_description;
                vm.project_costs3 = [];
                vm.project_costs4 = [];
                vm.project_costs5 = [];
                vm.project_costs6 = [];
            }
            if(vm.report.level==4) {
                vm.heading4 = project_cost.wbs_code + '::' + project_cost.wbs_description;
                vm.project_costs4 = [];
                vm.project_costs5 = [];
                vm.project_costs6 = [];
            }
            if(vm.report.level==5) {
                vm.heading5 = project_cost.wbs_code + '::' + project_cost.wbs_description;
                vm.project_costs5 = [];
                vm.project_costs6 = [];
            }
            if(vm.report.level==6) {
                vm.heading6 = project_cost.wbs_code + '::' + project_cost.wbs_description;
                vm.project_costs6 = [];
            }
            vm.getProjectLevelCosts()
        },
	},
};
</script>